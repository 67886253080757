import { bannerPerFunzionalita, bannerProfiloParametri, BannerWcmDto, getBannerPerFunzionalita } from "@sparkasse/commons";
import CarouselCustom from "component/carouselCustom/carouselCustom.component";
import { navigateToPage, WEBPAGE } from "component/history/history.component";
import { base64ToImageSrc } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";
import ButtonStandard from "../buttonStandard/buttonPrimary.component";

const Banner: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    bannerPerFunzionalita,
    funzionalita,
    bannerProfiloParametri,
    className
  } = props;

  const [eseguitaChiamata, setEseguitaChiamata] = useState(false);

  const banners = bannerPerFunzionalita?.[funzionalita] || [];

  useEffect(() => {
    if (bannerProfiloParametri.some(b => b.vincoloFunzione === funzionalita) && !eseguitaChiamata) {
      setEseguitaChiamata(true);
      props.getBannerPerFunzionalita(funzionalita);
    }
  }, [bannerProfiloParametri]);

  const handleNavigation = (banner: BannerWcmDto) => {
    if (banner.urlLink) {
      if (banner?.urlLink?.toLowerCase().startsWith('http://perte.it/')) {
        navigateToPage(WEBPAGE.CatalogoProdotti.url, { idProdotto: banner.urlLink.substr('http://PERTE.it/'.length) })
      } else if (banner?.urlLink) {
        window.open(banner.urlLink);
      }
    } else if (banner.codiceServizio) {
      navigateToPage(banner.codiceServizio)
    }
  };

  const BannerItems = (
    !banners.length ? [] : banners.map(banner =>
      <div key={banner.messaggioId} className="banner-body">
        {!!banner.immagine &&
          <div className="banner-icon-view iconView">
            <img className="banner-icon-view-img" src={base64ToImageSrc(banner.immagine)} />
          </div>
        }
        <div className="txtView">
          {banner.visualizzaTitolo && !!banner.titolo ?
            <p className="txtTitle titoloBanner">
              {banner.titolo}
            </p> : ""
          }
          <p className="txtDescription" dangerouslySetInnerHTML={{ __html: banner.testo || "" }} />
        </div>
        {(banner.urlLink || banner.codiceServizio) && (
          <div className="buttonView">
            <ButtonStandard
              descrizione={t("banner.componente.scopriDiPiu")}
              onClick={() => handleNavigation(banner)}
            />
          </div>
        )}
      </div>
    ));

  return (
    <div className={`content-main-banner-view ${className || ""} ${!banners.length ? "home-banner-empty" : ""}`}>
      <CarouselCustom
        className="banner-home-carousel"
        custom={{
          slides: BannerItems,
          autoplayMilliseconds: BannerItems.length > 1 ? 8000 : undefined
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  bannerPerFunzionalita: bannerPerFunzionalita(state.banner),
  bannerProfiloParametri: bannerProfiloParametri(state.profilo),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getBannerPerFunzionalita,
    },
    dispatch
  );

interface IProps {
  funzionalita: string
  className?: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;

export default connect(mapStateToProps, mapDispatchToProps)(Banner);

