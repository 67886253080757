"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MandatiSddRevocaRequestInToJSON = exports.MandatiSddRevocaRequestInFromJSONTyped = exports.MandatiSddRevocaRequestInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MandatiSddRevocaRequestInFromJSON(json) {
  return MandatiSddRevocaRequestInFromJSONTyped(json, false);
}
exports.MandatiSddRevocaRequestInFromJSON = MandatiSddRevocaRequestInFromJSON;
function MandatiSddRevocaRequestInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziale']),
    'mandato': !runtime_1.exists(json, 'mandato') ? undefined : _1.MandatoSDDDtoFromJSON(json['mandato'])
  };
}
exports.MandatiSddRevocaRequestInFromJSONTyped = MandatiSddRevocaRequestInFromJSONTyped;
function MandatiSddRevocaRequestInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'credenziale': _1.CredenzialeDispositivaDtoToJSON(value.credenziale),
    'mandato': _1.MandatoSDDDtoToJSON(value.mandato)
  };
}
exports.MandatiSddRevocaRequestInToJSON = MandatiSddRevocaRequestInToJSON;