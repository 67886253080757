"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RiepilogoPatrimonioDisponibilitaDtoToJSON = exports.RiepilogoPatrimonioDisponibilitaDtoFromJSONTyped = exports.RiepilogoPatrimonioDisponibilitaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RiepilogoPatrimonioDisponibilitaDtoFromJSON(json) {
  return RiepilogoPatrimonioDisponibilitaDtoFromJSONTyped(json, false);
}
exports.RiepilogoPatrimonioDisponibilitaDtoFromJSON = RiepilogoPatrimonioDisponibilitaDtoFromJSON;
function RiepilogoPatrimonioDisponibilitaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'disponibilitaLordo': !runtime_1.exists(json, 'disponibilitaLordo') ? undefined : json['disponibilitaLordo'],
    'disponibilitaNetto': !runtime_1.exists(json, 'disponibilitaNetto') ? undefined : json['disponibilitaNetto'],
    'disponibilitaNettoList': !runtime_1.exists(json, 'disponibilitaNettoList') ? undefined : json['disponibilitaNettoList'].map(_1.PosizioneDisponibilitaDtoFromJSON),
    'disponibilitaNettoListVisibile': !runtime_1.exists(json, 'disponibilitaNettoListVisibile') ? undefined : json['disponibilitaNettoListVisibile'],
    'disponibilitaLordoList': !runtime_1.exists(json, 'disponibilitaLordoList') ? undefined : json['disponibilitaLordoList'].map(_1.PosizioneDisponibilitaDtoFromJSON),
    'disponibilitaLordoListVisibile': !runtime_1.exists(json, 'disponibilitaLordoListVisibile') ? undefined : json['disponibilitaLordoListVisibile']
  };
}
exports.RiepilogoPatrimonioDisponibilitaDtoFromJSONTyped = RiepilogoPatrimonioDisponibilitaDtoFromJSONTyped;
function RiepilogoPatrimonioDisponibilitaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'disponibilitaLordo': value.disponibilitaLordo,
    'disponibilitaNetto': value.disponibilitaNetto,
    'disponibilitaNettoList': value.disponibilitaNettoList === undefined ? undefined : value.disponibilitaNettoList.map(_1.PosizioneDisponibilitaDtoToJSON),
    'disponibilitaNettoListVisibile': value.disponibilitaNettoListVisibile,
    'disponibilitaLordoList': value.disponibilitaLordoList === undefined ? undefined : value.disponibilitaLordoList.map(_1.PosizioneDisponibilitaDtoToJSON),
    'disponibilitaLordoListVisibile': value.disponibilitaLordoListVisibile
  };
}
exports.RiepilogoPatrimonioDisponibilitaDtoToJSON = RiepilogoPatrimonioDisponibilitaDtoToJSON;