"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RigaSezioneIdentificativiDtoToJSON = exports.RigaSezioneIdentificativiDtoFromJSONTyped = exports.RigaSezioneIdentificativiDtoFromJSON = void 0;
function RigaSezioneIdentificativiDtoFromJSON(json) {
  return RigaSezioneIdentificativiDtoFromJSONTyped(json, false);
}
exports.RigaSezioneIdentificativiDtoFromJSON = RigaSezioneIdentificativiDtoFromJSON;
function RigaSezioneIdentificativiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoVeicolo': json['tipoVeicolo'],
    'elementoId': json['elementoId'],
    'codiceTributo': json['codiceTributo'],
    'annoRiferimento': json['annoRiferimento'],
    'importoDebito': json['importoDebito']
  };
}
exports.RigaSezioneIdentificativiDtoFromJSONTyped = RigaSezioneIdentificativiDtoFromJSONTyped;
function RigaSezioneIdentificativiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoVeicolo': value.tipoVeicolo,
    'elementoId': value.elementoId,
    'codiceTributo': value.codiceTributo,
    'annoRiferimento': value.annoRiferimento,
    'importoDebito': value.importoDebito
  };
}
exports.RigaSezioneIdentificativiDtoToJSON = RigaSezioneIdentificativiDtoToJSON;