"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContribuenteDomicilioDtoToJSON = exports.ContribuenteDomicilioDtoFromJSONTyped = exports.ContribuenteDomicilioDtoFromJSON = void 0;
function ContribuenteDomicilioDtoFromJSON(json) {
  return ContribuenteDomicilioDtoFromJSONTyped(json, false);
}
exports.ContribuenteDomicilioDtoFromJSON = ContribuenteDomicilioDtoFromJSON;
function ContribuenteDomicilioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'comune': json['comune'],
    'provincia': json['provincia'],
    'indirizzo': json['indirizzo']
  };
}
exports.ContribuenteDomicilioDtoFromJSONTyped = ContribuenteDomicilioDtoFromJSONTyped;
function ContribuenteDomicilioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'comune': value.comune,
    'provincia': value.provincia,
    'indirizzo': value.indirizzo
  };
}
exports.ContribuenteDomicilioDtoToJSON = ContribuenteDomicilioDtoToJSON;