"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
  bannerPerFunzionalita: {},
  bannerPerFunzionalitaSenzaHTML: {},
  banner: (0, api_rest_1.BannerWcmListDtoFromJSON)([]),
  bannerSelezionato: (0, api_rest_1.BannerWcmDtoFromJSON)({}),
  bannerDaNascondere: [],
  visibilitaBanner: false,
  bannerConImmagine: [],
  bannerConImmagineSenzaHTML: [],
  bannerSenzaImmagine: [],
  bannerSenzaImmagineSenzaHTML: [],
  bannerFunzionalitaConImmagineSelezionato: (0, api_rest_1.BannerWcmDtoFromJSON)({}),
  bannerFunzionalitaSenzaImmagineSelezionato: (0, api_rest_1.BannerWcmDtoFromJSON)({})
};
let bannerSenzaHTML;
const bannerReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.BANNER_GET_BANNER_SUCCESS:
      let bannerPerFunzionalita = {
        ...state.bannerPerFunzionalita
      };
      const bannerPerFunzionalitaSenzaHTML = {};
      // const bannerPerFunzionalitaArray = Object.keys(bannerPerFunzionalita);
      // for (let funzionalita in bannerPerFunzionalitaArray) {
      //   bannerPerFunzionalitaSenzaHTML[funzionalita] = (bannerPerFunzionalita[funzionalita] || []).map((banner) => {
      //     return {
      //       ...banner,
      //       testo: extractContentFromHTML(banner.testo || '')
      //     }
      //   })
      // }
      if (action.funzionalita) {
        bannerPerFunzionalita[action.funzionalita] = action.payload.banners || [];
        bannerPerFunzionalitaSenzaHTML[action.funzionalita] = (action.payload.banners || []).map(banner => {
          return {
            ...banner,
            testo: (0, helpers_1.extractContentFromHTML)(banner.testo || '')
          };
        });
      }
      return {
        ...state,
        banner: action.payload,
        bannerPerFunzionalita: bannerPerFunzionalita,
        bannerPerFunzionalitaSenzaHTML: bannerPerFunzionalitaSenzaHTML
      };
    case __1.EReduxActionTypes.BANNER_SET_BANNER_SELEZIONATO:
      return {
        ...state,
        bannerSelezionato: action.payload
      };
    case __1.EReduxActionTypes.BANNER_SET_BANNER_NASCOSTO:
      return {
        ...state,
        bannerDaNascondere: (state.bannerDaNascondere || []).concat(action.payload || [])
      };
    case __1.EReduxActionTypes.BANNER_SET_VISIBILITA:
      return {
        ...state,
        visibilitaBanner: action.payload
      };
    case __1.EReduxActionTypes.BANNER_SET_BANNER_CON_IMMAGINE:
      bannerSenzaHTML = action.payload.map(banner => {
        return {
          ...banner,
          testo: (0, helpers_1.extractContentFromHTML)(banner.testo || '')
        };
      });
      return {
        ...state,
        bannerConImmagine: action.payload,
        bannerConImmagineSenzaHTML: bannerSenzaHTML
      };
    case __1.EReduxActionTypes.BANNER_SET_BANNER_SENZA_IMMAGINE:
      bannerSenzaHTML = action.payload.map(banner => {
        return {
          ...banner,
          testo: (0, helpers_1.extractContentFromHTML)(banner.testo || '')
        };
      });
      return {
        ...state,
        bannerSenzaImmagine: action.payload,
        bannerSenzaImmagineSenzaHTML: bannerSenzaHTML
      };
    case __1.EReduxActionTypes.BANNER_SET_BANNER_CON_IMMAGINE_SELEZIONATO:
      return {
        ...state,
        bannerFunzionalitaConImmagineSelezionato: action.payload
      };
    case __1.EReduxActionTypes.BANNER_SET_BANNER_SENZA_IMMAGINE_SELEZIONATO:
      return {
        ...state,
        bannerFunzionalitaSenzaImmagineSelezionato: action.payload
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.bannerReducer = bannerReducer;
exports.default = exports.bannerReducer;