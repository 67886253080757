"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StartPinViewSCAResponseDtoToJSON = exports.StartPinViewSCAResponseDtoFromJSONTyped = exports.StartPinViewSCAResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function StartPinViewSCAResponseDtoFromJSON(json) {
  return StartPinViewSCAResponseDtoFromJSONTyped(json, false);
}
exports.StartPinViewSCAResponseDtoFromJSON = StartPinViewSCAResponseDtoFromJSON;
function StartPinViewSCAResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'txId': !runtime_1.exists(json, 'txId') ? undefined : json['txId'],
    'callbackUrl': !runtime_1.exists(json, 'callbackUrl') ? undefined : json['callbackUrl'],
    'authSchema': !runtime_1.exists(json, 'authSchema') ? undefined : json['authSchema']
  };
}
exports.StartPinViewSCAResponseDtoFromJSONTyped = StartPinViewSCAResponseDtoFromJSONTyped;
function StartPinViewSCAResponseDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'txId': value.txId,
    'callbackUrl': value.callbackUrl,
    'authSchema': value.authSchema
  };
}
exports.StartPinViewSCAResponseDtoToJSON = StartPinViewSCAResponseDtoToJSON;