"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esportaDettaglioMovimentoRicaricaCartaPDF = exports.esportaMovimentiRicaricaCartaPDF = exports.setRaicaricaCartaSetMovimentiRapportoSelezionato = exports.setRicaricaCartaSetPanFiltri = exports.setRicaricaCartaSetCodiceRapporto = exports.setRicaricaCartaSetPeriodo = exports.setRicaricaCartaSetDataDa = exports.setRicaricaCartaSetDataA = exports.getMovimentiRicaricaCarta = exports.getParametriMovimentiRicaricaCarta = exports.autorizzaRicarica = exports.getInfoDispositivaRicaricaCarta = exports.getOneclickRicaricaCarta = exports.getRicaricaCartaFromBozza = exports.shouldUpdateCarta = exports.getBozzaRicaricaCarta = exports.updateBozzaRicaricaCartaAction = exports.delBozzaRicaricaCarta = exports.addRicaricaCartaAction = exports.getBozzeRicaricaCarta = exports.setRicaricaCartaTipoAction = exports.setRicaricaCartaSetPanAction = exports.setRicaricaCartaSetStep = exports.setRicaricaCartaSetSave1Click = exports.setRicaricaCartaSetAlias1Click = exports.setRicaricaCartaSetDataEsecuzione = exports.setRicaricaCartaImporto = exports.setRicaricaCartaRapportoSelezionato = exports.controlloRicaricaCartaAction = exports.getParametriRicaricaCarta = exports.rimuoviFiltriRicaricaCarta = exports.resetRicaricaCartaInfoDispositiva = exports.resetRiepilogoRicaricaCarta = exports.resetBozzeRicaricaCarta = exports.resetRicaricaCarta = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const moment = require("moment");
function resetRicaricaCarta() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_RESET
  };
}
exports.resetRicaricaCarta = resetRicaricaCarta;
function resetBozzeRicaricaCarta() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_RESET_BOZZE
  };
}
exports.resetBozzeRicaricaCarta = resetBozzeRicaricaCarta;
function resetRiepilogoRicaricaCarta() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_RESET_RIEPILOGO
  };
}
exports.resetRiepilogoRicaricaCarta = resetRiepilogoRicaricaCarta;
function resetRicaricaCartaInfoDispositiva() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_RESET_INFO_DISPOSITIVA
  };
}
exports.resetRicaricaCartaInfoDispositiva = resetRicaricaCartaInfoDispositiva;
function rimuoviFiltriRicaricaCarta() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_RIMUOVI_FILTRI
  };
}
exports.rimuoviFiltriRicaricaCarta = rimuoviFiltriRicaricaCarta;
function getParametriRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_PARAMETRI_START
  };
}
function getParametriRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function getParametriPerRipetiRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_PARAMETRI_PER_RIPETI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    !!sendErrorToMatomo && sendErrorToMatomo('RICARICA_CIVIPAY', "code_error RICARICA_CIVIPAY ".concat(value.status, " ").concat(value === null || value === void 0 ? void 0 : value.message, " / ").concat(value.timestamp));
  };
}
const getParametriRicaricaCarta = function () {
  let ripeti = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let psd2In = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriRicaricaCartaStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/parametri_bonifico.json')).then(parametri => {
        dispatch(ripeti ? getParametriRicaricaCartaSuccess((0, api_rest_1.ParametriRicaricaCarteDtoFromJSON)(parametri)) : getParametriPerRipetiRicaricaCartaSuccess((0, api_rest_1.ParametriRicaricaCarteDtoFromJSON)(parametri)));
        dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriRicaricaCartaRicaricabile().then(response => {
          var _a;
          dispatch(ripeti ? getParametriPerRipetiRicaricaCartaSuccess(response) : getParametriRicaricaCartaSuccess(response));
          ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({
            type: general_types_1.TypeNoRapporti.RICARICA_CARTA
          }));
        }).catch(async error => {
          // @ts-ignore
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getParametriRicaricaCarta = getParametriRicaricaCarta;
function controlloRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_CONTROLLO_START
  };
}
function controlloRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_CONTROLLO_SUCCESS,
    payload
  };
}
const controlloRicaricaCartaAction = () => async (dispatch, getState, extraArguments) => {
  const ricaricaCarta = getState().ricaricaCarta;
  dispatch(setRicaricaCartaSetFormSubmittedAction(true));
  let request;
  request = __1.buildRicaricaCarta;
  if ((0, __1.formRicaricaCartaValid)(ricaricaCarta)) {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloRicaricaCartaStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      const controlloRicaricaCartaRequest = {
        ricaricaCarteDto: (0, __1.buildRicaricaCarta)(ricaricaCarta)
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloRicaricaCarta(controlloRicaricaCartaRequest).then(response => {
          dispatch(controlloRicaricaCartaSuccess(response));
        }).catch(async error => {
          const formattedError = await (0, helpers_1.formatError)(error);
          //@ts-ignore
          dispatch(generalError(formattedError));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.controlloRicaricaCartaAction = controlloRicaricaCartaAction;
function setRicaricaCartaRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setRicaricaCartaRapportoSelezionato = setRicaricaCartaRapportoSelezionato;
function setRicaricaCartaImporto(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_IMPORTO,
    payload
  };
}
exports.setRicaricaCartaImporto = setRicaricaCartaImporto;
function setRicaricaCartaSetDataEsecuzione(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_DATA_ESECUZIONE,
    payload
  };
}
exports.setRicaricaCartaSetDataEsecuzione = setRicaricaCartaSetDataEsecuzione;
function setRicaricaCartaSetAlias1Click(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_ALIAS_ONE_CLICK,
    payload
  };
}
exports.setRicaricaCartaSetAlias1Click = setRicaricaCartaSetAlias1Click;
function setRicaricaCartaSetSave1Click(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_SAVE_ONE_CLICK,
    payload
  };
}
exports.setRicaricaCartaSetSave1Click = setRicaricaCartaSetSave1Click;
function setRicaricaCartaSetStepAction(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_STEP,
    payload
  };
}
function setRicaricaCartaSetFormSubmittedAction(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_FORM_SUBMITTED,
    payload
  };
}
const setRicaricaCartaSetStep = i => async (dispatch, _, extraArguments) => {
  dispatch(setRicaricaCartaSetStepAction(i));
  if (extraArguments.platform == 'mobile') {
    dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
  }
};
exports.setRicaricaCartaSetStep = setRicaricaCartaSetStep;
function setRicaricaCartaSetPanAction(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_PAN,
    payload
  };
}
exports.setRicaricaCartaSetPanAction = setRicaricaCartaSetPanAction;
function setRicaricaCartaTipoAction(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_TIPO_CARTA,
    payload
  };
}
exports.setRicaricaCartaTipoAction = setRicaricaCartaTipoAction;
function getBozzeRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_BOZZE_START
  };
}
function getBozzeRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_BOZZE_SUCCESS,
    payload
  };
}
const getBozzeRicaricaCarta = request => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getBozzeRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bozze_mav.json')).then(bozze => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getBozzeRicaricaCartaRequest = {
      bozzeInDto: {
        codiceRapporto: request.codiceRapporto
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bozzeRicaricaCarta(getBozzeRicaricaCartaRequest).then(response => {
        dispatch(getBozzeRicaricaCartaSuccess(response));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getBozzeRicaricaCarta = getBozzeRicaricaCarta;
function addBozzaRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_ADD_BOZZA_START
  };
}
function addRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_ADD_BOZZA_SUCCESS,
    payload
  };
}
const addRicaricaCartaAction = () => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  const ricaricaCarta = getState().ricaricaCarta;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(addBozzaRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    let request;
    request = __1.buildRicaricaCarta;
    const addBozzaRicaricaCartaRequest = {
      ricaricaCarteDto: {
        ...request,
        pan: ricaricaCarta.formRicaricaCarta.pan,
        contoAddebito: ((_b = (_a = ricaricaCarta.formRicaricaCarta.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        tipoCarta: ricaricaCarta.formRicaricaCarta.tipoCarta,
        importo: (0, __1.formattedNumberToNumber)(ricaricaCarta.formRicaricaCarta.importo),
        bozza: {
          dataUltimaModifica: moment().toDate()
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).salvaBozzaRicaricaCarta(addBozzaRicaricaCartaRequest).then(response => {
        dispatch(addRicaricaCartaSuccess(response));
        dispatch((0, general_actions_1.addSnackbar)('cartaRicaricabile.bozzaSalvata'));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.addRicaricaCartaAction = addRicaricaCartaAction;
function delBozzaRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_DEL_BOZZA_START
  };
}
function delBozzaRicaricaCartaSuccess(id) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_DEL_BOZZA_SUCCESS,
    payload: id
  };
}
const delBozzaRicaricaCarta = id => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(delBozzaRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const delRicaricaCartaRequest = {
      id
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).eliminaBozzaRicaricaCarta(delRicaricaCartaRequest).then(() => {
        dispatch(delBozzaRicaricaCartaSuccess(id));
        dispatch((0, general_actions_1.addSnackbar)('cartaRicaricabile.bozzaEliminata'));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.delBozzaRicaricaCarta = delBozzaRicaricaCarta;
function updateBozzaRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_UPDATE_BOZZA_START
  };
}
function updateBozzaRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_UPDATE_BOZZA_SUCCESS,
    payload
  };
}
const updateBozzaRicaricaCartaAction = () => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  const ricaricaCarta = getState().ricaricaCarta;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(updateBozzaRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    let request;
    let successLabel = '';
    request = __1.buildRicaricaCarta;
    const idCurrentBozza = ((_a = ricaricaCarta.currentBozza) === null || _a === void 0 ? void 0 : _a.id) || 0;
    const updateBozzaRicaricaCartaRequest = {
      id: idCurrentBozza,
      ricaricaCarteDto: {
        ...request,
        id: idCurrentBozza,
        pan: ricaricaCarta.formRicaricaCarta.pan,
        contoAddebito: ((_c = (_b = ricaricaCarta.formRicaricaCarta.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
        tipoCarta: ricaricaCarta.formRicaricaCarta.tipoCarta,
        importo: (0, __1.formattedNumberToNumber)(ricaricaCarta.formRicaricaCarta.importo),
        bozza: {
          dataUltimaModifica: moment().toDate()
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateBozzaRicaricaCarta(updateBozzaRicaricaCartaRequest).then(response => {
        dispatch(updateBozzaRicaricaCartaSuccess(response));
        dispatch((0, general_actions_1.addSnackbar)('cartaRicaricabile.bozzaAggiornata'));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.updateBozzaRicaricaCartaAction = updateBozzaRicaricaCartaAction;
function getBozzaRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_BOZZA_START
  };
}
function getBozzaRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_BOZZA_SUCCESS,
    payload
  };
}
const getBozzaRicaricaCarta = id => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getBozzaRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getBozzaRicaricaCartaRequest = {
      id: id
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bozzaRicaricaCarta(getBozzaRicaricaCartaRequest).then(response => {
        dispatch(getBozzaRicaricaCartaSuccess(response));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getBozzaRicaricaCarta = getBozzaRicaricaCarta;
const shouldUpdateCarta = () => async (dispatch, getState, extraArguments) => {
  const state = getState().ricaricaCarta;
  if ((0, __1.checkUpdateBozzaRicaricaCarta)(state)) {
    dispatch((0, exports.updateBozzaRicaricaCartaAction)());
  } else {
    dispatch((0, exports.addRicaricaCartaAction)());
  }
};
exports.shouldUpdateCarta = shouldUpdateCarta;
function getRicaricaCartaFromBozza(payload) {
  let noBozza = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_RICARICA_FROM_BOZZA,
    payload,
    noBozza
  };
}
exports.getRicaricaCartaFromBozza = getRicaricaCartaFromBozza;
function getOneclickRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_ONECLICK_START
  };
}
function getOneclickRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_ONECLICK_SUCCESS,
    payload
  };
}
const getOneclickRicaricaCarta = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getOneclickRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      dispatch(getOneclickRicaricaCartaSuccess((0, api_rest_1.OperazioniOneClickDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getOneclickRicaricaCartaRequest = {
      operazioniOneClickInputDto: {}
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioniOneClickCarteRicaricabili(getOneclickRicaricaCartaRequest).then(response => {
        dispatch(getOneclickRicaricaCartaSuccess(response));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getOneclickRicaricaCarta = getOneclickRicaricaCarta;
function getInfoDispositivaRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_INFO_DISPOSITIVA_START
  };
}
function getInfoDispositivaRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_INFO_DISPOSITIVA_SUCCESS,
    payload
  };
}
const getInfoDispositivaRicaricaCarta = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoDispositivaRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
      //dispatch(getInfoDispositivaRicaricaCartaSuccess(InformazioniAutenticazioneDispositivaDtoFromJSON(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getInfoDispositivaRicaricaCartaRequest = {
      idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneRicaricaCarta(getInfoDispositivaRicaricaCartaRequest).then(response => {
        dispatch(getInfoDispositivaRicaricaCartaSuccess(response));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoDispositivaRicaricaCarta = getInfoDispositivaRicaricaCarta;
function autorizzaRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_AUTORIZZA_START
  };
}
function autorizzaRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_AUTORIZZA_SUCCESS,
    payload
  };
}
const autorizzaRicarica = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
      //dispatch(autorizzaRicaricaCartaSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getInfoDispositivaRicariCartaRequest = {
      idDispositiva,
      credenzialeDispositivaDto: credenziale
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaRicaricaCarta(getInfoDispositivaRicariCartaRequest).then(response => {
        dispatch(autorizzaRicaricaCartaSuccess(response));
        extraArguments.sendEventToMatomo('RICARICA_CIVIPAY', "esito dispositiva RICARICA_CIVIPAY ".concat(response.stato), 'ESITO_DISPOSITIVA');
      }).catch(async error => {
        dispatch((0, __1.setPaymentPollingActive)(false));
        const decodedError = await (0, __1.decodeError)(error);
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(decodedError, false, true), false, extraArguments.sendEventToMatomo));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaRicarica = autorizzaRicarica;
function getParametriMovimentiRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_MOVIMENTI_PARAMETRI_START
  };
}
function getParametriMovimentiRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_MOVIMENTI_PARAMETRI_SUCCESS,
    payload
  };
}
const getParametriMovimentiRicaricaCarta = function () {
  let loaderEnabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriMovimentiRicaricaCartaStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/parametri_bonifico.json')).then(parametri => {
        // dispatch(getParametriMovimentiRicaricaCartaSuccess(ParametriRicaricaCarteDtoFromJSON(parametri)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriMovimentiCartaRicaricabile().then(response => {
          dispatch(getParametriMovimentiRicaricaCartaSuccess(response));
        }).catch(async error => {
          //@ts-ignore
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getParametriMovimentiRicaricaCarta = getParametriMovimentiRicaricaCarta;
function getMovimentiRicaricaCartaStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_LISTA_MOVIMENTI_START
  };
}
;
function getMovimentiRicaricaCartaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_GET_LISTA_MOVIMENTI_SUCCESS,
    payload
  };
}
;
const getMovimentiRicaricaCarta = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getMovimentiRicaricaCartaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/nexiListaMovimentiCarta.json')).then(response => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const filtri = getState().ricaricaCarta.filtri;
    const requestParameters = {
      elencoMovimentiFilterDto: {
        pan: filtri === null || filtri === void 0 ? void 0 : filtri.pan,
        codiceRapporto: (filtri === null || filtri === void 0 ? void 0 : filtri.codiceRapporto) || 0,
        dataIniziale: moment(filtri.dataDa).toDate(),
        dataFinale: moment(filtri.dataA).toDate()
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).elencoMovimentiCartaRicaricabile(requestParameters).then(response => {
        dispatch(getMovimentiRicaricaCartaSuccess(response));
      }).catch(async error => {
        //@ts-ignore
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getMovimentiRicaricaCarta = getMovimentiRicaricaCarta;
function setRicaricaCartaSetDataA(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_DATA_A,
    payload
  };
}
exports.setRicaricaCartaSetDataA = setRicaricaCartaSetDataA;
function setRicaricaCartaSetDataDa(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_DATA_DA,
    payload
  };
}
exports.setRicaricaCartaSetDataDa = setRicaricaCartaSetDataDa;
function setRicaricaCartaSetPeriodo(txt) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_PERIODO,
    payload: txt
  };
}
exports.setRicaricaCartaSetPeriodo = setRicaricaCartaSetPeriodo;
function setRicaricaCartaSetCodiceRapporto(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_RAPPORTO_FILTRI,
    payload
  };
}
exports.setRicaricaCartaSetCodiceRapporto = setRicaricaCartaSetCodiceRapporto;
function setRicaricaCartaSetPanFiltri(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_PAN_FILTRI,
    payload
  };
}
exports.setRicaricaCartaSetPanFiltri = setRicaricaCartaSetPanFiltri;
function setRaicaricaCartaSetMovimentiRapportoSelezionato(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO_FILTRI,
    payload
  };
}
exports.setRaicaricaCartaSetMovimentiRapportoSelezionato = setRaicaricaCartaSetMovimentiRapportoSelezionato;
;
function esportaMovimentiRicaricaCartaPDFStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_ESPORTA_MOVIMENTI_START
  };
}
function esportaMovimentiRicaricaCartaPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_ESPORTA_MOVIMENTI_SUCCESS,
    payload
  };
}
const esportaMovimentiRicaricaCartaPDF = (share, title, message, type) => async (dispatch, getState, extraArguments) => {
  dispatch(esportaMovimentiRicaricaCartaPDFStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
      import('../../mock/lista_movimenti.json').then(parametri => {
        dispatch(esportaMovimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
      });
      */
  } else {
    const filtri = getState().ricaricaCarta.filtri;
    if (filtri) {
      const esportaMovimentiPDFRequest = {
        type: type || api_rest_1.ElencoMovimentiPdfCarteRicaricabiliTypeEnum.pdf,
        elencoMovimentiFilterDto: {
          pan: filtri.pan,
          codiceRapporto: filtri.codiceRapporto || 0,
          dataIniziale: moment(filtri.dataDa).toDate(),
          dataFinale: moment(filtri.dataA).toDate()
        }
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).elencoMovimentiPdfCarteRicaricabili(esportaMovimentiPDFRequest).then(response => {
          if (extraArguments.platform === 'desktop') {
            (0, __1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
          }
          if (extraArguments.platform === 'mobile') {
            (0, __1.download_sharePDF)(extraArguments, 'movimenti_ricarica_carta.pdf', response, share, title, message);
          }
          dispatch(esportaMovimentiRicaricaCartaPDFSuccess(response));
        }).catch(async error => {
          //@ts-ignore
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.esportaMovimentiRicaricaCartaPDF = esportaMovimentiRicaricaCartaPDF;
function esportaDettaglioMovimentoRicaricaCartaPDFStart() {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_START
  };
}
function esportaDettaglioMovimentoRicaricaCartaPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_SUCCESS,
    payload
  };
}
const esportaDettaglioMovimentoRicaricaCartaPDF = (movimento, share, title, message) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch((0, general_actions_1.setWaitingSection)(true, "PDF".concat(movimento.descrizione)));
  dispatch(esportaDettaglioMovimentoRicaricaCartaPDFStart());
  const filtri = getState().ricaricaCarta.filtri;
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaDettaglioMovimentoPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const esportaDettaglioMovimentoPDFRequest = {
      exportMovimentoCartaRicaricabileInDto: {
        movimento: movimento,
        codiceRapporto: filtri.codiceRapporto || 0
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CarteRicaricabiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioMovimentiPdfCarteRicaricabili(esportaDettaglioMovimentoPDFRequest).then(async response => {
        if (extraArguments.platform === 'desktop') {
          (0, __1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, __1.download_sharePDF)(extraArguments, "movimento-".concat(movimento.descrizione, ".pdf"), response, share, title, message);
        }
        dispatch(esportaDettaglioMovimentoRicaricaCartaPDFSuccess(response));
      }).catch(async error => {
        try {
          dispatch(
          //@ts-ignore
          generalError(await (0, helpers_1.formatError)(error)));
        } catch (e) {
          console.log(e);
        }
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
        dispatch((0, general_actions_1.setWaitingSection)(false, "PDF".concat(movimento.descrizione)));
      });
    });
  }
};
exports.esportaDettaglioMovimentoRicaricaCartaPDF = esportaDettaglioMovimentoRicaricaCartaPDF;