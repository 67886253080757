"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriMovimentiDtoToJSON = exports.ParametriMovimentiDtoFromJSONTyped = exports.ParametriMovimentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriMovimentiDtoFromJSON(json) {
  return ParametriMovimentiDtoFromJSONTyped(json, false);
}
exports.ParametriMovimentiDtoFromJSON = ParametriMovimentiDtoFromJSON;
function ParametriMovimentiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'tagsAndCategories': !runtime_1.exists(json, 'tagsAndCategories') ? undefined : _1.PfmTagsAndCategoriesDtoFromJSON(json['tagsAndCategories']),
    'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : new Date(json['dataIniziale']),
    'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : new Date(json['dataFinale']),
    'chiaviNLP': !runtime_1.exists(json, 'chiaviNLP') ? undefined : json['chiaviNLP']
  };
}
exports.ParametriMovimentiDtoFromJSONTyped = ParametriMovimentiDtoFromJSONTyped;
function ParametriMovimentiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri,
    'tagsAndCategories': _1.PfmTagsAndCategoriesDtoToJSON(value.tagsAndCategories),
    'dataIniziale': value.dataIniziale === undefined ? undefined : value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale === undefined ? undefined : value.dataFinale.toISOString(),
    'chiaviNLP': value.chiaviNLP
  };
}
exports.ParametriMovimentiDtoToJSON = ParametriMovimentiDtoToJSON;