"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaglioRicaricaCellulareDtoToJSON = exports.TaglioRicaricaCellulareDtoFromJSONTyped = exports.TaglioRicaricaCellulareDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TaglioRicaricaCellulareDtoFromJSON(json) {
  return TaglioRicaricaCellulareDtoFromJSONTyped(json, false);
}
exports.TaglioRicaricaCellulareDtoFromJSON = TaglioRicaricaCellulareDtoFromJSON;
function TaglioRicaricaCellulareDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'commissioniOperazione': !runtime_1.exists(json, 'commissioniOperazione') ? undefined : json['commissioniOperazione'],
    'commissioniServizio': !runtime_1.exists(json, 'commissioniServizio') ? undefined : json['commissioniServizio'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'totaleAddebito': !runtime_1.exists(json, 'totaleAddebito') ? undefined : json['totaleAddebito']
  };
}
exports.TaglioRicaricaCellulareDtoFromJSONTyped = TaglioRicaricaCellulareDtoFromJSONTyped;
function TaglioRicaricaCellulareDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'commissioniOperazione': value.commissioniOperazione,
    'commissioniServizio': value.commissioniServizio,
    'divisa': value.divisa,
    'importo': value.importo,
    'totaleAddebito': value.totaleAddebito
  };
}
exports.TaglioRicaricaCellulareDtoToJSON = TaglioRicaricaCellulareDtoToJSON;