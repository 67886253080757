"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formModificaRipetitivoValid = exports.esitoRiattivaBonificoRipetitivo = exports.infoRiattivaBonificoRipetitivo = exports.formRiattivaBonificoRipetitivo = exports.riepilogoRiattivaBonificoRipetitivo = exports.esitoSospendiBonificoRipetitivo = exports.infoSospendiBonificoRipetitivo = exports.formSospendiBonificoRipetitivo = exports.riepilogoSospendiBonificoRipetitivo = exports.checkUpdateBozzaBonificoRipetitivo = exports.formValidForBozzaBonificoRipetitivo = exports.formElencoBonificiRipetitiviFiltriApplicati = exports.formElencoBonificiRipetitiviValid = exports.formElencoBonificiRipetitiviStati = exports.formElencoBonificiRipetitiviAsFiltro = exports.pagamentoRipetitivoSelezionato = exports.formElencoBonificiRipetitivi = exports.dettaglioBonificoRipetitivo = exports.elencoBonificiRipetitiviConTotale = exports.elencoBonificiRipetitivi = exports.currentBozzaBonificoRipetitivo = exports.bozzeBonificoRipetitivo = exports.esitoRevocaBonificoRipetitivo = exports.infoRevocaBonificoRipetitivo = exports.formRevocaBonificoRipetitivo = exports.riepilogoRevocaBonificoRipetitivo = exports.esitoBonificoRipetitivo = exports.infoDispositivaBonificoRipetitivo = exports.riepilogoBonificoRipetitivo = exports.disableSalvaBozzaBonificoRipetitivo = exports.formRipetitivoValid = exports.buildModificaBonificoRipetitivo = exports.buildBonificoRipetitivo = exports.formBonificoRipetitivo = exports.periodiBonificoRipetitivo = exports.parametriElencoBonificiRipetitivi = exports.parametriBonificoRipetitivo = exports.OrdinePeriodo = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const bonificoripetitivo_types_1 = require("./bonificoripetitivo.types");
exports.OrdinePeriodo = {
  AN: 9,
  BI: 5,
  DE: 2,
  ME: 4,
  Q1: 7,
  QU: 3,
  S1: 8,
  SE: 1,
  TR: 6
};
const IMPORTO_MOLTIPLICATORI_INTERVALLO = {
  MIN: 0.8,
  MAX: 1.2
};
const STATI_SELEZIONABILI = ['A', 'S', 'R', 'C'];
const bonificoRipetitivoReducer = state => state;
exports.parametriBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.parametri;
});
exports.parametriElencoBonificiRipetitivi = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.parametriElenco;
});
exports.periodiBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a;
  return (Object.keys(((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.periodo) || {}) || []).sort((a, b) => exports.OrdinePeriodo[a] < exports.OrdinePeriodo[b] ? -1 : 1);
});
exports.formBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.form;
});
exports.buildBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
  let primaRata = undefined;
  if (state.form.periodo.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato && state.form.tipoImporto.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Variabile) {
    const currentMonth = moment().month() + 1;
    primaRata = state.form.rate.filter(r => !!r.selected).find(r => r.mese === currentMonth);
  }
  return {
    descrizioneCausale: state.form.descrizione,
    ordinante: {
      contoAddebito: (_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id,
      codiceIban: (_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.rapporto) === null || _d === void 0 ? void 0 : _d.iban,
      anagrafica: (_f = (_e = state.form.rapportoSelezionato) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.intestazione
    },
    dataInserimento: new Date(),
    beneficiario: {
      codiceIban: state.form.iban.toUpperCase(),
      anagrafica: state.form.beneficiario
    },
    disposizione: {
      tipoImporto: state.form.tipoImporto.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Fisso ? bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Fisso : bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Variabile,
      importoBase: (0, helpers_1.formattedNumberToNumber)(state.form.periodo.value !== bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato || state.form.tipoImporto.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Fisso ? state.form.importo : (_h = (_g = state.form.rate) === null || _g === void 0 ? void 0 : _g.find(r => !!r.importo)) === null || _h === void 0 ? void 0 : _h.importo),
      tipoPeriodo: state.form.periodo.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato ? bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato : bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Fisso,
      periodo: state.form.periodo.value != bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato ? api_rest_1.DisposizioneDtoPeriodoEnum[state.form.periodo.value] : api_rest_1.DisposizioneDtoPeriodoEnum.ME,
      motivazioneOrdine: state.form.descrizione,
      tipoData: ((_j = state.form.tipoDataPagamento) === null || _j === void 0 ? void 0 : _j.value) ? api_rest_1.DisposizioneDtoTipoDataEnum[state.form.tipoDataPagamento.value] : undefined,
      fineMese: state.form.fineMese,
      giornoSelezionato: state.form.periodo.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato && state.form.tipoImporto.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Variabile && primaRata ? primaRata.giorno : ((_k = state.form.giorno) === null || _k === void 0 ? void 0 : _k.value) ? Number(state.form.giorno.value) : ((_l = state.form.dataProssimoPagamento) === null || _l === void 0 ? void 0 : _l.value) ? moment(state.form.dataProssimoPagamento.value).date() : undefined,
      meseSelezionato: state.form.periodo.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato && state.form.tipoImporto.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Variabile && primaRata ? primaRata.mese : ((_m = state.form.dataProssimoPagamento) === null || _m === void 0 ? void 0 : _m.value) ? moment(state.form.dataProssimoPagamento.value).month() + 1 : undefined,
      annoSelezionato: state.form.periodo.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato && state.form.tipoImporto.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Variabile && primaRata ? primaRata.annoRiferimento : ((_o = state.form.dataProssimoPagamento) === null || _o === void 0 ? void 0 : _o.value) ? moment(state.form.dataProssimoPagamento.value).year() : undefined,
      tipoDisposizione: ((_p = state.form.tipoDisposizione) === null || _p === void 0 ? void 0 : _p.value) ? api_rest_1.DisposizioneDtoTipoDisposizioneEnum[state.form.tipoDisposizione.value] : bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.FinoA.Revoca,
      dataFineDisposizione: state.form.dataScadenza.value && state.form.tipoDisposizione.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.FinoA.Scadenza ? moment(state.form.dataScadenza.value).toDate() : undefined,
      numeroRateTotali: state.form.tipoDisposizione.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.FinoA.NumeroRate ? Number(state.form.numeroRate.value) : undefined,
      dataPrimaRata: state.form.periodo.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato ? undefined : moment(state.form.dataProssimoPagamento.value).toDate(),
      tabellaRate: state.form.periodo.value !== bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato ? undefined : state.form.rate.filter(r => !!r.selected).map(r => ({
        giorno: r.selected ? r.giorno : undefined,
        mese: r.selected ? r.mese : undefined,
        importo: r.selected ? state.form.periodo.value != bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato || state.form.tipoImporto.value == bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Fisso ? (0, helpers_1.formattedNumberToNumber)(state.form.importo) : (0, helpers_1.formattedNumberToNumber)(r.importo) : undefined
      }))
    },
    idBozzaCollegata: state.currentBozza.id,
    idContattoBeneficiario: state.form.idContattoBeneficiario,
    aggiungiRubrica: state.form.addRubrica,
    aggiungiWhitelist: state.form.addWhiteList
  };
});
exports.buildModificaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a, _b;
  return {
    importo: state.form.periodo.value === 'V' ? undefined : (0, helpers_1.formattedNumberToNumber)(state.form.importo),
    codiceIban: state.form.iban,
    codiceRapporto: (_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id,
    causale: state.form.descrizione,
    id: state.form.id,
    codiceDisposizione: state.form.codiceDisposizione,
    dataFineDisposizione: state.form.dataScadenza.value && state.form.tipoDisposizione.value === api_rest_1.DisposizioneDtoTipoDisposizioneEnum.S ? moment(state.form.dataScadenza.value).toDate() : undefined,
    tabellaRate: state.form.periodo.value != bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato ? undefined : state.form.rate.map(r => {
      return {
        giorno: r.selected ? r.giorno : undefined,
        mese: r.selected ? r.mese : undefined,
        importo: r.selected ? (0, helpers_1.formattedNumberToNumber)(r.importo) : undefined
      };
    })
  };
});
exports.formRipetitivoValid = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  const mainError = !!state.form.beneficiarioError || !!state.form.ibanError || !!state.form.dataEsecuzioneError || !!state.form.descrizioneError || !!state.form.tipoImporto.error || !!state.form.tipoDataPagamento.error || !!state.form.tipoDisposizione.error || !!state.form.periodo.error;
  const importoError = state.form.tipoImporto.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Fisso ? !!state.form.importoError : false;
  const dataScadenzaError = state.form.tipoDisposizione.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.FinoA.Scadenza ? !!state.form.dataScadenza.error : false;
  const numeroRateError = state.form.tipoDisposizione.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.FinoA.NumeroRate ? !!state.form.numeroRate.error : false;
  const giornoError = state.form.tipoImporto.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Fisso && state.form.periodo.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato && !state.form.fineMese ? !!state.form.giorno.error : false;
  const rateError = state.form.periodo.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato ? !state.form.rate.some(r => r.selected) || !!state.form.rateError : false;
  const rataError = state.form.periodo.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.TipoPeriodo.Personalizzato && state.form.tipoImporto.value === bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni.ModalitaImporto.Variabile ? state.form.rate.some(r => r.selected && (r.importoError || r.giornoError)) : false;
  const thereIsAtLeastOneError = mainError || importoError || dataScadenzaError || numeroRateError || giornoError || rateError || rataError;
  return !thereIsAtLeastOneError;
});
exports.disableSalvaBozzaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  let isBozzaAccepted = !state.form.bozzaSalvata && (!!state.form.iban || !!state.form.descrizione || !!state.form.beneficiario);
  isBozzaAccepted = isBozzaAccepted && !!state.form.iban ? !state.form.ibanError : isBozzaAccepted;
  isBozzaAccepted = isBozzaAccepted && !!state.form.descrizione ? !state.form.descrizioneError : isBozzaAccepted;
  isBozzaAccepted = isBozzaAccepted && !!state.form.beneficiario ? !state.form.beneficiarioError : isBozzaAccepted;
  return !isBozzaAccepted;
});
exports.riepilogoBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.riepilogo;
});
exports.infoDispositivaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state === null || state === void 0 ? void 0 : state.infoDispositiva;
});
exports.esitoBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.esito;
});
exports.riepilogoRevocaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.riepilogoRevoca;
});
exports.formRevocaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.formRevoca;
});
exports.infoRevocaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.infoRevoca;
});
exports.esitoRevocaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.esitoRevoca;
});
exports.bozzeBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.bozzeBonifico && state.bozzeBonifico.ripetitivi ? state.bozzeBonifico.ripetitivi.sort((a, b) => b.id - a.id) : [];
});
exports.currentBozzaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.currentBozza;
});
exports.elencoBonificiRipetitivi = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a;
  return ((_a = state.elenco) === null || _a === void 0 ? void 0 : _a.ripetitivi) || [];
});
exports.elencoBonificiRipetitiviConTotale = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.elenco;
});
exports.dettaglioBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.dettaglioBonificoRipetitivo;
});
exports.formElencoBonificiRipetitivi = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.formElenco;
});
exports.pagamentoRipetitivoSelezionato = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.pagamentoRipetitivoSelezionato;
});
exports.formElencoBonificiRipetitiviAsFiltro = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a, _b;
  let dataDa = moment(String(((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b['DEFAULT.BONIFICORIPETITIVO.DATA_INIZIALE']) || undefined)).toDate();
  let dataA = moment().toDate();
  if (state.formElenco.dataDa.value) {
    dataDa = moment(state.formElenco.dataDa.value).toDate();
  }
  if (state.formElenco.dataA.value) {
    dataA = moment(state.formElenco.dataA.value).toDate();
  }
  return {
    importoDa: (0, helpers_1.formattedNumberToNumber)(state.formElenco.importo.value) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MIN || undefined,
    importoA: (0, helpers_1.formattedNumberToNumber)(state.formElenco.importo.value) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MAX || undefined,
    dataDa: dataDa,
    dataA: dataA,
    beneficiario: state.formElenco.beneficiario.value,
    causale: state.formElenco.causale.value,
    elencoStato: state.formElenco.stati.join(',')
  };
});
exports.formElencoBonificiRipetitiviStati = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a;
  const stati = {
    ...(((_a = state.parametriElenco) === null || _a === void 0 ? void 0 : _a.stati) || {})
  };
  return Object.keys(stati).filter(s => STATI_SELEZIONABILI.some(ss => ss === s));
});
exports.formElencoBonificiRipetitiviValid = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  let formValid = false;
  if (!state.formElenco.beneficiario.error && !state.formElenco.causale.error && !state.formElenco.importo.error) {
    formValid = true;
  }
  return formValid;
});
exports.formElencoBonificiRipetitiviFiltriApplicati = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a, _b, _c, _d;
  let r = false;
  if (!!state.formElenco.beneficiario.value || !!state.formElenco.causale.value || !!state.formElenco.importo.value || !!state.formElenco.stati && state.formElenco.stati.length > 0 || state.formElenco.dataDa.value !== ((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b["DEFAULT.BONIFICORIPETITIVO.DATA_INIZIALE"].toString()) || state.formElenco.dataA.value !== ((_d = (_c = state.parametri) === null || _c === void 0 ? void 0 : _c.parametri) === null || _d === void 0 ? void 0 : _d["DEFAULT.BONIFICORIPETITIVO.DATA_FINALE"].toString())) {
    r = true;
  }
  return r;
});
// TODO: Check which fields are mandatory for salva come bozza
exports.formValidForBozzaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  let formValid = false;
  if (!state.form.beneficiarioError || !state.form.ibanError || !state.form.descrizioneError) {
    formValid = true;
  }
  return formValid;
});
exports.checkUpdateBozzaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  var _a;
  return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
exports.riepilogoSospendiBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.riepilogoSospendi;
});
exports.formSospendiBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.formSospendi;
});
exports.infoSospendiBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.infoSospendi;
});
exports.esitoSospendiBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.esitoSospendi;
});
exports.riepilogoRiattivaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.riepilogoRiattiva;
});
exports.formRiattivaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.formRiattiva;
});
exports.infoRiattivaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.infoRiattiva;
});
exports.esitoRiattivaBonificoRipetitivo = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  return state.esitoRiattiva;
});
exports.formModificaRipetitivoValid = (0, reselect_1.createSelector)(bonificoRipetitivoReducer, state => {
  let formValid = false;
  if (!state.form.beneficiarioError && !state.form.ibanError && !state.form.descrizioneError) {
    formValid = true;
  }
  return formValid;
});