"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRevocaRavDtoFromJSON = exports.FormRavDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormRavDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
    'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
    'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
    'codiceRAV': !(0, helpers_1.exists)(json, 'codiceRAV') ? undefined : json.codiceRAV,
    'codiceRAVDirty': !(0, helpers_1.exists)(json, 'codiceRAVDirty') ? false : json.codiceRAVDirty,
    'codiceRAVError': !(0, helpers_1.exists)(json, 'codiceRAVError') ? 'invalid.required' : json.codiceRAVError,
    'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
    'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
    'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti
  };
}
exports.FormRavDtoFromJSONTyped = FormRavDtoFromJSONTyped;
function FormRevocaRavDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaRavDtoFromJSON = FormRevocaRavDtoFromJSON;