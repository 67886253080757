"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExportMovimentoCartaRicaricabileInDtoToJSON = exports.ExportMovimentoCartaRicaricabileInDtoFromJSONTyped = exports.ExportMovimentoCartaRicaricabileInDtoFromJSON = void 0;
const _1 = require("./");
function ExportMovimentoCartaRicaricabileInDtoFromJSON(json) {
  return ExportMovimentoCartaRicaricabileInDtoFromJSONTyped(json, false);
}
exports.ExportMovimentoCartaRicaricabileInDtoFromJSON = ExportMovimentoCartaRicaricabileInDtoFromJSON;
function ExportMovimentoCartaRicaricabileInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'movimento': _1.MovimentoCarteRicaricabileDtoFromJSON(json['movimento']),
    'codiceRapporto': json['codiceRapporto']
  };
}
exports.ExportMovimentoCartaRicaricabileInDtoFromJSONTyped = ExportMovimentoCartaRicaricabileInDtoFromJSONTyped;
function ExportMovimentoCartaRicaricabileInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'movimento': _1.MovimentoCarteRicaricabileDtoToJSON(value.movimento),
    'codiceRapporto': value.codiceRapporto
  };
}
exports.ExportMovimentoCartaRicaricabileInDtoToJSON = ExportMovimentoCartaRicaricabileInDtoToJSON;