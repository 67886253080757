"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AspspOutDtoToJSON = exports.AspspOutDtoFromJSONTyped = exports.AspspOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AspspOutDtoFromJSON(json) {
  return AspspOutDtoFromJSONTyped(json, false);
}
exports.AspspOutDtoFromJSON = AspspOutDtoFromJSON;
function AspspOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'availables': !runtime_1.exists(json, 'availables') ? undefined : json['availables'].map(_1.ASPSPDtoFromJSON),
    'enrolled': !runtime_1.exists(json, 'enrolled') ? undefined : json['enrolled'].map(_1.ASPSPDtoFromJSON)
  };
}
exports.AspspOutDtoFromJSONTyped = AspspOutDtoFromJSONTyped;
function AspspOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'availables': value.availables === undefined ? undefined : value.availables.map(_1.ASPSPDtoToJSON),
    'enrolled': value.enrolled === undefined ? undefined : value.enrolled.map(_1.ASPSPDtoToJSON)
  };
}
exports.AspspOutDtoToJSON = AspspOutDtoToJSON;