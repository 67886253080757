"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListaServiziOperativiOutputDtoToJSON = exports.ListaServiziOperativiOutputDtoFromJSONTyped = exports.ListaServiziOperativiOutputDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ListaServiziOperativiOutputDtoFromJSON(json) {
  return ListaServiziOperativiOutputDtoFromJSONTyped(json, false);
}
exports.ListaServiziOperativiOutputDtoFromJSON = ListaServiziOperativiOutputDtoFromJSON;
function ListaServiziOperativiOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'returnCode': !runtime_1.exists(json, 'returnCode') ? undefined : json['returnCode'],
    'returnMessage': !runtime_1.exists(json, 'returnMessage') ? undefined : json['returnMessage'],
    'serviziOperativi': !runtime_1.exists(json, 'serviziOperativi') ? undefined : json['serviziOperativi'].map(_1.ServizioOperativoOutputDtoFromJSON)
  };
}
exports.ListaServiziOperativiOutputDtoFromJSONTyped = ListaServiziOperativiOutputDtoFromJSONTyped;
function ListaServiziOperativiOutputDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'returnCode': value.returnCode,
    'returnMessage': value.returnMessage,
    'serviziOperativi': value.serviziOperativi === undefined ? undefined : value.serviziOperativi.map(_1.ServizioOperativoOutputDtoToJSON)
  };
}
exports.ListaServiziOperativiOutputDtoToJSON = ListaServiziOperativiOutputDtoToJSON;