"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.massimaleFirma = exports.webViewLinkControlloBonificoSepa = exports.checkUpdateBozzaBonificoDF = exports.checkUpdateBozzaBonificoSepa = exports.checkUpdateBozzaSmartCard = exports.currentBozzaBonifico = exports.bozzeBonifico = exports.esitoRevocaBonificoSepa = exports.infoRevocaBonificoSepa = exports.formRevocaBonificoSepa = exports.riepilogoRevocaBonificoSepa = exports.oneclickBonificoSepa = exports.esitoBonificoSepa = exports.infoDispositivaBonificoSepa = exports.riepilogoBonificoSepa = exports.disableSalvaBozzaFiscale = exports.disableSalvaBozza = exports.formSmartCardValid = exports.formDFValid = exports.formValid1Click = exports.formValid = exports.buildSmartCard = exports.buildBonificoDF = exports.buildBonificoSepa = exports.formBonificoSepa = exports.motivazioniPagamento = exports.parametriBonificoSepa = void 0;
const moment = require("moment");
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const bonificoSepaReducer = state => state;
exports.parametriBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.parametri;
});
exports.motivazioniPagamento = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.parametri && state.parametri.motivazioniPagamento && state.parametri.motivazioniPagamento;
});
exports.formBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.form;
});
exports.buildBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
  let psd2 = undefined;
  if ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.psd2) {
    psd2 = {
      resourceId: (_g = (_f = (_e = (0, helpers_1.findInArrayRapporti)((_c = state.parametri) === null || _c === void 0 ? void 0 : _c.rapporti, (_d = state.form.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.rapporto.id)) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.psd2) === null || _g === void 0 ? void 0 : _g.resourceId,
      aspspId: state.form.rapportoSelezionato.rapporto.psd2.aspspId,
      providerId: state.form.rapportoSelezionato.rapporto.psd2.providerId,
      psuId: state.form.usernamePSD2 || state.form.rapportoSelezionato.rapporto.psd2.psuId,
      intestatario: (_k = (_j = (_h = state.form.rapportoSelezionato) === null || _h === void 0 ? void 0 : _h.rapporto) === null || _j === void 0 ? void 0 : _j.intestazione) !== null && _k !== void 0 ? _k : '',
      aspspProductCode: state.form.rapportoSelezionato.rapporto.psd2.aspspProductCode,
      aspspBusinessName: state.form.rapportoSelezionato.rapporto.nomeBanca,
      aspspProductTypes: state.form.rapportoSelezionato.rapporto.psd2.aspspProductTypes,
      aspspProductSuggestedLabel: state.form.rapportoSelezionato.rapporto.psd2.aspspProductSuggestedLabel
    };
  }
  return {
    id: (_l = state.currentBozza) === null || _l === void 0 ? void 0 : _l.id,
    causale: state.form.descrizione,
    contoAddebito: ((_o = (_m = state.form.rapportoSelezionato) === null || _m === void 0 ? void 0 : _m.rapporto) === null || _o === void 0 ? void 0 : _o.id) || 0,
    contoAddebitoIban: ((_q = (_p = state.form.rapportoSelezionato) === null || _p === void 0 ? void 0 : _p.rapporto) === null || _q === void 0 ? void 0 : _q.iban) || '',
    ordinanteIntestazione: (_t = (_s = (_r = state.form.rapportoSelezionato) === null || _r === void 0 ? void 0 : _r.rapporto) === null || _s === void 0 ? void 0 : _s.intestazione) !== null && _t !== void 0 ? _t : '',
    dataEsecuzione: new Date(state.form.dataEsecuzione),
    ibanBeneficiario: state.form.iban.toUpperCase(),
    importo: (0, helpers_1.formattedNumberToNumber)(state.form.importo),
    motivazionePagamento: 'ORDI',
    naturaMovimento: state.form.istantaneo ? 'SCI' : 'SCT',
    nomeBeneficiario: state.form.beneficiario,
    datiEsecuzioneBonifico: {
      urgente: false
    },
    dettOrdi: {
      intestazione: psd2 ? undefined : state.form.ordinanteEffettivo
    },
    idBozzaCollegata: psd2 ? undefined : state.currentBozza.id,
    aggiungiRubrica: psd2 ? undefined : state.form.addRubrica,
    aggiungiWhitelist: psd2 ? undefined : state.form.addWhiteList,
    aggiungi1click: psd2 ? undefined : state.form.salva1Click,
    alias1Click: psd2 ? undefined : state.form.alias1Click,
    idContattoBeneficiario: psd2 ? undefined : state.form.idContattoBeneficiario,
    idOneClickCollegata: psd2 ? undefined : state.form.idOneClickCollegata,
    psd2PaymentDto: psd2
  };
});
exports.buildBonificoDF = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  var _a, _b, _c, _d, _e;
  return {
    id: (_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id,
    causale: state.form.descrizione,
    contoAddebito: ((_c = (_b = state.form.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
    contoAddebitoIban: ((_e = (_d = state.form.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.rapporto) === null || _e === void 0 ? void 0 : _e.iban) || '',
    dataEsecuzione: moment(state.form.dataEsecuzione).toDate(),
    ibanBeneficiario: state.form.iban.toUpperCase(),
    importo: (0, helpers_1.formattedNumberToNumber)(state.form.importo),
    motivazionePagamento: state.form.motivazionePagamento,
    naturaMovimento: 'SCT',
    nomeBeneficiario: state.form.beneficiario,
    datiFiscali: {
      beneficiario: state.form.codiceFiscalePIVABeneficiario,
      amministratore: state.form.codiceFiscalePIVAAmministratore,
      ordinanti: state.form.codiceFiscalePIVAOrdinanti
    },
    datiEsecuzioneBonifico: {
      urgente: false
    },
    idBozzaCollegata: state.currentBozza.id,
    aggiungiRubrica: state.form.addRubrica,
    aggiungiWhitelist: state.form.addWhiteList,
    idContattoBeneficiario: state.form.idContattoBeneficiario
  };
});
exports.buildSmartCard = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  var _a, _b, _c, _d, _e, _f, _g;
  return {
    causale: state.form.descrizione,
    contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
    contoAddebitoIban: ((_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.rapporto) === null || _d === void 0 ? void 0 : _d.iban) || '',
    ordinanteIntestazione: (_g = (_f = (_e = state.form.rapportoSelezionato) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.intestazione) !== null && _g !== void 0 ? _g : '',
    dataEsecuzione: moment().toDate(),
    ibanBeneficiario: state.form.iban.toUpperCase(),
    importo: (0, helpers_1.formattedNumberToNumber)(state.form.importo),
    motivazionePagamento: 'RICA',
    naturaMovimento: 'SCT',
    nomeBeneficiario: state.form.beneficiario,
    datiEsecuzioneBonifico: {
      urgente: false
    },
    idBozzaCollegata: state.currentBozza.id,
    aggiungiRubrica: state.form.addRubrica,
    aggiungiWhitelist: state.form.addWhiteList,
    aggiungi1click: state.form.salva1Click,
    alias1Click: state.form.alias1Click,
    idContattoBeneficiario: state.form.idContattoBeneficiario,
    idOneClickCollegata: state.form.idOneClickCollegata
  };
});
exports.formValid = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  let formValid = false;
  if (!state.form.beneficiarioError && !state.form.ibanError && !state.form.importoError && !state.form.dataEsecuzioneError && !state.form.descrizioneError && !state.form.aliasError) {
    formValid = true;
  }
  return formValid;
});
exports.formValid1Click = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  let formValid = false;
  if (!state.form.beneficiarioError && !state.form.ibanError && !state.form.importoError && !state.form.descrizioneError) {
    formValid = true;
  }
  return formValid;
});
exports.formDFValid = (0, reselect_1.createSelector)(bonificoSepaReducer, state => !state.form.beneficiarioError && !state.form.ibanError && !state.form.importoError && !state.form.dataEsecuzioneError && !state.form.descrizioneError && !state.form.aliasError && !state.form.codiceFiscalePIVAOrdinantiError.some(o => o) && !state.form.codiceFiscalePIVABeneficiarioError && !state.form.motivazionePagamentoError);
exports.formSmartCardValid = (0, reselect_1.createSelector)(bonificoSepaReducer, state => !state.form.beneficiarioError && !state.form.ibanError && !state.form.importoError && !state.form.descrizioneError && !state.form.aliasError);
exports.disableSalvaBozza = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  let isBozzaAccepted = !state.form.bozzaSalvata && (!!state.form.iban || !!state.form.descrizione || !!state.form.importo || !!state.form.beneficiario);
  isBozzaAccepted = isBozzaAccepted && !!state.form.iban ? !state.form.ibanError : isBozzaAccepted;
  isBozzaAccepted = isBozzaAccepted && !!state.form.descrizione ? !state.form.descrizioneError : isBozzaAccepted;
  isBozzaAccepted = isBozzaAccepted && !!state.form.importo ? !state.form.importoError : isBozzaAccepted;
  isBozzaAccepted = isBozzaAccepted && !!state.form.beneficiario ? !state.form.beneficiarioError : isBozzaAccepted;
  return !isBozzaAccepted;
});
exports.disableSalvaBozzaFiscale = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return !(!state.form.bozzaSalvata && state.form.motivazionePagamento && !state.form.motivazionePagamentoError && (state.form.iban && !state.form.ibanError || state.form.descrizione && !state.form.descrizioneError || state.form.importo && !state.form.importoError || state.form.beneficiario && !state.form.beneficiarioError));
});
exports.riepilogoBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.riepilogo;
});
exports.infoDispositivaBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.infoDispositiva;
});
exports.esitoBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.esito;
});
exports.oneclickBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.oneclick;
});
exports.riepilogoRevocaBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.riepilogoRevoca;
});
exports.formRevocaBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.formRevoca;
});
exports.infoRevocaBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.infoRevoca;
});
exports.esitoRevocaBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.esitoRevoca;
});
exports.bozzeBonifico = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.bozzeBonifico && state.bozzeBonifico.bonifici ? state.bozzeBonifico.bonifici.sort((a, b) => b.id - a.id) : [];
});
exports.currentBozzaBonifico = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.currentBozza;
});
exports.checkUpdateBozzaSmartCard = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j;
  let check = false;
  if (((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id) && (((_b = state.form) === null || _b === void 0 ? void 0 : _b.beneficiario) !== ((_c = state.currentBozza) === null || _c === void 0 ? void 0 : _c.nomeBeneficiario) || ((_d = state.form) === null || _d === void 0 ? void 0 : _d.iban) !== ((_e = state.currentBozza) === null || _e === void 0 ? void 0 : _e.ibanBeneficiario) || ((_f = state.form) === null || _f === void 0 ? void 0 : _f.descrizione) !== ((_g = state.currentBozza) === null || _g === void 0 ? void 0 : _g.causale) || ((_h = state.form) === null || _h === void 0 ? void 0 : _h.importo) !== ((_j = state.currentBozza) === null || _j === void 0 ? void 0 : _j.importo))) {
    check = true;
  }
  return check;
});
exports.checkUpdateBozzaBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  var _a;
  return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
exports.checkUpdateBozzaBonificoDF = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  var _a;
  return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
exports.webViewLinkControlloBonificoSepa = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  return state.webViewLinkControllo;
});
exports.massimaleFirma = (0, reselect_1.createSelector)(bonificoSepaReducer, state => {
  var _a;
  return !!((_a = state.massimaleFirma) === null || _a === void 0 ? void 0 : _a.firma);
});