"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MercatoDtoToJSON = exports.MercatoDtoFromJSONTyped = exports.MercatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function MercatoDtoFromJSON(json) {
  return MercatoDtoFromJSONTyped(json, false);
}
exports.MercatoDtoFromJSON = MercatoDtoFromJSON;
function MercatoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'desc': !runtime_1.exists(json, 'desc') ? undefined : json['desc'],
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice']
  };
}
exports.MercatoDtoFromJSONTyped = MercatoDtoFromJSONTyped;
function MercatoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'desc': value.desc,
    'codice': value.codice
  };
}
exports.MercatoDtoToJSON = MercatoDtoToJSON;