"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRevocaRicaricaCellulareDtoFromJSON = exports.FormRicaricaCellulareDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormRicaricaCellulareDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'taglioRicarica': !(0, helpers_1.exists)(json, 'taglioRicarica') ? undefined : json.taglioRicarica,
    'taglioRicaricaDirty': !(0, helpers_1.exists)(json, 'taglioRicaricaDirty') ? false : json.taglioRicaricaDirty,
    'taglioRicaricaError': !(0, helpers_1.exists)(json, 'taglioRicaricaError') ? 'invalid.required' : json.taglioRicaricaError,
    'numeroCellulare': !(0, helpers_1.exists)(json, 'numeroCellulare') ? undefined : json.numeroCellulare,
    'numeroCellulareDirty': !(0, helpers_1.exists)(json, 'numeroCellulareDirty') ? false : json.numeroCellulareDirty,
    'numeroCellulareError': !(0, helpers_1.exists)(json, 'numeroCellulareError') ? 'invalid.required' : json.numeroCellulareError,
    'nomeGestore': !(0, helpers_1.exists)(json, 'nomeGestore') ? undefined : json.nomeGestore,
    'nomeGestoreDirty': !(0, helpers_1.exists)(json, 'nomeGestoreDirty') ? false : json.nomeGestoreDirty,
    'nomeGestoreError': !(0, helpers_1.exists)(json, 'nomeGestoreError') ? 'invalid.required' : json.nomeGestoreError,
    'codiceSia': !(0, helpers_1.exists)(json, 'codiceSia') ? undefined : json.codiceSia,
    'codiceSiaDirty': !(0, helpers_1.exists)(json, 'codiceSiaDirty') ? false : json.codiceSiaDirty,
    'codiceSiaError': !(0, helpers_1.exists)(json, 'codiceSiaError') ? 'invalid.required' : json.codiceSiaError,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'beneficiarioAffidabile': !(0, helpers_1.exists)(json, 'beneficiarioAffidabile') ? false : json.beneficiarioAffidabile,
    'aggiungiBeneficiarioRubrica': !(0, helpers_1.exists)(json, 'aggiungiBeneficiarioRubrica') ? false : json.aggiungiBeneficiarioRubrica,
    'salva_1Click': !(0, helpers_1.exists)(json, 'salva_1Click') ? false : json.salva_1Click,
    'nomeBeneficiario': !(0, helpers_1.exists)(json, 'nomeBeneficiario') ? undefined : json.nomeBeneficiario,
    'nomeBeneficiarioDirty': !(0, helpers_1.exists)(json, 'nomeBeneficiarioDirty') ? false : json.nomeBeneficiarioDirty,
    'nomeBeneficiarioError': !(0, helpers_1.exists)(json, 'nomeBeneficiarioError') ? 'invalid.required' : json.nomeBeneficiarioError,
    'idContattoBeneficiario': !(0, helpers_1.exists)(json, 'idContattoBeneficiario') ? undefined : json.idContattoBeneficiario,
    'alias_1Click': !(0, helpers_1.exists)(json, 'alias_1Click') ? undefined : json.alias_1Click,
    'alias_1ClickDirty': !(0, helpers_1.exists)(json, 'alias_1ClickDirty') ? false : json.alias_1ClickDirty,
    'alias_1ClickError': !(0, helpers_1.exists)(json, 'alias_1ClickError') ? false : json.alias_1ClickError,
    'contattoSelezionato': !(0, helpers_1.exists)(json, 'contattoSelezionato') ? undefined : json.contattoSelezionato,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti
  };
}
exports.FormRicaricaCellulareDtoFromJSONTyped = FormRicaricaCellulareDtoFromJSONTyped;
function FormRevocaRicaricaCellulareDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaRicaricaCellulareDtoFromJSON = FormRevocaRicaricaCellulareDtoFromJSON;