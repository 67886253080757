"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NumeroContattoDtoToJSON = exports.NumeroContattoDtoFromJSONTyped = exports.NumeroContattoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function NumeroContattoDtoFromJSON(json) {
  return NumeroContattoDtoFromJSONTyped(json, false);
}
exports.NumeroContattoDtoFromJSON = NumeroContattoDtoFromJSON;
function NumeroContattoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'numeroContatto': !runtime_1.exists(json, 'numeroContatto') ? undefined : json['numeroContatto'],
    'numeroContattoEstero': !runtime_1.exists(json, 'numeroContattoEstero') ? undefined : json['numeroContattoEstero'],
    'fasciaList': !runtime_1.exists(json, 'fasciaList') ? undefined : json['fasciaList'].map(_1.FasciaDtoFromJSON)
  };
}
exports.NumeroContattoDtoFromJSONTyped = NumeroContattoDtoFromJSONTyped;
function NumeroContattoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'numeroContatto': value.numeroContatto,
    'numeroContattoEstero': value.numeroContattoEstero,
    'fasciaList': value.fasciaList === undefined ? undefined : value.fasciaList.map(_1.FasciaDtoToJSON)
  };
}
exports.NumeroContattoDtoToJSON = NumeroContattoDtoToJSON;