"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CertificateDtoToJSON = exports.CertificateDtoFromJSONTyped = exports.CertificateDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CertificateDtoFromJSON(json) {
  return CertificateDtoFromJSONTyped(json, false);
}
exports.CertificateDtoFromJSON = CertificateDtoFromJSON;
function CertificateDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'certDer': !runtime_1.exists(json, 'certDer') ? undefined : json['certDer'],
    'sha256': !runtime_1.exists(json, 'sha256') ? undefined : json['sha256']
  };
}
exports.CertificateDtoFromJSONTyped = CertificateDtoFromJSONTyped;
function CertificateDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'certDer': value.certDer,
    'sha256': value.sha256
  };
}
exports.CertificateDtoToJSON = CertificateDtoToJSON;