"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UltimiAccessiDtoToJSON = exports.UltimiAccessiDtoFromJSONTyped = exports.UltimiAccessiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UltimiAccessiDtoFromJSON(json) {
  return UltimiAccessiDtoFromJSONTyped(json, false);
}
exports.UltimiAccessiDtoFromJSON = UltimiAccessiDtoFromJSON;
function UltimiAccessiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'listaAccessi': !runtime_1.exists(json, 'listaAccessi') ? undefined : json['listaAccessi'].map(_1.UltimoAccessoOutDtoFromJSON),
    'numeroElementi': !runtime_1.exists(json, 'numeroElementi') ? undefined : json['numeroElementi']
  };
}
exports.UltimiAccessiDtoFromJSONTyped = UltimiAccessiDtoFromJSONTyped;
function UltimiAccessiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'listaAccessi': value.listaAccessi === undefined ? undefined : value.listaAccessi.map(_1.UltimoAccessoOutDtoToJSON),
    'numeroElementi': value.numeroElementi
  };
}
exports.UltimiAccessiDtoToJSON = UltimiAccessiDtoToJSON;