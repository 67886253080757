"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SmsAlertDtoToJSON = exports.SmsAlertDtoFromJSONTyped = exports.SmsAlertDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SmsAlertDtoFromJSON(json) {
  return SmsAlertDtoFromJSONTyped(json, false);
}
exports.SmsAlertDtoFromJSON = SmsAlertDtoFromJSON;
function SmsAlertDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'notifiche': !runtime_1.exists(json, 'notifiche') ? undefined : json['notifiche'].map(_1.ServizioDtoFromJSON),
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'],
    'soglia': !runtime_1.exists(json, 'soglia') ? undefined : _1.SogliaNotificheDtoFromJSON(json['soglia']),
    'cellulare': !runtime_1.exists(json, 'cellulare') ? undefined : json['cellulare'],
    'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
    'rapportiAddebito': !runtime_1.exists(json, 'rapportiAddebito') ? undefined : json['rapportiAddebito'],
    'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
    'mostraCellulare': !runtime_1.exists(json, 'mostraCellulare') ? undefined : json['mostraCellulare'],
    'mostraEmail': !runtime_1.exists(json, 'mostraEmail') ? undefined : json['mostraEmail'],
    'gestioneSogliaConto': !runtime_1.exists(json, 'gestioneSogliaConto') ? undefined : json['gestioneSogliaConto']
  };
}
exports.SmsAlertDtoFromJSONTyped = SmsAlertDtoFromJSONTyped;
function SmsAlertDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'notifiche': value.notifiche === undefined ? undefined : value.notifiche.map(_1.ServizioDtoToJSON),
    'rapporti': value.rapporti,
    'soglia': _1.SogliaNotificheDtoToJSON(value.soglia),
    'cellulare': value.cellulare,
    'email': value.email,
    'contoAddebito': value.contoAddebito,
    'rapportiAddebito': value.rapportiAddebito,
    'lingua': value.lingua,
    'mostraCellulare': value.mostraCellulare,
    'mostraEmail': value.mostraEmail,
    'gestioneSogliaConto': value.gestioneSogliaConto
  };
}
exports.SmsAlertDtoToJSON = SmsAlertDtoToJSON;