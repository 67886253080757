"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrdineTradingDtoToJSON = exports.OrdineTradingDtoFromJSONTyped = exports.OrdineTradingDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OrdineTradingDtoFromJSON(json) {
  return OrdineTradingDtoFromJSONTyped(json, false);
}
exports.OrdineTradingDtoFromJSON = OrdineTradingDtoFromJSON;
function OrdineTradingDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idOrdine': !runtime_1.exists(json, 'idOrdine') ? undefined : json['idOrdine'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'indicativoTitolo': !runtime_1.exists(json, 'indicativoTitolo') ? undefined : json['indicativoTitolo'],
    'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
    'segnoOrdine': !runtime_1.exists(json, 'segnoOrdine') ? undefined : json['segnoOrdine'],
    'mercatoOrdine': !runtime_1.exists(json, 'mercatoOrdine') ? undefined : json['mercatoOrdine'],
    'quantitaRichiesta': !runtime_1.exists(json, 'quantitaRichiesta') ? undefined : json['quantitaRichiesta'],
    'faseOrdine': !runtime_1.exists(json, 'faseOrdine') ? undefined : json['faseOrdine'],
    'executionOnly': !runtime_1.exists(json, 'executionOnly') ? undefined : json['executionOnly'],
    'ordineLimitato': !runtime_1.exists(json, 'ordineLimitato') ? undefined : json['ordineLimitato'],
    'prezzoLimite': !runtime_1.exists(json, 'prezzoLimite') ? undefined : json['prezzoLimite'],
    'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : new Date(json['dataFinale']),
    'parametroQuantita': !runtime_1.exists(json, 'parametroQuantita') ? undefined : json['parametroQuantita'],
    'parametroPrezzo': !runtime_1.exists(json, 'parametroPrezzo') ? undefined : json['parametroPrezzo'],
    'parametroTempo': !runtime_1.exists(json, 'parametroTempo') ? undefined : json['parametroTempo'],
    'oraLimite': !runtime_1.exists(json, 'oraLimite') ? undefined : new Date(json['oraLimite']),
    'ordineProssimaGiornata': !runtime_1.exists(json, 'ordineProssimaGiornata') ? undefined : json['ordineProssimaGiornata'],
    'condizioneStop': !runtime_1.exists(json, 'condizioneStop') ? undefined : json['condizioneStop'],
    'prezzoStop': !runtime_1.exists(json, 'prezzoStop') ? undefined : json['prezzoStop'],
    'prezzoStart': !runtime_1.exists(json, 'prezzoStart') ? undefined : json['prezzoStart'],
    'rapportoCC': !runtime_1.exists(json, 'rapportoCC') ? undefined : _1.RapportoContoCorrenteDtoFromJSON(json['rapportoCC']),
    'controvalorePresunto': !runtime_1.exists(json, 'controvalorePresunto') ? undefined : json['controvalorePresunto'],
    'saldoContoCorrente': !runtime_1.exists(json, 'saldoContoCorrente') ? undefined : json['saldoContoCorrente'],
    'numeroOrdine': !runtime_1.exists(json, 'numeroOrdine') ? undefined : json['numeroOrdine'],
    'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : new Date(json['dataIniziale']),
    'quantitaMinima': !runtime_1.exists(json, 'quantitaMinima') ? undefined : json['quantitaMinima'],
    'profiloRischio': !runtime_1.exists(json, 'profiloRischio') ? undefined : json['profiloRischio'],
    'classificazioneMifid': !runtime_1.exists(json, 'classificazioneMifid') ? undefined : json['classificazioneMifid'],
    'adeguatoAppropriato': !runtime_1.exists(json, 'adeguatoAppropriato') ? undefined : json['adeguatoAppropriato'],
    'conflittoInteressi': !runtime_1.exists(json, 'conflittoInteressi') ? undefined : json['conflittoInteressi'],
    'pattiChiari': !runtime_1.exists(json, 'pattiChiari') ? undefined : json['pattiChiari'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'flagMercatoChiuso': !runtime_1.exists(json, 'flagMercatoChiuso') ? undefined : json['flagMercatoChiuso'],
    'adeguatoAppropriatoStringa': !runtime_1.exists(json, 'adeguatoAppropriatoStringa') ? undefined : json['adeguatoAppropriatoStringa'],
    'conflittoInteressiStringa': !runtime_1.exists(json, 'conflittoInteressiStringa') ? undefined : json['conflittoInteressiStringa'],
    'productGovernance': !runtime_1.exists(json, 'productGovernance') ? undefined : _1.ProductGovernanceDtoFromJSON(json['productGovernance']),
    'derogataAdeguatezzaAppropriatezza': !runtime_1.exists(json, 'derogataAdeguatezzaAppropriatezza') ? undefined : json['derogataAdeguatezzaAppropriatezza'],
    'kidKiid': !runtime_1.exists(json, 'kidKiid') ? undefined : json['kidKiid'],
    'raccomandazione': !runtime_1.exists(json, 'raccomandazione') ? undefined : _1.RaccomandazioneDtoFromJSON(json['raccomandazione']),
    'negoziazioneContoTerzi': !runtime_1.exists(json, 'negoziazioneContoTerzi') ? undefined : _1.NegoziazioneContoTerziDtoFromJSON(json['negoziazioneContoTerzi']),
    'collocamento': !runtime_1.exists(json, 'collocamento') ? undefined : _1.CollocamentoDtoFromJSON(json['collocamento']),
    'negoziazioneContoProprio': !runtime_1.exists(json, 'negoziazioneContoProprio') ? undefined : _1.NegoziazioneContoProprioDtoFromJSON(json['negoziazioneContoProprio']),
    'visualizzaSpeseVerificaOrdine': !runtime_1.exists(json, 'visualizzaSpeseVerificaOrdine') ? undefined : json['visualizzaSpeseVerificaOrdine'],
    'obbligatorioVisionePopupSpese': !runtime_1.exists(json, 'obbligatorioVisionePopupSpese') ? undefined : json['obbligatorioVisionePopupSpese'],
    'tipoOrdine': !runtime_1.exists(json, 'tipoOrdine') ? undefined : json['tipoOrdine'],
    'numeroDossier': !runtime_1.exists(json, 'numeroDossier') ? undefined : json['numeroDossier'],
    'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario']
  };
}
exports.OrdineTradingDtoFromJSONTyped = OrdineTradingDtoFromJSONTyped;
function OrdineTradingDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idOrdine': value.idOrdine,
    'codiceRapporto': value.codiceRapporto,
    'titolo': value.titolo,
    'indicativoTitolo': value.indicativoTitolo,
    'codiceIsin': value.codiceIsin,
    'segnoOrdine': value.segnoOrdine,
    'mercatoOrdine': value.mercatoOrdine,
    'quantitaRichiesta': value.quantitaRichiesta,
    'faseOrdine': value.faseOrdine,
    'executionOnly': value.executionOnly,
    'ordineLimitato': value.ordineLimitato,
    'prezzoLimite': value.prezzoLimite,
    'dataFinale': value.dataFinale === undefined ? undefined : value.dataFinale.toISOString(),
    'parametroQuantita': value.parametroQuantita,
    'parametroPrezzo': value.parametroPrezzo,
    'parametroTempo': value.parametroTempo,
    'oraLimite': value.oraLimite === undefined ? undefined : value.oraLimite.toISOString(),
    'ordineProssimaGiornata': value.ordineProssimaGiornata,
    'condizioneStop': value.condizioneStop,
    'prezzoStop': value.prezzoStop,
    'prezzoStart': value.prezzoStart,
    'rapportoCC': _1.RapportoContoCorrenteDtoToJSON(value.rapportoCC),
    'controvalorePresunto': value.controvalorePresunto,
    'saldoContoCorrente': value.saldoContoCorrente,
    'numeroOrdine': value.numeroOrdine,
    'dataIniziale': value.dataIniziale === undefined ? undefined : value.dataIniziale.toISOString(),
    'quantitaMinima': value.quantitaMinima,
    'profiloRischio': value.profiloRischio,
    'classificazioneMifid': value.classificazioneMifid,
    'adeguatoAppropriato': value.adeguatoAppropriato,
    'conflittoInteressi': value.conflittoInteressi,
    'pattiChiari': value.pattiChiari,
    'divisa': value.divisa,
    'flagMercatoChiuso': value.flagMercatoChiuso,
    'adeguatoAppropriatoStringa': value.adeguatoAppropriatoStringa,
    'conflittoInteressiStringa': value.conflittoInteressiStringa,
    'productGovernance': _1.ProductGovernanceDtoToJSON(value.productGovernance),
    'derogataAdeguatezzaAppropriatezza': value.derogataAdeguatezzaAppropriatezza,
    'kidKiid': value.kidKiid,
    'raccomandazione': _1.RaccomandazioneDtoToJSON(value.raccomandazione),
    'negoziazioneContoTerzi': _1.NegoziazioneContoTerziDtoToJSON(value.negoziazioneContoTerzi),
    'collocamento': _1.CollocamentoDtoToJSON(value.collocamento),
    'negoziazioneContoProprio': _1.NegoziazioneContoProprioDtoToJSON(value.negoziazioneContoProprio),
    'visualizzaSpeseVerificaOrdine': value.visualizzaSpeseVerificaOrdine,
    'obbligatorioVisionePopupSpese': value.obbligatorioVisionePopupSpese,
    'tipoOrdine': value.tipoOrdine,
    'numeroDossier': value.numeroDossier,
    'intestatario': value.intestatario
  };
}
exports.OrdineTradingDtoToJSON = OrdineTradingDtoToJSON;