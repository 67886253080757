"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GooglePlacesApi = void 0;
const runtime = require("@sparkasse/api-rest");
const TextSearchResponse_1 = require("../models/TextSearchResponse");
class GooglePlacesApi extends runtime.BaseAPI {
  /**
   * Restituisce la lista di tutti gli atm per il relativo codice istituto
   */
  async textSearchRaw(requestParameters) {
    if (requestParameters.query === null || requestParameters.query === undefined) {
      throw new runtime.RequiredError('query', 'Required parameter requestParameters.query was null or undefined when calling textSearch.');
    }
    if (requestParameters.key === null || requestParameters.key === undefined) {
      throw new runtime.RequiredError('key', 'Required parameter requestParameters.key was null or undefined when calling textSearch.');
    }
    const queryParameters = {};
    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query;
    }
    if (requestParameters.key !== undefined) {
      queryParameters['key'] = requestParameters.key;
    }
    if (requestParameters.language !== undefined) {
      queryParameters['language'] = requestParameters.language;
    }
    if (requestParameters.location !== undefined) {
      queryParameters['location'] = requestParameters.location;
    }
    if (requestParameters.maxprice !== undefined) {
      queryParameters['maxprice'] = requestParameters.maxprice;
    }
    if (requestParameters.minprice !== undefined) {
      queryParameters['minprice'] = requestParameters.minprice;
    }
    if (requestParameters.opennow !== undefined) {
      queryParameters['opennow'] = requestParameters.opennow;
    }
    if (requestParameters.pagetoken !== undefined) {
      queryParameters['pagetoken'] = requestParameters.pagetoken;
    }
    if (requestParameters.radius !== undefined) {
      queryParameters['radius'] = requestParameters.radius;
    }
    if (requestParameters.region !== undefined) {
      queryParameters['region'] = requestParameters.region;
    }
    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type;
    }
    const headerParameters = {};
    const response = await this.request({
      path: "/place/textsearch/json",
      method: 'GET',
      headers: headerParameters,
      query: queryParameters
    });
    return new runtime.JSONApiResponse(response, jsonValue => (0, TextSearchResponse_1.TextSearchResponseFromJSON)(jsonValue));
  }
  /**
   * Restituisce la lista di tutti gli atm per il relativo codice istituto
   */
  async textSearch(requestParameters) {
    const response = await this.textSearchRaw(requestParameters);
    return await response.value();
  }
}
exports.GooglePlacesApi = GooglePlacesApi;