"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IndirizzoAnagrafeDtoToJSON = exports.IndirizzoAnagrafeDtoFromJSONTyped = exports.IndirizzoAnagrafeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function IndirizzoAnagrafeDtoFromJSON(json) {
  return IndirizzoAnagrafeDtoFromJSONTyped(json, false);
}
exports.IndirizzoAnagrafeDtoFromJSON = IndirizzoAnagrafeDtoFromJSON;
function IndirizzoAnagrafeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoVia': !runtime_1.exists(json, 'tipoVia') ? undefined : json['tipoVia'],
    'nomeVia': !runtime_1.exists(json, 'nomeVia') ? undefined : json['nomeVia'],
    'numeroVia': !runtime_1.exists(json, 'numeroVia') ? undefined : json['numeroVia'],
    'localita': !runtime_1.exists(json, 'localita') ? undefined : json['localita'],
    'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
    'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
    'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
    'tipoIndirizzo': !runtime_1.exists(json, 'tipoIndirizzo') ? undefined : json['tipoIndirizzo'],
    'presso': !runtime_1.exists(json, 'presso') ? undefined : json['presso'],
    'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
    'codiceCasellario': !runtime_1.exists(json, 'codiceCasellario') ? undefined : json['codiceCasellario'],
    'descrizioneCasellario': !runtime_1.exists(json, 'descrizioneCasellario') ? undefined : json['descrizioneCasellario'],
    'codiceCasellaPostale': !runtime_1.exists(json, 'codiceCasellaPostale') ? undefined : json['codiceCasellaPostale'],
    'descrizioneCasellaPostale': !runtime_1.exists(json, 'descrizioneCasellaPostale') ? undefined : json['descrizioneCasellaPostale'],
    'infoAggiuntive': !runtime_1.exists(json, 'infoAggiuntive') ? undefined : json['infoAggiuntive']
  };
}
exports.IndirizzoAnagrafeDtoFromJSONTyped = IndirizzoAnagrafeDtoFromJSONTyped;
function IndirizzoAnagrafeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoVia': value.tipoVia,
    'nomeVia': value.nomeVia,
    'numeroVia': value.numeroVia,
    'localita': value.localita,
    'cap': value.cap,
    'comune': value.comune,
    'provincia': value.provincia,
    'tipoIndirizzo': value.tipoIndirizzo,
    'presso': value.presso,
    'filiale': value.filiale,
    'codiceCasellario': value.codiceCasellario,
    'descrizioneCasellario': value.descrizioneCasellario,
    'codiceCasellaPostale': value.codiceCasellaPostale,
    'descrizioneCasellaPostale': value.descrizioneCasellaPostale,
    'infoAggiuntive': value.infoAggiuntive
  };
}
exports.IndirizzoAnagrafeDtoToJSON = IndirizzoAnagrafeDtoToJSON;