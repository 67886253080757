"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetDtoToJSON = exports.WidgetDtoFromJSONTyped = exports.WidgetDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WidgetDtoFromJSON(json) {
  return WidgetDtoFromJSONTyped(json, false);
}
exports.WidgetDtoFromJSON = WidgetDtoFromJSON;
function WidgetDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'option': !runtime_1.exists(json, 'option') ? undefined : _1.OptionDtoFromJSON(json['option']),
    'widgetId': !runtime_1.exists(json, 'widgetId') ? undefined : json['widgetId'],
    'order': !runtime_1.exists(json, 'order') ? undefined : json['order']
  };
}
exports.WidgetDtoFromJSONTyped = WidgetDtoFromJSONTyped;
function WidgetDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'option': _1.OptionDtoToJSON(value.option),
    'widgetId': value.widgetId,
    'order': value.order
  };
}
exports.WidgetDtoToJSON = WidgetDtoToJSON;