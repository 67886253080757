"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postLoginError = exports.noRapporti = exports.error = exports.androidPermissionGranted = exports.downloadPDF = exports.ACSLoginAsked = exports.inAppBrowserOpened = exports.chatGenesysOpened = exports.erroreAdeguataVerifica = exports.assistenteOpened = exports.azioniSmartVisible = exports.neosuranceLoginAsked = exports.popUps = exports.mobileIsConnected = exports.callerObject = exports.snackbar = exports.saldoContabileSaldoDisponibile = exports.mobileTheme = exports.bottomTabBarVisible = exports.isSessionExpired = exports.waitingSections = exports.loaderActive = exports.tooltipMobile = exports.currentTab = exports.nextRoute = exports.generalReducer = void 0;
const reselect_1 = require("reselect");
const generalReducer = state => state;
exports.generalReducer = generalReducer;
exports.nextRoute = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.nextRoute;
});
exports.currentTab = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.currentTab;
});
exports.tooltipMobile = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.tooltipMobile;
});
exports.loaderActive = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.isLoader;
});
exports.waitingSections = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.waitingSections;
});
exports.isSessionExpired = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.sessionExpired;
});
exports.bottomTabBarVisible = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.bottomTabBarVisible;
});
exports.mobileTheme = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.mobileTheme;
});
exports.saldoContabileSaldoDisponibile = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.saldoContabileSaldoDisponibile;
});
exports.snackbar = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.snackbar;
});
exports.callerObject = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.callerObject;
});
exports.mobileIsConnected = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.isConnected;
});
exports.popUps = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.popUps;
});
exports.neosuranceLoginAsked = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.neosuranceLoginAsked;
});
exports.azioniSmartVisible = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.azioniSmartVisible;
});
exports.assistenteOpened = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.assistenteOpened;
});
exports.erroreAdeguataVerifica = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.erroreAdeguataVerifica;
});
exports.chatGenesysOpened = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.chatGenesysOpened;
});
exports.inAppBrowserOpened = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.inAppBrowserOpened;
});
exports.ACSLoginAsked = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.ACSLoginAsked;
});
exports.downloadPDF = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.downloadPDF;
});
exports.androidPermissionGranted = (0, reselect_1.createSelector)(exports.generalReducer, state => {
  return state.androidPermissionGranted;
});
exports.error = (0, reselect_1.createSelector)(exports.generalReducer, state => state.error);
exports.noRapporti = (0, reselect_1.createSelector)(exports.generalReducer, state => state.noRapporti);
exports.postLoginError = (0, reselect_1.createSelector)(exports.generalReducer, state => state.postLoginError);