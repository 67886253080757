"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagDtoToJSON = exports.TagDtoFromJSONTyped = exports.TagDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TagDtoFromJSON(json) {
  return TagDtoFromJSONTyped(json, false);
}
exports.TagDtoFromJSON = TagDtoFromJSON;
function TagDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idMenu': !runtime_1.exists(json, 'idMenu') ? undefined : json['idMenu'],
    'menuLabel': !runtime_1.exists(json, 'menuLabel') ? undefined : json['menuLabel'],
    'tags': !runtime_1.exists(json, 'tags') ? undefined : json['tags']
  };
}
exports.TagDtoFromJSONTyped = TagDtoFromJSONTyped;
function TagDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idMenu': value.idMenu,
    'menuLabel': value.menuLabel,
    'tags': value.tags
  };
}
exports.TagDtoToJSON = TagDtoToJSON;