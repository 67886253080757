"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.variazioneCanaliReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
  rapportiPredefiniti: [],
  infoAutenticazione: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  parametriCorrispondenza: (0, api_rest_1.ParametriCorrispondenzaDtoFromJSON)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneRapportiDtoFromJSON)({}),
  corrispondenze: [],
  condizioniEconomiche: (0, api_rest_1.MessaggioBodyDtoFromJSON)({}),
  form: {
    step: 0,
    selectedItems: [],
    selectedAll: false,
    formDirty: false
  }
};
const variazioneCanaliReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c;
  switch (action.type) {
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_SUCCESS:
      return {
        ...initialState,
        parametriCorrispondenza: action.payload
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_PER_MODIFICA_SUCCESS:
      return {
        ...initialState,
        parametriCorrispondenza: action.payload,
        form: state.form,
        condizioniEconomiche: state.condizioniEconomiche,
        corrispondenze: state.corrispondenze
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_CORRISPONDEZE_SUCCESS:
      return {
        ...state,
        corrispondenze: action.payload,
        form: {
          ...state.form,
          selectedItems: action.payload,
          selectedAll: !((_a = action.payload) === null || _a === void 0 ? void 0 : _a.some(item => !(item === null || item === void 0 ? void 0 : item.cartacea)))
        }
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_AUTORIZZA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esito: action.payload,
          form: {
            ...state.form
          }
        };
      } else {
        return {
          ...state,
          infoAutenticazione: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazione, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_SUCCESS:
      return {
        ...state,
        rapportiPredefiniti: action.payload
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_SUCCESS:
      return {
        ...state,
        infoAutenticazione: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_ACCETTATO:
      return {
        ...state,
        form: {
          ...state.form,
          accettato: action.payload
        }
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_SELECTED_ALL:
      const allItems = (_b = state.form) === null || _b === void 0 ? void 0 : _b.selectedItems.map(item => {
        return {
          ...item,
          cartacea: true
        };
      });
      const noItems = (_c = state.form) === null || _c === void 0 ? void 0 : _c.selectedItems.map(item => {
        return {
          ...item,
          cartacea: false
        };
      });
      return {
        ...state,
        form: {
          ...state.form,
          selectedAll: action.payload,
          selectedItems: action.payload ? allItems : noItems,
          formDirty: true
        }
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_SELECTED_ITEMS:
      let listaSelected = state.form.selectedItems || [];
      const foundIndex = listaSelected === null || listaSelected === void 0 ? void 0 : listaSelected.findIndex(item => item.conto === action.payload.conto);
      const newList = Object.assign([], listaSelected, {
        [foundIndex]: action.payload
      });
      return {
        ...state,
        form: {
          ...state.form,
          selectedItems: newList,
          selectedAll: !newList.some(item => !(item === null || item === void 0 ? void 0 : item.cartacea)),
          formDirty: true
        }
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_SUCCESS:
      return {
        ...state,
        condizioniEconomiche: action.payload
      };
    case __1.EReduxActionTypes.VARIAZIONE_CANALI_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.variazioneCanaliReducer = variazioneCanaliReducer;
exports.default = exports.variazioneCanaliReducer;