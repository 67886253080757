"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FiltroElencoDepositiRisparmioDtoSegnoEnum = exports.FiltroElencoDepositiRisparmioDtoToJSON = exports.FiltroElencoDepositiRisparmioDtoFromJSONTyped = exports.FiltroElencoDepositiRisparmioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroElencoDepositiRisparmioDtoFromJSON(json) {
  return FiltroElencoDepositiRisparmioDtoFromJSONTyped(json, false);
}
exports.FiltroElencoDepositiRisparmioDtoFromJSON = FiltroElencoDepositiRisparmioDtoFromJSON;
function FiltroElencoDepositiRisparmioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : new Date(json['dataIniziale']),
    'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : new Date(json['dataFinale']),
    'keyword': !runtime_1.exists(json, 'keyword') ? undefined : json['keyword'],
    'ordinamento': !runtime_1.exists(json, 'ordinamento') ? undefined : json['ordinamento'],
    'importoDa': !runtime_1.exists(json, 'importoDa') ? undefined : json['importoDa'],
    'importoA': !runtime_1.exists(json, 'importoA') ? undefined : json['importoA'],
    'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno']
  };
}
exports.FiltroElencoDepositiRisparmioDtoFromJSONTyped = FiltroElencoDepositiRisparmioDtoFromJSONTyped;
function FiltroElencoDepositiRisparmioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'dataIniziale': value.dataIniziale === undefined ? undefined : value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale === undefined ? undefined : value.dataFinale.toISOString(),
    'keyword': value.keyword,
    'ordinamento': value.ordinamento,
    'importoDa': value.importoDa,
    'importoA': value.importoA,
    'segno': value.segno
  };
}
exports.FiltroElencoDepositiRisparmioDtoToJSON = FiltroElencoDepositiRisparmioDtoToJSON;
/**
* @export
* @enum {string}
*/
var FiltroElencoDepositiRisparmioDtoSegnoEnum;
(function (FiltroElencoDepositiRisparmioDtoSegnoEnum) {
  FiltroElencoDepositiRisparmioDtoSegnoEnum["A"] = "A";
  FiltroElencoDepositiRisparmioDtoSegnoEnum["D"] = "D";
})(FiltroElencoDepositiRisparmioDtoSegnoEnum = exports.FiltroElencoDepositiRisparmioDtoSegnoEnum || (exports.FiltroElencoDepositiRisparmioDtoSegnoEnum = {}));