"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxCollaborationActionTypes = void 0;
var EReduxCollaborationActionTypes;
(function (EReduxCollaborationActionTypes) {
  EReduxCollaborationActionTypes["GET_CONFIG_COLLABORATION_START"] = "GET_CONFIG_COLLABORATION_START";
  EReduxCollaborationActionTypes["GET_CONFIG_COLLABORATION_SUCCESS"] = "GET_CONFIG_COLLABORATION_SUCCESS";
  EReduxCollaborationActionTypes["GET_CONSULENTE_START"] = "GET_CONSULENTE_START";
  EReduxCollaborationActionTypes["GET_CONSULENTE_SUCCESS"] = "GET_CONSULENTE_SUCCESS";
  EReduxCollaborationActionTypes["GET_DISPONIBILITA_CONSULENTE_START"] = "GET_DISPONIBILITA_CONSULENTE_START";
  EReduxCollaborationActionTypes["GET_DISPONIBILITA_CONSULENTE_SUCCESS"] = "GET_DISPONIBILITA_CONSULENTE_SUCCESS";
  EReduxCollaborationActionTypes["CREA_APPUNTAMENTO_START"] = "CREA_APPUNTAMENTO_START";
  EReduxCollaborationActionTypes["CREA_APPUNTAMENTO_SUCCESS"] = "CREA_APPUNTAMENTO_SUCCESS";
  EReduxCollaborationActionTypes["MODIFICA_APPUNTAMENTO_START"] = "MODIFICA_APPUNTAMENTO_START";
  EReduxCollaborationActionTypes["MODIFICA_APPUNTAMENTO_SUCCESS"] = "MODIFICA_APPUNTAMENTO_SUCCESS";
  EReduxCollaborationActionTypes["ELIMINA_APPUNTAMENTO_START"] = "ELIMINA_APPUNTAMENTO_START";
  EReduxCollaborationActionTypes["ELIMINA_APPUNTAMENTO_SUCCESS"] = "ELIMINA_APPUNTAMENTO_SUCCESS";
  EReduxCollaborationActionTypes["GET_ELENCO_APPUNTAMENTI_START"] = "GET_ELENCO_APPUNTAMENTI_START";
  EReduxCollaborationActionTypes["GET_ELENCO_APPUNTAMENTI_SUCCESS"] = "GET_ELENCO_APPUNTAMENTI_SUCCESS";
  EReduxCollaborationActionTypes["RESET_APPUNTAMENTO_ELIMINATO"] = "RESET_APPUNTAMENTO_ELIMINATO";
  EReduxCollaborationActionTypes["RESET_APPUNTAMENTO_MODIFICATO"] = "RESET_APPUNTAMENTO_MODIFICATO";
  EReduxCollaborationActionTypes["RESET_APPUNTAMENTO_CREATO"] = "RESET_APPUNTAMENTO_CREATO";
  EReduxCollaborationActionTypes["GET_TOKEN_EVENTO_START"] = "GET_TOKEN_EVENTO_START";
  EReduxCollaborationActionTypes["GET_TOKEN_EVENTO_SUCCESS"] = "GET_TOKEN_EVENTO_SUCCESS";
  EReduxCollaborationActionTypes["RESET_TOKEN_EVENTO"] = "RESET_TOKEN_EVENTO";
  EReduxCollaborationActionTypes["GET_ARGOMENTI_EVENTO_START"] = "GET_ARGOMENTI_EVENTO_START";
  EReduxCollaborationActionTypes["GET_ARGOMENTI_EVENTO_SUCCESS"] = "GET_ARGOMENTI_EVENTO_SUCCESS";
  EReduxCollaborationActionTypes["SET_CREA_APPUNTAMENTO_NOTE"] = "SET_CREA_APPUNTAMENTO_NOTE";
  EReduxCollaborationActionTypes["SET_CREA_APPUNTAMENTO_MODALITA_INCONTRO"] = "SET_CREA_APPUNTAMENTO_MODALITA_INCONTRO";
  EReduxCollaborationActionTypes["SET_CREA_APPUNTAMENTO_ARGOMENTO_SELEZIONATO"] = "SET_CREA_APPUNTAMENTO_ARGOMENTO_SELEZIONATO";
  EReduxCollaborationActionTypes["RESET_FORM_CREA_APPUNTAMENTO"] = "RESET_FORM_CREA_APPUNTAMENTO";
  EReduxCollaborationActionTypes["SET_APPUNTAMENTO_DA_MODIFICARE"] = "SET_APPUNTAMENTO_DA_MODIFICARE";
})(EReduxCollaborationActionTypes = exports.EReduxCollaborationActionTypes || (exports.EReduxCollaborationActionTypes = {}));