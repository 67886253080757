"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetBeniDebitiDtoToJSON = exports.WidgetBeniDebitiDtoFromJSONTyped = exports.WidgetBeniDebitiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WidgetBeniDebitiDtoFromJSON(json) {
  return WidgetBeniDebitiDtoFromJSONTyped(json, false);
}
exports.WidgetBeniDebitiDtoFromJSON = WidgetBeniDebitiDtoFromJSON;
function WidgetBeniDebitiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'beni': !runtime_1.exists(json, 'beni') ? undefined : _1.WidgetAssetDebtDtoFromJSON(json['beni']),
    'debiti': !runtime_1.exists(json, 'debiti') ? undefined : _1.WidgetAssetDebtDtoFromJSON(json['debiti']),
    'beniTypes': !runtime_1.exists(json, 'beniTypes') ? undefined : json['beniTypes'].map(_1.WidgetAssetDebtTypeDtoFromJSON),
    'debitiTypes': !runtime_1.exists(json, 'debitiTypes') ? undefined : json['debitiTypes'].map(_1.WidgetAssetDebtTypeDtoFromJSON),
    'stackedChart': !runtime_1.exists(json, 'stackedChart') ? undefined : _1.WidgetAssetDebtChartDtoFromJSON(json['stackedChart'])
  };
}
exports.WidgetBeniDebitiDtoFromJSONTyped = WidgetBeniDebitiDtoFromJSONTyped;
function WidgetBeniDebitiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'beni': _1.WidgetAssetDebtDtoToJSON(value.beni),
    'debiti': _1.WidgetAssetDebtDtoToJSON(value.debiti),
    'beniTypes': value.beniTypes === undefined ? undefined : value.beniTypes.map(_1.WidgetAssetDebtTypeDtoToJSON),
    'debitiTypes': value.debitiTypes === undefined ? undefined : value.debitiTypes.map(_1.WidgetAssetDebtTypeDtoToJSON),
    'stackedChart': _1.WidgetAssetDebtChartDtoToJSON(value.stackedChart)
  };
}
exports.WidgetBeniDebitiDtoToJSON = WidgetBeniDebitiDtoToJSON;