"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRevocaMavDtoFromJSON = exports.FormMavDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormMavDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
    'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
    'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
    'codiceMAV': !(0, helpers_1.exists)(json, 'codiceMAV') ? undefined : json.codiceMAV,
    'codiceMAVDirty': !(0, helpers_1.exists)(json, 'codiceMAVDirty') ? false : json.codiceMAVDirty,
    'codiceMAVError': !(0, helpers_1.exists)(json, 'codiceMAVError') ? 'invalid.required' : json.codiceMAVError,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
    'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
    'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti
  };
}
exports.FormMavDtoFromJSONTyped = FormMavDtoFromJSONTyped;
function FormRevocaMavDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaMavDtoFromJSON = FormRevocaMavDtoFromJSON;