"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum = exports.CriteriRicercaMovimentiNexiDebitDtoSegnoEnum = exports.CriteriRicercaMovimentiNexiDebitDtoToJSON = exports.CriteriRicercaMovimentiNexiDebitDtoFromJSONTyped = exports.CriteriRicercaMovimentiNexiDebitDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CriteriRicercaMovimentiNexiDebitDtoFromJSON(json) {
  return CriteriRicercaMovimentiNexiDebitDtoFromJSONTyped(json, false);
}
exports.CriteriRicercaMovimentiNexiDebitDtoFromJSON = CriteriRicercaMovimentiNexiDebitDtoFromJSON;
function CriteriRicercaMovimentiNexiDebitDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': json['codiceRapporto'],
    'dataIniziale': new Date(json['dataIniziale']),
    'dataFinale': new Date(json['dataFinale']),
    'binPan': json['binPan'],
    'tailPan': json['tailPan'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'keyword': !runtime_1.exists(json, 'keyword') ? undefined : json['keyword'],
    'importoDa': !runtime_1.exists(json, 'importoDa') ? undefined : json['importoDa'],
    'importoA': !runtime_1.exists(json, 'importoA') ? undefined : json['importoA'],
    'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno'],
    'ordinamento': !runtime_1.exists(json, 'ordinamento') ? undefined : json['ordinamento'],
    'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset']
  };
}
exports.CriteriRicercaMovimentiNexiDebitDtoFromJSONTyped = CriteriRicercaMovimentiNexiDebitDtoFromJSONTyped;
function CriteriRicercaMovimentiNexiDebitDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'dataIniziale': value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale.toISOString(),
    'binPan': value.binPan,
    'tailPan': value.tailPan,
    'divisa': value.divisa,
    'keyword': value.keyword,
    'importoDa': value.importoDa,
    'importoA': value.importoA,
    'segno': value.segno,
    'ordinamento': value.ordinamento,
    'offset': value.offset
  };
}
exports.CriteriRicercaMovimentiNexiDebitDtoToJSON = CriteriRicercaMovimentiNexiDebitDtoToJSON;
/**
* @export
* @enum {string}
*/
var CriteriRicercaMovimentiNexiDebitDtoSegnoEnum;
(function (CriteriRicercaMovimentiNexiDebitDtoSegnoEnum) {
  CriteriRicercaMovimentiNexiDebitDtoSegnoEnum["A"] = "A";
  CriteriRicercaMovimentiNexiDebitDtoSegnoEnum["D"] = "D";
})(CriteriRicercaMovimentiNexiDebitDtoSegnoEnum = exports.CriteriRicercaMovimentiNexiDebitDtoSegnoEnum || (exports.CriteriRicercaMovimentiNexiDebitDtoSegnoEnum = {}));
/**
* @export
* @enum {string}
*/
var CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum;
(function (CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum) {
  CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum["A"] = "A";
  CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum["D"] = "D";
})(CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum = exports.CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum || (exports.CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum = {}));