"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypeNoRapporti = exports.generalReducer = exports.setPinningCertificates = exports.setCustomEnv = exports.getCustomEnv = exports.Environments = exports.selectedEnv = exports.selectedAbi = exports.package_id = void 0;
var abiConfiguration_1 = require("./abiConfiguration");
Object.defineProperty(exports, "package_id", {
  enumerable: true,
  get: function () {
    return abiConfiguration_1.package_id;
  }
});
Object.defineProperty(exports, "selectedAbi", {
  enumerable: true,
  get: function () {
    return abiConfiguration_1.selectedAbi;
  }
});
var env_1 = require("./env");
Object.defineProperty(exports, "selectedEnv", {
  enumerable: true,
  get: function () {
    return env_1.selectedEnv;
  }
});
var environments_1 = require("./environments");
Object.defineProperty(exports, "Environments", {
  enumerable: true,
  get: function () {
    return environments_1.Environments;
  }
});
Object.defineProperty(exports, "getCustomEnv", {
  enumerable: true,
  get: function () {
    return environments_1.getCustomEnv;
  }
});
Object.defineProperty(exports, "setCustomEnv", {
  enumerable: true,
  get: function () {
    return environments_1.setCustomEnv;
  }
});
__exportStar(require("./general.actions"), exports);
var general_configuration_1 = require("./general.configuration");
Object.defineProperty(exports, "setPinningCertificates", {
  enumerable: true,
  get: function () {
    return general_configuration_1.setPinningCertificates;
  }
});
var general_reducer_1 = require("./general.reducer");
Object.defineProperty(exports, "generalReducer", {
  enumerable: true,
  get: function () {
    return general_reducer_1.generalReducer;
  }
});
__exportStar(require("./general.selectors"), exports);
var general_types_1 = require("./general.types");
Object.defineProperty(exports, "TypeNoRapporti", {
  enumerable: true,
  get: function () {
    return general_types_1.TypeNoRapporti;
  }
});