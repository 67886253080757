"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServizioOperativoOutputDtoToJSON = exports.ServizioOperativoOutputDtoFromJSONTyped = exports.ServizioOperativoOutputDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ServizioOperativoOutputDtoFromJSON(json) {
  return ServizioOperativoOutputDtoFromJSONTyped(json, false);
}
exports.ServizioOperativoOutputDtoFromJSON = ServizioOperativoOutputDtoFromJSON;
function ServizioOperativoOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idServizio': !runtime_1.exists(json, 'idServizio') ? undefined : json['idServizio'],
    'idStrumento': !runtime_1.exists(json, 'idStrumento') ? undefined : json['idStrumento'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'defaultRicezione': !runtime_1.exists(json, 'defaultRicezione') ? undefined : json['defaultRicezione'],
    'defaultInvio': !runtime_1.exists(json, 'defaultInvio') ? undefined : json['defaultInvio'],
    'selectedInvio': !runtime_1.exists(json, 'selectedInvio') ? undefined : json['selectedInvio'],
    'ibanFormattato': !runtime_1.exists(json, 'ibanFormattato') ? undefined : json['ibanFormattato'],
    'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione']
  };
}
exports.ServizioOperativoOutputDtoFromJSONTyped = ServizioOperativoOutputDtoFromJSONTyped;
function ServizioOperativoOutputDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idServizio': value.idServizio,
    'idStrumento': value.idStrumento,
    'iban': value.iban,
    'defaultRicezione': value.defaultRicezione,
    'defaultInvio': value.defaultInvio,
    'selectedInvio': value.selectedInvio,
    'ibanFormattato': value.ibanFormattato,
    'ordinanteIntestazione': value.ordinanteIntestazione
  };
}
exports.ServizioOperativoOutputDtoToJSON = ServizioOperativoOutputDtoToJSON;