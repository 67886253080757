"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setMessaggioComunicazioneSelezionato = exports.updateMessaggiBloccanti = exports.downloadAllegato = exports.setMessaggiSetAccettato = exports.segnaComeLettoMessaggi = exports.getNumeroMessaggiNonLettiMessaggi = exports.getComunicazioniMessaggi = exports.resetMessaggiBloccanti = exports.getBloccantiMessaggi = exports.resetMessaggiAccettazioneAssistenteVocale = exports.resetMessaggiAccettazione = exports.getAccettazioniMessaggi = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/* Get Accettazioni - START */
function getAccettazioniMessaggiStart() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_ACCETTAZIONI_START
  };
}
function getAccettazioniMessaggiSuccess(payload, funzione) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_ACCETTAZIONI_SUCCESS,
    payload,
    funzione
  };
}
function getAccettazioniMessaggiAssistenteVocaleSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_ACCETTAZIONI_ASSISTENTE_VOCALE_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getAccettazioniMessaggi = (funzione, salvaMessaggioAssistente) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getAccettazioniMessaggiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/messaggi_accettazione.json')).then(messaggiAccettazione => {
      dispatch(getAccettazioniMessaggiSuccess(messaggiAccettazione.map(messaggioAccettazione => (0, api_rest_1.MessaggioBodyDtoFromJSON)(messaggioAccettazione)), funzione));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const request = {
      funzione: funzione
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MessaggiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getAccettazioni(request).then(response => {
        if (funzione === api_rest_1.GetAccettazioniFunzioneEnum.ASSISTENTEVIRTUALE && salvaMessaggioAssistente) {
          dispatch(getAccettazioniMessaggiAssistenteVocaleSuccess(response));
        } else {
          dispatch(getAccettazioniMessaggiSuccess(response, funzione));
        }
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getAccettazioniMessaggi = getAccettazioniMessaggi;
function resetMessaggiAccettazione() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_RESET_ACCETTAZIONI
  };
}
exports.resetMessaggiAccettazione = resetMessaggiAccettazione;
function resetMessaggiAccettazioneAssistenteVocale() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_RESET_ACCETTAZIONI_ASSISTENTE_VOCALE
  };
}
exports.resetMessaggiAccettazioneAssistenteVocale = resetMessaggiAccettazioneAssistenteVocale;
/* Get Accettazioni - FINE */
/* Get Bloccanti - START */
function getBloccantiMessaggiStart() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_BLOCCANTI_START
  };
}
function getBloccantiMessaggiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_BLOCCANTI_SUCCESS,
    payload
  };
}
const getBloccantiMessaggi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getBloccantiMessaggiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/messaggi_bloccanti.json')).then(messaggiBloccanti => {
      dispatch(getBloccantiMessaggiSuccess([]));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MessaggiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getBloccanti().then(response => {
        dispatch(getBloccantiMessaggiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
        dispatch((0, general_actions_1.setPostLoginError)(true));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getBloccantiMessaggi = getBloccantiMessaggi;
function resetMessaggiBloccanti() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_RESET_BLOCCANTI
  };
}
exports.resetMessaggiBloccanti = resetMessaggiBloccanti;
/* Get Bloccanti - FINE */
/* Get Comunicazioni - START */
function getComunicazioniMessaggiStart() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_COMUNICAZIONI_START
  };
}
function getComunicazioniMessaggiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_COMUNICAZIONI_SUCCESS,
    payload
  };
}
const getComunicazioniMessaggi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getComunicazioniMessaggiStart());
  if (extraArguments.mock) {
    dispatch(getComunicazioniMessaggiSuccess([]));
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MessaggiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getComunicazioni().then(response => {
        dispatch(getComunicazioniMessaggiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getComunicazioniMessaggi = getComunicazioniMessaggi;
/* Get Comunicazioni - FINE */
/* Get Numero Messaggi Non Letti - START */
function getNumeroMessaggiNonLettiMessaggiStart() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_START
  };
}
function getNumeroMessaggiNonLettiMessaggiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_SUCCESS,
    payload
  };
}
const getNumeroMessaggiNonLettiMessaggi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getNumeroMessaggiNonLettiMessaggiStart());
  if (extraArguments.mock) {
    dispatch(getNumeroMessaggiNonLettiMessaggiSuccess(0));
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MessaggiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getNumeroMessaggiNonLetti().then(response => {
        dispatch(getNumeroMessaggiNonLettiMessaggiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getNumeroMessaggiNonLettiMessaggi = getNumeroMessaggiNonLettiMessaggi;
/* Get Numero Messaggi Non Letti - FINE */
/* Segna Come Letto - START */
function segnaComeLettoMessaggiStart() {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_SEGNA_COME_LETTO_START
  };
}
function segnaComeLettoMessaggiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_SEGNA_COME_LETTO_SUCCESS,
    payload
  };
}
const segnaComeLettoMessaggi = idMessaggio => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(segnaComeLettoMessaggiStart());
  if (extraArguments.mock) {
    dispatch(segnaComeLettoMessaggiSuccess(idMessaggio));
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const request = {
      idMessaggio
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MessaggiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).segnaComeLetto(request).then(() => {
        dispatch(segnaComeLettoMessaggiSuccess(idMessaggio));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.segnaComeLettoMessaggi = segnaComeLettoMessaggi;
/* Segna Come Letto - FINE */
function setMessaggiSetAccettato(value) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_ACCETTATO,
    payload: value
  };
}
exports.setMessaggiSetAccettato = setMessaggiSetAccettato;
const downloadAllegato = allegato => async (dispatch, _, extraArguments) => {
  if (extraArguments.platform === 'mobile') {
    (0, helpers_1.downloadFile)(extraArguments, allegato.name || '', allegato.data || '');
  }
};
exports.downloadAllegato = downloadAllegato;
/* Messaggi update bloccanti */
function updateMessaggiBloccanti(id) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_UPDATE_BLOCCANTI,
    payload: id
  };
}
exports.updateMessaggiBloccanti = updateMessaggiBloccanti;
function setMessaggioComunicazioneSelezionato(messaggio) {
  return {
    type: __1.EReduxActionTypes.MESSAGGI_SET_MESSAGGIO_COMUNICAZIONE_SELEZIONATO,
    payload: messaggio
  };
}
exports.setMessaggioComunicazioneSelezionato = setMessaggioComunicazioneSelezionato;