"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notificheMenu = exports.enrolledUsername = exports.tabsMenu = exports.isNeosuranceVisible = exports.isNotificaMenuAssistenzaVisible = exports.isNotificaMenuProfiloVisible = exports.isNotificaMenuComunicazioniVisible = exports.isMenuAssistenzaVisible = exports.isMenuProfiloVisible = exports.isMenuComunicazioniVisible = exports.menuAssistenza = exports.menuProfilo = exports.menuComunicazioni = exports.menuTextSearch = exports.itemSearch = exports.menuProfiloMobile = exports.menuMobile = exports.menuList = exports.menuReducer = void 0;
const reselect_1 = require("reselect");
const menuReducer = state => state;
exports.menuReducer = menuReducer;
exports.menuList = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  return state;
});
const buildMenuMobile = items => {
  let sezioniMenu = [];
  items.forEach((item, index) => {
    sezioniMenu.push({
      id: item.id || '',
      title: item.id || '',
      data: [[]]
    });
    let subMenuLists = [];
    !!item.submenu && item.submenu.forEach((subItem, subIndex) => {
      var _a, _b, _c;
      const newItemIsOpenable = subItem.tipoSubmenu == 'nestedMenu' && !!subItem.submenu && subItem.submenu.length > 0;
      const newItem = {
        id: subItem.id || '',
        data: subItem,
        isOpenable: newItemIsOpenable,
        itemRef: {
          itemIndex: Math.floor(subIndex / 3),
          rowIndex: index
        }
      };
      sezioniMenu[index].data[0].push(newItem);
      if (newItem.isOpenable) {
        const newSubMenuList = subItem.submenu.map((subSubItem, subSubIndex) => {
          var _a;
          const isFirstSubMenuRow = Math.floor(subSubIndex / 3) == 0;
          const isLastSubMenuRow = Math.floor(subSubIndex / 3) == Math.floor(((((_a = subItem.submenu) === null || _a === void 0 ? void 0 : _a.length) || 0) - 1) / 3);
          return {
            id: subSubItem.id || '',
            data: subSubItem,
            isSubMenuOfId: subItem.id || '',
            isFirstSubMenuRow,
            isLastSubMenuRow
          };
        });
        const emptySubItems = (3 - subItem.submenu.length % 3) % 3;
        for (let i = 0; i < emptySubItems; i++) {
          const isFirstSubMenuRow = Math.floor(((((_a = subItem.submenu) === null || _a === void 0 ? void 0 : _a.length) || 0) - 1) / 3) == 0;
          newSubMenuList.push({
            id: 'subItem_empty_' + i,
            data: {},
            isEmpty: true,
            isSubMenuOfId: subItem.id || '',
            isFirstSubMenuRow,
            isLastSubMenuRow: true
          });
        }
        subMenuLists.push(newSubMenuList);
      }
      if (subIndex == (((_b = item.submenu) === null || _b === void 0 ? void 0 : _b.length) || 0) - 1) {
        const emptyItems = (3 - item.submenu.length % 3) % 3;
        for (let i = 0; i < emptyItems; i++) {
          sezioniMenu[index].data[0].push({
            id: 'item_empty_' + i,
            data: {},
            isEmpty: true
          });
        }
      }
      if ((subIndex + 1) % 3 == 0 || subIndex == (((_c = item.submenu) === null || _c === void 0 ? void 0 : _c.length) || 0) - 1) {
        subMenuLists.forEach(subMenuList => {
          sezioniMenu[index].data[0] = sezioniMenu[index].data[0].concat(subMenuList);
        });
        subMenuLists = [];
      }
    });
  });
  return sezioniMenu.map(sezione => {
    return {
      ...sezione,
      data: [sezione.data[0].map((elem, i) => {
        const isLeftItem = (i + 1) % 3 == 1;
        const isRightItem = (i + 1) % 3 == 0;
        return {
          ...elem,
          isLeftItem,
          isRightItem
        };
      })]
    };
  });
};
exports.menuMobile = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b;
  if (!((_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.items)) {
    return undefined;
  }
  return buildMenuMobile((_b = state.profiloMenu) === null || _b === void 0 ? void 0 : _b.items);
});
exports.menuProfiloMobile = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b;
  if (!((_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.profiloItems)) {
    return undefined;
  }
  return buildMenuMobile((_b = state.profiloMenu) === null || _b === void 0 ? void 0 : _b.profiloItems);
});
exports.itemSearch = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b;
  let arr = [];
  let str = state === null || state === void 0 ? void 0 : state.textSearch;
  if (str.length > 2) {
    arr = ((_b = (_a = state === null || state === void 0 ? void 0 : state.profiloMenu) === null || _a === void 0 ? void 0 : _a.tags) === null || _b === void 0 ? void 0 : _b.filter(item => {
      var _a;
      return item.tags && item.tags.findIndex(element => element.toLowerCase().includes(str.toLowerCase())) >= 0 || item.menuLabel && ((_a = item.menuLabel) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(str.toLowerCase())) > -1;
    })) || [];
  }
  return arr;
});
exports.menuTextSearch = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  return state.textSearch;
});
exports.menuComunicazioni = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a;
  return (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.comunicazioniItems;
});
exports.menuProfilo = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a;
  return (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.profiloItems;
});
exports.menuAssistenza = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a;
  return (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.assistenzaItems;
});
exports.isMenuComunicazioniVisible = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b, _c, _d;
  return ((_b = (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.comunicazioniItems) === null || _b === void 0 ? void 0 : _b.length) ? ((_d = (_c = state.profiloMenu) === null || _c === void 0 ? void 0 : _c.comunicazioniItems) === null || _d === void 0 ? void 0 : _d.length) > 0 : false;
});
exports.isMenuProfiloVisible = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b, _c, _d;
  return ((_b = (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.profiloItems) === null || _b === void 0 ? void 0 : _b.length) ? ((_d = (_c = state.profiloMenu) === null || _c === void 0 ? void 0 : _c.profiloItems) === null || _d === void 0 ? void 0 : _d.length) > 0 : false;
});
exports.isMenuAssistenzaVisible = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b, _c, _d;
  return ((_b = (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.assistenzaItems) === null || _b === void 0 ? void 0 : _b.length) ? ((_d = (_c = state.profiloMenu) === null || _c === void 0 ? void 0 : _c.assistenzaItems) === null || _d === void 0 ? void 0 : _d.length) > 0 : false;
});
exports.isNotificaMenuComunicazioniVisible = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b;
  return (0, exports.isMenuComunicazioniVisible)(state) ? ((_b = (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.comunicazioniItems) === null || _b === void 0 ? void 0 : _b.some(item => {
    var _a, _b;
    return !!item.id && ((_a = state.notificheMenu) === null || _a === void 0 ? void 0 : _a.some(n => item.id == n.id && !!n.notifica)) || ((_b = item.submenu) === null || _b === void 0 ? void 0 : _b.some(itemSubmenu => {
      var _a;
      return !!itemSubmenu.id && ((_a = state.notificheMenu) === null || _a === void 0 ? void 0 : _a.some(n => itemSubmenu.id == n.id && !!n.notifica));
    }));
  })) || false : false;
});
exports.isNotificaMenuProfiloVisible = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b;
  return (0, exports.isMenuProfiloVisible)(state) ? ((_b = (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.profiloItems) === null || _b === void 0 ? void 0 : _b.some(item => {
    var _a, _b;
    return !!item.id && ((_a = state.notificheMenu) === null || _a === void 0 ? void 0 : _a.some(n => item.id == n.id && !!n.notifica)) || ((_b = item.submenu) === null || _b === void 0 ? void 0 : _b.some(itemSubmenu => {
      var _a;
      return !!itemSubmenu.id && ((_a = state.notificheMenu) === null || _a === void 0 ? void 0 : _a.some(n => itemSubmenu.id == n.id && !!n.notifica));
    }));
  })) || false : false;
});
exports.isNotificaMenuAssistenzaVisible = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b;
  return (0, exports.isMenuAssistenzaVisible)(state) ? ((_b = (_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.assistenzaItems) === null || _b === void 0 ? void 0 : _b.some(item => {
    var _a, _b;
    return !!item.id && ((_a = state.notificheMenu) === null || _a === void 0 ? void 0 : _a.some(n => item.id == n.id && !!n.notifica)) || ((_b = item.submenu) === null || _b === void 0 ? void 0 : _b.some(itemSubmenu => {
      var _a;
      return !!itemSubmenu.id && ((_a = state.notificheMenu) === null || _a === void 0 ? void 0 : _a.some(n => itemSubmenu.id == n.id && !!n.notifica));
    }));
  })) || false : false;
});
const idAssicurare = 'menu.assicurare';
const idNeosurance = 'menu.neosurance_digital_insurance';
exports.isNeosuranceVisible = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b, _c;
  if ((_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.items) {
    let found = false;
    for (let i = 0; i < ((_b = state.profiloMenu) === null || _b === void 0 ? void 0 : _b.items.length); i++) {
      if (found) {
        break;
      }
      const item = (_c = state.profiloMenu) === null || _c === void 0 ? void 0 : _c.items[i];
      if (item.id === idAssicurare && item.submenu && item.submenu.length > 0) {
        found = item.submenu.some(subitem => {
          return subitem.id === idNeosurance;
        });
      }
    }
    return found;
  } else {
    return undefined;
  }
});
exports.tabsMenu = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  var _a, _b, _c, _d, _e;
  let tabs = {};
  function recoursive(items) {
    if (items && items.length > 0) {
      items.forEach(item => {
        if (item.tipoSubmenu === 'tabMenu') {
          tabs[item.id] = item.submenu;
        } else {
          recoursive(item.submenu);
        }
      });
    }
  }
  ((_a = state.profiloMenu) === null || _a === void 0 ? void 0 : _a.items) && recoursive(state.profiloMenu.items);
  ((_b = state.profiloMenu) === null || _b === void 0 ? void 0 : _b.assistenzaItems) && recoursive(state.profiloMenu.assistenzaItems);
  ((_c = state.profiloMenu) === null || _c === void 0 ? void 0 : _c.comunicazioniItems) && recoursive(state.profiloMenu.comunicazioniItems);
  ((_d = state.profiloMenu) === null || _d === void 0 ? void 0 : _d.profiloItems) && recoursive(state.profiloMenu.profiloItems);
  ((_e = state.profiloMenu) === null || _e === void 0 ? void 0 : _e.perTeItems) && recoursive(state.profiloMenu.perTeItems);
  return tabs;
});
exports.enrolledUsername = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  return state.smartOtpEnrolledUsername;
});
exports.notificheMenu = (0, reselect_1.createSelector)(exports.menuReducer, state => {
  return state.notificheMenu;
});