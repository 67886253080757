"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfiloMenuDtoToJSON = exports.ProfiloMenuDtoFromJSONTyped = exports.ProfiloMenuDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProfiloMenuDtoFromJSON(json) {
  return ProfiloMenuDtoFromJSONTyped(json, false);
}
exports.ProfiloMenuDtoFromJSON = ProfiloMenuDtoFromJSON;
function ProfiloMenuDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'items': !runtime_1.exists(json, 'items') ? undefined : json['items'].map(_1.ProfiloMenuFromJSON),
    'profiloItems': !runtime_1.exists(json, 'profiloItems') ? undefined : json['profiloItems'].map(_1.ProfiloMenuFromJSON),
    'assistenzaItems': !runtime_1.exists(json, 'assistenzaItems') ? undefined : json['assistenzaItems'].map(_1.ProfiloMenuFromJSON),
    'comunicazioniItems': !runtime_1.exists(json, 'comunicazioniItems') ? undefined : json['comunicazioniItems'].map(_1.ProfiloMenuFromJSON),
    'perTeItems': !runtime_1.exists(json, 'perTeItems') ? undefined : json['perTeItems'].map(_1.ProfiloMenuFromJSON),
    'tags': !runtime_1.exists(json, 'tags') ? undefined : json['tags'].map(_1.TagDtoFromJSON)
  };
}
exports.ProfiloMenuDtoFromJSONTyped = ProfiloMenuDtoFromJSONTyped;
function ProfiloMenuDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'items': value.items === undefined ? undefined : value.items.map(_1.ProfiloMenuToJSON),
    'profiloItems': value.profiloItems === undefined ? undefined : value.profiloItems.map(_1.ProfiloMenuToJSON),
    'assistenzaItems': value.assistenzaItems === undefined ? undefined : value.assistenzaItems.map(_1.ProfiloMenuToJSON),
    'comunicazioniItems': value.comunicazioniItems === undefined ? undefined : value.comunicazioniItems.map(_1.ProfiloMenuToJSON),
    'perTeItems': value.perTeItems === undefined ? undefined : value.perTeItems.map(_1.ProfiloMenuToJSON),
    'tags': value.tags === undefined ? undefined : value.tags.map(_1.TagDtoToJSON)
  };
}
exports.ProfiloMenuDtoToJSON = ProfiloMenuDtoToJSON;