"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApriPraticaOutDtoToJSON = exports.ApriPraticaOutDtoFromJSONTyped = exports.ApriPraticaOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ApriPraticaOutDtoFromJSON(json) {
  return ApriPraticaOutDtoFromJSONTyped(json, false);
}
exports.ApriPraticaOutDtoFromJSON = ApriPraticaOutDtoFromJSON;
function ApriPraticaOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'success': !runtime_1.exists(json, 'success') ? undefined : json['success'],
    'datiDocumento': !runtime_1.exists(json, 'datiDocumento') ? undefined : _1.DatiDocumentoFromJSON(json['datiDocumento'])
  };
}
exports.ApriPraticaOutDtoFromJSONTyped = ApriPraticaOutDtoFromJSONTyped;
function ApriPraticaOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'success': value.success,
    'datiDocumento': _1.DatiDocumentoToJSON(value.datiDocumento)
  };
}
exports.ApriPraticaOutDtoToJSON = ApriPraticaOutDtoToJSON;