"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CofidisDtoToJSON = exports.CofidisDtoFromJSONTyped = exports.CofidisDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CofidisDtoFromJSON(json) {
  return CofidisDtoFromJSONTyped(json, false);
}
exports.CofidisDtoFromJSON = CofidisDtoFromJSON;
function CofidisDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'utenteValido': !runtime_1.exists(json, 'utenteValido') ? undefined : json['utenteValido'],
    'datiUtente': !runtime_1.exists(json, 'datiUtente') ? undefined : _1.CofidisUtenteDtoFromJSON(json['datiUtente'])
  };
}
exports.CofidisDtoFromJSONTyped = CofidisDtoFromJSONTyped;
function CofidisDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'utenteValido': value.utenteValido,
    'datiUtente': _1.CofidisUtenteDtoToJSON(value.datiUtente)
  };
}
exports.CofidisDtoToJSON = CofidisDtoToJSON;