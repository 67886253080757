"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formModificaContattoValid = exports.esitoContatti = exports.infoDispositivaContatti = exports.riepilogoContatti = exports.formDatiPersonaliAsFilter = exports.esitoDatiPersonali = exports.formDatiPersonali = exports.dataAnagrafici = void 0;
const reselect_1 = require("reselect");
const datiPersonaliReducer = state => state;
exports.dataAnagrafici = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  return state.dataAnagrafici;
});
exports.formDatiPersonali = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  return state.formDatiPersonali;
});
exports.esitoDatiPersonali = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  return state.esito;
});
exports.formDatiPersonaliAsFilter = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  var _a, _b;
  return {
    email: (_a = state.formDatiPersonali) === null || _a === void 0 ? void 0 : _a.email,
    cellulare: (_b = state.formDatiPersonali) === null || _b === void 0 ? void 0 : _b.cellulare
  };
});
exports.riepilogoContatti = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  return state.riepilogoContatti;
});
exports.infoDispositivaContatti = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  return state.infoDispositivaContatti;
});
exports.esitoContatti = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  return state.esitoContatti;
});
exports.formModificaContattoValid = (0, reselect_1.createSelector)(datiPersonaliReducer, state => {
  let formModificaContattoValid = true;
  if (state.formDatiPersonali.emailError || state.formDatiPersonali.cellulareError) {
    formModificaContattoValid = false;
  }
  return formModificaContattoValid;
});
// export const formValidDatiPersonali = createSelector(
//     datiPersonaliReducer,
//     (state) => {
//         let formValid = false;
//         if (
//             !state.formDatiPersonali.provinciaError &&
//             !state.formDatiPersonali.comuneError &&
//             !state.formDatiPersonali.localitaError &&
//             !state.formDatiPersonali.capError &&
//             !state.formDatiPersonali.tipoError &&
//             !state.formDatiPersonali.nomeError &&
//             !state.formDatiPersonali.numeroDomicilioError
//         ) { formValid = true; }
//         return formValid;
//     }
// );