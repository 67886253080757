"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.codiceFiscalePartitaIvaSelezionato = exports.formRibaAsFiltro = exports.buildRiba = exports.esitoRevocaRiba = exports.infoAutenticazioneRevocaRiba = exports.riepilogoRiba = exports.infoAutenticazioneRiba = exports.esitoRiba = exports.formRiba = exports.elencoRiba = exports.parametriRiba = void 0;
const reselect_1 = require("reselect");
const ribaReducer = state => state;
exports.parametriRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.parametri;
});
exports.elencoRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.elenco;
});
exports.formRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.form;
});
exports.esitoRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.esito;
});
exports.infoAutenticazioneRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.infoAutenticazione;
});
exports.riepilogoRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.riepilogo;
});
exports.infoAutenticazioneRevocaRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.infoAutenticazioneRevoca;
});
exports.esitoRevocaRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.esitoRevoca;
});
exports.buildRiba = (0, reselect_1.createSelector)(ribaReducer, state => {
  var _a, _b, _c, _d;
  return {
    codiceRapporto: ((_c = (_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
    listAvvisiRest: (_d = state.form) === null || _d === void 0 ? void 0 : _d.selectedAvvisi
  };
});
exports.formRibaAsFiltro = (0, reselect_1.createSelector)(ribaReducer, state => {
  var _a, _b, _c;
  return {
    codiceRapporto: ((_c = (_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
    codiceFiscale: state.codFiscalePIvaSelezionato
  };
});
exports.codiceFiscalePartitaIvaSelezionato = (0, reselect_1.createSelector)(ribaReducer, state => {
  return state.codFiscalePIvaSelezionato;
});