"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anatocismoReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
  anatocismo: (0, api_rest_1.PreferenzeAnatocismoDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.ControlloAnatocismoDtoFromJSON)({}),
  infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneAnatocismoDtoFromJSON)({}),
  form: {
    step: 0,
    rapporti: []
  }
};
const anatocismoReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.ANATOCISMO_ACCETTATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapporti: (state.anatocismo.rapporti || []).filter((r, i) => (i === action.index || action.index < 0) && r.accettato !== action.payload),
          accetta: action.payload
        }
      };
    case __1.EReduxActionTypes.ANATOCISMO_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.ANATOCISMO_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.ANATOCISMO_GET_CONSENSI_SUCCESS:
      return {
        ...state,
        anatocismo: action.payload
      };
    case __1.EReduxActionTypes.ANATOCISMO_CONTROLLO_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.ANATOCISMO_GET_INFO_DISPOSITIVA_SUCCESS:
      return {
        ...state,
        infoDispositiva: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.ANATOCISMO_AUTORIZZA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.anatocismoReducer = anatocismoReducer;
exports.default = exports.anatocismoReducer;