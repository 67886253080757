"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiStoriciDtoToJSON = exports.DatiStoriciDtoFromJSONTyped = exports.DatiStoriciDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiStoriciDtoFromJSON(json) {
  return DatiStoriciDtoFromJSONTyped(json, false);
}
exports.DatiStoriciDtoFromJSON = DatiStoriciDtoFromJSON;
function DatiStoriciDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'totaleQuotazioni': !runtime_1.exists(json, 'totaleQuotazioni') ? undefined : json['totaleQuotazioni'],
    'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : new Date(json['dataIniziale']),
    'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : new Date(json['dataFinale']),
    'dati': !runtime_1.exists(json, 'dati') ? undefined : json['dati'].map(_1.DatiStoriciQuotazioneDtoFromJSON)
  };
}
exports.DatiStoriciDtoFromJSONTyped = DatiStoriciDtoFromJSONTyped;
function DatiStoriciDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'totaleQuotazioni': value.totaleQuotazioni,
    'dataIniziale': value.dataIniziale === undefined ? undefined : value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale === undefined ? undefined : value.dataFinale.toISOString(),
    'dati': value.dati === undefined ? undefined : value.dati.map(_1.DatiStoriciQuotazioneDtoToJSON)
  };
}
exports.DatiStoriciDtoToJSON = DatiStoriciDtoToJSON;