"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneRapportiDtoToJSON = exports.EsitoAutorizzazioneDisposizioneRapportiDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneRapportiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneRapportiDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneRapportiDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneRapportiDtoFromJSON = EsitoAutorizzazioneDisposizioneRapportiDtoFromJSON;
function EsitoAutorizzazioneDisposizioneRapportiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : json['disposizione'].map(_1.CorrispondenzaDtoFromJSON)
  };
}
exports.EsitoAutorizzazioneDisposizioneRapportiDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneRapportiDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneRapportiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': value.disposizione === undefined ? undefined : value.disposizione.map(_1.CorrispondenzaDtoToJSON)
  };
}
exports.EsitoAutorizzazioneDisposizioneRapportiDtoToJSON = EsitoAutorizzazioneDisposizioneRapportiDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneRapportiDtoStatoEnum = {}));