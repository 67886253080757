"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delBozzaArchivioBozze = exports.resetArchivioBozze = exports.setBozzeAccordionFiltriOpened = exports.getBozze = exports.setBozzeTuttiRapporti = exports.setBozzeTipologia = exports.setBozzeDescrizione = exports.setBozzeSetRapportoSelezionato = exports.getParametriBozze = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/* get parametri - START */
function getParametriBozzeStart() {
  return {
    type: __1.EReduxActionTypes.BOZZE_GET_PARAMETRI_START
  };
}
function getParametriBozzeSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOZZE_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getParametriBozze = () => async (dispatch, _, extraArguments) => {
  if (extraArguments.platform == 'mobile') {
    dispatch((0, general_actions_1.setLoader)(true));
  }
  dispatch(getParametriBozzeStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_bonifico.json')).then(parametri => {
      dispatch(getParametriBozzeSuccess((0, api_rest_1.ParametriBozzeDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BozzeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriBozze().then(response => {
        dispatch(getParametriBozzeSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriBozze = getParametriBozze;
/* get parametri - FINE */
/* action varie - START */
function setBozzeSetRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.BOZZE_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setBozzeSetRapportoSelezionato = setBozzeSetRapportoSelezionato;
function setBozzeDescrizione(txt) {
  return {
    type: __1.EReduxActionTypes.BOZZE_SET_DESCRIZIONE,
    payload: txt
  };
}
exports.setBozzeDescrizione = setBozzeDescrizione;
function setBozzeTipologia(txt) {
  return {
    type: __1.EReduxActionTypes.BOZZE_SET_TIPOLOGIA,
    payload: txt
  };
}
exports.setBozzeTipologia = setBozzeTipologia;
function setBozzeTuttiRapporti(b) {
  return {
    type: __1.EReduxActionTypes.BOZZE_SET_TUTTI_RAPPORTI,
    payload: b
  };
}
exports.setBozzeTuttiRapporti = setBozzeTuttiRapporti;
/* action varie - FINE */
/* get bozza bonifico sepa - START */
function getBozzeStart() {
  return {
    type: __1.EReduxActionTypes.BOZZE_GET_BOZZE_START
  };
}
function getBozzeSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOZZE_GET_BOZZE_SUCCESS,
    payload
  };
}
const getBozze = () => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getBozzeStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      // dispatch(getBozzeBonificoSepaSuccess(BonificoSepaDtoFromJSON(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const stateBozze = getState().bozze;
    let tipologia = undefined;
    switch (stateBozze.form.tipologia) {
      case "BOLLETTINOPOSTALEBIANCO":
        tipologia = "BIANCO";
        break;
      case "BOLLETTINOPOSTALEPREMARCATO":
        tipologia = "PREMARCATO";
        break;
    }
    const getBozzeRequest = {
      archivioBozzeInDto: {
        codiceFunzione: stateBozze.form.tipologia || 'BONIFICOSEPA',
        codiceRapporto: stateBozze.form.tuttiRapporti ? undefined : (_b = (_a = stateBozze.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id,
        paroleChiave: stateBozze.form.descrizione,
        tipologia: tipologia,
        tipoOrdinamento: api_rest_1.ArchivioBozzeInDtoTipoOrdinamentoEnum.DESC
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BozzeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bozze(getBozzeRequest).then(response => {
        dispatch(getBozzeSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getBozze = getBozze;
/* get bozza bonifico sepa - FINE */
function setBozzeAccordionFiltriOpened(opened) {
  return {
    type: __1.EReduxActionTypes.BOZZE_SET_ACCORDION_FILTRI_OPENED,
    payload: opened
  };
}
exports.setBozzeAccordionFiltriOpened = setBozzeAccordionFiltriOpened;
function resetArchivioBozze() {
  return {
    type: __1.EReduxActionTypes.BOZZE_RESET
  };
}
exports.resetArchivioBozze = resetArchivioBozze;
/* del bozza bonifico sepa - START */
function delBozzaArchivioBozzeStart() {
  return {
    type: __1.EReduxActionTypes.BOZZE_ELIMINA_BOZZA_ARCHIVIO_START
  };
}
function delBozzaArchivioBozzeSuccess(id) {
  return {
    type: __1.EReduxActionTypes.BOZZE_ELIMINA_BOZZA_ARCHIVIO_SUCCESS,
    payload: id
  };
}
const delBozzaArchivioBozze = (id, codiceFunzione) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(delBozzaArchivioBozzeStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const delBozzaArchivioBozzeRequest = {
      id,
      codiceFunzione
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BozzeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).eliminaBozza(delBozzaArchivioBozzeRequest).then(() => {
        dispatch(delBozzaArchivioBozzeSuccess(id));
        dispatch((0, general_actions_1.addSnackbar)('bozze.bozzaEliminataConSuccesso'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.delBozzaArchivioBozze = delBozzaArchivioBozze;
/* del bozza bonifico sepa - FINE */