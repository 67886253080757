import { NotificaMenuDto, ProfiloMenu } from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isFirstLevel: boolean
  items: ProfiloMenu[]
  label: string
  notificheMenu: NotificaMenuDto[] | undefined
  onClickBack: () => void
  onClickElement: (el: ProfiloMenu) => void
};

const SidebarMenuNavigatorArea = (props: Props) => {

  const { t } = useTranslation();

  const {
    isFirstLevel,
    items,
    label,
    notificheMenu,
    onClickBack,
    onClickElement
  } = props;

  return (
    <>
      <div className="menu-window-area">

        <div className="menu-window-header">
          <BankIcon
            title="freccia-sinistra"
            className={`frecciaSinistra ${isFirstLevel ? "first-level" : ""}`}
            width={44}
            height={44}
            onClick={onClickBack}
          />
          <span
            onClick={onClickBack}
          >
            {t(label)}
          </span>
        </div>

        <div className="menu-window-body">
          {items?.map(
            (el: ProfiloMenu, i: number) => {
              const hasNotification =
                el.id && (
                  notificheMenu?.some(n => n.id == el.id) ||
                  el.submenu?.some(elSubmenu => notificheMenu?.some(n => n.id == elSubmenu.id))
                );
              return (
                <div
                  key={i}
                  id={el.id || ""}
                  className="menu-window-element icone-azioni"
                  onClick={() => onClickElement(el)}
                >

                  <div className={`menu-element-icon ${hasNotification ? 'has-notification' : ''}`}>
                    <BankIcon
                      title={el.id || "notFound"}
                      active
                    />
                  </div>

                  <div className="menu-element-label">
                    {el.label === "menu.oneclick"
                      ? t("menu.elenco_oneclick")
                      : t(el.label || "notFound")
                    }
                  </div>

                  {!!el.submenu?.length && el.tipoSubmenu !== "tabMenu" && (
                    <span className="menu-item-corner" />
                  )}

                </div>
              );
            }
          )}
        </div>

      </div>

    </>
  );
};

export default SidebarMenuNavigatorArea;
