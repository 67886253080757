"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bilancioReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const bilancio_types_1 = require("./bilancio.types");
const initialState = {
  bilancio: (0, api_rest_1.BilancioDtoFromJSON)({}),
  filtri: (0, bilancio_types_1.FilterBilancioFromJSON)({})
};
const bilancioReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.BILANCIO_GET_BILANCIO_SUCCESS:
      {
        return {
          ...state,
          bilancio: action.payload
        };
      }
    case __1.EReduxActionTypes.BILANCIO_RAPPORTO_SELEZIONATO:
      {
        return {
          ...state,
          filtri: {
            ...state.filtri,
            rapportoSelezionato: action.payload,
            filtriDirty: true
          }
        };
      }
    case __1.EReduxActionTypes.BILANCIO_DATA_FINALE:
      {
        return {
          ...state,
          filtri: {
            ...state.filtri,
            dataFinale: action.payload,
            filtriDirty: true
          }
        };
      }
    case __1.EReduxActionTypes.BILANCIO_DATA_INIZIALE:
      {
        return {
          ...state,
          filtri: {
            ...state.filtri,
            dataIniziale: action.payload,
            filtriDirty: true
          }
        };
      }
    case __1.EReduxActionTypes.BILANCIO_TUTTI_RAPPORTI_SELEZIONATI:
      {
        return {
          ...state,
          filtri: {
            ...state.filtri,
            tuttiRapportiSelezionati: action.payload,
            filtriDirty: true
          }
        };
      }
    case __1.EReduxActionTypes.BILANCIO_RESET_FILTRI:
      {
        return {
          ...state,
          filtri: initialState.filtri
        };
      }
    case __1.EReduxActionTypes.BILANCIO_RESET:
      {
        return {
          ...initialState
        };
      }
    case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS:
      // action invocata tramite pull to refresh
      return {
        ...state,
        bilancio: initialState.bilancio
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      {
        return state;
      }
  }
};
exports.bilancioReducer = bilancioReducer;