"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PosizioneTitoliPerAnnoDtoToJSON = exports.PosizioneTitoliPerAnnoDtoFromJSONTyped = exports.PosizioneTitoliPerAnnoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PosizioneTitoliPerAnnoDtoFromJSON(json) {
  return PosizioneTitoliPerAnnoDtoFromJSONTyped(json, false);
}
exports.PosizioneTitoliPerAnnoDtoFromJSON = PosizioneTitoliPerAnnoDtoFromJSON;
function PosizioneTitoliPerAnnoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'anno': !runtime_1.exists(json, 'anno') ? undefined : json['anno'],
    'minusMaturate': !runtime_1.exists(json, 'minusMaturate') ? undefined : json['minusMaturate'],
    'scadenza': !runtime_1.exists(json, 'scadenza') ? undefined : json['scadenza'],
    'minusUtilizzabili': !runtime_1.exists(json, 'minusUtilizzabili') ? undefined : json['minusUtilizzabili']
  };
}
exports.PosizioneTitoliPerAnnoDtoFromJSONTyped = PosizioneTitoliPerAnnoDtoFromJSONTyped;
function PosizioneTitoliPerAnnoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'anno': value.anno,
    'minusMaturate': value.minusMaturate,
    'scadenza': value.scadenza,
    'minusUtilizzabili': value.minusUtilizzabili
  };
}
exports.PosizioneTitoliPerAnnoDtoToJSON = PosizioneTitoliPerAnnoDtoToJSON;