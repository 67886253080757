import BankIcon from "component/Icon/icon.component";
import React from "react";

interface Props {
  descrizione: string;
  icon: any;
  onClick: any;
  className?: string;
}

const ItemWidget: React.FC<Props> = (props: Props) => {
  const { icon, descrizione, onClick, className } = props;

  const onGetAction = () => {
    onClick && onClick();
  };

  return (
    <div
      className={`quick-operation ${className || ""}`}
      onClick={() => {
        onGetAction();
      }}
    >
      <div className="iconView-item-quickOpr">
        <BankIcon title={icon} />
      </div>
      <p className="txtWidgetView-item-quickOpr">{descrizione}</p>
    </div>
  );
};

export default ItemWidget;
