"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuotazioneIndiceDtoToJSON = exports.QuotazioneIndiceDtoFromJSONTyped = exports.QuotazioneIndiceDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function QuotazioneIndiceDtoFromJSON(json) {
  return QuotazioneIndiceDtoFromJSONTyped(json, false);
}
exports.QuotazioneIndiceDtoFromJSON = QuotazioneIndiceDtoFromJSON;
function QuotazioneIndiceDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'desc': !runtime_1.exists(json, 'desc') ? undefined : json['desc'],
    'data': !runtime_1.exists(json, 'data') ? undefined : new Date(json['data']),
    'valoreMax': !runtime_1.exists(json, 'valoreMax') ? undefined : json['valoreMax'],
    'valoreMin': !runtime_1.exists(json, 'valoreMin') ? undefined : json['valoreMin'],
    'trend': !runtime_1.exists(json, 'trend') ? undefined : json['trend'],
    'varPerc': !runtime_1.exists(json, 'varPerc') ? undefined : json['varPerc'],
    'ultimoPrezzo': !runtime_1.exists(json, 'ultimoPrezzo') ? undefined : json['ultimoPrezzo'],
    'apertura': !runtime_1.exists(json, 'apertura') ? undefined : json['apertura'],
    'chiusura': !runtime_1.exists(json, 'chiusura') ? undefined : json['chiusura']
  };
}
exports.QuotazioneIndiceDtoFromJSONTyped = QuotazioneIndiceDtoFromJSONTyped;
function QuotazioneIndiceDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mercato': value.mercato,
    'codice': value.codice,
    'desc': value.desc,
    'data': value.data === undefined ? undefined : value.data.toISOString(),
    'valoreMax': value.valoreMax,
    'valoreMin': value.valoreMin,
    'trend': value.trend,
    'varPerc': value.varPerc,
    'ultimoPrezzo': value.ultimoPrezzo,
    'apertura': value.apertura,
    'chiusura': value.chiusura
  };
}
exports.QuotazioneIndiceDtoToJSON = QuotazioneIndiceDtoToJSON;