"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SezioneIdentificativiDtoToJSON = exports.SezioneIdentificativiDtoFromJSONTyped = exports.SezioneIdentificativiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SezioneIdentificativiDtoFromJSON(json) {
  return SezioneIdentificativiDtoFromJSONTyped(json, false);
}
exports.SezioneIdentificativiDtoFromJSON = SezioneIdentificativiDtoFromJSON;
function SezioneIdentificativiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceUfficio': json['codiceUfficio'],
    'codiceAtto': json['codiceAtto'],
    'righe': !runtime_1.exists(json, 'righe') ? undefined : json['righe'].map(_1.RigaSezioneIdentificativiDtoFromJSON),
    'totalIdentificativi': !runtime_1.exists(json, 'totalIdentificativi') ? undefined : json['totalIdentificativi']
  };
}
exports.SezioneIdentificativiDtoFromJSONTyped = SezioneIdentificativiDtoFromJSONTyped;
function SezioneIdentificativiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceUfficio': value.codiceUfficio,
    'codiceAtto': value.codiceAtto,
    'righe': value.righe === undefined ? undefined : value.righe.map(_1.RigaSezioneIdentificativiDtoToJSON),
    'totalIdentificativi': value.totalIdentificativi
  };
}
exports.SezioneIdentificativiDtoToJSON = SezioneIdentificativiDtoToJSON;