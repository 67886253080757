"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoToJSON = exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSON = EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSON;
function EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.CredenzialiDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneCredenzialiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.CredenzialiDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoToJSON = EsitoAutorizzazioneDisposizioneCredenzialiDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneCredenzialiDtoStatoEnum = {}));