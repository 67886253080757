"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setRapportoSelezionatoCarouselPrestitoIdea = exports.getDettaglioInformativaPrestitoIdea = exports.getParametriPrestitoIdea = exports.resetPrestitoIdea = exports.getURLPrestitoIdea = exports.setRapportoDatiUtentePrestitoIdea = exports.getDatiUtentePrestitoIdea = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const prestitoIdea_selectors_1 = require("./prestitoIdea.selectors");
/* get dati utente prestito idea - START */
function getDatiUtentePrestitoIdeaStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_DATI_UTENTE_START
  };
}
function getDatiUtentePrestitoIdeaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_DATI_UTENTE_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getDatiUtentePrestitoIdea = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDatiUtentePrestitoIdeaStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CofidisControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).datiUtente().then(response => {
        dispatch(getDatiUtentePrestitoIdeaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDatiUtentePrestitoIdea = getDatiUtentePrestitoIdea;
/* get dati utente prestito idea - FINE */
/* set rapporto dati utente prestito idea - START */
function setRapportoDatiUtentePrestitoIdea(rapporto) {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_SET_RAPPORTO_DATI_UTENTE,
    payload: rapporto
  };
}
exports.setRapportoDatiUtentePrestitoIdea = setRapportoDatiUtentePrestitoIdea;
/* set rapporto dati utente prestito idea - FINE */
/* get URL prestito idea - START */
function getURLPrestitoIdeaStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_URL_START
  };
}
function getURLPrestitoIdeaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_URL_SUCCESS,
    payload
  };
}
const getURLPrestitoIdea = () => async (dispatch, getState, extraArguments) => {
  const state = getState().prestitoIdea;
  const datiUtente = (0, prestitoIdea_selectors_1.datiUtentePrestitoIdea)(state) || {};
  const rapportoSelezionato = (0, prestitoIdea_selectors_1.rapportoSelezionatoPrestitoIdea)(state) || '';
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getURLPrestitoIdeaStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      var _a, _b, _c;
      const requestParameters = {
        cofidisInDto: {
          codiceFiscale: ((_a = datiUtente.datiUtente) === null || _a === void 0 ? void 0 : _a.codiceFiscale) || '',
          nome: ((_b = datiUtente.datiUtente) === null || _b === void 0 ? void 0 : _b.nome) || '',
          cognome: ((_c = datiUtente.datiUtente) === null || _c === void 0 ? void 0 : _c.cognome) || '',
          iban: rapportoSelezionato
        }
      };
      new api_rest_1.CofidisControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).cofidisUrl(requestParameters).then(response => {
        dispatch(getURLPrestitoIdeaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getURLPrestitoIdea = getURLPrestitoIdea;
/* get URL prestito idea - FINE */
function resetPrestitoIdea() {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_RESET
  };
}
exports.resetPrestitoIdea = resetPrestitoIdea;
/* get parametri prestito idea - START */
function getParametriPrestitoIdeaStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_PARAMETRI_START
  };
}
function getParametriPrestitoIdeaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_PARAMETRI_SUCCESS,
    payload
  };
}
const getParametriPrestitoIdea = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriPrestitoIdeaStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CofidisControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriInformativaCofidis().then(response => {
        dispatch(getParametriPrestitoIdeaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriPrestitoIdea = getParametriPrestitoIdea;
/* get parametri prestito idea - END */
/* get dati info prestito idea - START */
function getDettaglioInformativaPrestitoIdeaStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_DETTAGLIO_INFORMATIVA_START
  };
}
function getDettaglioInformativaPrestitoIdeaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_GET_DETTAGLIO_INFORMATIVA_SUCCESS,
    payload
  };
}
const getDettaglioInformativaPrestitoIdea = codiceRapporto => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDettaglioInformativaPrestitoIdeaStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      var _a;
      const request = {
        codiceRapporto: !!codiceRapporto ? codiceRapporto : (_a = getState().prestitoIdea.rapportoSelezionatoCarousel.codiceRapporto) !== null && _a !== void 0 ? _a : 0
      };
      new api_rest_1.CofidisControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).dettaglioInformativaCofidis(request).then(response => {
        dispatch(getDettaglioInformativaPrestitoIdeaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDettaglioInformativaPrestitoIdea = getDettaglioInformativaPrestitoIdea;
/* get dati info prestito idea - END */
/* set rapporto selezionato carousel rapporti prestito idea - START */
function setRapportoSelezionatoCarouselPrestitoIdea(rapporto) {
  return {
    type: __1.EReduxActionTypes.PRESTITO_IDEA_SET_RAPPORTO_SELEZIONATO_CAROUSEL,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoCarouselPrestitoIdea = setRapportoSelezionatoCarouselPrestitoIdea;
/* set rapporto selezionato carousel rapporti prestito idea - FINE */