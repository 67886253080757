"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetPrestiti = exports.resetDashboardPrestiti = exports.resetRatePrestiti = exports.getDashboardPrestiti = exports.setPrestitiRapportoSelezionato = exports.getPianoAmmortamentoPrestiti = exports.getRateMoraPrestiti = exports.getRatePagatePrestiti = exports.getRateResiduePrestiti = exports.getParametriPrestiti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const prestiti_selectors_1 = require("./prestiti.selectors");
/* get parametri prestiti- START */
function getParametriPrestitiStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_PARAMETRI_START
  };
}
function getParametriPrestitiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getParametriPrestiti = function () {
  let loaderEnabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriPrestitiStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/parametri_prestiti.json')).then(parametri => {
        dispatch(getParametriPrestitiSuccess((0, api_rest_1.ParametriFinanziamentiDtoFromJSON)(parametri)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.PrestitiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriPrestiti().then(response => {
          var _a;
          dispatch(getParametriPrestitiSuccess(response));
          ((_a = response.finanziamenti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({
            type: general_types_1.TypeNoRapporti.PRESTITI
          }));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getParametriPrestiti = getParametriPrestiti;
/* get parametri prestiti- FINE */
/* rate residue prestiti - START */
function getRateResiduePrestitiStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_RATE_RESIDUE_START
  };
}
function getRateResiduePrestitiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_RATE_RESIDUE_SUCCESS,
    payload
  };
}
const getRateResiduePrestiti = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRateResiduePrestitiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getRateResiduePrestitiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, prestiti_selectors_1.formPrestitiAsFiltro)(getState().prestiti);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PrestitiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getRateResiduePrestiti(request).then(response => {
        dispatch(getRateResiduePrestitiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRateResiduePrestiti = getRateResiduePrestiti;
/* rate residue prestiti - FINE */
/* rate pagate prestiti - START */
function getRatePagatePrestitiStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_RATE_PAGATE_START
  };
}
function getRatePagatePrestitiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_RATE_PAGATE_SUCCESS,
    payload
  };
}
const getRatePagatePrestiti = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRatePagatePrestitiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getRatePagatePrestitiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, prestiti_selectors_1.formPrestitiAsFiltro)(getState().prestiti);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PrestitiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getRatePagatePrestiti(request).then(response => {
        dispatch(getRatePagatePrestitiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRatePagatePrestiti = getRatePagatePrestiti;
/* rate pagate prestiti - FINE */
/* rate mora prestiti - START */
function getRateMoraPrestitiStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_RATE_MORA_START
  };
}
function getRateMoraPrestitiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_RATE_MORA_SUCCESS,
    payload
  };
}
const getRateMoraPrestiti = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRateMoraPrestitiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getRateMoraPrestitiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, prestiti_selectors_1.formPrestitiAsFiltro)(getState().prestiti);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PrestitiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getRateMoraPrestiti(request).then(response => {
        dispatch(getRateMoraPrestitiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRateMoraPrestiti = getRateMoraPrestiti;
/* rate mora prestiti - FINE */
/* piano ammortamento prestiti - START */
function getPianoAmmortamentoPrestitiStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_PIANO_AMMORTAMENTO_START
  };
}
function getPianoAmmortamentoPrestitiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_PIANO_AMMORTAMENTO_SUCCESS,
    payload
  };
}
const getPianoAmmortamentoPrestiti = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getPianoAmmortamentoPrestitiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getPianoAmmortamentoPrestitiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, prestiti_selectors_1.formPrestitiAsFiltro)(getState().prestiti);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PrestitiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getPianoAmmortamentoPrestiti(request).then(response => {
        dispatch(getPianoAmmortamentoPrestitiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getPianoAmmortamentoPrestiti = getPianoAmmortamentoPrestiti;
/* piano ammortamento prestiti - FINE */
/* set form values - START */
function setPrestitiRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.PRESTITI_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setPrestitiRapportoSelezionato = setPrestitiRapportoSelezionato;
/* get dashboard prestiti - START */
function getDashboardPrestitiStart() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_DASHBOARD_START
  };
}
function getDashboardPrestitiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PRESTITI_GET_DASHBOARD_SUCCESS,
    payload
  };
}
const getDashboardPrestiti = function (filtroFinanziamenti) {
  let loaderEnabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return async (dispatch, getState, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDashboardPrestitiStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/dashboard_prestiti.json')).then(parametri => {
        dispatch(getDashboardPrestitiSuccess((0, api_rest_1.FinanziamentiDashboardDtoFromJSON)(parametri)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      const requestIn = filtroFinanziamenti || (0, prestiti_selectors_1.formPrestitiAsFiltro)(getState().prestiti);
      const request = {
        filtroFinanziamentiDto: requestIn
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.PrestitiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDashboardPrestiti(request).then(response => {
          dispatch(getDashboardPrestitiSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getDashboardPrestiti = getDashboardPrestiti;
function resetRatePrestiti() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_RESET_RATE
  };
}
exports.resetRatePrestiti = resetRatePrestiti;
function resetDashboardPrestiti() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_RESET_DASHBOARD
  };
}
exports.resetDashboardPrestiti = resetDashboardPrestiti;
/* get dashboard prestiti - FINE */
function resetPrestiti() {
  return {
    type: __1.EReduxActionTypes.PRESTITI_RESET
  };
}
exports.resetPrestiti = resetPrestiti;