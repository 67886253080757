"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriTimeDepositDtoToJSON = exports.ParametriTimeDepositDtoFromJSONTyped = exports.ParametriTimeDepositDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriTimeDepositDtoFromJSON(json) {
  return ParametriTimeDepositDtoFromJSONTyped(json, false);
}
exports.ParametriTimeDepositDtoFromJSON = ParametriTimeDepositDtoFromJSON;
function ParametriTimeDepositDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapportiInfo': !runtime_1.exists(json, 'rapportiInfo') ? undefined : json['rapportiInfo'].map(_1.RapportoCompletoDtoFromJSON),
    'rapportiAcquisto': !runtime_1.exists(json, 'rapportiAcquisto') ? undefined : json['rapportiAcquisto'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri']
  };
}
exports.ParametriTimeDepositDtoFromJSONTyped = ParametriTimeDepositDtoFromJSONTyped;
function ParametriTimeDepositDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapportiInfo': value.rapportiInfo === undefined ? undefined : value.rapportiInfo.map(_1.RapportoCompletoDtoToJSON),
    'rapportiAcquisto': value.rapportiAcquisto === undefined ? undefined : value.rapportiAcquisto.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri
  };
}
exports.ParametriTimeDepositDtoToJSON = ParametriTimeDepositDtoToJSON;