"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModificaTokenPinDtoToJSON = exports.ModificaTokenPinDtoFromJSONTyped = exports.ModificaTokenPinDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ModificaTokenPinDtoFromJSON(json) {
  return ModificaTokenPinDtoFromJSONTyped(json, false);
}
exports.ModificaTokenPinDtoFromJSON = ModificaTokenPinDtoFromJSON;
function ModificaTokenPinDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idServerChiamante': !runtime_1.exists(json, 'idServerChiamante') ? undefined : json['idServerChiamante'],
    'idOperazione': !runtime_1.exists(json, 'idOperazione') ? undefined : json['idOperazione'],
    'idApplicazioneChiamante': !runtime_1.exists(json, 'idApplicazioneChiamante') ? undefined : json['idApplicazioneChiamante'],
    'authorization': !runtime_1.exists(json, 'authorization') ? undefined : json['authorization'],
    'body': !runtime_1.exists(json, 'body') ? undefined : _1.ModificaPinTokenUtenteRichiestaDtoFromJSON(json['body']),
    'idSessioneChiamante': !runtime_1.exists(json, 'idSessioneChiamante') ? undefined : json['idSessioneChiamante'],
    'identityStore': !runtime_1.exists(json, 'identityStore') ? undefined : json['identityStore']
  };
}
exports.ModificaTokenPinDtoFromJSONTyped = ModificaTokenPinDtoFromJSONTyped;
function ModificaTokenPinDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idServerChiamante': value.idServerChiamante,
    'idOperazione': value.idOperazione,
    'idApplicazioneChiamante': value.idApplicazioneChiamante,
    'authorization': value.authorization,
    'body': _1.ModificaPinTokenUtenteRichiestaDtoToJSON(value.body),
    'idSessioneChiamante': value.idSessioneChiamante,
    'identityStore': value.identityStore
  };
}
exports.ModificaTokenPinDtoToJSON = ModificaTokenPinDtoToJSON;