"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildRichiestaPDF = exports.formValidFilialeVirtuale = exports.contattiFilialeVirtualeNumeroEstero = exports.contattiFilialeVirtuale = exports.filtriFilialeVirtualeApplicati = exports.filtriFilialeVirtualeDirty = exports.dettaglioRichiestaUtente = exports.infoAutenticazioneFilialeVirtuale = exports.riepilogoFilialeVirtuale = exports.esitoFilialeVirtuale = exports.formFilialeVirtuale = exports.buildFilterElencoRichiesteUtente = exports.elencoRichiesteUtente = exports.filtriFilialeVirtuale = exports.templateSelezionato = exports.parametriFilialeVirtuale = exports.motiviRichiesteList = exports.templateList = void 0;
const reselect_1 = require("reselect");
const moment = require("moment");
const filialeVirtualeReducer = state => state;
exports.templateList = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.templateRichieste;
});
exports.motiviRichiesteList = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.motiviRichieste;
});
exports.parametriFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.parametri;
});
exports.templateSelezionato = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.templateSelezionato;
});
exports.filtriFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.filter;
});
exports.elencoRichiesteUtente = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.elencoRichieste;
});
exports.buildFilterElencoRichiesteUtente = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  var _a, _b, _c;
  let filter = {
    descrizione: (_a = state.filter.descrizione) !== null && _a !== void 0 ? _a : undefined,
    dataInoltroA: state.filter.dataRichiestaA ? state.filter.dataRichiestaA : ((_b = state === null || state === void 0 ? void 0 : state.parametri) === null || _b === void 0 ? void 0 : _b.parametri) ? new Date(state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_FINALE'].toString()) : new Date(),
    dataInoltroDa: state.filter.dataRichiestaDa ? state.filter.dataRichiestaDa : ((_c = state === null || state === void 0 ? void 0 : state.parametri) === null || _c === void 0 ? void 0 : _c.parametri) ? new Date(state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_INIZIALEA'].toString()) : new Date()
  };
  return filter;
});
exports.formFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.form;
});
exports.esitoFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.esito;
});
exports.riepilogoFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.riepilogo;
});
exports.infoAutenticazioneFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.infoAutenticazione;
});
exports.dettaglioRichiestaUtente = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.dettaglioRichiesta;
});
exports.filtriFilialeVirtualeDirty = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.filter.filterDirty;
});
exports.filtriFilialeVirtualeApplicati = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  var _a, _b;
  let dataInizialeDefault = undefined;
  let dataFinaleDefault = undefined;
  if (((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) && state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_INIZIALE']) {
    dataInizialeDefault = new Date(state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_INIZIALE'].toString());
  }
  if (((_b = state.parametri) === null || _b === void 0 ? void 0 : _b.parametri) && state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_FINALE']) {
    dataFinaleDefault = new Date(state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_FINALE'].toString());
  }
  if (moment(state.filter.dataRichiestaDa).format('DD-MM-YYYY') !== moment(dataInizialeDefault).format('DD-MM-YYYY') || moment(state.filter.dataRichiestaA).format('DD-MM-YYYY') !== moment(dataFinaleDefault).format('DD-MM-YYYY') || state.filter.descrizione) {
    return true;
  }
  return false;
});
exports.contattiFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  return state.contatti;
});
exports.contattiFilialeVirtualeNumeroEstero = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  var _a, _b;
  return !!((_a = state.contatti) === null || _a === void 0 ? void 0 : _a.contattiList) ? (_b = state.contatti) === null || _b === void 0 ? void 0 : _b.contattiList[0].numeroContattoEstero : undefined;
});
exports.formValidFilialeVirtuale = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  var _a, _b, _c;
  let valid = (_c = (_b = (_a = state.templateSelezionato) === null || _a === void 0 ? void 0 : _a.campi) === null || _b === void 0 ? void 0 : _b.every(value => !value.error)) !== null && _c !== void 0 ? _c : false;
  return valid;
});
exports.buildRichiestaPDF = (0, reselect_1.createSelector)(filialeVirtualeReducer, state => {
  var _a, _b;
  return ((_a = state.dettaglioRichiesta) === null || _a === void 0 ? void 0 : _a.id) ? state.dettaglioRichiesta : (_b = state.esito) === null || _b === void 0 ? void 0 : _b.disposizione;
});