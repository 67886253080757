"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DisposizioneDtoStatoDisposizioneEnum = exports.DisposizioneDtoTipoDisposizioneEnum = exports.DisposizioneDtoTipoDataEnum = exports.DisposizioneDtoPeriodoEnum = exports.DisposizioneDtoTipoPeriodoEnum = exports.DisposizioneDtoTipoImportoEnum = exports.DisposizioneDtoToJSON = exports.DisposizioneDtoFromJSONTyped = exports.DisposizioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DisposizioneDtoFromJSON(json) {
  return DisposizioneDtoFromJSONTyped(json, false);
}
exports.DisposizioneDtoFromJSON = DisposizioneDtoFromJSON;
function DisposizioneDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoImporto': !runtime_1.exists(json, 'tipoImporto') ? undefined : json['tipoImporto'],
    'importoBase': !runtime_1.exists(json, 'importoBase') ? undefined : json['importoBase'],
    'tipoPeriodo': !runtime_1.exists(json, 'tipoPeriodo') ? undefined : json['tipoPeriodo'],
    'periodo': !runtime_1.exists(json, 'periodo') ? undefined : json['periodo'],
    'descrizionePeriodo': !runtime_1.exists(json, 'descrizionePeriodo') ? undefined : json['descrizionePeriodo'],
    'motivazioneOrdine': !runtime_1.exists(json, 'motivazioneOrdine') ? undefined : json['motivazioneOrdine'],
    'tipoData': !runtime_1.exists(json, 'tipoData') ? undefined : json['tipoData'],
    'giornoSelezionato': !runtime_1.exists(json, 'giornoSelezionato') ? undefined : json['giornoSelezionato'],
    'meseSelezionato': !runtime_1.exists(json, 'meseSelezionato') ? undefined : json['meseSelezionato'],
    'annoSelezionato': !runtime_1.exists(json, 'annoSelezionato') ? undefined : json['annoSelezionato'],
    'fineMese': !runtime_1.exists(json, 'fineMese') ? undefined : json['fineMese'],
    'numeroRateAnno': !runtime_1.exists(json, 'numeroRateAnno') ? undefined : json['numeroRateAnno'],
    'numeroRateTotali': !runtime_1.exists(json, 'numeroRateTotali') ? undefined : json['numeroRateTotali'],
    'tipoDisposizione': !runtime_1.exists(json, 'tipoDisposizione') ? undefined : json['tipoDisposizione'],
    'dataFineDisposizione': !runtime_1.exists(json, 'dataFineDisposizione') ? undefined : new Date(json['dataFineDisposizione']),
    'dataPrimaRata': !runtime_1.exists(json, 'dataPrimaRata') ? undefined : new Date(json['dataPrimaRata']),
    'importoPrimaRata': !runtime_1.exists(json, 'importoPrimaRata') ? undefined : json['importoPrimaRata'],
    'dataUltimaRata': !runtime_1.exists(json, 'dataUltimaRata') ? undefined : new Date(json['dataUltimaRata']),
    'importoUltimaRata': !runtime_1.exists(json, 'importoUltimaRata') ? undefined : json['importoUltimaRata'],
    'tabellaRate': !runtime_1.exists(json, 'tabellaRate') ? undefined : json['tabellaRate'].map(_1.RataDtoFromJSON),
    'listino': !runtime_1.exists(json, 'listino') ? undefined : json['listino'],
    'descrizioneListino': !runtime_1.exists(json, 'descrizioneListino') ? undefined : json['descrizioneListino'],
    'commissioneOrdinante': !runtime_1.exists(json, 'commissioneOrdinante') ? undefined : json['commissioneOrdinante'],
    'descrizioneCommissione': !runtime_1.exists(json, 'descrizioneCommissione') ? undefined : json['descrizioneCommissione'],
    'giorniStacco': !runtime_1.exists(json, 'giorniStacco') ? undefined : json['giorniStacco'],
    'tipoStacco': !runtime_1.exists(json, 'tipoStacco') ? undefined : json['tipoStacco'],
    'commissioneBeneficiario': !runtime_1.exists(json, 'commissioneBeneficiario') ? undefined : json['commissioneBeneficiario'],
    'codiceDisposizione': !runtime_1.exists(json, 'codiceDisposizione') ? undefined : json['codiceDisposizione'],
    'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    'descrizioneStato': !runtime_1.exists(json, 'descrizioneStato') ? undefined : json['descrizioneStato'],
    'numeroRatePagate': !runtime_1.exists(json, 'numeroRatePagate') ? undefined : json['numeroRatePagate'],
    'numeroRateAnnullate': !runtime_1.exists(json, 'numeroRateAnnullate') ? undefined : json['numeroRateAnnullate'],
    'numeroRateSospese': !runtime_1.exists(json, 'numeroRateSospese') ? undefined : json['numeroRateSospese'],
    'dataFineDisposizioneCli': !runtime_1.exists(json, 'dataFineDisposizioneCli') ? undefined : new Date(json['dataFineDisposizioneCli']),
    'giorniStaccoListino': !runtime_1.exists(json, 'giorniStaccoListino') ? undefined : json['giorniStaccoListino'],
    'tipoStaccoListino': !runtime_1.exists(json, 'tipoStaccoListino') ? undefined : json['tipoStaccoListino'],
    'codiceDisposizioneCollegata': !runtime_1.exists(json, 'codiceDisposizioneCollegata') ? undefined : json['codiceDisposizioneCollegata'],
    'importoSemplice': !runtime_1.exists(json, 'importoSemplice') ? undefined : json['importoSemplice']
  };
}
exports.DisposizioneDtoFromJSONTyped = DisposizioneDtoFromJSONTyped;
function DisposizioneDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoImporto': value.tipoImporto,
    'importoBase': value.importoBase,
    'tipoPeriodo': value.tipoPeriodo,
    'periodo': value.periodo,
    'descrizionePeriodo': value.descrizionePeriodo,
    'motivazioneOrdine': value.motivazioneOrdine,
    'tipoData': value.tipoData,
    'giornoSelezionato': value.giornoSelezionato,
    'meseSelezionato': value.meseSelezionato,
    'annoSelezionato': value.annoSelezionato,
    'fineMese': value.fineMese,
    'numeroRateAnno': value.numeroRateAnno,
    'numeroRateTotali': value.numeroRateTotali,
    'tipoDisposizione': value.tipoDisposizione,
    'dataFineDisposizione': value.dataFineDisposizione === undefined ? undefined : value.dataFineDisposizione.toISOString(),
    'dataPrimaRata': value.dataPrimaRata === undefined ? undefined : value.dataPrimaRata.toISOString(),
    'importoPrimaRata': value.importoPrimaRata,
    'dataUltimaRata': value.dataUltimaRata === undefined ? undefined : value.dataUltimaRata.toISOString(),
    'importoUltimaRata': value.importoUltimaRata,
    'tabellaRate': value.tabellaRate === undefined ? undefined : value.tabellaRate.map(_1.RataDtoToJSON),
    'listino': value.listino,
    'descrizioneListino': value.descrizioneListino,
    'commissioneOrdinante': value.commissioneOrdinante,
    'descrizioneCommissione': value.descrizioneCommissione,
    'giorniStacco': value.giorniStacco,
    'tipoStacco': value.tipoStacco,
    'commissioneBeneficiario': value.commissioneBeneficiario,
    'codiceDisposizione': value.codiceDisposizione,
    'statoDisposizione': value.statoDisposizione,
    'descrizioneStato': value.descrizioneStato,
    'numeroRatePagate': value.numeroRatePagate,
    'numeroRateAnnullate': value.numeroRateAnnullate,
    'numeroRateSospese': value.numeroRateSospese,
    'dataFineDisposizioneCli': value.dataFineDisposizioneCli === undefined ? undefined : value.dataFineDisposizioneCli.toISOString(),
    'giorniStaccoListino': value.giorniStaccoListino,
    'tipoStaccoListino': value.tipoStaccoListino,
    'codiceDisposizioneCollegata': value.codiceDisposizioneCollegata,
    'importoSemplice': value.importoSemplice
  };
}
exports.DisposizioneDtoToJSON = DisposizioneDtoToJSON;
/**
* @export
* @enum {string}
*/
var DisposizioneDtoTipoImportoEnum;
(function (DisposizioneDtoTipoImportoEnum) {
  DisposizioneDtoTipoImportoEnum["F"] = "F";
  DisposizioneDtoTipoImportoEnum["V"] = "V";
})(DisposizioneDtoTipoImportoEnum = exports.DisposizioneDtoTipoImportoEnum || (exports.DisposizioneDtoTipoImportoEnum = {}));
/**
* @export
* @enum {string}
*/
var DisposizioneDtoTipoPeriodoEnum;
(function (DisposizioneDtoTipoPeriodoEnum) {
  DisposizioneDtoTipoPeriodoEnum["F"] = "F";
  DisposizioneDtoTipoPeriodoEnum["V"] = "V";
})(DisposizioneDtoTipoPeriodoEnum = exports.DisposizioneDtoTipoPeriodoEnum || (exports.DisposizioneDtoTipoPeriodoEnum = {}));
/**
* @export
* @enum {string}
*/
var DisposizioneDtoPeriodoEnum;
(function (DisposizioneDtoPeriodoEnum) {
  DisposizioneDtoPeriodoEnum["SE"] = "SE";
  DisposizioneDtoPeriodoEnum["DE"] = "DE";
  DisposizioneDtoPeriodoEnum["QU"] = "QU";
  DisposizioneDtoPeriodoEnum["ME"] = "ME";
  DisposizioneDtoPeriodoEnum["BI"] = "BI";
  DisposizioneDtoPeriodoEnum["TR"] = "TR";
  DisposizioneDtoPeriodoEnum["Q1"] = "Q1";
  DisposizioneDtoPeriodoEnum["Q_1"] = "Q_1";
  DisposizioneDtoPeriodoEnum["S1"] = "S1";
  DisposizioneDtoPeriodoEnum["S_1"] = "S_1";
  DisposizioneDtoPeriodoEnum["AN"] = "AN";
})(DisposizioneDtoPeriodoEnum = exports.DisposizioneDtoPeriodoEnum || (exports.DisposizioneDtoPeriodoEnum = {}));
/**
* @export
* @enum {string}
*/
var DisposizioneDtoTipoDataEnum;
(function (DisposizioneDtoTipoDataEnum) {
  DisposizioneDtoTipoDataEnum["AD"] = "AD";
  DisposizioneDtoTipoDataEnum["AC"] = "AC";
})(DisposizioneDtoTipoDataEnum = exports.DisposizioneDtoTipoDataEnum || (exports.DisposizioneDtoTipoDataEnum = {}));
/**
* @export
* @enum {string}
*/
var DisposizioneDtoTipoDisposizioneEnum;
(function (DisposizioneDtoTipoDisposizioneEnum) {
  DisposizioneDtoTipoDisposizioneEnum["R"] = "R";
  DisposizioneDtoTipoDisposizioneEnum["N"] = "N";
  DisposizioneDtoTipoDisposizioneEnum["S"] = "S";
})(DisposizioneDtoTipoDisposizioneEnum = exports.DisposizioneDtoTipoDisposizioneEnum || (exports.DisposizioneDtoTipoDisposizioneEnum = {}));
/**
* @export
* @enum {string}
*/
var DisposizioneDtoStatoDisposizioneEnum;
(function (DisposizioneDtoStatoDisposizioneEnum) {
  DisposizioneDtoStatoDisposizioneEnum["A"] = "A";
  DisposizioneDtoStatoDisposizioneEnum["C"] = "C";
  DisposizioneDtoStatoDisposizioneEnum["D"] = "D";
  DisposizioneDtoStatoDisposizioneEnum["E"] = "E";
  DisposizioneDtoStatoDisposizioneEnum["R"] = "R";
  DisposizioneDtoStatoDisposizioneEnum["S"] = "S";
})(DisposizioneDtoStatoDisposizioneEnum = exports.DisposizioneDtoStatoDisposizioneEnum || (exports.DisposizioneDtoStatoDisposizioneEnum = {}));