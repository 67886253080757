"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiEsecuzioneBonificoDtoToJSON = exports.DatiEsecuzioneBonificoDtoFromJSONTyped = exports.DatiEsecuzioneBonificoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiEsecuzioneBonificoDtoFromJSON(json) {
  return DatiEsecuzioneBonificoDtoFromJSONTyped(json, false);
}
exports.DatiEsecuzioneBonificoDtoFromJSON = DatiEsecuzioneBonificoDtoFromJSON;
function DatiEsecuzioneBonificoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'cro': !runtime_1.exists(json, 'cro') ? undefined : json['cro'],
    'timestampInserimento': !runtime_1.exists(json, 'timestampInserimento') ? undefined : new Date(json['timestampInserimento']),
    'bic': !runtime_1.exists(json, 'bic') ? undefined : json['bic'],
    'importoCommissioni': !runtime_1.exists(json, 'importoCommissioni') ? undefined : json['importoCommissioni'],
    'importoTotale': !runtime_1.exists(json, 'importoTotale') ? undefined : json['importoTotale'],
    'dataAddebito': !runtime_1.exists(json, 'dataAddebito') ? undefined : new Date(json['dataAddebito']),
    'dataEsecuzioneAddebito': !runtime_1.exists(json, 'dataEsecuzioneAddebito') ? undefined : new Date(json['dataEsecuzioneAddebito']),
    'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : new Date(json['dataValuta']),
    'dataRegolamento': !runtime_1.exists(json, 'dataRegolamento') ? undefined : new Date(json['dataRegolamento']),
    'denominazioneBanca': !runtime_1.exists(json, 'denominazioneBanca') ? undefined : json['denominazioneBanca'],
    'denominazioneSportello': !runtime_1.exists(json, 'denominazioneSportello') ? undefined : json['denominazioneSportello'],
    'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
    'riferimentoOperazione': !runtime_1.exists(json, 'riferimentoOperazione') ? undefined : json['riferimentoOperazione'],
    'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : new Date(json['dataContabile']),
    'urgente': json['urgente']
  };
}
exports.DatiEsecuzioneBonificoDtoFromJSONTyped = DatiEsecuzioneBonificoDtoFromJSONTyped;
function DatiEsecuzioneBonificoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'cro': value.cro,
    'timestampInserimento': value.timestampInserimento === undefined ? undefined : value.timestampInserimento.toISOString(),
    'bic': value.bic,
    'importoCommissioni': value.importoCommissioni,
    'importoTotale': value.importoTotale,
    'dataAddebito': value.dataAddebito === undefined ? undefined : value.dataAddebito.toISOString(),
    'dataEsecuzioneAddebito': value.dataEsecuzioneAddebito === undefined ? undefined : value.dataEsecuzioneAddebito.toISOString(),
    'dataValuta': value.dataValuta === undefined ? undefined : value.dataValuta.toISOString(),
    'dataRegolamento': value.dataRegolamento === undefined ? undefined : value.dataRegolamento.toISOString(),
    'denominazioneBanca': value.denominazioneBanca,
    'denominazioneSportello': value.denominazioneSportello,
    'revocabile': value.revocabile,
    'riferimentoOperazione': value.riferimentoOperazione,
    'urgente': value.urgente
  };
}
exports.DatiEsecuzioneBonificoDtoToJSON = DatiEsecuzioneBonificoDtoToJSON;