"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zainettoFiscaleReducer = void 0;
const zainettoFiscale_types_1 = require("./zainettoFiscale.types");
const initialState = {
  posizioneTitoli: {}
};
const zainettoFiscaleReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case zainettoFiscale_types_1.EReduxZainettoFiscaleActionTypes.ZAINETTO_FISCALE_GETPOSIZIONE_TITOLI_SUCCESS:
      return {
        ...state,
        posizioneTitoli: action.payload
      };
    default:
      return state;
  }
};
exports.zainettoFiscaleReducer = zainettoFiscaleReducer;
exports.default = exports.zainettoFiscaleReducer;