"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartaNexiDtoVisualEnum = exports.CartaNexiDtoToJSON = exports.CartaNexiDtoFromJSONTyped = exports.CartaNexiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CartaNexiDtoFromJSON(json) {
  return CartaNexiDtoFromJSONTyped(json, false);
}
exports.CartaNexiDtoFromJSON = CartaNexiDtoFromJSON;
function CartaNexiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : _1.CartaNexiIdDtoFromJSON(json['id']),
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'circuito': !runtime_1.exists(json, 'circuito') ? undefined : json['circuito'],
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'productCode': !runtime_1.exists(json, 'productCode') ? undefined : json['productCode'],
    'saldoUtilizzato': !runtime_1.exists(json, 'saldoUtilizzato') ? undefined : _1.SaldoCartaNexiDtoFromJSON(json['saldoUtilizzato']),
    'disponibilitaResidua': !runtime_1.exists(json, 'disponibilitaResidua') ? undefined : _1.SaldoCartaNexiDtoFromJSON(json['disponibilitaResidua']),
    'limiteMensile': !runtime_1.exists(json, 'limiteMensile') ? undefined : _1.SaldoCartaNexiDtoFromJSON(json['limiteMensile']),
    'limiteGiornaliero': !runtime_1.exists(json, 'limiteGiornaliero') ? undefined : _1.SaldoCartaNexiDtoFromJSON(json['limiteGiornaliero']),
    'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
    'panBin': !runtime_1.exists(json, 'panBin') ? undefined : json['panBin'],
    'accountId': !runtime_1.exists(json, 'accountId') ? undefined : json['accountId'],
    'visual': !runtime_1.exists(json, 'visual') ? undefined : json['visual'],
    'processor': !runtime_1.exists(json, 'processor') ? undefined : json['processor'],
    'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType']
  };
}
exports.CartaNexiDtoFromJSONTyped = CartaNexiDtoFromJSONTyped;
function CartaNexiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': _1.CartaNexiIdDtoToJSON(value.id),
    'intestazione': value.intestazione,
    'iban': value.iban,
    'circuito': value.circuito,
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'productCode': value.productCode,
    'saldoUtilizzato': _1.SaldoCartaNexiDtoToJSON(value.saldoUtilizzato),
    'disponibilitaResidua': _1.SaldoCartaNexiDtoToJSON(value.disponibilitaResidua),
    'limiteMensile': _1.SaldoCartaNexiDtoToJSON(value.limiteMensile),
    'limiteGiornaliero': _1.SaldoCartaNexiDtoToJSON(value.limiteGiornaliero),
    'pan': value.pan,
    'panBin': value.panBin,
    'accountId': value.accountId,
    'visual': value.visual,
    'processor': value.processor,
    'cardType': value.cardType
  };
}
exports.CartaNexiDtoToJSON = CartaNexiDtoToJSON;
/**
* @export
* @enum {string}
*/
var CartaNexiDtoVisualEnum;
(function (CartaNexiDtoVisualEnum) {
  CartaNexiDtoVisualEnum["PLASTIC"] = "PLASTIC";
  CartaNexiDtoVisualEnum["WOOD"] = "WOOD";
})(CartaNexiDtoVisualEnum = exports.CartaNexiDtoVisualEnum || (exports.CartaNexiDtoVisualEnum = {}));