"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CategoryDtoToJSON = exports.CategoryDtoFromJSONTyped = exports.CategoryDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CategoryDtoFromJSON(json) {
  return CategoryDtoFromJSONTyped(json, false);
}
exports.CategoryDtoFromJSON = CategoryDtoFromJSON;
function CategoryDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'label': !runtime_1.exists(json, 'label') ? undefined : json['label'],
    'cssId': !runtime_1.exists(json, 'cssId') ? undefined : json['cssId'],
    'value': !runtime_1.exists(json, 'value') ? undefined : json['value'],
    'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
    'subcategories': !runtime_1.exists(json, 'subcategories') ? undefined : json['subcategories'].map(_1.SubcategoryDtoFromJSON)
  };
}
exports.CategoryDtoFromJSONTyped = CategoryDtoFromJSONTyped;
function CategoryDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'label': value.label,
    'cssId': value.cssId,
    'value': value.value,
    'type': value.type,
    'subcategories': value.subcategories === undefined ? undefined : value.subcategories.map(_1.SubcategoryDtoToJSON)
  };
}
exports.CategoryDtoToJSON = CategoryDtoToJSON;