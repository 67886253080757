"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cartaContoReducer = void 0;
const __1 = require("..");
const initialState = {
  pdf: undefined,
  parametri: undefined
};
const cartaContoReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.CARTA_CONTO_ESPORTA_PDF_SUCCESS:
      return {
        ...state,
        pdf: action.payload
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.cartaContoReducer = cartaContoReducer;
exports.default = exports.cartaContoReducer;