"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModificaPinTokenUtenteRichiestaDtoToJSON = exports.ModificaPinTokenUtenteRichiestaDtoFromJSONTyped = exports.ModificaPinTokenUtenteRichiestaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ModificaPinTokenUtenteRichiestaDtoFromJSON(json) {
  return ModificaPinTokenUtenteRichiestaDtoFromJSONTyped(json, false);
}
exports.ModificaPinTokenUtenteRichiestaDtoFromJSON = ModificaPinTokenUtenteRichiestaDtoFromJSON;
function ModificaPinTokenUtenteRichiestaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'nuovoPin': !runtime_1.exists(json, 'nuovoPin') ? undefined : json['nuovoPin'],
    'tokencode': !runtime_1.exists(json, 'tokencode') ? undefined : json['tokencode'],
    'vecchioPin': !runtime_1.exists(json, 'vecchioPin') ? undefined : json['vecchioPin']
  };
}
exports.ModificaPinTokenUtenteRichiestaDtoFromJSONTyped = ModificaPinTokenUtenteRichiestaDtoFromJSONTyped;
function ModificaPinTokenUtenteRichiestaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'nuovoPin': value.nuovoPin,
    'tokencode': value.tokencode,
    'vecchioPin': value.vecchioPin
  };
}
exports.ModificaPinTokenUtenteRichiestaDtoToJSON = ModificaPinTokenUtenteRichiestaDtoToJSON;