"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrdineParametriQuotazioniToJSON = exports.OrdineParametriQuotazioniFromJSONTyped = exports.OrdineParametriQuotazioniFromJSON = void 0;
const runtime_1 = require("../runtime");
function OrdineParametriQuotazioniFromJSON(json) {
  return OrdineParametriQuotazioniFromJSONTyped(json, false);
}
exports.OrdineParametriQuotazioniFromJSON = OrdineParametriQuotazioniFromJSON;
function OrdineParametriQuotazioniFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'varPerc': !runtime_1.exists(json, 'varPerc') ? undefined : json['varPerc'],
    'ultimoPrezzo': !runtime_1.exists(json, 'ultimoPrezzo') ? undefined : json['ultimoPrezzo'],
    'oraUltimoPrezzo': !runtime_1.exists(json, 'oraUltimoPrezzo') ? undefined : json['oraUltimoPrezzo'],
    'volTrade': !runtime_1.exists(json, 'volTrade') ? undefined : json['volTrade'],
    'miglioreDenaro': !runtime_1.exists(json, 'miglioreDenaro') ? undefined : json['miglioreDenaro'],
    'miglioreLettera': !runtime_1.exists(json, 'miglioreLettera') ? undefined : json['miglioreLettera'],
    'volumeMiglioreDenaro': !runtime_1.exists(json, 'volumeMiglioreDenaro') ? undefined : json['volumeMiglioreDenaro'],
    'volumeMiglioreLettera': !runtime_1.exists(json, 'volumeMiglioreLettera') ? undefined : json['volumeMiglioreLettera'],
    'codiceTitolo': !runtime_1.exists(json, 'codiceTitolo') ? undefined : json['codiceTitolo']
  };
}
exports.OrdineParametriQuotazioniFromJSONTyped = OrdineParametriQuotazioniFromJSONTyped;
function OrdineParametriQuotazioniToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'varPerc': value.varPerc,
    'ultimoPrezzo': value.ultimoPrezzo,
    'oraUltimoPrezzo': value.oraUltimoPrezzo,
    'volTrade': value.volTrade,
    'miglioreDenaro': value.miglioreDenaro,
    'miglioreLettera': value.miglioreLettera,
    'volumeMiglioreDenaro': value.volumeMiglioreDenaro,
    'volumeMiglioreLettera': value.volumeMiglioreLettera,
    'codiceTitolo': value.codiceTitolo
  };
}
exports.OrdineParametriQuotazioniToJSON = OrdineParametriQuotazioniToJSON;