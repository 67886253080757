"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dashboardPrestiti = exports.formPrestiti = exports.formPrestitiAsFiltro = exports.rateMoraPrestiti = exports.ratePagatePrestiti = exports.rateResiduePrestiti = exports.parametriPrestiti = void 0;
const reselect_1 = require("reselect");
const prestitiReducer = state => state;
exports.parametriPrestiti = (0, reselect_1.createSelector)(prestitiReducer, state => {
  return state.parametri;
});
exports.rateResiduePrestiti = (0, reselect_1.createSelector)(prestitiReducer, state => {
  return state.rateResiduePrestiti;
});
exports.ratePagatePrestiti = (0, reselect_1.createSelector)(prestitiReducer, state => {
  return state.ratePagatePrestiti;
});
exports.rateMoraPrestiti = (0, reselect_1.createSelector)(prestitiReducer, state => {
  return state.rateMoraPrestiti;
});
exports.formPrestitiAsFiltro = (0, reselect_1.createSelector)(prestitiReducer, state => {
  var _a, _b, _c, _d;
  return {
    codiceRapporto: (_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.codiceRapporto,
    progressivoRapporto: (_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.progressivoRapporto
  };
});
exports.formPrestiti = (0, reselect_1.createSelector)(prestitiReducer, state => {
  return state.form;
});
exports.dashboardPrestiti = (0, reselect_1.createSelector)(prestitiReducer, state => {
  return state.dashboardPrestiti;
});