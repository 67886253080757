"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExtraServiceProductToJSON = exports.ExtraServiceProductFromJSONTyped = exports.ExtraServiceProductFromJSON = void 0;
const runtime_1 = require("../runtime");
function ExtraServiceProductFromJSON(json) {
  return ExtraServiceProductFromJSONTyped(json, false);
}
exports.ExtraServiceProductFromJSON = ExtraServiceProductFromJSON;
function ExtraServiceProductFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'label': !runtime_1.exists(json, 'label') ? undefined : json['label']
  };
}
exports.ExtraServiceProductFromJSONTyped = ExtraServiceProductFromJSONTyped;
function ExtraServiceProductToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'label': value.label
  };
}
exports.ExtraServiceProductToJSON = ExtraServiceProductToJSON;