"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCustomEnv = exports.setCustomEnv = exports.customEnv = exports.Environments = void 0;
exports.Environments = {
  LOCAL: {
    corebanking: 'https://on-banking-svi.civibank.it',
    oauth2: 'https://oauth2-uat.civibank.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "da509bf5-2891-40b7-896c-dd8a4188d60c",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp cbs-an cbs-aq",
    publicSite: "https://www.civibank.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "http://20.101.196.49",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-svi.sparkasse.it',
    selfreset: 'https://on-banking-svi.sparkasse.it',
    pushNotification: "https://on-banking-svi.sparkasse.it",
    corporate: "https://cbcoll2.cedacri.it/CORE/main/LogonAttempt"
  },
  DEV: {
    corebanking: 'https://on-banking-svi.civibank.it',
    oauth2: 'https://oauth2-uat.civibank.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "76ff70c2-7fa4-45d5-8268-d854bc93bae2",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "email ihbnext nextgen openid phone private_profile prometeia",
    publicSite: "https://www.civibank.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-svi.civibank.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-svi.sparkasse.it',
    selfreset: 'https://on-banking-svi.sparkasse.it',
    pushNotification: "https://on-banking-svi.sparkasse.it",
    corporate: "https://cbcoll2.cedacri.it/CORE/main/LogonAttempt"
  },
  QA: {
    corebanking: 'https://on-banking-qa.civibank.it',
    oauth2: 'https://oauth2-uat.civibank.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "76ff70c2-7fa4-45d5-8268-d854bc93bae2",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "email ihbnext nextgen openid phone private_profile prometeia",
    publicSite: "https://www.civibank.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-qa.civibank.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-qa.sparkasse.it',
    selfreset: 'https://on-banking-qa.sparkasse.it',
    pushNotification: "https://on-banking-qa.sparkasse.it",
    corporate: "https://cbcoll2.cedacri.it/CORE/main/LogonAttempt"
  },
  ADS: {
    corebanking: 'https://on-banking-col.civibank.it',
    oauth2: 'https://oauth2-uat.civibank.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "76ff70c2-7fa4-45d5-8268-d854bc93bae2",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "email ihbnext nextgen openid phone private_profile prometeia",
    publicSite: "https://www.civibank.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-col.civibank.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-col.sparkasse.it',
    selfreset: 'https://on-banking-col.sparkasse.it',
    pushNotification: "https://on-banking-col.sparkasse.it",
    corporate: "https://cbcoll2.cedacri.it/CORE/main/LogonAttempt"
  },
  PROD: {
    corebanking: 'https://on-banking.civibank.it',
    oauth2: 'https://oauth2.civibank.it',
    aliaslabUrl: 'https://smartotp.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://spks.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'gvaSNPSsysM1076Bup',
    neosuranceCode: 'spks',
    clientId: "d11cbeb8-0af2-4a8b-a0e7-083b8c019a9e",
    googleAnalyticsId: 'UA-183958198-1',
    sessionLifetime: 300,
    scope: "authorization email ihbnext nextgen openid phone private_profile prometeia security-profile",
    publicSite: "https://www.civibank.it",
    hub: "https://gw.prd.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking.civibank.it",
    deploymentKeyGenesys: "627d1d36-7ebd-4eff-9628-4e601a5fda12",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking.sparkasse.it',
    selfreset: 'https://on-banking.sparkasse.it',
    pushNotification: "https://on-banking.sparkasse.it",
    corporate: "https://isi-business.civibank.it/CORE/main/LogonAttempt"
  },
  MOCK: {
    corebanking: 'https://on-banking-qa.sparkasse.it',
    oauth2: 'https://oauth2-uat.sparkasse.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "d11cbeb8-0af2-4a8b-a0e7-083b8c019a9e",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp cbs-an cbs-aq",
    publicSite: "https://www.civibank.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-qa.civibank.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-svi.sparkasse.it',
    selfreset: 'https://on-banking-svi.sparkasse.it',
    pushNotification: "https://on-banking-qa.sparkasse.it",
    corporate: "https://cbcoll2.cedacri.it/CORE/main/LogonAttempt"
  }
};
function setCustomEnv(c) {
  exports.customEnv = c;
}
exports.setCustomEnv = setCustomEnv;
function getCustomEnv() {
  return exports.customEnv;
}
exports.getCustomEnv = getCustomEnv;