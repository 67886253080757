"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetDettaglioTitoliQuotati = exports.resetTitoliQuotati = exports.resetFormTitoliQuotati = exports.setTitoliQuotatiTipoGrafico = exports.setTitoliQuotatiOffset = exports.setTitoloQuotatoSelezionato = exports.setTitoliQuotatiDenominazione = exports.setTitoliQuotatiMercato = exports.getDatiStoriciTitoloQuotato = exports.getDatiIntradayTitoloQuotato = exports.getSchedaTitoloQuotato = exports.getDettaglioTitoloQuotato = exports.getElencoTitoliQuotati = exports.getMercatiTitoliQuotati = void 0;
const general_actions_1 = require("../general/general.actions");
const api_rest_1 = require("@sparkasse/api-rest");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const titoliQuotati_types_1 = require("./titoliQuotati.types");
const moment = require("moment");
// Get mercati Titoli Quotati - Start
function getMercatiTitoliQuotatiStart() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_MERCATI_START
  };
}
function getMercatiTitoliQuotatiSuccess(payload) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_MERCATI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getMercatiTitoliQuotati = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getMercatiTitoliQuotatiStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getMercatiTitoli().then(response => {
        dispatch(getMercatiTitoliQuotatiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getMercatiTitoliQuotati = getMercatiTitoliQuotati;
// Get mercati Titoli quotati - Fine
// Get Titoli Quotati - Start
function getElencoTitoliQuotatiStart() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_TITOLI_START
  };
}
function getElencoTitoliQuotatiSuccess(payload, offset) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_TITOLI_SUCCESS,
    payload,
    offset
  };
}
const getElencoTitoliQuotati = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getElencoTitoliQuotatiStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const titoliQuotatiState = getState().titoliQuotati;
    const request = {
      quotazioniFiltriInDto: {
        mercato: titoliQuotatiState.form.mercato,
        cerca: titoliQuotatiState.form.denominazione
      }
    };
    if (extraArguments.platform === 'mobile') {
      request.quotazioniFiltriInDto.offset = titoliQuotatiState.form.offset;
    }
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).quotazioniTitoli(request).then(response => {
        dispatch(getElencoTitoliQuotatiSuccess(response, titoliQuotatiState.form.offset));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getElencoTitoliQuotati = getElencoTitoliQuotati;
// Get Titoli Quotati - Fine
// Get dettaglio Titolo Quotato - Start
function getDettaglioTitoloQuotatoStart() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DETTAGLIO_TITOLO_START
  };
}
function getDettaglioTitoloQuotatoSuccess(payload) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DETTAGLIO_TITOLO_SUCCESS,
    payload
  };
}
const getDettaglioTitoloQuotato = () => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDettaglioTitoloQuotatoStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const titoliState = getState().titoliQuotati;
    const request = {
      mercato: titoliState.form.mercato,
      codiceTitolo: (_a = titoliState.form.titoloSelezionato.codice) !== null && _a !== void 0 ? _a : ''
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).quotazioneDettaglioTitolo(request).then(response => {
        dispatch(getDettaglioTitoloQuotatoSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDettaglioTitoloQuotato = getDettaglioTitoloQuotato;
// Get dettaglio Titolo Quotato - Fine
// Get scheda Titolo Quotato - Start
function getSchedaTitoloQuotatoStart() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_SCHEDA_TITOLO_START
  };
}
function getSchedaTitoloQuotatoSuccess(payload) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_SCHEDA_TITOLO_SUCCESS,
    payload
  };
}
const getSchedaTitoloQuotato = () => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getSchedaTitoloQuotatoStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const titoliState = getState().titoliQuotati;
    const request = {
      mercato: (_a = titoliState.form.titoloSelezionato.mercato) !== null && _a !== void 0 ? _a : titoliState.form.mercato,
      codiceTitolo: (_b = titoliState.form.titoloSelezionato.codice) !== null && _b !== void 0 ? _b : ''
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getSchedaTitoloTrading(request).then(response => {
        dispatch(getSchedaTitoloQuotatoSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getSchedaTitoloQuotato = getSchedaTitoloQuotato;
// Get dettaglio Titolo Quotato - Fine
function aggiornaGraficoTitoloQuotatoSuccess(payload, aggiornamento, tipo) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_AGGIORNA_GRAFICO,
    payload,
    aggiornamento,
    tipo
  };
}
function aggiornaGraficoTitoloQuotatoEsteroSuccess(payload, aggiornamento, tipo) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_ESTERI_AGGIORNA_GRAFICO,
    payload,
    aggiornamento,
    tipo
  };
}
// Get dati intraday Titolo Quotato - Start
function getDatiIntradayTitoloQuotatoStart() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DATI_INTRADAY_TITOLO_START
  };
}
function getDatiIntradayTitoloQuotatoSuccess(payload, aggiornamento) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DATI_INTRADAY_TITOLO_SUCCESS,
    payload,
    aggiornamento
  };
}
const getDatiIntradayTitoloQuotato = (refresh, tipo) => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDatiIntradayTitoloQuotatoStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const titoliState = getState().titoliQuotati;
    const request = {
      datiIntradayTitoloInDto: {
        mercato: titoliState.form.mercato,
        codiceTitolo: (_a = titoliState.form.titoloSelezionato.codice) !== null && _a !== void 0 ? _a : ''
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).datiIntradayTitolo(request).then(response => {
        if (refresh) {
          dispatch(aggiornaGraficoTitoloQuotatoSuccess(response, moment().toDate(), tipo !== null && tipo !== void 0 ? tipo : 0));
        } else {
          dispatch(getDatiIntradayTitoloQuotatoSuccess(response, moment().toDate()));
        }
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDatiIntradayTitoloQuotato = getDatiIntradayTitoloQuotato;
// Get dati Intraday Titolo Quotato - Fine
// Get dati storici Titolo Quotato - Start
function getDatiStoriciTitoloQuotatoStart() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DATI_STORICI_TITOLO_START
  };
}
function getDatiStoriciTitoloQuotatoSuccess(payload, aggiornamento, tipo, tipologiaGrafico) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DATI_STORICI_TITOLO_SUCCESS,
    payload,
    aggiornamento,
    tipo,
    tipologiaGrafico
  };
}
const getDatiStoriciTitoloQuotato = (type, isEstero, refresh) => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDatiStoriciTitoloQuotatoStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const now = moment();
    const titoliState = getState().titoliQuotati;
    const tipoGrafico = isEstero ? titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri : titoliQuotati_types_1.TipoGraficoTitoliQuotati;
    const request = {
      datiIntradayTitoloInDto: {
        mercato: titoliState.form.mercato,
        codiceTitolo: (_a = titoliState.form.titoloSelezionato.codice) !== null && _a !== void 0 ? _a : '',
        dataFinale: now.toDate(),
        dataIniziale: (type === tipoGrafico.Settimana1 ? now.subtract(7, "days") : type === tipoGrafico.Mese1 ? now.subtract(1, "months") : now.subtract(12, "months")).toDate()
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).datiStoriciTitolo(request).then(response => {
        if (refresh && isEstero) {
          dispatch(aggiornaGraficoTitoloQuotatoEsteroSuccess(response, moment().toDate(), type));
        } else if (refresh && !isEstero) {
          dispatch(aggiornaGraficoTitoloQuotatoSuccess(response, moment().toDate(), type));
        } else {
          dispatch(getDatiStoriciTitoloQuotatoSuccess(response, now.toDate(), type, tipoGrafico));
        }
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDatiStoriciTitoloQuotato = getDatiStoriciTitoloQuotato;
// Get dati Intraday Titolo Quotato - Fine
/* set form values - START */
function setTitoliQuotatiMercato(txt) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.SET_TITOLI_QUOTATI_MERCATO,
    payload: txt
  };
}
exports.setTitoliQuotatiMercato = setTitoliQuotatiMercato;
function setTitoliQuotatiDenominazione(txt) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.SET_TITOLI_QUOTATI_DENOMINAZIONE,
    payload: txt
  };
}
exports.setTitoliQuotatiDenominazione = setTitoliQuotatiDenominazione;
function setTitoloQuotatoSelezionato(titolo) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_SET_TITOLO_SELEZIONATO,
    payload: titolo
  };
}
exports.setTitoloQuotatoSelezionato = setTitoloQuotatoSelezionato;
function setTitoliQuotatiOffset(offset) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_SET_OFFSET,
    payload: offset
  };
}
exports.setTitoliQuotatiOffset = setTitoliQuotatiOffset;
/* set form values - Fine */
function setTitoliQuotatiTipoGrafico(tipo) {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_SET_TIPO_GRAFICO,
    payload: tipo
  };
}
exports.setTitoliQuotatiTipoGrafico = setTitoliQuotatiTipoGrafico;
// Reset Form Titoli Quotati
function resetFormTitoliQuotati() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_RESET_FORM
  };
}
exports.resetFormTitoliQuotati = resetFormTitoliQuotati;
function resetTitoliQuotati() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_RESET
  };
}
exports.resetTitoliQuotati = resetTitoliQuotati;
// end Reset Form Titoli Quotati
// Reset dettaglio Titoli Quotati
function resetDettaglioTitoliQuotati() {
  return {
    type: titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_RESET_DETTAGLIO
  };
}
exports.resetDettaglioTitoliQuotati = resetDettaglioTitoliQuotati;
// end Reset dettaglio Titoli Quotati