"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExportElencoRibaTypeEnum = exports.RiBaControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class RiBaControllerApi extends runtime.BaseAPI {
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationRibaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationRiba.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/riba/infoautenticazione/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationRiba(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationRibaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di riba
   */
  authorizeRibaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeRiba.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeRiba.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/riba/autorizza/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneRibaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di riba
   */
  authorizeRiba(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeRibaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Controllo elenco avvisi
   */
  controlloRibaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ribaRequestInDto === null || requestParameters.ribaRequestInDto === undefined) {
        throw new runtime.RequiredError('ribaRequestInDto', 'Required parameter requestParameters.ribaRequestInDto was null or undefined when calling controlloRiba.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/riba/controllo",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.RibaRequestInDtoToJSON(requestParameters.ribaRequestInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RibaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controllo elenco avvisi
   */
  controlloRiba(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloRibaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Export in PDF del dettaglio riba in base ai criteri inviati per il rapporto indicato
   */
  exportDettaglioRibaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ribaDto === null || requestParameters.ribaDto === undefined) {
        throw new runtime.RequiredError('ribaDto', 'Required parameter requestParameters.ribaDto was null or undefined when calling exportDettaglioRiba.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/riba/export/dettaglio/pdf",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.RibaDtoToJSON(requestParameters.ribaDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in PDF del dettaglio riba in base ai criteri inviati per il rapporto indicato
   */
  exportDettaglioRiba(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.exportDettaglioRibaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Genera file PDF o XLS degli avvisi riba
   */
  exportElencoRibaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling exportElencoRiba.');
      }
      if (requestParameters.filtroElencoAvvisiDto === null || requestParameters.filtroElencoAvvisiDto === undefined) {
        throw new runtime.RequiredError('filtroElencoAvvisiDto', 'Required parameter requestParameters.filtroElencoAvvisiDto was null or undefined when calling exportElencoRiba.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/riba/export/elenco/{type}".replace("{".concat("type", "}"), encodeURIComponent(String(requestParameters.type))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroElencoAvvisiDtoToJSON(requestParameters.filtroElencoAvvisiDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Genera file PDF o XLS degli avvisi riba
   */
  exportElencoRiba(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.exportElencoRibaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce l\'elenco degli avvisi
   */
  getElencoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroElencoAvvisiDto === null || requestParameters.filtroElencoAvvisiDto === undefined) {
        throw new runtime.RequiredError('filtroElencoAvvisiDto', 'Required parameter requestParameters.filtroElencoAvvisiDto was null or undefined when calling getElenco.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/riba/elenco",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroElencoAvvisiDtoToJSON(requestParameters.filtroElencoAvvisiDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.AvvisoDtoFromJSON));
    });
  }
  /**
   * Restituisce l\'elenco degli avvisi
   */
  getElenco(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getElencoRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura dei parametri Riba
   */
  parametriRibaRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/riba/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriRibaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura dei parametri Riba
   */
  parametriRiba() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriRibaRaw();
      return yield response.value();
    });
  }
}
exports.RiBaControllerApi = RiBaControllerApi;
/**
    * @export
    * @enum {string}
    */
var ExportElencoRibaTypeEnum;
(function (ExportElencoRibaTypeEnum) {
  ExportElencoRibaTypeEnum["pdf"] = "pdf";
  ExportElencoRibaTypeEnum["excel"] = "excel";
})(ExportElencoRibaTypeEnum = exports.ExportElencoRibaTypeEnum || (exports.ExportElencoRibaTypeEnum = {}));