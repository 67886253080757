"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esportaElencoOrdiniExcel = exports.esportaDettaglioOrdinePDF = exports.esportaElencoOrdiniPDF = exports.resetRevocaOrdineForm = exports.createDettaglioOrdine = exports.setRevocaOrdineSetStep = exports.setStoricoOrdiniOffset = exports.resetStoricoOrdiniEseguiti = exports.resetFiltriStoricoOrdini = exports.setStoricoOrdiniResetDettaglioOrdine = exports.setStoricoOrdiniSetPeriodo = exports.setStoricoOrdiniStati = exports.setStoricoOrdiniDataA = exports.setStoricoOrdiniDataDa = exports.setStoricoOrdiniAccordionFiltriOpened = exports.setStoricoOrdiniRapportoSelezionato = exports.getEseguitiOrdine = exports.autorizzaRevocaOrdine = exports.getInfoRevocaOrdine = exports.controlloRevocaOrdine = exports.getParametriTrading = exports.getElencoOrdiniInScadenza = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getElencoOrdiniInScadenzaStart() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_GET_ORDINI_IN_SCADENZA_START
  };
}
function getElencoOrdiniInScadenzaSuccess(payload, offset) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_GET_ORDINI_IN_SCADENZA_SUCCESS,
    payload,
    offset
  };
}
function generalError(value, showGenericModalError) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
  };
}
const getElencoOrdiniInScadenza = filtro => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getElencoOrdiniInScadenzaStart());
  if (extraArguments.mock) {} else {
    const requestIn = filtro || (0, __1.formStoricoOrdiniAsFiltro)(getState().storicoOrdini);
    if (requestIn) {
      const getElencoOrdiniRequest = {
        ordiniInScadenzaFiltriInDto: requestIn
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).ordiniInScadenza(getElencoOrdiniRequest).then(response => {
          dispatch(getElencoOrdiniInScadenzaSuccess(response, requestIn.offset));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.getElencoOrdiniInScadenza = getElencoOrdiniInScadenza;
function getParametriTradingStart() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_GET_PARAMETRI_TRADING_START
  };
}
function getParametriTradingSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_GET_PARAMETRI_TRADING_SUCCESS,
    payload
  };
}
const getParametriTrading = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriTradingStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriTrading().then(response => {
        dispatch(getParametriTradingSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriTrading = getParametriTrading;
/* controllo revoca - START */
function controlloRevocaOrdineStart() {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_CONTROLLO_REVOCA_START
  };
}
function controlloRevocaOrdineSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_CONTROLLO_REVOCA_SUCCESS,
    payload
  };
}
const controlloRevocaOrdine = () => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d, _e, _f, _g;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(controlloRevocaOrdineStart());
  if (extraArguments.mock) {} else {
    const storicoOrdini = getState().storicoOrdini;
    const requestParameters = {
      revocaOrdineTradingDto: {
        funzione: 'TRADING_ANNULLO',
        codiceRapporto: (_a = storicoOrdini.storicoOrdiniForm.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id,
        titolo: (_c = (_b = storicoOrdini.dettaglioOrdine) === null || _b === void 0 ? void 0 : _b.ordine) === null || _c === void 0 ? void 0 : _c.titolo,
        indicativoTitolo: (_e = (_d = storicoOrdini.dettaglioOrdine) === null || _d === void 0 ? void 0 : _d.ordine) === null || _e === void 0 ? void 0 : _e.indicativoTitolo,
        numeroOrdine: (_g = (_f = storicoOrdini.dettaglioOrdine) === null || _f === void 0 ? void 0 : _f.ordine) === null || _g === void 0 ? void 0 : _g.idOrdine
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloRevocaOrdine(requestParameters).then(response => {
        dispatch(controlloRevocaOrdineSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.controlloRevocaOrdine = controlloRevocaOrdine;
/* controllo revoca - FINE */
/* get info revoca - START */
function getInfoRevocaOrdineStart() {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_GET_INFO_REVOCA_START
  };
}
function getInfoRevocaOrdineSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_GET_INFO_REVOCA_SUCCESS,
    payload
  };
}
const getInfoRevocaOrdine = (idDisposizione, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoRevocaOrdineStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
      dispatch(getInfoRevocaOrdineSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestParameters = {
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).infoAutenticazioneRevoca(requestParameters).then(response => {
        dispatch(getInfoRevocaOrdineSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoRevocaOrdine = getInfoRevocaOrdine;
/* get info revoca - FINE */
/* autorizza revoca - START */
function autorizzaRevocaOrdineStart() {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_AUTORIZZA_REVOCA_START
  };
}
function autorizzaRevocaOrdineSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_AUTORIZZA_REVOCA_SUCCESS,
    payload
  };
}
const autorizzaRevocaOrdine = (idDisposizione, credenziale) => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d, _e, _f;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaRevocaOrdineStart());
  if (extraArguments.mock) {} else {
    const storicoOrdini = getState().storicoOrdini;
    const requestParameters = {
      revocaOrdineTradingDto: {
        funzione: 'TRADING_ANNULLO',
        credenziale: credenziale,
        codiceRapporto: storicoOrdini.ordineRevocaForm.controlloRevoca.codiceRapporto,
        titolo: (_b = (_a = storicoOrdini.dettaglioOrdine) === null || _a === void 0 ? void 0 : _a.ordine) === null || _b === void 0 ? void 0 : _b.titolo,
        indicativoTitolo: (_d = (_c = storicoOrdini.dettaglioOrdine) === null || _c === void 0 ? void 0 : _c.ordine) === null || _d === void 0 ? void 0 : _d.indicativoTitolo,
        numeroOrdine: (_f = (_e = storicoOrdini.dettaglioOrdine) === null || _e === void 0 ? void 0 : _e.ordine) === null || _f === void 0 ? void 0 : _f.idOrdine
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaRevoca(requestParameters).then(response => {
        dispatch(autorizzaRevocaOrdineSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaRevocaOrdine = autorizzaRevocaOrdine;
/* autorizza revoca - FINE */
/* get dettaglio - START */
function getEseguitiOrdineStart() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_GET_ESEGUITI_ORDINE_START
  };
}
function getEseguitiOrdineSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_GET_ESEGUITI_ORDINE_SUCCESS,
    payload
  };
}
const getEseguitiOrdine = numeroOrdine => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getEseguitiOrdineStart());
  if (extraArguments.mock) {} else {
    const requestParameters = {
      numeroOrdine: numeroOrdine,
      codiceRapporto: (_b = (_a = getState().storicoOrdini.storicoOrdiniForm.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).dettaglioOrdine(requestParameters).then(response => {
        dispatch(getEseguitiOrdineSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getEseguitiOrdine = getEseguitiOrdine;
function setStoricoOrdiniRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setStoricoOrdiniRapportoSelezionato = setStoricoOrdiniRapportoSelezionato;
function setStoricoOrdiniAccordionFiltriOpened(opened) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_ACCORDION_FILTRI_OPENED,
    payload: opened
  };
}
exports.setStoricoOrdiniAccordionFiltriOpened = setStoricoOrdiniAccordionFiltriOpened;
function setStoricoOrdiniDataDa(dataDa) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_DATA_DA,
    payload: dataDa
  };
}
exports.setStoricoOrdiniDataDa = setStoricoOrdiniDataDa;
function setStoricoOrdiniDataA(dataA) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_DATA_A,
    payload: dataA
  };
}
exports.setStoricoOrdiniDataA = setStoricoOrdiniDataA;
function setStoricoOrdiniStati(stati) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_STATI,
    payload: stati
  };
}
exports.setStoricoOrdiniStati = setStoricoOrdiniStati;
function setStoricoOrdiniSetPeriodo(periodo) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_PERIODO,
    payload: periodo
  };
}
exports.setStoricoOrdiniSetPeriodo = setStoricoOrdiniSetPeriodo;
function setStoricoOrdiniResetDettaglioOrdine() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_RESET_DETTAGLIO_ORDINE
  };
}
exports.setStoricoOrdiniResetDettaglioOrdine = setStoricoOrdiniResetDettaglioOrdine;
function resetFiltriStoricoOrdini() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_RESET_FILTRO
  };
}
exports.resetFiltriStoricoOrdini = resetFiltriStoricoOrdini;
function resetStoricoOrdiniEseguiti() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_RESET_ESEGUITI_ORDINE
  };
}
exports.resetStoricoOrdiniEseguiti = resetStoricoOrdiniEseguiti;
function setStoricoOrdiniOffset(offset) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_OFFSET,
    payload: offset
  };
}
exports.setStoricoOrdiniOffset = setStoricoOrdiniOffset;
function setRevocaOrdineSetStepAction(i) {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_SET_STEP_REVOCA,
    payload: i
  };
}
const setRevocaOrdineSetStep = i => async (dispatch, _, extraArguments) => {
  dispatch(setRevocaOrdineSetStepAction(i));
  if (extraArguments.platform == 'mobile') {
    dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 1));
  }
};
exports.setRevocaOrdineSetStep = setRevocaOrdineSetStep;
function setDettaglioOrdine(dettaglio) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_SET_DETTAGLIO_ORDINE,
    payload: dettaglio
  };
}
const createDettaglioOrdine = dettaglio => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  const form = {
    intestazione: (_a = getState().storicoOrdini.storicoOrdiniForm.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.intestazione,
    dossier: (_b = getState().storicoOrdini.storicoOrdiniForm.rapportoSelezionato.rapporto) === null || _b === void 0 ? void 0 : _b.codiceRapportoFormatoBanca,
    ordine: dettaglio,
    codiceRapporto: (_c = getState().storicoOrdini.storicoOrdiniForm.rapportoSelezionato.rapporto) === null || _c === void 0 ? void 0 : _c.id
  };
  dispatch(setDettaglioOrdine(form));
};
exports.createDettaglioOrdine = createDettaglioOrdine;
function resetRevocaOrdineForm() {
  return {
    type: __1.EReduxActionTypes.REVOCA_ORDINE_RESET_FORM_REVOCA
  };
}
exports.resetRevocaOrdineForm = resetRevocaOrdineForm;
// export pdf elenco ordini - start
function esportaElencoOrdiniPDFStart() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_ESPORTA_PDF_ELENCO_START
  };
}
function esportaElencoOrdiniPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_ESPORTA_PDF_ELENCO_SUCCESS,
    payload
  };
}
const esportaElencoOrdiniPDF = (datiForm, share, title, message) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaElencoOrdiniPDFStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaPagaimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const requestIn = datiForm || (0, __1.formStoricoOrdiniAsFiltro)(getState().storicoOrdini);
    if (requestIn) {
      const esportaElencoOrdiniPDFRequest = {
        type: api_rest_1.StampaOrdiniInScadenzaTypeEnum.pdf,
        ordiniInScadenzaFiltriInDto: requestIn
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaOrdiniInScadenza(esportaElencoOrdiniPDFRequest).then(response => {
          if (extraArguments.platform === 'desktop') {
            (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
          }
          if (extraArguments.platform === 'mobile') {
            (0, helpers_1.download_sharePDF)(extraArguments, 'ELencoOrdini.pdf', response, share, title, message);
          }
          dispatch(esportaElencoOrdiniPDFSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.esportaElencoOrdiniPDF = esportaElencoOrdiniPDF;
// export pdf elenco ordini - fine
// export pdf dettaglio ordine - start
function esportaDettaglioOrdinePDFStart() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_ESPORTA_PDF_DETTAGLIO_START
  };
}
function esportaDettaglioOrdinePDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_ESPORTA_PDF_DETTAGLIO_SUCCESS,
    payload
  };
}
const esportaDettaglioOrdinePDF = (share, title, message) => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d, _e;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaDettaglioOrdinePDFStart());
  if (extraArguments.mock) {} else {
    const esportaDettaglioPDFRequest = {
      eseguitiResponseDto: {
        codiceRapporto: (_b = (_a = getState().storicoOrdini.storicoOrdiniForm.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
        ordine: (_c = getState().storicoOrdini.dettaglioOrdine) === null || _c === void 0 ? void 0 : _c.ordine,
        dossier: (_d = getState().storicoOrdini.dettaglioOrdine) === null || _d === void 0 ? void 0 : _d.dossier,
        intestazione: (_e = getState().storicoOrdini.dettaglioOrdine) === null || _e === void 0 ? void 0 : _e.intestazione
      }
      // filtroOrdiniDto: {
      //   codiceRapporto: getState().storicoOrdini.storicoOrdiniForm.rapportoSelezionato.rapporto?.id ?? 0,
      //   numeroOrdine: getState().storicoOrdini.dettaglioOrdine?.ordine?.idOrdine ?? ''
      // }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioOrdinePdf(esportaDettaglioPDFRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'DettaglioOrdine.pdf', response, share, title, message);
        }
        dispatch(esportaDettaglioOrdinePDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaDettaglioOrdinePDF = esportaDettaglioOrdinePDF;
// export excel elenco ordini - start
function esportaElencoOrdiniExcelStart() {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_ESPORTA_PDF_ELENCO_START
  };
}
function esportaElencoOrdiniExcelSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.STORICO_ORDINI_ESPORTA_PDF_ELENCO_SUCCESS,
    payload
  };
}
const esportaElencoOrdiniExcel = (datiForm, share, title, message) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaElencoOrdiniExcelStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaPagaimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const esportaElencoOrdiniPDFRequest = {
      type: api_rest_1.StampaOrdiniInScadenzaTypeEnum.excel,
      ordiniInScadenzaFiltriInDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).stampaOrdiniInScadenza(esportaElencoOrdiniPDFRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadleExcelResponse)(response, 'ordini.xlsx');
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'ELencoOrdini.excel', response, share, title, message);
        }
        dispatch(esportaElencoOrdiniExcelSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaElencoOrdiniExcel = esportaElencoOrdiniExcel;
// export pdf elenco ordini - fine