"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnrollmentActivityDtoToJSON = exports.EnrollmentActivityDtoFromJSONTyped = exports.EnrollmentActivityDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EnrollmentActivityDtoFromJSON(json) {
  return EnrollmentActivityDtoFromJSONTyped(json, false);
}
exports.EnrollmentActivityDtoFromJSON = EnrollmentActivityDtoFromJSON;
function EnrollmentActivityDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'activeConsent': !runtime_1.exists(json, 'activeConsent') ? undefined : json['activeConsent'],
    'listDetail': !runtime_1.exists(json, 'listDetail') ? undefined : json['listDetail'].map(_1.AspspDetailDtoFromJSON),
    'ibanRequired': !runtime_1.exists(json, 'ibanRequired') ? undefined : json['ibanRequired'],
    'psuIdRequired': !runtime_1.exists(json, 'psuIdRequired') ? undefined : json['psuIdRequired']
  };
}
exports.EnrollmentActivityDtoFromJSONTyped = EnrollmentActivityDtoFromJSONTyped;
function EnrollmentActivityDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'activeConsent': value.activeConsent,
    'listDetail': value.listDetail === undefined ? undefined : value.listDetail.map(_1.AspspDetailDtoToJSON),
    'ibanRequired': value.ibanRequired,
    'psuIdRequired': value.psuIdRequired
  };
}
exports.EnrollmentActivityDtoToJSON = EnrollmentActivityDtoToJSON;