"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pagamentiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const pagamenti_types_1 = require("./pagamenti.types");
const moment = require("moment");
const initialState = {
  parametri: (0, api_rest_1.ParametriElencoPagamentiDtoFromJSON)({}),
  pagamenti: (0, api_rest_1.DatiPagamentiDtoFromJSON)({}),
  pagamentiRapportiEstinti: (0, api_rest_1.DatiPagamentiDtoFromJSON)({}),
  pagamentoSelezionato: undefined,
  dettaglioPagamento: undefined,
  form: (0, pagamenti_types_1.FormPagamentiDtoFromJSON)({}),
  rapportiEstinti: [],
  formEstinti: (0, pagamenti_types_1.FormEstintiDtoFromJSON)({})
};
const pagamentiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a;
  switch (action.type) {
    case __1.EReduxActionTypes.PAGAMENTI_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.PAGAMENTI_GET_PAGAMENTI_SUCCESS:
      return {
        ...state,
        pagamenti: !action.offset ? action.payload : {
          ...state.pagamenti,
          datiPagamenti: (state.pagamenti.datiPagamenti || []).concat(action.payload.datiPagamenti || [])
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_SET_PAGAMENTO_SELEZIONATO:
      return {
        ...state,
        pagamentoSelezionato: action.payload
      };
    case __1.EReduxActionTypes.PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_SUCCESS:
      return {
        ...state,
        dettaglioPagamento: action.payload
      };
    case __1.EReduxActionTypes.PAGAMENTI_RESET_DETTAGLIO_PAGAMENTO:
      return {
        ...state,
        dettaglioPagamento: undefined
      };
    case __1.EReduxActionTypes.PAGAMENTI_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload,
          formDirty: true
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RESET_FILTRO:
      return {
        ...state,
        form: {
          ...initialState.form
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_DESCRIZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          descrizione: action.payload,
          descrizioneError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_PERIODO:
      return {
        ...state,
        form: {
          ...state.form,
          periodo: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_DATA_DA:
      return {
        ...state,
        form: {
          ...state.form,
          dataDa: action.payload,
          dataDaError: (0, helpers_1.validationField)(action.payload, ''),
          periodo: undefined,
          tipologieError: Math.abs(moment(action.payload).diff(moment(state.form.dataA), 'days')) > 60 && state.form.tipologie.length !== 1 ? 'invalid.selezionareUnSoloTipoDiPagamento' : ''
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_DATA_A:
      return {
        ...state,
        form: {
          ...state.form,
          dataA: action.payload,
          dataAError: (0, helpers_1.validationField)(action.payload, ''),
          periodo: undefined,
          tipologieError: Math.abs(moment(action.payload).diff(moment(state.form.dataDa), 'days')) > 60 && state.form.tipologie.length !== 1 ? 'invalid.selezionareUnSoloTipoDiPagamento' : ''
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_IMPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          importo: action.payload,
          importoError: (0, helpers_1.validationField)(action.payload, ''),
          formDirty: true
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_CODICE_FISCALE:
      return {
        ...state,
        form: {
          ...state.form,
          codiceFiscale: action.payload,
          codiceFiscaleDirty: true,
          codiceFiscaleError: (0, helpers_1.validationCodiceFiscale)(action.payload, false),
          formDirty: true
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_TIPOLOGIE:
      return {
        ...state,
        form: {
          ...state.form,
          tipologie: action.payload,
          formDirty: true,
          tipologieError: Math.abs(moment(state.form.dataDa).diff(moment(state.form.dataA), 'days')) > 60 && action.payload.length !== 1 ? 'invalid.selezionareUnSoloTipoDiPagamento' : ''
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_STATI:
      return {
        ...state,
        form: {
          ...state.form,
          stati: action.payload,
          formDirty: true
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_SET_ACCORDION_FILTRI_OPENED:
      return {
        ...state,
        form: {
          ...state.form,
          accordionFiltriOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RESET_ELENCO:
      return {
        ...state,
        pagamenti: initialState.pagamenti
      };
    case __1.EReduxActionTypes.PAGAMENTI_SET_OFFSET:
      return {
        ...state,
        form: {
          ...state.form,
          offset: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_LIST_LOADER:
      return {
        ...state,
        form: {
          ...state.form,
          listLoader: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_GET_RAPPORTI_ESTINTI_SUCCESS:
      return {
        ...state,
        rapportiEstinti: action.payload,
        formEstinti: {
          ...state.formEstinti,
          rapportoSelezionato: initialState.formEstinti.rapportoSelezionato
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RESET_FILTRI_RAPPORTI_ESTINTI:
      return {
        ...state,
        formEstinti: {
          ...initialState.formEstinti,
          rapportoSelezionato: state.formEstinti.rapportoSelezionato,
          dataDa: state.parametri.parametri ? moment(state.parametri.parametri['DEFAULT.ELENCOPAGAMENTI.DATA_INIZIALE_ESTINTI']).format('YYYY-MM-DD') : moment().subtract(2, 'years').format('YYYY-MM-DD'),
          dataA: state.parametri.parametri ? moment(state.parametri.parametri['DEFAULT.ELENCOPAGAMENTI.DATA_FINALE_ESTINTI']).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          tipologie: !!state.parametri.tipologieRappEstinti ? (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.tipologieRappEstinti[0] : ''
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_DATA_DA:
      return {
        ...state,
        formEstinti: {
          ...state.formEstinti,
          dataDa: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_DATA_A:
      return {
        ...state,
        formEstinti: {
          ...state.formEstinti,
          dataA: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_TIPOLOGIA:
      return {
        ...state,
        formEstinti: {
          ...state.formEstinti,
          tipologie: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        formEstinti: {
          ...state.formEstinti,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_GET_PAGAMENTI_RAPPORTI_ESTINTI_SUCCESS:
      return {
        ...state,
        pagamentiRapportiEstinti: !action.offset ? action.payload : {
          ...state.pagamentiRapportiEstinti,
          datiPagamenti: (state.pagamentiRapportiEstinti.datiPagamenti || []).concat(action.payload.datiPagamenti || [])
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_OFFSET:
      return {
        ...state,
        formEstinti: {
          ...state.formEstinti,
          offset: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_LIST_LOADER:
      return {
        ...state,
        formEstinti: {
          ...state.formEstinti,
          listLoader: action.payload
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_RESET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        formEstinti: {
          ...state.formEstinti,
          rapportoSelezionato: initialState.formEstinti.rapportoSelezionato
        }
      };
    case __1.EReduxActionTypes.PAGAMENTI_SET_DETTAGLIO_PAGAMENTO_SELEZIONATO:
      return {
        ...state,
        dettaglioPagamento: action.payload
      };
    case __1.EReduxActionTypes.PAGAMENTI_RESET_ELENCO_RAPPORTI_ESTINTI:
      return {
        ...state,
        pagamentiRapportiEstinti: initialState.pagamentiRapportiEstinti
      };
    case __1.EReduxActionTypes.PAGAMENTI_SET_ACCORDION_FILTRI_RAPPORTI_ESTINTI_OPENED:
      {
        return {
          ...state,
          formEstinti: {
            ...state.formEstinti,
            accordionFiltriOpened: action.payload
          }
        };
      }
      ;
    case __1.EReduxActionTypes.GENERAL_RESET_CACHE_POST_DISPO:
      // action invocata dopo una dispositiva di tipo resetWidgetsDispoFunctions
      return {
        ...state,
        parametri: initialState.parametri
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.pagamentiReducer = pagamentiReducer;
exports.default = exports.pagamentiReducer;