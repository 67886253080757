"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mandatiSDDReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const mandatiSDD_types_1 = require("./mandatiSDD.types");
const initialState = {
  mandatiSDD: [],
  parametri: (0, api_rest_1.ParametriAssegniDtoFromJSON)({}),
  filter: (0, mandatiSDD_types_1.FiltroMandatoSDDFromJSONTyped)({}),
  pdfMandatiSDD: undefined,
  sddSelezionato: undefined,
  esitoRevocaMandatoSDD: (0, api_rest_1.EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSON)({}),
  infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  formRevoca: (0, mandatiSDD_types_1.FormRevocaRevocaSDDDtoFromJSON)({}),
  revocaSelezionata: false
};
const mandatiSDDReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.GET_MANDATI_SDD_SUCCESS:
      return {
        ...state,
        mandatiSDD: action.payload
      };
    case __1.EReduxActionTypes.MANDATI_SDD_GET_PARAMETRI_SUCCESS:
      return {
        ...initialState,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.MANDATI_SDD_STATI_MANDATI:
      return {
        ...state,
        filter: {
          ...state.filter,
          statoMandato: action.payload
        }
      };
    case __1.EReduxActionTypes.MANDATI_SDD_RAPPORTO_SELEZIONATO:
      {
        return {
          ...state,
          filter: {
            ...state.filter,
            rapportoSelezionato: action.payload
          }
        };
      }
    case __1.EReduxActionTypes.MANDATI_SDD_RESET_FILTRI:
      return {
        ...state,
        filter: {
          ...state.filter,
          statoMandato: []
        }
      };
    case __1.EReduxActionTypes.MANDATI_SDD_RESET:
      return {
        ...state,
        esitoRevocaMandatoSDD: initialState.esitoRevocaMandatoSDD
      };
    case __1.EReduxActionTypes.MANDATI_SDD_MANDATO_SELEZIONATO:
      return {
        ...state,
        sddSelezionato: action.payload,
        formRevoca: initialState.formRevoca
      };
    case __1.EReduxActionTypes.MANDATI_SDD_GET_INFO_REVOCA_SUCCESS:
      return {
        ...state,
        infoRevoca: action.payload,
        esitoRevocaMandatoSDD: initialState.esitoRevocaMandatoSDD
      };
    case __1.EReduxActionTypes.MANDATI_SDD_AUTORIZZA_REVOCA_SUCCESS:
      // return {
      //   ...state,
      //   parametri: initialState.parametri,
      //   infoRevoca: initialState.infoRevoca,
      //   esitoRevocaMandatoSDD: action.payload,
      // };
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esitoRevocaMandatoSDD: action.payload
        };
      } else {
        return {
          ...state,
          parametri: initialState.parametri,
          esitoRevocaMandatoSDD: initialState.esitoRevocaMandatoSDD
        };
      }
    case __1.EReduxActionTypes.MANDATI_SDD_STEP_REVOCA:
      return {
        ...state,
        formRevoca: {
          ...state.formRevoca,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.MANDATI_SDD_RESET_MANDATO_SELEZIONATO:
      return {
        ...state,
        sddSelezionato: undefined
      };
    case __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_PDF_SUCCESS:
      return {
        ...state,
        pdfMandatiSDD: action.payload
      };
    case __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_SUCCESS:
      return {
        ...state,
        pdfDettaglioMandatoSDD: action.payload
      };
    case __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_EXCEL_SUCCESS:
      return {
        ...state,
        excelMandatiSDD: action.payload
      };
    case __1.EReduxActionTypes.MANDATI_SDD_REVOCA_SELEZIONATA:
      return {
        ...state,
        revocaSelezionata: action.payload
      };
    case __1.EReduxActionTypes.MANDATI_SDD_RESET_TUTTO:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.mandatiSDDReducer = mandatiSDDReducer;