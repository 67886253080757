"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildCerca = exports.modifica1Click = exports.crea1Click = exports.infoAutenticazione1Click = exports.esito1Click = exports.riepilogo1Click = exports.filtriOneClickApplicati = exports.parametriOneClick = exports.operazioneSelezionata = exports.formNewClickValid = exports.formOneClickValid = exports.formOneClick = exports.operazioniOneClick = exports.operazioniOneclickHomepage = void 0;
const reselect_1 = require("reselect");
const operazioniOneClickReducer = state => state;
exports.operazioniOneclickHomepage = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.operazioniOneclickHomepage;
});
exports.operazioniOneClick = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.operazioniOneClick;
});
exports.formOneClick = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.form;
});
exports.formOneClickValid = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  let formValid = false;
  if (!state.form.contenutoError && !state.form.tipoOperazioneError) {
    formValid = true;
  }
  return formValid;
});
exports.formNewClickValid = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  let formValid = false;
  if (!state.form.tipoOperazioneError && !state.form.aliasError) {
    formValid = true;
  }
  return formValid;
});
exports.operazioneSelezionata = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.operazioneSelezionata;
});
exports.parametriOneClick = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.parametri;
});
exports.filtriOneClickApplicati = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  let r = false;
  if (!!state.form.contenuto || !!state.form.tipoOperazione) {
    r = true;
  }
  return r;
});
exports.riepilogo1Click = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.riepilogo;
});
exports.esito1Click = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.esito;
});
exports.infoAutenticazione1Click = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.infoDispositiva;
});
exports.crea1Click = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.creaOneClick;
});
exports.modifica1Click = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return state.modificaOneClick;
});
exports.buildCerca = (0, reselect_1.createSelector)(operazioniOneClickReducer, state => {
  return {
    idContatto: undefined,
    numeroOperazioni: undefined,
    paroleChiave: state.form.contenuto ? state.form.contenuto : undefined,
    tipoOperazione: state.form.tipoOperazioneRicerca ? state.form.tipoOperazioneRicerca : undefined
  };
});