"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNdgPresent = exports.prodottiTop = exports.getTitleByCodiceFunzione = exports.prodottoCatalogoDaId = exports.prodottoCatalogoDaCodiceFunzione = exports.prodottoSelezionato = exports.catalogoProdotti = void 0;
const reselect_1 = require("reselect");
const catalogoProdottiReducer = state => state;
exports.catalogoProdotti = (0, reselect_1.createSelector)(catalogoProdottiReducer, state => {
  return state.catalogo;
});
exports.prodottoSelezionato = (0, reselect_1.createSelector)(catalogoProdottiReducer, state => {
  return state.prodottoSelezionato;
});
exports.prodottoCatalogoDaCodiceFunzione = (0, reselect_1.createSelector)(catalogoProdottiReducer, state => {
  return state.prodottoCatalogoDaCodiceFunzione;
});
exports.prodottoCatalogoDaId = (0, reselect_1.createSelector)(catalogoProdottiReducer, state => {
  return state.prodottoCatalogoDaId;
});
exports.getTitleByCodiceFunzione = (0, reselect_1.createSelector)(catalogoProdottiReducer, state => {
  let title = '-';
  if (!!state.prodottoCatalogoDaCodiceFunzione && !state.prodottoSelezionato) {
    title = state.prodottoCatalogoDaCodiceFunzione.title;
  } else if (!!state.prodottoSelezionato) {
    title = state.prodottoSelezionato.title;
  }
  return title;
});
exports.prodottiTop = (0, reselect_1.createSelector)(catalogoProdottiReducer, state => {
  return state.prodottiTop;
});
exports.isNdgPresent = (0, reselect_1.createSelector)(catalogoProdottiReducer, state => {
  return state.isNdgPresent;
});