"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum = exports.DatiAdeguataVerificaDtoNaturaGiuridicaEnum = exports.DatiAdeguataVerificaDtoTipoClienteEnum = exports.DatiAdeguataVerificaDtoToJSON = exports.DatiAdeguataVerificaDtoFromJSONTyped = exports.DatiAdeguataVerificaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiAdeguataVerificaDtoFromJSON(json) {
  return DatiAdeguataVerificaDtoFromJSONTyped(json, false);
}
exports.DatiAdeguataVerificaDtoFromJSON = DatiAdeguataVerificaDtoFromJSON;
function DatiAdeguataVerificaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idQuestionario': !runtime_1.exists(json, 'idQuestionario') ? undefined : json['idQuestionario'],
    'statoQuestionario': !runtime_1.exists(json, 'statoQuestionario') ? undefined : json['statoQuestionario'],
    'dataScadenzaQuestionario': !runtime_1.exists(json, 'dataScadenzaQuestionario') ? undefined : json['dataScadenzaQuestionario'],
    'dataUltimoQuestionario': !runtime_1.exists(json, 'dataUltimoQuestionario') ? undefined : json['dataUltimoQuestionario'],
    'fasciaRischio': !runtime_1.exists(json, 'fasciaRischio') ? undefined : json['fasciaRischio'],
    'tipoCliente': !runtime_1.exists(json, 'tipoCliente') ? undefined : json['tipoCliente'],
    'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
    'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
    'codiceCittadinanza': !runtime_1.exists(json, 'codiceCittadinanza') ? undefined : json['codiceCittadinanza'],
    'cittadinanza': !runtime_1.exists(json, 'cittadinanza') ? undefined : json['cittadinanza'],
    'cittadinanza2': !runtime_1.exists(json, 'cittadinanza2') ? undefined : json['cittadinanza2'],
    'sesso': !runtime_1.exists(json, 'sesso') ? undefined : json['sesso'],
    'luogoNascita': !runtime_1.exists(json, 'luogoNascita') ? undefined : json['luogoNascita'],
    'provinciaNascita': !runtime_1.exists(json, 'provinciaNascita') ? undefined : json['provinciaNascita'],
    'dataNascita': !runtime_1.exists(json, 'dataNascita') ? undefined : json['dataNascita'],
    'statoNascita': !runtime_1.exists(json, 'statoNascita') ? undefined : json['statoNascita'],
    'codiceStatoNascita': !runtime_1.exists(json, 'codiceStatoNascita') ? undefined : json['codiceStatoNascita'],
    'indirizzoResidenza': !runtime_1.exists(json, 'indirizzoResidenza') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['indirizzoResidenza']),
    'indirizzoDomicilio': !runtime_1.exists(json, 'indirizzoDomicilio') ? undefined : _1.DomiciliazioneAmministrativaDtoFromJSON(json['indirizzoDomicilio']),
    'documentoRiconoscimento': !runtime_1.exists(json, 'documentoRiconoscimento') ? undefined : _1.DocumentoDatiAnagraficiDtoFromJSON(json['documentoRiconoscimento']),
    'dataScadenzaDocumento': !runtime_1.exists(json, 'dataScadenzaDocumento') ? undefined : json['dataScadenzaDocumento'],
    'personaPoliticamenteEsposta': !runtime_1.exists(json, 'personaPoliticamenteEsposta') ? undefined : json['personaPoliticamenteEsposta'],
    'altreCarichePubbliche': !runtime_1.exists(json, 'altreCarichePubbliche') ? undefined : json['altreCarichePubbliche'],
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoADVDtoFromJSON),
    'naturaGiuridica': !runtime_1.exists(json, 'naturaGiuridica') ? undefined : json['naturaGiuridica'],
    'qualificaLavorativa': !runtime_1.exists(json, 'qualificaLavorativa') ? undefined : json['qualificaLavorativa'],
    'professione': !runtime_1.exists(json, 'professione') ? undefined : json['professione'],
    'ultimaProfessione': !runtime_1.exists(json, 'ultimaProfessione') ? undefined : json['ultimaProfessione'],
    'tae': !runtime_1.exists(json, 'tae') ? undefined : json['tae'],
    'cae': !runtime_1.exists(json, 'cae') ? undefined : json['cae'],
    'provinciaAttivitaPrevalente': !runtime_1.exists(json, 'provinciaAttivitaPrevalente') ? undefined : json['provinciaAttivitaPrevalente'],
    'statoAttivitaPrevalente': !runtime_1.exists(json, 'statoAttivitaPrevalente') ? undefined : json['statoAttivitaPrevalente'],
    'datiRedditoPatrimonio': !runtime_1.exists(json, 'datiRedditoPatrimonio') ? undefined : _1.DatiRedditualiPatrimonialiDtoFromJSON(json['datiRedditoPatrimonio']),
    'primoPaeseRelazioniCliente': !runtime_1.exists(json, 'primoPaeseRelazioniCliente') ? undefined : json['primoPaeseRelazioniCliente'],
    'secondoPaeseRelazioniCliente': !runtime_1.exists(json, 'secondoPaeseRelazioniCliente') ? undefined : json['secondoPaeseRelazioniCliente'],
    'terzoPaeseRelazioniCliente': !runtime_1.exists(json, 'terzoPaeseRelazioniCliente') ? undefined : json['terzoPaeseRelazioniCliente'],
    'filialeMadre': !runtime_1.exists(json, 'filialeMadre') ? undefined : json['filialeMadre'],
    'noteAttivita': !runtime_1.exists(json, 'noteAttivita') ? undefined : json['noteAttivita'],
    'appaltiFinanziamentiPubblici': !runtime_1.exists(json, 'appaltiFinanziamentiPubblici') ? undefined : json['appaltiFinanziamentiPubblici'],
    'statoDocumentoRiconoscimento': !runtime_1.exists(json, 'statoDocumentoRiconoscimento') ? undefined : json['statoDocumentoRiconoscimento'],
    'errore': !runtime_1.exists(json, 'errore') ? undefined : json['errore'],
    'descrizioneErrore': !runtime_1.exists(json, 'descrizioneErrore') ? undefined : json['descrizioneErrore']
  };
}
exports.DatiAdeguataVerificaDtoFromJSONTyped = DatiAdeguataVerificaDtoFromJSONTyped;
function DatiAdeguataVerificaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idQuestionario': value.idQuestionario,
    'statoQuestionario': value.statoQuestionario,
    'dataScadenzaQuestionario': value.dataScadenzaQuestionario,
    'dataUltimoQuestionario': value.dataUltimoQuestionario,
    'fasciaRischio': value.fasciaRischio,
    'tipoCliente': value.tipoCliente,
    'ndg': value.ndg,
    'codiceFiscale': value.codiceFiscale,
    'nome': value.nome,
    'cognome': value.cognome,
    'codiceCittadinanza': value.codiceCittadinanza,
    'cittadinanza': value.cittadinanza,
    'cittadinanza2': value.cittadinanza2,
    'sesso': value.sesso,
    'luogoNascita': value.luogoNascita,
    'provinciaNascita': value.provinciaNascita,
    'dataNascita': value.dataNascita,
    'statoNascita': value.statoNascita,
    'codiceStatoNascita': value.codiceStatoNascita,
    'indirizzoResidenza': _1.IndirizzoDatiAnagraficiDtoToJSON(value.indirizzoResidenza),
    'indirizzoDomicilio': _1.DomiciliazioneAmministrativaDtoToJSON(value.indirizzoDomicilio),
    'documentoRiconoscimento': _1.DocumentoDatiAnagraficiDtoToJSON(value.documentoRiconoscimento),
    'dataScadenzaDocumento': value.dataScadenzaDocumento,
    'personaPoliticamenteEsposta': value.personaPoliticamenteEsposta,
    'altreCarichePubbliche': value.altreCarichePubbliche,
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoADVDtoToJSON),
    'naturaGiuridica': value.naturaGiuridica,
    'qualificaLavorativa': value.qualificaLavorativa,
    'professione': value.professione,
    'ultimaProfessione': value.ultimaProfessione,
    'tae': value.tae,
    'cae': value.cae,
    'provinciaAttivitaPrevalente': value.provinciaAttivitaPrevalente,
    'statoAttivitaPrevalente': value.statoAttivitaPrevalente,
    'datiRedditoPatrimonio': _1.DatiRedditualiPatrimonialiDtoToJSON(value.datiRedditoPatrimonio),
    'primoPaeseRelazioniCliente': value.primoPaeseRelazioniCliente,
    'secondoPaeseRelazioniCliente': value.secondoPaeseRelazioniCliente,
    'terzoPaeseRelazioniCliente': value.terzoPaeseRelazioniCliente,
    'filialeMadre': value.filialeMadre,
    'noteAttivita': value.noteAttivita,
    'appaltiFinanziamentiPubblici': value.appaltiFinanziamentiPubblici,
    'statoDocumentoRiconoscimento': value.statoDocumentoRiconoscimento,
    'errore': value.errore,
    'descrizioneErrore': value.descrizioneErrore
  };
}
exports.DatiAdeguataVerificaDtoToJSON = DatiAdeguataVerificaDtoToJSON;
/**
* @export
* @enum {string}
*/
var DatiAdeguataVerificaDtoTipoClienteEnum;
(function (DatiAdeguataVerificaDtoTipoClienteEnum) {
  DatiAdeguataVerificaDtoTipoClienteEnum["NUOVO"] = "NUOVO";
  DatiAdeguataVerificaDtoTipoClienteEnum["TITOLARE_RAPPORTO_PRESSO_BANCA"] = "TITOLARE_RAPPORTO_PRESSO_BANCA";
  DatiAdeguataVerificaDtoTipoClienteEnum["OCCASIONALE"] = "OCCASIONALE";
})(DatiAdeguataVerificaDtoTipoClienteEnum = exports.DatiAdeguataVerificaDtoTipoClienteEnum || (exports.DatiAdeguataVerificaDtoTipoClienteEnum = {}));
/**
* @export
* @enum {string}
*/
var DatiAdeguataVerificaDtoNaturaGiuridicaEnum;
(function (DatiAdeguataVerificaDtoNaturaGiuridicaEnum) {
  DatiAdeguataVerificaDtoNaturaGiuridicaEnum["PERSONA_FISICA"] = "PERSONA_FISICA";
  DatiAdeguataVerificaDtoNaturaGiuridicaEnum["SOCIETA"] = "SOCIETA";
  DatiAdeguataVerificaDtoNaturaGiuridicaEnum["BANCHE"] = "BANCHE";
  DatiAdeguataVerificaDtoNaturaGiuridicaEnum["ENTI"] = "ENTI";
})(DatiAdeguataVerificaDtoNaturaGiuridicaEnum = exports.DatiAdeguataVerificaDtoNaturaGiuridicaEnum || (exports.DatiAdeguataVerificaDtoNaturaGiuridicaEnum = {}));
/**
* @export
* @enum {string}
*/
var DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum;
(function (DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum) {
  DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum["VALIDO"] = "VALIDO";
  DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum["SCADUTO"] = "SCADUTO";
  DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum["IN_ATTESA_DI_APPROVAZIONE"] = "IN_ATTESA_DI_APPROVAZIONE";
})(DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum = exports.DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum || (exports.DatiAdeguataVerificaDtoStatoDocumentoRiconoscimentoEnum = {}));