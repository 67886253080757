"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorManagementResponseToJSON = exports.ErrorManagementResponseFromJSONTyped = exports.ErrorManagementResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
function ErrorManagementResponseFromJSON(json) {
  return ErrorManagementResponseFromJSONTyped(json, false);
}
exports.ErrorManagementResponseFromJSON = ErrorManagementResponseFromJSON;
function ErrorManagementResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'errorCode': !runtime_1.exists(json, 'errorCode') ? undefined : json['errorCode'],
    'errorDescription': !runtime_1.exists(json, 'errorDescription') ? undefined : json['errorDescription']
  };
}
exports.ErrorManagementResponseFromJSONTyped = ErrorManagementResponseFromJSONTyped;
function ErrorManagementResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'errorCode': value.errorCode,
    'errorDescription': value.errorDescription
  };
}
exports.ErrorManagementResponseToJSON = ErrorManagementResponseToJSON;