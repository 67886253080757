"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GeometryToJSON = exports.GeometryFromJSONTyped = exports.GeometryFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const Location_1 = require("./Location");
function GeometryFromJSON(json) {
  return GeometryFromJSONTyped(json, false);
}
exports.GeometryFromJSON = GeometryFromJSON;
function GeometryFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'location': !(0, api_rest_1.exists)(json, 'location') ? undefined : (0, Location_1.LocationFromJSON)(json['location'])
  };
}
exports.GeometryFromJSONTyped = GeometryFromJSONTyped;
function GeometryToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'location': value.location
  };
}
exports.GeometryToJSON = GeometryToJSON;