import { ProfiloMenu, ProfiloMenuDto, selectedAbi } from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  menu: ProfiloMenuDto
  navigateTo: (path: string) => void
  onChangeLanguage: (lingua: string) => void
  currentLanguage: string
  onLogout: () => void
  openSubMenuArea: (items: ProfiloMenu[] | undefined, label: string) => void
  areaLabelLvl1: string
  isMenuComunicazioniVisible: boolean
  isMenuProfiloVisible: boolean
  isMenuAssistenzaVisible: boolean
  isNotificaMenuComunicazioniVisible: boolean
  isNotificaMenuProfiloVisible: boolean
  isNotificaMenuAssistenzaVisible: boolean
};

const NavbarButtons = (props: Props) => {

  const { t } = useTranslation();

  const {
    onChangeLanguage,
    currentLanguage,
    onLogout,
    menu,
    openSubMenuArea,
    areaLabelLvl1,
    isMenuComunicazioniVisible,
    isMenuProfiloVisible,
    isMenuAssistenzaVisible,
    isNotificaMenuComunicazioniVisible,
    isNotificaMenuProfiloVisible,
    isNotificaMenuAssistenzaVisible
  } = props;

  const renderLanguageTooltip = (props: any) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t(`home.navbar.language.${props.language}`)}
      </Tooltip>
    );
  };

  const renderTooltip = (el: string) => {
    return <Tooltip id="button-tooltip">{el}</Tooltip>
  };

  const renderLogoutTooltip = (props: any) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t('home.navbar.logout')}
      </Tooltip>
    );
  };

  const selectedElements: [boolean, boolean, boolean] = useMemo(() => {
    return [
      areaLabelLvl1 == t('home.navbar.assistenza'),
      areaLabelLvl1 == t('home.navbar.areaPersonale'),
      areaLabelLvl1 == t('home.navbar.comunicazioni')
    ];
  }, [areaLabelLvl1, t]);

  return (
    <>

      <div className="header-buttons header-buttons-first-group">

        {isMenuComunicazioniVisible &&
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(t('home.navbar.comunicazioni'))}
          >
            <div
              id={'comunicazioni'}
              className={`${isNotificaMenuComunicazioniVisible ? 'has-notification' : ''} ${selectedElements[2] ? 'is-selected' : ''}`}
              onClick={() => openSubMenuArea(menu?.comunicazioniItems, t('home.navbar.comunicazioni'))}
            >
              <BankIcon title="comunicazioni" />
            </div>
          </OverlayTrigger>
        }

        {isMenuProfiloVisible &&
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(t('home.navbar.areaPersonale'))}
          >
            <div
              id={'profilo'}
              className={`${isNotificaMenuProfiloVisible ? 'has-notification' : ''} ${selectedElements[1] ? 'is-selected' : ''}`}
              onClick={() => openSubMenuArea(menu?.profiloItems, t('home.navbar.areaPersonale'))}
            >
              <BankIcon title="profilo" />
            </div>
          </OverlayTrigger>
        }

        {isMenuAssistenzaVisible &&
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(t('home.navbar.assistenza'))}
          >
            <div
              id={'assistenza'}
              className={`${isNotificaMenuAssistenzaVisible ? 'has-notification' : ''} ${selectedElements[0] ? 'is-selected' : ''}`}
              onClick={() => openSubMenuArea(menu?.assistenzaItems, t('home.navbar.assistenza'))}
            >
              <BankIcon title="assistenza" />
            </div>
          </OverlayTrigger>
        }

      </div>

      <div className="header-buttons header-buttons-second-group">

        {selectedAbi === '06045' && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 250 }}
            overlay={renderLanguageTooltip({
              language: currentLanguage === "it" ? "de" : "it"
            })}
          >
            <div
              className="header-change-language"
              onClick={() => onChangeLanguage(currentLanguage === "it" ? "de" : "it")}
            >
              <BankIcon
                title={`lingua-${currentLanguage === "it" ? "de" : "it"}`}
              />
            </div>
          </OverlayTrigger>
        )}

        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderLogoutTooltip}
        >
          <div
            className="header-logout"
            onClick={onLogout}
          >
            <BankIcon
              title="logout"
              white
            />
          </div>
        </OverlayTrigger>

      </div>

    </>
  );
};

export default NavbarButtons;
