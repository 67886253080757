"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stampaPDFBilancio = exports.resetBilancio = exports.setBilancioResetFiltri = exports.setBilancioSetTuttiRapportiSelezionati = exports.setBilancioSetDataFinale = exports.setBilancioSetDataIniziale = exports.setBilancioSetRapportoSelezionato = exports.getBilancio = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const bilancio_selectors_1 = require("./bilancio.selectors");
/* GET BILANCIO - START */
function getBilancioStart() {
  return {
    type: __1.EReduxActionTypes.BILANCIO_GET_BILANCIO_START
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_1.setError)(value));
  };
}
function getBilancioSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BILANCIO_GET_BILANCIO_SUCCESS,
    payload
  };
}
const getBilancio = function (criteriBilancioIn) {
  let loaderEnabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return async (dispatch, getState, extraArguments) => {
    loaderEnabled && dispatch((0, general_1.setLoader)(true));
    dispatch(getBilancioStart());
    const state = getState().bilancio;
    const _criteriBilancioIn = (0, bilancio_selectors_1.buildFilterBilancio)(state);
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/bilancio.json')).then(response => {
        dispatch(getBilancioSuccess((0, api_rest_1.BilancioDtoFromJSON)(response)));
        loaderEnabled && dispatch((0, general_1.setLoader)(false));
      });
    } else {
      const filter = {
        criteriBilancioIn: criteriBilancioIn || _criteriBilancioIn
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.BilancioControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bilancioTrimestrale(filter).then(response => {
          dispatch(getBilancioSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getBilancio = getBilancio;
/* GET BILANCIO - END */
/* FILTER - START */
function setBilancioSetRapportoSelezionato(payload) {
  return {
    type: __1.EReduxActionTypes.BILANCIO_RAPPORTO_SELEZIONATO,
    payload
  };
}
exports.setBilancioSetRapportoSelezionato = setBilancioSetRapportoSelezionato;
function setBilancioSetDataIniziale(payload) {
  return {
    type: __1.EReduxActionTypes.BILANCIO_DATA_INIZIALE,
    payload
  };
}
exports.setBilancioSetDataIniziale = setBilancioSetDataIniziale;
function setBilancioSetDataFinale(payload) {
  return {
    type: __1.EReduxActionTypes.BILANCIO_DATA_FINALE,
    payload
  };
}
exports.setBilancioSetDataFinale = setBilancioSetDataFinale;
function setBilancioSetTuttiRapportiSelezionati(payload) {
  return {
    type: __1.EReduxActionTypes.BILANCIO_TUTTI_RAPPORTI_SELEZIONATI,
    payload
  };
}
exports.setBilancioSetTuttiRapportiSelezionati = setBilancioSetTuttiRapportiSelezionati;
function setBilancioResetFiltri() {
  return {
    type: __1.EReduxActionTypes.BILANCIO_RESET_FILTRI
  };
}
exports.setBilancioResetFiltri = setBilancioResetFiltri;
/* FILTER - END */
function resetBilancio() {
  return {
    type: __1.EReduxActionTypes.BILANCIO_RESET
  };
}
exports.resetBilancio = resetBilancio;
/* stampa PDF - start
 */
function stampaPDFBilancioStart() {
  return {
    type: __1.EReduxActionTypes.BILANCIO_EXPORT_PDF_START_ACTION
  };
}
function stampaPDFBilancioSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BILANCIO_EXPORT_PDF_SUCCESS_ACTION,
    payload
  };
}
const stampaPDFBilancio = (share, title, message) => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  dispatch((0, general_1.setLoader)(true));
  dispatch(stampaPDFBilancioStart());
  const state = getState().bilancio;
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bilancio.json')).then(response => {
      dispatch(getBilancioSuccess((0, api_rest_1.BilancioDtoFromJSON)(response)));
    });
  } else {
    const filter = {
      elencoBilancioDto: {
        codiceRapporto: (_b = (_a = state.filtri.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id,
        bilancio: state.bilancio
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BilancioControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaElencoBilancioPdf(filter).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'bilancio.pdf', response, share, title, message);
        }
        dispatch(stampaPDFBilancioSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.stampaPDFBilancio = stampaPDFBilancio;
/* stampa PDF - end
 */