"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiPagamentoTributoDtoToJSON = exports.DatiPagamentoTributoDtoFromJSONTyped = exports.DatiPagamentoTributoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiPagamentoTributoDtoFromJSON(json) {
  return DatiPagamentoTributoDtoFromJSONTyped(json, false);
}
exports.DatiPagamentoTributoDtoFromJSON = DatiPagamentoTributoDtoFromJSON;
function DatiPagamentoTributoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idDisposzione': !runtime_1.exists(json, 'idDisposzione') ? undefined : json['idDisposzione'],
    'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    'idPagamentoTributo': !runtime_1.exists(json, 'idPagamentoTributo') ? undefined : json['idPagamentoTributo'],
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    'codicePresaInCarico': !runtime_1.exists(json, 'codicePresaInCarico') ? undefined : json['codicePresaInCarico'],
    'statoTributo': !runtime_1.exists(json, 'statoTributo') ? undefined : json['statoTributo'],
    'pagamento': !runtime_1.exists(json, 'pagamento') ? undefined : _1.PagamentoTributiDtoFromJSON(json['pagamento']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : json['disposizione']
  };
}
exports.DatiPagamentoTributoDtoFromJSONTyped = DatiPagamentoTributoDtoFromJSONTyped;
function DatiPagamentoTributoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idDisposzione': value.idDisposzione,
    'statoDisposizione': value.statoDisposizione,
    'idPagamentoTributo': value.idPagamentoTributo,
    'tipo': value.tipo,
    'codicePresaInCarico': value.codicePresaInCarico,
    'statoTributo': value.statoTributo,
    'pagamento': _1.PagamentoTributiDtoToJSON(value.pagamento),
    'disposizione': value.disposizione
  };
}
exports.DatiPagamentoTributoDtoToJSON = DatiPagamentoTributoDtoToJSON;