"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CofidisUrlDtoToJSON = exports.CofidisUrlDtoFromJSONTyped = exports.CofidisUrlDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CofidisUrlDtoFromJSON(json) {
  return CofidisUrlDtoFromJSONTyped(json, false);
}
exports.CofidisUrlDtoFromJSON = CofidisUrlDtoFromJSON;
function CofidisUrlDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'success': !runtime_1.exists(json, 'success') ? undefined : json['success'],
    'url': !runtime_1.exists(json, 'url') ? undefined : json['url']
  };
}
exports.CofidisUrlDtoFromJSONTyped = CofidisUrlDtoFromJSONTyped;
function CofidisUrlDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'success': value.success,
    'url': value.url
  };
}
exports.CofidisUrlDtoToJSON = CofidisUrlDtoToJSON;