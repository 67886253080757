"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfiloDatiPersonaliDtoToJSON = exports.ProfiloDatiPersonaliDtoFromJSONTyped = exports.ProfiloDatiPersonaliDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProfiloDatiPersonaliDtoFromJSON(json) {
  return ProfiloDatiPersonaliDtoFromJSONTyped(json, false);
}
exports.ProfiloDatiPersonaliDtoFromJSON = ProfiloDatiPersonaliDtoFromJSON;
function ProfiloDatiPersonaliDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'cellulare': !runtime_1.exists(json, 'cellulare') ? undefined : json['cellulare'],
    'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
    'dataNascita': !runtime_1.exists(json, 'dataNascita') ? undefined : new Date(json['dataNascita']),
    'luogoNascita': !runtime_1.exists(json, 'luogoNascita') ? undefined : json['luogoNascita'],
    'naturaGiuridica': !runtime_1.exists(json, 'naturaGiuridica') ? undefined : json['naturaGiuridica'],
    'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
    'anagrafe': !runtime_1.exists(json, 'anagrafe') ? undefined : _1.ProfiloAnagrafeDtoFromJSON(json['anagrafe']),
    'immaginePersonale': !runtime_1.exists(json, 'immaginePersonale') ? undefined : _1.ProfiloImmaginePersonaleDtoFromJSON(json['immaginePersonale'])
  };
}
exports.ProfiloDatiPersonaliDtoFromJSONTyped = ProfiloDatiPersonaliDtoFromJSONTyped;
function ProfiloDatiPersonaliDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'cellulare': value.cellulare,
    'email': value.email,
    'codiceFiscale': value.codiceFiscale,
    'descrizione': value.descrizione,
    'indirizzo': value.indirizzo,
    'dataNascita': value.dataNascita === undefined ? undefined : value.dataNascita.toISOString(),
    'luogoNascita': value.luogoNascita,
    'naturaGiuridica': value.naturaGiuridica,
    'lingua': value.lingua,
    'anagrafe': _1.ProfiloAnagrafeDtoToJSON(value.anagrafe),
    'immaginePersonale': _1.ProfiloImmaginePersonaleDtoToJSON(value.immaginePersonale)
  };
}
exports.ProfiloDatiPersonaliDtoToJSON = ProfiloDatiPersonaliDtoToJSON;