"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookDtoToJSON = exports.BookDtoFromJSONTyped = exports.BookDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BookDtoFromJSON(json) {
  return BookDtoFromJSONTyped(json, false);
}
exports.BookDtoFromJSON = BookDtoFromJSON;
function BookDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceMercato': !runtime_1.exists(json, 'codiceMercato') ? undefined : json['codiceMercato'],
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
    'varPerc': !runtime_1.exists(json, 'varPerc') ? undefined : json['varPerc'],
    'ultimoPrezzo': !runtime_1.exists(json, 'ultimoPrezzo') ? undefined : json['ultimoPrezzo'],
    'oraUltimoPrezzo': !runtime_1.exists(json, 'oraUltimoPrezzo') ? undefined : new Date(json['oraUltimoPrezzo']),
    'volTrade': !runtime_1.exists(json, 'volTrade') ? undefined : json['volTrade'],
    'numDenari': !runtime_1.exists(json, 'numDenari') ? undefined : json['numDenari'],
    'denari': !runtime_1.exists(json, 'denari') ? undefined : json['denari'],
    'volumeDenari': !runtime_1.exists(json, 'volumeDenari') ? undefined : json['volumeDenari'],
    'numLettere': !runtime_1.exists(json, 'numLettere') ? undefined : json['numLettere'],
    'lettere': !runtime_1.exists(json, 'lettere') ? undefined : json['lettere'],
    'volumeLettere': !runtime_1.exists(json, 'volumeLettere') ? undefined : json['volumeLettere']
  };
}
exports.BookDtoFromJSONTyped = BookDtoFromJSONTyped;
function BookDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceMercato': value.codiceMercato,
    'codice': value.codice,
    'codiceIsin': value.codiceIsin,
    'varPerc': value.varPerc,
    'ultimoPrezzo': value.ultimoPrezzo,
    'oraUltimoPrezzo': value.oraUltimoPrezzo === undefined ? undefined : value.oraUltimoPrezzo.toISOString(),
    'volTrade': value.volTrade,
    'numDenari': value.numDenari,
    'denari': value.denari,
    'volumeDenari': value.volumeDenari,
    'numLettere': value.numLettere,
    'lettere': value.lettere,
    'volumeLettere': value.volumeLettere
  };
}
exports.BookDtoToJSON = BookDtoToJSON;