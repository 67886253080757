"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PosizioneDisponibilitaDtoToJSON = exports.PosizioneDisponibilitaDtoFromJSONTyped = exports.PosizioneDisponibilitaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PosizioneDisponibilitaDtoFromJSON(json) {
  return PosizioneDisponibilitaDtoFromJSONTyped(json, false);
}
exports.PosizioneDisponibilitaDtoFromJSON = PosizioneDisponibilitaDtoFromJSON;
function PosizioneDisponibilitaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
    'tipologiaDesc': !runtime_1.exists(json, 'tipologiaDesc') ? undefined : json['tipologiaDesc'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore']
  };
}
exports.PosizioneDisponibilitaDtoFromJSONTyped = PosizioneDisponibilitaDtoFromJSONTyped;
function PosizioneDisponibilitaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipologia': value.tipologia,
    'tipologiaDesc': value.tipologiaDesc,
    'divisa': value.divisa,
    'controvalore': value.controvalore
  };
}
exports.PosizioneDisponibilitaDtoToJSON = PosizioneDisponibilitaDtoToJSON;