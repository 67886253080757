"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriNexiDtoToJSON = exports.ParametriNexiDtoFromJSONTyped = exports.ParametriNexiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriNexiDtoFromJSON(json) {
  return ParametriNexiDtoFromJSONTyped(json, false);
}
exports.ParametriNexiDtoFromJSON = ParametriNexiDtoFromJSON;
function ParametriNexiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCartaNexiDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri']
  };
}
exports.ParametriNexiDtoFromJSONTyped = ParametriNexiDtoFromJSONTyped;
function ParametriNexiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCartaNexiDtoToJSON),
    'parametri': value.parametri
  };
}
exports.ParametriNexiDtoToJSON = ParametriNexiDtoToJSON;