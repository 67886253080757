"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaveQuestionnaireAnswersInDtoToJSON = exports.SaveQuestionnaireAnswersInDtoFromJSONTyped = exports.SaveQuestionnaireAnswersInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SaveQuestionnaireAnswersInDtoFromJSON(json) {
  return SaveQuestionnaireAnswersInDtoFromJSONTyped(json, false);
}
exports.SaveQuestionnaireAnswersInDtoFromJSON = SaveQuestionnaireAnswersInDtoFromJSON;
function SaveQuestionnaireAnswersInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'customerId': json['customerId'],
    'idQuestionnaire': json['idQuestionnaire'],
    'body': !runtime_1.exists(json, 'body') ? undefined : _1.QuestionnaireSaveBodyDtoFromJSON(json['body']),
    'idControl': !runtime_1.exists(json, 'idControl') ? undefined : json['idControl'],
    'updateReason': !runtime_1.exists(json, 'updateReason') ? undefined : json['updateReason'],
    'save': !runtime_1.exists(json, 'save') ? undefined : json['save']
  };
}
exports.SaveQuestionnaireAnswersInDtoFromJSONTyped = SaveQuestionnaireAnswersInDtoFromJSONTyped;
function SaveQuestionnaireAnswersInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'customerId': value.customerId,
    'idQuestionnaire': value.idQuestionnaire,
    'body': _1.QuestionnaireSaveBodyDtoToJSON(value.body),
    'idControl': value.idControl,
    'updateReason': value.updateReason,
    'save': value.save
  };
}
exports.SaveQuestionnaireAnswersInDtoToJSON = SaveQuestionnaireAnswersInDtoToJSON;