"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PianoCedoleDtoToJSON = exports.PianoCedoleDtoFromJSONTyped = exports.PianoCedoleDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PianoCedoleDtoFromJSON(json) {
  return PianoCedoleDtoFromJSONTyped(json, false);
}
exports.PianoCedoleDtoFromJSON = PianoCedoleDtoFromJSON;
function PianoCedoleDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'interessiNetti': !runtime_1.exists(json, 'interessiNetti') ? undefined : json['interessiNetti'],
    'ritenutaFiscale': !runtime_1.exists(json, 'ritenutaFiscale') ? undefined : json['ritenutaFiscale'],
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'tassoCedola': !runtime_1.exists(json, 'tassoCedola') ? undefined : json['tassoCedola'],
    'statoCedola': !runtime_1.exists(json, 'statoCedola') ? undefined : json['statoCedola']
  };
}
exports.PianoCedoleDtoFromJSONTyped = PianoCedoleDtoFromJSONTyped;
function PianoCedoleDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'interessiNetti': value.interessiNetti,
    'ritenutaFiscale': value.ritenutaFiscale,
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'tassoCedola': value.tassoCedola,
    'statoCedola': value.statoCedola
  };
}
exports.PianoCedoleDtoToJSON = PianoCedoleDtoToJSON;