"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.limitiOperativiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
  parametri: (0, api_rest_1.ParametriLimitiOperativiDtoFromJSON)({}),
  limitiOperativi: (0, api_rest_1.LimitiOperativiDtoFromJSON)({})
};
const limitiOperativiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.LIMITI_OPERATIVI_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.LIMITI_OPERATIVI_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_SUCCESS:
      return {
        ...state,
        limitiOperativi: action.payload
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.limitiOperativiReducer = limitiOperativiReducer;