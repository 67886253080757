"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatoAggiuntivoDtoToJSON = exports.DatoAggiuntivoDtoFromJSONTyped = exports.DatoAggiuntivoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatoAggiuntivoDtoFromJSON(json) {
  return DatoAggiuntivoDtoFromJSONTyped(json, false);
}
exports.DatoAggiuntivoDtoFromJSON = DatoAggiuntivoDtoFromJSON;
function DatoAggiuntivoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoOperazione': !runtime_1.exists(json, 'tipoOperazione') ? undefined : json['tipoOperazione'],
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'valore': !runtime_1.exists(json, 'valore') ? undefined : json['valore'],
    'messaggioErrore': !runtime_1.exists(json, 'messaggioErrore') ? undefined : json['messaggioErrore'],
    'esito': !runtime_1.exists(json, 'esito') ? undefined : json['esito']
  };
}
exports.DatoAggiuntivoDtoFromJSONTyped = DatoAggiuntivoDtoFromJSONTyped;
function DatoAggiuntivoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoOperazione': value.tipoOperazione,
    'codice': value.codice,
    'valore': value.valore,
    'messaggioErrore': value.messaggioErrore,
    'esito': value.esito
  };
}
exports.DatoAggiuntivoDtoToJSON = DatoAggiuntivoDtoToJSON;