"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperazioneOneClickDtoStatoDisposizioneEnum = exports.OperazioneOneClickDtoToJSON = exports.OperazioneOneClickDtoFromJSONTyped = exports.OperazioneOneClickDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OperazioneOneClickDtoFromJSON(json) {
  return OperazioneOneClickDtoFromJSONTyped(json, false);
}
exports.OperazioneOneClickDtoFromJSON = OperazioneOneClickDtoFromJSON;
function OperazioneOneClickDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'codiceFunzione': json['codiceFunzione'],
    'idContatto': !runtime_1.exists(json, 'idContatto') ? undefined : json['idContatto'],
    'idMenu': !runtime_1.exists(json, 'idMenu') ? undefined : json['idMenu'],
    'info': !runtime_1.exists(json, 'info') ? undefined : _1.InformazioniDtoFromJSON(json['info']),
    'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    'idOperazione': !runtime_1.exists(json, 'idOperazione') ? undefined : json['idOperazione'],
    'payload': _1.PayloadDtoFromJSON(json['payload'])
  };
}
exports.OperazioneOneClickDtoFromJSONTyped = OperazioneOneClickDtoFromJSONTyped;
function OperazioneOneClickDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'codiceFunzione': value.codiceFunzione,
    'idContatto': value.idContatto,
    'idMenu': value.idMenu,
    'info': _1.InformazioniDtoToJSON(value.info),
    'statoDisposizione': value.statoDisposizione,
    'idOperazione': value.idOperazione,
    'payload': _1.PayloadDtoToJSON(value.payload)
  };
}
exports.OperazioneOneClickDtoToJSON = OperazioneOneClickDtoToJSON;
/**
* @export
* @enum {string}
*/
var OperazioneOneClickDtoStatoDisposizioneEnum;
(function (OperazioneOneClickDtoStatoDisposizioneEnum) {
  OperazioneOneClickDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
  OperazioneOneClickDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
  OperazioneOneClickDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
  OperazioneOneClickDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
  OperazioneOneClickDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
  OperazioneOneClickDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
  OperazioneOneClickDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
  OperazioneOneClickDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
  OperazioneOneClickDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
  OperazioneOneClickDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
  OperazioneOneClickDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
  OperazioneOneClickDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
  OperazioneOneClickDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(OperazioneOneClickDtoStatoDisposizioneEnum = exports.OperazioneOneClickDtoStatoDisposizioneEnum || (exports.OperazioneOneClickDtoStatoDisposizioneEnum = {}));