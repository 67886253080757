"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CodiceIdentificativoDtoToJSON = exports.CodiceIdentificativoDtoFromJSONTyped = exports.CodiceIdentificativoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CodiceIdentificativoDtoFromJSON(json) {
  return CodiceIdentificativoDtoFromJSONTyped(json, false);
}
exports.CodiceIdentificativoDtoFromJSON = CodiceIdentificativoDtoFromJSON;
function CodiceIdentificativoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceSia': !runtime_1.exists(json, 'codiceSia') ? undefined : json['codiceSia'],
    'riferimentoCreditore': !runtime_1.exists(json, 'riferimentoCreditore') ? undefined : json['riferimentoCreditore']
  };
}
exports.CodiceIdentificativoDtoFromJSONTyped = CodiceIdentificativoDtoFromJSONTyped;
function CodiceIdentificativoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceSia': value.codiceSia,
    'riferimentoCreditore': value.riferimentoCreditore
  };
}
exports.CodiceIdentificativoDtoToJSON = CodiceIdentificativoDtoToJSON;