"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RevocaOrdineTradingDtoToJSON = exports.RevocaOrdineTradingDtoFromJSONTyped = exports.RevocaOrdineTradingDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RevocaOrdineTradingDtoFromJSON(json) {
  return RevocaOrdineTradingDtoFromJSONTyped(json, false);
}
exports.RevocaOrdineTradingDtoFromJSON = RevocaOrdineTradingDtoFromJSON;
function RevocaOrdineTradingDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'funzione': !runtime_1.exists(json, 'funzione') ? undefined : json['funzione'],
    'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaFromJSON(json['credenziale']),
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'indicativoTitolo': !runtime_1.exists(json, 'indicativoTitolo') ? undefined : json['indicativoTitolo'],
    'numeroOrdine': !runtime_1.exists(json, 'numeroOrdine') ? undefined : json['numeroOrdine']
  };
}
exports.RevocaOrdineTradingDtoFromJSONTyped = RevocaOrdineTradingDtoFromJSONTyped;
function RevocaOrdineTradingDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'funzione': value.funzione,
    'credenziale': _1.CredenzialeDispositivaToJSON(value.credenziale),
    'codiceRapporto': value.codiceRapporto,
    'titolo': value.titolo,
    'indicativoTitolo': value.indicativoTitolo,
    'numeroOrdine': value.numeroOrdine
  };
}
exports.RevocaOrdineTradingDtoToJSON = RevocaOrdineTradingDtoToJSON;