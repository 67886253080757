"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UltimoAccessoOutDtoToJSON = exports.UltimoAccessoOutDtoFromJSONTyped = exports.UltimoAccessoOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function UltimoAccessoOutDtoFromJSON(json) {
  return UltimoAccessoOutDtoFromJSONTyped(json, false);
}
exports.UltimoAccessoOutDtoFromJSON = UltimoAccessoOutDtoFromJSON;
function UltimoAccessoOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'data': !runtime_1.exists(json, 'data') ? undefined : json['data'],
    'ora': !runtime_1.exists(json, 'ora') ? undefined : json['ora'],
    'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
    'indirizzoIp': !runtime_1.exists(json, 'indirizzoIp') ? undefined : json['indirizzoIp']
  };
}
exports.UltimoAccessoOutDtoFromJSONTyped = UltimoAccessoOutDtoFromJSONTyped;
function UltimoAccessoOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'data': value.data,
    'ora': value.ora,
    'canale': value.canale,
    'indirizzoIp': value.indirizzoIp
  };
}
exports.UltimoAccessoOutDtoToJSON = UltimoAccessoOutDtoToJSON;