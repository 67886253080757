"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxBancomatPayActionTypes = void 0;
var EReduxBancomatPayActionTypes;
(function (EReduxBancomatPayActionTypes) {
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_STEP"] = "BANCOMAT_PAY_STEP";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_STEP_MASSIMALI"] = "BANCOMAT_PAY_STEP_MASSIMALI";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_SET_RAPPORTO_SELEZIONATO"] = "BANCOMAT_PAY_SET_RAPPORTO_SELEZIONATO";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_RESET"] = "BANCOMAT_PAY_RESET";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ACCETTATO"] = "BANCOMAT_PAY_ACCETTATO";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_PARAMETRI_START"] = "BANCOMAT_PAY_GET_PARAMETRI_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_PARAMETRI_SUCCESS"] = "BANCOMAT_PAY_GET_PARAMETRI_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_STATO_UTENTE_START"] = "BANCOMAT_PAY_GET_STATO_UTENTE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_STATO_UTENTE_SUCCESS"] = "BANCOMAT_PAY_GET_STATO_UTENTE_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_SET_ERRORE_SPECIFICO"] = "BANCOMAT_PAY_SET_ERRORE_SPECIFICO";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_RESET_ERRORE_SPECIFICO"] = "BANCOMAT_PAY_RESET_ERRORE_SPECIFICO";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ENROLLMENT_START"] = "BANCOMAT_PAY_ENROLLMENT_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ENROLLMENT_SUCCESS"] = "BANCOMAT_PAY_ENROLLMENT_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_INFO_DISPOSIZIONE_START"] = "BANCOMAT_PAY_GET_INFO_DISPOSIZIONE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_INFO_DISPOSIZIONE_SUCCESS"] = "BANCOMAT_PAY_GET_INFO_DISPOSIZIONE_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_AUTORIZZA_START"] = "BANCOMAT_PAY_AUTORIZZA_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_AUTORIZZA_SUCCESS"] = "BANCOMAT_PAY_AUTORIZZA_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_SET_MASSIMALE_TRANSAZIONE"] = "BANCOMAT_PAY_SET_MASSIMALE_TRANSAZIONE";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_SET_MASSIMALE_GIORNALIERO"] = "BANCOMAT_PAY_SET_MASSIMALE_GIORNALIERO";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_SET_MASSIMALE_MENSILE"] = "BANCOMAT_PAY_SET_MASSIMALE_MENSILE";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_AUTORIZZA_START"] = "BANCOMAT_PAY_MASSIMALI_AUTORIZZA_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_AUTORIZZA_SUCCESS"] = "BANCOMAT_PAY_MASSIMALI_AUTORIZZA_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_AUTORIZZA_ERROR"] = "BANCOMAT_PAY_MASSIMALI_AUTORIZZA_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_START"] = "BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_SUCCESS"] = "BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_ERROR"] = "BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_MODIFICA_START"] = "BANCOMAT_PAY_MASSIMALI_MODIFICA_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_MODIFICA_SUCCESS"] = "BANCOMAT_PAY_MASSIMALI_MODIFICA_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MASSIMALI_MODIFICA_ERROR"] = "BANCOMAT_PAY_MASSIMALI_MODIFICA_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_START"] = "BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_SUCCESS"] = "BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_ERROR"] = "BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_START"] = "BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_ERROR"] = "BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_START"] = "BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_SUCCESS"] = "BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_ERROR"] = "BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_SET_STEP"] = "BANCOMAT_PAY_ATTIVAZIONE_SET_STEP";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_START"] = "BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_SUCCESS"] = "BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_ERROR"] = "BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_START"] = "BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_ERROR"] = "BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_AUTORIZZA_START"] = "BANCOMAT_PAY_DISATTIVA_AUTORIZZA_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_AUTORIZZA_SUCCESS"] = "BANCOMAT_PAY_DISATTIVA_AUTORIZZA_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_AUTORIZZA_ERROR"] = "BANCOMAT_PAY_DISATTIVA_AUTORIZZA_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_SET_STEP"] = "BANCOMAT_PAY_DISATTIVA_SET_STEP";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_UTENTE_START"] = "BANCOMAT_PAY_DISATTIVA_UTENTE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_UTENTE_SUCCESS"] = "BANCOMAT_PAY_DISATTIVA_UTENTE_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_DISATTIVA_UTENTE_ERROR"] = "BANCOMAT_PAY_DISATTIVA_UTENTE_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_CONTROLLO_START"] = "BANCOMAT_PAY_MODIFICA_IBAN_CONTROLO_JIFFY_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_CONTROLLO_SUCCESS"] = "BANCOMAT_PAY_MODIFICA_IBAN_CONTROLO_JIFFY_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_CONTROLLO_ERROR"] = "BANCOMAT_PAY_MODIFICA_IBAN_CONTROLO_JIFFY_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_START"] = "BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_ERROR"] = "BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_START"] = "BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_SUCCESS"] = "BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_SUCCESS";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_ERROR"] = "BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_ERROR";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_STEP"] = "BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_STEP";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_SELECTED_LISTA_SERVIZI_OPERATIVI"] = "BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_SELECTED_LISTA_SERVIZI_OPERATIVI";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_DEFAULT_INVIO"] = "BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_DEFAULT_INVIO";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_SELECTED_INVIO"] = "BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_SELECTED_INVIO";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_SOGLIE_START"] = "BANCOMAT_PAY_GET_SOGLIE_START";
  EReduxBancomatPayActionTypes["BANCOMAT_PAY_GET_SOGLIE_SUCCESS"] = "BANCOMAT_PAY_GET_SOGLIE_SUCCESS";
})(EReduxBancomatPayActionTypes = exports.EReduxBancomatPayActionTypes || (exports.EReduxBancomatPayActionTypes = {}));