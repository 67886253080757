"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxZainettoFiscaleActionTypes = void 0;
;
;
;
var EReduxZainettoFiscaleActionTypes;
(function (EReduxZainettoFiscaleActionTypes) {
  EReduxZainettoFiscaleActionTypes["ZAINETTO_FISCALE_GETPOSIZIONE_TITOLI_START"] = "ZAINETTO_FISCALE_GETPOSIZIONE_TITOLI_START";
  EReduxZainettoFiscaleActionTypes["ZAINETTO_FISCALE_GETPOSIZIONE_TITOLI_SUCCESS"] = "ZAINETTO_FISCALE_GETPOSIZIONE_TITOLI_SUCCESS";
})(EReduxZainettoFiscaleActionTypes = exports.EReduxZainettoFiscaleActionTypes || (exports.EReduxZainettoFiscaleActionTypes = {}));
;