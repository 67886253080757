"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.I18nControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class I18nControllerApi extends runtime.BaseAPI {
  /**
   * Restituisce i messaggi
   */
  messaggiWithHashRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.lingua === null || requestParameters.lingua === undefined) {
        throw new runtime.RequiredError('lingua', 'Required parameter requestParameters.lingua was null or undefined when calling messaggiWithHash.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/messaggi/{lingua}".replace("{".concat("lingua", "}"), encodeURIComponent(String(requestParameters.lingua))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.MessaggiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce i messaggi
   */
  messaggiWithHash(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.messaggiWithHashRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   */
  widgetGenesysRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/messaggi/genesys/widget",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   */
  widgetGenesys() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.widgetGenesysRaw();
      return yield response.value();
    });
  }
}
exports.I18nControllerApi = I18nControllerApi;