"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuotazioniFiltriInDtoToJSON = exports.QuotazioniFiltriInDtoFromJSONTyped = exports.QuotazioniFiltriInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function QuotazioniFiltriInDtoFromJSON(json) {
  return QuotazioniFiltriInDtoFromJSONTyped(json, false);
}
exports.QuotazioniFiltriInDtoFromJSON = QuotazioniFiltriInDtoFromJSON;
function QuotazioniFiltriInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mercato': json['mercato'],
    'cerca': !runtime_1.exists(json, 'cerca') ? undefined : json['cerca'],
    'dimPagina': !runtime_1.exists(json, 'dimPagina') ? undefined : json['dimPagina'],
    'numPagina': !runtime_1.exists(json, 'numPagina') ? undefined : json['numPagina'],
    'sortItem': !runtime_1.exists(json, 'sortItem') ? undefined : json['sortItem'],
    'sortOrder': !runtime_1.exists(json, 'sortOrder') ? undefined : json['sortOrder'],
    'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset']
  };
}
exports.QuotazioniFiltriInDtoFromJSONTyped = QuotazioniFiltriInDtoFromJSONTyped;
function QuotazioniFiltriInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mercato': value.mercato,
    'cerca': value.cerca,
    'dimPagina': value.dimPagina,
    'numPagina': value.numPagina,
    'sortItem': value.sortItem,
    'sortOrder': value.sortOrder,
    'offset': value.offset
  };
}
exports.QuotazioniFiltriInDtoToJSON = QuotazioniFiltriInDtoToJSON;