"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitoliFondiDtoToJSON = exports.TitoliFondiDtoFromJSONTyped = exports.TitoliFondiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TitoliFondiDtoFromJSON(json) {
  return TitoliFondiDtoFromJSONTyped(json, false);
}
exports.TitoliFondiDtoFromJSON = TitoliFondiDtoFromJSON;
function TitoliFondiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'dataElaborazione': !runtime_1.exists(json, 'dataElaborazione') ? undefined : new Date(json['dataElaborazione']),
    'saldoTotale': !runtime_1.exists(json, 'saldoTotale') ? undefined : json['saldoTotale'],
    'titoliFondi': !runtime_1.exists(json, 'titoliFondi') ? undefined : json['titoliFondi'].map(_1.TitoliFondiElementoDtoFromJSON)
  };
}
exports.TitoliFondiDtoFromJSONTyped = TitoliFondiDtoFromJSONTyped;
function TitoliFondiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'dataElaborazione': value.dataElaborazione === undefined ? undefined : value.dataElaborazione.toISOString(),
    'saldoTotale': value.saldoTotale,
    'titoliFondi': value.titoliFondi === undefined ? undefined : value.titoliFondi.map(_1.TitoliFondiElementoDtoToJSON)
  };
}
exports.TitoliFondiDtoToJSON = TitoliFondiDtoToJSON;