import BankIcon from "component/Icon/icon.component";
import TourContestuale from "component/TourContestuale/TourContestuale";
import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode
  isVisible: boolean
  setVisible?: (v: boolean) => void
  hideTour?: boolean
}

const RightbarShoulder = (props: Props) => {

  const {
    children,
    isVisible,
    setVisible,
    hideTour
  } = props;

  return (
    <>

      <div
        className={`spalla-destra-background ${isVisible ? "" : "spalla-destra-chiusa"}`}
        onClick={() => setVisible && setVisible(false)}
      ></div>

      <div className={`spalla-destra ${isVisible ? "" : "spalla-destra-chiusa"}`}>

        <div className="spalla-destra-content">

          {children}

          {!hideTour &&
            <TourContestuale />
          }

        </div>

        <div className="chiudi-spalla-destra">
          <BankIcon
            title={"navigazione_chiudi"}
            onClick={() => setVisible && setVisible(false)}
          />
        </div>

      </div>
    </>
  );
};

export default RightbarShoulder;
