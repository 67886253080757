"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRapportiAdeguataVerifica = exports.esportaDettaglioRapportoPDF = exports.resetSelezionaRapportiForm = exports.setRapportoSelezionatoPolizzeBancaAss = exports.setRapportoSelezionatoFinanziamentiInfo = exports.setRapportoSelezionatoTradingInfo = exports.setRapportoSelezionatoTrading = exports.setRapportoSelezionatoPagamenti = exports.setRapportoSelezionatoBonifici = exports.setRapportoSelezionatoDepositirisparmio = exports.setRapportoSelezionatoContoDeposito = exports.setRapportoSelezionatoContoEstero = exports.setRapportoSelezionatoContoItalia = exports.setRapportoSelezionatoDettaglioRapporto = exports.setAliasRapportoSelezionato = exports.aggiornaAliasRapporto = exports.aggiornaRapportiPredefiniti = exports.getElencoRapportiUtente = exports.getElencoRapportiPredefiniti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getElencoRapportiPredefinitiStart() {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_GET_PREDEFINITI_START
  };
}
function getElencoRapportiPredefinitiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_GET_PREDEFINITI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getElencoRapportiPredefiniti = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getElencoRapportiPredefinitiStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).rapportiPredefiniti().then(response => {
        dispatch(getElencoRapportiPredefinitiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getElencoRapportiPredefiniti = getElencoRapportiPredefiniti;
function getElencoRapportiUtenteStart() {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_GET_RAPPORTI_UTENTE_START
  };
}
function getElencoRapportiUtenteSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_GET_RAPPORTI_UTENTE_SUCCESS,
    payload
  };
}
const getElencoRapportiUtente = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getElencoRapportiUtenteStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).rapportiUtente().then(response => {
        dispatch(getElencoRapportiUtenteSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getElencoRapportiUtente = getElencoRapportiUtente;
function aggiornaRapportiPredefinitiStart() {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_AGGIORNA_START
  };
}
function aggiornaRapportiPredefinitiSuccess() {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_AGGIORNA_SUCCESS
  };
}
const aggiornaRapportiPredefiniti = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(aggiornaRapportiPredefinitiStart());
  if (extraArguments.mock) {} else {
    const filtro = (0, __1.setupAggiornaRequest)(getState().elencoRapporti);
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).aggiornaRapportiPredefiniti({
        rapportoPerServizioDto: filtro.rapportiPredefiniti
      }).then(() => {
        dispatch((0, general_actions_1.addSnackbar)('gestioneRapporti.rapportiDefaultAggiornati'));
        dispatch(aggiornaRapportiPredefinitiSuccess());
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.aggiornaRapportiPredefiniti = aggiornaRapportiPredefiniti;
function aggiornaAliasRapportoStart() {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_AGGIORNA_ALIAS_START
  };
}
function aggiornaAliasRapportoSuccess(response) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_AGGIORNA_ALIAS_SUCCESS,
    payload: response
  };
}
const aggiornaAliasRapporto = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(aggiornaAliasRapportoStart());
  if (extraArguments.mock) {} else {
    const rapporto = (0, __1.rapportoSelezionato)(getState().elencoRapporti);
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateAlias({
        rapportoDto: rapporto
      }).then(response => {
        dispatch(aggiornaAliasRapportoSuccess(response));
        dispatch((0, exports.getElencoRapportiUtente)());
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.aggiornaAliasRapporto = aggiornaAliasRapporto;
function setAliasRapportoSelezionato(alias) {
  return {
    type: __1.EReduxActionTypes.SET_ALIAS_RAPPORTO_SELEZIONATO,
    payload: alias
  };
}
exports.setAliasRapportoSelezionato = setAliasRapportoSelezionato;
function setRapportoSelezionatoDettaglioRapporto(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoDettaglioRapporto = setRapportoSelezionatoDettaglioRapporto;
function setRapportoSelezionatoContoItalia(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ITALIA,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoContoItalia = setRapportoSelezionatoContoItalia;
function setRapportoSelezionatoContoEstero(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ESTERO,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoContoEstero = setRapportoSelezionatoContoEstero;
function setRapportoSelezionatoContoDeposito(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoContoDeposito = setRapportoSelezionatoContoDeposito;
function setRapportoSelezionatoDepositirisparmio(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_DEPOSITI_RISPARMIO,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoDepositirisparmio = setRapportoSelezionatoDepositirisparmio;
function setRapportoSelezionatoBonifici(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_BONIFICI,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoBonifici = setRapportoSelezionatoBonifici;
function setRapportoSelezionatoPagamenti(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_PAGAMENTI,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoPagamenti = setRapportoSelezionatoPagamenti;
function setRapportoSelezionatoTrading(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoTrading = setRapportoSelezionatoTrading;
function setRapportoSelezionatoTradingInfo(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING_INFO,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoTradingInfo = setRapportoSelezionatoTradingInfo;
function setRapportoSelezionatoFinanziamentiInfo(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_FINANZIAMENTI_INFO,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoFinanziamentiInfo = setRapportoSelezionatoFinanziamentiInfo;
function setRapportoSelezionatoPolizzeBancaAss(rapporto) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_POLIZZE_BANCA_ASS,
    payload: rapporto
  };
}
exports.setRapportoSelezionatoPolizzeBancaAss = setRapportoSelezionatoPolizzeBancaAss;
function resetSelezionaRapportiForm() {
  return {
    type: __1.EReduxActionTypes.SELEZIONA_RAPPORTI_RESET_FORM
  };
}
exports.resetSelezionaRapportiForm = resetSelezionaRapportiForm;
/* ESPORTA DETTAGLIO PDF - START */
function esportaDettaglioRapportoPDFStart() {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_ESPORTA_DETTAGLIO_PDF_START
  };
}
function esportaDettaglioRapportoPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ELENCO_RAPPORTI_ESPORTA_DETTAGLIO_PDF_SUCCESS,
    payload
  };
}
const esportaDettaglioRapportoPDF = (share, title, message) => async (dispatch, getState, extraArguments) => {
  const state = getState().elencoRapporti;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaDettaglioRapportoPDFStart());
  if (extraArguments.mock) {} else {
    const requestParameters = {
      rapportoDto: state.form.rapportoSelezionato
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportDettaglioRapporto(requestParameters).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'dettaglio_rapporto.pdf', response, share, title, message);
        }
        dispatch(esportaDettaglioRapportoPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaDettaglioRapportoPDF = esportaDettaglioRapportoPDF;
/* ESPORTA DETTAGLIO PDF - END */
function getRapportiAdeguataVerificaStart() {
  return {
    type: __1.EReduxActionTypes.RAPPORTI_GET_RAPPORTI_ADEGUATA_VERIFICA_START
  };
}
function getRapportiAdeguataVerificaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RAPPORTI_GET_RAPPORTI_ADEGUATA_VERIFICA_SUCCESS,
    payload
  };
}
const getRapportiAdeguataVerifica = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRapportiAdeguataVerificaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/adeguata_verifica_rapporti.json')).then(parametri => {
      dispatch(getRapportiAdeguataVerificaSuccess((0, api_rest_1.AdeguataVerificaRapportiDtoFromJSON)(parametri)));
    });
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).rapportiAdeguataVerifica().then(response => {
        dispatch(getRapportiAdeguataVerificaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRapportiAdeguataVerifica = getRapportiAdeguataVerifica;