"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isModificaContattoRubrica = exports.riepilogoAggiungiContatto = exports.buildContatto = exports.formAggiungiContattoValid = exports.formAggiungiContatto = exports.esitoAggiungiContatto = exports.infoDispositivaAggiungiContatto = exports.parametriContatti = exports.contattoSelezionato = exports.filtroRubricaApplicato = exports.filterRubricaValid = exports.filterRubrica = exports.rubricaLight = exports.rubricaContatti = void 0;
const reselect_1 = require("reselect");
const moment = require("moment");
const helpers_1 = require("../helpers");
const rubricaContattiReducer = state => state;
exports.rubricaContatti = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.rubricaContatti;
});
exports.rubricaLight = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.rubricaLight;
});
exports.filterRubrica = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.filter;
});
exports.filterRubricaValid = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  let formValid = false;
  if (!state.filter.contenutoError && !state.filter.tipoBeneficiarioError) {
    formValid = true;
  }
  return formValid;
});
exports.filtroRubricaApplicato = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  let filtroApplicato = false;
  if (state.filter.contenuto || state.filter.tipoBeneficiario) {
    filtroApplicato = true;
  }
  return filtroApplicato;
});
exports.contattoSelezionato = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.contattoSelezionato;
});
exports.parametriContatti = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.parametri;
});
exports.infoDispositivaAggiungiContatto = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.infoDispositivaAggiungiContatto;
});
exports.esitoAggiungiContatto = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.esitoAggiungiContatto;
});
exports.formAggiungiContatto = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.formAggiungiContatto;
});
exports.formAggiungiContattoValid = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  if (state.formAggiungiContatto.aliasError || state.formAggiungiContatto.nomeError || state.formAggiungiContatto.cognomeError || state.formAggiungiContatto.dataDiNascitaError || state.formAggiungiContatto.sessoError || state.formAggiungiContatto.luogoNascitaError || state.formAggiungiContatto.provinciaNascitaError || state.formAggiungiContatto.cfError || state.formAggiungiContatto.pivaError) {
    return false;
  }
  for (let i = 0; i < state.formAggiungiContatto.email.length; i++) {
    if (state.formAggiungiContatto.email[i].recapitoError) {
      return false;
    }
  }
  for (let i = 0; i < state.formAggiungiContatto.iban.length; i++) {
    if (state.formAggiungiContatto.iban[i].recapitoError || state.formAggiungiContatto.iban[i].etichettaError) {
      return false;
    }
  }
  for (let i = 0; i < state.formAggiungiContatto.telefono.length; i++) {
    if (state.formAggiungiContatto.telefono[i].recapitoError || state.formAggiungiContatto.telefono[i].etichettaError) {
      return false;
    }
  }
  for (let i = 0; i < state.formAggiungiContatto.targa.length; i++) {
    if (state.formAggiungiContatto.targa[i].recapitoError || state.formAggiungiContatto.targa[i].etichettaError) {
      return false;
    }
  }
  for (let i = 0; i < state.formAggiungiContatto.altoAdigePass.length; i++) {
    if (state.formAggiungiContatto.altoAdigePass[i].recapitoError || state.formAggiungiContatto.altoAdigePass[i].etichettaError) {
      return false;
    }
  }
  return true;
});
exports.buildContatto = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return {
    id: state.formAggiungiContatto.id,
    alias: state.formAggiungiContatto.alias,
    nome: state.formAggiungiContatto.nome,
    cognome: state.formAggiungiContatto.cognome,
    dataNascita: state.formAggiungiContatto.dataDiNascita ? moment(state.formAggiungiContatto.dataDiNascita).toDate() : undefined,
    sesso: state.formAggiungiContatto.sesso,
    luogoNascita: state.formAggiungiContatto.luogoNascita,
    provinciaNascita: state.formAggiungiContatto.provinciaNascita,
    cf: state.formAggiungiContatto.cf,
    dispensaF24: state.formAggiungiContatto.dispensaF24,
    piva: state.formAggiungiContatto.piva,
    email: (0, helpers_1.buildRecapito)(state.formAggiungiContatto.email, 'email'),
    iban: (0, helpers_1.buildRecapito)(state.formAggiungiContatto.iban, 'iban'),
    telefono: (0, helpers_1.buildRecapito)(state.formAggiungiContatto.telefono, 'telefono'),
    mezziTrasporto: (0, helpers_1.buildRecapito)(state.formAggiungiContatto.targa, 'mezzo'),
    sudtirolPass: (0, helpers_1.buildRecapito)(state.formAggiungiContatto.altoAdigePass, 'pass'),
    tipoContatto: 'P',
    indirizzo: {
      cap: state.formAggiungiContatto.cap,
      comune: state.formAggiungiContatto.comune,
      indirizzo: state.formAggiungiContatto.indirizzo,
      provincia: state.formAggiungiContatto.provincia
    }
  };
});
exports.riepilogoAggiungiContatto = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return state.riepilogoAggiungiContatto;
});
exports.isModificaContattoRubrica = (0, reselect_1.createSelector)(rubricaContattiReducer, state => {
  return !!state.formAggiungiContatto.id;
});