"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ControlloAnatocismoDtoToJSON = exports.ControlloAnatocismoDtoFromJSONTyped = exports.ControlloAnatocismoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ControlloAnatocismoDtoFromJSON(json) {
  return ControlloAnatocismoDtoFromJSONTyped(json, false);
}
exports.ControlloAnatocismoDtoFromJSON = ControlloAnatocismoDtoFromJSON;
function ControlloAnatocismoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idDisposizione': !runtime_1.exists(json, 'idDisposizione') ? undefined : json['idDisposizione'],
    'preferenze': !runtime_1.exists(json, 'preferenze') ? undefined : json['preferenze'].map(_1.AnatocismoDtoFromJSON)
  };
}
exports.ControlloAnatocismoDtoFromJSONTyped = ControlloAnatocismoDtoFromJSONTyped;
function ControlloAnatocismoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idDisposizione': value.idDisposizione,
    'preferenze': value.preferenze === undefined ? undefined : value.preferenze.map(_1.AnatocismoDtoToJSON)
  };
}
exports.ControlloAnatocismoDtoToJSON = ControlloAnatocismoDtoToJSON;