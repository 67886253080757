"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOneClickRicaricaAltoAdigePass = exports.setRicaricaAltoAdigePassSetAlias1Click = exports.setRicaricaAltoAdigePassSetAggiungi1Click = exports.setRicaricaAltoAdigePassIdContattoBeneficiario = exports.setRicaricaAltoAdigePassSetBeneficiarioAffidabile = exports.getRicaricaAltoAdigePassFromBozza = exports.verificaRicaricaAltoAdigePass = exports.recuperaStoricoRicaricheAltoAdigePass = exports.autorizzaRicaricaAltoAdigePass = exports.controlloRicaricaAltoAdigePass = exports.getInfoDispositivaRicaricaAltoAdigePass = exports.getParametriRicaricaAltoAdigePass = exports.resetFormAltoAdigePass = exports.resetRiepilogoAltoAdigePass = exports.setRicaricaAltoAdigePassSetRapportoSelezionato = exports.resetAltoAdigePass = exports.setRicaricaAltoAdigePassSetFormSubmitted = exports.setRicaricaAltoAdigePassSetStep = exports.setRicaricaAltoAdigePassSetTipoOperazione = exports.setRicaricaAltoAdigePassSetTaglioRicarica = exports.setRicaricaAltoAdigePassSetAggiungiBeneficiarioARubrica = exports.setRicaricaAltoAdigePassSetNomeBeneficiario = exports.setRicaricaAltoAdigePassSetNumeroAltoAdigePass = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const altoAdigePass_selectors_1 = require("./altoAdigePass.selectors");
/* set form values - START */
function setRicaricaAltoAdigePassSetNumeroAltoAdigePass(value) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_NUMERO_ALTO_ADIGE_PASS,
    payload: value
  };
}
exports.setRicaricaAltoAdigePassSetNumeroAltoAdigePass = setRicaricaAltoAdigePassSetNumeroAltoAdigePass;
function setRicaricaAltoAdigePassSetNomeBeneficiario(value) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_NOME_BENIFICIARIO,
    payload: value
  };
}
exports.setRicaricaAltoAdigePassSetNomeBeneficiario = setRicaricaAltoAdigePassSetNomeBeneficiario;
function setRicaricaAltoAdigePassSetAggiungiBeneficiarioARubrica(value) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_AGGIUNGI_BENEFICIARIO_RUBRICA,
    payload: value
  };
}
exports.setRicaricaAltoAdigePassSetAggiungiBeneficiarioARubrica = setRicaricaAltoAdigePassSetAggiungiBeneficiarioARubrica;
function setRicaricaAltoAdigePassSetTaglioRicarica(value) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_TAGLIO_RICARICA,
    payload: value
  };
}
exports.setRicaricaAltoAdigePassSetTaglioRicarica = setRicaricaAltoAdigePassSetTaglioRicarica;
function setRicaricaAltoAdigePassSetTipoOperazione(value) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_TIPO_OPERAZIONE,
    payload: value
  };
}
exports.setRicaricaAltoAdigePassSetTipoOperazione = setRicaricaAltoAdigePassSetTipoOperazione;
function setRicaricaAltoAdigePassSetStep(value) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_STEP,
    payload: value
  };
}
exports.setRicaricaAltoAdigePassSetStep = setRicaricaAltoAdigePassSetStep;
function setRicaricaAltoAdigePassSetFormSubmitted(value) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_FORM_SUBMITTED,
    payload: value
  };
}
exports.setRicaricaAltoAdigePassSetFormSubmitted = setRicaricaAltoAdigePassSetFormSubmitted;
function resetAltoAdigePass() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_RESET
  };
}
exports.resetAltoAdigePass = resetAltoAdigePass;
function setRicaricaAltoAdigePassSetRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setRicaricaAltoAdigePassSetRapportoSelezionato = setRicaricaAltoAdigePassSetRapportoSelezionato;
function resetRiepilogoAltoAdigePass() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_RESET_RIEPILOGO
  };
}
exports.resetRiepilogoAltoAdigePass = resetRiepilogoAltoAdigePass;
function resetFormAltoAdigePass() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_RESET_FORM
  };
}
exports.resetFormAltoAdigePass = resetFormAltoAdigePass;
/* set form values - FINE */
/* get parametri - START */
function getParametriAltoAdigePassStart() {
  return {
    type: __1.EReduxActionTypes.ALTO_ADIGE_PASS_GET_PARAMETRI_START
  };
}
function getParametriAltoAdigePassSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ALTO_ADIGE_PASS_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function getParametriPerRipetiRicaricaAltoAdigePassSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_PARAMETRI_PER_RIPETI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    !!sendErrorToMatomo && sendErrorToMatomo('RICARICA_ALTO_ADIGE', "code_error RICARICA_ALTO_ADIGE ".concat(value === null || value === void 0 ? void 0 : value.status, " / ").concat(value === null || value === void 0 ? void 0 : value.message, " / ").concat(value === null || value === void 0 ? void 0 : value.timestamp), 'ESITO_DISPOSITIVA');
  };
}
const getParametriRicaricaAltoAdigePass = ripeti => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriAltoAdigePassStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.SudtirolPassControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriSudtirol().then(response => {
        var _a;
        dispatch(ripeti ? getParametriPerRipetiRicaricaAltoAdigePassSuccess(response) : getParametriAltoAdigePassSuccess(response));
        ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.RICARICA_ALTOADIGE
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriRicaricaAltoAdigePass = getParametriRicaricaAltoAdigePass;
/* get parametri - FINE */
/* get info dispositiva - START */
function getInfoDispositivaRicaricaAltoAdigePassStart() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_START
  };
}
function getInfoDispositivaRicaricaAltoAdigePassSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_SUCCESS,
    payload
  };
}
const getInfoDispositivaRicaricaAltoAdigePass = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoDispositivaRicaricaAltoAdigePassStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const getInfoDispositivaAltoAdigePassRequest = {
      idDispositiva,
      eseguiInit: eseguiInit,
      chiamataUscente: chiamataUscente,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.SudtirolPassControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneSudtirolPass(getInfoDispositivaAltoAdigePassRequest).then(response => {
        dispatch(getInfoDispositivaRicaricaAltoAdigePassSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoDispositivaRicaricaAltoAdigePass = getInfoDispositivaRicaricaAltoAdigePass;
/* get info dispositiva - FINE */
/* controllo - START */
function controlloRicaricaAltoAdigePassStart() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_CONTROLLO_START
  };
}
function controlloRicaricaAltoAdigePassSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_CONTROLLO_SUCCESS,
    payload
  };
}
const controlloRicaricaAltoAdigePass = () => async (dispatch, getState, extraArguments) => {
  const altoAdigePass = getState().altoAdigePass;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(controlloRicaricaAltoAdigePassStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const controlloRicaricaAltoAdigePassRequest = {
      ricaricaSudtirolPassDto: {
        ...(0, altoAdigePass_selectors_1.buildRicaricaAltoAdigePass)(altoAdigePass)
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.SudtirolPassControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloRicarica(controlloRicaricaAltoAdigePassRequest).then(response => {
        dispatch(controlloRicaricaAltoAdigePassSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.controlloRicaricaAltoAdigePass = controlloRicaricaAltoAdigePass;
/* controllo - FINE */
/* autorizza - START */
function autorizzaRicaricaAltoAdigePassStart() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_START
  };
}
function autorizzaRicaricaAltoAdigePassSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_SUCCESS,
    payload
  };
}
const autorizzaRicaricaAltoAdigePass = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaRicaricaAltoAdigePassStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const autorizzaRicaricaAltoAdigePassRequest = {
      idDispositiva,
      credenzialeDispositivaDto: credenziale
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.SudtirolPassControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaBonificoSudtirolPass(autorizzaRicaricaAltoAdigePassRequest).then(response => {
        dispatch(autorizzaRicaricaAltoAdigePassSuccess(response));
        extraArguments.sendEventToMatomo('RICARICA_ALTO_ADIGE', "esito dispositiva RICARICA_ALTO_ADIGE ".concat(response.stato), 'ESITO_DISPOSITIVA');
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaRicaricaAltoAdigePass = autorizzaRicaricaAltoAdigePass;
/* autorizza - FINE */
/* recupera storico ricariche - START */
function recuperaStoricoRicaricheAltoAdigePassStart() {
  return {
    type: __1.EReduxActionTypes.ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_START
  };
}
function recuperaStoricoRicaricheAltoAdigePassSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_SUCCESS,
    payload
  };
}
const recuperaStoricoRicaricheAltoAdigePass = altoAdigePassRicaricheFiltriInDto => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(recuperaStoricoRicaricheAltoAdigePassStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const requestParameters = {
      sudtirolPassRicaricheFiltriInDto: altoAdigePassRicaricheFiltriInDto
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.SudtirolPassControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaStoricoRicariche(requestParameters).then(response => {
        dispatch(recuperaStoricoRicaricheAltoAdigePassSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.recuperaStoricoRicaricheAltoAdigePass = recuperaStoricoRicaricheAltoAdigePass;
/* recupera storico ricariche - FINE */
/* verifica ricarica - START */
function ricaricaAltoAdigePassVerificaStart() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_VERIFICA_START
  };
}
function ricaricaAltoAdigePassVerificaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_VERIFICA_SUCCESS,
    payload
  };
}
function ricaricaAltoAdigePassVerificaError() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_VERIFICA_ERROR
  };
}
const verificaRicaricaAltoAdigePass = () => async (dispatch, getState, extraArguments) => {
  const altoAdigePass = getState().altoAdigePass;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(ricaricaAltoAdigePassVerificaStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    const verificaRicaricaRequest = {
      verificaRicaricaRequestDto: {
        ...(0, altoAdigePass_selectors_1.buildVerificaNumeroAltoAdigePass)(altoAdigePass)
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.SudtirolPassControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).verificaRicarica(verificaRicaricaRequest).then(response => {
        dispatch(ricaricaAltoAdigePassVerificaSuccess(response));
      }).catch(async error => {
        dispatch(ricaricaAltoAdigePassVerificaError());
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.verificaRicaricaAltoAdigePass = verificaRicaricaAltoAdigePass;
/* verifica ricarica - FINE */
function getRicaricaAltoAdigePassFromBozza(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_RICARICA_ALTO_ADIGE_PASS_FROM_BOZZA,
    payload
  };
}
exports.getRicaricaAltoAdigePassFromBozza = getRicaricaAltoAdigePassFromBozza;
function setRicaricaAltoAdigePassSetBeneficiarioAffidabile(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_BENEFICIARIO_AFFIDABILE,
    payload
  };
}
exports.setRicaricaAltoAdigePassSetBeneficiarioAffidabile = setRicaricaAltoAdigePassSetBeneficiarioAffidabile;
function setRicaricaAltoAdigePassIdContattoBeneficiario(id) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_ID_CONTATTO_BENEFICIARIO,
    payload: id
  };
}
exports.setRicaricaAltoAdigePassIdContattoBeneficiario = setRicaricaAltoAdigePassIdContattoBeneficiario;
function setRicaricaAltoAdigePassSetAggiungi1Click(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_1CLICK,
    payload
  };
}
exports.setRicaricaAltoAdigePassSetAggiungi1Click = setRicaricaAltoAdigePassSetAggiungi1Click;
function setRicaricaAltoAdigePassSetAlias1Click(txt) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_ALIAS_1CLICK,
    payload: txt
  };
}
exports.setRicaricaAltoAdigePassSetAlias1Click = setRicaricaAltoAdigePassSetAlias1Click;
/* get oneclick Ricarica Alto adige pass - START */
function getOneClickRicaricaAltoAdigePassStart() {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_ONECLICK_START
  };
}
function getOneclickRicaricaAltoAdigePassSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_ONECLICK_SUCCESS,
    payload
  };
}
const getOneClickRicaricaAltoAdigePass = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getOneClickRicaricaAltoAdigePassStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/ricarica_celulare_oneclick.json')).then(parametri => {
      dispatch(getOneclickRicaricaAltoAdigePassSuccess((0, api_rest_1.OperazioniOneClickDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const request = {
      operazioniOneClickInputDto: {
        tipoOperazione: "SUDTIROLPASS"
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.SudtirolPassControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioniOneClickRicaricaSudtirolPass(request).then(response => {
        dispatch(getOneclickRicaricaAltoAdigePassSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getOneClickRicaricaAltoAdigePass = getOneClickRicaricaAltoAdigePass;
/* get oneclick Ricarica alto adige - FINE */