"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formCercaTitoliValid = exports.parametriTitoli = exports.titoli = exports.formTitoli = exports.buildFilterCercaTitoli = void 0;
const reselect_1 = require("reselect");
const cercaTitoliReducer = state => state;
exports.buildFilterCercaTitoli = (0, reselect_1.createSelector)(cercaTitoliReducer, state => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j;
  const filter = {
    codiceRapporto: (_a = state.form.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id,
    codiceIsin: (_c = (_b = state.form) === null || _b === void 0 ? void 0 : _b.isin) !== null && _c !== void 0 ? _c : '',
    descrizione: (_f = (_e = (_d = state.form) === null || _d === void 0 ? void 0 : _d.descrizione) === null || _e === void 0 ? void 0 : _e.toUpperCase()) !== null && _f !== void 0 ? _f : '',
    mercatoFiltro: (_h = (_g = state.form) === null || _g === void 0 ? void 0 : _g.mercato) !== null && _h !== void 0 ? _h : '',
    raggruppamentoTitolo: (_j = state.form.tipo) !== null && _j !== void 0 ? _j : 0,
    flagMercatoChiuso: false,
    onlyPreferred: state.form.onlyPreferred
  };
  return filter;
});
exports.formTitoli = (0, reselect_1.createSelector)(cercaTitoliReducer, state => {
  return state.form;
});
exports.titoli = (0, reselect_1.createSelector)(cercaTitoliReducer, state => {
  return state.titoli;
});
exports.parametriTitoli = (0, reselect_1.createSelector)(cercaTitoliReducer, state => {
  return state.parametri;
});
exports.formCercaTitoliValid = (0, reselect_1.createSelector)(cercaTitoliReducer, state => {
  let valid = false;
  if ((state.form.isin !== '' || state.form.descrizione !== '') && state.form.errorIsin === '' && state.form.descrizioneError === '') {
    valid = true;
  }
  return valid;
});