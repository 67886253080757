"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.posizioneTitoli = void 0;
const reselect_1 = require("reselect");
const zainettoFiscaleReducer = state => state;
exports.posizioneTitoli = (0, reselect_1.createSelector)(zainettoFiscaleReducer, state => {
  return state.posizioneTitoli;
});