"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.firmaDigitaleReducer = void 0;
const __1 = require("..");
const firma_digitale_types_1 = require("./firma-digitale.types");
const initialState = {
  pushUrl: undefined,
  sessioniDiFirma: undefined,
  urlJob: undefined,
  form: {
    showModal: false,
    jobSelezionato: undefined
  }
};
const firmaDigitaleReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.RECUPERA_SESSIONI_DI_FIRMA_SUCCESS:
      return {
        ...state,
        sessioniDiFirma: action.payload
      };
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_SET_SHOW_MODAL:
      return {
        ...state,
        form: {
          ...state.form,
          showModal: action.payload
        }
      };
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_SET_JOB:
      return {
        ...state,
        form: {
          ...state.form,
          jobSelezionato: action.payload
        }
      };
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_GET_URL_SUCCESS:
      return {
        ...state,
        urlJob: action.payload
      };
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_RESET_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          jobSelezionato: undefined
        }
      };
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_SET_PUSH_URL:
      return {
        ...state,
        pushUrl: action.payload
      };
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_RESET_PUSH_URL:
      return {
        ...state,
        pushUrl: initialState.pushUrl
      };
    case firma_digitale_types_1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_RESET_URL_JOB:
      return {
        ...state,
        urlJob: initialState.urlJob,
        form: {
          ...state.form,
          showModal: false,
          jobSelezionato: initialState.form.jobSelezionato
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.firmaDigitaleReducer = firmaDigitaleReducer;
exports.default = exports.firmaDigitaleReducer;