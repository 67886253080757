"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExportMovimentiInDtoToJSON = exports.ExportMovimentiInDtoFromJSONTyped = exports.ExportMovimentiInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ExportMovimentiInDtoFromJSON(json) {
  return ExportMovimentiInDtoFromJSONTyped(json, false);
}
exports.ExportMovimentiInDtoFromJSON = ExportMovimentiInDtoFromJSON;
function ExportMovimentiInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'movimento': _1.MovimentoDtoFromJSON(json['movimento']),
    'cartaconto': !runtime_1.exists(json, 'cartaconto') ? undefined : json['cartaconto'],
    'contoDeposito': !runtime_1.exists(json, 'contoDeposito') ? undefined : json['contoDeposito'],
    'criteriPsd2': !runtime_1.exists(json, 'criteriPsd2') ? undefined : _1.CriteriMovimentiPsd2InFromJSON(json['criteriPsd2'])
  };
}
exports.ExportMovimentiInDtoFromJSONTyped = ExportMovimentiInDtoFromJSONTyped;
function ExportMovimentiInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'movimento': _1.MovimentoDtoToJSON(value.movimento),
    'cartaconto': value.cartaconto,
    'contoDeposito': value.contoDeposito,
    'criteriPsd2': _1.CriteriMovimentiPsd2InToJSON(value.criteriPsd2)
  };
}
exports.ExportMovimentiInDtoToJSON = ExportMovimentiInDtoToJSON;