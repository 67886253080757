"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModificaAdeguataVerificaRequestDtoToJSON = exports.ModificaAdeguataVerificaRequestDtoFromJSONTyped = exports.ModificaAdeguataVerificaRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ModificaAdeguataVerificaRequestDtoFromJSON(json) {
  return ModificaAdeguataVerificaRequestDtoFromJSONTyped(json, false);
}
exports.ModificaAdeguataVerificaRequestDtoFromJSON = ModificaAdeguataVerificaRequestDtoFromJSON;
function ModificaAdeguataVerificaRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'fasciaRischioIniziale': !runtime_1.exists(json, 'fasciaRischioIniziale') ? undefined : json['fasciaRischioIniziale'],
    'modificaDomicilio': !runtime_1.exists(json, 'modificaDomicilio') ? undefined : json['modificaDomicilio'],
    'modificaDocumento': !runtime_1.exists(json, 'modificaDocumento') ? undefined : json['modificaDocumento'],
    'indirizzoResidenza': !runtime_1.exists(json, 'indirizzoResidenza') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['indirizzoResidenza']),
    'indirizzoDomicilio': !runtime_1.exists(json, 'indirizzoDomicilio') ? undefined : _1.DomiciliazioneAmministrativaDtoFromJSON(json['indirizzoDomicilio']),
    'rapportoADVDto': !runtime_1.exists(json, 'rapportoADVDto') ? undefined : json['rapportoADVDto'].map(_1.RapportoADVDtoFromJSON),
    'altreCarichePubbliche': !runtime_1.exists(json, 'altreCarichePubbliche') ? undefined : json['altreCarichePubbliche'],
    'professione': !runtime_1.exists(json, 'professione') ? undefined : json['professione'],
    'ultimaProfessione': !runtime_1.exists(json, 'ultimaProfessione') ? undefined : json['ultimaProfessione'],
    'tae': !runtime_1.exists(json, 'tae') ? undefined : json['tae'],
    'cae': !runtime_1.exists(json, 'cae') ? undefined : json['cae'],
    'esercizioDati': !runtime_1.exists(json, 'esercizioDati') ? undefined : json['esercizioDati'],
    'patrimonio': !runtime_1.exists(json, 'patrimonio') ? undefined : json['patrimonio'],
    'redditoAnnuo': !runtime_1.exists(json, 'redditoAnnuo') ? undefined : json['redditoAnnuo'],
    'originePatrimonio': !runtime_1.exists(json, 'originePatrimonio') ? undefined : json['originePatrimonio'],
    'origineReddito': !runtime_1.exists(json, 'origineReddito') ? undefined : json['origineReddito'],
    'statoAttivitaPrevalente': !runtime_1.exists(json, 'statoAttivitaPrevalente') ? undefined : json['statoAttivitaPrevalente'],
    'provinciaAttivitaPrevalente': !runtime_1.exists(json, 'provinciaAttivitaPrevalente') ? undefined : json['provinciaAttivitaPrevalente'],
    'noteAttivita': !runtime_1.exists(json, 'noteAttivita') ? undefined : json['noteAttivita'],
    'primoPaeseRelazioniCliente': !runtime_1.exists(json, 'primoPaeseRelazioniCliente') ? undefined : json['primoPaeseRelazioniCliente'],
    'secondoPaeseRelazioniCliente': !runtime_1.exists(json, 'secondoPaeseRelazioniCliente') ? undefined : json['secondoPaeseRelazioniCliente'],
    'terzoPaeseRelazioniClente': !runtime_1.exists(json, 'terzoPaeseRelazioniClente') ? undefined : json['terzoPaeseRelazioniClente'],
    'appaltiFinanziamentiPubblici': !runtime_1.exists(json, 'appaltiFinanziamentiPubblici') ? undefined : json['appaltiFinanziamentiPubblici']
  };
}
exports.ModificaAdeguataVerificaRequestDtoFromJSONTyped = ModificaAdeguataVerificaRequestDtoFromJSONTyped;
function ModificaAdeguataVerificaRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'fasciaRischioIniziale': value.fasciaRischioIniziale,
    'modificaDomicilio': value.modificaDomicilio,
    'modificaDocumento': value.modificaDocumento,
    'indirizzoResidenza': _1.IndirizzoDatiAnagraficiDtoToJSON(value.indirizzoResidenza),
    'indirizzoDomicilio': _1.DomiciliazioneAmministrativaDtoToJSON(value.indirizzoDomicilio),
    'rapportoADVDto': value.rapportoADVDto === undefined ? undefined : value.rapportoADVDto.map(_1.RapportoADVDtoToJSON),
    'altreCarichePubbliche': value.altreCarichePubbliche,
    'professione': value.professione,
    'ultimaProfessione': value.ultimaProfessione,
    'tae': value.tae,
    'cae': value.cae,
    'esercizioDati': value.esercizioDati,
    'patrimonio': value.patrimonio,
    'redditoAnnuo': value.redditoAnnuo,
    'originePatrimonio': value.originePatrimonio,
    'origineReddito': value.origineReddito,
    'statoAttivitaPrevalente': value.statoAttivitaPrevalente,
    'provinciaAttivitaPrevalente': value.provinciaAttivitaPrevalente,
    'noteAttivita': value.noteAttivita,
    'primoPaeseRelazioniCliente': value.primoPaeseRelazioniCliente,
    'secondoPaeseRelazioniCliente': value.secondoPaeseRelazioniCliente,
    'terzoPaeseRelazioniClente': value.terzoPaeseRelazioniClente,
    'appaltiFinanziamentiPubblici': value.appaltiFinanziamentiPubblici
  };
}
exports.ModificaAdeguataVerificaRequestDtoToJSON = ModificaAdeguataVerificaRequestDtoToJSON;