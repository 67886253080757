"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchBicResponseToJSON = exports.SearchBicResponseFromJSONTyped = exports.SearchBicResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SearchBicResponseFromJSON(json) {
  return SearchBicResponseFromJSONTyped(json, false);
}
exports.SearchBicResponseFromJSON = SearchBicResponseFromJSON;
function SearchBicResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'paginazione': !runtime_1.exists(json, 'paginazione') ? undefined : _1.PaginazioneFromJSON(json['paginazione']),
    'bicList': !runtime_1.exists(json, 'bicList') ? undefined : json['bicList'].map(_1.BicDtoFromJSON)
  };
}
exports.SearchBicResponseFromJSONTyped = SearchBicResponseFromJSONTyped;
function SearchBicResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'paginazione': _1.PaginazioneToJSON(value.paginazione),
    'bicList': value.bicList === undefined ? undefined : value.bicList.map(_1.BicDtoToJSON)
  };
}
exports.SearchBicResponseToJSON = SearchBicResponseToJSON;