"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BeneficiarioDtoTipoCoordinateEnum = exports.BeneficiarioDtoToJSON = exports.BeneficiarioDtoFromJSONTyped = exports.BeneficiarioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BeneficiarioDtoFromJSON(json) {
  return BeneficiarioDtoFromJSONTyped(json, false);
}
exports.BeneficiarioDtoFromJSON = BeneficiarioDtoFromJSON;
function BeneficiarioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoCoordinate': !runtime_1.exists(json, 'tipoCoordinate') ? undefined : json['tipoCoordinate'],
    'codiceIban': !runtime_1.exists(json, 'codiceIban') ? undefined : json['codiceIban'],
    'codiceAbi': !runtime_1.exists(json, 'codiceAbi') ? undefined : json['codiceAbi'],
    'codiceCab': !runtime_1.exists(json, 'codiceCab') ? undefined : json['codiceCab'],
    'codiceBic': !runtime_1.exists(json, 'codiceBic') ? undefined : json['codiceBic'],
    'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
    'anagrafica': !runtime_1.exists(json, 'anagrafica') ? undefined : json['anagrafica'],
    'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : _1.IndirizzoDtoFromJSON(json['indirizzo']),
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
    'tipoCarta': !runtime_1.exists(json, 'tipoCarta') ? undefined : json['tipoCarta'],
    'num1': !runtime_1.exists(json, 'num1') ? undefined : json['num1'],
    'num2': !runtime_1.exists(json, 'num2') ? undefined : json['num2'],
    'anagraficaOri': !runtime_1.exists(json, 'anagraficaOri') ? undefined : json['anagraficaOri'],
    'tipoBeneficiario': !runtime_1.exists(json, 'tipoBeneficiario') ? undefined : json['tipoBeneficiario'],
    'contoCoge': !runtime_1.exists(json, 'contoCoge') ? undefined : json['contoCoge']
  };
}
exports.BeneficiarioDtoFromJSONTyped = BeneficiarioDtoFromJSONTyped;
function BeneficiarioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoCoordinate': value.tipoCoordinate,
    'codiceIban': value.codiceIban,
    'codiceAbi': value.codiceAbi,
    'codiceCab': value.codiceCab,
    'codiceBic': value.codiceBic,
    'ndg': value.ndg,
    'anagrafica': value.anagrafica,
    'indirizzo': _1.IndirizzoDtoToJSON(value.indirizzo),
    'causale': value.causale,
    'contoAddebito': value.contoAddebito,
    'tipoCarta': value.tipoCarta,
    'num1': value.num1,
    'num2': value.num2,
    'anagraficaOri': value.anagraficaOri,
    'tipoBeneficiario': value.tipoBeneficiario,
    'contoCoge': value.contoCoge
  };
}
exports.BeneficiarioDtoToJSON = BeneficiarioDtoToJSON;
/**
* @export
* @enum {string}
*/
var BeneficiarioDtoTipoCoordinateEnum;
(function (BeneficiarioDtoTipoCoordinateEnum) {
  BeneficiarioDtoTipoCoordinateEnum["I"] = "I";
  BeneficiarioDtoTipoCoordinateEnum["R"] = "R";
})(BeneficiarioDtoTipoCoordinateEnum = exports.BeneficiarioDtoTipoCoordinateEnum || (exports.BeneficiarioDtoTipoCoordinateEnum = {}));