"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElencoBilancioDtoToJSON = exports.ElencoBilancioDtoFromJSONTyped = exports.ElencoBilancioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ElencoBilancioDtoFromJSON(json) {
  return ElencoBilancioDtoFromJSONTyped(json, false);
}
exports.ElencoBilancioDtoFromJSON = ElencoBilancioDtoFromJSON;
function ElencoBilancioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'bilancio': !runtime_1.exists(json, 'bilancio') ? undefined : _1.BilancioDtoFromJSON(json['bilancio'])
  };
}
exports.ElencoBilancioDtoFromJSONTyped = ElencoBilancioDtoFromJSONTyped;
function ElencoBilancioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'bilancio': _1.BilancioDtoToJSON(value.bilancio)
  };
}
exports.ElencoBilancioDtoToJSON = ElencoBilancioDtoToJSON;