"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxAdeguataVerificaActionTypes = exports.FormADVScopiRapportiFromJsonTyped = exports.FormDatiDocumentoIdentitaADVDTOFromJSONTyped = exports.FormDatiRedditoPatrimonialiADVDtoFromJSONTyped = exports.FormDatiResidenzaADVDtoFromJSONTyped = exports.FormADVDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormADVDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'scopoPrevalenteRapporto': !(0, helpers_1.exists)(json, 'scopoPrevalenteRapporto') ? undefined : json.scopoPrevalenteRapporto,
    'disabeldFirma': !(0, helpers_1.exists)(json, 'disabeldFirma') ? false : json.disabeldFirma,
    'origineDestinazioneFondi': !(0, helpers_1.exists)(json, 'origineDestinazioneFondi') ? false : json.origineDestinazioneFondi
  };
}
exports.FormADVDtoFromJSONTyped = FormADVDtoFromJSONTyped;
function FormDatiResidenzaADVDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'provinciaResidenza': !(0, helpers_1.exists)(json, 'provinciaResidenza') ? '' : json.provinciaResidenza,
    'provinciaResidenzaDirty': !(0, helpers_1.exists)(json, 'provinciaResidenzaDirty') ? false : json.provinciaResidenzaDirty,
    'provinciaResidenzaError': !(0, helpers_1.exists)(json, 'provinciaResidenzaError') ? false : json.provinciaResidenzaError,
    'comuneResidenza': !(0, helpers_1.exists)(json, 'comuneResidenza') ? '' : json.comuneResidenza,
    'comuneResidenzaDirty': !(0, helpers_1.exists)(json, 'comuneResidenzaDirty') ? false : json.comuneResidenza,
    'comuneResidenzaError': !(0, helpers_1.exists)(json, 'comuneResidenzaError') ? '' : json.comuneResidenzaError,
    'localitaResidenza': !(0, helpers_1.exists)(json, 'localitaResidenza') ? '' : json.localitaResidenza,
    'localitaResidenzaDirty': !(0, helpers_1.exists)(json, 'localitaResidenzaDirty') ? false : json.localitaResidenzaDirty,
    'localitaResidenzaError': !(0, helpers_1.exists)(json, 'localitaResidenzaError') ? '' : json.localitaResidenzaError,
    'tipoViaResidenza': !(0, helpers_1.exists)(json, 'tipoViaResidenza') ? '' : json.tipoViaResidenza,
    'tipoViaResidenzaDirty': !(0, helpers_1.exists)(json, 'tipoViaResidenzaDirty') ? false : json.tipoViaResidenzaDirty,
    'tipoViaResidenzaError': !(0, helpers_1.exists)(json, 'tipoViaResidenzaError') ? '' : json.tipoViaResidenzaError,
    'indirizzoResidenza': !(0, helpers_1.exists)(json, 'indirizzoResidenza') ? '' : json.indirizzoResidenza,
    'indirizzoResidenzaDirty': !(0, helpers_1.exists)(json, 'indirizzoResidenzaDirty') ? false : json.indirizzoResidenzaDirty,
    'indirizzoResidenzaError': !(0, helpers_1.exists)(json, 'indirizzoResidenzaError') ? '' : json.indirizzoResidenzaError,
    'numeroCivicoResidenza': !(0, helpers_1.exists)(json, 'numeroCivicoResidenza') ? '' : json.numeroCivicoResidenza,
    'numeroCivicoResidenzaDirty': !(0, helpers_1.exists)(json, 'numeroCivicoResidenzaDirty') ? false : json.numeroCivicoResidenzaDirty,
    'numeroCivicoResidenzaError': !(0, helpers_1.exists)(json, 'numeroCivicoResidenzaError') ? '' : json.numeroCivicoResidenzaError,
    'capResidenza': !(0, helpers_1.exists)(json, 'capResidenza') ? '' : json.capResidenza,
    'capResidenzaDirty': !(0, helpers_1.exists)(json, 'capResidenzaDirty') ? false : json.capResidenzaDirty,
    'capResidenzaError': !(0, helpers_1.exists)(json, 'capResidenzaError') ? '' : json.capResidenzaError,
    'provinciaDomicilio': !(0, helpers_1.exists)(json, 'provinciaDomicilio') ? '' : json.provinciaDomicilio,
    'provinciaDomicilioDirty': !(0, helpers_1.exists)(json, 'provinciaDomicilioDirty') ? false : json.provinciaDomicilioDirty,
    'provinciaDomicilioError': !(0, helpers_1.exists)(json, 'provinciaDomicilioError') ? '' : json.provinciaDomicilioError,
    'comuneDomicilio': !(0, helpers_1.exists)(json, 'comuneDomicilio') ? '' : json.comuneDomicilio,
    'comuneDomicilioDirty': !(0, helpers_1.exists)(json, 'comuneDomicilioDirty') ? false : json.comuneDomicilioDirty,
    'comuneDomicilioError': !(0, helpers_1.exists)(json, 'comuneDomicilioError') ? '' : json.comuneDomicilioError,
    'localitaDomicilio': !(0, helpers_1.exists)(json, 'localitaDomicilio') ? '' : json.localitaDomicilio,
    'localitaDomicilioDirty': !(0, helpers_1.exists)(json, 'localitaDomicilioDirty') ? false : json.localitaDomicilioDirty,
    'localitaDomicilioError': !(0, helpers_1.exists)(json, 'localitaDomicilioError') ? '' : json.localitaDomicilioError,
    'tipoViaDomicilio': !(0, helpers_1.exists)(json, 'tipoViaDomicilio') ? '' : json.tipoViaDomicilio,
    'tipoViaDomicilioDirty': !(0, helpers_1.exists)(json, 'tipoViaDomicilioDirty') ? false : json.tipoViaDomicilioDirty,
    'tipoViaDomicilioError': !(0, helpers_1.exists)(json, 'tipoViaDomicilioError') ? '' : json.tipoViaDomicilioError,
    'indirizzoDomicilio': !(0, helpers_1.exists)(json, 'indirizzoDomicilio') ? '' : json.indirizzoDomicilio,
    'indirizzoDomicilioDirty': !(0, helpers_1.exists)(json, 'indirizzoDomicilioDirty') ? false : json.indirizzoDomicilioDirty,
    'indirizzoDomicilioError': !(0, helpers_1.exists)(json, 'indirizzoDomicilioError') ? '' : json.indirizzoDomicilioError,
    'numeroCivicoDomicilio': !(0, helpers_1.exists)(json, 'numeroCivicoDomicilio') ? '' : json.numeroCivicoDomicilio,
    'numeroCivicoDomicilioDirty': !(0, helpers_1.exists)(json, 'numeroCivicoDomicilioDirty') ? false : json.numeroCivicoDomicilioDirty,
    'numeroCivicoDomicilioError': !(0, helpers_1.exists)(json, 'numeroCivicoDomicilioError') ? '' : json.numeroCivicoDomicilioError,
    'capDomicilio': !(0, helpers_1.exists)(json, 'capDomicilio') ? '' : json.capDomicilio,
    'capDomicilioDirty': !(0, helpers_1.exists)(json, 'capDomicilioDirty') ? false : json.capDomicilioDirty,
    'capDomicilioError': !(0, helpers_1.exists)(json, 'capDomicilioError') ? '' : json.capDomicilioError,
    'inserisciDomicilio': !(0, helpers_1.exists)(json, 'inserisciDomicilio') ? false : json.inserisciDomicilio,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'modificaDatiDomicilio': !(0, helpers_1.exists)(json, 'modificaDatiDomicilio') ? false : json.modificaDatiDomicilio
  };
}
exports.FormDatiResidenzaADVDtoFromJSONTyped = FormDatiResidenzaADVDtoFromJSONTyped;
function FormDatiRedditoPatrimonialiADVDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'professione': !(0, helpers_1.exists)(json, 'professione') ? undefined : json.professione,
    'professioneDirty': !(0, helpers_1.exists)(json, 'professioneDirty') ? false : json.professioneDirty,
    'professioneError': !(0, helpers_1.exists)(json, 'professioneError') ? '' : json.professioneError,
    'tae': !(0, helpers_1.exists)(json, 'tae') ? '' : json.tae,
    'taeDirty': !(0, helpers_1.exists)(json, 'taeDirty') ? false : json.taeDirty,
    'taeError': !(0, helpers_1.exists)(json, 'taeDirtyError') ? '' : json.taeDirtyError,
    'cae': !(0, helpers_1.exists)(json, 'cae') ? '' : json.cae,
    'caeDirty': !(0, helpers_1.exists)(json, 'caeDirty') ? false : json.caeDirty,
    'caeError': !(0, helpers_1.exists)(json, 'caeDirtyError') ? '' : json.caeError,
    'redditoAnnuo': !(0, helpers_1.exists)(json, 'redditoAnnuo') ? '' : json.redditoAnnuo,
    'redditoAnnuoDirty': !(0, helpers_1.exists)(json, 'redditoAnnuoDirty') ? false : json.redditoAnnuoDirty,
    'redditoAnnuoError': !(0, helpers_1.exists)(json, 'redditoAnnuoError') ? '' : json.redditoAnnuoError,
    'patrimonioTotaleCliente': !(0, helpers_1.exists)(json, 'patrimonioTotaleCliente') ? '' : json.patrimonioTotaleCliente,
    'patrimonioTotaleClienteDirty': !(0, helpers_1.exists)(json, 'patrimonioTotaleClienteDirty') ? false : json.patrimonioTotaleClienteDirty,
    'patrimonioTotaleClienteError': !(0, helpers_1.exists)(json, 'patrimonioTotaleClienteError') ? '' : json.patrimonioTotaleClienteError,
    'statoattecprev': !(0, helpers_1.exists)(json, 'statoattecprev') ? '' : json.statoattecprev,
    'statoattecprevDirty': !(0, helpers_1.exists)(json, 'statoattecprevDirty') ? false : json.statoattecprevDirty,
    'statoattecprevError': !(0, helpers_1.exists)(json, 'statoattecprevError') ? '' : json.statoattecprevError,
    'provattecprev': !(0, helpers_1.exists)(json, 'provattecprev') ? '' : json.provattecprev,
    'provattecprevDirty': !(0, helpers_1.exists)(json, 'provattecprevDirty') ? false : json.provattecprevDirty,
    'provattecprevError': !(0, helpers_1.exists)(json, 'provattecprevError') ? '' : json.provattecprevError,
    'qualificaLavorativa': !(0, helpers_1.exists)(json, 'qualificaLavorativa') ? '' : json.qualificaLavorativa,
    'qualificaLavorativaDirty': !(0, helpers_1.exists)(json, 'qualificaLavorativaDirty') ? false : json.qualificaLavorativaDirty,
    'qualificaLavorativaError': !(0, helpers_1.exists)(json, 'qualificaLavorativaError') ? '' : json.qualificaLavorativaError,
    'notaTipologia': !(0, helpers_1.exists)(json, 'notaTipologia') ? '' : json.notaTipologia,
    'notaTipologiaDirty': !(0, helpers_1.exists)(json, 'notaTipologiaDirty') ? false : json.notaTipologiaDirty,
    'notaTipologiaError': !(0, helpers_1.exists)(json, 'notaTipologiaError') ? '' : json.notaTipologiaError,
    'primoPaese': !(0, helpers_1.exists)(json, 'primoPaese') ? '' : json.primoPaese,
    'primoPaeseDirty': !(0, helpers_1.exists)(json, 'primoPaeseDirty') ? false : json.primoPaeseDirty,
    'primoPaeseError': !(0, helpers_1.exists)(json, 'primoPaeseError') ? '' : json.primoPaeseError,
    'secondoPaese': !(0, helpers_1.exists)(json, 'secondoPaese') ? '' : json.secondoPaese,
    'terzoPaese': !(0, helpers_1.exists)(json, 'terzoPaese') ? '' : json.terzoPaese,
    'esercizioDati': !(0, helpers_1.exists)(json, 'esercizioDati') ? '' : json.esercizioDati,
    'esercizioDatiDirty': !(0, helpers_1.exists)(json, 'esercizioDatiDirty') ? false : json.esercizioDatiDirty,
    'esercizioDatiError': !(0, helpers_1.exists)(json, 'esercizioDatiError') ? '' : json.esercizioDatiError,
    'ultimaProfessione': !(0, helpers_1.exists)(json, 'ultimaProfessione') ? '' : json.ultimaProfessione,
    'ultimaProfessioneDirty': !(0, helpers_1.exists)(json, 'ultimaProfessioneDirty') ? false : json.ultimaProfessioneDirty,
    'ultimaProfessioneError': !(0, helpers_1.exists)(json, 'ultimaProfessioneError') ? '' : json.ultimaProfessioneError,
    'formDatiRedSubmitted': !(0, helpers_1.exists)(json, 'formDatiRedSubmitted') ? false : json.formDatiRedSubmitted,
    'appaltiFinanziamentiPubblici': !(0, helpers_1.exists)(json, 'appaltiFinanziamentiPubblici') ? false : json.appaltiFinanziamentiPubblici,
    'origineReddito': !(0, helpers_1.exists)(json, 'origineReddito') ? '' : json.origineReddito,
    'origineRedditoDirty': !(0, helpers_1.exists)(json, 'origineRedditoDirty') ? false : json.origineRedditoDirty,
    'origineRedditoError': !(0, helpers_1.exists)(json, 'origineRedditoError') ? '' : json.origineRedditoError,
    'originePatrimonio': !(0, helpers_1.exists)(json, 'originePatrimonio') ? '' : json.originePatrimonio,
    'originePatrimonioDirty': !(0, helpers_1.exists)(json, 'originePatrimonioDirty') ? false : json.originePatrimonioDirty,
    'originePatrimonioError': !(0, helpers_1.exists)(json, 'originePatrimonioError') ? '' : json.originePatrimonioError
  };
}
exports.FormDatiRedditoPatrimonialiADVDtoFromJSONTyped = FormDatiRedditoPatrimonialiADVDtoFromJSONTyped;
function FormDatiDocumentoIdentitaADVDTOFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoDocumento': !(0, helpers_1.exists)(json, 'tipoDocumento') ? '' : json.tipoDocumento,
    'numDocumento': !(0, helpers_1.exists)(json, 'numDocumento') ? undefined : json.numDocumento,
    'numDocumentoDirty': !(0, helpers_1.exists)(json, 'numDocumentoDirty') ? false : json.numDocumentoDirty,
    'numDocumentoError': !(0, helpers_1.exists)(json, 'numDocumentoError') ? '' : json.numDocumentoError,
    'luogoRilascio': !(0, helpers_1.exists)(json, 'luogoRilascio') ? undefined : json.luogoRilascio,
    'luogoRilascioDirty': !(0, helpers_1.exists)(json, 'luogoRilascioDirty') ? false : json.luogoRilascioDirty,
    'luogoRilascioError': !(0, helpers_1.exists)(json, 'luogoRilascioError') ? '' : json.luogoRilascioError,
    'dataRilascio': !(0, helpers_1.exists)(json, 'dataRilascio') ? new Date() : json.dataRilascio,
    'dataRilascioDirty': !(0, helpers_1.exists)(json, 'dataRilascioDirty') ? false : json.dataRilascioDirty,
    'dataRilascioError': !(0, helpers_1.exists)(json, 'dataRilascioError') ? '' : json.dataRilascioError,
    'dataScadenza': !(0, helpers_1.exists)(json, 'dataScadenza') ? new Date() : json.dataScadenza,
    'dataScadenzaDirty': !(0, helpers_1.exists)(json, 'dataScadenzaDirty') ? false : json.dataScadenzaDirty,
    'dataScadenzaError': !(0, helpers_1.exists)(json, 'dataScadenzaError') ? '' : json.dataScadenzaError,
    'files': !(0, helpers_1.exists)(json, 'files') ? undefined : json.files,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'modificaDocumento': !(0, helpers_1.exists)(json, 'modificaDocumento') ? false : json.modificaDocumento,
    'isUploadLoading': !(0, helpers_1.exists)(json, 'isUploadLoading') ? false : json.isUploadLoading
  };
}
exports.FormDatiDocumentoIdentitaADVDTOFromJSONTyped = FormDatiDocumentoIdentitaADVDTOFromJSONTyped;
function FormADVScopiRapportiFromJsonTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !(0, helpers_1.exists)(json, 'rapporti') ? (0, api_rest_1.RapportoADVDtoFromJSON)({}) : json.rapporti
  };
}
exports.FormADVScopiRapportiFromJsonTyped = FormADVScopiRapportiFromJsonTyped;
var EReduxAdeguataVerificaActionTypes;
(function (EReduxAdeguataVerificaActionTypes) {
  EReduxAdeguataVerificaActionTypes["ADV_RESET_TOTALE"] = "ADV_RESET_TOTALE";
  EReduxAdeguataVerificaActionTypes["ADV_RESET_TOTALE_DOCUMENTO"] = "ADV_RESET_TOTALE_DOCUMENTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA_PROVINCIA"] = "ADV_SET_DATI_RESIDENZA_PROVINCIA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA_COMUNE"] = "ADV_SET_DATI_RESIDENZA_COMUNE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA_LOCALITA"] = "ADV_SET_DATI_RESIDENZA_LOCALITA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA_CAP"] = "ADV_SET_DATI_RESIDENZA_CAP";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA_TIPO_VIA"] = "ADV_SET_DATI_RESIDENZA_TIPO_VIA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA_INDIRIZZO"] = "ADV_SET_DATI_RESIDENZA_INDIRIZZO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA_NCIVICO"] = "ADV_SET_DATI_RESIDENZA_NCIVICO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOMICILIO_PROVINCIA"] = "ADV_SET_DATI_DOMICILIO_PROVINCIA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOMICILIO_COMUNE"] = "ADV_SET_DATI_DOMICILIO_COMUNE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOMICILIO_LOCALITA"] = "ADV_SET_DATI_DOMICILIO_LOCALITA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOMICILIO_CAP"] = "ADV_SET_DATI_DOMICILIO_CAP";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOMICILIO_TIPO_VIA"] = "ADV_SET_DATI_DOMICILIO_TIPO_VIA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOMICILIO_INDIRIZZO"] = "ADV_SET_DATI_DOMICILIO_INDIRIZZO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOMICILIO_NCIVICO"] = "ADV_SET_DATI_DOMICILIO_NCIVICO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_RESIDENZA"] = "ADV_SET_DATI_RESIDENZA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_FORM_RESIDENZA_SUBMITTED"] = "ADV_SET_FORM_RESIDENZA_SUBMITTED";
  EReduxAdeguataVerificaActionTypes["ADV_SET_MODIFICA_DOMICILIO"] = "ADV_SET_MODIFICA_DOMICILIO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOCUMENTO_IDENTITA_TIPO_DOCUMENTO"] = "ADV_SET_DATI_DOCUMENTO_IDENTITA_TIPO_DOCUMENTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOCUMENTO_IDENTITA_NUMERO_DOCUMENTO"] = "ADV_SET_DATI_DOCUMENTO_IDENTITA_NUMERO_DOCUMENTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOCUMENTO_IDENTITA_LUOGO_RILASCIO"] = "ADV_SET_DATI_DOCUMENTO_IDENTITA_LUOGO_RILASCIO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOCUMENTO_IDENTITA_DATA_RILASCIO"] = "ADV_SET_DATI_DOCUMENTO_IDENTITA_DATA_RILASCIO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOCUMENTO_IDENTITA_DATA_SCADENZA"] = "ADV_SET_DATI_DOCUMENTO_IDENTITA_DATA_SCADENZA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_FILES_DOCUMENTO_IDENTITA"] = "ADV_SET_FILES_DOCUMENTO_IDENTITA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOCUMENTO_IDENTITA"] = "ADV_SET_DATI_DOCUMENTO_IDENTITA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_FORM_DOCUMENTO_SUBMITTED"] = "ADV_SET_FORM_DOCUMENTO_SUBMITTED";
  EReduxAdeguataVerificaActionTypes["ADV_AGGIORNA_DOCUMENTO_START"] = "ADV_AGGIORNA_DOCUMENTO_START";
  EReduxAdeguataVerificaActionTypes["ADV_AGGIORNA_DOCUMENTO_SUCCESS"] = "ADV_AGGIORNA_DOCUMENTO_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_SET_MODIFICA_DOCUMENTO"] = "ADV_SET_MODIFICA_DOCUMENTO";
  EReduxAdeguataVerificaActionTypes["ADV_RESET_FORM_DOCUMENTI_ACTION"] = "ADV_RESET_FORM_DOCUMENTI_ACTION";
  EReduxAdeguataVerificaActionTypes["ADV_SET_IS_UPLOAD_LOADING"] = "ADV_SET_IS_UPLOAD_LOADING";
  EReduxAdeguataVerificaActionTypes["ADV_SET_ARRAY_RAPPORTI_SCOPI"] = "ADV_SET_ARRAY_RAPPORTI_SCOPI";
  EReduxAdeguataVerificaActionTypes["ADV_SET_SCOPO_RAPPORTO"] = "ADV_SET_SCOPO_RAPPORTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DISABLED_FIRMA"] = "ADV_SET_DISABLED_FIRMA";
  EReduxAdeguataVerificaActionTypes["ADV_GET_PARAMETRI_START"] = "ADV_GET_PARAMETRI_START";
  EReduxAdeguataVerificaActionTypes["ADV_GET_PARAMETRI_SUCCESS"] = "ADV_GET_PARAMETRI_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_GET_DATI_START"] = " ADV_GET_DATI_START";
  EReduxAdeguataVerificaActionTypes["ADV_GET_DATI_SUCCESS"] = "ADV_GET_DATI_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DI_RESIDENZA"] = "ADV_SET_DATI_DI_RESIDENZA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DI_DOMICILIO"] = "ADV_SET_DATI_DI_DOMICILIO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATI_DOCUMENTO_DI_RICONOSCIMENTO"] = "ADV_SET_DATI_DOCUMENTO_DI_RICONOSCIMENTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_SCOPO_PREVALENTE_RAPPORTO"] = "ADV_SET_SCOPO_PREVALENTE_RAPPORTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_PROFESSIONE"] = "ADV_SET_PROFESSIONE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_TAE"] = "ADV_SET_TAE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_CAE"] = "ADV_SET_CAE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_STATO_ATT_EC_PREV"] = "ADV_SET_STATO_ATT_EC_PREV";
  EReduxAdeguataVerificaActionTypes["ADV_SET_PROV_ATT_EC_PREV"] = "ADV_SET_PROV_ATT_EC_PREV";
  EReduxAdeguataVerificaActionTypes["ADV_SET_REDDITO_ANNUO"] = "ADV_SET_REDDITO_ANNUO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_PATRIMONIO_TOTALE_CLIENTE"] = "ADV_SET_PATRIMONIO_TOTALE_CLIENTE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_QUALIFICA_LAVORATIVA"] = "ADV_SET_QUALIFICA_LAVORATIVA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_NOTA_TIPOLOGIA"] = "ADV_SET_NOTA_TIPOLOGIA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_PRIMO_PAESE"] = "ADV_SET_PRIMO_PAESE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_SECONDO_PAESE"] = "ADV_SET_SECONDO_PAESE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_TERZO_PAESE"] = "ADV_SET_TERZO_PAESE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_ESERCIZIO_DATI"] = "ADV_SET_ESERCIZIO_DATI";
  EReduxAdeguataVerificaActionTypes["ADV_SET_ULTIMA_PROFESSIONE"] = "ADV_SET_ULTIMA_PROFESSIONE";
  EReduxAdeguataVerificaActionTypes["ADV_SET_APPALTI_PUBBLICI"] = "ADV_SET_APPALTI_PUBBLICI";
  EReduxAdeguataVerificaActionTypes["ADV_SET_ORIGINE_REDDITO"] = "ADV_SET_ORIGINE_REDDITO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_ORIGINE_PATRIMONIO"] = "ADV_SET_ORIGINE_PATRIMONIO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_FORM_DATI_REDDITTUALI_SUBMITTED"] = "ADV_SET_FORM_DATI_REDDITTUALI_SUBMITTED";
  EReduxAdeguataVerificaActionTypes["ADV_GET_QUALIFICHE_TAE_CAE_BY_PROFESSIONE_START"] = "ADV_GET_QUALIFICHE_TAE_CAE_BY_PROFESSIONE_START";
  EReduxAdeguataVerificaActionTypes["ADV_GET_QUALIFICHE_TAE_CAE_BY_PROFESSIONE_SUCCESS"] = "ADV_GET_QUALIFICHE_TAE_CAE_BY_PROFESSIONE_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_SET_TIPO_DOCUMENTO"] = "ADV_SET_TIPO_DOCUMENTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_NUMERO_DOCUMENTO"] = "ADV_SET_NUMERO_DOCUMENTO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_LUOGO_DI_RILASCIO"] = "ADV_SET_LUOGO_DI_RILASCIO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATA_DI_RILASCIO"] = "ADV_SET_DATA_DI_RILASCIO";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DATA_DI_SCADENZA"] = "ADV_SET_DATA_DI_SCADENZA";
  EReduxAdeguataVerificaActionTypes["ADV_SET_FORM_DATI_REDDITUALI"] = "ADV_SET_FORM_DATI_REDDITUALI";
  EReduxAdeguataVerificaActionTypes["ADV_MODIFICA_QUESTIONARIO_START"] = "ADV_MODIFICA_QUESTIONARIO_START";
  EReduxAdeguataVerificaActionTypes["ADV_MODIFICA_QUESTIONARIO_SUCCESS"] = "ADV_MODIFICA_QUESTIONARIO_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_RESET_RIEPILOGO"] = "ADV_RESET_RIEPILOGO";
  EReduxAdeguataVerificaActionTypes["ADV_EXPORT_PDF_START"] = "ADV_EXPORT_PDF_START";
  EReduxAdeguataVerificaActionTypes["ADV_EXPORT_PDF_SUCCESS"] = "ADV_EXPORT_PDF_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_SALVA_DOCUMENTO_MODIFICATO_START"] = "ADV_SALVA_DOCUMENTO_MODIFICATO_START";
  EReduxAdeguataVerificaActionTypes["ADV_SALVA_DOCUMENTO_MODIFICATO_SUCCESS"] = "ADV_SALVA_DOCUMENTO_MODIFICATO_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_SET_DOCUMENTO_MODIFICATO"] = "ADV_SET_DOCUMENTO_MODIFICATO";
  EReduxAdeguataVerificaActionTypes["ADV_VERIFICA_CARICAMENTO_DOCUMENTO_START"] = "ADV_VERIFICA_CARICAMENTO_DOCUMENTO_START";
  EReduxAdeguataVerificaActionTypes["ADV_VERIFICA_CARICAMENTO_DOCUMENTO_SUCCESS"] = "ADV_VERIFICA_CARICAMENTO_DOCUMENTO_SUCCESS";
  EReduxAdeguataVerificaActionTypes["ADV_SET_ORIGINE_DESTINAZIONE_FONDI"] = "ADV_SET_ORIGINE_DESTINAZIONE_FONDI";
})(EReduxAdeguataVerificaActionTypes = exports.EReduxAdeguataVerificaActionTypes || (exports.EReduxAdeguataVerificaActionTypes = {}));