"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SezioneInpsDtoToJSON = exports.SezioneInpsDtoFromJSONTyped = exports.SezioneInpsDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SezioneInpsDtoFromJSON(json) {
  return SezioneInpsDtoFromJSONTyped(json, false);
}
exports.SezioneInpsDtoFromJSON = SezioneInpsDtoFromJSON;
function SezioneInpsDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'righe': !runtime_1.exists(json, 'righe') ? undefined : json['righe'].map(_1.RigaSezioneInpsDtoFromJSON),
    'totalInps': !runtime_1.exists(json, 'totalInps') ? undefined : json['totalInps']
  };
}
exports.SezioneInpsDtoFromJSONTyped = SezioneInpsDtoFromJSONTyped;
function SezioneInpsDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'righe': value.righe === undefined ? undefined : value.righe.map(_1.RigaSezioneInpsDtoToJSON),
    'totalInps': value.totalInps
  };
}
exports.SezioneInpsDtoToJSON = SezioneInpsDtoToJSON;