"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RigaSezioneErarioDtoToJSON = exports.RigaSezioneErarioDtoFromJSONTyped = exports.RigaSezioneErarioDtoFromJSON = void 0;
function RigaSezioneErarioDtoFromJSON(json) {
  return RigaSezioneErarioDtoFromJSONTyped(json, false);
}
exports.RigaSezioneErarioDtoFromJSON = RigaSezioneErarioDtoFromJSON;
function RigaSezioneErarioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceTributo': json['codiceTributo'],
    'infoTributo': json['infoTributo'],
    'anno': json['anno'],
    'importoDebito': json['importoDebito'],
    'importoCredito': json['importoCredito']
  };
}
exports.RigaSezioneErarioDtoFromJSONTyped = RigaSezioneErarioDtoFromJSONTyped;
function RigaSezioneErarioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceTributo': value.codiceTributo,
    'infoTributo': value.infoTributo,
    'anno': value.anno,
    'importoDebito': value.importoDebito,
    'importoCredito': value.importoCredito
  };
}
exports.RigaSezioneErarioDtoToJSON = RigaSezioneErarioDtoToJSON;