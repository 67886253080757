"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pdfCartaConto = void 0;
const reselect_1 = require("reselect");
const cartaContoReducer = state => state;
exports.pdfCartaConto = (0, reselect_1.createSelector)(cartaContoReducer, state => {
  return state.pdf;
});