"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.psd2ResponseStatus = exports.paymentPsd2PollingModalShown = exports.paymentPSD2PollingActive = exports.formPsd2EnrollmentValid = exports.certificatoFirmaDigitale = exports.statoFirmaDigitale = exports.webViewLinkConsentRenewPSD2 = exports.enrollmentPSD2Completed = exports.enrollmentPSD2PollingActive = exports.enrollmentPsd2PollingModalShown = exports.webViewLinkEnrollmentPSD2 = exports.consensoRichiestoEnrollmentPSD2 = exports.elencoProdottiEnrollmentPSD2 = exports.dettaglioBancaEnrollmentPSD2 = exports.formEnrollmentPSD2 = exports.bancheEnrollatePSD2 = exports.bancheDisponibiliPSD2 = void 0;
const reselect_1 = require("reselect");
const psd2Reducer = state => state;
exports.bancheDisponibiliPSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.bancheDisponibili;
});
exports.bancheEnrollatePSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.bancheEnrollate;
});
exports.formEnrollmentPSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.formEnrollment;
});
exports.dettaglioBancaEnrollmentPSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.dettaglioBanca;
});
exports.elencoProdottiEnrollmentPSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  var _a, _b, _c;
  return (_c = (_b = (_a = state.dettaglioBanca) === null || _a === void 0 ? void 0 : _a.listDetail) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.aspspProductList;
});
exports.consensoRichiestoEnrollmentPSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  var _a;
  return !!((_a = state.dettaglioBanca) === null || _a === void 0 ? void 0 : _a.activeConsent);
});
exports.webViewLinkEnrollmentPSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.enrollmentWebViewLink;
});
exports.enrollmentPsd2PollingModalShown = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.enrollmentPollingModalShown;
});
exports.enrollmentPSD2PollingActive = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.enrollmentPollingActive;
});
exports.enrollmentPSD2Completed = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.enrollmentCompleted;
});
exports.webViewLinkConsentRenewPSD2 = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.renewWebViewLink;
});
exports.statoFirmaDigitale = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.verificaFirmaDigitale;
});
exports.certificatoFirmaDigitale = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.certificatoFirmaDigitale;
});
exports.formPsd2EnrollmentValid = (0, reselect_1.createSelector)(psd2Reducer, state => {
  var _a;
  if ((_a = state.dettaglioBanca) === null || _a === void 0 ? void 0 : _a.ibanRequired) {
    return state.formEnrollment.usernameError == "" && !state.formEnrollment.ibanList.some(iban => !!iban.ibanError) && state.formEnrollment.ibanList.length > 0;
  }
  return state.formEnrollment.usernameError == "";
});
exports.paymentPSD2PollingActive = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.paymentPollingActive;
});
exports.paymentPsd2PollingModalShown = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.paymentPollingModalShown;
});
exports.psd2ResponseStatus = (0, reselect_1.createSelector)(psd2Reducer, state => {
  return state.psd2ResponseStatus.psd2Response;
});