"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrestitiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PrestitiControllerApi extends runtime.BaseAPI {
  /**
   * Restituisce i dati per la dasboard
   */
  getDashboardPrestitiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
        throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getDashboardPrestiti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/prestiti/dashboard",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.FinanziamentiDashboardDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce i dati per la dasboard
   */
  getDashboardPrestiti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getDashboardPrestitiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce piano ammortamento per un prestito
   */
  getPianoAmmortamentoPrestitiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
        throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getPianoAmmortamentoPrestiti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/prestiti/ammortamento",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce piano ammortamento per un prestito
   */
  getPianoAmmortamentoPrestiti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getPianoAmmortamentoPrestitiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce la lista delle rate in mora per un prestito
   */
  getRateMoraPrestitiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
        throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getRateMoraPrestiti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/prestiti/rate/mora",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce la lista delle rate in mora per un prestito
   */
  getRateMoraPrestiti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getRateMoraPrestitiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce la lista delle rate pagate
   */
  getRatePagatePrestitiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
        throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getRatePagatePrestiti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/prestiti/rate/pagate",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce la lista delle rate pagate
   */
  getRatePagatePrestiti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getRatePagatePrestitiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce la lista delle rate residue per un prestito
   */
  getRateResiduePrestitiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
        throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getRateResiduePrestiti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/prestiti/rate/residue",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce la lista delle rate residue per un prestito
   */
  getRateResiduePrestiti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getRateResiduePrestitiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Richiesta parametri iniziali per i prestiti
   */
  parametriPrestitiRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/prestiti/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriFinanziamentiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Richiesta parametri iniziali per i prestiti
   */
  parametriPrestiti() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriPrestitiRaw();
      return yield response.value();
    });
  }
}
exports.PrestitiControllerApi = PrestitiControllerApi;