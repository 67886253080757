"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuestionnaireReportInDtoToJSON = exports.QuestionnaireReportInDtoFromJSONTyped = exports.QuestionnaireReportInDtoFromJSON = void 0;
function QuestionnaireReportInDtoFromJSON(json) {
  return QuestionnaireReportInDtoFromJSONTyped(json, false);
}
exports.QuestionnaireReportInDtoFromJSON = QuestionnaireReportInDtoFromJSON;
function QuestionnaireReportInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'customerId': json['customerId'],
    'generateReport': json['generateReport'],
    'downloadReport': json['downloadReport']
  };
}
exports.QuestionnaireReportInDtoFromJSONTyped = QuestionnaireReportInDtoFromJSONTyped;
function QuestionnaireReportInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'customerId': value.customerId,
    'generateReport': value.generateReport,
    'downloadReport': value.downloadReport
  };
}
exports.QuestionnaireReportInDtoToJSON = QuestionnaireReportInDtoToJSON;