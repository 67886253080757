"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RiskProfileResultDtoToJSON = exports.RiskProfileResultDtoFromJSONTyped = exports.RiskProfileResultDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RiskProfileResultDtoFromJSON(json) {
  return RiskProfileResultDtoFromJSONTyped(json, false);
}
exports.RiskProfileResultDtoFromJSON = RiskProfileResultDtoFromJSON;
function RiskProfileResultDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    'key': !runtime_1.exists(json, 'key') ? undefined : json['key'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    'value': !runtime_1.exists(json, 'value') ? undefined : json['value'],
    'valueDescription': !runtime_1.exists(json, 'valueDescription') ? undefined : json['valueDescription']
  };
}
exports.RiskProfileResultDtoFromJSONTyped = RiskProfileResultDtoFromJSONTyped;
function RiskProfileResultDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'bankCode': value.bankCode,
    'description': value.description,
    'key': value.key,
    'message': value.message,
    'userId': value.userId,
    'value': value.value,
    'valueDescription': value.valueDescription
  };
}
exports.RiskProfileResultDtoToJSON = RiskProfileResultDtoToJSON;