"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datiIntradayNews = exports.dettaglioNews = exports.elencoNews = exports.formNews = exports.parametriNews = void 0;
const reselect_1 = require("reselect");
const newsReducer = state => state;
exports.parametriNews = (0, reselect_1.createSelector)(newsReducer, state => {
  return state.parametri;
});
exports.formNews = (0, reselect_1.createSelector)(newsReducer, state => {
  return state.form;
});
exports.elencoNews = (0, reselect_1.createSelector)(newsReducer, state => {
  return state.news;
});
exports.dettaglioNews = (0, reselect_1.createSelector)(newsReducer, state => {
  return state.dettaglioNews;
});
exports.datiIntradayNews = (0, reselect_1.createSelector)(newsReducer, state => {
  return state.datiIntradayNews;
});