"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MandatoryActionTypeEnumToJSON = exports.MandatoryActionTypeEnumFromJSONTyped = exports.MandatoryActionTypeEnumFromJSON = exports.MandatoryActionTypeEnum = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var MandatoryActionTypeEnum;
(function (MandatoryActionTypeEnum) {
  MandatoryActionTypeEnum["CHANGE_PASSWORD"] = "CHANGE_PASSWORD";
  MandatoryActionTypeEnum["TOKEN_ENROLLMENT"] = "TOKEN_ENROLLMENT";
  MandatoryActionTypeEnum["TOKEN_RESET"] = "TOKEN_RESET";
})(MandatoryActionTypeEnum = exports.MandatoryActionTypeEnum || (exports.MandatoryActionTypeEnum = {}));
function MandatoryActionTypeEnumFromJSON(json) {
  return MandatoryActionTypeEnumFromJSONTyped(json, false);
}
exports.MandatoryActionTypeEnumFromJSON = MandatoryActionTypeEnumFromJSON;
function MandatoryActionTypeEnumFromJSONTyped(json, ignoreDiscriminator) {
  return json;
}
exports.MandatoryActionTypeEnumFromJSONTyped = MandatoryActionTypeEnumFromJSONTyped;
function MandatoryActionTypeEnumToJSON(value) {
  return value;
}
exports.MandatoryActionTypeEnumToJSON = MandatoryActionTypeEnumToJSON;