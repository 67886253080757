"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneProfiloDtoToJSON = exports.EsitoAutorizzazioneDisposizioneProfiloDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneProfiloDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneProfiloDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneProfiloDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneProfiloDtoFromJSON = EsitoAutorizzazioneDisposizioneProfiloDtoFromJSON;
function EsitoAutorizzazioneDisposizioneProfiloDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.DatiAnagraficiDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneProfiloDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneProfiloDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneProfiloDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.DatiAnagraficiDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneProfiloDtoToJSON = EsitoAutorizzazioneDisposizioneProfiloDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneProfiloDtoStatoEnum = {}));