"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datiGraficoIndice = exports.dettaglioIndice = exports.elencoIndici = exports.formIndici = exports.mercatiIndici = void 0;
const indici_types_1 = require("./indici.types");
const reselect_1 = require("reselect");
const moment = require("moment");
const indiciReducer = state => state;
exports.mercatiIndici = (0, reselect_1.createSelector)(indiciReducer, state => {
  return state.mercati;
});
exports.formIndici = (0, reselect_1.createSelector)(indiciReducer, state => {
  return state.form;
});
exports.elencoIndici = (0, reselect_1.createSelector)(indiciReducer, state => {
  return state.indici;
});
exports.dettaglioIndice = (0, reselect_1.createSelector)(indiciReducer, state => {
  return state.indiceDettaglio;
});
exports.datiGraficoIndice = (0, reselect_1.createSelector)(indiciReducer, state => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const now = state.indiceDettaglio.aggiornamento || new Date();
  const giorno = (_b = (_a = state.indiceDettaglio.datiIntradayIndice) === null || _a === void 0 ? void 0 : _a.dati) === null || _b === void 0 ? void 0 : _b.map(d => ({
    ...d,
    x: d.data ? moment(d.data).format("x") : "-"
  }));
  let ora1 = giorno === null || giorno === void 0 ? void 0 : giorno.filter(d => (d.data || now) <= moment(now).subtract(1, "hours").toDate());
  let ora4 = giorno === null || giorno === void 0 ? void 0 : giorno.filter(d => (d.data || now) <= moment(now).subtract(4, "hours").toDate());
  return {
    ...state.indiceDettaglio,
    datiGiornoPresenti: giorno ? !!(giorno === null || giorno === void 0 ? void 0 : giorno.length) : undefined,
    datiOraPresenti: ora1 ? !!(ora1 === null || ora1 === void 0 ? void 0 : ora1.length) : undefined,
    dati4OrePresenti: ora4 ? !!(ora4 === null || ora4 === void 0 ? void 0 : ora4.length) : undefined,
    graficoCorrente: state.indiceDettaglio.tipoGrafico === indici_types_1.TipoGraficoIndici.Ore1 ? ora1 : state.indiceDettaglio.tipoGrafico === indici_types_1.TipoGraficoIndici.Ore4 ? ora4 : state.indiceDettaglio.tipoGrafico === indici_types_1.TipoGraficoIndici.Giorni1 ? giorno : (_h = (_g = (_f = state.indiceDettaglio.tipoGrafico === indici_types_1.TipoGraficoIndici.Settimana1 ? (_c = state.indiceDettaglio.datiSettimana) === null || _c === void 0 ? void 0 : _c.dati : state.indiceDettaglio.tipoGrafico === indici_types_1.TipoGraficoIndici.Mese1 ? (_d = state.indiceDettaglio.datiMese) === null || _d === void 0 ? void 0 : _d.dati : (_e = state.indiceDettaglio.datiAnno) === null || _e === void 0 ? void 0 : _e.dati) === null || _f === void 0 ? void 0 : _f.map(d => ({
      data: d.data,
      valore: d.chiusura
    }))) === null || _g === void 0 ? void 0 : _g.sort((a, b) => a.data && b.data && a.data < b.data ? -1 : 1)) === null || _h === void 0 ? void 0 : _h.map(d => ({
      ...d,
      x: d.data ? moment(d.data).format("x") : "-"
    }))
  };
});