"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JobActorDtoToJSON = exports.JobActorDtoFromJSONTyped = exports.JobActorDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function JobActorDtoFromJSON(json) {
  return JobActorDtoFromJSONTyped(json, false);
}
exports.JobActorDtoFromJSON = JobActorDtoFromJSON;
function JobActorDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
    'lastname': !runtime_1.exists(json, 'lastname') ? undefined : json['lastname'],
    'institutionCode': !runtime_1.exists(json, 'institutionCode') ? undefined : json['institutionCode'],
    'fiscalCode': !runtime_1.exists(json, 'fiscalCode') ? undefined : json['fiscalCode'],
    'linkedFields': !runtime_1.exists(json, 'linkedFields') ? undefined : json['linkedFields'],
    'linkedDocuments': !runtime_1.exists(json, 'linkedDocuments') ? undefined : json['linkedDocuments'],
    'occField': !runtime_1.exists(json, 'occField') ? undefined : json['occField'],
    'indexOrder': !runtime_1.exists(json, 'indexOrder') ? undefined : json['indexOrder'],
    'attributesValue': !runtime_1.exists(json, 'attributesValue') ? undefined : _1.JobActorAttributesValueDtoFromJSON(json['attributesValue']),
    'completed': !runtime_1.exists(json, 'completed') ? undefined : json['completed']
  };
}
exports.JobActorDtoFromJSONTyped = JobActorDtoFromJSONTyped;
function JobActorDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'name': value.name,
    'lastname': value.lastname,
    'institutionCode': value.institutionCode,
    'fiscalCode': value.fiscalCode,
    'linkedFields': value.linkedFields,
    'linkedDocuments': value.linkedDocuments,
    'occField': value.occField,
    'indexOrder': value.indexOrder,
    'attributesValue': _1.JobActorAttributesValueDtoToJSON(value.attributesValue),
    'completed': value.completed
  };
}
exports.JobActorDtoToJSON = JobActorDtoToJSON;