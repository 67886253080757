"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ricaricaCellulareReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const ricaricaCellulare_types_1 = require("./ricaricaCellulare.types");
const initialState = {
  form: (0, ricaricaCellulare_types_1.FormRicaricaCellulareDtoFromJSONTyped)({}),
  parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
  infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.RicaricaCellulareDtoFromJSON)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSON)({}),
  gestori: undefined,
  tagli: (0, api_rest_1.TagliRicaricaCellulareDtoFromJSON)({}),
  bozzeRicaricaCellulare: [],
  currentBozza: (0, api_rest_1.RicaricaCellulareDtoFromJSON)({}),
  oneclick: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({})
};
let rapportoSelezionato;
const ricaricaCellulareReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a;
  switch (action.type) {
    case __1.EReduxActionTypes.RICARICA_CELLULARE_NUMERO_CELLULARE:
      return {
        ...state,
        form: {
          ...state.form,
          numeroCellulare: action.payload,
          numeroCellulareDirty: true,
          numeroCellulareError: (0, helpers_1.validationNumeroCellulare)(action.payload, true),
          nomeBeneficiarioError: '',
          idContattoBeneficiario: undefined,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_AGGIUNGI_BENEFICIARIO_RUBRICA:
      return {
        ...state,
        form: {
          ...state.form,
          aggiungiBeneficiarioRubrica: action.payload,
          beneficiarioAffidabile: !action.payload && false,
          nomeBeneficiario: '',
          nomeBeneficiarioDirty: false,
          nomeBeneficiarioError: 'invalid.required'
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_NOME_BENEFICIARIO:
      return {
        ...state,
        form: {
          ...state.form,
          nomeBeneficiario: action.payload,
          nomeBeneficiarioDirty: true,
          nomeBeneficiarioError: (0, helpers_1.validationField)(action.payload, ''),
          idContattoBeneficiario: undefined,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_SET_ID_CONTATTO_BENEFICIARIO:
      return {
        ...state,
        form: {
          ...state.form,
          idContattoBeneficiario: action.payload
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_BENEFICIARIO_AFFIDABILE:
      return {
        ...state,
        form: {
          ...state.form,
          beneficiarioAffidabile: action.payload,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_NOME_GESTORE:
      return {
        ...state,
        form: {
          ...state.form,
          nomeGestore: action.payload,
          nomeGestoreDirty: true,
          nomeGestoreError: (0, helpers_1.validationField)(action.payload, 0),
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_CODICE_SIA:
      return {
        ...state,
        form: {
          ...state.form,
          codiceSia: action.payload,
          codiceSiaDirty: true,
          codiceSiaError: (0, helpers_1.validationField)(action.payload, ''),
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_TAGLIO_RICARICA:
      return {
        ...state,
        form: {
          ...state.form,
          taglioRicarica: action.payload,
          taglioRicaricaDirty: true,
          taglioRicaricaError: (0, helpers_1.validationField)(action.payload, 0),
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_SALVA_1CLICK:
      return {
        ...state,
        form: {
          ...state.form,
          salva_1Click: action.payload,
          alias_1Click: action.payload ? 'Ricarica ' + state.form.numeroCellulare + ', ' + state.form.taglioRicarica + '€' : '',
          alias_1ClickDirty: false,
          alias_1ClickError: ''
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_ALIAS_1CLICK:
      return {
        ...state,
        form: {
          ...state.form,
          alias_1Click: action.payload,
          alias_1ClickDirty: true,
          alias_1ClickError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_FORM_SUBMITTED:
      return {
        ...state,
        form: {
          ...state.form,
          formSubmitted: action.payload
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_CODICE_RAPPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: {
            ...state.form.rapportoSelezionato,
            rapporto: {
              ...((_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto),
              id: action.payload
            }
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload,
        gestori: state.gestori
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_PARAMETRI_PER_RIPETI_SUCCESS:
      return {
        ...initialState,
        parametri: action.payload,
        gestori: state.gestori,
        tagli: state.tagli,
        form: {
          ...state.form,
          aggiungiBeneficiarioRubrica: false,
          beneficiarioAffidabile: false,
          salva_1Click: false,
          alias_1Click: '',
          ripeti: true
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_SUCCESS:
      return {
        ...state,
        infoDispositiva: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_CONTROLLO_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_AUTORIZZA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_GESTORI_SUCCESS:
      return {
        ...state,
        gestori: action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_TAGLI_GESTORE_SUCCESS:
      return {
        ...state,
        tagli: action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_SET_BOZZASALVATA:
      return {
        ...state,
        form: {
          ...state.form,
          bozzaSalvata: action.payload
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_BOZZE_SUCCESS:
      return {
        ...state,
        bozzeRicaricaCellulare: action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_BOZZA_SUCCESS:
      rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.codiceRapporto);
      return {
        ...state,
        form: {
          ...state.form,
          numeroCellulare: action.payload.numeroCellulare,
          numeroCellulareDirty: false,
          numeroCellulareError: (0, helpers_1.validationNumeroCellulare)(action.payload.numeroCellulare || '', true),
          taglioRicarica: action.payload.taglioRicarica,
          taglioRicaricaDirty: false,
          taglioRicaricaError: (0, helpers_1.validationField)(action.payload.taglioRicarica, 0),
          codiceSia: action.payload.codiceSia,
          codiceSiaDirty: false,
          codiceSiaError: (0, helpers_1.validationField)(action.payload.codiceSia, ''),
          alias_1ClickError: '',
          nomeBeneficiarioError: '',
          nomeGestoreError: '',
          rapportoSelezionato: rapportoSelezionato
        },
        currentBozza: action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_RICARICA_CELLULARE_FROM_BOZZA:
      rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.codiceRapporto);
      return {
        ...state,
        form: {
          ...state.form,
          numeroCellulare: action.payload.numeroCellulare,
          numeroCellulareDirty: false,
          numeroCellulareError: (0, helpers_1.validationNumeroCellulare)(action.payload.numeroCellulare || '', true),
          taglioRicarica: action.payload.taglioRicarica,
          taglioRicaricaDirty: false,
          taglioRicaricaError: (0, helpers_1.validationField)(action.payload.taglioRicarica, 0),
          codiceSia: action.payload.codiceSia,
          codiceSiaDirty: false,
          codiceSiaError: (0, helpers_1.validationField)(action.payload.codiceSia, ''),
          alias_1ClickError: '',
          nomeBeneficiarioError: '',
          nomeGestoreError: '',
          nomeGestore: action.payload.nomeGestore || '',
          rapportoSelezionato: rapportoSelezionato,
          idOneClickCollegata: action.payload.idOneClickCollegata
        },
        currentBozza: action.noBozza ? initialState.currentBozza : action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_ADD_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeRicaricaCellulare: state.bozzeRicaricaCellulare ? [...state.bozzeRicaricaCellulare, action.payload] : [action.payload],
        form: {
          ...state.form,
          bozzaSalvata: true
        },
        currentBozza: action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_DEL_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeRicaricaCellulare: state.bozzeRicaricaCellulare ? state.bozzeRicaricaCellulare.filter(ricaricaCellulare => ricaricaCellulare.id !== action.payload) : [],
        form: {
          ...state.form,
          bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
        },
        currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_UPDATE_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeRicaricaCellulare: state.bozzeRicaricaCellulare ? state.bozzeRicaricaCellulare.map(ricaricaCellulare => ricaricaCellulare.id === action.payload.id ? action.payload : ricaricaCellulare) : [],
        form: {
          ...state.form,
          bozzaSalvata: true
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_GET_ONECLICK_SUCCESS:
      return {
        ...state,
        oneclick: action.payload
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_SET_CONTATTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          contattoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_RIEPILOGO:
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_SET_SALVA_BOZZA_VALIDATION:
      return {
        ...state,
        form: {
          ...state.form,
          taglioRicaricaError: '',
          codiceSiaError: ''
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_SET_AVANTI_VALIDATION:
      return {
        ...state,
        form: {
          ...state.form,
          taglioRicaricaDirty: true,
          taglioRicaricaError: (0, helpers_1.validationField)(action.payload.taglioRicarica, 0),
          codiceSiaDirty: true,
          codiceSiaError: (0, helpers_1.validationField)(action.payload.codiceSia, '')
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_TAGLIO_RICARICA:
      return {
        ...state,
        form: {
          ...state.form,
          taglioRicarica: initialState.form.taglioRicarica,
          taglioRicaricaDirty: initialState.form.taglioRicaricaDirty,
          taglioRicaricaError: initialState.form.taglioRicaricaError
        }
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_CURRENT_BOZZA_AND_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          numeroCellulare: '',
          numeroCellulareDirty: false,
          numeroCellulareError: '',
          taglioRicarica: undefined,
          taglioRicaricaDirty: false,
          taglioRicaricaError: '',
          codiceSia: '',
          codiceSiaDirty: false,
          codiceSiaError: '',
          alias_1ClickError: '',
          nomeBeneficiarioError: '',
          nomeGestoreError: '',
          rapportoSelezionato: initialState.form.rapportoSelezionato
        },
        currentBozza: initialState.currentBozza
      };
    case __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_FORM:
      return {
        ...state,
        form: {
          ...initialState.form
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.ricaricaCellulareReducer = ricaricaCellulareReducer;
exports.default = exports.ricaricaCellulareReducer;