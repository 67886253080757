"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatusCodes = exports.TextSearchResponseToJSON = exports.TextSearchResponseFromJSONTyped = exports.TextSearchResponseFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const Result_1 = require("./Result");
function TextSearchResponseFromJSON(json) {
  return TextSearchResponseFromJSONTyped(json, false);
}
exports.TextSearchResponseFromJSON = TextSearchResponseFromJSON;
function TextSearchResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'html_attributions': !(0, api_rest_1.exists)(json, 'html_attributions') ? undefined : json['html_attributions'],
    'results': !(0, api_rest_1.exists)(json, 'results') ? undefined : json['results'].map(Result_1.ResultFromJSON),
    'status': !(0, api_rest_1.exists)(json, 'status') ? undefined : json['status']
  };
}
exports.TextSearchResponseFromJSONTyped = TextSearchResponseFromJSONTyped;
function TextSearchResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'html_attributions': value.html_attributions,
    'results': value.results,
    'status': value.status
  };
}
exports.TextSearchResponseToJSON = TextSearchResponseToJSON;
var StatusCodes;
(function (StatusCodes) {
  /**
   * Code indicates that no errors occurred; the place was successfully detected
   * and at least one result was returned.
   */
  StatusCodes["OK"] = "OK";
  /**
   * Code indicates that the search was successful but returned no results.
   * This may occur if the search was passed a latlng in a remote location.
   */
  StatusCodes["ZERO_RESULTS"] = "ZERO_RESULTS";
  /**
   * Error indicates that you are over your quota.
   */
  StatusCodes["OVER_QUERY_LIMIT"] = "OVER_QUERY_LIMIT";
  /**
   * Error indicates that your request was denied, generally because of lack of
   * an invalid key parameter.
   */
  StatusCodes["REQUEST_DENIED"] = "REQUEST_DENIED";
  /**
   * Error generally indicates that a required query parameter (location or radius)
   * is missing.
   */
  StatusCodes["INVALID_REQUEST"] = "INVALID_REQUEST";
  /**
   * Error that indicates a server-side error; trying again may be successful.
   */
  StatusCodes["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
})(StatusCodes = exports.StatusCodes || (exports.StatusCodes = {}));