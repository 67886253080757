"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartaAbilitabileDtoToJSON = exports.CartaAbilitabileDtoFromJSONTyped = exports.CartaAbilitabileDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CartaAbilitabileDtoFromJSON(json) {
  return CartaAbilitabileDtoFromJSONTyped(json, false);
}
exports.CartaAbilitabileDtoFromJSON = CartaAbilitabileDtoFromJSON;
function CartaAbilitabileDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'carta': !runtime_1.exists(json, 'carta') ? undefined : _1.CondizioniAcquistoNexiDebitFromJSON(json['carta']),
    'defaultChoice': !runtime_1.exists(json, 'defaultChoice') ? undefined : json['defaultChoice'],
    'messaggiConvenzione': !runtime_1.exists(json, 'messaggiConvenzione') ? undefined : json['messaggiConvenzione'].map(_1.MessaggioConvenzioneDtoFromJSON)
  };
}
exports.CartaAbilitabileDtoFromJSONTyped = CartaAbilitabileDtoFromJSONTyped;
function CartaAbilitabileDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'carta': _1.CondizioniAcquistoNexiDebitToJSON(value.carta),
    'defaultChoice': value.defaultChoice,
    'messaggiConvenzione': value.messaggiConvenzione === undefined ? undefined : value.messaggiConvenzione.map(_1.MessaggioConvenzioneDtoToJSON)
  };
}
exports.CartaAbilitabileDtoToJSON = CartaAbilitabileDtoToJSON;