"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.F24ParametriDtoToJSON = exports.F24ParametriDtoFromJSONTyped = exports.F24ParametriDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function F24ParametriDtoFromJSON(json) {
  return F24ParametriDtoFromJSONTyped(json, false);
}
exports.F24ParametriDtoFromJSON = F24ParametriDtoFromJSON;
function F24ParametriDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'codiciTributiErario': !runtime_1.exists(json, 'codiciTributiErario') ? undefined : json['codiciTributiErario'].map(_1.F24LookupDtoFromJSON),
    'tributiInps': !runtime_1.exists(json, 'tributiInps') ? undefined : json['tributiInps'].map(_1.F24LookupDtoFromJSON),
    'province': !runtime_1.exists(json, 'province') ? undefined : json['province'].map(_1.F24LookupDtoFromJSON),
    'sediInps': !runtime_1.exists(json, 'sediInps') ? undefined : json['sediInps'].map(_1.F24LookupDtoFromJSON),
    'codiciIdentificativiCoobbligato': !runtime_1.exists(json, 'codiciIdentificativiCoobbligato') ? undefined : json['codiciIdentificativiCoobbligato'].map(_1.F24LookupDtoFromJSON),
    'codiciTributiRegioni': !runtime_1.exists(json, 'codiciTributiRegioni') ? undefined : json['codiciTributiRegioni'].map(_1.F24LookupDtoFromJSON),
    'codiciTributiEntiLocali': !runtime_1.exists(json, 'codiciTributiEntiLocali') ? undefined : json['codiciTributiEntiLocali'].map(_1.F24LookupDtoFromJSON),
    'tipiEnte': !runtime_1.exists(json, 'tipiEnte') ? undefined : json['tipiEnte'].map(_1.F24LookupDtoFromJSON),
    'tributiAccise': !runtime_1.exists(json, 'tributiAccise') ? undefined : json['tributiAccise'].map(_1.F24LookupDtoFromJSON),
    'sediInail': !runtime_1.exists(json, 'sediInail') ? undefined : json['sediInail'].map(_1.F24LookupDtoFromJSON),
    'sediEnpals': !runtime_1.exists(json, 'sediEnpals') ? undefined : json['sediEnpals'].map(_1.F24LookupDtoFromJSON),
    'tributiEnpals': !runtime_1.exists(json, 'tributiEnpals') ? undefined : json['tributiEnpals'].map(_1.F24LookupDtoFromJSON),
    'tributiInpdai': !runtime_1.exists(json, 'tributiInpdai') ? undefined : json['tributiInpdai'].map(_1.F24LookupDtoFromJSON),
    'tributiInpdap': !runtime_1.exists(json, 'tributiInpdap') ? undefined : json['tributiInpdap'].map(_1.F24LookupDtoFromJSON),
    'sediTrentino': !runtime_1.exists(json, 'sediTrentino') ? undefined : json['sediTrentino'].map(_1.F24LookupDtoFromJSON),
    'tributiTrentino': !runtime_1.exists(json, 'tributiTrentino') ? undefined : json['tributiTrentino'].map(_1.F24LookupDtoFromJSON),
    'tributiConsulentiLavoro': !runtime_1.exists(json, 'tributiConsulentiLavoro') ? undefined : json['tributiConsulentiLavoro'].map(_1.F24LookupDtoFromJSON),
    'tributiEnpacl': !runtime_1.exists(json, 'tributiEnpacl') ? undefined : json['tributiEnpacl'].map(_1.F24LookupDtoFromJSON),
    'tributiEnpapi': !runtime_1.exists(json, 'tributiEnpapi') ? undefined : json['tributiEnpapi'].map(_1.F24LookupDtoFromJSON),
    'tributiEpap': !runtime_1.exists(json, 'tributiEpap') ? undefined : json['tributiEpap'].map(_1.F24LookupDtoFromJSON),
    'tributiEppi': !runtime_1.exists(json, 'tributiEppi') ? undefined : json['tributiEppi'].map(_1.F24LookupDtoFromJSON)
  };
}
exports.F24ParametriDtoFromJSONTyped = F24ParametriDtoFromJSONTyped;
function F24ParametriDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri,
    'codiciTributiErario': value.codiciTributiErario === undefined ? undefined : value.codiciTributiErario.map(_1.F24LookupDtoToJSON),
    'tributiInps': value.tributiInps === undefined ? undefined : value.tributiInps.map(_1.F24LookupDtoToJSON),
    'province': value.province === undefined ? undefined : value.province.map(_1.F24LookupDtoToJSON),
    'sediInps': value.sediInps === undefined ? undefined : value.sediInps.map(_1.F24LookupDtoToJSON),
    'codiciIdentificativiCoobbligato': value.codiciIdentificativiCoobbligato === undefined ? undefined : value.codiciIdentificativiCoobbligato.map(_1.F24LookupDtoToJSON),
    'codiciTributiRegioni': value.codiciTributiRegioni === undefined ? undefined : value.codiciTributiRegioni.map(_1.F24LookupDtoToJSON),
    'codiciTributiEntiLocali': value.codiciTributiEntiLocali === undefined ? undefined : value.codiciTributiEntiLocali.map(_1.F24LookupDtoToJSON),
    'tipiEnte': value.tipiEnte === undefined ? undefined : value.tipiEnte.map(_1.F24LookupDtoToJSON),
    'tributiAccise': value.tributiAccise === undefined ? undefined : value.tributiAccise.map(_1.F24LookupDtoToJSON),
    'sediInail': value.sediInail === undefined ? undefined : value.sediInail.map(_1.F24LookupDtoToJSON),
    'sediEnpals': value.sediEnpals === undefined ? undefined : value.sediEnpals.map(_1.F24LookupDtoToJSON),
    'tributiEnpals': value.tributiEnpals === undefined ? undefined : value.tributiEnpals.map(_1.F24LookupDtoToJSON),
    'tributiInpdai': value.tributiInpdai === undefined ? undefined : value.tributiInpdai.map(_1.F24LookupDtoToJSON),
    'tributiInpdap': value.tributiInpdap === undefined ? undefined : value.tributiInpdap.map(_1.F24LookupDtoToJSON),
    'sediTrentino': value.sediTrentino === undefined ? undefined : value.sediTrentino.map(_1.F24LookupDtoToJSON),
    'tributiTrentino': value.tributiTrentino === undefined ? undefined : value.tributiTrentino.map(_1.F24LookupDtoToJSON),
    'tributiConsulentiLavoro': value.tributiConsulentiLavoro === undefined ? undefined : value.tributiConsulentiLavoro.map(_1.F24LookupDtoToJSON),
    'tributiEnpacl': value.tributiEnpacl === undefined ? undefined : value.tributiEnpacl.map(_1.F24LookupDtoToJSON),
    'tributiEnpapi': value.tributiEnpapi === undefined ? undefined : value.tributiEnpapi.map(_1.F24LookupDtoToJSON),
    'tributiEpap': value.tributiEpap === undefined ? undefined : value.tributiEpap.map(_1.F24LookupDtoToJSON),
    'tributiEppi': value.tributiEppi === undefined ? undefined : value.tributiEppi.map(_1.F24LookupDtoToJSON)
  };
}
exports.F24ParametriDtoToJSON = F24ParametriDtoToJSON;