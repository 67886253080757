"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PctDisposizioneVirtualeDtoStatoDisposizioneEnum = exports.PctDisposizioneVirtualeDtoToJSON = exports.PctDisposizioneVirtualeDtoFromJSONTyped = exports.PctDisposizioneVirtualeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PctDisposizioneVirtualeDtoFromJSON(json) {
  return PctDisposizioneVirtualeDtoFromJSONTyped(json, false);
}
exports.PctDisposizioneVirtualeDtoFromJSON = PctDisposizioneVirtualeDtoFromJSON;
function PctDisposizioneVirtualeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : _1.DisposizioneVirtualeChiaveDtoFromJSON(json['id']),
    'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    'customData': !runtime_1.exists(json, 'customData') ? undefined : json['customData'],
    'codiceVerifica': !runtime_1.exists(json, 'codiceVerifica') ? undefined : json['codiceVerifica'],
    'codiceConfermaRecapiti': !runtime_1.exists(json, 'codiceConfermaRecapiti') ? undefined : json['codiceConfermaRecapiti'],
    'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : new Date(json['dataInserimento']),
    'codiceIstituto': !runtime_1.exists(json, 'codiceIstituto') ? undefined : json['codiceIstituto'],
    'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
    'pct': !runtime_1.exists(json, 'pct') ? undefined : _1.PctDtoFromJSON(json['pct'])
  };
}
exports.PctDisposizioneVirtualeDtoFromJSONTyped = PctDisposizioneVirtualeDtoFromJSONTyped;
function PctDisposizioneVirtualeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': _1.DisposizioneVirtualeChiaveDtoToJSON(value.id),
    'statoDisposizione': value.statoDisposizione,
    'customData': value.customData,
    'codiceVerifica': value.codiceVerifica,
    'codiceConfermaRecapiti': value.codiceConfermaRecapiti,
    'dataInserimento': value.dataInserimento === undefined ? undefined : value.dataInserimento.toISOString(),
    'codiceIstituto': value.codiceIstituto,
    'codiceUtente': value.codiceUtente,
    'pct': _1.PctDtoToJSON(value.pct)
  };
}
exports.PctDisposizioneVirtualeDtoToJSON = PctDisposizioneVirtualeDtoToJSON;
/**
* @export
* @enum {string}
*/
var PctDisposizioneVirtualeDtoStatoDisposizioneEnum;
(function (PctDisposizioneVirtualeDtoStatoDisposizioneEnum) {
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
  PctDisposizioneVirtualeDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(PctDisposizioneVirtualeDtoStatoDisposizioneEnum = exports.PctDisposizioneVirtualeDtoStatoDisposizioneEnum || (exports.PctDisposizioneVirtualeDtoStatoDisposizioneEnum = {}));