"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PNSMessageBodyToJSON = exports.PNSMessageBodyFromJSONTyped = exports.PNSMessageBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function PNSMessageBodyFromJSON(json) {
  return PNSMessageBodyFromJSONTyped(json, false);
}
exports.PNSMessageBodyFromJSON = PNSMessageBodyFromJSON;
function PNSMessageBodyFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
    'body': !runtime_1.exists(json, 'body') ? undefined : json['body'],
    'imageUrl': !runtime_1.exists(json, 'imageUrl') ? undefined : json['imageUrl'],
    'operation': json['operation'],
    'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
    'event': !runtime_1.exists(json, 'event') ? undefined : json['event'],
    'cachedKey': !runtime_1.exists(json, 'cachedKey') ? undefined : json['cachedKey']
  };
}
exports.PNSMessageBodyFromJSONTyped = PNSMessageBodyFromJSONTyped;
function PNSMessageBodyToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'title': value.title,
    'body': value.body,
    'imageUrl': value.imageUrl,
    'operation': value.operation,
    'url': value.url,
    'event': value.event,
    'cachedKey': value.cachedKey
  };
}
exports.PNSMessageBodyToJSON = PNSMessageBodyToJSON;