"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnswerDtoToJSON = exports.AnswerDtoFromJSONTyped = exports.AnswerDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AnswerDtoFromJSON(json) {
  return AnswerDtoFromJSONTyped(json, false);
}
exports.AnswerDtoFromJSON = AnswerDtoFromJSON;
function AnswerDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'dftValue': !runtime_1.exists(json, 'dftValue') ? undefined : json['dftValue'],
    'extValue': !runtime_1.exists(json, 'extValue') ? undefined : json['extValue'],
    'question': json['question'],
    'questionTableId': !runtime_1.exists(json, 'questionTableId') ? undefined : json['questionTableId'],
    'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
    'value': !runtime_1.exists(json, 'value') ? undefined : json['value']
  };
}
exports.AnswerDtoFromJSONTyped = AnswerDtoFromJSONTyped;
function AnswerDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'dftValue': value.dftValue,
    'extValue': value.extValue,
    'question': value.question,
    'questionTableId': value.questionTableId,
    'status': value.status,
    'value': value.value
  };
}
exports.AnswerDtoToJSON = AnswerDtoToJSON;