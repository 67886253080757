"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CodiceTitoliDtoToJSON = exports.CodiceTitoliDtoFromJSONTyped = exports.CodiceTitoliDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CodiceTitoliDtoFromJSON(json) {
  return CodiceTitoliDtoFromJSONTyped(json, false);
}
exports.CodiceTitoliDtoFromJSON = CodiceTitoliDtoFromJSON;
function CodiceTitoliDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
    'conto': !runtime_1.exists(json, 'conto') ? undefined : json['conto'],
    'progressivo': !runtime_1.exists(json, 'progressivo') ? undefined : json['progressivo']
  };
}
exports.CodiceTitoliDtoFromJSONTyped = CodiceTitoliDtoFromJSONTyped;
function CodiceTitoliDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'filiale': value.filiale,
    'conto': value.conto,
    'progressivo': value.progressivo
  };
}
exports.CodiceTitoliDtoToJSON = CodiceTitoliDtoToJSON;