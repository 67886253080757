"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BozzaEsteroDtoToJSON = exports.BozzaEsteroDtoFromJSONTyped = exports.BozzaEsteroDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BozzaEsteroDtoFromJSON(json) {
  return BozzaEsteroDtoFromJSONTyped(json, false);
}
exports.BozzaEsteroDtoFromJSON = BozzaEsteroDtoFromJSON;
function BozzaEsteroDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'dataUltimaModifica': new Date(json['dataUltimaModifica']),
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    'importoString': !runtime_1.exists(json, 'importoString') ? undefined : json['importoString'],
    'aliasRappporto': !runtime_1.exists(json, 'aliasRappporto') ? undefined : json['aliasRappporto']
  };
}
exports.BozzaEsteroDtoFromJSONTyped = BozzaEsteroDtoFromJSONTyped;
function BozzaEsteroDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'importo': value.importo,
    'intestazione': value.intestazione,
    'dataUltimaModifica': value.dataUltimaModifica.toISOString(),
    'descrizione': value.descrizione,
    'importoString': value.importoString,
    'aliasRappporto': value.aliasRappporto
  };
}
exports.BozzaEsteroDtoToJSON = BozzaEsteroDtoToJSON;