"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModificaMassimaliInputDtoToJSON = exports.ModificaMassimaliInputDtoFromJSONTyped = exports.ModificaMassimaliInputDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ModificaMassimaliInputDtoFromJSON(json) {
  return ModificaMassimaliInputDtoFromJSONTyped(json, false);
}
exports.ModificaMassimaliInputDtoFromJSON = ModificaMassimaliInputDtoFromJSON;
function ModificaMassimaliInputDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'idStrumento': !runtime_1.exists(json, 'idStrumento') ? undefined : json['idStrumento'],
    'idServizio': !runtime_1.exists(json, 'idServizio') ? undefined : json['idServizio'],
    'idSessioneChiamante': !runtime_1.exists(json, 'idSessioneChiamante') ? undefined : json['idSessioneChiamante'],
    'massimaleTransazione': !runtime_1.exists(json, 'massimaleTransazione') ? undefined : json['massimaleTransazione'],
    'massimaleGiornaliero': !runtime_1.exists(json, 'massimaleGiornaliero') ? undefined : json['massimaleGiornaliero'],
    'massimaleMensile': !runtime_1.exists(json, 'massimaleMensile') ? undefined : json['massimaleMensile']
  };
}
exports.ModificaMassimaliInputDtoFromJSONTyped = ModificaMassimaliInputDtoFromJSONTyped;
function ModificaMassimaliInputDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'idStrumento': value.idStrumento,
    'idServizio': value.idServizio,
    'idSessioneChiamante': value.idSessioneChiamante,
    'massimaleTransazione': value.massimaleTransazione,
    'massimaleGiornaliero': value.massimaleGiornaliero,
    'massimaleMensile': value.massimaleMensile
  };
}
exports.ModificaMassimaliInputDtoToJSON = ModificaMassimaliInputDtoToJSON;