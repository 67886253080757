"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AvvisoDtoToJSON = exports.AvvisoDtoFromJSONTyped = exports.AvvisoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AvvisoDtoFromJSON(json) {
  return AvvisoDtoFromJSONTyped(json, false);
}
exports.AvvisoDtoFromJSON = AvvisoDtoFromJSON;
function AvvisoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'dataScadenza': new Date(json['dataScadenza']),
    'numeroAvviso': json['numeroAvviso'],
    'numeroRicevuta': json['numeroRicevuta'],
    'creditore': json['creditore'],
    'motivazionePagamento': json['motivazionePagamento'],
    'importo': json['importo'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'numeroOperazione': !runtime_1.exists(json, 'numeroOperazione') ? undefined : json['numeroOperazione']
  };
}
exports.AvvisoDtoFromJSONTyped = AvvisoDtoFromJSONTyped;
function AvvisoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'dataScadenza': value.dataScadenza.toISOString(),
    'numeroAvviso': value.numeroAvviso,
    'numeroRicevuta': value.numeroRicevuta,
    'creditore': value.creditore,
    'motivazionePagamento': value.motivazionePagamento,
    'importo': value.importo,
    'codiceFiscale': value.codiceFiscale,
    'numeroOperazione': value.numeroOperazione
  };
}
exports.AvvisoDtoToJSON = AvvisoDtoToJSON;