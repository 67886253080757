"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CertificationResponseToJSON = exports.CertificationResponseFromJSONTyped = exports.CertificationResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CertificationResponseFromJSON(json) {
  return CertificationResponseFromJSONTyped(json, false);
}
exports.CertificationResponseFromJSON = CertificationResponseFromJSON;
function CertificationResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'code': !runtime_1.exists(json, 'code') ? undefined : json['code'],
    'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'keyLabel': !runtime_1.exists(json, 'keyLabel') ? undefined : json['keyLabel']
  };
}
exports.CertificationResponseFromJSONTyped = CertificationResponseFromJSONTyped;
function CertificationResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'code': value.code,
    'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
    'id': value.id,
    'keyLabel': value.keyLabel
  };
}
exports.CertificationResponseToJSON = CertificationResponseToJSON;