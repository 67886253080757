"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StampaElencoTimeDepositTypeEnum = exports.TimeDepositControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class TimeDepositControllerApi extends runtime.BaseAPI {
  /**
   * Autorizzazione di una disposizione di revoca time deposit
   */
  autorizzaRevocaTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaRevocaTimeDeposit.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaRevocaTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/timedeposit/autorizza/{idDispositiva}/revoca".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneTimeDepositDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di revoca time deposit
   */
  autorizzaRevocaTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaRevocaTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di timedeposit
   */
  autorizzaTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaTimeDeposit.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/timedeposit/autorizza/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneTimeDepositDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di timedeposit
   */
  autorizzaTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Export in pdf del dettaglio del time deposit in base ai criteri inviati per il rapporto indicato
   */
  dettaglioTimeDepositPdfRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroDettaglioTimeDepositDto === null || requestParameters.filtroDettaglioTimeDepositDto === undefined) {
        throw new runtime.RequiredError('filtroDettaglioTimeDepositDto', 'Required parameter requestParameters.filtroDettaglioTimeDepositDto was null or undefined when calling dettaglioTimeDepositPdf.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/timedeposit/export/dettaglio/pdf",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroDettaglioTimeDepositDtoToJSON(requestParameters.filtroDettaglioTimeDepositDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in pdf del dettaglio del time deposit in base ai criteri inviati per il rapporto indicato
   */
  dettaglioTimeDepositPdf(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioTimeDepositPdfRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Il metodo restituisce il dettaglio di un vincolo
   */
  getDettaglioTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
        throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling getDettaglioTimeDeposit.');
      }
      if (requestParameters.numeroOperazione === null || requestParameters.numeroOperazione === undefined) {
        throw new runtime.RequiredError('numeroOperazione', 'Required parameter requestParameters.numeroOperazione was null or undefined when calling getDettaglioTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/{codiceRapporto}/dettaglio/{numeroOperazione}".replace("{".concat("codiceRapporto", "}"), encodeURIComponent(String(requestParameters.codiceRapporto))).replace("{".concat("numeroOperazione", "}"), encodeURIComponent(String(requestParameters.numeroOperazione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TimeDepositDtoFromJSON(jsonValue));
    });
  }
  /**
   * Il metodo restituisce il dettaglio di un vincolo
   */
  getDettaglioTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getDettaglioTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Il metodo restituisce la lista dei TimeDeposit STANDARD per rapporto selezionato
   */
  getTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroTimeDepositDto === null || requestParameters.filtroTimeDepositDto === undefined) {
        throw new runtime.RequiredError('filtroTimeDepositDto', 'Required parameter requestParameters.filtroTimeDepositDto was null or undefined when calling getTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/timedeposit/lista",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroTimeDepositDtoToJSON(requestParameters.filtroTimeDepositDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TimeDepositElencoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Il metodo restituisce la lista dei TimeDeposit STANDARD per rapporto selezionato
   */
  getTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Il metodo restituisce la lista dei TimeDeposit STANDARD per rapporto selezionato
   */
  getTimeDepositSingleListRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroTimeDepositDto === null || requestParameters.filtroTimeDepositDto === undefined) {
        throw new runtime.RequiredError('filtroTimeDepositDto', 'Required parameter requestParameters.filtroTimeDepositDto was null or undefined when calling getTimeDepositSingleList.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/timedeposit/listasingola",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroTimeDepositDtoToJSON(requestParameters.filtroTimeDepositDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.TimeDepositDtoFromJSON));
    });
  }
  /**
   * Il metodo restituisce la lista dei TimeDeposit STANDARD per rapporto selezionato
   */
  getTimeDepositSingleList(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getTimeDepositSingleListRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Il metodo restituisce l\'elenco dei listini TimeDeposit con tutte le property valorizzate
   */
  getlistiniTimeDepositRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/listini",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.ListiniTimeDepositDtoFromJSON));
    });
  }
  /**
   * Il metodo restituisce l\'elenco dei listini TimeDeposit con tutte le property valorizzate
   */
  getlistiniTimeDeposit() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getlistiniTimeDepositRaw();
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneRevocaTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRevocaTimeDeposit.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/infoautenticazione/{idDispositiva}/revoca".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneRevocaTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneRevocaTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneTimeDeposit.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/infoautenticazione/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Il metodo legge i parametri applicabili ai TimeDeposit
   */
  parametriTimeDepositRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriTimeDepositDtoFromJSON(jsonValue));
    });
  }
  /**
   * Il metodo legge i parametri applicabili ai TimeDeposit
   */
  parametriTimeDeposit() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriTimeDepositRaw();
      return yield response.value();
    });
  }
  /**
   * Il metodo legge i parametri applicabili ai TimeDeposit
   */
  parametriTimeDepositBoxRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/box/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriTimeDepositDtoFromJSON(jsonValue));
    });
  }
  /**
   * Il metodo legge i parametri applicabili ai TimeDeposit
   */
  parametriTimeDepositBox() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriTimeDepositBoxRaw();
      return yield response.value();
    });
  }
  /**
   * Il metodo recupera il piano cedole per il vincolo sottoscritto in base al rapporto in input
   */
  pianoCedoleTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
        throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling pianoCedoleTimeDeposit.');
      }
      if (requestParameters.numeroOperazione === null || requestParameters.numeroOperazione === undefined) {
        throw new runtime.RequiredError('numeroOperazione', 'Required parameter requestParameters.numeroOperazione was null or undefined when calling pianoCedoleTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/{codiceRapporto}/pianocedole/{numeroOperazione}".replace("{".concat("codiceRapporto", "}"), encodeURIComponent(String(requestParameters.codiceRapporto))).replace("{".concat("numeroOperazione", "}"), encodeURIComponent(String(requestParameters.numeroOperazione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.PianoCedoleDtoFromJSON));
    });
  }
  /**
   * Il metodo recupera il piano cedole per il vincolo sottoscritto in base al rapporto in input
   */
  pianoCedoleTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.pianoCedoleTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Verifica il vincolo
   */
  simulaAperturaTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.timeDepositDto === null || requestParameters.timeDepositDto === undefined) {
        throw new runtime.RequiredError('timeDepositDto', 'Required parameter requestParameters.timeDepositDto was null or undefined when calling simulaAperturaTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/timedeposit/simula/apertura",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.TimeDepositDtoToJSON(requestParameters.timeDepositDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TimeDepositDtoFromJSON(jsonValue));
    });
  }
  /**
   * Verifica il vincolo
   */
  simulaAperturaTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.simulaAperturaTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Simula estinzione del vincolo
   */
  simulaEstinzioneTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.contoAddebito === null || requestParameters.contoAddebito === undefined) {
        throw new runtime.RequiredError('contoAddebito', 'Required parameter requestParameters.contoAddebito was null or undefined when calling simulaEstinzioneTimeDeposit.');
      }
      if (requestParameters.numeroOperazione === null || requestParameters.numeroOperazione === undefined) {
        throw new runtime.RequiredError('numeroOperazione', 'Required parameter requestParameters.numeroOperazione was null or undefined when calling simulaEstinzioneTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/{contoAddebito}/simula/estinzione/{numeroOperazione}".replace("{".concat("contoAddebito", "}"), encodeURIComponent(String(requestParameters.contoAddebito))).replace("{".concat("numeroOperazione", "}"), encodeURIComponent(String(requestParameters.numeroOperazione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TimeDepositDtoFromJSON(jsonValue));
    });
  }
  /**
   * Simula estinzione del vincolo
   */
  simulaEstinzioneTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.simulaEstinzioneTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Simula il piano cedole in base all\'id disposizione e al listino passati in input
   */
  simulaPianoCedoleTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.dispoId === null || requestParameters.dispoId === undefined) {
        throw new runtime.RequiredError('dispoId', 'Required parameter requestParameters.dispoId was null or undefined when calling simulaPianoCedoleTimeDeposit.');
      }
      if (requestParameters.listino === null || requestParameters.listino === undefined) {
        throw new runtime.RequiredError('listino', 'Required parameter requestParameters.listino was null or undefined when calling simulaPianoCedoleTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/timedeposit/simula/pianocedole/{dispoId}/{listino}".replace("{".concat("dispoId", "}"), encodeURIComponent(String(requestParameters.dispoId))).replace("{".concat("listino", "}"), encodeURIComponent(String(requestParameters.listino))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.PianoCedoleDtoFromJSON));
    });
  }
  /**
   * Simula il piano cedole in base all\'id disposizione e al listino passati in input
   */
  simulaPianoCedoleTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.simulaPianoCedoleTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Export in pdf o excel dei time deposit in base ai criteri inviati per il rapporto indicato
   */
  stampaElencoTimeDepositRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling stampaElencoTimeDeposit.');
      }
      if (requestParameters.filtroTimeDepositDto === null || requestParameters.filtroTimeDepositDto === undefined) {
        throw new runtime.RequiredError('filtroTimeDepositDto', 'Required parameter requestParameters.filtroTimeDepositDto was null or undefined when calling stampaElencoTimeDeposit.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/timedeposit/export/elenco/{type}".replace("{".concat("type", "}"), encodeURIComponent(String(requestParameters.type))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroTimeDepositDtoToJSON(requestParameters.filtroTimeDepositDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in pdf o excel dei time deposit in base ai criteri inviati per il rapporto indicato
   */
  stampaElencoTimeDeposit(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.stampaElencoTimeDepositRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.TimeDepositControllerApi = TimeDepositControllerApi;
/**
    * @export
    * @enum {string}
    */
var StampaElencoTimeDepositTypeEnum;
(function (StampaElencoTimeDepositTypeEnum) {
  StampaElencoTimeDepositTypeEnum["pdf"] = "pdf";
  StampaElencoTimeDepositTypeEnum["excel"] = "excel";
})(StampaElencoTimeDepositTypeEnum = exports.StampaElencoTimeDepositTypeEnum || (exports.StampaElencoTimeDepositTypeEnum = {}));