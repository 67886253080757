"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectCategoriaFAQ = exports.scaricaAllegatoFAQ = exports.getFAQ = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getFAQStart() {
  return {
    type: __1.EReduxActionTypes.FAQ_GET_START
  };
}
function getFAQSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.FAQ_GET_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getFAQ = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getFAQStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/faq.json')).then(faq => {
      dispatch(getFAQSuccess(faq.map(f => (0, api_rest_1.FaqWcmCategoriaDtoFromJSON)(f))));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.WcmControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getFaq().then(response => {
        dispatch(getFAQSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getFAQ = getFAQ;
/* download Allegato - START */
function scaricaAllegatoFAQStart() {
  return {
    type: __1.EReduxActionTypes.FAQ_SCARICA_ALLEGATO_START
  };
}
function scaricaAllegatoFAQSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.FAQ_SCARICA_ALLEGATO_SUCCESS,
    payload
  };
}
const scaricaAllegatoFAQ = idAllegato => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(scaricaAllegatoFAQStart());
  if (extraArguments.mock) {
    dispatch((0, general_actions_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const request = {
        idAllegato
      };
      new api_rest_1.WcmControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).downloadAllegatoFaq(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'faq.pdf', response, false);
        }
        dispatch(scaricaAllegatoFAQSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.scaricaAllegatoFAQ = scaricaAllegatoFAQ;
/* download Allegato - FINE */
function selectCategoriaFAQ(item) {
  return {
    type: __1.EReduxActionTypes.FAQ_SET_CATEGORIA_SEL,
    payload: item
  };
}
exports.selectCategoriaFAQ = selectCategoriaFAQ;