"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountRendimenti = exports.rapportiSelezionatoRendimenti = exports.rapportiRendimenti = exports.formRendimenti = exports.formRendimentiValid = void 0;
const reselect_1 = require("reselect");
const rendimenti_types_1 = require("./rendimenti.types");
const rendimentiReducer = state => state;
exports.formRendimentiValid = (0, reselect_1.createSelector)(rendimentiReducer, state => {
  return state.rapportoSelezionato.ndg && (state.form.periodo !== rendimenti_types_1.PeriodoRendimenti.free || state.form.dataDa && state.form.dataA && !state.form.dataDaError && !state.form.dataAError);
});
exports.formRendimenti = (0, reselect_1.createSelector)(rendimentiReducer, state => {
  return state.form;
});
exports.rapportiRendimenti = (0, reselect_1.createSelector)(rendimentiReducer, state => {
  return state.rapporti;
});
exports.rapportiSelezionatoRendimenti = (0, reselect_1.createSelector)(rendimentiReducer, state => {
  return state.rapportoSelezionato;
});
exports.accountRendimenti = (0, reselect_1.createSelector)(rendimentiReducer, state => {
  return state.accountRendimenti;
});