"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DocumentoContrattoDtoTipoEnum = exports.DocumentoContrattoDtoToJSON = exports.DocumentoContrattoDtoFromJSONTyped = exports.DocumentoContrattoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DocumentoContrattoDtoFromJSON(json) {
  return DocumentoContrattoDtoFromJSONTyped(json, false);
}
exports.DocumentoContrattoDtoFromJSON = DocumentoContrattoDtoFromJSON;
function DocumentoContrattoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    'contrattoBase64': !runtime_1.exists(json, 'contrattoBase64') ? undefined : json['contrattoBase64']
  };
}
exports.DocumentoContrattoDtoFromJSONTyped = DocumentoContrattoDtoFromJSONTyped;
function DocumentoContrattoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipo': value.tipo,
    'contrattoBase64': value.contrattoBase64
  };
}
exports.DocumentoContrattoDtoToJSON = DocumentoContrattoDtoToJSON;
/**
* @export
* @enum {string}
*/
var DocumentoContrattoDtoTipoEnum;
(function (DocumentoContrattoDtoTipoEnum) {
  DocumentoContrattoDtoTipoEnum["ADDENDUM_COPIA_BANCA"] = "ADDENDUM_COPIA_BANCA";
  DocumentoContrattoDtoTipoEnum["ADDENDUM_COPIA_CLIENTE"] = "ADDENDUM_COPIA_CLIENTE";
  DocumentoContrattoDtoTipoEnum["CONDIZIONI_GENERALI_SERVIZIO"] = "CONDIZIONI_GENERALI_SERVIZIO";
  DocumentoContrattoDtoTipoEnum["QUESTIONARIO_MIFID_COPIA_BANCA"] = "QUESTIONARIO_MIFID_COPIA_BANCA";
  DocumentoContrattoDtoTipoEnum["QUESTIONARIO_MIFID_COPIA_CLIENTE"] = "QUESTIONARIO_MIFID_COPIA_CLIENTE";
  DocumentoContrattoDtoTipoEnum["ATTIVAZIONE_ON_COPIA_BANCA"] = "ATTIVAZIONE_ON_COPIA_BANCA";
  DocumentoContrattoDtoTipoEnum["ATTIVAZIONE_ON_COPIA_CLIENTE"] = "ATTIVAZIONE_ON_COPIA_CLIENTE";
  DocumentoContrattoDtoTipoEnum["QUESTIONARIO_ADV_COPIA_BANCA"] = "QUESTIONARIO_ADV_COPIA_BANCA";
  DocumentoContrattoDtoTipoEnum["MODIFICA_MASSIMALI_COPIA_BANCA"] = "MODIFICA_MASSIMALI_COPIA_BANCA";
  DocumentoContrattoDtoTipoEnum["MODIFICA_MASSIMALI_COPIA_CLIENTE"] = "MODIFICA_MASSIMALI_COPIA_CLIENTE";
  DocumentoContrattoDtoTipoEnum["CARTA_NEXI"] = "CARTA_NEXI";
})(DocumentoContrattoDtoTipoEnum = exports.DocumentoContrattoDtoTipoEnum || (exports.DocumentoContrattoDtoTipoEnum = {}));