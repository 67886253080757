import { ProfiloMenu } from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  items: ProfiloMenu[] | undefined
  submenu: ProfiloMenu[] | undefined
  clickOnElement: (el: ProfiloMenu, i: number) => void
  setSelectedLvl1: (v: number | null) => void
  selectedLvl2: number | null
  setSelectedLvl2: (v: number | null) => void
}

const SidebarMenuNavigatorLvl3 = (props: Props) => {

  const { t } = useTranslation();

  const {
    items,
    submenu,
    clickOnElement,
    setSelectedLvl1,
    selectedLvl2,
    setSelectedLvl2
  } = props;

  const onElementClick = (e: any, i: number) => {
    setSelectedLvl1(null);
    clickOnElement(e, i);
  };

  const clickOnLeftArrow = () => {
    setSelectedLvl2(null);
  };

  return selectedLvl2 != null && !!submenu?.[selectedLvl2].label
    ? (
      <div className="menu-window menu-window-LVL3">

        <div className="menu-window-header">
          <BankIcon
            title="freccia-sinistra"
            className="frecciaSinistra"
            width={44}
            height={44}
            onClick={clickOnLeftArrow}
          />
          <span
            onClick={clickOnLeftArrow}
          >
            {t(submenu[selectedLvl2].label!)}
          </span>
        </div>

        <div className="menu-window-body">
          {items &&
            items.map((e: ProfiloMenu, i: number) => {
              return (
                <span
                  id={e.id || ""}
                  className="menu-window-element icone-azioni"
                  onClick={() => onElementClick(e, i)}
                  key={i}
                >

                  <BankIcon
                    title={e.id || "notFound"}
                    active
                  />

                  <div className="menu-element-label">
                    {t(e.label || "notFound")}
                  </div>

                </span>
              );
            })}
        </div>

      </div>
    )
    : null;
};

export default SidebarMenuNavigatorLvl3;
