"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuietanzaDtoToJSON = exports.QuietanzaDtoFromJSONTyped = exports.QuietanzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function QuietanzaDtoFromJSON(json) {
  return QuietanzaDtoFromJSONTyped(json, false);
}
exports.QuietanzaDtoFromJSON = QuietanzaDtoFromJSON;
function QuietanzaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codUffPostale': !runtime_1.exists(json, 'codUffPostale') ? undefined : json['codUffPostale'],
    'sezUffPostale': !runtime_1.exists(json, 'sezUffPostale') ? undefined : json['sezUffPostale'],
    'uffVirtuale': !runtime_1.exists(json, 'uffVirtuale') ? undefined : json['uffVirtuale'],
    'codStruPag': !runtime_1.exists(json, 'codStruPag') ? undefined : json['codStruPag'],
    'dataAccettazione': !runtime_1.exists(json, 'dataAccettazione') ? undefined : new Date(json['dataAccettazione']),
    'progIncasso': !runtime_1.exists(json, 'progIncasso') ? undefined : json['progIncasso'],
    'residuo': !runtime_1.exists(json, 'residuo') ? undefined : json['residuo']
  };
}
exports.QuietanzaDtoFromJSONTyped = QuietanzaDtoFromJSONTyped;
function QuietanzaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codUffPostale': value.codUffPostale,
    'sezUffPostale': value.sezUffPostale,
    'uffVirtuale': value.uffVirtuale,
    'codStruPag': value.codStruPag,
    'dataAccettazione': value.dataAccettazione === undefined ? undefined : value.dataAccettazione.toISOString(),
    'progIncasso': value.progIncasso,
    'residuo': value.residuo
  };
}
exports.QuietanzaDtoToJSON = QuietanzaDtoToJSON;