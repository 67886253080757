"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContattoIbanDtoToJSON = exports.ContattoIbanDtoFromJSONTyped = exports.ContattoIbanDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContattoIbanDtoFromJSON(json) {
  return ContattoIbanDtoFromJSONTyped(json, false);
}
exports.ContattoIbanDtoFromJSON = ContattoIbanDtoFromJSON;
function ContattoIbanDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'seq': !runtime_1.exists(json, 'seq') ? undefined : json['seq'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'ibanFormattato': !runtime_1.exists(json, 'ibanFormattato') ? undefined : json['ibanFormattato'],
    'ibanAlias': !runtime_1.exists(json, 'ibanAlias') ? undefined : json['ibanAlias'],
    'bic': !runtime_1.exists(json, 'bic') ? undefined : json['bic'],
    'etichetta': !runtime_1.exists(json, 'etichetta') ? undefined : json['etichetta'],
    'dispensaCredenziale': !runtime_1.exists(json, 'dispensaCredenziale') ? undefined : json['dispensaCredenziale'],
    'isDefault': !runtime_1.exists(json, 'isDefault') ? undefined : json['isDefault']
  };
}
exports.ContattoIbanDtoFromJSONTyped = ContattoIbanDtoFromJSONTyped;
function ContattoIbanDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'seq': value.seq,
    'iban': value.iban,
    'ibanFormattato': value.ibanFormattato,
    'ibanAlias': value.ibanAlias,
    'bic': value.bic,
    'etichetta': value.etichetta,
    'dispensaCredenziale': value.dispensaCredenziale,
    'isDefault': value.isDefault
  };
}
exports.ContattoIbanDtoToJSON = ContattoIbanDtoToJSON;