"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * PNS Client API
 * PNS - Push Notification Service: Api per l\'invio di notifiche
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PNSSubscriptionDataPlatformEnum = exports.PNSSubscriptionDataToJSON = exports.PNSSubscriptionDataFromJSONTyped = exports.PNSSubscriptionDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PNSSubscriptionDataFromJSON(json) {
  return PNSSubscriptionDataFromJSONTyped(json, false);
}
exports.PNSSubscriptionDataFromJSON = PNSSubscriptionDataFromJSON;
function PNSSubscriptionDataFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'userId': json['userId'],
    'deviceId': json['deviceId'],
    'platform': json['platform'],
    'registrationData': !runtime_1.exists(json, 'registrationData') ? undefined : _1.PNSPlatformRegistrationFromJSON(json['registrationData']),
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale']
  };
}
exports.PNSSubscriptionDataFromJSONTyped = PNSSubscriptionDataFromJSONTyped;
function PNSSubscriptionDataToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'userId': value.userId,
    'deviceId': value.deviceId,
    'platform': value.platform,
    'registrationData': _1.PNSPlatformRegistrationToJSON(value.registrationData),
    'codiceFiscale': value.codiceFiscale
  };
}
exports.PNSSubscriptionDataToJSON = PNSSubscriptionDataToJSON;
/**
* @export
* @enum {string}
*/
var PNSSubscriptionDataPlatformEnum;
(function (PNSSubscriptionDataPlatformEnum) {
  PNSSubscriptionDataPlatformEnum["Fcm"] = "fcm";
  PNSSubscriptionDataPlatformEnum["Apn"] = "apn";
  PNSSubscriptionDataPlatformEnum["Google"] = "google";
})(PNSSubscriptionDataPlatformEnum = exports.PNSSubscriptionDataPlatformEnum || (exports.PNSSubscriptionDataPlatformEnum = {}));