"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FaqWcmCategoriaDtoToJSON = exports.FaqWcmCategoriaDtoFromJSONTyped = exports.FaqWcmCategoriaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FaqWcmCategoriaDtoFromJSON(json) {
  return FaqWcmCategoriaDtoFromJSONTyped(json, false);
}
exports.FaqWcmCategoriaDtoFromJSON = FaqWcmCategoriaDtoFromJSON;
function FaqWcmCategoriaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceFunzione': !runtime_1.exists(json, 'codiceFunzione') ? undefined : json['codiceFunzione'],
    'label': !runtime_1.exists(json, 'label') ? undefined : json['label'],
    'faqs': !runtime_1.exists(json, 'faqs') ? undefined : json['faqs'].map(_1.FaqWcmDtoFromJSON)
  };
}
exports.FaqWcmCategoriaDtoFromJSONTyped = FaqWcmCategoriaDtoFromJSONTyped;
function FaqWcmCategoriaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceFunzione': value.codiceFunzione,
    'label': value.label,
    'faqs': value.faqs === undefined ? undefined : value.faqs.map(_1.FaqWcmDtoToJSON)
  };
}
exports.FaqWcmCategoriaDtoToJSON = FaqWcmCategoriaDtoToJSON;