"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PagamentoTributiDtoToJSON = exports.PagamentoTributiDtoFromJSONTyped = exports.PagamentoTributiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PagamentoTributiDtoFromJSON(json) {
  return PagamentoTributiDtoFromJSONTyped(json, false);
}
exports.PagamentoTributiDtoFromJSON = PagamentoTributiDtoFromJSON;
function PagamentoTributiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'beneficiario': !runtime_1.exists(json, 'beneficiario') ? undefined : json['beneficiario'],
    'bic': !runtime_1.exists(json, 'bic') ? undefined : json['bic'],
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'categoria': !runtime_1.exists(json, 'categoria') ? undefined : json['categoria'],
    'cinComplessivoFreccia': !runtime_1.exists(json, 'cinComplessivoFreccia') ? undefined : json['cinComplessivoFreccia'],
    'cinImportoFreccia': !runtime_1.exists(json, 'cinImportoFreccia') ? undefined : json['cinImportoFreccia'],
    'cinIntermedioFreccia': !runtime_1.exists(json, 'cinIntermedioFreccia') ? undefined : json['cinIntermedioFreccia'],
    'codEsenzioneFreccia': !runtime_1.exists(json, 'codEsenzioneFreccia') ? undefined : json['codEsenzioneFreccia'],
    'codFiscaleF': !runtime_1.exists(json, 'codFiscaleF') ? undefined : json['codFiscaleF'],
    'codFiscaleG': !runtime_1.exists(json, 'codFiscaleG') ? undefined : json['codFiscaleG'],
    'idFreccia': !runtime_1.exists(json, 'idFreccia') ? undefined : json['idFreccia'],
    'codPagamento': !runtime_1.exists(json, 'codPagamento') ? undefined : json['codPagamento'],
    'codRiferimento': !runtime_1.exists(json, 'codRiferimento') ? undefined : json['codRiferimento'],
    'codSia': !runtime_1.exists(json, 'codSia') ? undefined : json['codSia'],
    'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
    'dataNascita': !runtime_1.exists(json, 'dataNascita') ? undefined : new Date(json['dataNascita']),
    'dataPagamento': !runtime_1.exists(json, 'dataPagamento') ? undefined : new Date(json['dataPagamento']),
    'cro': !runtime_1.exists(json, 'cro') ? undefined : json['cro'],
    'nomeDitta': !runtime_1.exists(json, 'nomeDitta') ? undefined : json['nomeDitta'],
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    'descrizioneEstesa': !runtime_1.exists(json, 'descrizioneEstesa') ? undefined : json['descrizioneEstesa'],
    'descrizioneScaduto': !runtime_1.exists(json, 'descrizioneScaduto') ? undefined : json['descrizioneScaduto'],
    'guid': !runtime_1.exists(json, 'guid') ? undefined : json['guid'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
    'scadenza': !runtime_1.exists(json, 'scadenza') ? undefined : new Date(json['scadenza']),
    'tipoDebitore': !runtime_1.exists(json, 'tipoDebitore') ? undefined : json['tipoDebitore'],
    'tipoPagamento': !runtime_1.exists(json, 'tipoPagamento') ? undefined : json['tipoPagamento'],
    'terminePrimo': !runtime_1.exists(json, 'terminePrimo') ? undefined : new Date(json['terminePrimo']),
    'termineUltimo': !runtime_1.exists(json, 'termineUltimo') ? undefined : new Date(json['termineUltimo']),
    'urlScaduto': !runtime_1.exists(json, 'urlScaduto') ? undefined : json['urlScaduto'],
    'f24': !runtime_1.exists(json, 'f24') ? undefined : json['f24'],
    'luogoNascita': !runtime_1.exists(json, 'luogoNascita') ? undefined : json['luogoNascita'],
    'provinciaNascita': !runtime_1.exists(json, 'provinciaNascita') ? undefined : json['provinciaNascita'],
    'sesso': !runtime_1.exists(json, 'sesso') ? undefined : json['sesso']
  };
}
exports.PagamentoTributiDtoFromJSONTyped = PagamentoTributiDtoFromJSONTyped;
function PagamentoTributiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'beneficiario': value.beneficiario,
    'bic': value.bic,
    'causale': value.causale,
    'categoria': value.categoria,
    'cinComplessivoFreccia': value.cinComplessivoFreccia,
    'cinImportoFreccia': value.cinImportoFreccia,
    'cinIntermedioFreccia': value.cinIntermedioFreccia,
    'codEsenzioneFreccia': value.codEsenzioneFreccia,
    'codFiscaleF': value.codFiscaleF,
    'codFiscaleG': value.codFiscaleG,
    'idFreccia': value.idFreccia,
    'codPagamento': value.codPagamento,
    'codRiferimento': value.codRiferimento,
    'codSia': value.codSia,
    'cognome': value.cognome,
    'dataNascita': value.dataNascita === undefined ? undefined : value.dataNascita.toISOString(),
    'dataPagamento': value.dataPagamento === undefined ? undefined : value.dataPagamento.toISOString(),
    'cro': value.cro,
    'nomeDitta': value.nomeDitta,
    'descrizione': value.descrizione,
    'descrizioneEstesa': value.descrizioneEstesa,
    'descrizioneScaduto': value.descrizioneScaduto,
    'guid': value.guid,
    'iban': value.iban,
    'importo': value.importo,
    'nome': value.nome,
    'scadenza': value.scadenza === undefined ? undefined : value.scadenza.toISOString(),
    'tipoDebitore': value.tipoDebitore,
    'tipoPagamento': value.tipoPagamento,
    'terminePrimo': value.terminePrimo === undefined ? undefined : value.terminePrimo.toISOString(),
    'termineUltimo': value.termineUltimo === undefined ? undefined : value.termineUltimo.toISOString(),
    'urlScaduto': value.urlScaduto,
    'f24': value.f24,
    'luogoNascita': value.luogoNascita,
    'provinciaNascita': value.provinciaNascita,
    'sesso': value.sesso
  };
}
exports.PagamentoTributiDtoToJSON = PagamentoTributiDtoToJSON;