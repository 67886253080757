"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnnulloMyBankDtoToJSON = exports.AnnulloMyBankDtoFromJSONTyped = exports.AnnulloMyBankDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AnnulloMyBankDtoFromJSON(json) {
  return AnnulloMyBankDtoFromJSONTyped(json, false);
}
exports.AnnulloMyBankDtoFromJSON = AnnulloMyBankDtoFromJSON;
function AnnulloMyBankDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'urlRedirect': !runtime_1.exists(json, 'urlRedirect') ? undefined : json['urlRedirect']
  };
}
exports.AnnulloMyBankDtoFromJSONTyped = AnnulloMyBankDtoFromJSONTyped;
function AnnulloMyBankDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'urlRedirect': value.urlRedirect
  };
}
exports.AnnulloMyBankDtoToJSON = AnnulloMyBankDtoToJSON;