"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnrollmentStatusInDtoToJSON = exports.EnrollmentStatusInDtoFromJSONTyped = exports.EnrollmentStatusInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EnrollmentStatusInDtoFromJSON(json) {
  return EnrollmentStatusInDtoFromJSONTyped(json, false);
}
exports.EnrollmentStatusInDtoFromJSON = EnrollmentStatusInDtoFromJSON;
function EnrollmentStatusInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'providerId': !runtime_1.exists(json, 'providerId') ? undefined : json['providerId'],
    'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
    'aspspProductCode': !runtime_1.exists(json, 'aspspProductCode') ? undefined : json['aspspProductCode'],
    'activeConsent': !runtime_1.exists(json, 'activeConsent') ? undefined : json['activeConsent'],
    'activeConsentFlag': !runtime_1.exists(json, 'activeConsentFlag') ? undefined : json['activeConsentFlag'],
    'consentId': !runtime_1.exists(json, 'consentId') ? undefined : json['consentId'],
    'consentType': !runtime_1.exists(json, 'consentType') ? undefined : _1.ConsentTypeFromJSON(json['consentType']),
    'ibanList': !runtime_1.exists(json, 'ibanList') ? undefined : json['ibanList'],
    'consentStatus': !runtime_1.exists(json, 'consentStatus') ? undefined : json['consentStatus']
  };
}
exports.EnrollmentStatusInDtoFromJSONTyped = EnrollmentStatusInDtoFromJSONTyped;
function EnrollmentStatusInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'providerId': value.providerId,
    'aspspId': value.aspspId,
    'aspspProductCode': value.aspspProductCode,
    'activeConsent': value.activeConsent,
    'activeConsentFlag': value.activeConsentFlag,
    'consentId': value.consentId,
    'consentType': _1.ConsentTypeToJSON(value.consentType),
    'ibanList': value.ibanList,
    'consentStatus': value.consentStatus
  };
}
exports.EnrollmentStatusInDtoToJSON = EnrollmentStatusInDtoToJSON;