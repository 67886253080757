"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatoModificaMassimaleOutDtoStatoEnum = exports.StatoModificaMassimaleOutDtoToJSON = exports.StatoModificaMassimaleOutDtoFromJSONTyped = exports.StatoModificaMassimaleOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function StatoModificaMassimaleOutDtoFromJSON(json) {
  return StatoModificaMassimaleOutDtoFromJSONTyped(json, false);
}
exports.StatoModificaMassimaleOutDtoFromJSON = StatoModificaMassimaleOutDtoFromJSON;
function StatoModificaMassimaleOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato']
  };
}
exports.StatoModificaMassimaleOutDtoFromJSONTyped = StatoModificaMassimaleOutDtoFromJSONTyped;
function StatoModificaMassimaleOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato
  };
}
exports.StatoModificaMassimaleOutDtoToJSON = StatoModificaMassimaleOutDtoToJSON;
/**
* @export
* @enum {string}
*/
var StatoModificaMassimaleOutDtoStatoEnum;
(function (StatoModificaMassimaleOutDtoStatoEnum) {
  StatoModificaMassimaleOutDtoStatoEnum["NON_RICHIESTA"] = "NON_RICHIESTA";
  StatoModificaMassimaleOutDtoStatoEnum["IN_ATTESA_DI_FIRMA"] = "IN_ATTESA_DI_FIRMA";
  StatoModificaMassimaleOutDtoStatoEnum["IN_ATTESA_DI_FIRMA_NO_STANZA"] = "IN_ATTESA_DI_FIRMA_NO_STANZA";
  StatoModificaMassimaleOutDtoStatoEnum["OK"] = "OK";
  StatoModificaMassimaleOutDtoStatoEnum["ERRORE_RECUPERO_STATO"] = "ERRORE_RECUPERO_STATO";
})(StatoModificaMassimaleOutDtoStatoEnum = exports.StatoModificaMassimaleOutDtoStatoEnum || (exports.StatoModificaMassimaleOutDtoStatoEnum = {}));