"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FiltroListaPagamentiDtoToJSON = exports.FiltroListaPagamentiDtoFromJSONTyped = exports.FiltroListaPagamentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroListaPagamentiDtoFromJSON(json) {
  return FiltroListaPagamentiDtoFromJSONTyped(json, false);
}
exports.FiltroListaPagamentiDtoFromJSON = FiltroListaPagamentiDtoFromJSON;
function FiltroListaPagamentiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': json['codiceRapporto'],
    'dataDa': new Date(json['dataDa']),
    'dataA': new Date(json['dataA']),
    'importoDa': !runtime_1.exists(json, 'importoDa') ? undefined : json['importoDa'],
    'importoA': !runtime_1.exists(json, 'importoA') ? undefined : json['importoA'],
    'tipologie': !runtime_1.exists(json, 'tipologie') ? undefined : json['tipologie'],
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'paroleChiave': !runtime_1.exists(json, 'paroleChiave') ? undefined : json['paroleChiave'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'rapportoEstinto': !runtime_1.exists(json, 'rapportoEstinto') ? undefined : json['rapportoEstinto'],
    'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    'ibanRapportoEstinto': !runtime_1.exists(json, 'ibanRapportoEstinto') ? undefined : json['ibanRapportoEstinto']
  };
}
exports.FiltroListaPagamentiDtoFromJSONTyped = FiltroListaPagamentiDtoFromJSONTyped;
function FiltroListaPagamentiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'dataDa': value.dataDa.toISOString(),
    'dataA': value.dataA.toISOString(),
    'importoDa': value.importoDa,
    'importoA': value.importoA,
    'tipologie': value.tipologie,
    'stato': value.stato,
    'paroleChiave': value.paroleChiave,
    'codiceFiscale': value.codiceFiscale,
    'rapportoEstinto': value.rapportoEstinto,
    'offset': value.offset,
    'ibanRapportoEstinto': value.ibanRapportoEstinto
  };
}
exports.FiltroListaPagamentiDtoToJSON = FiltroListaPagamentiDtoToJSON;