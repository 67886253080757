"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RigaSezioneEntiPrevidenzialiDtoToJSON = exports.RigaSezioneEntiPrevidenzialiDtoFromJSONTyped = exports.RigaSezioneEntiPrevidenzialiDtoFromJSON = void 0;
function RigaSezioneEntiPrevidenzialiDtoFromJSON(json) {
  return RigaSezioneEntiPrevidenzialiDtoFromJSONTyped(json, false);
}
exports.RigaSezioneEntiPrevidenzialiDtoFromJSON = RigaSezioneEntiPrevidenzialiDtoFromJSON;
function RigaSezioneEntiPrevidenzialiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceSede': json['codiceSede'],
    'causale': json['causale'],
    'codicePosizione': json['codicePosizione'],
    'periodoDaAnno': json['periodoDaAnno'],
    'periodoAAnno': json['periodoAAnno'],
    'periodoDaMese': json['periodoDaMese'],
    'periodoAMese': json['periodoAMese'],
    'importoDebito': json['importoDebito'],
    'importoCredito': json['importoCredito']
  };
}
exports.RigaSezioneEntiPrevidenzialiDtoFromJSONTyped = RigaSezioneEntiPrevidenzialiDtoFromJSONTyped;
function RigaSezioneEntiPrevidenzialiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceSede': value.codiceSede,
    'causale': value.causale,
    'codicePosizione': value.codicePosizione,
    'periodoDaAnno': value.periodoDaAnno,
    'periodoAAnno': value.periodoAAnno,
    'periodoDaMese': value.periodoDaMese,
    'periodoAMese': value.periodoAMese,
    'importoDebito': value.importoDebito,
    'importoCredito': value.importoCredito
  };
}
exports.RigaSezioneEntiPrevidenzialiDtoToJSON = RigaSezioneEntiPrevidenzialiDtoToJSON;