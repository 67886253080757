"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPolizzeResetPolizzaSelezionata = exports.setPolizzeSetPolizzaSelezionata = exports.getPolizzeInvestimento = exports.getPolizzeAssicurazione = exports.esportaPDFDettaglioPolizzaInvestimento = exports.esportaPDFDettaglioPolizzaProtection = exports.setPolizzeSetRapportoSelezionato = exports.getDettaglioPolizza = exports.getParametriPolizze = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const polizze_selectors_1 = require("./polizze.selectors");
function getParametriPolizzeStart() {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_PARAMETRI_START
  };
}
function getParametriPolizzeSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getParametriPolizze = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriPolizzeStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssicurazioneControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriAssicurazioni().then(response => {
        dispatch(getParametriPolizzeSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriPolizze = getParametriPolizze;
function getDettaglioPolizzaStart() {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_DETTAGLIO_START
  };
}
function getDettaglioPolizzaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_DETTAGLIO_SUCCESS,
    payload
  };
}
const getDettaglioPolizza = () => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDettaglioPolizzaStart());
  if (extraArguments.mock) {} else {
    const requestParameters = {
      codiceRapporto: ((_a = (0, polizze_selectors_1.formPolizze)(getState().polizze).rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id) || 0
    };
    requestParameters.codiceRapporto && extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssicurazioneControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDettaglioAssicurazioni(requestParameters).then(response => {
        dispatch(getDettaglioPolizzaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDettaglioPolizza = getDettaglioPolizza;
function setPolizzeSetRapportoSelezionato(payload) {
  return {
    type: __1.EReduxActionTypes.POLIZZE_SET_RAPPORTO_SELEZIONATO,
    payload: payload
  };
}
exports.setPolizzeSetRapportoSelezionato = setPolizzeSetRapportoSelezionato;
/* esporta dettaglio PDF - START */
function esportaPDFDettaglioPolizzaStart() {
  return {
    type: __1.EReduxActionTypes.POLIZZE_ESPORTA_PDF_DETTAGLIO_START
  };
}
function esportaPDFDettaglioPolizzaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.POLIZZE_ESPORTA_PDF_DETTAGLIO_SUCCESS,
    payload
  };
}
const esportaPDFDettaglioPolizzaProtection = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
  const grafico = getState().polizze.form.polizzaSelezionata.ramo === 'F';
  esportaPDFDettaglioPolizzaAction(share, title, message, filtro, grafico)(dispatch, getState, extraArguments);
};
exports.esportaPDFDettaglioPolizzaProtection = esportaPDFDettaglioPolizzaProtection;
const esportaPDFDettaglioPolizzaInvestimento = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
  esportaPDFDettaglioPolizzaAction(share, title, message, filtro, true)(dispatch, getState, extraArguments);
};
exports.esportaPDFDettaglioPolizzaInvestimento = esportaPDFDettaglioPolizzaInvestimento;
const esportaPDFDettaglioPolizzaAction = (share, title, message, filtro, visualizzaGrafico) => async (dispatch, getState, extraArguments) => {
  dispatch(esportaPDFDettaglioPolizzaStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {} else {
    const assicurazioneDto = filtro || getState().polizze.form.polizzaSelezionata;
    const request = {
      assicurazioneDto,
      visualizzaGrafico
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssicurazioneControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportDettaglioPolizza(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'dettaglio.pdf', response, share, title, message);
        }
        dispatch(esportaPDFDettaglioPolizzaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
/* esporta dettaglio PDF  - FINE */
/* get lista polizze assicurazione - start */
function getPolizzeAssicurazioneStart() {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_POLIZZE_ASSICURAZIONE_START
  };
}
function getPolizzeAssicurazioneSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_POLIZZE_ASSICURAZIONE_SUCCESS,
    payload
  };
}
const getPolizzeAssicurazione = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getPolizzeAssicurazioneStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssicurazioneControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getAndFilterAssicurazioni().then(response => {
        dispatch(getPolizzeAssicurazioneSuccess(response));
        response.length === 0 && dispatch((0, general_actions_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.POLIZZE_ASSICURAZIONI
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getPolizzeAssicurazione = getPolizzeAssicurazione;
/* get lista polizze assicurazione - end */
/* get lista polizze investimento - start */
function getPolizzeInvestimentoStart() {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_POLIZZE_INVESTIMENTO_START
  };
}
function getPolizzeInvestimentoSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.POLIZZE_GET_POLIZZE_INVESTIMENTO_SUCCESS,
    payload
  };
}
const getPolizzeInvestimento = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getPolizzeInvestimentoStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssicurazioneControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getAndFilterPolizzeInvestimento().then(response => {
        dispatch(getPolizzeInvestimentoSuccess(response));
        response.length === 0 && dispatch((0, general_actions_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.POLIZZE_INVESTIMENTI
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getPolizzeInvestimento = getPolizzeInvestimento;
/* get lista polizze investimento - end */
function setPolizzeSetPolizzaSelezionata(payload) {
  return {
    type: __1.EReduxActionTypes.POLIZZE_SET_POLIZZA_SELEZIONATA,
    payload
  };
}
exports.setPolizzeSetPolizzaSelezionata = setPolizzeSetPolizzaSelezionata;
function setPolizzeResetPolizzaSelezionata() {
  return {
    type: __1.EReduxActionTypes.POLIZZE_RESET_POLIZZA_SELEZIONATA
  };
}
exports.setPolizzeResetPolizzaSelezionata = setPolizzeResetPolizzaSelezionata;