"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PosizioneFinanziamentiDtoToJSON = exports.PosizioneFinanziamentiDtoFromJSONTyped = exports.PosizioneFinanziamentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PosizioneFinanziamentiDtoFromJSON(json) {
  return PosizioneFinanziamentiDtoFromJSONTyped(json, false);
}
exports.PosizioneFinanziamentiDtoFromJSON = PosizioneFinanziamentiDtoFromJSON;
function PosizioneFinanziamentiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'saldoContabileVisibile': !runtime_1.exists(json, 'saldoContabileVisibile') ? undefined : json['saldoContabileVisibile'],
    'saldoContabile': !runtime_1.exists(json, 'saldoContabile') ? undefined : json['saldoContabile'],
    'saldoDisponibileVisibile': !runtime_1.exists(json, 'saldoDisponibileVisibile') ? undefined : json['saldoDisponibileVisibile'],
    'saldoDisponibile': !runtime_1.exists(json, 'saldoDisponibile') ? undefined : json['saldoDisponibile'],
    'saldoCapitaleResiduoVisibile': !runtime_1.exists(json, 'saldoCapitaleResiduoVisibile') ? undefined : json['saldoCapitaleResiduoVisibile'],
    'saldoCapitaleResiduo': !runtime_1.exists(json, 'saldoCapitaleResiduo') ? undefined : json['saldoCapitaleResiduo'],
    'saldoImportoStipulatoVisibile': !runtime_1.exists(json, 'saldoImportoStipulatoVisibile') ? undefined : json['saldoImportoStipulatoVisibile'],
    'saldoImportoStipulato': !runtime_1.exists(json, 'saldoImportoStipulato') ? undefined : json['saldoImportoStipulato'],
    'finanziamentiContoList': !runtime_1.exists(json, 'finanziamentiContoList') ? undefined : json['finanziamentiContoList'].map(_1.FinanziamentiContoDtoFromJSON),
    'finanziamentiContoListVisibile': !runtime_1.exists(json, 'finanziamentiContoListVisibile') ? undefined : json['finanziamentiContoListVisibile'],
    'totaleSaldoCapitaleResiduo': !runtime_1.exists(json, 'totaleSaldoCapitaleResiduo') ? undefined : json['totaleSaldoCapitaleResiduo'],
    'totaleSaldoContabile': !runtime_1.exists(json, 'totaleSaldoContabile') ? undefined : json['totaleSaldoContabile']
  };
}
exports.PosizioneFinanziamentiDtoFromJSONTyped = PosizioneFinanziamentiDtoFromJSONTyped;
function PosizioneFinanziamentiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipologia': value.tipologia,
    'divisa': value.divisa,
    'saldoContabileVisibile': value.saldoContabileVisibile,
    'saldoContabile': value.saldoContabile,
    'saldoDisponibileVisibile': value.saldoDisponibileVisibile,
    'saldoDisponibile': value.saldoDisponibile,
    'saldoCapitaleResiduoVisibile': value.saldoCapitaleResiduoVisibile,
    'saldoCapitaleResiduo': value.saldoCapitaleResiduo,
    'saldoImportoStipulatoVisibile': value.saldoImportoStipulatoVisibile,
    'saldoImportoStipulato': value.saldoImportoStipulato,
    'finanziamentiContoList': value.finanziamentiContoList === undefined ? undefined : value.finanziamentiContoList.map(_1.FinanziamentiContoDtoToJSON),
    'finanziamentiContoListVisibile': value.finanziamentiContoListVisibile,
    'totaleSaldoCapitaleResiduo': value.totaleSaldoCapitaleResiduo,
    'totaleSaldoContabile': value.totaleSaldoContabile
  };
}
exports.PosizioneFinanziamentiDtoToJSON = PosizioneFinanziamentiDtoToJSON;