"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PosizioneLiquiditaDtoToJSON = exports.PosizioneLiquiditaDtoFromJSONTyped = exports.PosizioneLiquiditaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PosizioneLiquiditaDtoFromJSON(json) {
  return PosizioneLiquiditaDtoFromJSONTyped(json, false);
}
exports.PosizioneLiquiditaDtoFromJSON = PosizioneLiquiditaDtoFromJSON;
function PosizioneLiquiditaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
    'tipologiaDesc': !runtime_1.exists(json, 'tipologiaDesc') ? undefined : json['tipologiaDesc'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'saldoVisibile': !runtime_1.exists(json, 'saldoVisibile') ? undefined : json['saldoVisibile'],
    'saldo': !runtime_1.exists(json, 'saldo') ? undefined : json['saldo'],
    'saldoContabileVisibile': !runtime_1.exists(json, 'saldoContabileVisibile') ? undefined : json['saldoContabileVisibile'],
    'saldoDisponibileVisibile': !runtime_1.exists(json, 'saldoDisponibileVisibile') ? undefined : json['saldoDisponibileVisibile'],
    'liquiditaContiListVisibile': !runtime_1.exists(json, 'liquiditaContiListVisibile') ? undefined : json['liquiditaContiListVisibile'],
    'liquiditaContiList': !runtime_1.exists(json, 'liquiditaContiList') ? undefined : json['liquiditaContiList'].map(_1.LiquiditaContoDtoFromJSON),
    'totaleSaldoContabile': !runtime_1.exists(json, 'totaleSaldoContabile') ? undefined : json['totaleSaldoContabile']
  };
}
exports.PosizioneLiquiditaDtoFromJSONTyped = PosizioneLiquiditaDtoFromJSONTyped;
function PosizioneLiquiditaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipologia': value.tipologia,
    'tipologiaDesc': value.tipologiaDesc,
    'divisa': value.divisa,
    'saldoVisibile': value.saldoVisibile,
    'saldo': value.saldo,
    'saldoContabileVisibile': value.saldoContabileVisibile,
    'saldoDisponibileVisibile': value.saldoDisponibileVisibile,
    'liquiditaContiListVisibile': value.liquiditaContiListVisibile,
    'liquiditaContiList': value.liquiditaContiList === undefined ? undefined : value.liquiditaContiList.map(_1.LiquiditaContoDtoToJSON),
    'totaleSaldoContabile': value.totaleSaldoContabile
  };
}
exports.PosizioneLiquiditaDtoToJSON = PosizioneLiquiditaDtoToJSON;