import { GetAccettazioniFunzioneEnum as CodiceFunzione, getTour, profiloTour, setHiddenTourHashes } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import CarouselCustom from "component/carouselCustom/carouselCustom.component";
import { getCurrentPathAlias } from "component/history/history.component";
import BankIcon from "component/Icon/icon.component";
import InputBox from "component/inputBox/inputBox.component";
import { fireWindowResize } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

export const codiciFunzioneAbilitatiAlTourContestuale: Array<CodiceFunzione | string> = [
  'HOME',
  'RENDIMENTI',
  'DOSSIER',
  'POLIZZE',
  'RUBRICA',
  CodiceFunzione.ELENCOPAGAMENTI,
  CodiceFunzione.FIRMADIGITALE
];

const TourContestuale: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    getTour,
    setHiddenTourHashes,
    profiloTour,
    funzionalita,
  } = props;

  const [visible, setVisible] = useState(false);
  const [_funzionalita, setFunzionalita] = useState("");
  const [nonVisualizzare, setNonVisualizzare] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const tours = profiloTour.tour?.[_funzionalita || ""] || undefined;

  useEffect(() => {
    if (profiloTour.hiddenHashes === undefined) {
      recuperaHiddenTourHashes();
    }
  }, []);

  useEffect(() => {
    const funcByPath = getCurrentPathAlias();
    setFunzionalita(funzionalita || funcByPath?.toUpperCase() || "")
  }, [funzionalita]);

  useEffect(() => {
    setSlideIndex(0);
    if (visible) {
      if (profiloTour.hiddenHashes === undefined) {
        recuperaHiddenTourHashes();
      }
    }
  }, [visible]);

  useEffect(() => {
    if (codiciFunzioneAbilitatiAlTourContestuale.indexOf(_funzionalita) > -1) {
      if (_funzionalita && profiloTour.tour?.[_funzionalita || ""] === undefined) {
        getTour(_funzionalita);
      } else if (_funzionalita && profiloTour.tour?.[_funzionalita || ""]?.elementi?.length) {
        const _nonVisualizzare = !!profiloTour.hiddenHashes?.includes(profiloTour.tour?.[_funzionalita || ""]?.codiceTour || "");
        setNonVisualizzare(_nonVisualizzare);
        if (!_nonVisualizzare) {
          setVisible(true);
          fireWindowResize();
        }
      }
    }
  }, [profiloTour.tour, _funzionalita]);

  useEffect(() => {
    profiloTour.hiddenHashes !== undefined && salvaHiddenTourHashes();
  }, [profiloTour.hiddenHashes]);

  const recuperaHiddenTourHashes = () => {
    if (profiloTour.hiddenHashes === undefined) {
      try {
        const _hiddenTourHashes: string | null = localStorage.getItem("_hiddenTourHashes");
        if (_hiddenTourHashes && _hiddenTourHashes !== "undefined") {
          const hiddenHashes = JSON.parse(_hiddenTourHashes);
          setHiddenTourHashes(hiddenHashes);
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const salvaHiddenTourHashes = () => {
    try {
      const _serialized = JSON.stringify(profiloTour.hiddenHashes);
      localStorage.setItem("_hiddenTourHashes", _serialized);
    } catch (e) {
      console.warn(e);
    }
  };

  const onClose = () => {
    let _hiddenHashes = profiloTour.hiddenHashes || [];
    if (!nonVisualizzare) {
      _hiddenHashes = _hiddenHashes.filter(h => h !== tours.codiceTour);
    } else {
      if (tours.codiceTour && !_hiddenHashes.some(h => h === tours.codiceTour)) {
        _hiddenHashes = _hiddenHashes.concat([tours.codiceTour]);
      }
    }
    setHiddenTourHashes(_hiddenHashes);
    setVisible(false);
  };

  const indietro = () => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
    } else {
      onClose();
    }
  };

  const avanti = () => {
    setSlideIndex((current) => {
      if (current < (tours?.elementi?.length || 0) - 1) {
        return current + 1;
      } else {
        onClose();
        return current;
      }
    });
  };

  const _carousel = !!tours?.elementi?.length && visible &&
    <CarouselCustom
      className="tour-contestuale-carousel"
      custom={{
        slideIndexTrigger: slideIndex,
        onSelectSlideIndex: setSlideIndex,
        slides:
          profiloTour.tour?.[_funzionalita || ""]?.elementi?.map((slide, i) =>
            <div key={`tour-slide-${i}`} className={`tour-slide`}>
              <div className={`tour-slide-container`}>
                {!!slide.mediaType && slide.url && slide.mediaType.indexOf("img") >= 0 ?
                  <div className="tour-slide-container-image-wrapper">
                    <img className="tour-slide-container-image" src={slide.url} />
                  </div> :
                  !!slide.mediaType && slide.url && slide.mediaType.indexOf("video") >= 0 ?
                    <div className="tour-slide-container-video-wrapper">
                      <iframe
                        className="tour-slide-container-video"
                        src={slide.url}
                      />
                    </div> : <></>
                }
                <p
                  className={`tour-slide-container-text`}
                  dangerouslySetInnerHTML={{ __html: slide.contenuto || "" }}
                ></p>
              </div>
            </div>
          )
      }}
    />;

  const _modal = () => {

    const isLastSlide = slideIndex === (tours?.elementi?.length || 0) - 1;

    return (
      <Modal show={visible} className="tour-contestuale-modal" onHide={onClose}>

        <Modal.Header>
          <BankIcon title="navigazione_chiudi" className="" onClick={onClose} white />
          <Modal.Title>{tours?.elementi?.[slideIndex]?.titolo || ""}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {_carousel}
        </Modal.Body>

        <Modal.Footer>

          <div className="checkBox">
            <InputBox
              descrizione={t('modalTour.nonVisualizzare')}
              onChange={() => setNonVisualizzare(!nonVisualizzare)}
              value={!!nonVisualizzare}
              disabled={false}
            />
          </div>

          <div className="pulsanti">
            <button
              type="button"
              className={'modal-preferiti-modal__close-button left'}
              onClick={indietro}
            >
              {t(slideIndex > 0 ? "modalTour.button.indietro" : "modalTour.button.salta").toUpperCase()}
            </button>
            <button
              type="button"
              className={`modal-preferiti-modal__close-button ${isLastSlide ? 'end' : 'right'}`}
              onClick={avanti}
              disabled={false}
            >
              {t(isLastSlide ? "modalTour.button.fine" : "modalTour.button.avanti").toUpperCase()}
            </button>
          </div>

        </Modal.Footer>

      </Modal>
    )
  };

  return (
    <>
      {!!tours?.elementi?.length && _funzionalita !== 'HOME' &&
        <ButtonText2
          className="content-main-section--right-bar-small-button"
          descrizione={t("modalTour.visualizzaSchedaInformativa")}
          onClick={() => setVisible(true)}
          img={"tour"}
          imageOnLeft
        />
      }
      {!!tours?.elementi?.length && _modal()}
    </>
  );
};


const mapStateToProps = (state: AppState) => ({
  profiloTour: profiloTour(state.profilo)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getTour,
      setHiddenTourHashes,
    },
    dispatch
  );

interface IProps {
  funzionalita?: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;

export default connect(mapStateToProps, mapDispatchToProps)(TourContestuale);

