"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChiaveQuietanzaDtoToJSON = exports.ChiaveQuietanzaDtoFromJSONTyped = exports.ChiaveQuietanzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ChiaveQuietanzaDtoFromJSON(json) {
  return ChiaveQuietanzaDtoFromJSONTyped(json, false);
}
exports.ChiaveQuietanzaDtoFromJSON = ChiaveQuietanzaDtoFromJSON;
function ChiaveQuietanzaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceDocumento': !runtime_1.exists(json, 'codiceDocumento') ? undefined : json['codiceDocumento'],
    'annoInserimento': !runtime_1.exists(json, 'annoInserimento') ? undefined : json['annoInserimento'],
    'progressivoInserimento': !runtime_1.exists(json, 'progressivoInserimento') ? undefined : json['progressivoInserimento']
  };
}
exports.ChiaveQuietanzaDtoFromJSONTyped = ChiaveQuietanzaDtoFromJSONTyped;
function ChiaveQuietanzaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceDocumento': value.codiceDocumento,
    'annoInserimento': value.annoInserimento,
    'progressivoInserimento': value.progressivoInserimento
  };
}
exports.ChiaveQuietanzaDtoToJSON = ChiaveQuietanzaDtoToJSON;