"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneMavDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneMavDtoToJSON = exports.EsitoAutorizzazioneDisposizioneMavDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneMavDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneMavDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneMavDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneMavDtoFromJSON = EsitoAutorizzazioneDisposizioneMavDtoFromJSON;
function EsitoAutorizzazioneDisposizioneMavDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.MavDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneMavDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneMavDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneMavDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.MavDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneMavDtoToJSON = EsitoAutorizzazioneDisposizioneMavDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneMavDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneMavDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneMavDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneMavDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneMavDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneMavDtoStatoEnum = {}));