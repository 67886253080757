"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRevocaBolloAciDtoFromJSON = exports.FormBolloAciDtoDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormBolloAciDtoDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'alias1Click': !(0, helpers_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
    'alias1ClickDirty': !(0, helpers_1.exists)(json, 'alias1ClickDirty') ? false : json.alias1ClickDirty,
    'alias1ClickError': !(0, helpers_1.exists)(json, 'alias1ClickError') ? '' : json.alias1ClickError,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'salva1Click': !(0, helpers_1.exists)(json, 'salva1Click') ? undefined : json.salva1Click,
    'tipoVeicolo': !(0, helpers_1.exists)(json, 'tipoVeicolo') ? undefined : json.tipoVeicolo,
    'tipoVeicoloDirty': !(0, helpers_1.exists)(json, 'tipoVeicoloDirty') ? false : json.tipoVeicoloDirty,
    'tipoVeicoloError': !(0, helpers_1.exists)(json, 'tipoVeicoloError') ? 'invalid.required' : json.tipoVeicoloError,
    'targa': !(0, helpers_1.exists)(json, 'targa') ? undefined : json.targa,
    'targaDirty': !(0, helpers_1.exists)(json, 'targaDirty') ? false : json.targaDirty,
    'targaError': !(0, helpers_1.exists)(json, 'targaError') ? 'invalid.required' : json.targaError,
    'nome': !(0, helpers_1.exists)(json, 'nome') ? undefined : json.nome,
    'nomeDirty': !(0, helpers_1.exists)(json, 'nomeDirty') ? false : json.nomeDirty,
    'nomeError': !(0, helpers_1.exists)(json, 'nomeError') ? 'invalid.required' : json.nomeError,
    'aggiungiRubrica': !(0, helpers_1.exists)(json, 'aggiungiRubrica') ? undefined : json.aggiungiRubrica,
    'affidabile': !(0, helpers_1.exists)(json, 'affidabile') ? undefined : json.affidabile,
    'idContattoBeneficiario': !(0, helpers_1.exists)(json, 'idContattoBeneficiario') ? undefined : json.idContattoBeneficiario,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? undefined : json.ripeti,
    'contattoSelezionato': !(0, helpers_1.exists)(json, 'contattoSelezionato') ? undefined : json.contattoSelezionato
  };
}
exports.FormBolloAciDtoDtoFromJSONTyped = FormBolloAciDtoDtoFromJSONTyped;
function FormRevocaBolloAciDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaBolloAciDtoFromJSON = FormRevocaBolloAciDtoFromJSON;