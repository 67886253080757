"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationToJSON = exports.LocationFromJSONTyped = exports.LocationFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function LocationFromJSON(json) {
  return LocationFromJSONTyped(json, false);
}
exports.LocationFromJSON = LocationFromJSON;
function LocationFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'lat': !(0, api_rest_1.exists)(json, 'lat') ? undefined : json['lat'],
    'lng': !(0, api_rest_1.exists)(json, 'lng') ? undefined : json['lng']
  };
}
exports.LocationFromJSONTyped = LocationFromJSONTyped;
function LocationToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'lat': value.lat,
    'lng': value.lng
  };
}
exports.LocationToJSON = LocationToJSON;