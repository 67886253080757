"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CondizioniAcquistoNexiDebitToJSON = exports.CondizioniAcquistoNexiDebitFromJSONTyped = exports.CondizioniAcquistoNexiDebitFromJSON = void 0;
const runtime_1 = require("../runtime");
function CondizioniAcquistoNexiDebitFromJSON(json) {
  return CondizioniAcquistoNexiDebitFromJSONTyped(json, false);
}
exports.CondizioniAcquistoNexiDebitFromJSON = CondizioniAcquistoNexiDebitFromJSON;
function CondizioniAcquistoNexiDebitFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    'abi': !runtime_1.exists(json, 'abi') ? undefined : json['abi'],
    'canoneMensile': !runtime_1.exists(json, 'canoneMensile') ? undefined : json['canoneMensile'],
    'massimaleGiornaliero': !runtime_1.exists(json, 'massimaleGiornaliero') ? undefined : json['massimaleGiornaliero'],
    'massimaleMensile': !runtime_1.exists(json, 'massimaleMensile') ? undefined : json['massimaleMensile'],
    'msgKey': !runtime_1.exists(json, 'msgKey') ? undefined : json['msgKey'],
    'productType': !runtime_1.exists(json, 'productType') ? undefined : json['productType']
  };
}
exports.CondizioniAcquistoNexiDebitFromJSONTyped = CondizioniAcquistoNexiDebitFromJSONTyped;
function CondizioniAcquistoNexiDebitToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipo': value.tipo,
    'abi': value.abi,
    'canoneMensile': value.canoneMensile,
    'massimaleGiornaliero': value.massimaleGiornaliero,
    'massimaleMensile': value.massimaleMensile,
    'msgKey': value.msgKey,
    'productType': value.productType
  };
}
exports.CondizioniAcquistoNexiDebitToJSON = CondizioniAcquistoNexiDebitToJSON;