"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElementoPrivacyDtoToJSON = exports.ElementoPrivacyDtoFromJSONTyped = exports.ElementoPrivacyDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ElementoPrivacyDtoFromJSON(json) {
  return ElementoPrivacyDtoFromJSONTyped(json, false);
}
exports.ElementoPrivacyDtoFromJSON = ElementoPrivacyDtoFromJSON;
function ElementoPrivacyDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceDato': !runtime_1.exists(json, 'codiceDato') ? undefined : json['codiceDato'],
    'descrizioneDato': !runtime_1.exists(json, 'descrizioneDato') ? undefined : json['descrizioneDato'],
    'consenso': !runtime_1.exists(json, 'consenso') ? undefined : json['consenso'],
    'data': !runtime_1.exists(json, 'data') ? undefined : new Date(json['data']),
    'agenzia': !runtime_1.exists(json, 'agenzia') ? undefined : json['agenzia'],
    'privFi': !runtime_1.exists(json, 'privFi') ? undefined : json['privFi']
  };
}
exports.ElementoPrivacyDtoFromJSONTyped = ElementoPrivacyDtoFromJSONTyped;
function ElementoPrivacyDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceDato': value.codiceDato,
    'descrizioneDato': value.descrizioneDato,
    'consenso': value.consenso,
    'data': value.data === undefined ? undefined : value.data.toISOString(),
    'agenzia': value.agenzia,
    'privFi': value.privFi
  };
}
exports.ElementoPrivacyDtoToJSON = ElementoPrivacyDtoToJSON;