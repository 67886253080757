import React from "react";

interface CarouselStepperItemProps {
  index: number
  isSelected: boolean
  onSetIndex: (index: number) => void
}

const CarouselStepperItem = (props: CarouselStepperItemProps) => {

  const {
    index,
    isSelected,
    onSetIndex
  } = props;

  const onClick = () => {
    onSetIndex(index);
  };

  return (
    <div
      onClick={onClick}
      className={`stepper-step${isSelected ? ' stepper-selected-step' : ''}`}
    ></div>
  );
};

interface CarouselStepperProps {
  items: any[] | undefined
  selectedItemIndex: number,
  onSelectItemIndex: (itemIndexSelected: number) => void
}

const CarouselStepper = (props: CarouselStepperProps) => {

  const {
    items,
    selectedItemIndex,
    onSelectItemIndex
  } = props;

  return (
    <>

      {items && items.length > 1 &&
        <div className="carousel-stepper">

          {items?.map((_: any, index: number) => (
            <CarouselStepperItem
              key={'step_' + index}
              index={index}
              isSelected={index == selectedItemIndex}
              onSetIndex={onSelectItemIndex}
            />
          ))}

        </div>
      }

    </>
  );
};

export default CarouselStepper;
