"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.disableAccettaTutti = exports.esitoAnatocismo = exports.infoDispositivaAnatocismo = exports.formValidAnatocismo = exports.riepilogoAnatocismo = exports.anatocismo = exports.formAnatocismo = void 0;
const reselect_1 = require("reselect");
const anatocismoReducer = state => state;
exports.formAnatocismo = (0, reselect_1.createSelector)(anatocismoReducer, state => {
  return state.form;
});
exports.anatocismo = (0, reselect_1.createSelector)(anatocismoReducer, state => {
  return state.anatocismo;
});
exports.riepilogoAnatocismo = (0, reselect_1.createSelector)(anatocismoReducer, state => {
  return state.riepilogo;
});
exports.formValidAnatocismo = (0, reselect_1.createSelector)(anatocismoReducer, state => {
  return true;
});
exports.infoDispositivaAnatocismo = (0, reselect_1.createSelector)(anatocismoReducer, state => {
  return state.infoDispositiva;
});
exports.esitoAnatocismo = (0, reselect_1.createSelector)(anatocismoReducer, state => {
  return state.esito;
});
exports.disableAccettaTutti = (0, reselect_1.createSelector)(anatocismoReducer, state => {
  var _a;
  return Boolean(!((_a = state.anatocismo.rapporti) === null || _a === void 0 ? void 0 : _a.find(rapporto => !rapporto.accettato)));
});