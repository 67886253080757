"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rubricaContattiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const rubrica_types_1 = require("./rubrica.types");
const initialState = {
  rubricaContatti: (0, api_rest_1.ContattiDtoFromJSON)({}),
  rubricaLight: (0, api_rest_1.ContattiDtoFromJSON)({}),
  filter: {},
  contattoSelezionato: undefined,
  parametri: (0, api_rest_1.ParametriContattiDtoFromJSON)({}),
  infoDispositivaAggiungiContatto: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esitoAggiungiContatto: (0, api_rest_1.EsitoAutorizzazioneDisposizioneContattoDtoFromJSON)({}),
  formAggiungiContatto: (0, rubrica_types_1.FormAggiungiContattoDtoFromJSON)({}),
  riepilogoAggiungiContatto: (0, api_rest_1.ContattoDtoFromJSON)({})
};
const rubricaContattiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.RUBRICA_GET_CONTATTI_SUCCESS:
      return {
        ...state,
        rubricaContatti: action.payload
      };
    case __1.EReduxActionTypes.RUBRICA_LIGHT_GET_CONTATTI_SUCCESS:
      return {
        ...state,
        rubricaLight: action.payload
      };
    case __1.EReduxActionTypes.RUBRICA_TIPO_BENEFICIARIO:
      return {
        ...state,
        filter: {
          ...state.filter,
          tipoBeneficiario: action.payload,
          tipoBeneficiarioError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RUBRICA_CONTENUTO:
      return {
        ...state,
        filter: {
          ...state.filter,
          contenuto: action.payload,
          contenutoError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ACCORDION_FILTRI_OPENED:
      return {
        ...state,
        filter: {
          ...state.filter,
          accordionFiltriOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_CONTATTO_SELEZIONATO:
      return {
        ...state,
        contattoSelezionato: action.payload
      };
    case __1.EReduxActionTypes.RUBRICA_RESET_CONTATTO_SELEZIONATO:
      return {
        ...state,
        contattoSelezionato: initialState.contattoSelezionato
      };
    case __1.EReduxActionTypes.RUBRICA_GET_PARAMETRI_SUCCESS:
      return {
        ...initialState,
        //formAggiungiContatto: FormAggiungiContattoDtoFromJSON({}),
        parametri: action.payload
      };
    case __1.EReduxActionTypes.RUBRICA_RESET_FILTRO:
      return {
        ...state,
        filter: {
          accordionFiltriOpened: state.filter.accordionFiltriOpened
        }
      };
    case __1.EReduxActionTypes.RUBRICA_ELIMINA_CONTATTO_SUCCESS:
      const contatti = state.rubricaContatti && state.rubricaContatti.contatti ? state.rubricaContatti.contatti.filter(val => val.id !== action.payload) : [];
      return {
        ...state,
        rubricaContatti: {
          ...state.rubricaContatti,
          contatti: contatti
        }
      };
    case __1.EReduxActionTypes.RUBRICA_RESET_CONTATTI:
      return {
        ...state,
        rubricaContatti: initialState.rubricaContatti,
        rubricaLight: initialState.rubricaLight
      };
    case __1.EReduxActionTypes.RUBRICA_AGGIUNGI_CONTATTO_SET_STEP:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ACCORDION_DATI_PERSONALI_OPENED:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          accordionDatiPersonaliOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ACCORDION_DATI_BONIFICO_OPENED:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          accordionDatiBonificoOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ACCORDION_DATI_RICARICA_OPENED:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          accordionDatiRicaricaOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ACCORDION_DATI_BOLLO_OPENED:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          accordionDatiBolloOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ACCORDION_DATI_ALTOADIGEPASS_OPENED:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          accordionDatiAltoAdigePassOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ACCORDION_DATI_F24_OPENED:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          accordionDatiF24Opened: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_EMAIL_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          email: state.formAggiungiContatto.email.map((email, index) => {
            if (index === action.payload.index) {
              return {
                ...email,
                recapito: action.payload.value,
                recapitoDirty: true,
                recapitoError: action.payload.value ? (0, helpers_1.validationEmail)(action.payload.value, false) : ''
              };
            }
            return email;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_AGGIUNGI_EMAIL_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          email: [...state.formAggiungiContatto.email, (0, rubrica_types_1.FormRecapitoFromJSON)()]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_RIMUOVI_EMAIL_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          email: [...state.formAggiungiContatto.email.slice(0, action.payload), ...state.formAggiungiContatto.email.slice(action.payload + 1)]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_IBAN_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          iban: state.formAggiungiContatto.iban.map((ibanObject, index) => {
            if (index === action.payload.index) {
              return {
                ...ibanObject,
                recapito: action.payload.value,
                recapitoDirty: true,
                recapitoError: action.payload.value ? (0, helpers_1.validationField)(action.payload.value, 0, true) : ''
              };
            }
            return ibanObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_IBAN_ALIAS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          iban: state.formAggiungiContatto.iban.map((ibanObject, index) => {
            if (index === action.payload.index) {
              return {
                ...ibanObject,
                etichetta: action.payload.value,
                etichettaDirty: true,
                etichettaError: ''
              };
            }
            return ibanObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_IBAN_DISPENSA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          iban: state.formAggiungiContatto.iban.map((ibanObject, index) => {
            if (index === action.payload.index) {
              return {
                ...ibanObject,
                dispensaCredenziale: action.payload.value
              };
            }
            return ibanObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_AGGIUNGI_IBAN_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          iban: [...state.formAggiungiContatto.iban, (0, rubrica_types_1.FormRecapitoFromJSON)()]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_RIMUOVI_IBAN_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          iban: [...state.formAggiungiContatto.iban.slice(0, action.payload), ...state.formAggiungiContatto.iban.slice(action.payload + 1)]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_TELEFONO_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          telefono: state.formAggiungiContatto.telefono.map((telefonoObject, index) => {
            if (index === action.payload.index) {
              return {
                ...telefonoObject,
                recapito: action.payload.value,
                recapitoDirty: true,
                recapitoError: action.payload.value ? (0, helpers_1.validationNumeroCellulare)(action.payload.value, false) : ''
              };
            }
            return telefonoObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_TELEFONO_ALIAS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          telefono: state.formAggiungiContatto.telefono.map((telefonoObject, index) => {
            if (index === action.payload.index) {
              return {
                ...telefonoObject,
                etichetta: action.payload.value,
                etichettaDirty: true,
                etichettaError: ''
              };
            }
            return telefonoObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_TELEFONO_DISPENSA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          telefono: state.formAggiungiContatto.telefono.map((telefonoObject, index) => {
            if (index === action.payload.index) {
              return {
                ...telefonoObject,
                dispensaCredenziale: action.payload.value
              };
            }
            return telefonoObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_AGGIUNGI_TELEFONO_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          telefono: [...state.formAggiungiContatto.telefono, (0, rubrica_types_1.FormRecapitoFromJSON)()]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_RIMUOVI_TELEFONO_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          telefono: [...state.formAggiungiContatto.telefono.slice(0, action.payload), ...state.formAggiungiContatto.telefono.slice(action.payload + 1)]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_TARGA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          targa: state.formAggiungiContatto.targa.map((targaObject, index) => {
            if (index === action.payload.index) {
              return {
                ...targaObject,
                recapito: action.payload.value,
                recapitoDirty: true,
                recapitoError: action.payload.value ? (0, helpers_1.validationTarga)(action.payload.value, false) : ''
              };
            }
            return targaObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_TARGA_ALIAS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          targa: state.formAggiungiContatto.targa.map((targaObject, index) => {
            if (index === action.payload.index) {
              return {
                ...targaObject,
                etichetta: action.payload.value,
                etichettaDirty: true,
                etichettaError: ''
              };
            }
            return targaObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_TARGA_DISPENSA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          targa: state.formAggiungiContatto.targa.map((targaObject, index) => {
            if (index === action.payload.index) {
              return {
                ...targaObject,
                dispensaCredenziale: action.payload.value
              };
            }
            return targaObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_AGGIUNGI_TARGA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          targa: [...state.formAggiungiContatto.targa, (0, rubrica_types_1.FormRecapitoFromJSON)()]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_RIMUOVI_TARGA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          targa: [...state.formAggiungiContatto.targa.slice(0, action.payload), ...state.formAggiungiContatto.targa.slice(action.payload + 1)]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ALTOADIGEPASS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          altoAdigePass: state.formAggiungiContatto.altoAdigePass.map((altoAdigePassObject, index) => {
            if (index === action.payload.index) {
              return {
                ...altoAdigePassObject,
                recapito: action.payload.value,
                recapitoDirty: true,
                recapitoError: action.payload.value ? (0, helpers_1.validationAltoAdigePass)(action.payload.value, false) : ''
              };
            }
            return altoAdigePassObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ALTOADIGEPASS_ALIAS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          altoAdigePass: state.formAggiungiContatto.altoAdigePass.map((altoAdigePassObject, index) => {
            if (index === action.payload.index) {
              return {
                ...altoAdigePassObject,
                etichetta: action.payload.value,
                etichettaDirty: true,
                etichettaError: ''
              };
            }
            return altoAdigePassObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ALTOADIGEPASS_DISPENSA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          altoAdigePass: state.formAggiungiContatto.altoAdigePass.map((altoAdigePassObject, index) => {
            if (index === action.payload.index) {
              return {
                ...altoAdigePassObject,
                dispensaCredenziale: action.payload.value
              };
            }
            return altoAdigePassObject;
          })
        }
      };
    case __1.EReduxActionTypes.RUBRICA_AGGIUNGI_ALTOADIGEPASS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          altoAdigePass: [...state.formAggiungiContatto.altoAdigePass, (0, rubrica_types_1.FormRecapitoFromJSON)()]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_RIMUOVI_ALTOADIGEPASS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          altoAdigePass: [...state.formAggiungiContatto.altoAdigePass.slice(0, action.payload), ...state.formAggiungiContatto.altoAdigePass.slice(action.payload + 1)]
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_ALIAS_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          alias: action.payload,
          aliasDirty: true,
          aliasError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_DISPENSA_CREDENZIALE_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          dispensaCredenziale: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_NOME_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          nome: action.payload,
          nomeDirty: true,
          nomeError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_COGNOME_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          cognome: action.payload,
          cognomeDirty: true,
          cognomeError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_DATADINASCITA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          dataDiNascita: action.payload,
          dataDiNascitaDirty: true,
          dataDiNascitaError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_SESSO_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          sesso: action.payload,
          sessoDirty: true,
          sessoError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_LUOGONASCITA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          luogoNascita: action.payload,
          luogoNascitaDirty: true,
          luogoNascitaError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_PROVINCIANASCITA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          provinciaNascita: action.payload,
          provinciaNascitaDirty: true,
          provinciaNascitaError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_INDIRIZZO_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          indirizzo: action.payload,
          indirizzoDirty: true,
          indirizzoError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_CAP_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          cap: action.payload,
          capDirty: true,
          capError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_COMUNE_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          comune: action.payload,
          comuneDirty: true,
          comuneError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_PROVINCIA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          provincia: action.payload,
          provinciaDirty: true,
          provinciaError: '' //validationField(action.payload, "")
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_CODICEFISCALE_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          cf: action.payload,
          cfDirty: true,
          cfError: (0, helpers_1.validationCodiceFiscale)(action.payload, false)
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_DISPENSA_CODICEFISCALE_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          dispensaF24: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_PARTITAIVA_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          piva: action.payload,
          pivaDirty: true,
          pivaError: (0, helpers_1.validationPartitaIva)(action.payload, false)
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_FORM_SUBMITTED_AGGIUNGI_CONTATTO:
      return {
        ...state,
        formAggiungiContatto: {
          ...state.formAggiungiContatto,
          formSubmitted: action.payload
        }
      };
    case __1.EReduxActionTypes.RUBRICA_SET_MODIFICA_CONTATTO:
      const {
        id,
        alias,
        dispensaCredenziale,
        nome,
        cognome,
        dataNascita,
        sesso,
        luogoNascita,
        provinciaNascita,
        indirizzo: {
          indirizzo,
          comune,
          cap,
          provincia
        } = {
          indirizzo: '',
          comune: '',
          cap: '',
          provincia: ''
        },
        cf,
        dispensaF24,
        piva,
        email,
        iban,
        telefono,
        mezziTrasporto,
        sudtirolPass
      } = action.payload;
      return {
        ...state,
        riepilogoAggiungiContatto: initialState.riepilogoAggiungiContatto,
        formAggiungiContatto: {
          id: id,
          // popolare tutto l'oggetto
          alias: alias || '',
          aliasDirty: false,
          aliasError: (0, helpers_1.validationField)(alias, ''),
          dispensaCredenziale: dispensaCredenziale || false,
          nome: nome || '',
          nomeDirty: false,
          nomeError: '',
          cognome: cognome || '',
          cognomeDirty: false,
          cognomeError: '',
          dataDiNascita: dataNascita ? moment(dataNascita).toString() : undefined,
          dataDiNascitaDirty: false,
          dataDiNascitaError: '',
          sesso: sesso || undefined,
          sessoDirty: false,
          sessoError: '',
          luogoNascita: luogoNascita || '',
          luogoNascitaDirty: false,
          luogoNascitaError: '',
          provinciaNascita: provinciaNascita || undefined,
          provinciaNascitaDirty: false,
          provinciaNascitaError: '',
          cf: cf || '',
          cfDirty: false,
          cfError: '',
          dispensaF24: dispensaF24 || false,
          piva: piva || '',
          pivaDirty: false,
          pivaError: '',
          indirizzo: indirizzo || '',
          indirizzoDirty: false,
          indirizzoError: '',
          comune: comune || '',
          comuneDirty: false,
          comuneError: '',
          cap: cap || '',
          capDirty: false,
          capError: '',
          provincia: provincia || '',
          provinciaDirty: false,
          provinciaError: '',
          email: (0, helpers_1.convertToFormRecapitiEmail)(email || []),
          iban: (0, helpers_1.convertToFormRecapitiIban)(iban || []),
          telefono: (0, helpers_1.convertToFormRecapitiTelefono)(telefono || []),
          targa: (0, helpers_1.convertToFormRecapitiTarga)(mezziTrasporto || []),
          altoAdigePass: (0, helpers_1.convertToFormRecapitiPass)(sudtirolPass || []),
          formSubmitted: false,
          step: 0,
          accordionDatiPersonaliOpened: true,
          accordionDatiBonificoOpened: (iban || []).length > 0,
          accordionDatiRicaricaOpened: (telefono || []).length > 0,
          accordionDatiBolloOpened: (mezziTrasporto || []).length > 0,
          accordionDatiAltoAdigePassOpened: (sudtirolPass || []).length > 0,
          accordionDatiF24Opened: !!(nome || cognome || dataNascita || sesso || luogoNascita || provinciaNascita || cf || piva || indirizzo || comune || cap || provincia)
        }
      };
    case __1.EReduxActionTypes.RUBRICA_CONTROLLO_CONTATTO_SUCCESS:
      return {
        ...state,
        riepilogoAggiungiContatto: action.payload
      };
    case __1.EReduxActionTypes.RUBRICA_GET_INFO_DISPOSITIVA_AGGIUNGI_CONTATTO_SUCCESS:
      return {
        ...state,
        infoDispositivaAggiungiContatto: action.payload,
        esitoAggiungiContatto: initialState.esitoAggiungiContatto
      };
    case __1.EReduxActionTypes.RUBRICA_AUTORIZZA_AGGIUNGI_CONTATTO_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogoAggiungiContatto: initialState.riepilogoAggiungiContatto,
          esitoAggiungiContatto: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositivaAggiungiContatto: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositivaAggiungiContatto, action.payload.informazioniAutenticazioneDispositiva),
          esitoAggiungiContatto: action.payload
        };
      }
    case __1.EReduxActionTypes.RUBRICA_RESET_FORM:
      return {
        ...state,
        formAggiungiContatto: initialState.formAggiungiContatto
      };
    case __1.EReduxActionTypes.RUBRICA_RESET_RIEPILOGO:
      return {
        ...state,
        riepilogoAggiungiContatto: initialState.riepilogoAggiungiContatto,
        infoDispositivaAggiungiContatto: initialState.infoDispositivaAggiungiContatto,
        esitoAggiungiContatto: initialState.esitoAggiungiContatto
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.rubricaContattiReducer = rubricaContattiReducer;