"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WatchlistQuotazioniDtoToJSON = exports.WatchlistQuotazioniDtoFromJSONTyped = exports.WatchlistQuotazioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WatchlistQuotazioniDtoFromJSON(json) {
  return WatchlistQuotazioniDtoFromJSONTyped(json, false);
}
exports.WatchlistQuotazioniDtoFromJSON = WatchlistQuotazioniDtoFromJSON;
function WatchlistQuotazioniDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceWatchlist': !runtime_1.exists(json, 'codiceWatchlist') ? undefined : json['codiceWatchlist'],
    'nomeWatchlist': !runtime_1.exists(json, 'nomeWatchlist') ? undefined : json['nomeWatchlist'],
    'tipoWatchlist': !runtime_1.exists(json, 'tipoWatchlist') ? undefined : json['tipoWatchlist'],
    'quotazioni': !runtime_1.exists(json, 'quotazioni') ? undefined : json['quotazioni'].map(_1.WatchlistQuotazioneDtoFromJSON),
    'totaleQuotazioni': !runtime_1.exists(json, 'totaleQuotazioni') ? undefined : json['totaleQuotazioni']
  };
}
exports.WatchlistQuotazioniDtoFromJSONTyped = WatchlistQuotazioniDtoFromJSONTyped;
function WatchlistQuotazioniDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceWatchlist': value.codiceWatchlist,
    'nomeWatchlist': value.nomeWatchlist,
    'tipoWatchlist': value.tipoWatchlist,
    'quotazioni': value.quotazioni === undefined ? undefined : value.quotazioni.map(_1.WatchlistQuotazioneDtoToJSON),
    'totaleQuotazioni': value.totaleQuotazioni
  };
}
exports.WatchlistQuotazioniDtoToJSON = WatchlistQuotazioniDtoToJSON;