"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormElencoBonificiRipetitiviDtoFromJSON = exports.FormRiattivaBonificoDtoFromJSON = exports.FormSospendiBonificoDtoFromJSON = exports.FormRevocaBonificoDtoFromJSON = exports.FormBonificoRipetitivoDtoFromJSONTyped = exports.BonificoRipetitivoImpostazioni = exports.BonificoRipetitivoTipoDisposizione = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
exports.BonificoRipetitivoTipoDisposizione = {
  NumeroRate: api_rest_1.DisposizioneDtoTipoDisposizioneEnum.N,
  Revoca: api_rest_1.DisposizioneDtoTipoDisposizioneEnum.R,
  Scadenza: api_rest_1.DisposizioneDtoTipoDisposizioneEnum.S
};
exports.BonificoRipetitivoImpostazioni = {
  ModalitaImporto: {
    Fisso: api_rest_1.DisposizioneDtoTipoImportoEnum.F,
    Variabile: api_rest_1.DisposizioneDtoTipoImportoEnum.V
  },
  TipoPeriodo: {
    Personalizzato: api_rest_1.DisposizioneDtoTipoPeriodoEnum.V,
    Fisso: api_rest_1.DisposizioneDtoTipoPeriodoEnum.F
  },
  FinoA: exports.BonificoRipetitivoTipoDisposizione,
  TipoDataPagamento: {
    AddebitoOrdinante: api_rest_1.DisposizioneDtoTipoDataEnum.AD,
    AccreditoBeneficiario: api_rest_1.DisposizioneDtoTipoDataEnum.AC
  }
};
function FormBonificoRipetitivoDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'beneficiario': !(0, helpers_1.exists)(json, 'beneficiario') ? undefined : json.beneficiario,
    'beneficiarioDirty': !(0, helpers_1.exists)(json, 'beneficiarioDirty') ? false : json.beneficiarioDirty,
    'beneficiarioError': !(0, helpers_1.exists)(json, 'beneficiarioError') ? 'invalid.required' : json.beneficiarioError,
    'iban': !(0, helpers_1.exists)(json, 'iban') ? undefined : json.iban,
    'ibanDirty': !(0, helpers_1.exists)(json, 'ibanDirty') ? false : json.ibanDirty,
    'ibanError': !(0, helpers_1.exists)(json, 'ibanError') ? 'invalid.required' : json.ibanError,
    'addRubrica': !(0, helpers_1.exists)(json, 'addRubrica') ? undefined : json.addRubrica,
    'addWhiteList': !(0, helpers_1.exists)(json, 'addWhiteList') ? undefined : json.addWhiteList,
    'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
    'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
    'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
    'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
    'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
    'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
    'descrizione': !(0, helpers_1.exists)(json, 'descrizione') ? undefined : json.descrizione,
    'descrizioneDirty': !(0, helpers_1.exists)(json, 'descrizioneDirty') ? false : json.descrizioneDirty,
    'descrizioneError': !(0, helpers_1.exists)(json, 'descrizioneError') ? 'invalid.required' : json.descrizioneError,
    'idContattoBeneficiario': !(0, helpers_1.exists)(json, 'idContattoBeneficiario') ? undefined : json.idContattoBeneficiario,
    'tipoImporto': !(0, helpers_1.exists)(json, 'tipoImporto') ? {
      value: '',
      dirty: false,
      error: 'invalid.required'
    } : json.tipoImporto,
    'tipoDataPagamento': !(0, helpers_1.exists)(json, 'tipoDataPagamento') ? {
      value: '',
      dirty: false,
      error: 'invalid.required'
    } : json.tipoDataPagamento,
    'periodo': !(0, helpers_1.exists)(json, 'periodo') ? {
      value: '',
      dirty: false,
      error: 'invalid.required'
    } : json.periodo,
    'tipoDisposizione': !(0, helpers_1.exists)(json, 'tipoDisposizione') ? {
      value: exports.BonificoRipetitivoImpostazioni.FinoA.Revoca,
      dirty: false,
      error: ''
    } : json.tipoDisposizione,
    'dataScadenza': !(0, helpers_1.exists)(json, 'dataScadenza') ? {
      value: '',
      dirty: false,
      error: 'invalid.required'
    } : json.dataScadenza,
    'numeroRate': !(0, helpers_1.exists)(json, 'numeroRate') ? {
      value: '',
      dirty: false,
      error: 'invalid.required'
    } : json.numeroRate,
    'giorno': !(0, helpers_1.exists)(json, 'giorno') ? {
      value: '',
      dirty: false,
      error: 'invalid.required'
    } : json.giorno,
    'fineMese': !(0, helpers_1.exists)(json, 'fineMese') ? false : json.fineMese,
    'rate': !(0, helpers_1.exists)(json, 'rate') ? new Array(12) : json.rate,
    'rateError': !(0, helpers_1.exists)(json, 'rateError') ? '' : json.rateError,
    'rateDirty': !(0, helpers_1.exists)(json, 'rateDirty') ? false : json.rateDirty,
    'dataProssimoPagamento': !(0, helpers_1.exists)(json, 'dataProssimoPagamento') ? {
      value: new Date(),
      dirty: false,
      error: 'invalid.required'
    } : json.periodo,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'intestatario': !(0, helpers_1.exists)(json, 'intestatario') ? undefined : json.intestatario,
    'IBANInt': !(0, helpers_1.exists)(json, 'IBANInt') ? undefined : json.IBANInt,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti,
    'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
    'isModifica': !(0, helpers_1.exists)(json, 'isModifica') ? false : json.isModifica,
    'id': !(0, helpers_1.exists)(json, 'id') ? undefined : json.id,
    'codiceDisposizione': !(0, helpers_1.exists)(json, 'codiceDisposizione') ? undefined : json.codiceDisposizione
  };
}
exports.FormBonificoRipetitivoDtoFromJSONTyped = FormBonificoRipetitivoDtoFromJSONTyped;
function FormRevocaBonificoDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaBonificoDtoFromJSON = FormRevocaBonificoDtoFromJSON;
function FormSospendiBonificoDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    //'formSubmitted': !exists(json, 'formSubmitted') ? false : json['formSubmitted'],
    'dataInizioSospensione': !(0, helpers_1.exists)(json, 'dataInizioSospensione') ? {
      value: undefined,
      dirty: false,
      error: ''
    } : json.dataInizioSospensione,
    'dataFineSospensione': !(0, helpers_1.exists)(json, 'dataFineSospensione') ? {
      value: undefined,
      dirty: false,
      error: ''
    } : json.dataFineSospensione,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormSospendiBonificoDtoFromJSON = FormSospendiBonificoDtoFromJSON;
function FormRiattivaBonificoDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    //'formSubmitted': !exists(json, 'formSubmitted') ? false : json['formSubmitted'],
    'dataRiattivazione': !(0, helpers_1.exists)(json, 'dataRiattivazione') ? {
      value: undefined,
      dirty: false,
      error: ''
    } : json.dataRiattivazione,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRiattivaBonificoDtoFromJSON = FormRiattivaBonificoDtoFromJSON;
function FormElencoBonificiRipetitiviDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'beneficiario': !(0, helpers_1.exists)(json, 'beneficiario') ? {
      value: '',
      dirty: false,
      error: ''
    } : json.beneficiario,
    'stati': !(0, helpers_1.exists)(json, 'stati') ? [] : json.stati,
    'causale': !(0, helpers_1.exists)(json, 'causale') ? {
      value: '',
      dirty: false,
      error: ''
    } : json.causale,
    'dataDa': !(0, helpers_1.exists)(json, 'dataDa') ? {
      value: '',
      dirty: false,
      error: ''
    } : json.dataDa,
    'dataA': !(0, helpers_1.exists)(json, 'dataA') ? {
      value: '',
      dirty: false,
      error: ''
    } : json.dataA,
    'importo': !(0, helpers_1.exists)(json, 'importo') ? {
      value: undefined,
      dirty: false,
      error: ''
    } : json.importo,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'offset': !(0, helpers_1.exists)(json, 'offset') ? 0 : json.offset,
    'accordionFiltriOpened': !(0, helpers_1.exists)(json, 'accordionFiltriOpened') ? false : json.accordionFiltriOpened,
    'formElencoDirty': !(0, helpers_1.exists)(json, 'formElencoDirty') ? false : json.formElencoDirty
  };
}
exports.FormElencoBonificiRipetitiviDtoFromJSON = FormElencoBonificiRipetitiviDtoFromJSON;