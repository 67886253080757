"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RiskProfileDtoToJSON = exports.RiskProfileDtoFromJSONTyped = exports.RiskProfileDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RiskProfileDtoFromJSON(json) {
  return RiskProfileDtoFromJSONTyped(json, false);
}
exports.RiskProfileDtoFromJSON = RiskProfileDtoFromJSON;
function RiskProfileDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'results': !runtime_1.exists(json, 'results') ? undefined : json['results'].map(_1.RiskProfileResultDtoFromJSON),
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId']
  };
}
exports.RiskProfileDtoFromJSONTyped = RiskProfileDtoFromJSONTyped;
function RiskProfileDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'bankCode': value.bankCode,
    'message': value.message,
    'results': value.results === undefined ? undefined : value.results.map(_1.RiskProfileResultDtoToJSON),
    'userId': value.userId
  };
}
exports.RiskProfileDtoToJSON = RiskProfileDtoToJSON;