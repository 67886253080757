import BankIcon from "component/Icon/icon.component";
import React, { useEffect, useRef, useState } from "react";

export interface IRapporto {
  id: string
  description: string
  descriptionClosed?: string
}

interface Props {
  items: Array<IRapporto>
  onSelect: (item: IRapporto) => void
  selectedItem?: string
  numeroMinimoItems?: number
  forceShowSelected?: boolean
  tooltipText?: string
}

const SelectRapportoMini = (props: Props) => {

  const {
    items,
    onSelect,
    selectedItem,
    numeroMinimoItems,
    forceShowSelected,
    tooltipText
  } = props;

  const optionContainer = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedElementIndex, selectElementIndex] = useState(-1);

  const numeroDiElementiSufficiente = (items.length >= (numeroMinimoItems || 3));

  useEffect(() => {
    const _selectedElementIndex = items?.findIndex(r => r.id === selectedItem);
    selectElementIndex(_selectedElementIndex);
  }, [items, selectedItem]);

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickOptionsList);
  };

  const outsideClickOptionsList = (event: any) => {
    if (!optionContainer.current?.contains(event.target)) {
      removeClickListener();
      setIsDropdownOpen(false);
    }
  };

  const openDropdown = (open: boolean) => {
    if (open && numeroDiElementiSufficiente) {
      setIsDropdownOpen(true);
      setTimeout(() => {
        document.addEventListener("click", outsideClickOptionsList);
      });
    } else {
      setIsDropdownOpen(false);
      removeClickListener();
    }
  }

  return (
    !!forceShowSelected || numeroDiElementiSufficiente
      ? <div className="mini-seleziona-rapporto">

        <div
          className={`mini-seleziona-rapporto-clickable ${numeroDiElementiSufficiente ? "numero-elementi-sufficiente" : ""}`}
          onClick={() => openDropdown(!isDropdownOpen)}
        >
          <span className="mini-seleziona-rapporto-selezione">
            {items?.[selectedElementIndex]?.descriptionClosed || items?.[selectedElementIndex]?.description || "-"}
          </span>
          <BankIcon title="azioni_mini_esegui_mini" rotation={90} width={33} height={33} />
        </div>

        {isDropdownOpen && numeroDiElementiSufficiente &&
          <div className="mini-seleziona-rapporto-dropdown" ref={optionContainer}>
            {items.map((item, i) => (
              <div
                key={`rapporto-mini-${i}`}
                className={`mini-seleziona-rapporto-dropdown-item ${item.id === items?.[selectedElementIndex]?.id ? "row-alternative-color" : ""}`}
                onClick={() => {
                  if (item.id !== items?.[selectedElementIndex]?.id) {
                    selectElementIndex(i);
                    onSelect(item);
                  }
                  openDropdown(false);
                }
                }
              >
                {item.description}
              </div>
            ))}
          </div>
        }
        {tooltipText && (
          <BankIcon title="info.tooltip" width={33} height={33} style={{ marginLeft: 8 }} tooltip={tooltipText} />
        )}

      </div>
      : null
  );
};

export default SelectRapportoMini;
