"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datiPersonaliReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const datiPersonali_types_1 = require("./datiPersonali.types");
const initialState = {
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSON)({}),
  infoDispositivaContatti: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  riepilogoContatti: (0, api_rest_1.DatiAnagraficiDtoFromJSON)({}),
  esitoContatti: (0, api_rest_1.EsitoAutorizzazioneDisposizioneProfiloDtoFromJSON)({}),
  dataAnagrafici: (0, api_rest_1.DatiAnagraficiDtoFromJSON)({}),
  formDatiPersonali: (0, datiPersonali_types_1.FormDatiPersonaliDtoFromJSONTyped)({})
};
const datiPersonaliReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.DATI_PERSONALI_GET_DATI_ANAGRAFICI_SUCCESS:
      return {
        ...state,
        dataAnagrafici: action.payload
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_AUTORIZZA_DATI_AGGIUNTIVI_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositivaContatti: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositivaContatti, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.DATI_PERSONALI_GET_INFORMAZIONI_DATI_AGGIUNTIVI_SUCCESS:
      return {
        ...state,
        infoDispositivaContatti: action.payload,
        esitoContatti: initialState.esitoContatti
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_SET_STEP:
      return {
        ...state,
        formDatiPersonali: {
          ...state.formDatiPersonali,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_RESET:
      return {
        ...state,
        formDatiPersonali: initialState.formDatiPersonali
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_CONTROLLO_SUCCESS:
      return {
        ...state,
        riepilogoContatti: action.payload
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_GET_INFO_DISPOSITIVA_SUCCESS:
      return {
        ...state,
        infoDispositivaContatti: action.payload,
        esitoContatti: initialState.esitoContatti,
        formDatiPersonali: {
          ...state.formDatiPersonali,
          infoImpostaContattiRecuperata: true
        }
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_AUTORIZZA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esitoContatti: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositivaContatti: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositivaContatti, action.payload.informazioniAutenticazioneDispositiva),
          esitoContatti: action.payload
        };
      }
    case __1.EReduxActionTypes.DATI_PERSONALI_SET_EMAIL:
      return {
        ...state,
        formDatiPersonali: {
          ...state.formDatiPersonali,
          email: action.payload,
          emailDirty: true,
          emailError: (0, helpers_1.validationEmail)(action.payload, false)
        }
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_SET_CELLULARE:
      return {
        ...state,
        formDatiPersonali: {
          ...state.formDatiPersonali,
          cellulare: action.payload,
          cellulareDirty: true,
          cellulareError: (0, helpers_1.validationNumeroCellulare)(action.payload, false)
        }
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_RESET_CONTATTI_CONTROLLO:
      return {
        ...state,
        riepilogoContatti: initialState.riepilogoContatti
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_RESET_CONTATTI_GET_INFO_DISPOSITIVA:
      return {
        ...state,
        infoDispositivaContatti: initialState.infoDispositivaContatti
      };
    case __1.EReduxActionTypes.DATI_PERSONALI_RESET_CONTATTI_AUTORIZZA:
      return {
        ...state,
        esitoContatti: initialState.esitoContatti
      };
    // case EReduxActionTypes.DATI_PERSONALI_SET_PROVINCIA:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             provinciaDirty: true,
    //             provinciaError: validationField(action.payload, ""),
    //         }
    //     };
    // case EReduxActionTypes.DATI_PERSONALI_SET_COMUNE:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             comuneDirty: true,
    //             comuneError: validationField(action.payload, ""),
    //         }
    //     };
    // case EReduxActionTypes.DATI_PERSONALI_SET_LOCALITA:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             localitaDirty: true,
    //             localitaError: validationCitta(action.payload, true),
    //         }
    //     };
    // case EReduxActionTypes.DATI_PERSONALI_SET_CAP:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             localitaDirty: true,
    //             localitaError: validationAsNumber(action.payload, true, 5),
    //         }
    //     };
    // case EReduxActionTypes.DATI_PERSONALI_SET_VIA_PLAZA:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             tipoDirty: true,
    //             tipoError: validationIndirizzo(action.payload, true),
    //         }
    //     };
    // case EReduxActionTypes.DATI_PERSONALI_SET_INDIRIZIO:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             nomeDirty: true,
    //             nomeError: validationIndirizzo(action.payload, true),
    //         }
    //     };
    // case EReduxActionTypes.DATI_PERSONALI_SET_NUMERO_CIVICIO:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             numeroDomicilioDirty: true,
    //             numeroDomicilioError: validationAsNumber(action.payload, true),
    //         }
    //     };
    // case EReduxActionTypes.DATI_PERSONALI_SET_SET_FORM_SUBMITTED:
    //     return {
    //         ...state,
    //         formDatiPersonali: {
    //             ...state.formDatiPersonali,
    //             formSubmitted: action.payload,
    //         },
    //     };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.datiPersonaliReducer = datiPersonaliReducer;