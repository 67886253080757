"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElencoNewsDtoToJSON = exports.ElencoNewsDtoFromJSONTyped = exports.ElencoNewsDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ElencoNewsDtoFromJSON(json) {
  return ElencoNewsDtoFromJSONTyped(json, false);
}
exports.ElencoNewsDtoFromJSON = ElencoNewsDtoFromJSON;
function ElencoNewsDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'news': !runtime_1.exists(json, 'news') ? undefined : json['news'].map(_1.NewsDtoFromJSON),
    'totNews': !runtime_1.exists(json, 'totNews') ? undefined : json['totNews']
  };
}
exports.ElencoNewsDtoFromJSONTyped = ElencoNewsDtoFromJSONTyped;
function ElencoNewsDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'news': value.news === undefined ? undefined : value.news.map(_1.NewsDtoToJSON),
    'totNews': value.totNews
  };
}
exports.ElencoNewsDtoToJSON = ElencoNewsDtoToJSON;