"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiIntradayCambioInDtoToJSON = exports.DatiIntradayCambioInDtoFromJSONTyped = exports.DatiIntradayCambioInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiIntradayCambioInDtoFromJSON(json) {
  return DatiIntradayCambioInDtoFromJSONTyped(json, false);
}
exports.DatiIntradayCambioInDtoFromJSON = DatiIntradayCambioInDtoFromJSON;
function DatiIntradayCambioInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mercato': json['mercato'],
    'codiceCambio': json['codiceCambio'],
    'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : new Date(json['dataIniziale']),
    'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : new Date(json['dataFinale'])
  };
}
exports.DatiIntradayCambioInDtoFromJSONTyped = DatiIntradayCambioInDtoFromJSONTyped;
function DatiIntradayCambioInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mercato': value.mercato,
    'codiceCambio': value.codiceCambio,
    'dataIniziale': value.dataIniziale === undefined ? undefined : value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale === undefined ? undefined : value.dataFinale.toISOString()
  };
}
exports.DatiIntradayCambioInDtoToJSON = DatiIntradayCambioInDtoToJSON;