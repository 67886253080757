"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChannelEnumToJSON = exports.ChannelEnumFromJSONTyped = exports.ChannelEnumFromJSON = exports.ChannelEnum = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ChannelEnum;
(function (ChannelEnum) {
  ChannelEnum["DESKTOP"] = "DESKTOP";
  ChannelEnum["MOBILE"] = "MOBILE";
  ChannelEnum["PHONE"] = "PHONE";
})(ChannelEnum = exports.ChannelEnum || (exports.ChannelEnum = {}));
function ChannelEnumFromJSON(json) {
  return ChannelEnumFromJSONTyped(json, false);
}
exports.ChannelEnumFromJSON = ChannelEnumFromJSON;
function ChannelEnumFromJSONTyped(json, ignoreDiscriminator) {
  return json;
}
exports.ChannelEnumFromJSONTyped = ChannelEnumFromJSONTyped;
function ChannelEnumToJSON(value) {
  return value;
}
exports.ChannelEnumToJSON = ChannelEnumToJSON;