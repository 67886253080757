"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * PNS Client API
 * PNS - Push Notification Service: Api per l\'invio di notifiche
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class SubscriptionApi extends runtime.BaseAPI {
  /**
   * Aggiunge una sottoscrizione ad un canale per un utente
   * Crea una nuova subscription
   */
  createSubscriptionsRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.pNSSubscriptionData === null || requestParameters.pNSSubscriptionData === undefined) {
        throw new runtime.RequiredError('pNSSubscriptionData', 'Required parameter requestParameters.pNSSubscriptionData was null or undefined when calling createSubscriptions.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (this.configuration && this.configuration.accessToken) {
        const token = this.configuration.accessToken;
        const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
        if (tokenString) {
          headerParameters["Authorization"] = "Bearer ".concat(tokenString);
        }
      }
      const response = yield this.request({
        path: "/private/subscription",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.PNSSubscriptionDataToJSON(requestParameters.pNSSubscriptionData)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.PNSResponseSubscriptionFromJSON(jsonValue));
    });
  }
  /**
   * Aggiunge una sottoscrizione ad un canale per un utente
   * Crea una nuova subscription
   */
  createSubscriptions(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.createSubscriptionsRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.SubscriptionApi = SubscriptionApi;