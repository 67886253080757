"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cambiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const cambi_types_1 = require("./cambi.types");
const initialState = {
  cambi: (0, api_rest_1.QuotazioniCambiDtoFromJSON)({}),
  mercati: (0, api_rest_1.MercatiDtoFromJSON)({}),
  cambioDettaglio: {
    dettaglioCambio: (0, api_rest_1.QuotazioneDettaglioCambioDtoFromJSON)({}),
    datiIntradayCambio: (0, api_rest_1.DatiIntradayDtoFromJSON)({}),
    datiStoriciCambio: (0, api_rest_1.DatiStoriciDtoFromJSON)({}),
    tipoGrafico: cambi_types_1.TipoGraficoCambi.Settimana1
  },
  form: {
    mercato: '',
    denominazione: '',
    cambioSelezionato: (0, api_rest_1.QuotazioneTitoloDtoFromJSON)({}),
    offset: 0
  }
};
const cambiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_MERCATI_SUCCESS:
      return {
        ...initialState,
        mercati: action.payload
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_TITOLI_SUCCESS:
      return {
        ...state,
        cambi: !action.offset ? action.payload : {
          ...state.cambi,
          quotazioni: (state.cambi.quotazioni || []).concat(action.payload.quotazioni || [])
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DETTAGLIO_CAMBIO_SUCCESS:
      return {
        ...state,
        cambioDettaglio: {
          ...state.cambioDettaglio,
          dettaglioCambio: action.payload
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DATI_INTRADAY_CAMBIO_SUCCESS:
      return {
        ...state,
        cambioDettaglio: {
          ...state.cambioDettaglio,
          datiIntradayCambio: action.payload,
          aggiornamento: action.aggiornamento
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DATI_STORICI_CAMBIO_SUCCESS:
      return {
        ...state,
        cambioDettaglio: {
          ...state.cambioDettaglio,
          aggiornamento: action.aggiornamento,
          datiStoriciCambio: action.payload,
          datiSettimana: action.tipo === cambi_types_1.TipoGraficoCambi.Settimana1 ? action.payload : state.cambioDettaglio.datiSettimana,
          datiMese: action.tipo === cambi_types_1.TipoGraficoCambi.Mese1 ? action.payload : state.cambioDettaglio.datiMese,
          datiAnno: action.tipo === cambi_types_1.TipoGraficoCambi.Anno1 ? action.payload : state.cambioDettaglio.datiAnno
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_SCHEDA_CAMBIO_SUCCESS:
      return {
        ...state,
        cambioDettaglio: {
          ...state.cambioDettaglio,
          schedaCambio: action.payload
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.SET_CAMBI_MERCATO:
      return {
        ...state,
        form: {
          ...state.form,
          mercato: action.payload
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.SET_CAMBI_DENOMINAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          denominazione: action.payload
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_RESET:
      return {
        ...initialState
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_RESET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          cambioSelezionato: initialState.form.cambioSelezionato,
          denominazione: initialState.form.denominazione,
          offset: initialState.form.offset
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_SET_CAMBIO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          cambioSelezionato: action.payload
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_SET_OFFSET:
      return {
        ...state,
        form: {
          ...state.form,
          offset: action.payload
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_SET_TIPO_GRAFICO:
      return {
        ...state,
        cambioDettaglio: {
          ...state.cambioDettaglio,
          tipoGrafico: action.payload
        }
      };
    case cambi_types_1.EReduxCambiActionTypes.CAMBI_RESET_DETTAGLIO:
      return {
        ...state,
        cambioDettaglio: initialState.cambioDettaglio
      };
    default:
      return state;
  }
};
exports.cambiReducer = cambiReducer;
exports.default = exports.cambiReducer;