"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfiloDatiUtenteDtoModoAutenticazioneEnum = exports.ProfiloDatiUtenteDtoToJSON = exports.ProfiloDatiUtenteDtoFromJSONTyped = exports.ProfiloDatiUtenteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProfiloDatiUtenteDtoFromJSON(json) {
  return ProfiloDatiUtenteDtoFromJSONTyped(json, false);
}
exports.ProfiloDatiUtenteDtoFromJSON = ProfiloDatiUtenteDtoFromJSON;
function ProfiloDatiUtenteDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceABI': !runtime_1.exists(json, 'codiceABI') ? undefined : json['codiceABI'],
    'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
    'userInternet': !runtime_1.exists(json, 'userInternet') ? undefined : json['userInternet'],
    'codiceInternoUtente': !runtime_1.exists(json, 'codiceInternoUtente') ? undefined : json['codiceInternoUtente'],
    'codiceAlias': !runtime_1.exists(json, 'codiceAlias') ? undefined : json['codiceAlias'],
    'modoAutenticazione': !runtime_1.exists(json, 'modoAutenticazione') ? undefined : json['modoAutenticazione'],
    'codiceIstituto': !runtime_1.exists(json, 'codiceIstituto') ? undefined : json['codiceIstituto'],
    'codiceIstituto2x': !runtime_1.exists(json, 'codiceIstituto2x') ? undefined : json['codiceIstituto2x'],
    'codiceProdotto': !runtime_1.exists(json, 'codiceProdotto') ? undefined : json['codiceProdotto'],
    'codiceProdottoSmsAlert': !runtime_1.exists(json, 'codiceProdottoSmsAlert') ? undefined : json['codiceProdottoSmsAlert'],
    'codiceContratto': !runtime_1.exists(json, 'codiceContratto') ? undefined : json['codiceContratto'],
    'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
    'codiceCabRiferimento': !runtime_1.exists(json, 'codiceCabRiferimento') ? undefined : json['codiceCabRiferimento'],
    'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
    'datiPersonali': !runtime_1.exists(json, 'datiPersonali') ? undefined : _1.ProfiloDatiPersonaliDtoFromJSON(json['datiPersonali'])
  };
}
exports.ProfiloDatiUtenteDtoFromJSONTyped = ProfiloDatiUtenteDtoFromJSONTyped;
function ProfiloDatiUtenteDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceABI': value.codiceABI,
    'codiceUtente': value.codiceUtente,
    'userInternet': value.userInternet,
    'codiceInternoUtente': value.codiceInternoUtente,
    'codiceAlias': value.codiceAlias,
    'modoAutenticazione': value.modoAutenticazione,
    'codiceIstituto': value.codiceIstituto,
    'codiceIstituto2x': value.codiceIstituto2x,
    'codiceProdotto': value.codiceProdotto,
    'codiceProdottoSmsAlert': value.codiceProdottoSmsAlert,
    'codiceContratto': value.codiceContratto,
    'filiale': value.filiale,
    'codiceCabRiferimento': value.codiceCabRiferimento,
    'ndg': value.ndg,
    'datiPersonali': _1.ProfiloDatiPersonaliDtoToJSON(value.datiPersonali)
  };
}
exports.ProfiloDatiUtenteDtoToJSON = ProfiloDatiUtenteDtoToJSON;
/**
* @export
* @enum {string}
*/
var ProfiloDatiUtenteDtoModoAutenticazioneEnum;
(function (ProfiloDatiUtenteDtoModoAutenticazioneEnum) {
  ProfiloDatiUtenteDtoModoAutenticazioneEnum["FULL"] = "FULL";
  ProfiloDatiUtenteDtoModoAutenticazioneEnum["LIGTH"] = "LIGTH}";
})(ProfiloDatiUtenteDtoModoAutenticazioneEnum = exports.ProfiloDatiUtenteDtoModoAutenticazioneEnum || (exports.ProfiloDatiUtenteDtoModoAutenticazioneEnum = {}));