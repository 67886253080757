"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxIndiciActionTypes = exports.TipoGraficoIndiciEsteri = exports.TipoGraficoIndici = void 0;
var TipoGraficoIndici;
(function (TipoGraficoIndici) {
  TipoGraficoIndici[TipoGraficoIndici["Ore1"] = 0] = "Ore1";
  TipoGraficoIndici[TipoGraficoIndici["Ore4"] = 1] = "Ore4";
  TipoGraficoIndici[TipoGraficoIndici["Giorni1"] = 2] = "Giorni1";
  TipoGraficoIndici[TipoGraficoIndici["Settimana1"] = 3] = "Settimana1";
  TipoGraficoIndici[TipoGraficoIndici["Mese1"] = 4] = "Mese1";
  TipoGraficoIndici[TipoGraficoIndici["Anno1"] = 5] = "Anno1";
})(TipoGraficoIndici = exports.TipoGraficoIndici || (exports.TipoGraficoIndici = {}));
;
var TipoGraficoIndiciEsteri;
(function (TipoGraficoIndiciEsteri) {
  TipoGraficoIndiciEsteri[TipoGraficoIndiciEsteri["Settimana1"] = 0] = "Settimana1";
  TipoGraficoIndiciEsteri[TipoGraficoIndiciEsteri["Mese1"] = 1] = "Mese1";
  TipoGraficoIndiciEsteri[TipoGraficoIndiciEsteri["Anno1"] = 2] = "Anno1";
})(TipoGraficoIndiciEsteri = exports.TipoGraficoIndiciEsteri || (exports.TipoGraficoIndiciEsteri = {}));
;
var EReduxIndiciActionTypes;
(function (EReduxIndiciActionTypes) {
  EReduxIndiciActionTypes["INDICI_GET_MERCATI_START"] = "INDICI_GET_MERCATI_START";
  EReduxIndiciActionTypes["INDICI_GET_MERCATI_SUCCESS"] = "INDICI_GET_MERCATI_SUCCESS";
  EReduxIndiciActionTypes["INDICI_GET_TITOLI_START"] = "INDICI_GET_TITOLI_START";
  EReduxIndiciActionTypes["INDICI_GET_TITOLI_SUCCESS"] = "INDICI_GET_TITOLI_SUCCESS";
  EReduxIndiciActionTypes["SET_INDICI_MERCATO"] = "SET_INDICI_MERCATO";
  EReduxIndiciActionTypes["SET_INDICI_DENOMINAZIONE"] = "SET_INDICI_DENOMINAZIONE";
  EReduxIndiciActionTypes["INDICI_RESET_FORM"] = "INDICI_RESET_FORM";
  EReduxIndiciActionTypes["INDICI_GET_DETTAGLIO_INDICE_START"] = "INDICI_GET_DETTAGLIO_INDICE_START";
  EReduxIndiciActionTypes["INDICI_GET_DETTAGLIO_INDICE_SUCCESS"] = "INDICI_GET_DETTAGLIO_INDICE_SUCCESS";
  EReduxIndiciActionTypes["INDICI_SET_INDICE_SELEZIONATO"] = "INDICI_SET_INDICE_SELEZIONATO";
  EReduxIndiciActionTypes["INDICI_GET_DATI_INTRADAY_INDICE_START"] = "INDICI_GET_DATI_INTRADAY_INDICE_START";
  EReduxIndiciActionTypes["INDICI_GET_DATI_INTRADAY_INDICE_SUCCESS"] = "INDICI_GET_DATI_INTRADAY_INDICE_SUCCESS";
  EReduxIndiciActionTypes["INDICI_GET_DATI_STORICI_INDICE_START"] = "INDICI_GET_DATI_STORICI_INDICE_START";
  EReduxIndiciActionTypes["INDICI_GET_DATI_STORICI_INDICE_SUCCESS"] = "INDICI_GET_DATI_STORICI_INDICE_SUCCESS";
  EReduxIndiciActionTypes["INDICI_GET_SCHEDA_INDICE_START"] = "INDICI_GET_SCHEDA_INDICE_START";
  EReduxIndiciActionTypes["INDICI_GET_SCHEDA_INDICE_SUCCESS"] = "INDICI_GET_SCHEDA_INDICE_SUCCESS";
  EReduxIndiciActionTypes["INDICI_SET_TIPO_GRAFICO"] = "INDICI_SET_TIPO_GRAFICO";
  EReduxIndiciActionTypes["INDICI_SET_OFFSET"] = "INDICI_SET_OFFSET";
  EReduxIndiciActionTypes["INDICI_RESET"] = "INDICI_RESET";
  EReduxIndiciActionTypes["INDICI_RESET_DETTAGLIO"] = "INDICI_RESET_DETTAGLIO";
  EReduxIndiciActionTypes["INDICI_AGGIORNA_GRAFICO"] = "INDICI_AGGIORNA_GRAFICO";
  EReduxIndiciActionTypes["INDICI_ESTERI_AGGIORNA_GRAFICO"] = "INDICI_ESTERI_AGGIORNA_GRAFICO";
})(EReduxIndiciActionTypes = exports.EReduxIndiciActionTypes || (exports.EReduxIndiciActionTypes = {}));