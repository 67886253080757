"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BonificiSepaDtoToJSON = exports.BonificiSepaDtoFromJSONTyped = exports.BonificiSepaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BonificiSepaDtoFromJSON(json) {
  return BonificiSepaDtoFromJSONTyped(json, false);
}
exports.BonificiSepaDtoFromJSON = BonificiSepaDtoFromJSON;
function BonificiSepaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'bonifici': !runtime_1.exists(json, 'bonifici') ? undefined : json['bonifici'].map(_1.BonificoSepaDtoFromJSON)
  };
}
exports.BonificiSepaDtoFromJSONTyped = BonificiSepaDtoFromJSONTyped;
function BonificiSepaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'bonifici': value.bonifici === undefined ? undefined : value.bonifici.map(_1.BonificoSepaDtoToJSON)
  };
}
exports.BonificiSepaDtoToJSON = BonificiSepaDtoToJSON;