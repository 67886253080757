"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CondivisioneDtoCanaleComunicazioneEnum = exports.CondivisioneDtoToJSON = exports.CondivisioneDtoFromJSONTyped = exports.CondivisioneDtoFromJSON = void 0;
function CondivisioneDtoFromJSON(json) {
  return CondivisioneDtoFromJSONTyped(json, false);
}
exports.CondivisioneDtoFromJSON = CondivisioneDtoFromJSON;
function CondivisioneDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'canaleComunicazione': json['canaleComunicazione'],
    'destinatario': json['destinatario']
  };
}
exports.CondivisioneDtoFromJSONTyped = CondivisioneDtoFromJSONTyped;
function CondivisioneDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'canaleComunicazione': value.canaleComunicazione,
    'destinatario': value.destinatario
  };
}
exports.CondivisioneDtoToJSON = CondivisioneDtoToJSON;
/**
* @export
* @enum {string}
*/
var CondivisioneDtoCanaleComunicazioneEnum;
(function (CondivisioneDtoCanaleComunicazioneEnum) {
  CondivisioneDtoCanaleComunicazioneEnum["EMAIL"] = "EMAIL";
  CondivisioneDtoCanaleComunicazioneEnum["SMS"] = "SMS";
})(CondivisioneDtoCanaleComunicazioneEnum = exports.CondivisioneDtoCanaleComunicazioneEnum || (exports.CondivisioneDtoCanaleComunicazioneEnum = {}));