"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JobDocumentAttributesValueDtoToJSON = exports.JobDocumentAttributesValueDtoFromJSONTyped = exports.JobDocumentAttributesValueDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function JobDocumentAttributesValueDtoFromJSON(json) {
  return JobDocumentAttributesValueDtoFromJSONTyped(json, false);
}
exports.JobDocumentAttributesValueDtoFromJSON = JobDocumentAttributesValueDtoFromJSON;
function JobDocumentAttributesValueDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idsuidescription': !runtime_1.exists(json, 'ids:ui:description') ? undefined : json['ids:ui:description'],
    'idsalldocumentGroup': !runtime_1.exists(json, 'ids:all:documentGroup') ? undefined : json['ids:all:documentGroup'],
    'idswfconvertToPdfa': !runtime_1.exists(json, 'ids:wf:convertToPdfa') ? undefined : json['ids:wf:convertToPdfa'],
    'idswfsendToCds': !runtime_1.exists(json, 'ids:wf:sendToCds') ? undefined : json['ids:wf:sendToCds'],
    'idsfdqpdfFlattening': !runtime_1.exists(json, 'ids:fdq:pdfFlattening') ? undefined : json['ids:fdq:pdfFlattening'],
    'idswffilename': !runtime_1.exists(json, 'ids:wf:filename') ? undefined : json['ids:wf:filename'],
    'idsallmergeGroupInternal': !runtime_1.exists(json, 'ids:all:mergeGroupInternal') ? undefined : json['ids:all:mergeGroupInternal'],
    'idsallindexOrder': !runtime_1.exists(json, 'ids:all:indexOrder') ? undefined : json['ids:all:indexOrder']
  };
}
exports.JobDocumentAttributesValueDtoFromJSONTyped = JobDocumentAttributesValueDtoFromJSONTyped;
function JobDocumentAttributesValueDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'ids:ui:description': value.idsuidescription,
    'ids:all:documentGroup': value.idsalldocumentGroup,
    'ids:wf:convertToPdfa': value.idswfconvertToPdfa,
    'ids:wf:sendToCds': value.idswfsendToCds,
    'ids:fdq:pdfFlattening': value.idsfdqpdfFlattening,
    'ids:wf:filename': value.idswffilename,
    'ids:all:mergeGroupInternal': value.idsallmergeGroupInternal,
    'ids:all:indexOrder': value.idsallindexOrder
  };
}
exports.JobDocumentAttributesValueDtoToJSON = JobDocumentAttributesValueDtoToJSON;