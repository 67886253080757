"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.f24Reducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const f24_types_1 = require("./f24.types");
const initialState = {
  parametri: undefined,
  riepilogo: (0, api_rest_1.ModelloF24DtoFromJSON)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON)({}),
  infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  form: (0, f24_types_1.FormF24OrdinarioInitial)(),
  riepilogoRevoca: (0, api_rest_1.ModelloF24DtoFromJSON)({}),
  formRevoca: (0, f24_types_1.FormRevocaF24ORdinarioInitial)(),
  infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON)({}),
  bozzeF24Ordinario: [],
  currentBozza: (0, api_rest_1.ModelloF24DtoFromJSON)({}),
  dettaglioF24Pdf: undefined,
  quietanzaF24Pdf: undefined
  // documentSessionInitF24Result: undefined,
  // documentSessionTokenInfoF24Result: undefined,
  // documentSessionTokenDownloadF24Result: undefined
};
const f24Reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62;
  switch (action.type) {
    case __1.EReduxActionTypes.F24_ORDINARIO_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_GET_PARAMETRI_PER_RIPETI_SUCCESS:
      return {
        ...initialState,
        parametri: action.payload,
        form: {
          ...state.form,
          ripeti: true
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_RESET_RIEPILOGO:
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_CODICE_FISCALE:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            codiceFiscale: action.payload,
            codiceFiscaleError: (0, helpers_1.validationCFPI)(true, action.payload),
            codiceFiscaleDirty: true,
            aggiungiBeneficiarioRubrica: false,
            aggiungiBeneficiarioWhitelist: false,
            idContattoBeneficiario: undefined
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_NON_COINCIDENTE_ANNO_SOLARE:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            nonCoincidenteAnnoSolare: action.payload,
            nonCoincidenteAnnoSolareDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_COGNOME:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            cognome: action.payload,
            cognomeError: (0, helpers_1.validationField)(action.payload, ''),
            cognomeDirty: true,
            aggiungiBeneficiarioRubrica: false,
            aggiungiBeneficiarioWhitelist: false,
            idContattoBeneficiario: undefined
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_NOME:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            nome: action.payload,
            nomeError: '',
            nomeDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_DATA_NASCITA:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            dataDiNascita: action.payload,
            dataDiNascitaError: action.payload ? (0, helpers_1.validationFieldDate)(action.payload, [], false) : '',
            dataDiNascitaDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_SESSO:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            sesso: action.payload,
            sessoError: '',
            sessoDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_COMUNE:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            comune: action.payload,
            comuneError: '',
            comuneDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_PROVINCIA:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            provincia: action.payload,
            provinciaError: '',
            provinciaDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_DOMICILIO_FISCALE_COMUNE:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            domicilioFiscale: ((_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.contribuente) === null || _b === void 0 ? void 0 : _b.domicilioFiscale) ? {
              ...((_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.contribuente) === null || _d === void 0 ? void 0 : _d.domicilioFiscale),
              comune: action.payload,
              comuneError: '',
              comuneDirty: true
            } : undefined
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_DOMICILIO_FISCALE_PROVINCIA:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            domicilioFiscale: ((_f = (_e = state.form) === null || _e === void 0 ? void 0 : _e.contribuente) === null || _f === void 0 ? void 0 : _f.domicilioFiscale) ? {
              ...((_h = (_g = state.form) === null || _g === void 0 ? void 0 : _g.contribuente) === null || _h === void 0 ? void 0 : _h.domicilioFiscale),
              provincia: action.payload,
              provinciaError: '',
              provinciaDirty: true
            } : undefined
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_DOMICILIO_FISCALE_VIA_E_CIVICO:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            domicilioFiscale: ((_k = (_j = state.form) === null || _j === void 0 ? void 0 : _j.contribuente) === null || _k === void 0 ? void 0 : _k.domicilioFiscale) ? {
              ...((_m = (_l = state.form) === null || _l === void 0 ? void 0 : _l.contribuente) === null || _m === void 0 ? void 0 : _m.domicilioFiscale),
              viaENumeroCivico: action.payload,
              viaENumeroCivicoError: '',
              viaENumeroCivicoDirty: true
            } : undefined
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CODICE_FISCALE_COOBLIGATO:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            codiceFiscaleCoobligato: action.payload,
            codiceFiscaleCoobligatoError: (0, helpers_1.validationCFPI)(false, action.payload),
            codiceFiscaleCoobligatoDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CODICE_IDENTIFICATIVO_COOBLIGATO:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            codiceIdentificativo: action.payload,
            codiceIdentificativoDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ERARIO_CODICE_ATTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneErario: {
            ...state.form.sezioneErario,
            codiceAtto: action.payload,
            codiceAttoDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ERARIO_CODICE_UFFICIO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneErario: {
            ...state.form.sezioneErario,
            codiceUfficio: action.payload,
            codiceUfficioDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ERARIO_RIGA_CODICE_TRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneErario: {
            ...state.form.sezioneErario,
            righe: (0, helpers_1.validateRigheSezioneErario)(state.form.sezioneErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceTributo: action.payload,
              codiceTributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ERARIO_RIGA_RATEAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneErario: {
            ...state.form.sezioneErario,
            righe: (0, helpers_1.validateRigheSezioneErario)(state.form.sezioneErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              rateazione: action.payload,
              rateazioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ERARIO_RIGA_ANNO_RIFERIMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneErario: {
            ...state.form.sezioneErario,
            righe: (0, helpers_1.validateRigheSezioneErario)(state.form.sezioneErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              annoDiRiferimento: action.payload,
              annoDiRiferimentoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ERARIO_RIGA_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneErario: {
            ...state.form.sezioneErario,
            righe: (0, helpers_1.validateRigheSezioneErario)(state.form.sezioneErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ERARIO_RIGA_CREDITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneErario: {
            ...state.form.sezioneErario,
            righe: (0, helpers_1.validateRigheSezioneErario)(state.form.sezioneErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoCredito: action.payload,
              importoCreditoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_INPS_RIGA_CODICE_SEDE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: (0, helpers_1.validateRigheSezioneINPS)(state.form.sezioneINPS.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceSede: action.payload,
              codiceSedeDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_INPS_RIGA_CAUSALE_CONTRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: (0, helpers_1.validateRigheSezioneINPS)(state.form.sezioneINPS.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              causaleContributo: action.payload,
              causaleContributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_INPS_RIGA_MATRICOLA:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: (0, helpers_1.validateRigheSezioneINPS)(state.form.sezioneINPS.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              matricolaINPS: action.payload,
              matricolaINPSDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_INPS_RIGA_PERIODO_DA:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: (0, helpers_1.validateRigheSezioneINPS)(state.form.sezioneINPS.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              periodoDa: action.payload,
              periodoDaDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_INPS_RIGA_PERIODO_A:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: (0, helpers_1.validateRigheSezioneINPS)(state.form.sezioneINPS.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              periodoA: action.payload,
              periodoADirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_INPS_RIGA_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: (0, helpers_1.validateRigheSezioneINPS)(state.form.sezioneINPS.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_INPS_RIGA_CREDITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: (0, helpers_1.validateRigheSezioneINPS)(state.form.sezioneINPS.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoCredito: action.payload,
              importoCreditoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_REGIONE_RIGA_CODICE_REGIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneRegioni: {
            ...state.form.sezioneRegioni,
            righe: (0, helpers_1.validateRigheSezioneRegioni)(state.form.sezioneRegioni.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceRegione: action.payload,
              codiceRegioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_REGIONE_RIGA_CODICE_TRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneRegioni: {
            ...state.form.sezioneRegioni,
            righe: (0, helpers_1.validateRigheSezioneRegioni)(state.form.sezioneRegioni.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceTributo: action.payload,
              codiceTributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_REGIONE_RIGA_RATEAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneRegioni: {
            ...state.form.sezioneRegioni,
            righe: (0, helpers_1.validateRigheSezioneRegioni)(state.form.sezioneRegioni.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              rateazione: action.payload,
              rateazioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_REGIONE_RIGA_ANNO_RIFERIMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneRegioni: {
            ...state.form.sezioneRegioni,
            righe: (0, helpers_1.validateRigheSezioneRegioni)(state.form.sezioneRegioni.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              annoRiferimento: action.payload,
              annoRiferimentoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_REGIONE_RIGA_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneRegioni: {
            ...state.form.sezioneRegioni,
            righe: (0, helpers_1.validateRigheSezioneRegioni)(state.form.sezioneRegioni.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_REGIONE_RIGA_CREDITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneRegioni: {
            ...state.form.sezioneRegioni,
            righe: (0, helpers_1.validateRigheSezioneRegioni)(state.form.sezioneRegioni.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoCredito: action.payload,
              importoCreditoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_DETRAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            detrazione: action.payload,
            detrazioneDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_CODICE_REGIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceRegione: action.payload,
              codiceRegioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_R:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              R: action.payload,
              RDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_IV:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              IV: action.payload,
              IVDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_A:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              A: action.payload,
              ADirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_S:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              S: action.payload,
              SDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_NI:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              NI: action.payload,
              NIDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_CODICE_TRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceTributo: action.payload,
              codiceTributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_RATEAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              rateazione: action.payload,
              rateazioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_ANNO_RIFERIMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              annoRiferimento: action.payload,
              annoRiferimentoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_IMU_RIGA_CREDITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: (0, helpers_1.validateRigheSezioneIMU)(state.form.sezioneIMU.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoCredito: action.payload,
              importoCreditoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CODICE_SEDE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiIL)(state.form.sezioneAltriEntiIL.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceSede: action.payload,
              codiceSedeDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CODICE_DITTA:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiIL)(state.form.sezioneAltriEntiIL.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceDitta: action.payload,
              codiceDittaDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CC:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiIL)(state.form.sezioneAltriEntiIL.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              CC: action.payload,
              CCDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_NUMERO_RIFERIMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiIL)(state.form.sezioneAltriEntiIL.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              numeroDiRiferimento: action.payload,
              numeroDiRiferimentoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CAUSALE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiIL)(state.form.sezioneAltriEntiIL.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              causale: action.payload,
              causaleDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiIL)(state.form.sezioneAltriEntiIL.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CREDITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiIL)(state.form.sezioneAltriEntiIL.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoCredito: action.payload,
              importoCreditoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_ENTE:
      {
        return {
          ...state,
          form: {
            ...state.form,
            sezioneAltriEntiMN: {
              ...state.form.sezioneAltriEntiMN,
              righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
                ...r
              } : {
                ...r,
                codiceEnte: action.payload,
                codiceEnteDirty: true
              }))
            },
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_SEDE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceSede: action.payload,
              codiceSedeDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CAUSALE_CONTRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              causaleContributo: action.payload,
              causaleContributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_POSIZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codicePosizione: action.payload,
              codicePosizioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_PERIODO_DA:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              periodoDa: action.payload,
              periodoDaDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_PERIODO_A:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              periodoA: action.payload,
              periodoADirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CREDITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            righe: (0, helpers_1.validateRigheSezioneAltriEntiMN)(state.form.sezioneAltriEntiMN.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoCredito: action.payload,
              importoCreditoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneDataESaldo: {
            ...state.form.sezioneDataESaldo,
            data: action.payload,
            dataError: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['F24.FESTIVI'] : [],
            // festivi,
            true, new Date().toDateString()),
            dataDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_FORM_SUBMITTED:
      {
        return {
          ...state,
          form: {
            ...state.form,
            formSubmitted: action.payload
          }
        };
      }
    case __1.EReduxActionTypes.F24_ORDINARIO_GET_INFO_DISPOSITIVA_SUCCESS:
      return {
        ...state,
        infoDispositiva: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_AUTORIZZA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_RIEPILOGO_REVOCA:
      return {
        ...state,
        formRevoca: initialState.formRevoca,
        riepilogoRevoca: action.payload
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_PROVENIENZA:
      return {
        ...state,
        formRevoca: {
          ...state.formRevoca,
          provenienza: action.payload
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_STEP_REVOCA:
      return {
        ...state,
        formRevoca: {
          ...state.formRevoca,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_GET_INFO_REVOCA_SUCCESS:
      return {
        ...state,
        infoRevoca: action.payload,
        esitoRevoca: initialState.esitoRevoca
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_GET_BOZZE_SUCCESS:
      return {
        ...state,
        bozzeF24Ordinario: action.payload
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_AUTORIZZA_REVOCA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          parametri: initialState.parametri,
          riepilogo: initialState.riepilogo,
          infoDispositiva: initialState.infoDispositiva,
          esito: initialState.esito,
          form: initialState.form,
          riepilogoRevoca: initialState.riepilogoRevoca,
          esitoRevoca: action.payload
        };
      } else {
        return {
          ...state,
          infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoRevoca, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.F24_ORDINARIO_GET_F24_FROM_BOZZA:
      {
        const rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.codiceRapporto) || state.form.rapportoSelezionato;
        const data = action.payload.dataOperazione && moment(action.payload.dataOperazione) >= moment() ? moment(action.payload.dataOperazione).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        return {
          ...state,
          form: {
            ...state.form,
            contribuente: {
              ...state.form.contribuente,
              codiceFiscale: ((_o = action.payload.contribuente) === null || _o === void 0 ? void 0 : _o.codiceFiscale) || '',
              codiceFiscaleError: (0, helpers_1.validationCFPI)(true, ((_p = action.payload.contribuente) === null || _p === void 0 ? void 0 : _p.codiceFiscale) || ''),
              codiceFiscaleDirty: false,
              nome: ((_r = (_q = action.payload.contribuente) === null || _q === void 0 ? void 0 : _q.datiAnagrafici) === null || _r === void 0 ? void 0 : _r.nome) || '',
              nomeError: '',
              nomeDirty: false,
              cognome: ((_t = (_s = action.payload.contribuente) === null || _s === void 0 ? void 0 : _s.datiAnagrafici) === null || _t === void 0 ? void 0 : _t.cognome) || '',
              cognomeError: '',
              cognomeDirty: false,
              dataDiNascita: (_x = (_w = (_v = (_u = action.payload.contribuente) === null || _u === void 0 ? void 0 : _u.datiAnagrafici) === null || _v === void 0 ? void 0 : _v.dataNascita) === null || _w === void 0 ? void 0 : _w.toString()) !== null && _x !== void 0 ? _x : undefined,
              dataDiNascitaError: (0, helpers_1.validationFieldDate)((_0 = (_z = (_y = action.payload.contribuente) === null || _y === void 0 ? void 0 : _y.datiAnagrafici) === null || _z === void 0 ? void 0 : _z.dataNascita) !== null && _0 !== void 0 ? _0 : new Date(), [], false),
              dataDiNascitaDirty: false,
              sesso: ((_2 = (_1 = action.payload.contribuente) === null || _1 === void 0 ? void 0 : _1.datiAnagrafici) === null || _2 === void 0 ? void 0 : _2.sesso) || '',
              sessoDirty: false,
              sessoError: '',
              comune: ((_4 = (_3 = action.payload.contribuente) === null || _3 === void 0 ? void 0 : _3.datiAnagrafici) === null || _4 === void 0 ? void 0 : _4.comune) || '',
              comuneDirty: false,
              comuneError: '',
              provincia: ((_6 = (_5 = action.payload.contribuente) === null || _5 === void 0 ? void 0 : _5.datiAnagrafici) === null || _6 === void 0 ? void 0 : _6.provincia) || '',
              provinciaDirty: false,
              provinciaError: '',
              domicilioFiscale: ((_8 = (_7 = state.form) === null || _7 === void 0 ? void 0 : _7.contribuente) === null || _8 === void 0 ? void 0 : _8.domicilioFiscale) ? {
                ...((_10 = (_9 = state.form) === null || _9 === void 0 ? void 0 : _9.contribuente) === null || _10 === void 0 ? void 0 : _10.domicilioFiscale),
                comune: ((_13 = (_12 = (_11 = action.payload) === null || _11 === void 0 ? void 0 : _11.contribuente) === null || _12 === void 0 ? void 0 : _12.domicilio) === null || _13 === void 0 ? void 0 : _13.comune) || '',
                comuneDirty: false,
                comuneError: '',
                provincia: ((_16 = (_15 = (_14 = action.payload) === null || _14 === void 0 ? void 0 : _14.contribuente) === null || _15 === void 0 ? void 0 : _15.domicilio) === null || _16 === void 0 ? void 0 : _16.provincia) || '',
                provinciaDirty: false,
                provinciaError: '',
                viaENumeroCivico: ((_19 = (_18 = (_17 = action.payload) === null || _17 === void 0 ? void 0 : _17.contribuente) === null || _18 === void 0 ? void 0 : _18.domicilio) === null || _19 === void 0 ? void 0 : _19.indirizzo) || '',
                viaENumeroCivicoDirty: false,
                viaENumeroCivicoError: ''
              } : undefined
            },
            sezioneErario: {
              ...state.form.sezioneErario,
              codiceAtto: (_22 = (_21 = (_20 = action === null || action === void 0 ? void 0 : action.payload) === null || _20 === void 0 ? void 0 : _20.erario) === null || _21 === void 0 ? void 0 : _21.codiceAtto) === null || _22 === void 0 ? void 0 : _22.toString(),
              codiceAttoDirty: false,
              codiceAttoError: '',
              codiceUfficio: (_24 = (_23 = action === null || action === void 0 ? void 0 : action.payload) === null || _23 === void 0 ? void 0 : _23.erario) === null || _24 === void 0 ? void 0 : _24.codiceUfficio,
              codiceUfficioDirty: false,
              codiceUfficioError: '',
              righe: (0, helpers_1.mapRigheSezioneErario)(((_25 = action.payload.erario) === null || _25 === void 0 ? void 0 : _25.righe) || [], action.platform)
            },
            sezioneMotivoPagamento: {
              ...state.form.sezioneMotivoPagamento,
              saldo: (_27 = (_26 = action === null || action === void 0 ? void 0 : action.payload) === null || _26 === void 0 ? void 0 : _26.semplificato) === null || _27 === void 0 ? void 0 : _27.totalSemplificato,
              data: (_29 = (_28 = action.payload.dataOperazione) === null || _28 === void 0 ? void 0 : _28.toString()) !== null && _29 !== void 0 ? _29 : undefined,
              dataError: action.payload.dataOperazione ? (0, helpers_1.validationFieldDate)(action.payload.dataOperazione, [], false) : '',
              dataDirty: false,
              righe: (0, helpers_1.mapRigheSezioneMotivoPagamento)(((_30 = action.payload.semplificato) === null || _30 === void 0 ? void 0 : _30.righe) || [], action.platform)
            },
            sezioneINPS: {
              ...state.form.sezioneINPS,
              totale: (0, helpers_1.formattedNumberToNumber)((_32 = (_31 = action === null || action === void 0 ? void 0 : action.payload) === null || _31 === void 0 ? void 0 : _31.inps) === null || _32 === void 0 ? void 0 : _32.totalInps),
              righe: (0, helpers_1.mapRigheSezioneINPS)(((_33 = action.payload.inps) === null || _33 === void 0 ? void 0 : _33.righe) || [], action.platform)
            },
            sezioneRegioni: {
              ...state.form.sezioneRegioni,
              totale: (0, helpers_1.formattedNumberToNumber)((_35 = (_34 = action === null || action === void 0 ? void 0 : action.payload) === null || _34 === void 0 ? void 0 : _34.regioni) === null || _35 === void 0 ? void 0 : _35.totalRegioni),
              righe: (0, helpers_1.mapRigheSezioneRegioni)(((_36 = action.payload.regioni) === null || _36 === void 0 ? void 0 : _36.righe) || [], action.platform)
            },
            sezioneIMU: {
              ...state.form.sezioneIMU,
              detrazione: (_38 = (_37 = action === null || action === void 0 ? void 0 : action.payload) === null || _37 === void 0 ? void 0 : _37.tributiLocali) === null || _38 === void 0 ? void 0 : _38.detrazione,
              detrazioneError: '',
              detrazioneDirty: false,
              totale: (0, helpers_1.formattedNumberToNumber)((_40 = (_39 = action === null || action === void 0 ? void 0 : action.payload) === null || _39 === void 0 ? void 0 : _39.tributiLocali) === null || _40 === void 0 ? void 0 : _40.totalImu),
              righe: (0, helpers_1.mapRigheSezioneIMU)(((_41 = action.payload.tributiLocali) === null || _41 === void 0 ? void 0 : _41.righe) || [], action.platform)
            },
            sezioneAltriEntiIL: {
              ...state.form.sezioneAltriEntiIL,
              totale: (0, helpers_1.formattedNumberToNumber)((_43 = (_42 = action === null || action === void 0 ? void 0 : action.payload) === null || _42 === void 0 ? void 0 : _42.inail) === null || _43 === void 0 ? void 0 : _43.totalInail),
              righe: (0, helpers_1.mapRigheSezioneAltriEntiIL)(((_44 = action.payload.inail) === null || _44 === void 0 ? void 0 : _44.righe) || [], action.platform)
            },
            sezioneAltriEntiMN: {
              ...state.form.sezioneAltriEntiMN,
              totale: (0, helpers_1.formattedNumberToNumber)((_46 = (_45 = action === null || action === void 0 ? void 0 : action.payload) === null || _45 === void 0 ? void 0 : _45.entiPrevidenziali) === null || _46 === void 0 ? void 0 : _46.totalEntiPrevidenziali),
              righe: (0, helpers_1.mapRigheSezioneAltriEntiMN)(((_48 = (_47 = action.payload.entiPrevidenziali) === null || _47 === void 0 ? void 0 : _47.righe) === null || _48 === void 0 ? void 0 : _48.map((r, i) => {
                var _a;
                if (i == 0) {
                  return {
                    ...r,
                    codiceEnte: ((_a = action.payload.entiPrevidenziali) === null || _a === void 0 ? void 0 : _a.codice) || ''
                  };
                }
                return {
                  ...r,
                  codiceEnte: ''
                };
              })) || [], action.platform)
            },
            sezioneDataESaldo: {
              ...state.form.sezioneDataESaldo,
              data: data,
              dataError: (0, helpers_1.validationFieldDate)(data, null, false, new Date().toDateString()),
              dataDirty: true
            },
            sezioneAccise: {
              ...state.form.sezioneAccise,
              codiceUfficio: (_50 = (_49 = action === null || action === void 0 ? void 0 : action.payload) === null || _49 === void 0 ? void 0 : _49.accise) === null || _50 === void 0 ? void 0 : _50.codiceUfficio,
              codiceUfficioError: '',
              codiceUfficioDirty: false,
              codiceAtto: (_52 = (_51 = action === null || action === void 0 ? void 0 : action.payload) === null || _51 === void 0 ? void 0 : _51.accise) === null || _52 === void 0 ? void 0 : _52.codiceAtto,
              codiceAttoError: '',
              codiceAttoDirty: false,
              totalAccise: (0, helpers_1.formattedNumberToNumber)((_54 = (_53 = action === null || action === void 0 ? void 0 : action.payload) === null || _53 === void 0 ? void 0 : _53.accise) === null || _54 === void 0 ? void 0 : _54.totalAccise),
              righe: (0, helpers_1.mapRigheSezioneAccise)(((_55 = action.payload.accise) === null || _55 === void 0 ? void 0 : _55.righe) || [], action.platform)
            },
            sezioneAltroErario: {
              ...state.form.sezioneAltroErario,
              codiceUfficio: (_57 = (_56 = action === null || action === void 0 ? void 0 : action.payload) === null || _56 === void 0 ? void 0 : _56.identificativi) === null || _57 === void 0 ? void 0 : _57.codiceUfficio,
              codiceUfficioError: '',
              codiceUfficioDirty: false,
              codiceAtto: (_59 = (_58 = action === null || action === void 0 ? void 0 : action.payload) === null || _58 === void 0 ? void 0 : _58.identificativi) === null || _59 === void 0 ? void 0 : _59.codiceAtto,
              codiceAttoError: '',
              codiceAttoDirty: false,
              totalIdentificativi: (0, helpers_1.formattedNumberToNumber)((_61 = (_60 = action === null || action === void 0 ? void 0 : action.payload) === null || _60 === void 0 ? void 0 : _60.identificativi) === null || _61 === void 0 ? void 0 : _61.totalIdentificativi),
              righe: (0, helpers_1.mapRigheSezioneAltroErario)(((_62 = action.payload.identificativi) === null || _62 === void 0 ? void 0 : _62.righe) || [], action.platform)
            },
            rapportoSelezionato: rapportoSelezionato,
            bozzaSalvata: true
          },
          currentBozza: action.noBozza ? initialState.currentBozza : action.payload
        };
      }
      ;
    /*case EReduxActionTypes.F24_GET_F24_FROM_SCANNER: {
           const f24 = action.payload;
      const dataDiNascita =
        f24.contribuente?.dataNascita?.value
          ? moment(f24.contribuente?.dataNascita?.value.slice(0, 8), 'DDMMYYYY')
          : undefined;
      const dataPagamento =
        f24.estremiVersamento?.data?.value
          ? moment(f24.estremiVersamento.data.value.slice(0, 8), 'DDMMYYYY')
          : undefined;
           return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            codiceFiscale: f24.contribuente?.codiceFiscale?.value || '',
            codiceFiscaleError: validationCFPI(true, f24.contribuente?.codiceFiscale?.value || ''),
            codiceFiscaleDirty: false,
            codiceFiscaleBoundingBox: f24.contribuente?.codiceFiscale?.boundingBox || undefined,
            nonCoincidenteAnnoSolare: initialState.form.contribuente.nonCoincidenteAnnoSolare,
            nonCoincidenteAnnoSolareBoundingBox: initialState.form.contribuente.nonCoincidenteAnnoSolareBoundingBox,
            cognome: f24.contribuente?.cognome?.value || '',
            cognomeError: '',
            cognomeDirty: false,
            cognomeBoundingBox: f24.contribuente?.cognome?.boundingBox || undefined,
            nome: f24.contribuente?.nome?.value || '',
            nomeError: '',
            nomeDirty: false,
            nomeBoundingBox: f24.contribuente?.nome?.boundingBox || undefined,
            dataDiNascita: dataDiNascita?.isValid() ? dataDiNascita.format('YYYY-MM-DD') : undefined,
            dataDiNascitaError: validationFieldDate(dataDiNascita, [], false),
            dataDiNascitaDirty: false,
            dataDiNascitaBoundingBox: f24.contribuente?.dataNascita?.boundingBox || undefined,
            sesso: f24.contribuente?.sesso?.value || '',
            sessoError: '',
            sessoDirty: false,
            sessoBoundingBox: f24.contribuente?.sesso?.boundingBox || undefined,
            comune: f24.contribuente?.comuneNascita?.value || '',
            comuneError: '',
            comuneDirty: false,
            comuneBoundingBox: f24.contribuente?.comuneNascita?.boundingBox || undefined,
            provincia: f24.contribuente?.provinciaNascita?.value || '',
            provinciaError: '',
            provinciaDirty: false,
            provinciaBoundingBox: f24.contribuente?.provinciaNascita?.boundingBox || undefined,
            domicilioFiscale: {
              ...state.form?.contribuente?.domicilioFiscale,
              comune: f24.contribuente?.comuneDomicilio?.value || '',
              comuneError: '',
              comuneDirty: false,
              comuneBoundingBox: f24.contribuente?.comuneDomicilio?.boundingBox || undefined,
              provincia: f24.contribuente?.provinciaDomicilio?.value || '',
              provinciaError: '',
              provinciaDirty: false,
              provinciaBoundingBox: f24.contribuente?.provinciaDomicilio?.boundingBox || undefined,
              viaENumeroCivico: f24.contribuente?.indirizzoDomicilio?.value || '',
              viaENumeroCivicoError: '',
              viaENumeroCivicoDirty: false,
              viaENumeroCivicoBoundingBox: f24.contribuente?.indirizzoDomicilio?.boundingBox || undefined
            },
            codiceFiscaleCoobligato: f24.contribuente?.codiceFiscaleCoobbligato?.value || '',
            codiceFiscaleCoobligatoError: validationCFPI(false, f24.contribuente?.codiceFiscaleCoobbligato?.value || ''),
            codiceFiscaleCoobligatoDirty: false,
            codiceFiscaleCoobligatoBoundingBox: f24.contribuente?.codiceFiscaleCoobbligato?.boundingBox || undefined,
            codiceIdentificativo: f24.contribuente?.codiceIdentificativo?.value || '',
            codiceIdentificativoError: '',
            codiceIdentificativoDirty: false,
            codiceIdentificativoBoundingBox: f24.contribuente?.codiceIdentificativo?.boundingBox || undefined,
            aggiungiBeneficiarioRubrica: initialState.form.contribuente.aggiungiBeneficiarioRubrica,
            aggiungiBeneficiarioWhitelist: initialState.form.contribuente.aggiungiBeneficiarioWhitelist,
            idContattoBeneficiario: initialState.form.contribuente.idContattoBeneficiario
          },
          sezioneErario: {
            ...state.form.sezioneErario,
            codiceAtto: f24.sezioneErario?.codiceAtto?.value || undefined,
            codiceAttoError: '',
            codiceAttoDirty: false,
            codiceAttoBoundingBox: f24.sezioneErario?.codiceAtto?.boundingBox || undefined,
            codiceUfficio: f24.sezioneErario?.codiceUfficio?.value || undefined,
            codiceUfficioError: '',
            codiceUfficioDirty: false,
            codiceUfficioBoundingBox: f24.sezioneErario?.codiceUfficio?.boundingBox || undefined,
            righe: mapRigheSezioneErario(f24.sezioneErario?.rows?.map(row => {
              const _row: RigaSezioneErarioDto & RigaSezioneErarioBoundingBox = {
                codiceTributo: row.codiceTributo?.value || '',
                // codiceTributoBoundingBox: row.codiceTributo?.boundingBox || undefined,
                infoTributo: row.rateazione?.value || '',
                // rateazioneBoundingBox: row.rateazione?.boundingBox || undefined,
                anno: formatStringToNumber(row.annoRiferimento?.value) || 0,
                // annoDiRiferimentoBoundingBox: row.annoRiferimento?.boundingBox || undefined,
                importoDebito: row.importoDebito?.value || 0,
                // importoDebitoBoundingBox: row.importoDebito?.boundingBox || undefined,
                importoCredito: row.importoCredito?.value || 0,
                // importoCreditoBoundingBox: row.importoCredito?.boundingBox || undefined
              };
              return _row;
            }) || [], 'desktop')
          },
          sezioneINPS: {
            ...state.form.sezioneINPS,
            righe: mapRigheSezioneINPS(f24.sezioneInps?.rows?.map(row => {
              const _row: RigaSezioneInpsDto & RigaSezioneInpsBoundingBox = {
                codiceSede: row.codiceSede?.value || '',
                // codiceSedeBoundingBox: row.codiceSede?.boundingBox || undefined,
                causaleContributo: row.causaleContributo?.value || '',
                // causaleContributoBoundingBox: row.causaleContributo?.boundingBox || undefined,
                matricolaInps: row.matricolaInps?.value || '',
                // matricolaINPSBoundingBox: row.matricolaInps?.boundingBox || undefined,
                periodoDaMese: row.dataDa?.value ? formatStringToNumber(moment(row.dataDa.value, 'MMYYYY').format('MM')) || 0 : 0,
                periodoDaAnno: row.dataDa?.value ? formatStringToNumber(moment(row.dataDa.value, 'MMYYYY').format('YYYY')) || 0 : 0,
                // periodoDaBoundingBox: row.dataDa?.boundingBox || undefined,
                periodoAMese: row.dataA?.value ? formatStringToNumber(moment(row.dataA.value, 'MMYYYY').format('MM')) || 0 : 0,
                periodoAAnno: row.dataA?.value ? formatStringToNumber(moment(row.dataA.value, 'MMYYYY').format('YYYY')) || 0 : 0,
                // periodoABoundingBox: row.dataA?.boundingBox || undefined,
                importoDebito: row.importoDebito?.value || 0,
                // importoDebitoBoundingBox: row.importoDebito?.boundingBox || undefined,
                importoCredito: row.importoCredito?.value || 0,
                // importoCreditoBoundingBox: row.importoCredito?.boundingBox || undefined
              };
              return _row;
            }) || [], 'desktop')
          },
          sezioneRegioni: {
            ...state.form.sezioneRegioni,
            righe: mapRigheSezioneRegioni(f24.sezioneRegione?.rows?.map(row => {
              const _row: RigaSezioneRegioniDto & RigaSezioneRegioniBoundingBox = {
                codiceRegione: row.codiceRegione?.value || '',
                // codiceRegioneBoundingBox: row.codiceRegione?.boundingBox || undefined,
                codiceTributo: row.codiceTributo?.value || '',
                // codiceTributoBoundingBox: row.codiceTributo?.boundingBox || undefined,
                infoTributo: row.rateazione?.value || '',
                // rateazioneBoundingBox: row.rateazione?.boundingBox || undefined,
                annoRiferimento: formatStringToNumber(row.annoRiferimento?.value) || 0,
                // annoRiferimentoBoundingBox: row.annoRiferimento?.boundingBox || undefined,
                importoDebito: row.importoDebito?.value || 0,
                // importoDebitoBoundingBox: row.importoDebito?.boundingBox || undefined,
                importoCredito: row.importoCredito?.value || 0,
                // importoCreditoBoundingBox: row.importoCredito?.boundingBox || undefined
              };
              return _row;
            }) || [], 'desktop')
          },
          sezioneIMU: {
            ...state.form.sezioneIMU,
            righe: mapRigheSezioneIMU(f24.sezioneImu?.rows?.map(row => {
              const _row: RigaSezioneTributiLocaliDto & RigaSezioneIMUBoundingBox = {
                codiceEnte: row.codiceEnte?.value || '',
                // codiceRegioneBoundingBox: row.codiceEnte?.boundingBox || undefined,
                ravvedimento:
                  row.flagRavvedimento
                    ? RigaSezioneTributiLocaliDtoRavvedimentoEnum.S
                    : RigaSezioneTributiLocaliDtoRavvedimentoEnum.N,
                // RBoundingBox: row.flagRavvedimento?.boundingBox || undefined,
                immVariati:
                  row.flagImmobiliVariati
                    ? RigaSezioneTributiLocaliDtoImmVariatiEnum.S
                    : RigaSezioneTributiLocaliDtoImmVariatiEnum.N,
                // IVBoundingBox: row.flagImmobiliVariati?.boundingBox || undefined,
                acconto:
                  row.flagAcconto
                    ? RigaSezioneTributiLocaliDtoAccontoEnum.S
                    : RigaSezioneTributiLocaliDtoAccontoEnum.N,
                // ABoundingBox: row.flagAcconto?.boundingBox || undefined,
                saldo:
                  row.flagSaldo
                    ? RigaSezioneTributiLocaliDtoSaldoEnum.S
                    : RigaSezioneTributiLocaliDtoSaldoEnum.N,
                // SBoundingBox: row.flagSaldo?.boundingBox || undefined,
                numeroImmobili: row.numeroImmobili?.value || 0,
                // NIBoundingBox: row.numeroImmobili?.boundingBox || undefined,
                codiceTributo: row.codiceTributo?.value || '',
                // codiceTributoBoundingBox: row.codiceTributo?.boundingBox || undefined,
                infoTributo: row.rateazione?.value || '',
                // rateazioneBoundingBox: row.rateazione?.boundingBox || undefined,
                annoRiferimento: formatStringToNumber(row.annoRiferimento?.value) || 0,
                // annoRiferimentoBoundingBox: row.annoRiferimento?.boundingBox || undefined,
                importoDebito: row.importoDebito?.value || 0,
                // importoDebitoBoundingBox: row.importoDebito?.boundingBox || undefined,
                importoCredito: row.importoCredito?.value || 0,
                // importoCreditoBoundingBox: row.importoCredito?.boundingBox || undefined
              };
              return _row;
            }) || [], 'desktop'),
            detrazione: f24.sezioneImu?.detrazione?.value || undefined,
            detrazioneError: '',
            detrazioneDirty: false,
            detrazioneBoundingBox: f24.sezioneImu?.detrazione?.boundingBox || undefined
          },
          sezioneAltriEntiIL: {
            ...state.form.sezioneAltriEntiIL,
            righe: mapRigheSezioneAltriEntiIL(f24.sezioneAltriEnti?.inailRows?.map(row => {
              const _row: RigaSezioneInailDto & RigaSezioneAltriEntiILBoundingBox = {
                codiceSede: row.codiceSede?.value || '',
                // codiceSedeBoundingBox: row.codiceSede?.boundingBox || undefined,
                codiceDitta: row.codiceDitta?.value || '',
                // codiceDittaBoundingBox: row.codiceDitta?.boundingBox || undefined,
                cc: row.cc?.value || '',
                // CCBoundingBox: row.cc?.boundingBox || undefined,
                riferimento: row.numeroRiferimento?.value || '',
                // numeroDiRiferimentoBoundingBox: row.numeroRiferimento?.boundingBox || undefined,
                causale: row.causale?.value || '',
                // causaleBoundingBox: row.causale?.boundingBox || undefined,
                importoDebito: row.importoDebito?.value || 0,
                // importoDebitoBoundingBox: row.importoDebito?.boundingBox || undefined,
                importoCredito: row.importoCredito?.value || 0,
                // importoCreditoBoundingBox: row.importoCredito?.boundingBox || undefined
              };
              return _row;
            }) || [], 'desktop')
          },
          sezioneAltriEntiMN: {
            ...state.form.sezioneAltriEntiMN,
            codiceEnte: f24.sezioneAltriEnti?.altriEntiRows?.[0]?.codiceEnte?.value || undefined,
            codiceEnteError: '',
            codiceEnteDirty: true,
            codiceEnteBoundingBox: f24.sezioneAltriEnti?.altriEntiRows?.[0]?.codiceEnte?.boundingBox || undefined,
            righe: mapRigheSezioneAltriEntiMN(f24.sezioneAltriEnti?.altriEntiRows?.map(row => {
              const _row: RigaSezioneEntiPrevidenzialiDto & RigaSezioneAltriEntiMNBoundingBox = {
                codiceSede: row.codiceSede?.value || '',
                // codiceSedeBoundingBox: row.codiceSede?.boundingBox || undefined,
                causale: row.causaleContributo?.value || '',
                // causaleContributoBoundingBox: row.causaleContributo?.boundingBox || undefined,
                codicePosizione: row.codicePosizione?.value || '',
                // codicePosizioneBoundingBox: row.codicePosizione?.boundingBox || undefined,
                periodoDaMese: row.dataDa?.value ? formatStringToNumber(moment(row.dataDa.value, 'MMYYYY').format('MM')) || 0 : 0,
                periodoDaAnno: row.dataDa?.value ? formatStringToNumber(moment(row.dataDa.value, 'MMYYYY').format('YYYY')) || 0 : 0,
                // periodoDaBoundingBox: row.dataDa?.boundingBox || undefined,
                periodoAMese: row.dataA?.value ? formatStringToNumber(moment(row.dataA.value, 'MMYYYY').format('MM')) || 0 : 0,
                periodoAAnno: row.dataA?.value ? formatStringToNumber(moment(row.dataA.value, 'MMYYYY').format('YYYY')) || 0 : 0,
                // periodoABoundingBox: row.dataA?.boundingBox || undefined,
                importoDebito: row.importoDebito?.value || 0,
                // importoDebitoBoundingBox: row.importoDebito?.boundingBox || undefined,
                importoCredito: row.importoCredito?.value || 0,
                // importoCreditoBoundingBox: row.importoCredito?.boundingBox || undefined
              };
              return _row;
            }) || [], 'desktop', f24.sezioneAltriEnti?.altriEntiRows?.[0]?.codiceEnte?.value || undefined, '')
          },
          sezioneDataESaldo: {
            ...state.form.sezioneDataESaldo,
            data: dataPagamento?.isValid() ? dataPagamento.format('YYYY-MM-DD') : undefined,
            dataError: validationFieldDate(dataPagamento, null, false, new Date().toDateString()),
            dataDirty: false,
            dataBoundingBox: f24.estremiVersamento?.data?.boundingBox || undefined
          },
          bozzaSalvata: initialState.form.bozzaSalvata
        },
        currentBozza: initialState.currentBozza
      };
    };*/
    case __1.EReduxActionTypes.F24_ORDINARIO_ADD_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeF24Ordinario: state.bozzeF24Ordinario ? [...state.bozzeF24Ordinario, action.payload] : [],
        form: {
          ...state.form,
          bozzaSalvata: true
        },
        currentBozza: initialState.currentBozza
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_DEL_BOZZA_SUCCESS:
      const newBozze = state.bozzeF24Ordinario ? state.bozzeF24Ordinario.filter(val => val.id !== action.payload) : [];
      return {
        ...state,
        bozzeF24Ordinario: newBozze
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_AGGIUNGI_BENEFICIARIO_RUBRICA:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            aggiungiBeneficiarioRubrica: action.payload
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_CONTRIBUENTE_AGGIUNGI_BENEFICIARIO_WHITE_LIST:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            aggiungiBeneficiarioWhitelist: action.payload
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_SET_ID_CONTATTO_BENEFICIARIO:
      return {
        ...state,
        form: {
          ...state.form,
          contribuente: {
            ...state.form.contribuente,
            idContattoBeneficiario: action.payload
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_CHECK_F24_ORDINARIO_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_CODICE_UFFICIO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            codiceUfficio: action.payload,
            codiceUfficioDirty: true,
            codiceUfficioError: ''
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_CODICE_ATTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            codiceAtto: action.payload,
            codiceAttoDirty: true,
            codiceAttoError: ''
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_TOTAL:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            totalAccise: action.payload
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_CODICE_ENTE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceEnte: action.payload,
              codiceEnteError: '',
              codiceEnteDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_PROVINCIA:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              provincia: action.payload,
              provinciaError: '',
              provinciaDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_CODICE_TRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceTributo: action.payload,
              codiceTributoError: '',
              codiceTributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_CODICE_IDENTIFICATIVO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceIdentificativo: action.payload,
              codiceIdentificativoError: '',
              codiceIdentificativoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_RATEAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              rateazione: action.payload,
              rateazioneError: '',
              rateazioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_MESE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              mese: action.payload,
              meseError: '',
              meseDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_ANNO_RIFERIMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              annoRiferimento: action.payload,
              annoRiferimentoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ACCISE_SET_RIGA_IMPORTO_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAccise: {
            ...state.form.sezioneAccise,
            righe: (0, helpers_1.validateRigheSezioneAccise)(state.form.sezioneAccise.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_CODICE_UFFICIO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            codiceUfficio: action.payload,
            codiceUfficioDirty: true,
            codiceUfficioError: ''
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_CODICE_ATTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            codiceAtto: action.payload,
            codiceAttoDirty: true,
            codiceAttoError: ''
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_TOTAL:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            totalIdentificativi: action.payload
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_RIGA_TIPO_VEICOLO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            righe: (0, helpers_1.validateRigheSezioneAltroErario)(state.form.sezioneAltroErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              tipoVeicolo: action.payload,
              tipoVeicoloDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_RIGA_ELEMENTO_ID:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            righe: (0, helpers_1.validateRigheSezioneAltroErario)(state.form.sezioneAltroErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              elementoId: action.payload,
              elementoIdDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_RIGA_CODICE_TRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            righe: (0, helpers_1.validateRigheSezioneAltroErario)(state.form.sezioneAltroErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceTributo: action.payload,
              codiceTributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_RIGA_ANNO_RIFERIMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            righe: (0, helpers_1.validateRigheSezioneAltroErario)(state.form.sezioneAltroErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              annoRiferimento: action.payload,
              annoRiferimentoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ELIDE_SET_RIGA_IMPORTO_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneAltroErario: {
            ...state.form.sezioneAltroErario,
            righe: (0, helpers_1.validateRigheSezioneAltroErario)(state.form.sezioneAltroErario.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_SEZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              sezione: action.payload,
              sezioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_CODICE_TRIBUTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceTributo: action.payload,
              codiceTributoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_CODICE_ENTE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              codiceEnte: action.payload,
              codiceEnteDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_RAVVEDIMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              ravvedimento: action.payload,
              ravvedimentoError: '',
              ravvedimentoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_IMMOBILIARI_VARIATI:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              immobiliareVariati: action.payload,
              immobiliareError: '',
              ravvedimentoDimmobiliareVariatiDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_SALDO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              saldo: action.payload,
              saldoError: '',
              saldoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_ACCONTO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              acconto: action.payload,
              accontoError: '',
              accontoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_NUMERO_IMMOBILIARI:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              numeroImmobiliari: action.payload,
              numeroImmobiliariDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_RATE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              rate: action.payload,
              rateError: '',
              rateDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_ANNO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              anno: action.payload ? Number(action.payload) : undefined,
              annoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_DETRAZIONI:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              detrazione: action.payload,
              detrazioneDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_IMPORTO_DEBITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoDebito: action.payload,
              importoDebitoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_IMPORTO_CREDITO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: (0, helpers_1.validateRigheSezioneMotivoPagamento)(state.form.sezioneMotivoPagamento.righe.map((r, i) => i !== action.riga ? {
              ...r
            } : {
              ...r,
              importoCredito: action.payload,
              importoCreditoDirty: true
            }))
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            data: action.payload,
            dataError: (0, helpers_1.validationFieldDate)(action.payload, null, false),
            dataDirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_SALDO_EURO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            saldo: action.payload
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SET_BOZZA_SALVATA:
      return {
        ...state,
        form: {
          ...state.form,
          bozzaSalvata: true
        }
      };
    case __1.EReduxActionTypes.F24_UPDATE_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeF24Ordinario: state.bozzeF24Ordinario ? state.bozzeF24Ordinario.map(bozza => bozza.id === state.currentBozza.id ? action.payload : bozza) : [],
        form: {
          ...state.form,
          bozzaSalvata: true
        },
        currentBozza: {
          ...state.currentBozza,
          id: action.payload.id
        }
      };
    case __1.EReduxActionTypes.F24_ESPORTA_F24_PDF_SUCCESS:
      return {
        ...state,
        dettaglioF24Pdf: action.payload
      };
    case __1.EReduxActionTypes.F24_ESPORTA_QUIETANZA_PDF_SUCCESS:
      return {
        ...state,
        quietanzaF24Pdf: action.payload
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_GET_CODICI_ENTE_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          codiciEnte: action.payload,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_ORDINARIO_GET_CODICI_UFFICIO_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          codiciUfficio: action.payload,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_RESET_MOTIVI_MOBILE:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: [0].map(i => (0, f24_types_1.RigaSezioneMotiviPagamentoFromJSON)(true))
          }
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_AGGIUNGI_MOTIVO:
      {
        return {
          ...state,
          form: {
            ...state.form,
            sezioneMotivoPagamento: {
              ...state.form.sezioneMotivoPagamento,
              righe: [...state.form.sezioneMotivoPagamento.righe, (0, f24_types_1.RigaSezioneMotiviPagamentoFromJSON)()]
            }
          }
        };
      }
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_RIMUOVI_MOTIVO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            righe: state.form.sezioneMotivoPagamento.righe.filter((_, index) => index !== action.payload)
          }
        }
      };
    case __1.EReduxActionTypes.F24_SEMPLIFICATO_SET_NUMERO_IDENTIFICATIVO:
      return {
        ...state,
        form: {
          ...state.form,
          sezioneMotivoPagamento: {
            ...state.form.sezioneMotivoPagamento,
            numeroIdentificativo: action.payload,
            numeroIdentificativoDirty: true,
            numeroIdentificativoError: (0, helpers_1.validationIdentificativoF24)(action.payload)
          }
        }
      };
    /*case EReduxActionTypes.F24_INIT_DOCUMENT_SESSION_F24_START:
      return {
        ...state,
        documentSessionInitF24Result: initialState.documentSessionInitF24Result
      };
         case EReduxActionTypes.F24_INIT_DOCUMENT_SESSION_F24_SUCCESS:
      return {
        ...state,
        documentSessionInitF24Result: action.payload
      };
         case EReduxActionTypes.F24_GET_INFO_DOCUMENT_SESSION_TOKEN_F24_SUCCESS:
      return {
        ...state,
        documentSessionTokenInfoF24Result: action.payload
      };
         case EReduxActionTypes.F24_GET_DOWNLOAD_DOCUMENT_SESSION_TOKEN_F24_SUCCESS:
      return {
        ...state,
        documentSessionTokenDownloadF24Result: action.payload
      };
         case EReduxActionTypes.F24_RESET_DOCUMENT_SESSION_F24:
      return {
        ...state,
        documentSessionInitF24Result: initialState.documentSessionInitF24Result,
        documentSessionTokenInfoF24Result: initialState.documentSessionTokenInfoF24Result,
        documentSessionTokenDownloadF24Result:
          action.keepImage
            ? state.documentSessionTokenDownloadF24Result
            : initialState.documentSessionTokenDownloadF24Result
      };*/
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.f24Reducer = f24Reducer;