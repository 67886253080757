"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilialeVirtualeControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FilialeVirtualeControllerApi extends runtime.BaseAPI {
  /**
   * Autorizzazione di una disposizione di filiale virtuale
   */
  autorizzaRichiestaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaRichiesta.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaRichiesta.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/filialevirtuale/autorizza/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di filiale virtuale
   */
  autorizzaRichiesta(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaRichiestaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce contatti
   */
  contattoRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/contatti",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.NumeroContattiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce contatti
   */
  contatto() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.contattoRaw();
      return yield response.value();
    });
  }
  /**
   * Controlla che i dati della richiesta, eseguita tramite la filiale virtuale, siano validi.
   */
  controlloRichiestaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.dettaglioRichiestaDto === null || requestParameters.dettaglioRichiestaDto === undefined) {
        throw new runtime.RequiredError('dettaglioRichiestaDto', 'Required parameter requestParameters.dettaglioRichiestaDto was null or undefined when calling controlloRichiesta.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/filialevirtuale/controllo",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.DettaglioRichiestaDtoToJSON(requestParameters.dettaglioRichiestaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DettaglioRichiestaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controlla che i dati della richiesta, eseguita tramite la filiale virtuale, siano validi.
   */
  controlloRichiesta(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloRichiestaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restitusce il dettaglio della richiesta sottomessa dall\'utente alla banca tramite la filiale virtuale, identificata attraverso l\'id passato nel path.
   */
  dettaglioRichiestaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling dettaglioRichiesta.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/richieste/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DettaglioRichiestaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restitusce il dettaglio della richiesta sottomessa dall\'utente alla banca tramite la filiale virtuale, identificata attraverso l\'id passato nel path.
   */
  dettaglioRichiesta(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioRichiestaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Stampa PDF dettaglio richiesta
   */
  dettaglioRichiestaPdfRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.dettaglioRichiestaDto === null || requestParameters.dettaglioRichiestaDto === undefined) {
        throw new runtime.RequiredError('dettaglioRichiestaDto', 'Required parameter requestParameters.dettaglioRichiestaDto was null or undefined when calling dettaglioRichiestaPdf.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/filialevirtuale/export/dettagliorichiesta/pdf",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.DettaglioRichiestaDtoToJSON(requestParameters.dettaglioRichiestaDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Stampa PDF dettaglio richiesta
   */
  dettaglioRichiestaPdf(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioRichiestaPdfRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restitusce il dettaglio della richiesta sottomessa dall\'utente alla banca tramite la filiale virtuale, identificata attraverso l\'id passato nel path.
   */
  dettaglioTemplateFilialeVirtualeRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling dettaglioTemplateFilialeVirtuale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/template/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TemplateDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restitusce il dettaglio della richiesta sottomessa dall\'utente alla banca tramite la filiale virtuale, identificata attraverso l\'id passato nel path.
   */
  dettaglioTemplateFilialeVirtuale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioTemplateFilialeVirtualeRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce l\'elenco delle richieste sottomesse dall\'utente alla banca tramite la filiale virtuale (es. una carta o un carnet di assegni da recapitare a domicilio) eventualmente filtrate in base ad un\'intervallo di ricerca.
   */
  elencoRichiesteRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.elencoRichiesteInDto === null || requestParameters.elencoRichiesteInDto === undefined) {
        throw new runtime.RequiredError('elencoRichiesteInDto', 'Required parameter requestParameters.elencoRichiesteInDto was null or undefined when calling elencoRichieste.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/filialevirtuale/richieste",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ElencoRichiesteInDtoToJSON(requestParameters.elencoRichiesteInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.RichiestaDtoFromJSON));
    });
  }
  /**
   * Restituisce l\'elenco delle richieste sottomesse dall\'utente alla banca tramite la filiale virtuale (es. una carta o un carnet di assegni da recapitare a domicilio) eventualmente filtrate in base ad un\'intervallo di ricerca.
   */
  elencoRichieste(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.elencoRichiesteRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Elimina la richiesta specificata tramite l\'ID
   */
  eliminaRichiestaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling eliminaRichiesta.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/richieste/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Elimina la richiesta specificata tramite l\'ID
   */
  eliminaRichiesta(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.eliminaRichiestaRaw(requestParameters);
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneRichiestaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRichiesta.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/infoautenticazione/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneRichiesta(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneRichiestaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce l\'elenco dei template di richiesta messi a disposizione dall\'istituto
   */
  listaTemplateFilialeVirtualeRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/listatemplate",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ElencoTemplateFilialeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce l\'elenco dei template di richiesta messi a disposizione dall\'istituto
   */
  listaTemplateFilialeVirtuale() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.listaTemplateFilialeVirtualeRaw();
      return yield response.value();
    });
  }
  /**
   * Lettura parametri filiale virtuale
   */
  parametriFilialeVirtualeRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri filiale virtuale
   */
  parametriFilialeVirtuale() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriFilialeVirtualeRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce l\'elenco dei template di richiesta messi a disposizione dall\'istituto
   */
  templateCatalogoProdottiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceTemplate === null || requestParameters.codiceTemplate === undefined) {
        throw new runtime.RequiredError('codiceTemplate', 'Required parameter requestParameters.codiceTemplate was null or undefined when calling templateCatalogoProdotti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/filialevirtuale/templatecatalogo/{codiceTemplate}".replace("{".concat("codiceTemplate", "}"), encodeURIComponent(String(requestParameters.codiceTemplate))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TemplateDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce l\'elenco dei template di richiesta messi a disposizione dall\'istituto
   */
  templateCatalogoProdotti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.templateCatalogoProdottiRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.FilialeVirtualeControllerApi = FilialeVirtualeControllerApi;