"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WatchlistStaticaDtoToJSON = exports.WatchlistStaticaDtoFromJSONTyped = exports.WatchlistStaticaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WatchlistStaticaDtoFromJSON(json) {
  return WatchlistStaticaDtoFromJSONTyped(json, false);
}
exports.WatchlistStaticaDtoFromJSON = WatchlistStaticaDtoFromJSON;
function WatchlistStaticaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
    'items': !runtime_1.exists(json, 'items') ? undefined : json['items'].map(_1.WatchlistStaticaItemDtoFromJSON)
  };
}
exports.WatchlistStaticaDtoFromJSONTyped = WatchlistStaticaDtoFromJSONTyped;
function WatchlistStaticaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'nome': value.nome,
    'items': value.items === undefined ? undefined : value.items.map(_1.WatchlistStaticaItemDtoToJSON)
  };
}
exports.WatchlistStaticaDtoToJSON = WatchlistStaticaDtoToJSON;