"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenInfoQrcodeDtoToJSON = exports.TokenInfoQrcodeDtoFromJSONTyped = exports.TokenInfoQrcodeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TokenInfoQrcodeDtoFromJSON(json) {
  return TokenInfoQrcodeDtoFromJSONTyped(json, false);
}
exports.TokenInfoQrcodeDtoFromJSON = TokenInfoQrcodeDtoFromJSON;
function TokenInfoQrcodeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
    'image': !runtime_1.exists(json, 'image') ? undefined : json['image']
  };
}
exports.TokenInfoQrcodeDtoFromJSONTyped = TokenInfoQrcodeDtoFromJSONTyped;
function TokenInfoQrcodeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'contentType': value.contentType,
    'image': value.image
  };
}
exports.TokenInfoQrcodeDtoToJSON = TokenInfoQrcodeDtoToJSON;