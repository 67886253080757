"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.excelAssegni = exports.pdfDettaglioAssegno = exports.pdfAssegni = exports.filtriAssegni = exports.parametriAssegni = exports.carnetSelezionato = exports.assegni = void 0;
const reselect_1 = require("reselect");
const assegniReducer = state => state;
exports.assegni = (0, reselect_1.createSelector)(assegniReducer, state => {
  return state.assegni;
});
exports.carnetSelezionato = (0, reselect_1.createSelector)(assegniReducer, state => {
  return state.carnetSelezionato;
});
exports.parametriAssegni = (0, reselect_1.createSelector)(assegniReducer, state => {
  return state.parametri;
});
exports.filtriAssegni = (0, reselect_1.createSelector)(assegniReducer, state => {
  return state.filter;
});
exports.pdfAssegni = (0, reselect_1.createSelector)(assegniReducer, state => {
  return state.pdfAssegni;
});
exports.pdfDettaglioAssegno = (0, reselect_1.createSelector)(assegniReducer, state => {
  return state.pdfDettaglioAssegno;
});
exports.excelAssegni = (0, reselect_1.createSelector)(assegniReducer, state => {
  return state.excelAssegni;
});