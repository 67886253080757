"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountBODtoToJSON = exports.AccountBODtoFromJSONTyped = exports.AccountBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AccountBODtoFromJSON(json) {
  return AccountBODtoFromJSONTyped(json, false);
}
exports.AccountBODtoFromJSON = AccountBODtoFromJSON;
function AccountBODtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'data': !runtime_1.exists(json, 'data') ? undefined : _1.PreCustomersAggregationLevelBODtoFromJSON(json['data']),
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'preId': !runtime_1.exists(json, 'preId') ? undefined : json['preId'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId']
  };
}
exports.AccountBODtoFromJSONTyped = AccountBODtoFromJSONTyped;
function AccountBODtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'bankCode': value.bankCode,
    'data': _1.PreCustomersAggregationLevelBODtoToJSON(value.data),
    'message': value.message,
    'preId': value.preId,
    'userId': value.userId
  };
}
exports.AccountBODtoToJSON = AccountBODtoToJSON;