"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuotazioneDtoToJSON = exports.QuotazioneDtoFromJSONTyped = exports.QuotazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function QuotazioneDtoFromJSON(json) {
  return QuotazioneDtoFromJSONTyped(json, false);
}
exports.QuotazioneDtoFromJSON = QuotazioneDtoFromJSON;
function QuotazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'delay': !runtime_1.exists(json, 'delay') ? undefined : json['delay'],
    'source': !runtime_1.exists(json, 'source') ? undefined : json['source'],
    'dato': !runtime_1.exists(json, 'dato') ? undefined : json['dato']
  };
}
exports.QuotazioneDtoFromJSONTyped = QuotazioneDtoFromJSONTyped;
function QuotazioneDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codice': value.codice,
    'delay': value.delay,
    'source': value.source,
    'dato': value.dato
  };
}
exports.QuotazioneDtoToJSON = QuotazioneDtoToJSON;