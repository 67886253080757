"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CaricaDocumentoIdentitaOutDtoToJSON = exports.CaricaDocumentoIdentitaOutDtoFromJSONTyped = exports.CaricaDocumentoIdentitaOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CaricaDocumentoIdentitaOutDtoFromJSON(json) {
  return CaricaDocumentoIdentitaOutDtoFromJSONTyped(json, false);
}
exports.CaricaDocumentoIdentitaOutDtoFromJSON = CaricaDocumentoIdentitaOutDtoFromJSON;
function CaricaDocumentoIdentitaOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'success': !runtime_1.exists(json, 'success') ? undefined : json['success'],
    'tokenIdentifier': !runtime_1.exists(json, 'tokenIdentifier') ? undefined : json['tokenIdentifier']
  };
}
exports.CaricaDocumentoIdentitaOutDtoFromJSONTyped = CaricaDocumentoIdentitaOutDtoFromJSONTyped;
function CaricaDocumentoIdentitaOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'success': value.success,
    'tokenIdentifier': value.tokenIdentifier
  };
}
exports.CaricaDocumentoIdentitaOutDtoToJSON = CaricaDocumentoIdentitaOutDtoToJSON;