"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBolloAciDtoToJSON = exports.EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSON = EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSON;
function EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.BolloAciDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneBolloAciDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.BolloAciDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneBolloAciDtoToJSON = EsitoAutorizzazioneDisposizioneBolloAciDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneBolloAciDtoStatoEnum = {}));