"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriCorrispondenzaDtoToJSON = exports.ParametriCorrispondenzaDtoFromJSONTyped = exports.ParametriCorrispondenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ParametriCorrispondenzaDtoFromJSON(json) {
  return ParametriCorrispondenzaDtoFromJSONTyped(json, false);
}
exports.ParametriCorrispondenzaDtoFromJSON = ParametriCorrispondenzaDtoFromJSON;
function ParametriCorrispondenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'condizioniTrasparenza': !runtime_1.exists(json, 'condizioniTrasparenza') ? undefined : json['condizioniTrasparenza']
  };
}
exports.ParametriCorrispondenzaDtoFromJSONTyped = ParametriCorrispondenzaDtoFromJSONTyped;
function ParametriCorrispondenzaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'parametri': value.parametri,
    'condizioniTrasparenza': value.condizioniTrasparenza
  };
}
exports.ParametriCorrispondenzaDtoToJSON = ParametriCorrispondenzaDtoToJSON;