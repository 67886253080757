"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneFrecciaDtoToJSON = exports.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON = EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON;
function EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.FrecciaDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneFrecciaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.FrecciaDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneFrecciaDtoToJSON = EsitoAutorizzazioneDisposizioneFrecciaDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneFrecciaDtoStatoEnum = {}));