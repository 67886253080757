"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartaSiSSoDtoToJSON = exports.CartaSiSSoDtoFromJSONTyped = exports.CartaSiSSoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CartaSiSSoDtoFromJSON(json) {
  return CartaSiSSoDtoFromJSONTyped(json, false);
}
exports.CartaSiSSoDtoFromJSON = CartaSiSSoDtoFromJSON;
function CartaSiSSoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'token': !runtime_1.exists(json, 'token') ? undefined : json['token'],
    'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
    'urlCarteCredito': !runtime_1.exists(json, 'urlCarteCredito') ? undefined : json['urlCarteCredito']
  };
}
exports.CartaSiSSoDtoFromJSONTyped = CartaSiSSoDtoFromJSONTyped;
function CartaSiSSoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'token': value.token,
    'url': value.url,
    'urlCarteCredito': value.urlCarteCredito
  };
}
exports.CartaSiSSoDtoToJSON = CartaSiSSoDtoToJSON;