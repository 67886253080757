"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelfResetBranchRequestDtoToJSON = exports.SelfResetBranchRequestDtoFromJSONTyped = exports.SelfResetBranchRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SelfResetBranchRequestDtoFromJSON(json) {
  return SelfResetBranchRequestDtoFromJSONTyped(json, false);
}
exports.SelfResetBranchRequestDtoFromJSON = SelfResetBranchRequestDtoFromJSON;
function SelfResetBranchRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceUtente': json['codiceUtente'],
    'codiceOperatore': !runtime_1.exists(json, 'codiceOperatore') ? undefined : json['codiceOperatore']
  };
}
exports.SelfResetBranchRequestDtoFromJSONTyped = SelfResetBranchRequestDtoFromJSONTyped;
function SelfResetBranchRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceUtente': value.codiceUtente,
    'codiceOperatore': value.codiceOperatore
  };
}
exports.SelfResetBranchRequestDtoToJSON = SelfResetBranchRequestDtoToJSON;