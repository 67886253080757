"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PresenceResponseToJSON = exports.PresenceResponseFromJSONTyped = exports.PresenceResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PresenceResponseFromJSON(json) {
  return PresenceResponseFromJSONTyped(json, false);
}
exports.PresenceResponseFromJSON = PresenceResponseFromJSON;
function PresenceResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'displayName': !runtime_1.exists(json, 'displayName') ? undefined : json['displayName'],
    'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
    'mail': !runtime_1.exists(json, 'mail') ? undefined : json['mail'],
    'presence': !runtime_1.exists(json, 'presence') ? undefined : json['presence']
  };
}
exports.PresenceResponseFromJSONTyped = PresenceResponseFromJSONTyped;
function PresenceResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'displayName': value.displayName,
    'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
    'mail': value.mail,
    'presence': value.presence
  };
}
exports.PresenceResponseToJSON = PresenceResponseToJSON;