"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetMutui = exports.esportaDettaglioMutuiEXCEL = exports.esportaDettaglioMutuiPDF = exports.esportaElencoMutuiEXCEL = exports.esportaElencoMutuiPDF = exports.resetDashboardMutui = exports.resetRateMutui = exports.getDashboardMutui = exports.getRateResidueMutui = exports.getRatePagateMutui = exports.getPianoAmmortamentoMutui = exports.getRateMoraMutui = exports.setMutuiRapportoSelezionato = exports.getElencoProgressiviMutui = exports.getParametriMutui = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const mutui_selectors_1 = require("./mutui.selectors");
/* get parametri mutui - START */
function getParametriMutuiStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_PARAMETRI_START
  };
}
function getParametriMutuiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getParametriMutui = function () {
  let loaderEnabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriMutuiStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/parametri_mutui.json')).then(parametri => {
        dispatch(getParametriMutuiSuccess((0, api_rest_1.ParametriMutuiDtoFromJSON)(parametri)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriMutui().then(response => {
          var _a;
          dispatch(getParametriMutuiSuccess(response));
          ((_a = response.mutui) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({
            type: general_types_1.TypeNoRapporti.MUTUI
          }));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getParametriMutui = getParametriMutui;
/* get parametri mutui - FINE */
/* elenco progressivi mutui - START */
function getElencoProgressiviMutuiStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_ELENCO_PROGERSSIVI_START
  };
}
function getElencoProgressiviMutuiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_ELENCO_PROGERSSIVI_SUCCESS,
    payload
  };
}
const getElencoProgressiviMutui = codiceRapporto => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getElencoProgressiviMutuiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getElencoProgressiviMutuiSuccess([]));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const request = {
      codiceRapporto
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).elencoProgressiviMutui(request).then(response => {
        dispatch(getElencoProgressiviMutuiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getElencoProgressiviMutui = getElencoProgressiviMutui;
/* elenco progressivi mutui - FINE */
/* set form values - START */
function setMutuiRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.MUTUI_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setMutuiRapportoSelezionato = setMutuiRapportoSelezionato;
/* set form values - FINE */
/* get rate mora mutui - START */
function getRateMoraMutuiStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_RATE_MORA_START
  };
}
function getRateMoraMutuiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_RATE_MORA_SUCCESS,
    payload
  };
}
const getRateMoraMutui = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRateMoraMutuiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getRateMoraMutuiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getRateMoraMutui(request).then(response => {
        dispatch(getRateMoraMutuiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRateMoraMutui = getRateMoraMutui;
/* get rate mora mutui  - FINE */
/* get piano ammortamento mutui - START */
function getPianoAmmortamentoMutuiStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_PIANO_AMMORTAMENTO_START
  };
}
function getPianoAmmortamentoMutuiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_PIANO_AMMORTAMENTO_SUCCESS,
    payload
  };
}
const getPianoAmmortamentoMutui = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getPianoAmmortamentoMutuiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getPianoAmmortamentoMutuiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getPianoAmmortamentoMutui(request).then(response => {
        dispatch(getPianoAmmortamentoMutuiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getPianoAmmortamentoMutui = getPianoAmmortamentoMutui;
/* get piano ammortamento mutui - FINE */
/* get rate pagate mutui - START */
function getRatePagateMutuiStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_RATE_PAGATE_START
  };
}
function getRatePagateMutuiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_RATE_PAGATE_SUCCESS,
    payload
  };
}
const getRatePagateMutui = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRatePagateMutuiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getRatePagateMutuiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getRatePagateMutui(request).then(response => {
        dispatch(getRatePagateMutuiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRatePagateMutui = getRatePagateMutui;
/* get rate pagate mutui  - FINE */
/* get rate residue mutui - START */
function getRateResidueMutuiStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_RATE_RESIDUE_START
  };
}
function getRateResidueMutuiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_RATE_RESIDUE_SUCCESS,
    payload
  };
}
const getRateResidueMutui = filtroFinanziamenti => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRateResidueMutuiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch(getRateResidueMutuiSuccess((0, api_rest_1.RateFinanziamentoDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestIn = filtroFinanziamenti || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      filtroFinanziamentiDto: requestIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getRateResidueMutui(request).then(response => {
        dispatch(getRateResidueMutuiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRateResidueMutui = getRateResidueMutui;
/* get rate residue mutui mutui - FINE */
/* get dashboard mutui - START */
function getDashboardMutuiStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_DASHBOARD_START
  };
}
function getDashboardMutuiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_GET_DASHBOARD_SUCCESS,
    payload
  };
}
const getDashboardMutui = function (filtroFinanziamenti) {
  let loaderEnabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return async (dispatch, getState, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDashboardMutuiStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/dashboard_mutui.json')).then(response => {
        dispatch(getDashboardMutuiSuccess((0, api_rest_1.FinanziamentiDashboardDtoFromJSON)(response)));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      const requestIn = filtroFinanziamenti || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
      const request = {
        filtroFinanziamentiDto: requestIn
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDashboardMutui(request).then(response => {
          dispatch(getDashboardMutuiSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getDashboardMutui = getDashboardMutui;
/* get dashboard mutui - FINE */
function resetRateMutui() {
  return {
    type: __1.EReduxActionTypes.MUTUI_RESET_RATE
  };
}
exports.resetRateMutui = resetRateMutui;
function resetDashboardMutui() {
  return {
    type: __1.EReduxActionTypes.MUTUI_RESET_DASHBOARD
  };
}
exports.resetDashboardMutui = resetDashboardMutui;
/* esporta elenco PDF - START */
function esportaElencoMutuiPDFStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_PDF_ELENCO_START
  };
}
function esportaElencoMutuiPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_PDF_ELENCO_SUCCESS,
    payload
  };
}
const esportaElencoMutuiPDF = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
  dispatch(esportaElencoMutuiPDFStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {} else {
    const datiIn = filtro || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      type: api_rest_1.ExportPianoMutuoPrestitoTypeEnum.pdf,
      filtroFinanziamentiDto: datiIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportPianoMutuoPrestito(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'elenco.pdf', response, share, title, message);
        }
        dispatch(esportaElencoMutuiPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaElencoMutuiPDF = esportaElencoMutuiPDF;
/* esporta elenco PDF  - FINE */
/* esporta elenco EXCEL - START */
function esportaElencoMutuiEXCELStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_EXCEL_ELENCO_START
  };
}
function esportaElencoMutuiEXCELSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_EXCEL_ELENCO_SUCCESS,
    payload
  };
}
const esportaElencoMutuiEXCEL = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
  dispatch(esportaElencoMutuiEXCELStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {} else {
    const datiIn = filtro || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      type: api_rest_1.ExportPianoMutuoPrestitoTypeEnum.excel,
      filtroFinanziamentiDto: datiIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).exportPianoMutuoPrestito(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadleExcelResponse)(response, 'elenco.xlsx');
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_shareXLS)(extraArguments, 'elenco.xlsx', response, share);
        }
        dispatch(esportaElencoMutuiEXCELSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaElencoMutuiEXCEL = esportaElencoMutuiEXCEL;
/* esporta elenco EXCEL - FINE */
/* esporta dettaglio PDF mutui - START */
function esportaDettaglioMutuiPDFStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_PDF_DETTAGLIO_START
  };
}
function esportaDettaglioMutuiPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_PDF_DETTAGLIO_SUCCESS,
    payload
  };
}
const esportaDettaglioMutuiPDF = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
  dispatch(esportaDettaglioMutuiPDFStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {} else {
    const datiIn = filtro || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      type: api_rest_1.ExportDettaglioRateTypeEnum.pdf,
      filtroFinanziamentiDto: datiIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportDettaglioRate(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'dettaglio.pdf', response, share, title, message);
        }
        dispatch(esportaDettaglioMutuiPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaDettaglioMutuiPDF = esportaDettaglioMutuiPDF;
/* esporta dettaglio PDF riba - FINE */
/* esporta dettaglio EXCEL mutui - START */
function esportaDettaglioMutuiEXCELStart() {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_EXCEL_DETTAGLIO_START
  };
}
function esportaDettaglioMutuiEXCELSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.MUTUI_ESPORTA_EXCEL_DETTAGLIO_SUCCESS,
    payload
  };
}
const esportaDettaglioMutuiEXCEL = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
  dispatch(esportaDettaglioMutuiEXCELStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {} else {
    const datiIn = filtro || (0, mutui_selectors_1.formMutuiAsFiltro)(getState().mutui);
    const request = {
      type: api_rest_1.ExportDettaglioRateTypeEnum.excel,
      filtroFinanziamentiDto: datiIn
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.MutuiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).exportDettaglioRate(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadleExcelResponse)(response, 'dettaglio.xlsx');
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_shareXLS)(extraArguments, 'dettaglio.xlsx', response, share);
        }
        dispatch(esportaDettaglioMutuiEXCELSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaDettaglioMutuiEXCEL = esportaDettaglioMutuiEXCEL;
/* esporta dettaglio EXCEL riba - FINE */
function resetMutui() {
  return {
    type: __1.EReduxActionTypes.MUTUI_RESET
  };
}
exports.resetMutui = resetMutui;