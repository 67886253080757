"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CoherenceResultDtoToJSON = exports.CoherenceResultDtoFromJSONTyped = exports.CoherenceResultDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CoherenceResultDtoFromJSON(json) {
  return CoherenceResultDtoFromJSONTyped(json, false);
}
exports.CoherenceResultDtoFromJSON = CoherenceResultDtoFromJSON;
function CoherenceResultDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'actionCode': !runtime_1.exists(json, 'actionCode') ? undefined : json['actionCode'],
    'actionDescription': !runtime_1.exists(json, 'actionDescription') ? undefined : json['actionDescription'],
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'blocking': !runtime_1.exists(json, 'blocking') ? undefined : json['blocking'],
    'endSectionMessage': !runtime_1.exists(json, 'endSectionMessage') ? undefined : json['endSectionMessage'],
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'questionList': !runtime_1.exists(json, 'questionList') ? undefined : json['questionList'],
    'targetEntityCode': !runtime_1.exists(json, 'targetEntityCode') ? undefined : json['targetEntityCode'],
    'targetThemeArea': !runtime_1.exists(json, 'targetThemeArea') ? undefined : json['targetThemeArea'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    'valid': !runtime_1.exists(json, 'valid') ? undefined : json['valid']
  };
}
exports.CoherenceResultDtoFromJSONTyped = CoherenceResultDtoFromJSONTyped;
function CoherenceResultDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'actionCode': value.actionCode,
    'actionDescription': value.actionDescription,
    'bankCode': value.bankCode,
    'blocking': value.blocking,
    'endSectionMessage': value.endSectionMessage,
    'id': value.id,
    'message': value.message,
    'questionList': value.questionList,
    'targetEntityCode': value.targetEntityCode,
    'targetThemeArea': value.targetThemeArea,
    'userId': value.userId,
    'valid': value.valid
  };
}
exports.CoherenceResultDtoToJSON = CoherenceResultDtoToJSON;