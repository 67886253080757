"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLimitiOperativi = exports.getParametriLimitiOperativi = exports.resetParametriLimitiOperativi = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function resetParametriLimitiOperativi() {
  return {
    type: __1.EReduxActionTypes.LIMITI_OPERATIVI_RESET
  };
}
exports.resetParametriLimitiOperativi = resetParametriLimitiOperativi;
/* limiti operativi get parametri - START  */
function getParametriLimitiOperativiStart() {
  return {
    type: __1.EReduxActionTypes.LIMITI_OPERATIVI_GET_PARAMETRI_START
  };
}
function getParametriLimitiOperativiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.LIMITI_OPERATIVI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getParametriLimitiOperativi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriLimitiOperativiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_limiti_operativi.json')).then(response => {
      dispatch(getParametriLimitiOperativiSuccess(response));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.LimitiOperativiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriLimitiOperativi().then(response => {
        dispatch(getParametriLimitiOperativiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriLimitiOperativi = getParametriLimitiOperativi;
/* limiti operativi get parametri - FINE */
/* get limiti operativi - START */
function getLimitiOperativiStart() {
  return {
    type: __1.EReduxActionTypes.LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_START
  };
}
function getLimitiOperativiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_SUCCESS,
    payload
  };
}
const getLimitiOperativi = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getLimitiOperativiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/limiti_operativi.json')).then(response => {
      dispatch(getLimitiOperativiSuccess(response));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.LimitiOperativiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).limitiOperativi().then(response => {
        dispatch(getLimitiOperativiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getLimitiOperativi = getLimitiOperativi;
/* get limiti operativi - FINE */
/* mock data */
const getMockParametri = () => {
  return {
    nomeIntestazione: 'ADRIANA',
    cognomeIntestazione: 'BALBO',
    parametri: {
      'pulsante.imposta.visibile': false
    }
  };
};
const getMockLimitiOperativi = () => {
  return {
    maxGiornaliero: 20000,
    utilizzatoGiornaliero: 0,
    residuoMaxGiornaliero: 20000,
    maxMensile: 20000,
    utilizzatoMensile: 5.19,
    residuoMaxMensile: 19994.81,
    serviziSoggetti: ['BOLLETTINOBANCARIOSP', 'BOLLETTINOPOSTALESSB', 'BONIFICORIPETITIVO', 'BONIFICOSEPA', 'CARTARICARICABILEDISPO', 'EBILLING', 'F24SP', 'F24STANDARD', 'F24SEMPLIFICATO', 'F24ACCISE', 'F24IDENTIFICATIVI', 'MYBANK', 'PAGEFFETTI', 'PAGMAVSP', 'PAGRAVSP', 'RICARICACELLULARE', 'SUDTIROLPASS']
  };
};
/* get mock data - FINE */