"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriCartaContoDtoToJSON = exports.ParametriCartaContoDtoFromJSONTyped = exports.ParametriCartaContoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriCartaContoDtoFromJSON(json) {
  return ParametriCartaContoDtoFromJSONTyped(json, false);
}
exports.ParametriCartaContoDtoFromJSON = ParametriCartaContoDtoFromJSON;
function ParametriCartaContoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'rapportiBeneficiario': !runtime_1.exists(json, 'rapportiBeneficiario') ? undefined : json['rapportiBeneficiario'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'motivazioniPagamento': !runtime_1.exists(json, 'motivazioniPagamento') ? undefined : json['motivazioniPagamento'].map(_1.MotivazionePagamentoDtoFromJSON)
  };
}
exports.ParametriCartaContoDtoFromJSONTyped = ParametriCartaContoDtoFromJSONTyped;
function ParametriCartaContoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'rapportiBeneficiario': value.rapportiBeneficiario === undefined ? undefined : value.rapportiBeneficiario.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri,
    'motivazioniPagamento': value.motivazioniPagamento === undefined ? undefined : value.motivazioniPagamento.map(_1.MotivazionePagamentoDtoToJSON)
  };
}
exports.ParametriCartaContoDtoToJSON = ParametriCartaContoDtoToJSON;