"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Form1ClickDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function Form1ClickDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoOperazione': !(0, helpers_1.exists)(json, 'tipoOperazione') ? undefined : json.tipoOperazione,
    'tipoOperazioneError': !(0, helpers_1.exists)(json, 'tipoOperazioneError') ? 'invalid.required' : json.tipoOperazioneError,
    'tipoOperazioneDirty': !(0, helpers_1.exists)(json, 'tipoOperazioneDirty') ? false : json.tipoOperazioneDirty,
    'contenuto': !(0, helpers_1.exists)(json, 'contenuto') ? undefined : json.contenuto,
    'contenutoError': !(0, helpers_1.exists)(json, 'contenutoError') ? '' : json.contenutoError,
    'accordionFiltriOpened': !(0, helpers_1.exists)(json, 'accordionFiltriOpened') ? false : json.accordionFiltriOpened,
    'step': !(0, helpers_1.exists)(json, 'step') ? 1 : json.step,
    'alias': !(0, helpers_1.exists)(json, 'alias') ? undefined : json.alias,
    'aliasError': !(0, helpers_1.exists)(json, 'aliasError') ? 'invalid.required' : json.aliasError,
    'aliasDirty': !(0, helpers_1.exists)(json, 'aliasDirty') ? false : json.aliasDirty,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'beneficiario': !(0, helpers_1.exists)(json, 'beneficiario') ? undefined : json.beneficiario,
    'beneficiarioDirty': !(0, helpers_1.exists)(json, 'beneficiarioDirty') ? false : json.beneficiarioDirty,
    'beneficiarioError': !(0, helpers_1.exists)(json, 'beneficiarioError') ? 'invalid.required' : json.beneficiarioError,
    'iban': !(0, helpers_1.exists)(json, 'iban') ? undefined : json.iban,
    'ibanDirty': !(0, helpers_1.exists)(json, 'ibanDirty') ? false : json.ibanDirty,
    'ibanError': !(0, helpers_1.exists)(json, 'ibanError') ? 'invalid.required' : json.ibanError,
    'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
    'causale': !(0, helpers_1.exists)(json, 'causale') ? undefined : json.causale,
    'stepSCA': !(0, helpers_1.exists)(json, 'stepSCA') ? 1 : json.stepSCA,
    'tipoOperazioneRicerca': !(0, helpers_1.exists)(json, 'tipoOperazioneRicerca') ? undefined : json.tipoOperazioneRicerca
  };
}
exports.Form1ClickDtoFromJSONTyped = Form1ClickDtoFromJSONTyped;