"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ComunicazioneDtoToJSON = exports.ComunicazioneDtoFromJSONTyped = exports.ComunicazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ComunicazioneDtoFromJSON(json) {
  return ComunicazioneDtoFromJSONTyped(json, false);
}
exports.ComunicazioneDtoFromJSON = ComunicazioneDtoFromJSON;
function ComunicazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'dataRiferimento': !runtime_1.exists(json, 'dataRiferimento') ? undefined : new Date(json['dataRiferimento']),
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    'letto': !runtime_1.exists(json, 'letto') ? undefined : json['letto'],
    'numeroRapporto': !runtime_1.exists(json, 'numeroRapporto') ? undefined : json['numeroRapporto'],
    'codiceCategoria': !runtime_1.exists(json, 'codiceCategoria') ? undefined : json['codiceCategoria']
  };
}
exports.ComunicazioneDtoFromJSONTyped = ComunicazioneDtoFromJSONTyped;
function ComunicazioneDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'dataRiferimento': value.dataRiferimento === undefined ? undefined : value.dataRiferimento.toISOString(),
    'descrizione': value.descrizione,
    'letto': value.letto,
    'numeroRapporto': value.numeroRapporto,
    'codiceCategoria': value.codiceCategoria
  };
}
exports.ComunicazioneDtoToJSON = ComunicazioneDtoToJSON;