"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerificaContrattoTipologiaContrattoEnum = exports.FirmaDigitalePrivateControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FirmaDigitalePrivateControllerApi extends runtime.BaseAPI {
  /**
   * Crea il certificato
   */
  creaCertificatoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.certificatoInfoDto === null || requestParameters.certificatoInfoDto === undefined) {
        throw new runtime.RequiredError('certificatoInfoDto', 'Required parameter requestParameters.certificatoInfoDto was null or undefined when calling creaCertificato.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/firmadigitale/certificato/crea",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CertificatoInfoDtoToJSON(requestParameters.certificatoInfoDto)
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Crea il certificato
   */
  creaCertificato(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.creaCertificatoRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce un url di firma dato per il jobId in ingresso.
   */
  generateJobUrlRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
        throw new runtime.RequiredError('jobId', 'Required parameter requestParameters.jobId was null or undefined when calling generateJobUrl.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/firmadigitale/idsign/sessionifirma/joburl/{jobId}".replace("{".concat("jobId", "}"), encodeURIComponent(String(requestParameters.jobId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.FirmaDigitaleSessionUrlDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce un url di firma dato per il jobId in ingresso.
   */
  generateJobUrl(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.generateJobUrlRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupera le sessioni di firma digitale presenti per l\'utente
   */
  recuperaJobsUtenteRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.isSessionComplete === null || requestParameters.isSessionComplete === undefined) {
        throw new runtime.RequiredError('isSessionComplete', 'Required parameter requestParameters.isSessionComplete was null or undefined when calling recuperaJobsUtente.');
      }
      const queryParameters = {};
      if (requestParameters.isSessionComplete !== undefined) {
        queryParameters['isSessionComplete'] = requestParameters.isSessionComplete;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/firmadigitale/idsign/sessionifirma",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.SessioniDiFirmaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupera le sessioni di firma digitale presenti per l\'utente
   */
  recuperaJobsUtente(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaJobsUtenteRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   */
  verificaCGSRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/firmadigitale/verificaCGS",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoVerificaCGSFromJSON(jsonValue));
    });
  }
  /**
   */
  verificaCGS() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.verificaCGSRaw();
      return yield response.value();
    });
  }
  /**
   */
  verificaContrattoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.tipologiaContratto === null || requestParameters.tipologiaContratto === undefined) {
        throw new runtime.RequiredError('tipologiaContratto', 'Required parameter requestParameters.tipologiaContratto was null or undefined when calling verificaContratto.');
      }
      const queryParameters = {};
      if (requestParameters.verificaEsitoFirma !== undefined) {
        queryParameters['verificaEsitoFirma'] = requestParameters.verificaEsitoFirma;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/firmadigitale/contratto/{tipologiaContratto}/verifica".replace("{".concat("tipologiaContratto", "}"), encodeURIComponent(String(requestParameters.tipologiaContratto))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoVerificaContrattoDtoFromJSON(jsonValue));
    });
  }
  /**
   */
  verificaContratto(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.verificaContrattoRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.FirmaDigitalePrivateControllerApi = FirmaDigitalePrivateControllerApi;
/**
    * @export
    * @enum {string}
    */
var VerificaContrattoTipologiaContrattoEnum;
(function (VerificaContrattoTipologiaContrattoEnum) {
  VerificaContrattoTipologiaContrattoEnum["ADDENDUM"] = "ADDENDUM";
  VerificaContrattoTipologiaContrattoEnum["QUESTIONARIO_MIFID"] = "QUESTIONARIO_MIFID";
  VerificaContrattoTipologiaContrattoEnum["ATTIVAZIONE_ON"] = "ATTIVAZIONE_ON";
  VerificaContrattoTipologiaContrattoEnum["QUESTIONARIO_ADEGUATA_VERIFICA"] = "QUESTIONARIO_ADEGUATA_VERIFICA";
  VerificaContrattoTipologiaContrattoEnum["CGS"] = "CGS";
  VerificaContrattoTipologiaContrattoEnum["MODIFICA_MASSIMALI"] = "MODIFICA_MASSIMALI";
  VerificaContrattoTipologiaContrattoEnum["ACQUISTO_NEXI_DEBIT"] = "ACQUISTO_NEXI_DEBIT";
})(VerificaContrattoTipologiaContrattoEnum = exports.VerificaContrattoTipologiaContrattoEnum || (exports.VerificaContrattoTipologiaContrattoEnum = {}));