"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoggerDtoToJSON = exports.LoggerDtoFromJSONTyped = exports.LoggerDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function LoggerDtoFromJSON(json) {
  return LoggerDtoFromJSONTyped(json, false);
}
exports.LoggerDtoFromJSON = LoggerDtoFromJSON;
function LoggerDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
    'debugEnabled': !runtime_1.exists(json, 'debugEnabled') ? undefined : json['debugEnabled'],
    'errorEnabled': !runtime_1.exists(json, 'errorEnabled') ? undefined : json['errorEnabled'],
    'infoEnabled': !runtime_1.exists(json, 'infoEnabled') ? undefined : json['infoEnabled'],
    'traceEnabled': !runtime_1.exists(json, 'traceEnabled') ? undefined : json['traceEnabled'],
    'warnEnabled': !runtime_1.exists(json, 'warnEnabled') ? undefined : json['warnEnabled']
  };
}
exports.LoggerDtoFromJSONTyped = LoggerDtoFromJSONTyped;
function LoggerDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'name': value.name,
    'debugEnabled': value.debugEnabled,
    'errorEnabled': value.errorEnabled,
    'infoEnabled': value.infoEnabled,
    'traceEnabled': value.traceEnabled,
    'warnEnabled': value.warnEnabled
  };
}
exports.LoggerDtoToJSON = LoggerDtoToJSON;