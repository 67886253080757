"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MfUrlResponseToJSON = exports.MfUrlResponseFromJSONTyped = exports.MfUrlResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
function MfUrlResponseFromJSON(json) {
  return MfUrlResponseFromJSONTyped(json, false);
}
exports.MfUrlResponseFromJSON = MfUrlResponseFromJSON;
function MfUrlResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mfUrl': !runtime_1.exists(json, 'mfUrl') ? undefined : json['mfUrl'],
    'mfUrlSafari': !runtime_1.exists(json, 'mfUrlSafari') ? undefined : json['mfUrlSafari']
  };
}
exports.MfUrlResponseFromJSONTyped = MfUrlResponseFromJSONTyped;
function MfUrlResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mfUrl': value.mfUrl,
    'mfUrlSafari': value.mfUrlSafari
  };
}
exports.MfUrlResponseToJSON = MfUrlResponseToJSON;