"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartaNexiIdDtoToJSON = exports.CartaNexiIdDtoFromJSONTyped = exports.CartaNexiIdDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CartaNexiIdDtoFromJSON(json) {
  return CartaNexiIdDtoFromJSONTyped(json, false);
}
exports.CartaNexiIdDtoFromJSON = CartaNexiIdDtoFromJSON;
function CartaNexiIdDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
    'value': !runtime_1.exists(json, 'value') ? undefined : json['value']
  };
}
exports.CartaNexiIdDtoFromJSONTyped = CartaNexiIdDtoFromJSONTyped;
function CartaNexiIdDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'type': value.type,
    'value': value.value
  };
}
exports.CartaNexiIdDtoToJSON = CartaNexiIdDtoToJSON;