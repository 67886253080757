"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BancomatPayControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BancomatPayControllerApi extends runtime.BaseAPI {
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationAttivazioneRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authenticationInformationAttivazione.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/infoautenticazione/attivazione/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationAttivazione(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationAttivazioneRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationDisattivaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authenticationInformationDisattiva.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/infoautenticazione/disattiva/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationDisattiva(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationDisattivaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationEnrollmentRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authenticationInformationEnrollment.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/infoautenticazione/enrollment/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationEnrollment(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationEnrollmentRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationMassimaliRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authenticationInformationMassimali.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/infoautenticazione/massimali/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationMassimali(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationMassimaliRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationModificaIbanRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authenticationInformationModificaIban.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/infoautenticazione/modificaiban/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationModificaIban(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationModificaIbanRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeAttivazioneRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authorizeAttivazione.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeAttivazione.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/autorizza/attivazione/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneAttivazioneJiffyDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeAttivazione(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeAttivazioneRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeDisattivaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authorizeDisattiva.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeDisattiva.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/autorizza/disattiva/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeDisattiva(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeDisattivaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeEnrollmentRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authorizeEnrollment.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeEnrollment.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/autorizza/enrollment/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeEnrollment(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeEnrollmentRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeMassimaliRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authorizeMassimali.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeMassimali.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/autorizza/massimali/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeMassimali(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeMassimaliRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeModificaIbanRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authorizeModificaIban.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeModificaIban.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/autorizza/modificaiban/{codiceDisposizione}".replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione virtuale
   */
  authorizeModificaIban(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeModificaIbanRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di pagamento jiffy
   */
  authorizePagamentoJiffyRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.funzione === null || requestParameters.funzione === undefined) {
        throw new runtime.RequiredError('funzione', 'Required parameter requestParameters.funzione was null or undefined when calling authorizePagamentoJiffy.');
      }
      if (requestParameters.codiceDisposizione === null || requestParameters.codiceDisposizione === undefined) {
        throw new runtime.RequiredError('codiceDisposizione', 'Required parameter requestParameters.codiceDisposizione was null or undefined when calling authorizePagamentoJiffy.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizePagamentoJiffy.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/{funzione}/autorizza/pagamentojiffy/{codiceDisposizione}".replace("{".concat("funzione", "}"), encodeURIComponent(String(requestParameters.funzione))).replace("{".concat("codiceDisposizione", "}"), encodeURIComponent(String(requestParameters.codiceDisposizione))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizionePagamentoJiffyDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di pagamento jiffy
   */
  authorizePagamentoJiffy(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizePagamentoJiffyRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Controlla genera codice atticazione App Jiffy
   */
  controlloGeneraCodiceAttivazioneRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idSessioneChiamante === null || requestParameters.idSessioneChiamante === undefined) {
        throw new runtime.RequiredError('idSessioneChiamante', 'Required parameter requestParameters.idSessioneChiamante was null or undefined when calling controlloGeneraCodiceAttivazione.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/controllo/generacodiceattivazione/{idSessioneChiamante}".replace("{".concat("idSessioneChiamante", "}"), encodeURIComponent(String(requestParameters.idSessioneChiamante))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controlla genera codice atticazione App Jiffy
   */
  controlloGeneraCodiceAttivazione(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloGeneraCodiceAttivazioneRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Controllo modifica iban
   */
  controlloModificaIbanJiffyRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.modificaIbanInputDto === null || requestParameters.modificaIbanInputDto === undefined) {
        throw new runtime.RequiredError('modificaIbanInputDto', 'Required parameter requestParameters.modificaIbanInputDto was null or undefined when calling controlloModificaIbanJiffy.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/controllo/modificaiban",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ModificaIbanInputDtoToJSON(requestParameters.modificaIbanInputDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controllo modifica iban
   */
  controlloModificaIbanJiffy(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloModificaIbanJiffyRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Controllo pagamento jiffy
   */
  controlloPagamentoJiffyRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.pagamentoJiffyDto === null || requestParameters.pagamentoJiffyDto === undefined) {
        throw new runtime.RequiredError('pagamentoJiffyDto', 'Required parameter requestParameters.pagamentoJiffyDto was null or undefined when calling controlloPagamentoJiffy.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/controllo",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.PagamentoJiffyDtoToJSON(requestParameters.pagamentoJiffyDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.PagamentoJiffyDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controllo pagamento jiffy
   */
  controlloPagamentoJiffy(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloPagamentoJiffyRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Disattiva utente
   */
  disattivaUtenteRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idSessioneChiamante === null || requestParameters.idSessioneChiamante === undefined) {
        throw new runtime.RequiredError('idSessioneChiamante', 'Required parameter requestParameters.idSessioneChiamante was null or undefined when calling disattivaUtente.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/controllo/disattivazione/{idSessioneChiamante}".replace("{".concat("idSessioneChiamante", "}"), encodeURIComponent(String(requestParameters.idSessioneChiamante))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Disattiva utente
   */
  disattivaUtente(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.disattivaUtenteRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Enrollment
   */
  enrollmentJiffyRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
        throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling enrollmentJiffy.');
      }
      if (requestParameters.idSessioneChiamante === null || requestParameters.idSessioneChiamante === undefined) {
        throw new runtime.RequiredError('idSessioneChiamante', 'Required parameter requestParameters.idSessioneChiamante was null or undefined when calling enrollmentJiffy.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/controllo/enrollment/{codiceRapporto}/{idSessioneChiamante}".replace("{".concat("codiceRapporto", "}"), encodeURIComponent(String(requestParameters.codiceRapporto))).replace("{".concat("idSessioneChiamante", "}"), encodeURIComponent(String(requestParameters.idSessioneChiamante))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Enrollment
   */
  enrollmentJiffy(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.enrollmentJiffyRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lista servizi operativi
   */
  listaServiziOperativiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idSessioneChiamante === null || requestParameters.idSessioneChiamante === undefined) {
        throw new runtime.RequiredError('idSessioneChiamante', 'Required parameter requestParameters.idSessioneChiamante was null or undefined when calling listaServiziOperativi.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/listaservizioperativi/{idSessioneChiamante}".replace("{".concat("idSessioneChiamante", "}"), encodeURIComponent(String(requestParameters.idSessioneChiamante))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ListaServiziOperativiOutputDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lista servizi operativi
   */
  listaServiziOperativi(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.listaServiziOperativiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lista servizi operativi
   */
  listaServiziOperativiResponseRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idSessioneChiamante === null || requestParameters.idSessioneChiamante === undefined) {
        throw new runtime.RequiredError('idSessioneChiamante', 'Required parameter requestParameters.idSessioneChiamante was null or undefined when calling listaServiziOperativiResponse.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/listaservizi/{idSessioneChiamante}".replace("{".concat("idSessioneChiamante", "}"), encodeURIComponent(String(requestParameters.idSessioneChiamante))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ListaServiziOperativiOutputDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lista servizi operativi
   */
  listaServiziOperativiResponse(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.listaServiziOperativiResponseRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Controllo modifica massimali Bpay
   */
  modificaMassimaliRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.modificaMassimaliInputDto === null || requestParameters.modificaMassimaliInputDto === undefined) {
        throw new runtime.RequiredError('modificaMassimaliInputDto', 'Required parameter requestParameters.modificaMassimaliInputDto was null or undefined when calling modificaMassimali.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/controllo/modificaMassimali",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ModificaMassimaliInputDtoToJSON(requestParameters.modificaMassimaliInputDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DisposizioneVirtualeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controllo modifica massimali Bpay
   */
  modificaMassimali(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.modificaMassimaliRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri pagamento jiffy
   */
  parametriJiffyRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri pagamento jiffy
   */
  parametriJiffy() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriJiffyRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce le soglie dell\'utente
   */
  recuperaSoglieRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.soglieInDto === null || requestParameters.soglieInDto === undefined) {
        throw new runtime.RequiredError('soglieInDto', 'Required parameter requestParameters.soglieInDto was null or undefined when calling recuperaSoglie.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/bancomatpay/soglie",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SoglieInDtoToJSON(requestParameters.soglieInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RecuperaImpostazioniDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le soglie dell\'utente
   */
  recuperaSoglie(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaSoglieRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Verifica stato utente
   */
  verificaStatoUtenteRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/bancomatpay/verificastatoutente",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.VerificaStatoUtenteDtoFromJSON(jsonValue));
    });
  }
  /**
   * Verifica stato utente
   */
  verificaStatoUtente() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.verificaStatoUtenteRaw();
      return yield response.value();
    });
  }
}
exports.BancomatPayControllerApi = BancomatPayControllerApi;