"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JobParametersDtoToJSON = exports.JobParametersDtoFromJSONTyped = exports.JobParametersDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function JobParametersDtoFromJSON(json) {
  return JobParametersDtoFromJSONTyped(json, false);
}
exports.JobParametersDtoFromJSON = JobParametersDtoFromJSON;
function JobParametersDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idswfreturnUrl': !runtime_1.exists(json, 'ids:wf:returnUrl') ? undefined : json['ids:wf:returnUrl'],
    'idswfworkflow': !runtime_1.exists(json, 'ids:wf:workflow') ? undefined : json['ids:wf:workflow'],
    'idswfui_workflow': !runtime_1.exists(json, 'ids:wf:ui-workflow') ? undefined : json['ids:wf:ui-workflow'],
    'idswfsignonsite': !runtime_1.exists(json, 'ids:wf:signonsite') ? undefined : json['ids:wf:signonsite']
  };
}
exports.JobParametersDtoFromJSONTyped = JobParametersDtoFromJSONTyped;
function JobParametersDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'ids:wf:returnUrl': value.idswfreturnUrl,
    'ids:wf:workflow': value.idswfworkflow,
    'ids:wf:ui-workflow': value.idswfui_workflow,
    'ids:wf:signonsite': value.idswfsignonsite
  };
}
exports.JobParametersDtoToJSON = JobParametersDtoToJSON;