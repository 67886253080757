"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BicDtoToJSON = exports.BicDtoFromJSONTyped = exports.BicDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BicDtoFromJSON(json) {
  return BicDtoFromJSONTyped(json, false);
}
exports.BicDtoFromJSON = BicDtoFromJSON;
function BicDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'prg': !runtime_1.exists(json, 'prg') ? undefined : json['prg'],
    'codiceAbi': !runtime_1.exists(json, 'codiceAbi') ? undefined : json['codiceAbi'],
    'cinAbi': !runtime_1.exists(json, 'cinAbi') ? undefined : json['cinAbi'],
    'denominazione': !runtime_1.exists(json, 'denominazione') ? undefined : json['denominazione'],
    'denominazioneBreveBanca': !runtime_1.exists(json, 'denominazioneBreveBanca') ? undefined : json['denominazioneBreveBanca'],
    'codiceCab': !runtime_1.exists(json, 'codiceCab') ? undefined : json['codiceCab'],
    'cinCab': !runtime_1.exists(json, 'cinCab') ? undefined : json['cinCab'],
    'codiceBic': !runtime_1.exists(json, 'codiceBic') ? undefined : json['codiceBic'],
    'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
    'localita': !runtime_1.exists(json, 'localita') ? undefined : json['localita'],
    'zipCode': !runtime_1.exists(json, 'zipCode') ? undefined : json['zipCode'],
    'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
    'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia']
  };
}
exports.BicDtoFromJSONTyped = BicDtoFromJSONTyped;
function BicDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'prg': value.prg,
    'codiceAbi': value.codiceAbi,
    'cinAbi': value.cinAbi,
    'denominazione': value.denominazione,
    'denominazioneBreveBanca': value.denominazioneBreveBanca,
    'codiceCab': value.codiceCab,
    'cinCab': value.cinCab,
    'codiceBic': value.codiceBic,
    'indirizzo': value.indirizzo,
    'localita': value.localita,
    'zipCode': value.zipCode,
    'comune': value.comune,
    'provincia': value.provincia
  };
}
exports.BicDtoToJSON = BicDtoToJSON;