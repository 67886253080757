"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilterAssegniFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
function FilterAssegniFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mostraCarnetEsauriti': !(0, helpers_1.exists)(json, 'mostraCarnetEsauriti') ? false : json.mostraCarnetEsauriti,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? undefined : json.rapportoSelezionato
  };
}
exports.FilterAssegniFromJSONTyped = FilterAssegniFromJSONTyped;