"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.riferimentiPrelogin = exports.contattiPrelogin = exports.disattivazioneSmartOtpConSelfCieSpidAbilitata = exports.disattivazioneSmartOtpConVecchioDispositivoAbilitata = exports.disattivazioneSmartOtpConHelpDeskAbilitata = exports.selfResetAbilitato = exports.rafforzamentoSmartOtp2Abilitato = exports.parametriPrelogin = exports.sslPinningCertificates = void 0;
const reselect_1 = require("reselect");
const preloginReducer = state => state;
exports.sslPinningCertificates = (0, reselect_1.createSelector)(preloginReducer, state => {
  return state.certificates;
});
exports.parametriPrelogin = (0, reselect_1.createSelector)(preloginReducer, state => {
  return state.parametri;
});
// deprecated
// export const rafforzamentoSmartOtpAbilitato = createSelector(
//   preloginReducer,
//   (state): boolean => {
//     return ((state.parametri?.configurazioni as { [key: string]: boolean })?.RAFFORZAMENTO_SMARTOTP_ENABLED) || false;
//   }
// );
exports.rafforzamentoSmartOtp2Abilitato = (0, reselect_1.createSelector)(preloginReducer, state => {
  var _a, _b;
  return ((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.configurazioni) === null || _b === void 0 ? void 0 : _b.RAFFORZAMENTO_SMARTOTP_2_ENABLED) || false;
});
exports.selfResetAbilitato = (0, reselect_1.createSelector)(preloginReducer, state => {
  var _a, _b;
  return ((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.configurazioni) === null || _b === void 0 ? void 0 : _b.SELFRESET_ENABLED) || false;
});
exports.disattivazioneSmartOtpConHelpDeskAbilitata = (0, reselect_1.createSelector)(preloginReducer, state => {
  var _a, _b;
  return ((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.configurazioni) === null || _b === void 0 ? void 0 : _b.DISATTIVA_SMARTOTP_HELPDESK) || false;
});
exports.disattivazioneSmartOtpConVecchioDispositivoAbilitata = (0, reselect_1.createSelector)(preloginReducer, state => {
  var _a, _b;
  return ((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.configurazioni) === null || _b === void 0 ? void 0 : _b.DISATTIVA_SMARTOTP_VECCHIODISPO) || false;
});
exports.disattivazioneSmartOtpConSelfCieSpidAbilitata = (0, reselect_1.createSelector)(preloginReducer, state => {
  var _a, _b;
  return ((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.configurazioni) === null || _b === void 0 ? void 0 : _b.DISATTIVA_SMARTOTP_SELFCIESPID) || false;
});
exports.contattiPrelogin = (0, reselect_1.createSelector)(preloginReducer, state => {
  return state.contatti;
});
exports.riferimentiPrelogin = (0, reselect_1.createSelector)(preloginReducer, state => {
  return state.riferimenti;
});