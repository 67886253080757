"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OnlineMeetingResponseToJSON = exports.OnlineMeetingResponseFromJSONTyped = exports.OnlineMeetingResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OnlineMeetingResponseFromJSON(json) {
  return OnlineMeetingResponseFromJSONTyped(json, false);
}
exports.OnlineMeetingResponseFromJSON = OnlineMeetingResponseFromJSON;
function OnlineMeetingResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
    'token': !runtime_1.exists(json, 'token') ? undefined : json['token']
  };
}
exports.OnlineMeetingResponseFromJSONTyped = OnlineMeetingResponseFromJSONTyped;
function OnlineMeetingResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
    'token': value.token
  };
}
exports.OnlineMeetingResponseToJSON = OnlineMeetingResponseToJSON;