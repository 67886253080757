"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.documentiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const documenti_types_1 = require("./documenti.types");
const initialState = {
  form: {
    tuttiIRapporti: true,
    tipoRapporto: '',
    numeroRapporto: '',
    intervallo: documenti_types_1.DocumentiTipoIntervallo.Ultimi5,
    fonteDocumento: documenti_types_1.DocumentiFonteDocumento.Comunicazioni,
    tipologiaComunicazione: undefined,
    dataDa: '',
    dataA: '',
    visualizzaSoloNonLetti: false,
    richiestoAggiornamento: false
  },
  parametri: (0, api_rest_1.ParametriDocumentaleDtoFromJSON)({}),
  rapporti: undefined,
  tipologiaComunicazioni: undefined,
  comunicazioni: [],
  segnaComeLetti: [],
  numeroNuoveComunicazioni: 0
};
const documentiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a;
  switch (action.type) {
    case __1.EReduxActionTypes.DOCUMENTI_GET_RAPPORTI_UTENTE_DOCUMENTALE_SUCCESS:
      return {
        ...state,
        rapporti: action.payload
      };
    case __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_DOCUMENTI_START:
    case __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_COMUNICAZIONI_START:
      return {
        ...state,
        comunicazioni: initialState.comunicazioni
      };
    case __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_DOCUMENTI_SUCCESS:
    case __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_COMUNICAZIONI_SUCCESS:
      return {
        ...state,
        comunicazioni: action.payload,
        form: {
          ...state.form,
          richiestoAggiornamento: false
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_GET_TIPOLOGIE_COMUNICAZIONI_SUCCESS:
      return {
        ...state,
        tipologiaComunicazioni: action.payload
      };
    case __1.EReduxActionTypes.DOCUMENTI_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload,
        form: {
          ...state.form,
          dataDa: action.payload.comunicazioniInfoDataIniziale || initialState.form.dataDa,
          dataA: action.payload.comunicazioniInfoDataFinale || initialState.form.dataA
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_GET_NUMERO_NUOVE_COMUNICAZIONI_SUCCESS:
      return {
        ...state,
        numeroNuoveComunicazioni: action.payload
      };
    case __1.EReduxActionTypes.DOCUMENTI_SEGNA_COME_LETTI_SUCCESS:
      const campiMapped = (_a = state.comunicazioni) === null || _a === void 0 ? void 0 : _a.map((e, i) => {
        var _a;
        const selected = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.some(item => item.id === e.id);
        if (selected) {
          e = {
            ...e,
            letto: true
          };
        }
        return e;
      });
      return {
        ...state,
        segnaComeLetti: action.payload,
        comunicazioni: campiMapped,
        form: {
          ...state.form,
          richiestoAggiornamento: true
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_TIPO_RAPPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          tipoRapporto: action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_RAPPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          numeroRapporto: action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_INTERVALLO:
      return {
        ...state,
        form: {
          ...state.form,
          intervallo: action.payload,
          dataDa: (action.payload === documenti_types_1.DocumentiTipoIntervallo.Personalizzato ? state.parametri.comunicazioniInfoDataIniziale : initialState.form.dataDa) || initialState.form.dataDa,
          dataA: (action.payload === documenti_types_1.DocumentiTipoIntervallo.Personalizzato ? state.parametri.comunicazioniInfoDataFinale : initialState.form.dataA) || initialState.form.dataA
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_FONTE_DOCUMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          fonteDocumento: action.payload,
          tipologiaComunicazione: action.payload === documenti_types_1.DocumentiFonteDocumento.ContabiliCassa ? '' : state.form.tipologiaComunicazione,
          intervallo: action.payload === documenti_types_1.DocumentiFonteDocumento.ContabiliCassa ? documenti_types_1.DocumentiTipoIntervallo.Personalizzato : state.form.intervallo
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_TIPOLOGIA_DOCUMENTAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          tipologiaComunicazione: action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_DATA_DA:
      return {
        ...state,
        form: {
          ...state.form,
          dataDa: action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_DATA_A:
      return {
        ...state,
        form: {
          ...state.form,
          dataA: action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_SOLO_NON_LETTI:
      return {
        ...state,
        form: {
          ...state.form,
          visualizzaSoloNonLetti: !!action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_SET_TUTTI_I_RAPPORTI:
      return {
        ...state,
        form: {
          ...state.form,
          tuttiIRapporti: !!action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_FORM_RESET:
      return {
        ...state,
        form: {
          ...initialState.form,
          dataDa: state.form.fonteDocumento === documenti_types_1.DocumentiFonteDocumento.ContabiliCassa && state.form.intervallo === documenti_types_1.DocumentiTipoIntervallo.Personalizzato ? state.parametri.comunicazioniInfoDataIniziale || initialState.form.dataDa : initialState.form.dataDa,
          dataA: state.form.fonteDocumento === documenti_types_1.DocumentiFonteDocumento.ContabiliCassa && state.form.intervallo === documenti_types_1.DocumentiTipoIntervallo.Personalizzato ? state.parametri.comunicazioniInfoDataFinale || initialState.form.dataA : initialState.form.dataDa
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_CONTRATTI_FORM_RESET:
      return {
        ...state,
        form: {
          ...initialState.form,
          dataDa: state.parametri.comunicazioniInfoDataIniziale || initialState.form.dataDa,
          dataA: state.parametri.comunicazioniInfoDataFinale || initialState.form.dataA
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_GET_DOCUMENTO_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          richiestoAggiornamento: action.payload
        }
      };
    case __1.EReduxActionTypes.DOCUMENTI_COMUNICAZIONI_RESET:
      return {
        ...state,
        parametri: state.parametri,
        form: {
          ...initialState.form,
          dataDa: state.parametri.comunicazioniInfoDataIniziale || initialState.form.dataDa,
          dataA: state.parametri.comunicazioniInfoDataFinale || initialState.form.dataA
        }
      };
    case __1.EReduxActionTypes.GENERAL_RESET_POST_CAMBIO_LINGUA:
      // action invocata dal cambio lingua
      return {
        ...state,
        tipologiaComunicazioni: initialState.tipologiaComunicazioni
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.documentiReducer = documentiReducer;
exports.default = exports.documentiReducer;