"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfiloAnagrafeDtoToJSON = exports.ProfiloAnagrafeDtoFromJSONTyped = exports.ProfiloAnagrafeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProfiloAnagrafeDtoFromJSON(json) {
  return ProfiloAnagrafeDtoFromJSONTyped(json, false);
}
exports.ProfiloAnagrafeDtoFromJSON = ProfiloAnagrafeDtoFromJSON;
function ProfiloAnagrafeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'nomeIntestazione': !runtime_1.exists(json, 'nomeIntestazione') ? undefined : json['nomeIntestazione'],
    'cognomeIntestazione': !runtime_1.exists(json, 'cognomeIntestazione') ? undefined : json['cognomeIntestazione'],
    'tipoVia': !runtime_1.exists(json, 'tipoVia') ? undefined : json['tipoVia'],
    'nomeVia': !runtime_1.exists(json, 'nomeVia') ? undefined : json['nomeVia'],
    'numeroVia': !runtime_1.exists(json, 'numeroVia') ? undefined : json['numeroVia'],
    'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
    'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
    'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
    'codiceAgenzia': !runtime_1.exists(json, 'codiceAgenzia') ? undefined : json['codiceAgenzia'],
    'descrizioneAgenzia': !runtime_1.exists(json, 'descrizioneAgenzia') ? undefined : json['descrizioneAgenzia']
  };
}
exports.ProfiloAnagrafeDtoFromJSONTyped = ProfiloAnagrafeDtoFromJSONTyped;
function ProfiloAnagrafeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'nomeIntestazione': value.nomeIntestazione,
    'cognomeIntestazione': value.cognomeIntestazione,
    'tipoVia': value.tipoVia,
    'nomeVia': value.nomeVia,
    'numeroVia': value.numeroVia,
    'cap': value.cap,
    'comune': value.comune,
    'provincia': value.provincia,
    'codiceAgenzia': value.codiceAgenzia,
    'descrizioneAgenzia': value.descrizioneAgenzia
  };
}
exports.ProfiloAnagrafeDtoToJSON = ProfiloAnagrafeDtoToJSON;