"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.praticaConclusaNexiDebitSelector = exports.annullaAcquistoNexiDebitSelector = exports.esitoVerificaCGSSelector = exports.esitoNexiDebitAcquisto = exports.formNexiDebitAcquistoStep4Valid = exports.formNexiDebitAcquistoStep3Valid = exports.formNexiDebitAcquistoStep2Valid = exports.formNexiDebitAcquistoStep1Valid = exports.formNexiDebitAcquisto = exports.userNexiDebitAcquisto = exports.convenzioneNexiDebitAcquisto = exports.parametriNexiDebitAcquisto = exports.getPanViewResponse = exports.startPanViewResponse = exports.getPinViewResponse = exports.startPinViewResponse = exports.formNexiDebit = exports.movimentiNexiDebit = exports.parametriNexiDebit = void 0;
const reselect_1 = require("reselect");
const nexiDebitReducer = state => state;
exports.parametriNexiDebit = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.parametri;
});
exports.movimentiNexiDebit = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.movimenti;
});
exports.formNexiDebit = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.form;
});
exports.startPinViewResponse = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.startPinViewResponse;
});
exports.getPinViewResponse = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.getPinViewResponse;
});
exports.startPanViewResponse = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.startPanViewResponse;
});
exports.getPanViewResponse = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.getPanViewResponse;
});
exports.parametriNexiDebitAcquisto = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.parametriAcquisto;
});
exports.convenzioneNexiDebitAcquisto = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.convenzioneRapporto;
});
exports.userNexiDebitAcquisto = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.userAcquisto;
});
exports.formNexiDebitAcquisto = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.formAcquisto;
});
exports.formNexiDebitAcquistoStep1Valid = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  const form = state.formAcquisto;
  return !(form.tipoCarta.error || form.codiceRapporto.error || form.presaVisioneDocumenti.error);
});
exports.formNexiDebitAcquistoStep2Valid = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  const form = state.formAcquisto;
  return !(form.tipoCarta.error || form.codiceRapporto.error || form.presaVisioneDocumenti.error || form.professione.error || form.tae.error || form.provenienzaFondi.error || form.provenienzaFondi.value.some(x => {
    var _a, _b;
    const selectedItem = (_b = (_a = state.parametriAcquisto) === null || _a === void 0 ? void 0 : _a.provenienzaFondiList) === null || _b === void 0 ? void 0 : _b.find(y => y.codice == x.codice);
    return selectedItem && selectedItem.showInput && !x.text;
  }) || form.personaPoliticamenteEsposta.error || form.personaPoliticamenteEsposta.value || form.scopoPrevalente.error);
});
exports.formNexiDebitAcquistoStep3Valid = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  const form = state.formAcquisto;
  return !(form.tipoCarta.error || form.codiceRapporto.error || form.professione.error || form.tae.error || form.provenienzaFondi.error || form.provenienzaFondi.value.some(x => {
    var _a, _b;
    const selectedItem = (_b = (_a = state.parametriAcquisto) === null || _a === void 0 ? void 0 : _a.provenienzaFondiList) === null || _b === void 0 ? void 0 : _b.find(y => y.codice == x.codice);
    return selectedItem && selectedItem.showInput && !x.text;
  }) || form.personaPoliticamenteEsposta.error || form.personaPoliticamenteEsposta.value || form.scopoPrevalente.error || form.presaVisioneDocumenti.error || form.consensoResponseList.some(x => x.error));
});
exports.formNexiDebitAcquistoStep4Valid = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  var _a;
  const form = state.formAcquisto;
  return !(form.tipoCarta.error || form.codiceRapporto.error || form.professione.error || form.tae.error || form.provenienzaFondi.error || form.provenienzaFondi.value.some(x => {
    var _a, _b;
    const selectedItem = (_b = (_a = state.parametriAcquisto) === null || _a === void 0 ? void 0 : _a.provenienzaFondiList) === null || _b === void 0 ? void 0 : _b.find(y => y.codice == x.codice);
    return selectedItem && selectedItem.showInput && !x.text;
  }) || form.personaPoliticamenteEsposta.error || form.personaPoliticamenteEsposta.value || form.scopoPrevalente.error || form.presaVisioneDocumenti.error || form.presaVisioneDocumentiFirma.error && !((_a = state.esitoCGS) === null || _a === void 0 ? void 0 : _a.found) || form.consensoResponseList.some(x => x.error));
});
exports.esitoNexiDebitAcquisto = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.esitoAcquisto;
});
exports.esitoVerificaCGSSelector = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.esitoCGS;
});
exports.annullaAcquistoNexiDebitSelector = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.annullaAcquisto;
});
exports.praticaConclusaNexiDebitSelector = (0, reselect_1.createSelector)(nexiDebitReducer, state => {
  return state.praticaConclusa;
});