"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoToJSON = exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON = EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON;
function EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.BollettinoPostaleDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.BollettinoPostaleDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoToJSON = EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoStatoEnum = {}));