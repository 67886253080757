"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CercaComunicazioniInDtoToJSON = exports.CercaComunicazioniInDtoFromJSONTyped = exports.CercaComunicazioniInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CercaComunicazioniInDtoFromJSON(json) {
  return CercaComunicazioniInDtoFromJSONTyped(json, false);
}
exports.CercaComunicazioniInDtoFromJSON = CercaComunicazioniInDtoFromJSON;
function CercaComunicazioniInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'soloNonLette': !runtime_1.exists(json, 'soloNonLette') ? undefined : json['soloNonLette'],
    'ultimeCinqueComunicazioni': !runtime_1.exists(json, 'ultimeCinqueComunicazioni') ? undefined : json['ultimeCinqueComunicazioni'],
    'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : new Date(json['dataIniziale']),
    'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : new Date(json['dataFinale']),
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'tipologiaComunicazioni': !runtime_1.exists(json, 'tipologiaComunicazioni') ? undefined : json['tipologiaComunicazioni']
  };
}
exports.CercaComunicazioniInDtoFromJSONTyped = CercaComunicazioniInDtoFromJSONTyped;
function CercaComunicazioniInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'soloNonLette': value.soloNonLette,
    'ultimeCinqueComunicazioni': value.ultimeCinqueComunicazioni,
    'dataIniziale': value.dataIniziale === undefined ? undefined : value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale === undefined ? undefined : value.dataFinale.toISOString(),
    'codiceRapporto': value.codiceRapporto,
    'tipologiaComunicazioni': value.tipologiaComunicazioni
  };
}
exports.CercaComunicazioniInDtoToJSON = CercaComunicazioniInDtoToJSON;