import * as React from "react";
import BankIcon from "./icon.component";

interface Props {
  visible?: boolean
}

const IconList: React.FC<Props> = (props: Props) => {

  const {
    visible
  } = props;

  const icons = [
    "assicurare_assicurare_polizze",
    "assicurare_assicurare_zurich",
    "assicurare_assicurare",
    "azioni_aggiungi_1click",
    "azioni_aggiungi_a_calendario",
    "azioni_aggiungi_a_rubrica",
    "azioni_aggiungi",
    "azioni_aggiungi_light",
    "azioni_elimina",
    "azioni_elimina_light",
    "azioni_esegui",
    "azioni_esporta_excel",
    "azioni_esporta_pdf",
    "azioni_apri_nuovo_tab",
    "azioni_seleziona_tutto",
    "azioni_segna-come-letto",
    "azioni_accetta",
    "azioni_fai_bonifico",
    "azioni_modifica_small",
    "azioni_modifica",
    "azioni_nuovo",
    "azioni_paga_bollo",
    "azioni_paga_f24",
    "azioni_rapide_rapide",
    "azioni_reset_filtri",
    "azioni_revoca",
    "azioni_riattiva",
    "azioni_sospendi",
    "azioni_ricarica_alto_adige_pass",
    "azioni_ricarica_cellulare",
    "azioni_ripeti",
    "azioni_segna_attendibile",
    "azioni_vai_elenco",
    "comunicazioni_comunicazioni_banca_cliente",
    "comunicazioni_comunicazioni_cliente_banca",
    "finanziare_cofidis",
    "finanziare_fido",
    "finanziare_finanziare",
    "finanziare_piano_finanziamento_ammortamento",
    "finanziare_piano_finanziamento_piano_finanziamento",
    "finanziare_piano_finanziamento_rate_in_mora",
    "finanziare_piano_finanziamento_rate_residue",
    "finanziare_piano_finanziamento_tuo_piano",
    "finanziare_piano_mutuo_ammortamento_mutuo",
    "finanziare_piano_mutuo_rate_in_mora",
    "finanziare_piano_mutuo_rate_residue",
    "finanziare_piano_mutuo_tuo_piano",
    "finanziare_piano_mutuo",
    "finanziare_simula_mutuo",
    "form_data",
    "form_EUR",
    "form_reset_filtri",
    "form_reset",
    "form_search_input",
    "form_select",
    "form_sort_downwards",
    "form_sort_upwards",
    "form_user",
    "guidare_guidare",
    "guidare_illuminare_e_riscaldare",
    "blocco_carte",
    "help_contatti",
    "help_help_online",
    "help_help",
    "help_ricerca_atm",
    "home_home",
    "investire_gestire_gestire_compra_vendi",
    "investire_gestire_gestire_compra",
    "investire_gestire_gestire_mercato",
    "investire_gestire_gestire_quotazioni",
    "investire_gestire_gestire_vendi",
    "investire_gestire_gestire_watchlist",
    "investire_gestire_gestire",
    "investire_investire",
    "investire_situazione_situazione_analisi",
    "investire_situazione_situazione_portafoglio_titoli",
    "investire_situazione_situazione_zainetto_fiscale",
    "investire_situazione_situazione",
    "lingua-de",
    "lingua-it",
    "logout",
    "movimenti_entrate_entrate_fiscali",
    "movimenti_entrate_entrate",
    "movimenti_entrate_proventi_finanziari",
    "movimenti_entrate_stipendio_pensione",
    "movimenti_uscite_adebito_carta_di_credito",
    "movimenti_uscite_affitto",
    "movimenti_uscite_animali_domestici",
    "movimenti_uscite_arte_e_cultura",
    "movimenti_uscite_assegno",
    "movimenti_uscite_assicurazione_casa",
    "movimenti_uscite_assicurazione_personale",
    "movimenti_uscite_assicurazione_veicolo",
    "movimenti_uscite_azioni_obbligazioni",
    "movimenti_uscite_bambini",
    "movimenti_uscite_carburante",
    "movimenti_uscite_casa_e_famiglia",
    "movimenti_uscite_commissioni_bancarie",
    "movimenti_uscite_estetica",
    "movimenti_uscite_farmacia",
    "movimenti_uscite_fondi_investimento",
    "movimenti_uscite_food_spesa_alt",
    "movimenti_uscite_food_spesa",
    "movimenti_uscite_food",
    "movimenti_uscite_gestione_veicolo",
    "movimenti_uscite_goals_progetti_risparmio",
    "movimenti_uscite_istruzione_formazione",
    "movimenti_uscite_leasing",
    "movimenti_uscite_lettura",
    "movimenti_uscite_libri_materiale_scolastico",
    "movimenti_uscite_musica_e_cinema",
    "movimenti_uscite_mutuo",
    "movimenti_uscite_oneri_finanziari",
    "movimenti_uscite_palestra",
    "movimenti_uscite_parcheggio",
    "movimenti_uscite_pedaggi",
    "movimenti_uscite_piano_pensionamento",
    "movimenti_uscite_piano_risparmio",
    "movimenti_uscite_polizza_vita",
    "movimenti_uscite_prelievo_atm",
    "movimenti_uscite_prodotti_servizi_casa",
    "movimenti_uscite_ricarica_altra_carta_prepagata",
    "movimenti_uscite_ricarica_propria_carta_prepagata",
    "movimenti_uscite_rimborso_finanziamento",
    "movimenti_uscite_risparmio",
    "movimenti_uscite_salute_benessere",
    "movimenti_uscite_shopping",
    "movimenti_uscite_sport",
    "movimenti_uscite_tasse",
    "movimenti_uscite_taxi_e_noleggi",
    "movimenti_uscite_tempo_libero",
    "movimenti_uscite_time_deposit",
    "movimenti_uscite_trasferimenti_interni",
    "movimenti_uscite_trasferimenti_tra_propri_conti",
    "movimenti_uscite_trasporti_pubblici",
    "movimenti_uscite_trasporto",
    "movimenti_uscite_universita_master",
    "movimenti_uscite_uscite",
    "movimenti_uscite_utenze",
    "movimenti_uscite_viaggi",
    "movimenti_uscite_videogiocchi",
    "movimenti_uscite_visite_mediche",
    "navigazione_chiudi",
    "navigazione_domanda",
    "navigazione_hamburger",
    "navigazione_indietro_circular",
    "navigazione_indietro",
    "navigazione_avanti",
    "navigazione_info_tooltip",
    "navigazione_mic",
    "navigazione_preferiti_off",
    "navigazione_preferiti_on",
    "navigazione_search",
    "occhio_occhio_chiuso",
    "occhio_occhio_aperto",
    "pagare_altri-pagamenti_assegni_estero",
    "pagare_altri-pagamenti_assegni_italia",
    "pagare_altri-pagamenti_assegni",
    "pagare_altri-pagamenti_interessi",
    "pagare_altri_pagamenti_RIBA",
    "pagare_altri-pagamenti_SDD",
    "pagare_altri_pagamenti",
    "pagare_bancomat_pay_bancomat_pay",
    "pagare_bolletini_bollettini",
    "pagare_bolletini_bollettino_bianco",
    "pagare_bolletini_bollettino_freccia",
    "pagare_bolletini_bollettino_premarcato",
    "pagare_bolletini_mav",
    "pagare_bonifici_bonifici",
    "pagare_bonifici_bonifico_detrazioni",
    "pagare_bonifici_bonifico_ripetitivo",
    "pagare_bonifici_girofondo",
    "pagare_bonifici_mybank",
    "pagare_bonifici_sct",
    "pagare_bonifici_sepa",
    "pagare_carte_carta_di_credito",
    "pagare_carte_carta_e_conto",
    "pagare_carte_carta_prepagata",
    "pagare_carte_carte",
    "pagare_conti_correnti_conti_correnti_bilancio",
    "pagare_conti_correnti_estratto_conto_estero",
    "pagare_conti_correnti_estratto_conto_italia",
    "pagare_conti_correnti_lista_movimenti",
    "pagare_conti_correnti",
    "pagare_prelievo_cardless_prelievo_cardless",
    "pagare_prelievo_cardless_prelievo_sos",
    "pagare_ricariche_AAP_emissione_bis",
    "pagare_ricariche_AAP_emissione",
    "pagare_ricariche_AAP_pagamento_duplicato",
    "pagare_ricariche_AAP_ricarica",
    "pagare_ricariche_AAP_tariffa_annuale",
    "pagare_ricariche_carta_e_conto",
    "pagare_ricariche_carta_prepagata",
    "pagare_ricariche_cell",
    "pagare_ricariche_ricariche",
    "pagare_rubrica",
    "pagare_saldi_e_movimenti",
    "pagare_scadenzario",
    "pagare_tasse_cbill_aci",
    "pagare_tasse_cbill_pagopa",
    "pagare_tasse_f24_accise",
    "pagare_tasse_f24_elide",
    "pagare_tasse_f24_ordinario",
    "pagare_tasse_f24_semplificato",
    "pagare_tasse_f24",
    "pagare_tasse_pagamenti_effetuati_canone_aci",
    "pagare_tasse_pagamenti_effetuati_canone_rai",
    "pagare_tasse_pagamenti_effetuati_pagamenti_effettuati",
    "pagare_tasse_pagamenti_effetuati_tributi_locali_bolzano",
    "pagare_tasse_rav",
    "pagare_tasse_tasse",
    "blocco_utente",
    "profilo_accesso_gestione_credenziali",
    "profilo_accesso",
    "profilo_dati_personali_consultazione",
    "profilo_dati_personali",
    "profilo_dati_personali_fatca",
    "profilo_dati_personali_kyc",
    "profilo_dati_personali_mifid",
    "profilo_dati_personali_modifica_recapiti",
    "profilo_gestione_rapporti_1click",
    "profilo_gestione_rapporti_canale_dispositivo",
    "profilo_gestione_rapporti_condizioni",
    "profilo_gestione_rapporti_elenco_rapporti",
    "profilo_gestione_rapporti_imposta_preferiti",
    "limiti_operativi",
    "profilo_gestione_rapporti",
    "random_caret_down_red",
    "random_caret_up_red",
    "risparmiare_conti_deposito_conti_deposito_interessi",
    "risparmiare_conti_deposito_conti_deposito_movimenti",
    "risparmiare_conti_deposito_conti_deposito_tasso",
    "risparmiare_conti_deposito_conti_deposito",
    "risparmiare_deposito_risparmio_deposito_risparmio",
    "risparmiare_libretto_libretto",
    "risparmiare_risparmiare",
    "risparmiare_time_deposit_time_deposit_sottoscrizione",
    "risparmiare_time_deposit_time_deposit",
    "rubrica_beneficiario_attendibile",
    "rubrica_beneficiario_non_attendibile",
    "user-generic",
    "orologio",
    "empty",
    "alert",
    "sca_face_id",
    "sca_push",
    "sca_qr",
    "pagare_pagare",
    "azioni_mini_aggiungi_1click_mini",
    "azioni_mini_aggiungi_a_calendario_mini",
    "azioni_mini_aggiungi_mini",
    "azioni_mini_condividi_mini",
    "azioni_mini_dettaglio_mini",
    "azioni_mini_elimina_mini",
    "azioni_mini_esegui_mini",
    "azioni_mini_esporta_excel_mini",
    "azioni_mini_esporta_pdf_mini",
    "azioni_mini_fai_bonifico_mini",
    "azioni_mini_go_back_mini",
    "azioni_mini_modifica_mini",
    "azioni_mini_nuovo_mini",
    "azioni_mini_paga_bollo_mini",
    "azioni_mini_paga_f24_mini",
    "azioni_mini_reset_filtri_mini",
    "azioni_mini_revoca_mini",
    "azioni_mini_ricarica_cellulare_mini",
    "azioni_mini_ripeti_mini",
    "azioni_mini_segna_attendibile_mini",
    "azioni_mini_vai_elenco_mini",
    "azioni_vai_docs",
    "pagare_mini_aggiungi_a_rubrica_mini",
    "chiama_mini",
    "chiama",
    "navigazione_breadcrumb_slash",
    "random_target",
    "lista",
    "conti_e_carte_conti_e_carte",
    "conti_e_carte_sdd",
    "accetta_tutte",
    "visualizza_grafico",
    "portale_nexi",
    "pagare_ricariche_cell",
    "azioni_accetta_tutte",
    "navigazione_avanti",
    "sca_just-qr",
    "sca-secure-call",
    "sca_push",
    "sca_token_hw",
    "sca_no_sca",
    "sca_generica",
    "azioni-dettaglio-active-mini",
    "copia_mini",
    "form_conferma",
    "azioni-ingrandisci-schermo",
    "form-apri-accordion",
    "form-chiudi-accordion",
    "visto_checkbox",
    "azioni-imposta-preferito_mini",
    "azioni_mini_revoca_mini_1",
    "profilo_gestione_credenziali",
    "azioandamentoni",
    "azioni_preferito",
    "azioni_non_preferito",
    "azioni_aggiungi_green",
    "azioni-mini-preferito-item",
    "azioni-mini-preferito-aggiungi-item",
    "azioni-mini-preferito-vuoto",
    "sca-autorizza-isi-banking",
    "sca-qr",
    "pagamenti_pos",
    "commissioni_bancarie",
    "finanziamento_ammortamento",
    "situazione_scadenza_titoli",
    "flussi_di_cassa_attesi",
    "rendimenti_titoli",
    "profilo_patrimonio",
    "icon_azioni_scaricaa",
    "azioni_scarica",
    "azioni-draggable",
    "azioni_time_deposit_box",
    "time-deposit-errore",
    "time-deposit-elaborazione",
    "varie",
    "attiva_carta_debito",
    "carte_debito_credito_smartcard",
    "conti_correnti",
    "conti_deposito_time_deposit",
    "gas_luce",
    "internet_banking",
    "investimenti",
    "libretti_risparmio",
    "modifica_massimali",
    "mutuo",
    "noleggio_auto",
    "prestiti",
    "prodotti_assicurativi",
    "ricontattato",
    "gestire_storico_ordine",
    "azioni-carica",
    "tributi_locali",
    "azioni-vai-docs",
    "azioni-vai-elenco",
    "PCT",
    "navigazione-search",
    "dettaglio-mini",
    "polizze_investimento",
    "i-tuoi-dati",
    "per-te",
    "servizi",
    "asset-class",
    "asset-type",
    "rimuovi_mini",
    "contratti-generico",
    "comunicazioni-comunicazioni",
    "azioni-mini-esegui-mini",
    "area-personale-impostazioni-canali",
    "shield",
    "ricarica_cellulare_trusted",
    "ricarica_cellulare",
    "paga_f24_trusted",
    "paga_f24",
    "paga_bollo_trusted",
    "paga_bollo",
    "paga_alto_adige_pass_trusted",
    "paga_alto_adige_pass",
    "fai_bonifico_trusted",
    "fai_bonifico",
    "nuovo-beneficiario",
    "no-beneficiari",
    "la-tua-banca",
    "consensi-terze-parti",
    "contratto-generale",
    "documento-generico",
    "contatti",
    "canali",
    "faq",
    "assicurare_protection_mountain",
    "assicurare_gestione_polizze",
    "assicurare_digital_insurance",
    "tour-play",
    "spalla-destra",
    "freccia-sinistra",
    "assistenza",
    "profilo",
    "comunicazioni",
    "accesso_sicurezza",
    "impostazioni",
    "firma_proposte",
    "accordion_arrow_down",
    "accordion_arrow_up",
    "freccetta-destra",
    "icon-funerale",
    "icon-ospedale",
    "icon-trasporto",
    "QRCodeF24",
    "freccetta-destra",
    "consulente_clean",
    "ultimo-accesso"
  ];

  return (
    <div style={{ overflow: 'auto', height: '100%', padding: 64, backgroundColor: "#c7c7c7" }}>
      {icons.map((icon, index) => (
        <div key={`icon-${index}`} style={{ height: "60px" }}>
          <BankIcon
            title={icon}
            tooltip={icon}
            onClick={() => { console.log("click") }}
          />&nbsp;
          <BankIcon
            title={icon}
            tooltip={icon}
            white
            onClick={() => { console.log("click") }}
          />&nbsp;
          <BankIcon
            title={icon}
            tooltip={icon}
            disabled
            onClick={() => { console.log("click") }}
          />&nbsp;
          <BankIcon
            title={icon}
            tooltip={icon}
            monochrome
            onClick={() => { console.log("click") }}
          />
          <span>&nbsp;{icon}</span>
        </div>
      ))}
    </div>
  );
};

export default IconList;
