"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SchedaStrumentoItemDtoToJSON = exports.SchedaStrumentoItemDtoFromJSONTyped = exports.SchedaStrumentoItemDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SchedaStrumentoItemDtoFromJSON(json) {
  return SchedaStrumentoItemDtoFromJSONTyped(json, false);
}
exports.SchedaStrumentoItemDtoFromJSON = SchedaStrumentoItemDtoFromJSON;
function SchedaStrumentoItemDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'desc': !runtime_1.exists(json, 'desc') ? undefined : json['desc']
  };
}
exports.SchedaStrumentoItemDtoFromJSONTyped = SchedaStrumentoItemDtoFromJSONTyped;
function SchedaStrumentoItemDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mercato': value.mercato,
    'codice': value.codice,
    'desc': value.desc
  };
}
exports.SchedaStrumentoItemDtoToJSON = SchedaStrumentoItemDtoToJSON;