"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FiltroTimeDepositDtoToJSON = exports.FiltroTimeDepositDtoFromJSONTyped = exports.FiltroTimeDepositDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroTimeDepositDtoFromJSON(json) {
  return FiltroTimeDepositDtoFromJSONTyped(json, false);
}
exports.FiltroTimeDepositDtoFromJSON = FiltroTimeDepositDtoFromJSON;
function FiltroTimeDepositDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'numeroOperazione': !runtime_1.exists(json, 'numeroOperazione') ? undefined : json['numeroOperazione'],
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'dataDecorrenzaDa': !runtime_1.exists(json, 'dataDecorrenzaDa') ? undefined : new Date(json['dataDecorrenzaDa']),
    'dataDecorrenzaA': !runtime_1.exists(json, 'dataDecorrenzaA') ? undefined : new Date(json['dataDecorrenzaA']),
    'dataScadenzaDa': !runtime_1.exists(json, 'dataScadenzaDa') ? undefined : new Date(json['dataScadenzaDa']),
    'dataScadenzaA': !runtime_1.exists(json, 'dataScadenzaA') ? undefined : new Date(json['dataScadenzaA']),
    'isDepoBox': !runtime_1.exists(json, 'isDepoBox') ? undefined : json['isDepoBox']
  };
}
exports.FiltroTimeDepositDtoFromJSONTyped = FiltroTimeDepositDtoFromJSONTyped;
function FiltroTimeDepositDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'numeroOperazione': value.numeroOperazione,
    'stato': value.stato,
    'dataDecorrenzaDa': value.dataDecorrenzaDa === undefined ? undefined : value.dataDecorrenzaDa.toISOString(),
    'dataDecorrenzaA': value.dataDecorrenzaA === undefined ? undefined : value.dataDecorrenzaA.toISOString(),
    'dataScadenzaDa': value.dataScadenzaDa === undefined ? undefined : value.dataScadenzaDa.toISOString(),
    'dataScadenzaA': value.dataScadenzaA === undefined ? undefined : value.dataScadenzaA.toISOString(),
    'isDepoBox': value.isDepoBox
  };
}
exports.FiltroTimeDepositDtoToJSON = FiltroTimeDepositDtoToJSON;