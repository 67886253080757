"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriCalendarioDtoToJSON = exports.ParametriCalendarioDtoFromJSONTyped = exports.ParametriCalendarioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriCalendarioDtoFromJSON(json) {
  return ParametriCalendarioDtoFromJSONTyped(json, false);
}
exports.ParametriCalendarioDtoFromJSON = ParametriCalendarioDtoFromJSON;
function ParametriCalendarioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'periodicita': !runtime_1.exists(json, 'periodicita') ? undefined : json['periodicita'],
    'intervallo': !runtime_1.exists(json, 'intervallo') ? undefined : json['intervallo'],
    'tipoAvviso': !runtime_1.exists(json, 'tipoAvviso') ? undefined : json['tipoAvviso'],
    'canali': !runtime_1.exists(json, 'canali') ? undefined : json['canali'],
    'operazioneAssociata': !runtime_1.exists(json, 'operazioneAssociata') ? undefined : json['operazioneAssociata'],
    'numeroNotificheOggi': !runtime_1.exists(json, 'numeroNotificheOggi') ? undefined : json['numeroNotificheOggi'],
    'pfmCalendarPatterns': !runtime_1.exists(json, 'pfmCalendarPatterns') ? undefined : json['pfmCalendarPatterns'].map(_1.PFMCalendarPatternDtoFromJSON),
    'altriParametri': !runtime_1.exists(json, 'altriParametri') ? undefined : json['altriParametri']
  };
}
exports.ParametriCalendarioDtoFromJSONTyped = ParametriCalendarioDtoFromJSONTyped;
function ParametriCalendarioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'periodicita': value.periodicita,
    'intervallo': value.intervallo,
    'tipoAvviso': value.tipoAvviso,
    'canali': value.canali,
    'operazioneAssociata': value.operazioneAssociata,
    'numeroNotificheOggi': value.numeroNotificheOggi,
    'pfmCalendarPatterns': value.pfmCalendarPatterns === undefined ? undefined : value.pfmCalendarPatterns.map(_1.PFMCalendarPatternDtoToJSON),
    'altriParametri': value.altriParametri
  };
}
exports.ParametriCalendarioDtoToJSON = ParametriCalendarioDtoToJSON;