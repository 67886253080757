"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bloccoCarteReducer = void 0;
const __1 = require("..");
const initialState = {
  riferimenti: []
};
const bloccoCarteReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS:
      return {
        ...state,
        riferimenti: action.payload
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.bloccoCarteReducer = bloccoCarteReducer;
exports.default = exports.bloccoCarteReducer;