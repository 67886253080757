"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.urlRedirect = exports.loginMyBankType = exports.nascondiModaleEnrollmentPerSessioneScaduta = exports.selfResetStarted = exports.selfResetStatus = exports.urlWebViewMigrazione = exports.tokenResettato = exports.formCambioPasswordObbligatorio = exports.formAzioniSmart = exports.formEnrollment = exports.refreshToken = exports.jwtToken = exports.strongAuthInfo = exports.loginError = exports.authenticationError = exports.authenticationResponse = exports.formLogin = void 0;
const reselect_1 = require("reselect");
const loginReducer = state => state;
exports.formLogin = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.formLogin;
});
exports.authenticationResponse = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.authenticationResponse;
});
exports.authenticationError = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.authenticationError;
});
exports.loginError = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.loginError;
});
exports.strongAuthInfo = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.strongAuthInfo;
});
exports.jwtToken = (0, reselect_1.createSelector)(loginReducer, state => {
  var _a;
  return ((_a = state.jwtToken) === null || _a === void 0 ? void 0 : _a.jwtToken) || '';
});
exports.refreshToken = (0, reselect_1.createSelector)(loginReducer, state => {
  var _a;
  return ((_a = state.jwtToken) === null || _a === void 0 ? void 0 : _a.refreshToken) || '';
});
exports.formEnrollment = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.formEnrollment;
});
exports.formAzioniSmart = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.formAzioniSmart;
});
exports.formCambioPasswordObbligatorio = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.formCambioPasswordObbligatorio;
});
exports.tokenResettato = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.tokenResettato;
});
exports.urlWebViewMigrazione = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.urlWebViewMigrazione;
});
exports.selfResetStatus = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.selfResetStatus;
});
exports.selfResetStarted = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.selfResetStarted;
});
exports.nascondiModaleEnrollmentPerSessioneScaduta = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.nascondiModaleEnrollmentPerSessioneScaduta;
});
exports.loginMyBankType = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.loginMyBankType;
});
exports.urlRedirect = (0, reselect_1.createSelector)(loginReducer, state => {
  return state.urlRedirect;
});