"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneRavDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneRavDtoToJSON = exports.EsitoAutorizzazioneDisposizioneRavDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneRavDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneRavDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneRavDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneRavDtoFromJSON = EsitoAutorizzazioneDisposizioneRavDtoFromJSON;
function EsitoAutorizzazioneDisposizioneRavDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.RavDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneRavDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneRavDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneRavDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.RavDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneRavDtoToJSON = EsitoAutorizzazioneDisposizioneRavDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneRavDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneRavDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneRavDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneRavDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneRavDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneRavDtoStatoEnum = {}));