"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LetturaDatiAggiuntiviRichiestaDtoToJSON = exports.LetturaDatiAggiuntiviRichiestaDtoFromJSONTyped = exports.LetturaDatiAggiuntiviRichiestaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function LetturaDatiAggiuntiviRichiestaDtoFromJSON(json) {
  return LetturaDatiAggiuntiviRichiestaDtoFromJSONTyped(json, false);
}
exports.LetturaDatiAggiuntiviRichiestaDtoFromJSON = LetturaDatiAggiuntiviRichiestaDtoFromJSON;
function LetturaDatiAggiuntiviRichiestaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiciDati': !runtime_1.exists(json, 'codiciDati') ? undefined : json['codiciDati']
  };
}
exports.LetturaDatiAggiuntiviRichiestaDtoFromJSONTyped = LetturaDatiAggiuntiviRichiestaDtoFromJSONTyped;
function LetturaDatiAggiuntiviRichiestaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiciDati': value.codiciDati
  };
}
exports.LetturaDatiAggiuntiviRichiestaDtoToJSON = LetturaDatiAggiuntiviRichiestaDtoToJSON;