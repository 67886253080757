"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esitoCompravendita = exports.infoDispositivaCompravendita = exports.riepilogoCompravendita = exports.formCompravenditaValid = exports.buildControlloCompravenditaTitolo = exports.formCompravendita = exports.buildInputGetParametriAvanzati = exports.bookTitolo = exports.quotazioneDettaglioTitolo = exports.parametriAvanzatiCompravendita = exports.compravenditaDossierCustomer = exports.compravenditaDossierRapporti = exports.parametriCompravendita = void 0;
const moment = require("moment");
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const compravenditaReducer = state => state;
exports.parametriCompravendita = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.parametri;
});
exports.compravenditaDossierRapporti = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.dossierRapporti;
});
exports.compravenditaDossierCustomer = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.customerDossier;
});
exports.parametriAvanzatiCompravendita = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.parametriAvanzati;
});
exports.quotazioneDettaglioTitolo = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.quotazioneTitolo;
});
exports.bookTitolo = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.bookTitolo;
});
exports.buildInputGetParametriAvanzati = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  var _a, _b;
  return {
    codiceIsin: state.form.codiceIsin,
    mercatoEsterno: state.form.mercatoEsterno || "0",
    mercatoSelezionato: state.form.mercatoOrdine,
    // codiceRapporto: state.form.segnoOrdine == "A" ? (state.form?.dossierDepositoSelezionato ? Number(state.form?.dossierDepositoSelezionato) : 0) : state.form.rapportoSelezionato?.rapporto?.id,
    codiceRapporto: ((_a = state.form) === null || _a === void 0 ? void 0 : _a.dossierDepositoSelezionato) ? Number((_b = state.form) === null || _b === void 0 ? void 0 : _b.dossierDepositoSelezionato) : 0,
    flagMercatoChiuso: false,
    segno: state.form.segnoOrdine
  };
});
exports.formCompravendita = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.form;
});
const getOrdineLimitato = tipoPrezzo => {
  return tipoPrezzo === 'S' || tipoPrezzo === 'SBL' || tipoPrezzo === 'SLL';
};
const getCondizioneStop = tipoPrezzo => {
  if (tipoPrezzo === 'SB' || tipoPrezzo === 'SBL') {
    return '5';
  } else if (tipoPrezzo === 'SL' || tipoPrezzo === 'SLL') {
    return '6';
  } else {
    return undefined;
  }
};
const getParametroPrezzo = tipoPrezzo => {
  return tipoPrezzo === 'SB' || tipoPrezzo === 'SBL' || tipoPrezzo === 'SL' || tipoPrezzo === 'SLL' ? 'ERP' : undefined;
};
exports.buildControlloCompravenditaTitolo = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return {
    codiceRapporto: Number(state.form.dossierDepositoSelezionato),
    titolo: state.parametriAvanzati.titolo,
    indicativoTitolo: state.parametriAvanzati.indicativoTitolo,
    codiceIsin: state.parametriAvanzati.codiceIsin,
    segnoOrdine: state.form.segnoOrdine,
    mercatoOrdine: state.parametriAvanzati.mercatiOrdine ? state.parametriAvanzati.mercatiOrdine[0] : undefined,
    quantitaRichiesta: state.form.quantita,
    // executionOnly: vm.actionData.formParent.areaAcquisto.executionOnly,
    ordineLimitato: getOrdineLimitato(state.form.tipoPrezzo),
    prezzoLimite: (0, helpers_1.formattedNumberToNumber)(state.form.prezzoLimite),
    condizioneStop: getCondizioneStop(state.form.tipoPrezzo),
    prezzoStop: (0, helpers_1.formattedNumberToNumber)(state.form.prezzoStartStop),
    dataFinale: moment(state.form.data).toDate(),
    parametroQuantita: state.form.tipoQuantita,
    parametroPrezzo: getParametroPrezzo(state.form.tipoPrezzo),
    parametroTempo: state.form.validita,
    faseOrdine: state.form.esecuzione,
    oraLimite: undefined,
    ordineProssimaGiornata: false,
    divisa: state.parametriAvanzati.divisa,
    flagMercatoChiuso: false,
    dataIniziale: undefined
  };
  /*
  {
    "codiceRapporto": 1204805000149,
    "titolo": 313247,
    "indicativoTitolo": 0,
    "codiceIsin": "IT0003132476",
    "segnoOrdine": "A",
    "mercatoOrdine": "MTA",
    "quantitaRichiesta": 1,
    "faseOrdine": "NEG",
    "executionOnly": false,
    "ordineLimitato": false,
    "prezzoLimite": 1,
    "dataFinale": null,
    "parametroQuantita": null,
    "parametroPrezzo": null,
    "parametroTempo": null,
    "oraLimite": null,
    "ordineProssimaGiornata": false,
    "condizioneStop": null,
    "prezzoStop": null,
    "prezzoStart": null,
    "rapportoCC": null,
    "controvalorePresunto": null,
    "saldoContoCorrente": null,
    "numeroOrdine": null,
    "dataIniziale": "2020-16-10",
    "quantitaMinima": null,
    "profiloRischio": null,
    "classificazioneMifid": null,
    "adeguatoAppropriato": false,
    "conflittoInteressi": false,
    "pattiChiari": false,
    "divisa": "EUR",
    "flagMercatoChiuso": false,
    "adeguatoAppropriatoStringa": null,
    "conflittoInteressiStringa": null,
    "productGovernance": null,
    "derogataAdeguatezzaAppropriatezza": null,
    "kidKiid": null,
    "raccomandazione": null,
    "negoziazioneContoTerzi": null,
    "collocamento": null,
    "negoziazioneContoProprio": null,
    "visualizzaSpeseVerificaOrdine": false,
    "obbligatorioVisionePopupSpese": false
  }
  */
});
exports.formCompravenditaValid = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  let formCompravenditaValid = true;
  if (state.form.quantitaError) {
    formCompravenditaValid = false;
  }
  if (state.form.prezzoStartStopEnabled && state.form.prezzoStartStopError) {
    formCompravenditaValid = false;
  }
  if (state.form.prezzoLimiteEnabled && state.form.prezzoLimiteError) {
    formCompravenditaValid = false;
  }
  return formCompravenditaValid;
});
exports.riepilogoCompravendita = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.riepilogo;
});
exports.infoDispositivaCompravendita = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.infoDispositiva;
});
exports.esitoCompravendita = (0, reselect_1.createSelector)(compravenditaReducer, state => {
  return state.esito;
});