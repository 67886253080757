"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generalReducer = void 0;
const __1 = require("..");
const initialState = {
  error: null,
  loaderCounter: 0,
  isLoader: false,
  sessionExpired: false,
  waitingSections: [],
  nextRoute: '',
  prevRoute: '',
  currentTab: -1,
  webMenuExpanded: false,
  blurText: false,
  stepHomeBonifico: 0,
  breadCrumbs: [],
  tooltipMobile: null,
  bottomTabBarVisible: true,
  mobileTheme: 'light',
  snackbar: [],
  callerObject: {},
  stepHomeRav: 0,
  stepHomeMav: 0,
  saldoContabileSaldoDisponibile: true,
  stepHomeBollettinoPostale: 0,
  isConnected: true,
  popUps: [],
  neosuranceLoginAsked: false,
  azioniSmartVisible: false,
  noRapporti: null,
  postLoginError: false,
  assistenteOpened: false,
  erroreAdeguataVerifica: null,
  chatGenesysOpened: false,
  inAppBrowserOpened: false,
  ACSLoginAsked: false,
  downloadPDF: false,
  androidPermissionGranted: false
};
function generalReducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.SET_GENERAL_LOADER:
      const updatedLoaderCounter = action.active ? state.loaderCounter + 1 : state.loaderCounter > 0 ? state.loaderCounter - 1 : 0;
      return {
        ...state,
        loaderCounter: updatedLoaderCounter,
        isLoader: updatedLoaderCounter > 0
      };
    case __1.EReduxActionTypes.SET_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: action.payload,
        popUps: initialState.popUps
      };
    case __1.EReduxActionTypes.SET_GENERAL_WAITING_SECTION:
      return {
        ...state,
        waitingSections: JSON.parse(JSON.stringify(!action.active ? state.waitingSections.filter(s => s !== action.section) : state.waitingSections.concat(action.section).filter((value, index, self) => self.indexOf(value) === index)))
      };
    case __1.EReduxActionTypes.SET_GENERAL_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case __1.EReduxActionTypes.RESET_GENERAL_ERROR:
      return {
        ...state,
        error: null
      };
    case __1.EReduxActionTypes.SET_GENERAL_ROUTE:
      return {
        ...state,
        nextRoute: action.payload.nextRoute,
        prevRoute: action.payload.prevRoute
      };
    case __1.EReduxActionTypes.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload
      };
    case __1.EReduxActionTypes.SET_GENERAL_WEBMENU:
      return {
        ...state,
        webMenuExpanded: action.webMenuExpanded
      };
    case __1.EReduxActionTypes.SET_GENERAL_BLURTEXT:
      return {
        ...state,
        blurText: action.blurText
      };
    case __1.EReduxActionTypes.SET_GENERAL_HOME_BONIFICO:
      return {
        ...state,
        stepHomeBonifico: action.payload
      };
    case __1.EReduxActionTypes.SET_GENERAL_BREAD_CRUMBS:
      return {
        ...state,
        breadCrumbs: action.payload
      };
    case __1.EReduxActionTypes.SHOW_TOOLTIP_MOBILE:
      return {
        ...state,
        tooltipMobile: action.payload
      };
    case __1.EReduxActionTypes.SET_BOTTOM_TAB_BAR_VISIBLE:
      return {
        ...state,
        bottomTabBarVisible: action.payload
      };
    case __1.EReduxActionTypes.GENERAL_ADD_SNACKBAR:
      return {
        ...state,
        snackbar: [...state.snackbar, {
          id: state.snackbar.length > 0 ? state.snackbar[state.snackbar.length - 1].id + 1 : 1,
          descrizione: action.payload
        }]
      };
    case __1.EReduxActionTypes.GENERAL_ADD_OR_REPLACE_SNACKBAR:
      const newSnackbars = state.snackbar.filter(val => val.id != action.payload.id);
      newSnackbars.push({
        id: action.payload.id,
        descrizione: action.payload.descrizione
      });
      return {
        ...state,
        snackbar: [...newSnackbars]
      };
    case __1.EReduxActionTypes.GENERAL_DEL_SNACKBAR:
      const newSnack = state.snackbar.filter(val => val.id !== action.payload);
      return {
        ...state,
        snackbar: newSnack
      };
    case __1.EReduxActionTypes.SET_GENERAL_CALLER_OBJECT:
      return {
        ...state,
        callerObject: action.payload
      };
    case __1.EReduxActionTypes.RESET_GENERAL_CALLER_OBJECT:
      return {
        ...state,
        callerObject: {}
      };
    case __1.EReduxActionTypes.SET_GENERAL_HOME_RAV:
      return {
        ...state,
        stepHomeRav: action.payload
      };
    case __1.EReduxActionTypes.SET_MOBILE_THEME:
      return {
        ...state,
        mobileTheme: action.payload
      };
    case __1.EReduxActionTypes.SET_GENERAL_HOME_MAV:
      return {
        ...state,
        stepHomeMav: action.payload
      };
    case __1.EReduxActionTypes.SWITCH_SALDO_CONTABILE_SALDO_DISPONIBILE:
      return {
        ...state,
        saldoContabileSaldoDisponibile: action.payload
      };
    case __1.EReduxActionTypes.SET_GENERAL_HOME_BOLLETTINO:
      return {
        ...state,
        stepHomeBollettinoPostale: action.payload
      };
    case __1.EReduxActionTypes.SET_GENERAL_ISCONNECTED:
      return {
        ...state,
        isConnected: action.payload
      };
    case __1.EReduxActionTypes.PUSH_GENERAL_POPUP:
      const popUpsNum = state.popUps.length;
      return {
        ...state,
        popUps: [...state.popUps, {
          ...action.payload,
          id: popUpsNum > 0 ? (state.popUps[popUpsNum - 1].id || 0) + 1 : 0
        }]
      };
    case __1.EReduxActionTypes.REMOVE_GENERAL_POPUP:
      return {
        ...state,
        popUps: state.popUps.filter(popUp => popUp.id !== action.payload)
      };
    case __1.EReduxActionTypes.SET_DISABLE_BUTTON_OK_CURRENT_GENERAL_POPUP:
      return {
        ...state,
        popUps: state.popUps.map((popUp, index) => {
          if (index == 0 && popUp.type == 'confirm') {
            return {
              ...popUp,
              buttonOk: {
                ...popUp.buttonOk,
                disabled: action.payload
              }
            };
          }
          return popUp;
        })
      };
    case __1.EReduxActionTypes.SET_NEOSURANCE_LOGIN_ASKED:
      return {
        ...state,
        neosuranceLoginAsked: action.payload
      };
    case __1.EReduxActionTypes.SET_AZIONI_SMART_VISIBLE:
      return {
        ...state,
        azioniSmartVisible: action.payload
      };
    case __1.EReduxActionTypes.SET_GENERAL_NO_RAPPORTI:
      return {
        ...state,
        noRapporti: action.payload
      };
    case __1.EReduxActionTypes.RESET_GENERAL_NO_RAPPORTI:
      return {
        ...state,
        noRapporti: null
      };
    case __1.EReduxActionTypes.SET_GENERAL_POST_LOGIN_ERROR:
      return {
        ...state,
        postLoginError: action.payload
      };
    case __1.EReduxActionTypes.RESET_GENERAL_POST_LOGIN_ERROR:
      return {
        ...state,
        postLoginError: initialState.postLoginError
      };
    case __1.EReduxActionTypes.SET_ASSISTENTE_OPENED:
      return {
        ...state,
        assistenteOpened: action.payload
      };
    case __1.EReduxActionTypes.GENERAL_SET_ERRORE_ADEGUATA_VERIFICA:
      return {
        ...state,
        erroreAdeguataVerifica: action.payload
      };
    case __1.EReduxActionTypes.GENERAL_RESET_ERRORE_ADEGUATA_VERIFICA:
      return {
        ...state,
        erroreAdeguataVerifica: initialState.erroreAdeguataVerifica
      };
    case __1.EReduxActionTypes.GENERAL_SET_CHAT_GENESYS_OPENED:
      return {
        ...state,
        chatGenesysOpened: action.payload
      };
    case __1.EReduxActionTypes.GENERAL_SET_IN_APP_BROWSER_OPENED:
      return {
        ...state,
        inAppBrowserOpened: action.payload
      };
    case __1.EReduxActionTypes.SET_ACS_LOGIN_ASKED:
      return {
        ...state,
        ACSLoginAsked: action.payload
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState,
        postLoginError: state.postLoginError
      };
    case __1.EReduxActionTypes.SET_DOWNLOAD_PDF:
      return {
        ...state,
        downloadPDF: action.payload
      };
    case __1.EReduxActionTypes.SET_ANDROID_PERMISSION_GRANTED:
      return {
        ...state,
        androidPermissionGranted: action.payload
      };
    default:
      return state;
  }
}
exports.generalReducer = generalReducer;