import { getOperazioniPreferiti, operazioniPreferite, setOperazioniPreferiti } from "@sparkasse/commons";
import closeIcon from "assets/icons/navigazione/chiudi.svg";
import { WEBPAGE } from "component/history/history.component";
import BankIcon, { ICON_ALIAS } from "component/Icon/icon.component";
import InputBox from "component/inputBox/inputBox.component";
import InputText from "component/inputText/inputText.component";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GridLayout from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import responsiveImage from "../../assets/image/responsive_illustration_2.svg";
import { AppState } from "../../reducers";

interface funzionalita {
  id: string
  descrizione: string
}

const minPreferiti = 3;
const maxPreferiti = 5;

const defaultQuickOperationItem: OperazionePreferita[] = [0, 1, 2, 3, 4].map((_, i) => ({
  id: `default${i}`,
  descrizione: "DEFAULT",
  icon: "navigazione_preferiti_on",
  path: "",
  order: maxPreferiti
}));

const ModalPreferitiComponent: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    show,
    onClose,
    items,
    operazioniPreferite,
    setOperazioniPreferiti
  } = props;

  const [preferitiUI, setPreferitiUI] = useState<OperazionePreferita[]>([]);
  const [preferitiUIConDefault, setPreferitiUIConDefault] = useState<OperazionePreferita[]>([]);
  const [elencoFunzionalita, setElencoFunzionalita] = useState<funzionalita[]>([]);
  const [filtroFunzionalita, setFiltroFunzionalita] = useState("");

  useEffect(() => {
    items?.length && setPreferitiUI(items);
  }, [items]);

  useEffect(() => {
    if (preferitiUI.length >= minPreferiti) {
      setPreferitiUIConDefault(preferitiUI);
    } else {
      const _preferitiUI = preferitiUI.concat(defaultQuickOperationItem);
      const _preferitiUISlice = _preferitiUI.slice(0, maxPreferiti);
      setPreferitiUIConDefault(_preferitiUISlice);
    }
  }, [preferitiUI]);

  useEffect(() => {
    const menuSupportati = Object.values(WEBPAGE).filter(w => !!w.menu).map(w => w.menu);
    if (operazioniPreferite?.elencoFunzionalita?.length) {
      const _elencoFunzionalita = operazioniPreferite?.elencoFunzionalita?.filter(f1 => menuSupportati.includes(f1));
      setElencoFunzionalita(_elencoFunzionalita.map(f2 => ({
        id: f2, descrizione: t(f2)
      }))?.sort((a, b) => (a.descrizione <= b.descrizione ? -1 : 1)));
    }
  }, [operazioniPreferite?.elencoFunzionalita, t]);

  const ListaPreferiti = (
    <div className="lista-preferiti">
      {elencoFunzionalita.
        filter(item => !preferitiUI.some((p, i) => p.id === item.id) && (!filtroFunzionalita || item.descrizione.toLowerCase().indexOf(filtroFunzionalita.toLowerCase()) >= 0)).
        map((item, i) => (
          <div key={`lpi-${i}`} className={`lista-preferiti-item ${i % 2 === 1 ? "alternate-row" : ""}`}>
            <div className="lista-preferiti-item-name">
              <BankIcon title={item.id} />
              <span>{item.descrizione}</span>
            </div>
            <div className="lista-preferiti-item-star">
              {preferitiUI.length < maxPreferiti &&
                <BankIcon
                  title={"azioni_aggiungi"}
                  red
                  onClick={() => {
                    setPreferitiUI(preferitiUI.concat([{
                      id: item.id,
                      descrizione: item.descrizione,
                      icon: item.id,
                      path: "",
                      order: preferitiUI.length
                    }]));
                  }}
                  width={32}
                />}
            </div>
          </div>
        ))
      }
    </div>
  );

  return (
    <>
      <Modal show={show} className="modal-preferiti-modal" onHide={onClose}>
        <Modal.Header>
          <Modal.Title>
            <h4 className='responsive-640-image-blocker'>{t("modalPreferiti.titolo")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body  >
          <div className="responsive-640-image-blocker" >
            <div className="modal-preferiti-modal-istruzioni">
              {preferitiUI.length < minPreferiti
                ? t("modalPreferiti.istruzioniMin")
                : preferitiUI.length >= maxPreferiti
                  ? t("modalPreferiti.istruzioniMax")
                  : t("modalPreferiti.istruzioni")
              }
            </div>
            <GridLayout
              className="quick-operations"
              cols={5}
              layout={preferitiUIConDefault.map((item, i) => ({ x: i, y: 0, w: 1, h: 1, i: item.id }))}
              useCSSTransforms={true}
              isDroppable={true}
              isResizable={false}
              isDraggable={preferitiUI.length >= minPreferiti}
              onDragStop={(newLayout) => {
                setPreferitiUI(preferitiUI
                  .map(p => ({ ...p, order: newLayout.find(n => n.i === p.id)?.x || 0 }))
                  .sort((a, b) => (a.order - b.order)))
              }}
              compactType={'horizontal'}
              verticalCompact
              width={590}
              rowHeight={88}
              maxRows={0}
              onDrag={() => { }}
              onDrop={() => { }}
              droppingItem={{
                i: "blank",
                h: 1,
                w: 1,
              }}
            >
              {preferitiUIConDefault.map((item, _) =>
                <div
                  key={item.id}
                  className={`quick-operation ${item.descrizione === "DEFAULT" ? "quick-operation-missing" : ""}`}
                >
                  <BankIcon title="azioni_elimina" height={24} width={24} className="preferito-elimina" onClick={() => { setPreferitiUI(preferitiUI.filter(pref => pref.id !== item.id)) }} />
                  <div className="iconView-item-quickOpr">
                    <BankIcon title={item.icon && ICON_ALIAS[item.icon] ? item.icon : "varie"} />
                  </div>
                  <p className="txtWidgetView-item-quickOpr">{item.descrizione}</p>
                </div>
              )}
            </GridLayout>
            <InputText
              descrizione={t("modalPreferiti.cercaFunzionalita")}
              img={closeIcon}
              value={filtroFunzionalita}
              onChange={setFiltroFunzionalita}
              imgOnClick={() => setFiltroFunzionalita("")}
            />
            {ListaPreferiti}
            <div className="modal-frequenti">
              <div className="modal-frequenti-title">{t("modalPreferiti.titoloFrequenti")}</div>
              <InputBox
                descrizione={t("modalPreferiti.mostraFrequenti")}
                onChange={() => { }}
                disabled
                value={false}
                isToggle={true}
              />
            </div>
          </div>
          {/* //vista in caso di risoluzione 640 */}
          <div className="change-modal-640-responsive">
            <img className="responsive-image" src={responsiveImage} />
            <span className="responsive-text-header">
              <p>{t('modalPreferiti.risoluzioneNonSufficiente')}</p>
            </span>
            <span className="responsive-text-subtitle">
              <p>{t('modalPreferiti.modificaRisoluzione')}</p>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={"justify"}
        >
          <div className="btn-group responsive-640-image-blocker">
            <button
              type="button"
              className="modal-preferiti-modal__close-button left"
              onClick={onClose}
            >
              {t("modalPreferiti.button.annulla").toUpperCase()}
            </button>
            <button
              type="button"
              className={`modal-preferiti-modal__close-button right ${preferitiUI.length < minPreferiti ? "button-disable" : ""}`}
              onClick={() => {
                setOperazioniPreferiti(preferitiUI.map(p => p.id));
                onClose();
              }}
              disabled={preferitiUI.length < minPreferiti}
            >
              {t("modalPreferiti.button.conferma").toUpperCase()}
            </button>
          </div>
          {/* bottone unico visualizzato per mobile view */}
          <button
            type="button"
            className="modal-preferiti-modal__close-button right change-modal-640-responsive"
            onClick={onClose}
          >
            {t("modalPreferiti.button.conferma").toUpperCase()}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  operazioniPreferite: operazioniPreferite(state.operazioni)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getOperazioniPreferiti,
      setOperazioniPreferiti,
    },
    dispatch
  );

export interface OperazionePreferita {
  id: string
  descrizione: string
  icon: string
  path: string
  order: number
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    show: boolean,
    onClose: () => void,
    items: OperazionePreferita[],
  };

export default connect(mapStateToProps, mapDispatchToProps)(ModalPreferitiComponent);
