"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxCompravenditaActionTypes = exports.FormCompravenditaDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormCompravenditaDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'codiceIsin': !(0, helpers_1.exists)(json, 'codiceIsin') ? undefined : json.codiceIsin,
    'mercatoEsterno': !(0, helpers_1.exists)(json, 'mercatoEsterno') ? undefined : json.mercatoEsterno,
    'mercatoOrdine': !(0, helpers_1.exists)(json, 'mercatoOrdine') ? undefined : json.mercatoOrdine,
    'segnoOrdine': !(0, helpers_1.exists)(json, 'segnoOrdine') ? undefined : json.segnoOrdine,
    'dossierDepositoSelezionato': !(0, helpers_1.exists)(json, 'dossierDepositoSelezionato') ? [''] : json.dossierDepositoSelezionato,
    'dossierRapportoSelezionato': !(0, helpers_1.exists)(json, 'dossierRapportoSelezionato') ? (0, api_rest_1.DatiIntestatarioDtoFromJSON)({}) : json.dossierRapportoSelezionato,
    'quantita': !(0, helpers_1.exists)(json, 'quantita') ? undefined : json.quantita,
    'quantitaDirty': !(0, helpers_1.exists)(json, 'quantitaDirty') ? false : json.quantitaDirty,
    'quantitaError': !(0, helpers_1.exists)(json, 'quantitaError') ? 'invalid.required' : json.quantitaError,
    'tipoPrezzo': !(0, helpers_1.exists)(json, 'tipoPrezzo') ? '' : json.tipoPrezzo,
    'prezzoStartStop': !(0, helpers_1.exists)(json, 'prezzoStartStop') ? undefined : json.prezzoStartStop,
    'prezzoStartStopEnabled': !(0, helpers_1.exists)(json, 'prezzoStartStopEnabled') ? true : json.prezzoStartStopEnabled,
    'prezzoStartStopDirty': !(0, helpers_1.exists)(json, 'prezzoStartStopDirty') ? false : json.prezzoStartStopDirty,
    'prezzoStartStopError': !(0, helpers_1.exists)(json, 'prezzoStartStopError') ? 'invalid.required' : json.prezzoStartStopError,
    'prezzoLimite': !(0, helpers_1.exists)(json, 'prezzoLimite') ? undefined : json.prezzoLimite,
    'prezzoLimiteEnabled': !(0, helpers_1.exists)(json, 'prezzoLimiteEnabled') ? true : json.prezzoLimiteEnabled,
    'prezzoLimiteDirty': !(0, helpers_1.exists)(json, 'prezzoLimiteDirty') ? false : json.prezzoLimiteDirty,
    'prezzoLimiteError': !(0, helpers_1.exists)(json, 'prezzoLimiteError') ? 'invalid.required' : json.prezzoLimiteError,
    'esecuzione': !(0, helpers_1.exists)(json, 'esecuzione') ? '' : json.esecuzione,
    'validita': !(0, helpers_1.exists)(json, 'validita') ? '' : json.validita,
    'validitaDirty': !(0, helpers_1.exists)(json, 'validitaDirty') ? false : json.validitaDirty,
    'validitaError': !(0, helpers_1.exists)(json, 'validitaError') ? 'invalid.required' : json.validitaError,
    'data': !(0, helpers_1.exists)(json, 'data') ? undefined : json.data,
    'dataEnabled': !(0, helpers_1.exists)(json, 'dataEnabled') ? false : json.dataEnabled,
    'dataDirty': !(0, helpers_1.exists)(json, 'dataDirty') ? false : json.dataDirty,
    'dataError': !(0, helpers_1.exists)(json, 'dataError') ? '' : json.dataError,
    'tipoQuantita': !(0, helpers_1.exists)(json, 'tipoQuantita') ? undefined : json.tipoQuantita,
    'accordionParametriAggiuntiviOpened': !(0, helpers_1.exists)(json, 'accordionParametriAggiuntiviOpened') ? false : json.accordionParametriAggiuntiviOpened,
    'presaVisioneSchedaTitoloEnabled': !(0, helpers_1.exists)(json, 'presaVisioneSchedaTitoloEnabled') ? false : json.presaVisioneSchedaTitoloEnabled,
    'presaVisioneSchedaTitolo': !(0, helpers_1.exists)(json, 'presaVisioneSchedaTitolo') ? false : json.presaVisioneSchedaTitolo,
    'presaVisioneKiidEnabled': !(0, helpers_1.exists)(json, 'presaVisioneKiidEnabled') ? false : json.presaVisioneKiidEnabled,
    'presaVisioneKiid': !(0, helpers_1.exists)(json, 'presaVisioneKiid') ? false : json.presaVisioneKiid,
    'presaVisioneCosti': !(0, helpers_1.exists)(json, 'accordionParametriAggiuntiviOpened') ? false : json.presaVisioneKiid,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'accordionBookOpened': !(0, helpers_1.exists)(json, 'accordionBookOpened') ? false : json.accordionBookOpened
  };
}
exports.FormCompravenditaDtoFromJSONTyped = FormCompravenditaDtoFromJSONTyped;
var EReduxCompravenditaActionTypes;
(function (EReduxCompravenditaActionTypes) {
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_PARAMETRI_COMPRAVENDITA_START"] = "COMPRAVENDITA_GET_PARAMETRI_COMPRAVENDITA_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_PARAMETRI_COMPRAVENDITA_SUCCESS"] = "COMPRAVENDITA_GET_PARAMETRI_COMPRAVENDITA_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_PARAMETRI_AVANZATI_COMPRAVENDITA_START"] = "COMPRAVENDITA_GET_PARAMETRI_AVANZATI_COMPRAVENDITA_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_PARAMETRI_AVANZATI_COMPRAVENDITA_SUCCESS"] = "COMPRAVENDITA_GET_PARAMETRI_AVANZATI_COMPRAVENDITA_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_QUOTAZIONE_DETTAGLIO_TITOLO_START"] = "COMPRAVENDITA_GET_QUOTAZIONE_DETTAGLIO_TITOLO_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_QUOTAZIONE_DETTAGLIO_TITOLO_SUCCESS"] = "COMPRAVENDITA_GET_QUOTAZIONE_DETTAGLIO_TITOLO_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_BOOK_TITOLO_START"] = "COMPRAVENDITA_GET_BOOK_TITOLO_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_BOOK_TITOLO_SUCCESS"] = "COMPRAVENDITA_GET_BOOK_TITOLO_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_CONTROLLO_COMPRAVENDITA_TITOLO_START"] = "COMPRAVENDITA_CONTROLLO_COMPRAVENDITA_TITOLO_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_CONTROLLO_COMPRAVENDITA_TITOLO_SUCCESS"] = "COMPRAVENDITA_CONTROLLO_COMPRAVENDITA_TITOLO_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_INFO_DISPOSITIVA_COMPRAVENDITA_TITOLO_START"] = "COMPRAVENDITA_GET_INFO_DISPOSITIVA_COMPRAVENDITA_TITOLO_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_GET_INFO_DISPOSITIVA_COMPRAVENDITA_TITOLO_SUCCESS"] = "COMPRAVENDITA_GET_INFO_DISPOSITIVA_COMPRAVENDITA_TITOLO_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_AUTORIZZA_COMPRAVENDITA_TITOLO_START"] = "COMPRAVENDITA_AUTORIZZA_COMPRAVENDITA_TITOLO_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_AUTORIZZA_COMPRAVENDITA_TITOLO_SUCCESS"] = "COMPRAVENDITA_AUTORIZZA_COMPRAVENDITA_TITOLO_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_STAMPA_PDF_KIID_START"] = "COMPRAVENDITA_STAMPA_PDF_KIID_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_STAMPA_PDF_KIID_SUCCESS"] = "COMPRAVENDITA_STAMPA_PDF_KIID_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_STAMPA_PDF_SCHEDA_TITOLO_START"] = "COMPRAVENDITA_STAMPA_PDF_SCHEDA_TITOLO_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_STAMPA_PDF_SCHEDA_TITOLO_SUCCESS"] = "COMPRAVENDITA_STAMPA_PDF_SCHEDA_TITOLO_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_STAMPA_PDF_GLOSSARIO_START"] = "COMPRAVENDITA_STAMPA_PDF_GLOSSARIO_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_STAMPA_PDF_GLOSSARIO_SUCCESS"] = "COMPRAVENDITA_STAMPA_PDF_GLOSSARIO_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_STEP"] = "COMPRAVENDITA_SET_STEP";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_DOSSIER_RAPPORTO_SELEZIONATO"] = "COMPRAVENDITA_SET_DOSSIER_RAPPORTO_SELEZIONATO";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_DOSSIER_DEPOSITO_SELEZIONATO"] = "COMPRAVENDITA_SET_DOSSIER_DEPOSITO_SELEZIONATO";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_CODICE_ISIN"] = "COMPRAVENDITA_SET_CODICE_ISIN";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_MERCATO_ESTERNO"] = "COMPRAVENDITA_SET_MERCATO_ESTERNO";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_MERCATO_ORDINE"] = "COMPRAVENDITA_SET_MERCATO_ORDINE";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_SEGNO_ORDINE"] = "COMPRAVENDITA_SET_SEGNO_ORDINE";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_QUANTITA"] = "COMPRAVENDITA_SET_QUANTITA";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_TIPO_PREZZO"] = "COMPRAVENDITA_SET_TIPO_PREZZO";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_PREZZO_START_STOP"] = "COMPRAVENDITA_SET_PREZZO_START_STOP";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_PREZZO_LIMITE"] = "COMPRAVENDITA_SET_PREZZO_LIMITE";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_ESECUZIONE"] = "COMPRAVENDITA_SET_ESECUZIONE";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_VALIDITA"] = "COMPRAVENDITA_SET_VALIDITA";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_DATA"] = "COMPRAVENDITA_SET_DATA";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_TIPO_QUANTITA"] = "COMPRAVENDITA_SET_TIPO_QUANTITA";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_RESET_RIEPILOGO"] = "COMPRAVENDITA_RESET_RIEPILOGO";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_RESET"] = "COMPRAVENDITA_RESET";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_ACCORDION_PARAMETRI_AGGIUNTIVI_OPENED"] = "COMPRAVENDITA_SET_ACCORDION_PARAMETRI_AGGIUNTIVI_OPENED";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_FORM_SUBMITTED"] = "COMPRAVENDITA_SET_FORM_SUBMITTED";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO_ENABLED"] = "COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO_ENABLED";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO"] = "COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_PRESA_VISIONE_KIID_ENABLED"] = "COMPRAVENDITA_SET_PRESA_VISIONE_KIID_ENABLED";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_PRESA_VISIONE_KIID"] = "COMPRAVENDITA_SET_PRESA_VISIONE_KIID";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_PRESA_VISIONE_COSTI"] = "COMPRAVENDITA_SET_PRESA_VISIONE_COSTI";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_SET_ACCORDION_BOOK_OPENED"] = "COMPRAVENDITA_SET_ACCORDION_BOOK_OPENED";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_DOSSIER_TITOLI_GET_RAPPORTI_START"] = "COMPRAVENDITA_DOSSIER_TITOLI_GET_RAPPORTI_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS"] = "COMPRAVENDITA_DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_COSTUMER_DOSSIER_START"] = "COMPRAVENDITA_COSTUMER_DOSSIER_START";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_COSTUMER_DOSSIER_SUCCESS"] = "COMPRAVENDITA_COSTUMER_DOSSIER_SUCCESS";
  EReduxCompravenditaActionTypes["COMPRAVENDITA_RESET_DOSSIER"] = "COMPRAVENDITA_RESET_DOSSIER";
})(EReduxCompravenditaActionTypes = exports.EReduxCompravenditaActionTypes || (exports.EReduxCompravenditaActionTypes = {}));