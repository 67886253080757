"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patrimonioReducer = void 0;
const __1 = require("..");
const initialState = {
  riepilogo: undefined
};
const patrimonioReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.PATRIMONIO_GET_RIEPILOGO_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.PATRIMONIO_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.GENERAL_RESET_CACHE_POST_DISPO:
      // action invocata dopo una dispositiva di tipo resetWidgetsDispoFunctions
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS:
      // action invocata tramite pull to refresh
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.patrimonioReducer = patrimonioReducer;
exports.default = exports.patrimonioReducer;