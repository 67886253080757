"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriRicaricaCarteDtoToJSON = exports.ParametriRicaricaCarteDtoFromJSONTyped = exports.ParametriRicaricaCarteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriRicaricaCarteDtoFromJSON(json) {
  return ParametriRicaricaCarteDtoFromJSONTyped(json, false);
}
exports.ParametriRicaricaCarteDtoFromJSON = ParametriRicaricaCarteDtoFromJSON;
function ParametriRicaricaCarteDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.SaldoRapportoDtoFromJSON),
    'carte': !runtime_1.exists(json, 'carte') ? undefined : json['carte'].map(_1.SaldoCartaDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri']
  };
}
exports.ParametriRicaricaCarteDtoFromJSONTyped = ParametriRicaricaCarteDtoFromJSONTyped;
function ParametriRicaricaCarteDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.SaldoRapportoDtoToJSON),
    'carte': value.carte === undefined ? undefined : value.carte.map(_1.SaldoCartaDtoToJSON),
    'parametri': value.parametri
  };
}
exports.ParametriRicaricaCarteDtoToJSON = ParametriRicaricaCarteDtoToJSON;