"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WatchlistStaticaItemDtoToJSON = exports.WatchlistStaticaItemDtoFromJSONTyped = exports.WatchlistStaticaItemDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function WatchlistStaticaItemDtoFromJSON(json) {
  return WatchlistStaticaItemDtoFromJSONTyped(json, false);
}
exports.WatchlistStaticaItemDtoFromJSON = WatchlistStaticaItemDtoFromJSON;
function WatchlistStaticaItemDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice']
  };
}
exports.WatchlistStaticaItemDtoFromJSONTyped = WatchlistStaticaItemDtoFromJSONTyped;
function WatchlistStaticaItemDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mercato': value.mercato,
    'codice': value.codice
  };
}
exports.WatchlistStaticaItemDtoToJSON = WatchlistStaticaItemDtoToJSON;