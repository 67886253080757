"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.riferimentiBloccoCarte = void 0;
const reselect_1 = require("reselect");
const bloccoCarteReducer = state => state;
exports.riferimentiBloccoCarte = (0, reselect_1.createSelector)(bloccoCarteReducer, state => {
  return state.riferimenti;
});