"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RigaSezioneTributiLocaliDtoSaldoEnum = exports.RigaSezioneTributiLocaliDtoAccontoEnum = exports.RigaSezioneTributiLocaliDtoImmVariatiEnum = exports.RigaSezioneTributiLocaliDtoRavvedimentoEnum = exports.RigaSezioneTributiLocaliDtoToJSON = exports.RigaSezioneTributiLocaliDtoFromJSONTyped = exports.RigaSezioneTributiLocaliDtoFromJSON = void 0;
function RigaSezioneTributiLocaliDtoFromJSON(json) {
  return RigaSezioneTributiLocaliDtoFromJSONTyped(json, false);
}
exports.RigaSezioneTributiLocaliDtoFromJSON = RigaSezioneTributiLocaliDtoFromJSON;
function RigaSezioneTributiLocaliDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceEnte': json['codiceEnte'],
    'ravvedimento': json['ravvedimento'],
    'immVariati': json['immVariati'],
    'acconto': json['acconto'],
    'saldo': json['saldo'],
    'numeroImmobili': json['numeroImmobili'],
    'codiceTributo': json['codiceTributo'],
    'infoTributo': json['infoTributo'],
    'annoRiferimento': json['annoRiferimento'],
    'importoDebito': json['importoDebito'],
    'importoCredito': json['importoCredito']
  };
}
exports.RigaSezioneTributiLocaliDtoFromJSONTyped = RigaSezioneTributiLocaliDtoFromJSONTyped;
function RigaSezioneTributiLocaliDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceEnte': value.codiceEnte,
    'ravvedimento': value.ravvedimento,
    'immVariati': value.immVariati,
    'acconto': value.acconto,
    'saldo': value.saldo,
    'numeroImmobili': value.numeroImmobili,
    'codiceTributo': value.codiceTributo,
    'infoTributo': value.infoTributo,
    'annoRiferimento': value.annoRiferimento,
    'importoDebito': value.importoDebito,
    'importoCredito': value.importoCredito
  };
}
exports.RigaSezioneTributiLocaliDtoToJSON = RigaSezioneTributiLocaliDtoToJSON;
/**
* @export
* @enum {string}
*/
var RigaSezioneTributiLocaliDtoRavvedimentoEnum;
(function (RigaSezioneTributiLocaliDtoRavvedimentoEnum) {
  RigaSezioneTributiLocaliDtoRavvedimentoEnum["N"] = "N";
  RigaSezioneTributiLocaliDtoRavvedimentoEnum["S"] = "S";
})(RigaSezioneTributiLocaliDtoRavvedimentoEnum = exports.RigaSezioneTributiLocaliDtoRavvedimentoEnum || (exports.RigaSezioneTributiLocaliDtoRavvedimentoEnum = {}));
/**
* @export
* @enum {string}
*/
var RigaSezioneTributiLocaliDtoImmVariatiEnum;
(function (RigaSezioneTributiLocaliDtoImmVariatiEnum) {
  RigaSezioneTributiLocaliDtoImmVariatiEnum["N"] = "N";
  RigaSezioneTributiLocaliDtoImmVariatiEnum["S"] = "S";
})(RigaSezioneTributiLocaliDtoImmVariatiEnum = exports.RigaSezioneTributiLocaliDtoImmVariatiEnum || (exports.RigaSezioneTributiLocaliDtoImmVariatiEnum = {}));
/**
* @export
* @enum {string}
*/
var RigaSezioneTributiLocaliDtoAccontoEnum;
(function (RigaSezioneTributiLocaliDtoAccontoEnum) {
  RigaSezioneTributiLocaliDtoAccontoEnum["N"] = "N";
  RigaSezioneTributiLocaliDtoAccontoEnum["S"] = "S";
})(RigaSezioneTributiLocaliDtoAccontoEnum = exports.RigaSezioneTributiLocaliDtoAccontoEnum || (exports.RigaSezioneTributiLocaliDtoAccontoEnum = {}));
/**
* @export
* @enum {string}
*/
var RigaSezioneTributiLocaliDtoSaldoEnum;
(function (RigaSezioneTributiLocaliDtoSaldoEnum) {
  RigaSezioneTributiLocaliDtoSaldoEnum["N"] = "N";
  RigaSezioneTributiLocaliDtoSaldoEnum["S"] = "S";
})(RigaSezioneTributiLocaliDtoSaldoEnum = exports.RigaSezioneTributiLocaliDtoSaldoEnum || (exports.RigaSezioneTributiLocaliDtoSaldoEnum = {}));