"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiGeneraliDtoToJSON = exports.DatiGeneraliDtoFromJSONTyped = exports.DatiGeneraliDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiGeneraliDtoFromJSON(json) {
  return DatiGeneraliDtoFromJSONTyped(json, false);
}
exports.DatiGeneraliDtoFromJSON = DatiGeneraliDtoFromJSON;
function DatiGeneraliDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'intestazioneRapporto': !runtime_1.exists(json, 'intestazioneRapporto') ? undefined : json['intestazioneRapporto'],
    'totaleAssegniManoCliente': !runtime_1.exists(json, 'totaleAssegniManoCliente') ? undefined : json['totaleAssegniManoCliente'],
    'totaleAssegniConsegnati': !runtime_1.exists(json, 'totaleAssegniConsegnati') ? undefined : json['totaleAssegniConsegnati'],
    'totaleAssegniConsegnatiGratis': !runtime_1.exists(json, 'totaleAssegniConsegnatiGratis') ? undefined : json['totaleAssegniConsegnatiGratis'],
    'massimoAssegniGratis': !runtime_1.exists(json, 'massimoAssegniGratis') ? undefined : json['massimoAssegniGratis'],
    'numeroTotaleCarnet': !runtime_1.exists(json, 'numeroTotaleCarnet') ? undefined : json['numeroTotaleCarnet']
  };
}
exports.DatiGeneraliDtoFromJSONTyped = DatiGeneraliDtoFromJSONTyped;
function DatiGeneraliDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'intestazioneRapporto': value.intestazioneRapporto,
    'totaleAssegniManoCliente': value.totaleAssegniManoCliente,
    'totaleAssegniConsegnati': value.totaleAssegniConsegnati,
    'totaleAssegniConsegnatiGratis': value.totaleAssegniConsegnatiGratis,
    'massimoAssegniGratis': value.massimoAssegniGratis,
    'numeroTotaleCarnet': value.numeroTotaleCarnet
  };
}
exports.DatiGeneraliDtoToJSON = DatiGeneraliDtoToJSON;