import React from "react";
import img1Click from "../../assets/icons/profile/gestione_rapporti/1click_white.svg";


const ItemOneclick: React.FC<Props> = (props: Props) => {
  return (
    <div className="item-one-click-button oneClickView-item-oneClick" onClick={props.click}>
      <p className="txtOneClick-item-oneClick">{props.descrizione}</p>
      <div className="iconView-item-oneClick">
        <img
          src={img1Click}
          alt="logo"
          style={{ height: 44, fill: "#000" }}
          color={"#fff"}
        />
      </div>
    </div>
  );
};

interface Props {
  descrizione: string;
  click: () => void
}
export default ItemOneclick
