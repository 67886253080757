"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mifidCustomerId = exports.mifidCurrentQuestions = exports.mifidCurrentSection = exports.mifidFormAnswers = exports.mifidRiepilogo = exports.mifidRisposte = exports.mifidRapporti = void 0;
const reselect_1 = require("reselect");
const MIFIDReducer = state => state;
exports.mifidRapporti = (0, reselect_1.createSelector)(MIFIDReducer, state => {
  return state.rapporti;
});
exports.mifidRisposte = (0, reselect_1.createSelector)(MIFIDReducer, state => {
  return state.risposte;
});
exports.mifidRiepilogo = (0, reselect_1.createSelector)(MIFIDReducer, state => {
  return state.riepilogo;
});
exports.mifidFormAnswers = (0, reselect_1.createSelector)(MIFIDReducer, state => {
  return state.formAnswers;
});
exports.mifidCurrentSection = (0, reselect_1.createSelector)(MIFIDReducer, state => {
  var _a;
  const sections = (_a = state.riepilogo) === null || _a === void 0 ? void 0 : _a.sections;
  const sectionIndex = (sections === null || sections === void 0 ? void 0 : sections.length) ? sections.length - 1 : undefined;
  const section = sections && sectionIndex != undefined ? sections[sectionIndex] : undefined;
  return {
    index: sectionIndex,
    section: section
  };
});
exports.mifidCurrentQuestions = (0, reselect_1.createSelector)(MIFIDReducer, state => {
  var _a;
  const currentSection = (0, exports.mifidCurrentSection)(state);
  return (_a = currentSection.section) === null || _a === void 0 ? void 0 : _a.questions;
});
exports.mifidCustomerId = (0, reselect_1.createSelector)(MIFIDReducer, state => {
  var _a, _b, _c;
  return ((_c = (_b = (_a = state.rapporti) === null || _a === void 0 ? void 0 : _a.find(r => r.ruolo == 'INTESTATARIO')) === null || _b === void 0 ? void 0 : _b.ndg) === null || _c === void 0 ? void 0 : _c.toString()) || '';
});