"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatusElementoDtoToJSON = exports.StatusElementoDtoFromJSONTyped = exports.StatusElementoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function StatusElementoDtoFromJSON(json) {
  return StatusElementoDtoFromJSONTyped(json, false);
}
exports.StatusElementoDtoFromJSON = StatusElementoDtoFromJSON;
function StatusElementoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'saeAlternativo': !runtime_1.exists(json, 'saeAlternativo') ? undefined : json['saeAlternativo'],
    'raeAlternativo': !runtime_1.exists(json, 'raeAlternativo') ? undefined : json['raeAlternativo'],
    'indicativoMarketing': !runtime_1.exists(json, 'indicativoMarketing') ? undefined : json['indicativoMarketing']
  };
}
exports.StatusElementoDtoFromJSONTyped = StatusElementoDtoFromJSONTyped;
function StatusElementoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'saeAlternativo': value.saeAlternativo,
    'raeAlternativo': value.raeAlternativo,
    'indicativoMarketing': value.indicativoMarketing
  };
}
exports.StatusElementoDtoToJSON = StatusElementoDtoToJSON;