"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizionePctDtoStatoEnum = exports.EsitoAutorizzazioneDisposizionePctDtoToJSON = exports.EsitoAutorizzazioneDisposizionePctDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizionePctDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizionePctDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizionePctDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizionePctDtoFromJSON = EsitoAutorizzazioneDisposizionePctDtoFromJSON;
function EsitoAutorizzazioneDisposizionePctDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.PctDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizionePctDtoFromJSONTyped = EsitoAutorizzazioneDisposizionePctDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizionePctDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.PctDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizionePctDtoToJSON = EsitoAutorizzazioneDisposizionePctDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizionePctDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizionePctDtoStatoEnum) {
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizionePctDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizionePctDtoStatoEnum = exports.EsitoAutorizzazioneDisposizionePctDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizionePctDtoStatoEnum = {}));