"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxPCTActionTypes = exports.FormPCTDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function FormPCTDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapportoSelezionato': !(0, api_rest_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'step': !(0, api_rest_1.exists)(json, 'step') ? 0 : json.step,
    'importo': !(0, api_rest_1.exists)(json, 'importo') ? 0 : json.importo,
    'importoDirty': !(0, api_rest_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
    'importoError': !(0, api_rest_1.exists)(json, 'importoError') ? '' : json.importoError,
    'listinoSelezionato': !(0, api_rest_1.exists)(json, 'listinoSelezionato') ? undefined : json.listinoSelezionato,
    'presaVisioneScheda': !(0, api_rest_1.exists)(json, 'presaVisioneScheda') ? false : json.presaVisioneScheda,
    'presaVisioneCosti': !(0, api_rest_1.exists)(json, 'presaVisioneCosti') ? false : json.presaVisioneCosti,
    'checkboxEnabled': !(0, api_rest_1.exists)(json, 'checkboxEnabled') ? false : json.checkboxEnabled,
    'formSubmitted': !(0, api_rest_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'stepImporto': !(0, api_rest_1.exists)(json, 'stepImporto') ? 0 : json.stepImporto
  };
}
exports.FormPCTDtoFromJSONTyped = FormPCTDtoFromJSONTyped;
var EReduxPCTActionTypes;
(function (EReduxPCTActionTypes) {
  EReduxPCTActionTypes["PCT_SET_STEP"] = "PCT_SET_STEP";
  EReduxPCTActionTypes["PCT_SET_IMPORTO"] = "PCT_SET_IMPORTO";
  EReduxPCTActionTypes["PCT_RESET_PCT"] = "PCT_RESET_PCT";
  EReduxPCTActionTypes["PCT_GET_PARAMETRI_PCT_START"] = "PCT_GET_PARAMETRI_PCT_START";
  EReduxPCTActionTypes["PCT_GET_PARAMETRI_PCT_SUCCESS"] = "PCT_GET_PARAMETRI_PCT_SUCCESS";
  EReduxPCTActionTypes["PCT_SET_LISTINO_SELEZIONATO"] = "PCT_SET_LISTINO_SELEZIONATO";
  EReduxPCTActionTypes["PCT_SET_RAPPORTO_SELEZIONATO"] = "PCT_SET_RAPPORTO_SELEZIONATO";
  EReduxPCTActionTypes["PCT_SET_PRESA_VISIONE_SCHEDA"] = "PCT_SET_PRESA_VISIONE_SCHEDA";
  EReduxPCTActionTypes["PCT_SET_PRESA_VISIONE_COSTI"] = "PCT_SET_PRESA_VISIONE_COSTI";
  EReduxPCTActionTypes["PCT_SET_CHECKBOX_ENABLED"] = "PCT_SET_CHECKBOX_ENABLED";
  EReduxPCTActionTypes["PCT_SET_FORM_SUBMITTED"] = "PCT_SET_FORM_SUBMITTED";
  EReduxPCTActionTypes["PCT_GET_LISTINI_PCT_START"] = "PCT_GET_LISTINI_PCT_START";
  EReduxPCTActionTypes["PCT_GET_LISTINI_PCT_SUCCESS"] = "PCT_GET_LISTINI_PCT_SUCCESS";
  EReduxPCTActionTypes["PCT_CONTROLLO_ACQUISTO_PCT_START"] = "PCT_CONTROLLO_ACQUISTO_PCT_START";
  EReduxPCTActionTypes["PCT_CONTROLLO_ACQUISTO_PCT_SUCCESS"] = "PCT_CONTROLLO_ACQUISTO_PCT_SUCCESS";
  EReduxPCTActionTypes["PCT_GET_INFO_DISPOSITIVA_ACQUISTO_PCT_START"] = "PCT_GET_INFO_DISPOSITIVA_ACQUISTO_PCT_START";
  EReduxPCTActionTypes["PCT_GET_INFO_DISPOSITIVA_ACQUISTO_PCT_SUCCESS"] = "PCT_GET_INFO_DISPOSITIVA_ACQUISTO_PCT_SUCCESS";
  EReduxPCTActionTypes["PCT_AUTORIZZA_ACQUISTO_PCT_START"] = "PCT_AUTORIZZA_ACQUISTO_PCT_START";
  EReduxPCTActionTypes["PCT_AUTORIZZA_ACQUISTO_PCT_SUCCESS"] = "PCT_AUTORIZZA_ACQUISTO_PCT_SUCCESS";
  EReduxPCTActionTypes["PCT_RESET_RIEPILOGO"] = "PCT_RESET_RIEPILOGO";
  EReduxPCTActionTypes["PCT_RESET_ESITO"] = "PCT_RESET_ESITO";
  EReduxPCTActionTypes["PCT_ESPORTA_PDF_ACQUISTO_PCT_START"] = "PCT_ESPORTA_PDF_ACQUISTO_PCT_START";
  EReduxPCTActionTypes["PCT_ESPORTA_PDF_ACQUISTO_PCT_SUCCESS"] = "PCT_ESPORTA_PDF_ACQUISTO_PCT_SUCCESS";
  EReduxPCTActionTypes["PCT_SET_STEP_IMPORTO"] = "PCT_SET_STEP_IMPORTO";
  EReduxPCTActionTypes["PCT_RESET_FORM"] = "PCT_RESET_FORM";
})(EReduxPCTActionTypes = exports.EReduxPCTActionTypes || (exports.EReduxPCTActionTypes = {}));