"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppuntamentoDtoToJSON = exports.AppuntamentoDtoFromJSONTyped = exports.AppuntamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AppuntamentoDtoFromJSON(json) {
  return AppuntamentoDtoFromJSONTyped(json, false);
}
exports.AppuntamentoDtoFromJSON = AppuntamentoDtoFromJSON;
function AppuntamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'idAppuntamento': !runtime_1.exists(json, 'idAppuntamento') ? undefined : json['idAppuntamento'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'data': !runtime_1.exists(json, 'data') ? undefined : new Date(json['data']),
    'modificabile': !runtime_1.exists(json, 'modificabile') ? undefined : json['modificabile'],
    'idOperazioneOneClick': !runtime_1.exists(json, 'idOperazioneOneClick') ? undefined : json['idOperazioneOneClick']
  };
}
exports.AppuntamentoDtoFromJSONTyped = AppuntamentoDtoFromJSONTyped;
function AppuntamentoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'idAppuntamento': value.idAppuntamento,
    'titolo': value.titolo,
    'data': value.data === undefined ? undefined : value.data.toISOString(),
    'modificabile': value.modificabile,
    'idOperazioneOneClick': value.idOperazioneOneClick
  };
}
exports.AppuntamentoDtoToJSON = AppuntamentoDtoToJSON;