"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OauthAuthorizeInToJSON = exports.OauthAuthorizeInFromJSONTyped = exports.OauthAuthorizeInFromJSON = void 0;
const runtime_1 = require("../runtime");
function OauthAuthorizeInFromJSON(json) {
  return OauthAuthorizeInFromJSONTyped(json, false);
}
exports.OauthAuthorizeInFromJSON = OauthAuthorizeInFromJSON;
function OauthAuthorizeInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'oauthState': !runtime_1.exists(json, 'oauthState') ? undefined : json['oauthState'],
    'lang': !runtime_1.exists(json, 'lang') ? undefined : json['lang']
  };
}
exports.OauthAuthorizeInFromJSONTyped = OauthAuthorizeInFromJSONTyped;
function OauthAuthorizeInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'oauthState': value.oauthState,
    'lang': value.lang
  };
}
exports.OauthAuthorizeInToJSON = OauthAuthorizeInToJSON;