"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperazioniOneClickDtoToJSON = exports.OperazioniOneClickDtoFromJSONTyped = exports.OperazioniOneClickDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OperazioniOneClickDtoFromJSON(json) {
  return OperazioniOneClickDtoFromJSONTyped(json, false);
}
exports.OperazioniOneClickDtoFromJSON = OperazioniOneClickDtoFromJSON;
function OperazioniOneClickDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'operazioniOneClick': !runtime_1.exists(json, 'operazioniOneClick') ? undefined : json['operazioniOneClick'].map(_1.OperazioneOneClickDtoFromJSON)
  };
}
exports.OperazioniOneClickDtoFromJSONTyped = OperazioniOneClickDtoFromJSONTyped;
function OperazioniOneClickDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'operazioniOneClick': value.operazioniOneClick === undefined ? undefined : value.operazioniOneClick.map(_1.OperazioneOneClickDtoToJSON)
  };
}
exports.OperazioniOneClickDtoToJSON = OperazioniOneClickDtoToJSON;