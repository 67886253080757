"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CredenzialiDtoToJSON = exports.CredenzialiDtoFromJSONTyped = exports.CredenzialiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CredenzialiDtoFromJSON(json) {
  return CredenzialiDtoFromJSONTyped(json, false);
}
exports.CredenzialiDtoFromJSON = CredenzialiDtoFromJSON;
function CredenzialiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'credenzialiModificabili': !runtime_1.exists(json, 'credenzialiModificabili') ? undefined : json['credenzialiModificabili'],
    'domandeSegrete': !runtime_1.exists(json, 'domandeSegrete') ? undefined : json['domandeSegrete'],
    'modalitaSblocco': !runtime_1.exists(json, 'modalitaSblocco') ? undefined : json['modalitaSblocco'],
    'pinDefault': !runtime_1.exists(json, 'pinDefault') ? undefined : json['pinDefault'],
    'secretAnswerAskedQuestions': !runtime_1.exists(json, 'secretAnswerAskedQuestions') ? undefined : json['secretAnswerAskedQuestions'],
    'otpPinMin': !runtime_1.exists(json, 'otpPinMin') ? undefined : json['otpPinMin'],
    'otpPinMax': !runtime_1.exists(json, 'otpPinMax') ? undefined : json['otpPinMax'],
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri']
  };
}
exports.CredenzialiDtoFromJSONTyped = CredenzialiDtoFromJSONTyped;
function CredenzialiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'credenzialiModificabili': value.credenzialiModificabili,
    'domandeSegrete': value.domandeSegrete,
    'modalitaSblocco': value.modalitaSblocco,
    'pinDefault': value.pinDefault,
    'secretAnswerAskedQuestions': value.secretAnswerAskedQuestions,
    'otpPinMin': value.otpPinMin,
    'otpPinMax': value.otpPinMax,
    'parametri': value.parametri
  };
}
exports.CredenzialiDtoToJSON = CredenzialiDtoToJSON;