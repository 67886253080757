"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.finanzaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const finanza_types_1 = require("./finanza.types");
const initialState = {
  parametriTrading: undefined,
  parametri: (0, api_rest_1.ParametriFinanzaDtoFromJSON)({}),
  parametriAvanzati: (0, api_rest_1.OrdineParametriDtoFromJSON)({}),
  titoliInScadenza: (0, api_rest_1.TitoliScadenzaDtoFromJSON)({}),
  datiGrafico: {
    tipoGrafico: finanza_types_1.TipoGraficoTitoli.Settimana1
  },
  form: {
    causale: undefined
  }
};
const finanzaReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a;
  switch (action.type) {
    case __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_TRADING_SUCCESS:
      return {
        ...initialState,
        parametriTrading: action.payload
      };
    case __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_SUCCESS:
      return {
        ...initialState,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_AVANZATI_SUCCESS:
      return {
        ...initialState,
        parametriAvanzati: action.payload
      };
    case __1.EReduxActionTypes.FINANZA_GET_TITOLI_IN_SCADENZA_SUCCESS:
      return {
        ...state,
        titoliInScadenza: !action.offset ? action.payload : {
          ...state.titoliInScadenza,
          titoli: (((_a = state.titoliInScadenza) === null || _a === void 0 ? void 0 : _a.titoli) || []).concat(action.payload.titoli || [])
        }
      };
    case __1.EReduxActionTypes.FINANZA_GET_MFURL_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          mfUrl: action.payload
        }
      };
    case __1.EReduxActionTypes.FINANZA_GET_DATI_INTRADAY_TITOLO_SUCCESS:
      return {
        ...state,
        datiGrafico: {
          ...state.datiGrafico,
          datiIntraday: action.payload,
          aggiornamento: action.aggiornamento
        }
      };
    case __1.EReduxActionTypes.FINANZA_GET_DATI_STORICI_TITOLO_SUCCESS:
      return {
        ...state,
        datiGrafico: {
          ...state.datiGrafico,
          aggiornamento: action.aggiornamento,
          datiSettimana: state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Settimana1 ? action.payload : state.datiGrafico.datiSettimana,
          datiMese: state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Mese1 ? action.payload : state.datiGrafico.datiMese,
          datiAnno: state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Anno1 ? action.payload : state.datiGrafico.datiAnno
        }
      };
    case __1.EReduxActionTypes.FINANZA_GET_DATI_INTRADAY_TITOLO_ERROR:
    case __1.EReduxActionTypes.FINANZA_GET_DATI_STORICI_TITOLO_ERROR:
      return {
        ...state,
        datiGrafico: {
          ...state.datiGrafico,
          erroreMF: true
        }
      };
    case __1.EReduxActionTypes.FINANZA_SET_TITOLO_SELEZIONATO:
      return {
        ...state,
        datiGrafico: {
          ...initialState.datiGrafico,
          titoloSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.FINANZA_SET_TIPO_GRAFICO_TITOLI:
      return {
        ...state,
        datiGrafico: {
          ...state.datiGrafico,
          tipoGrafico: action.payload
        }
      };
    case __1.EReduxActionTypes.FINANZA_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.FINANZA_SET_DATA_FINALE:
      return {
        ...state,
        form: {
          ...state.form,
          dataFinale: action.payload,
          dataFinaleError: (0, helpers_1.validationFieldDate)(action.payload, [], false),
          periodo: initialState.form.periodo
        }
      };
    case __1.EReduxActionTypes.FINANZA_SET_ACCORDION_FILTRI_OPENED:
      return {
        ...state,
        form: {
          ...state.form,
          accordionFiltriOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.FINANZA_SET_PERIODO:
      return {
        ...state,
        form: {
          ...state.form,
          periodo: action.payload
        }
      };
    case __1.EReduxActionTypes.FINANZA_SET_CAUSALE:
      return {
        ...state,
        form: {
          ...state.form,
          causale: action.payload
        }
      };
    case __1.EReduxActionTypes.FINANZA_RESET_CAUSALE:
      return {
        ...state,
        form: {
          ...state.form,
          causale: initialState.form.causale
        }
      };
    case __1.EReduxActionTypes.FINANZA_RESET_ALL:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.FINANZA_SET_DATA_MASSIMA:
      return {
        ...state,
        form: {
          ...state.form,
          dataMax: action.payload
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.finanzaReducer = finanzaReducer;
exports.default = exports.finanzaReducer;