"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoUtenteOutDtoToJSON = exports.InfoUtenteOutDtoFromJSONTyped = exports.InfoUtenteOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function InfoUtenteOutDtoFromJSON(json) {
  return InfoUtenteOutDtoFromJSONTyped(json, false);
}
exports.InfoUtenteOutDtoFromJSON = InfoUtenteOutDtoFromJSON;
function InfoUtenteOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'anagrafica': !runtime_1.exists(json, 'anagrafica') ? undefined : _1.AnagraficaCedacriDtoFromJSON(json['anagrafica'])
  };
}
exports.InfoUtenteOutDtoFromJSONTyped = InfoUtenteOutDtoFromJSONTyped;
function InfoUtenteOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'anagrafica': _1.AnagraficaCedacriDtoToJSON(value.anagrafica)
  };
}
exports.InfoUtenteOutDtoToJSON = InfoUtenteOutDtoToJSON;