"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrePositionLevelBODtoToJSON = exports.PrePositionLevelBODtoFromJSONTyped = exports.PrePositionLevelBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PrePositionLevelBODtoFromJSON(json) {
  return PrePositionLevelBODtoFromJSONTyped(json, false);
}
exports.PrePositionLevelBODtoFromJSON = PrePositionLevelBODtoFromJSON;
function PrePositionLevelBODtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'averageIdleCapital': !runtime_1.exists(json, 'averageIdleCapital') ? undefined : json['averageIdleCapital'],
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'code': !runtime_1.exists(json, 'code') ? undefined : json['code'],
    'contributionToPerformance': !runtime_1.exists(json, 'contributionToPerformance') ? undefined : json['contributionToPerformance'],
    'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
    'finalCapital': !runtime_1.exists(json, 'finalCapital') ? undefined : json['finalCapital'],
    'flowCouponsDividends': !runtime_1.exists(json, 'flowCouponsDividends') ? undefined : json['flowCouponsDividends'],
    'flowDeposit': !runtime_1.exists(json, 'flowDeposit') ? undefined : json['flowDeposit'],
    'flowWithdrawal': !runtime_1.exists(json, 'flowWithdrawal') ? undefined : json['flowWithdrawal'],
    'initialCapital': !runtime_1.exists(json, 'initialCapital') ? undefined : json['initialCapital'],
    'isin': !runtime_1.exists(json, 'isin') ? undefined : json['isin'],
    'marketSquare': !runtime_1.exists(json, 'marketSquare') ? undefined : json['marketSquare'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'movements': !runtime_1.exists(json, 'movements') ? undefined : json['movements'].map(_1.PrePositionMovementBODtoFromJSON),
    'netReturn': !runtime_1.exists(json, 'netReturn') ? undefined : json['netReturn'],
    'performance': !runtime_1.exists(json, 'performance') ? undefined : json['performance'],
    'startDate': !runtime_1.exists(json, 'startDate') ? undefined : json['startDate'],
    'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId']
  };
}
exports.PrePositionLevelBODtoFromJSONTyped = PrePositionLevelBODtoFromJSONTyped;
function PrePositionLevelBODtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'averageIdleCapital': value.averageIdleCapital,
    'bankCode': value.bankCode,
    'code': value.code,
    'contributionToPerformance': value.contributionToPerformance,
    'description': value.description,
    'endDate': value.endDate,
    'finalCapital': value.finalCapital,
    'flowCouponsDividends': value.flowCouponsDividends,
    'flowDeposit': value.flowDeposit,
    'flowWithdrawal': value.flowWithdrawal,
    'initialCapital': value.initialCapital,
    'isin': value.isin,
    'marketSquare': value.marketSquare,
    'message': value.message,
    'movements': value.movements === undefined ? undefined : value.movements.map(_1.PrePositionMovementBODtoToJSON),
    'netReturn': value.netReturn,
    'performance': value.performance,
    'startDate': value.startDate,
    'type': value.type,
    'userId': value.userId
  };
}
exports.PrePositionLevelBODtoToJSON = PrePositionLevelBODtoToJSON;