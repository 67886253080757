"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDatiFiliale = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getDatiFilialeStart() {
  return {
    type: __1.EReduxActionTypes.DATI_FILIALE_GET_DATI_START
  };
}
function getDatiFilialeSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_FILIALE_GET_DATI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getDatiFiliale = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDatiFilialeStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaDatiFiliale().then(response => {
        dispatch(getDatiFilialeSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDatiFiliale = getDatiFiliale;