"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esportaCartaContoPDF = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/* esporta PDF carta conto - START */
function esportaCartaContoPDFStart() {
  return {
    type: __1.EReduxActionTypes.CARTA_CONTO_ESPORTA_PDF_START
  };
}
function esportaCartaContoPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CARTA_CONTO_ESPORTA_PDF_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const esportaCartaContoPDF = (bonifico, share, title, message) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaCartaContoPDFStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaCartaContoPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const esportaDettaglioMovimentoPDFRequest = {
      bonificoSepaDto: bonifico
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.CartaContoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioCartaContoPdf(esportaDettaglioMovimentoPDFRequest).then(async response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'pagamento.pdf', response, share, title, message);
        }
        dispatch(esportaCartaContoPDFSuccess(response));
      }).catch(async error => {
        try {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        } catch (e) {
          console.log(e);
        }
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaCartaContoPDF = esportaCartaContoPDF;
/* esporta PDF carta conto - FINE */