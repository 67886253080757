"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JobActorAttributesValueDtoToJSON = exports.JobActorAttributesValueDtoFromJSONTyped = exports.JobActorAttributesValueDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function JobActorAttributesValueDtoFromJSON(json) {
  return JobActorAttributesValueDtoFromJSONTyped(json, false);
}
exports.JobActorAttributesValueDtoFromJSON = JobActorAttributesValueDtoFromJSON;
function JobActorAttributesValueDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idsuiname': !runtime_1.exists(json, 'ids:ui:name') ? undefined : json['ids:ui:name'],
    'idsuilastName': !runtime_1.exists(json, 'ids:ui:lastName') ? undefined : json['ids:ui:lastName'],
    'idsallfiscalCode': !runtime_1.exists(json, 'ids:all:fiscalCode') ? undefined : json['ids:all:fiscalCode'],
    'idsallphoneNumber': !runtime_1.exists(json, 'ids:all:phoneNumber') ? undefined : json['ids:all:phoneNumber'],
    'idsallemail': !runtime_1.exists(json, 'ids:all:email') ? undefined : json['ids:all:email'],
    'idsfdqinstitutionCode': !runtime_1.exists(json, 'ids:fdq:institutionCode') ? undefined : json['ids:fdq:institutionCode'],
    'idsfdqsigningMode': !runtime_1.exists(json, 'ids:fdq:signingMode') ? undefined : json['ids:fdq:signingMode'],
    'idsfdqcertificateId': !runtime_1.exists(json, 'ids:fdq:certificateId') ? undefined : json['ids:fdq:certificateId'],
    'idsallactorNDG': !runtime_1.exists(json, 'ids:all:actorNDG') ? undefined : json['ids:all:actorNDG'],
    'idsuidescription': !runtime_1.exists(json, 'ids:ui:description') ? undefined : json['ids:ui:description'],
    'idsallmergeGroupInternal': !runtime_1.exists(json, 'ids:all:mergeGroupInternal') ? undefined : json['ids:all:mergeGroupInternal'],
    'idsfdqtype': !runtime_1.exists(json, 'ids:fdq:type') ? undefined : json['ids:fdq:type']
  };
}
exports.JobActorAttributesValueDtoFromJSONTyped = JobActorAttributesValueDtoFromJSONTyped;
function JobActorAttributesValueDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'ids:ui:name': value.idsuiname,
    'ids:ui:lastName': value.idsuilastName,
    'ids:all:fiscalCode': value.idsallfiscalCode,
    'ids:all:phoneNumber': value.idsallphoneNumber,
    'ids:all:email': value.idsallemail,
    'ids:fdq:institutionCode': value.idsfdqinstitutionCode,
    'ids:fdq:signingMode': value.idsfdqsigningMode,
    'ids:fdq:certificateId': value.idsfdqcertificateId,
    'ids:all:actorNDG': value.idsallactorNDG,
    'ids:ui:description': value.idsuidescription,
    'ids:all:mergeGroupInternal': value.idsallmergeGroupInternal,
    'ids:fdq:type': value.idsfdqtype
  };
}
exports.JobActorAttributesValueDtoToJSON = JobActorAttributesValueDtoToJSON;