"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiRapportoDtoToJSON = exports.DatiRapportoDtoFromJSONTyped = exports.DatiRapportoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiRapportoDtoFromJSON(json) {
  return DatiRapportoDtoFromJSONTyped(json, false);
}
exports.DatiRapportoDtoFromJSON = DatiRapportoDtoFromJSON;
function DatiRapportoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'codiceRapportoFormattatoPerTipo': !runtime_1.exists(json, 'codiceRapportoFormattatoPerTipo') ? undefined : json['codiceRapportoFormattatoPerTipo']
  };
}
exports.DatiRapportoDtoFromJSONTyped = DatiRapportoDtoFromJSONTyped;
function DatiRapportoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'intestazione': value.intestazione,
    'codiceRapportoFormattatoPerTipo': value.codiceRapportoFormattatoPerTipo
  };
}
exports.DatiRapportoDtoToJSON = DatiRapportoDtoToJSON;