"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRevocaBonificoDtoFromJSON = exports.FormBonificoDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormBonificoDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'beneficiario': !(0, helpers_1.exists)(json, 'beneficiario') ? undefined : json.beneficiario,
    'beneficiarioDirty': !(0, helpers_1.exists)(json, 'beneficiarioDirty') ? false : json.beneficiarioDirty,
    'beneficiarioError': !(0, helpers_1.exists)(json, 'beneficiarioError') ? 'invalid.required' : json.beneficiarioError,
    'iban': !(0, helpers_1.exists)(json, 'iban') ? undefined : json.iban,
    'ibanDirty': !(0, helpers_1.exists)(json, 'ibanDirty') ? false : json.ibanDirty,
    'ibanError': !(0, helpers_1.exists)(json, 'ibanError') ? 'invalid.required' : json.ibanError,
    'addRubrica': !(0, helpers_1.exists)(json, 'addRubrica') ? undefined : json.addRubrica,
    'addWhiteList': !(0, helpers_1.exists)(json, 'addWhiteList') ? undefined : json.addWhiteList,
    'idContattoBeneficiario': !(0, helpers_1.exists)(json, 'idContattoBeneficiario') ? undefined : json.idContattoBeneficiario,
    'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
    'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
    'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
    'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
    'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
    'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
    'descrizione': !(0, helpers_1.exists)(json, 'descrizione') ? undefined : json.descrizione,
    'descrizioneDirty': !(0, helpers_1.exists)(json, 'descrizioneDirty') ? false : json.descrizioneDirty,
    'descrizioneError': !(0, helpers_1.exists)(json, 'descrizioneError') ? 'invalid.required' : json.descrizioneError,
    'salva1Click': !(0, helpers_1.exists)(json, 'salva1Click') ? undefined : json.salva1Click,
    'alias1Click': !(0, helpers_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
    'aliasDirty': !(0, helpers_1.exists)(json, 'aliasDirty') ? false : json.aliasDirty,
    'aliasError': !(0, helpers_1.exists)(json, 'aliasError') ? '' : json.aliasError,
    'ordinanteEffettivo': !(0, helpers_1.exists)(json, 'ordinanteEffettivo') ? undefined : json.ordinanteEffettivo,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'intestatario': !(0, helpers_1.exists)(json, 'intestatario') ? undefined : json.intestatario,
    'IBANInt': !(0, helpers_1.exists)(json, 'IBANInt') ? undefined : json.IBANInt,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'accordionDatiAggiuntiviOpened': !(0, helpers_1.exists)(json, 'accordionDatiAggiuntiviOpened') ? false : json.accordionDatiAggiuntiviOpened,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti,
    'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
    'idOneClickCollegata': !(0, helpers_1.exists)(json, 'idOneClickCollegata') ? undefined : json.idOneClickCollegata,
    'codiceFiscalePIVAOrdinanti': !(0, helpers_1.exists)(json, 'codiceFiscalePIVAOrdinanti') ? [''] : json.codiceFiscalePIVAOrdinanti,
    'codiceFiscalePIVABeneficiario': !(0, helpers_1.exists)(json, 'codiceFiscalePIVABeneficiario') ? undefined : json.codiceFiscalePIVABeneficiario,
    'codiceFiscalePIVAAmministratore': !(0, helpers_1.exists)(json, 'codiceFiscalePIVAAmministratore') ? undefined : json.codiceFiscalePIVAAmministratore,
    'motivazionePagamento': !(0, helpers_1.exists)(json, 'motivazionePagamento') ? undefined : json.motivazionePagamento,
    'codiceFiscalePIVAOrdinantiDirty': !(0, helpers_1.exists)(json, 'codiceFiscalePIVAOrdinantiDirty') ? false : json.codiceFiscalePIVAOrdinantiDirty,
    'codiceFiscalePIVAOrdinantiArrayDirty': !(0, helpers_1.exists)(json, 'codiceFiscalePIVAOrdinantiArrayDirty') ? [false] : json.codiceFiscalePIVAOrdinantiArrayDirty,
    'codiceFiscalePIVABeneficiarioDirty': !(0, helpers_1.exists)(json, 'codiceFiscalePIVABeneficiarioDirty') ? false : json.codiceFiscalePIVABeneficiarioDirty,
    'codiceFiscalePIVAAmministratoreDirty': !(0, helpers_1.exists)(json, 'codiceFiscalePIVAAmministratoreDirty') ? false : json.codiceFiscalePIVAAmministratoreDirty,
    'motivazionePagamentoDirty': !(0, helpers_1.exists)(json, 'motivazionePagamentoDirty') ? false : json.motivazionePagamentoDirty,
    'codiceFiscalePIVAOrdinantiError': !(0, helpers_1.exists)(json, 'codiceFiscalePIVAOrdinantiError') ? ['invalid.required'] : json.codiceFiscalePIVAOrdinantiError,
    'codiceFiscalePIVABeneficiarioError': !(0, helpers_1.exists)(json, 'codiceFiscalePIVABeneficiarioError') ? 'invalid.required' : json.codiceFiscalePIVABeneficiarioError,
    'codiceFiscalePIVAAmministratoreError': !(0, helpers_1.exists)(json, 'codiceFiscalePIVAAmministratoreError') ? '' : json.codiceFiscalePIVAAmministratoreError,
    'motivazionePagamentoError': !(0, helpers_1.exists)(json, 'motivazionePagamentoError') ? 'invalid.required' : json.motivazionePagamentoError,
    'istantaneo': !(0, helpers_1.exists)(json, 'istantaneo') ? false : json.istantaneo,
    'errorIstantaneo': !(0, helpers_1.exists)(json, 'errorIstantaneo') ? null : json.errorIstantaneo,
    'usernamePSD2': !(0, helpers_1.exists)(json, 'usernamePSD2') ? undefined : json.usernamePSD2,
    'usernamePSD2Dirty': !(0, helpers_1.exists)(json, 'usernamePSD2Dirty') ? false : json.usernamePSD2Dirty,
    'usernamePSD2Error': !(0, helpers_1.exists)(json, 'usernamePSD2Error') ? 'invalid.required' : json.usernamePSD2Error,
    'checkboxPagamentoSpeseSuPartiComuniCondominiali': !(0, helpers_1.exists)(json, 'checkboxPagamentoSpeseSuPartiComuniCondominiali') ? false : json.checkboxPagamentoSpeseSuPartiComuniCondominiali
  };
}
exports.FormBonificoDtoFromJSONTyped = FormBonificoDtoFromJSONTyped;
function FormRevocaBonificoDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaBonificoDtoFromJSON = FormRevocaBonificoDtoFromJSON;