"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdeguataVerificaControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AdeguataVerificaControllerApi extends runtime.BaseAPI {
  /**
   * Modifica i dati del questionario Adeguata Verifica
   */
  modificaQuestionarioAdeguataVerificaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.modificaAdeguataVerificaRequestDto === null || requestParameters.modificaAdeguataVerificaRequestDto === undefined) {
        throw new runtime.RequiredError('modificaAdeguataVerificaRequestDto', 'Required parameter requestParameters.modificaAdeguataVerificaRequestDto was null or undefined when calling modificaQuestionarioAdeguataVerifica.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/adeguataverifica",
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ModificaAdeguataVerificaRequestDtoToJSON(requestParameters.modificaAdeguataVerificaRequestDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DatiAdeguataVerificaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Modifica i dati del questionario Adeguata Verifica
   */
  modificaQuestionarioAdeguataVerifica(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.modificaQuestionarioAdeguataVerificaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri sezione adeguata verifica
   */
  parametriAdeguataVerificaRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/adeguataverifica/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriAdeguataVerificaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri sezione adeguata verifica
   */
  parametriAdeguataVerifica() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriAdeguataVerificaRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce i dati del questionario Adeguata Verifica
   */
  recuperaDatiRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/adeguataverifica/dati",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DatiAdeguataVerificaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce i dati del questionario Adeguata Verifica
   */
  recuperaDati() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaDatiRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce lista cae e tae associati alla professione
   */
  recuperaDatiProfessioneByCodiceRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceProfessione === null || requestParameters.codiceProfessione === undefined) {
        throw new runtime.RequiredError('codiceProfessione', 'Required parameter requestParameters.codiceProfessione was null or undefined when calling recuperaDatiProfessioneByCodice.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/adeguataverifica/professione/listeaggiuntive/{codiceProfessione}".replace("{".concat("codiceProfessione", "}"), encodeURIComponent(String(requestParameters.codiceProfessione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DatiProfessioneDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce lista cae e tae associati alla professione
   */
  recuperaDatiProfessioneByCodice(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaDatiProfessioneByCodiceRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Stampa il questionario di adeguata verifica in pdf
   */
  stampaQuestionarioPdfRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/adeguataverifica/export/pdf",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Stampa il questionario di adeguata verifica in pdf
   */
  stampaQuestionarioPdf() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.stampaQuestionarioPdfRaw();
      return yield response.value();
    });
  }
}
exports.AdeguataVerificaControllerApi = AdeguataVerificaControllerApi;