"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parametri = exports.ultimoAccesso = exports.formElencoUltimiAccessi = exports.numeroAccessi = exports.elencoUltimiAccessi = void 0;
const reselect_1 = require("reselect");
const elencoUltimiAccessiReducer = state => state;
exports.elencoUltimiAccessi = (0, reselect_1.createSelector)(elencoUltimiAccessiReducer, state => {
  return state.elencoUltimiAccessi.listaAccessi;
});
exports.numeroAccessi = (0, reselect_1.createSelector)(elencoUltimiAccessiReducer, state => {
  return state.elencoUltimiAccessi.numeroElementi;
});
exports.formElencoUltimiAccessi = (0, reselect_1.createSelector)(elencoUltimiAccessiReducer, state => {
  return state.form;
});
exports.ultimoAccesso = (0, reselect_1.createSelector)(elencoUltimiAccessiReducer, state => {
  return state.elencoUltimiAccessi.listaAccessi ? state.elencoUltimiAccessi.listaAccessi[1] : undefined;
});
exports.parametri = (0, reselect_1.createSelector)(elencoUltimiAccessiReducer, state => {
  return state.parametri;
});