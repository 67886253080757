"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListaMovimentiDtoToJSON = exports.ListaMovimentiDtoFromJSONTyped = exports.ListaMovimentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ListaMovimentiDtoFromJSON(json) {
  return ListaMovimentiDtoFromJSONTyped(json, false);
}
exports.ListaMovimentiDtoFromJSON = ListaMovimentiDtoFromJSON;
function ListaMovimentiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'dataInizialeSaldo': !runtime_1.exists(json, 'dataInizialeSaldo') ? undefined : new Date(json['dataInizialeSaldo']),
    'dataFinaleSaldo': !runtime_1.exists(json, 'dataFinaleSaldo') ? undefined : new Date(json['dataFinaleSaldo']),
    'saldoIniziale': !runtime_1.exists(json, 'saldoIniziale') ? undefined : json['saldoIniziale'],
    'saldoFinale': !runtime_1.exists(json, 'saldoFinale') ? undefined : json['saldoFinale'],
    'esclusi': !runtime_1.exists(json, 'esclusi') ? undefined : json['esclusi'],
    'movimenti': !runtime_1.exists(json, 'movimenti') ? undefined : json['movimenti'].map(_1.MovimentoDtoFromJSON),
    'totaleFinale': !runtime_1.exists(json, 'totaleFinale') ? undefined : json['totaleFinale'],
    'totaleMovimenti': !runtime_1.exists(json, 'totaleMovimenti') ? undefined : json['totaleMovimenti'],
    'saldo': !runtime_1.exists(json, 'saldo') ? undefined : _1.SaldoDtoFromJSON(json['saldo']),
    'tassoInteressi': !runtime_1.exists(json, 'tassoInteressi') ? undefined : _1.TassoInteressiDtoFromJSON(json['tassoInteressi']),
    'partitePrenotate': !runtime_1.exists(json, 'partitePrenotate') ? undefined : _1.PartitePrenotateDtoFromJSON(json['partitePrenotate']),
    'cambio': !runtime_1.exists(json, 'cambio') ? undefined : json['cambio'],
    'alertPsd2': !runtime_1.exists(json, 'alertPsd2') ? undefined : json['alertPsd2'].map(_1.AlertPsd2DtoFromJSON)
  };
}
exports.ListaMovimentiDtoFromJSONTyped = ListaMovimentiDtoFromJSONTyped;
function ListaMovimentiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'dataInizialeSaldo': value.dataInizialeSaldo === undefined ? undefined : value.dataInizialeSaldo.toISOString(),
    'dataFinaleSaldo': value.dataFinaleSaldo === undefined ? undefined : value.dataFinaleSaldo.toISOString(),
    'saldoIniziale': value.saldoIniziale,
    'saldoFinale': value.saldoFinale,
    'esclusi': value.esclusi,
    'movimenti': value.movimenti === undefined ? undefined : value.movimenti.map(_1.MovimentoDtoToJSON),
    'totaleFinale': value.totaleFinale,
    'totaleMovimenti': value.totaleMovimenti,
    'saldo': _1.SaldoDtoToJSON(value.saldo),
    'tassoInteressi': _1.TassoInteressiDtoToJSON(value.tassoInteressi),
    'partitePrenotate': _1.PartitePrenotateDtoToJSON(value.partitePrenotate),
    'cambio': value.cambio,
    'alertPsd2': value.alertPsd2 === undefined ? undefined : value.alertPsd2.map(_1.AlertPsd2DtoToJSON)
  };
}
exports.ListaMovimentiDtoToJSON = ListaMovimentiDtoToJSON;