"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MIFIDReducer = void 0;
const __1 = require("..");
const mifid_types_1 = require("./mifid.types");
const initialState = {
  rapporti: undefined,
  risposte: undefined,
  riepilogo: undefined,
  formAnswers: [],
  historyAnswers: [],
  coherenceToBeIgnored: []
};
const MIFIDReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c;
  switch (action.type) {
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_GET_RAPPORTI_SUCCESS:
      return {
        ...state,
        rapporti: action.payload
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_GET_RISPOSTE_SUCCESS:
      return {
        ...state,
        risposte: action.payload
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_SALVA_RISPOSTE_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_SET_ANSWERS:
      return {
        ...state,
        formAnswers: action.payload
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_SET_ANSWER_VALUE:
      return {
        ...state,
        formAnswers: (_a = state.formAnswers) === null || _a === void 0 ? void 0 : _a.map(a => {
          return a.question == action.payload.question ? {
            ...a,
            question: action.payload.question,
            value: action.payload.value
          } : a;
        })
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_HISTORY_SET_ANSWERS:
      return {
        ...state,
        historyAnswers: action.payload
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_SALVA_INCOERENZA_DA_IGNORARE:
      return {
        ...state,
        riepilogo: {
          ...state.riepilogo,
          coherence: (_c = (_b = state.riepilogo) === null || _b === void 0 ? void 0 : _b.coherence) === null || _c === void 0 ? void 0 : _c.filter(c => action.payload.coherence.id != c.id)
        },
        coherenceToBeIgnored: [...state.coherenceToBeIgnored, action.payload]
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_RIMUOVI_INCOERENZE_DA_IGNORARE:
      return {
        ...state,
        coherenceToBeIgnored: state.coherenceToBeIgnored.filter(ctbi => !action.payload.find(apc => apc.coherence.id == ctbi.coherence.id))
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_RESET_ANSWERS:
      return {
        ...state,
        formAnswers: initialState.formAnswers
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_RESET:
      return {
        ...state,
        riepilogo: initialState.riepilogo,
        historyAnswers: initialState.historyAnswers,
        formAnswers: initialState.formAnswers
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_RESET_NO_RAPPORTI:
      return {
        ...initialState,
        rapporti: state.rapporti
      };
    case mifid_types_1.EReduxMIFIDActionTypes.MIFID_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.GENERAL_RESET_POST_CAMBIO_LINGUA:
      // action invocata dal cambio lingua
      return {
        ...state,
        risposte: initialState.risposte
      };
    default:
      return state;
  }
};
exports.MIFIDReducer = MIFIDReducer;
exports.default = exports.MIFIDReducer;