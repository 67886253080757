"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrdineDtoToJSON = exports.OrdineDtoFromJSONTyped = exports.OrdineDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OrdineDtoFromJSON(json) {
  return OrdineDtoFromJSONTyped(json, false);
}
exports.OrdineDtoFromJSON = OrdineDtoFromJSON;
function OrdineDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idOrdine': !runtime_1.exists(json, 'idOrdine') ? undefined : json['idOrdine'],
    'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
    'descTitolo': !runtime_1.exists(json, 'descTitolo') ? undefined : json['descTitolo'],
    'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno'],
    'quantita': !runtime_1.exists(json, 'quantita') ? undefined : json['quantita'],
    'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
    'fase': !runtime_1.exists(json, 'fase') ? undefined : json['fase'],
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'quantitaTrattata': !runtime_1.exists(json, 'quantitaTrattata') ? undefined : json['quantitaTrattata'],
    'quantitaEseguita': !runtime_1.exists(json, 'quantitaEseguita') ? undefined : json['quantitaEseguita'],
    'prezzoMedioEsecuzione': !runtime_1.exists(json, 'prezzoMedioEsecuzione') ? undefined : json['prezzoMedioEsecuzione'],
    'controvaloreTotale': !runtime_1.exists(json, 'controvaloreTotale') ? undefined : json['controvaloreTotale'],
    'speseTotali': !runtime_1.exists(json, 'speseTotali') ? undefined : json['speseTotali'],
    'commissioniTotali': !runtime_1.exists(json, 'commissioniTotali') ? undefined : json['commissioniTotali'],
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'parametroPrezzo': !runtime_1.exists(json, 'parametroPrezzo') ? undefined : json['parametroPrezzo'],
    'prezzoLimitato': !runtime_1.exists(json, 'prezzoLimitato') ? undefined : json['prezzoLimitato'],
    'prezzoLimite': !runtime_1.exists(json, 'prezzoLimite') ? undefined : json['prezzoLimite'],
    'parametroQuantita': !runtime_1.exists(json, 'parametroQuantita') ? undefined : json['parametroQuantita'],
    'parametroTempo': !runtime_1.exists(json, 'parametroTempo') ? undefined : json['parametroTempo'],
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'stopOrder': !runtime_1.exists(json, 'stopOrder') ? undefined : json['stopOrder'],
    'condizioneStop': !runtime_1.exists(json, 'condizioneStop') ? undefined : json['condizioneStop'],
    'prezzoStop': !runtime_1.exists(json, 'prezzoStop') ? undefined : json['prezzoStop'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'data': !runtime_1.exists(json, 'data') ? undefined : new Date(json['data']),
    'controvaloreTotaleDivisa': !runtime_1.exists(json, 'controvaloreTotaleDivisa') ? undefined : json['controvaloreTotaleDivisa'],
    'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'cambioMedioEsecuzione': !runtime_1.exists(json, 'cambioMedioEsecuzione') ? undefined : json['cambioMedioEsecuzione'],
    'indicativoTitolo': !runtime_1.exists(json, 'indicativoTitolo') ? undefined : json['indicativoTitolo'],
    'numeroOrdine': !runtime_1.exists(json, 'numeroOrdine') ? undefined : json['numeroOrdine'],
    'canaleDiVendita': !runtime_1.exists(json, 'canaleDiVendita') ? undefined : json['canaleDiVendita'],
    'dossier': !runtime_1.exists(json, 'dossier') ? undefined : json['dossier'],
    'importoOrdine': !runtime_1.exists(json, 'importoOrdine') ? undefined : json['importoOrdine'],
    'tipologiaOrdine': !runtime_1.exists(json, 'tipologiaOrdine') ? undefined : json['tipologiaOrdine']
  };
}
exports.OrdineDtoFromJSONTyped = OrdineDtoFromJSONTyped;
function OrdineDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idOrdine': value.idOrdine,
    'codiceIsin': value.codiceIsin,
    'descTitolo': value.descTitolo,
    'segno': value.segno,
    'quantita': value.quantita,
    'mercato': value.mercato,
    'fase': value.fase,
    'stato': value.stato,
    'quantitaTrattata': value.quantitaTrattata,
    'quantitaEseguita': value.quantitaEseguita,
    'prezzoMedioEsecuzione': value.prezzoMedioEsecuzione,
    'controvaloreTotale': value.controvaloreTotale,
    'speseTotali': value.speseTotali,
    'commissioniTotali': value.commissioniTotali,
    'causale': value.causale,
    'parametroPrezzo': value.parametroPrezzo,
    'prezzoLimitato': value.prezzoLimitato,
    'prezzoLimite': value.prezzoLimite,
    'parametroQuantita': value.parametroQuantita,
    'parametroTempo': value.parametroTempo,
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'stopOrder': value.stopOrder,
    'condizioneStop': value.condizioneStop,
    'prezzoStop': value.prezzoStop,
    'divisa': value.divisa,
    'data': value.data === undefined ? undefined : value.data.toISOString(),
    'controvaloreTotaleDivisa': value.controvaloreTotaleDivisa,
    'revocabile': value.revocabile,
    'titolo': value.titolo,
    'cambioMedioEsecuzione': value.cambioMedioEsecuzione,
    'indicativoTitolo': value.indicativoTitolo,
    'numeroOrdine': value.numeroOrdine,
    'canaleDiVendita': value.canaleDiVendita,
    'dossier': value.dossier,
    'importoOrdine': value.importoOrdine,
    'tipologiaOrdine': value.tipologiaOrdine
  };
}
exports.OrdineDtoToJSON = OrdineDtoToJSON;