"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BilancioDtoToJSON = exports.BilancioDtoFromJSONTyped = exports.BilancioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BilancioDtoFromJSON(json) {
  return BilancioDtoFromJSONTyped(json, false);
}
exports.BilancioDtoFromJSON = BilancioDtoFromJSON;
function BilancioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'meseDa': !runtime_1.exists(json, 'meseDa') ? undefined : json['meseDa'],
    'meseA': !runtime_1.exists(json, 'meseA') ? undefined : json['meseA'],
    'entrateTrimestre': !runtime_1.exists(json, 'entrateTrimestre') ? undefined : json['entrateTrimestre'],
    'usciteTrimestre': !runtime_1.exists(json, 'usciteTrimestre') ? undefined : json['usciteTrimestre'],
    'sommaTrimestre': !runtime_1.exists(json, 'sommaTrimestre') ? undefined : json['sommaTrimestre'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'mesiBilancio': !runtime_1.exists(json, 'mesiBilancio') ? undefined : json['mesiBilancio'].map(_1.MeseBilancioDtoFromJSON)
  };
}
exports.BilancioDtoFromJSONTyped = BilancioDtoFromJSONTyped;
function BilancioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'meseDa': value.meseDa,
    'meseA': value.meseA,
    'entrateTrimestre': value.entrateTrimestre,
    'usciteTrimestre': value.usciteTrimestre,
    'sommaTrimestre': value.sommaTrimestre,
    'divisa': value.divisa,
    'mesiBilancio': value.mesiBilancio === undefined ? undefined : value.mesiBilancio.map(_1.MeseBilancioDtoToJSON)
  };
}
exports.BilancioDtoToJSON = BilancioDtoToJSON;