"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiAggiuntiviDtoToJSON = exports.DatiAggiuntiviDtoFromJSONTyped = exports.DatiAggiuntiviDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiAggiuntiviDtoFromJSON(json) {
  return DatiAggiuntiviDtoFromJSONTyped(json, false);
}
exports.DatiAggiuntiviDtoFromJSON = DatiAggiuntiviDtoFromJSON;
function DatiAggiuntiviDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceDato': !runtime_1.exists(json, 'codiceDato') ? undefined : json['codiceDato'],
    'layout': !runtime_1.exists(json, 'layout') ? undefined : json['layout'],
    'valoreCodiceDato': !runtime_1.exists(json, 'valoreCodiceDato') ? undefined : json['valoreCodiceDato'],
    'lunghezzaCodiceDato': !runtime_1.exists(json, 'lunghezzaCodiceDato') ? undefined : json['lunghezzaCodiceDato'],
    'numeroDecimaliCodiceDato': !runtime_1.exists(json, 'numeroDecimaliCodiceDato') ? undefined : json['numeroDecimaliCodiceDato'],
    'tipoDato': !runtime_1.exists(json, 'tipoDato') ? undefined : json['tipoDato'],
    'formatoIn': !runtime_1.exists(json, 'formatoIn') ? undefined : json['formatoIn'],
    'formatoOut': !runtime_1.exists(json, 'formatoOut') ? undefined : json['formatoOut'],
    'returnCode': !runtime_1.exists(json, 'returnCode') ? undefined : json['returnCode'],
    'responseCode': !runtime_1.exists(json, 'responseCode') ? undefined : json['responseCode'],
    'descrizioneErrore': !runtime_1.exists(json, 'descrizioneErrore') ? undefined : json['descrizioneErrore']
  };
}
exports.DatiAggiuntiviDtoFromJSONTyped = DatiAggiuntiviDtoFromJSONTyped;
function DatiAggiuntiviDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceDato': value.codiceDato,
    'layout': value.layout,
    'valoreCodiceDato': value.valoreCodiceDato,
    'lunghezzaCodiceDato': value.lunghezzaCodiceDato,
    'numeroDecimaliCodiceDato': value.numeroDecimaliCodiceDato,
    'tipoDato': value.tipoDato,
    'formatoIn': value.formatoIn,
    'formatoOut': value.formatoOut,
    'returnCode': value.returnCode,
    'responseCode': value.responseCode,
    'descrizioneErrore': value.descrizioneErrore
  };
}
exports.DatiAggiuntiviDtoToJSON = DatiAggiuntiviDtoToJSON;