"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RapportoADVDtoToJSON = exports.RapportoADVDtoFromJSONTyped = exports.RapportoADVDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RapportoADVDtoFromJSON(json) {
  return RapportoADVDtoFromJSONTyped(json, false);
}
exports.RapportoADVDtoFromJSON = RapportoADVDtoFromJSON;
function RapportoADVDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'codiceRapportoFormattatoPerTipo': !runtime_1.exists(json, 'codiceRapportoFormattatoPerTipo') ? undefined : json['codiceRapportoFormattatoPerTipo'],
    'servizio': !runtime_1.exists(json, 'servizio') ? undefined : json['servizio'],
    'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
    'numeroConto': !runtime_1.exists(json, 'numeroConto') ? undefined : json['numeroConto'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario'],
    'abilitato': !runtime_1.exists(json, 'abilitato') ? undefined : json['abilitato'],
    'giorniResidui': !runtime_1.exists(json, 'giorniResidui') ? undefined : json['giorniResidui'],
    'tipoRapporto': !runtime_1.exists(json, 'tipoRapporto') ? undefined : json['tipoRapporto'],
    'esigenzePersonali': !runtime_1.exists(json, 'esigenzePersonali') ? undefined : json['esigenzePersonali'],
    'esigenzeAziendali': !runtime_1.exists(json, 'esigenzeAziendali') ? undefined : json['esigenzeAziendali'],
    'naturaRapporto': !runtime_1.exists(json, 'naturaRapporto') ? undefined : json['naturaRapporto'],
    'scopoPrevalenteRapporto': !runtime_1.exists(json, 'scopoPrevalenteRapporto') ? undefined : json['scopoPrevalenteRapporto'],
    'descrizioneScopoPrevalenteRapporto': !runtime_1.exists(json, 'descrizioneScopoPrevalenteRapporto') ? undefined : json['descrizioneScopoPrevalenteRapporto'],
    'origineDestinazioneFondi': !runtime_1.exists(json, 'origineDestinazioneFondi') ? undefined : json['origineDestinazioneFondi'],
    'descOrigineDestinazioneFondi': !runtime_1.exists(json, 'descOrigineDestinazioneFondi') ? undefined : json['descOrigineDestinazioneFondi'],
    'codiceRapportoFormattatoBanca': !runtime_1.exists(json, 'codiceRapportoFormattatoBanca') ? undefined : json['codiceRapportoFormattatoBanca']
  };
}
exports.RapportoADVDtoFromJSONTyped = RapportoADVDtoFromJSONTyped;
function RapportoADVDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'codiceRapportoFormattatoPerTipo': value.codiceRapportoFormattatoPerTipo,
    'servizio': value.servizio,
    'filiale': value.filiale,
    'numeroConto': value.numeroConto,
    'iban': value.iban,
    'intestatario': value.intestatario,
    'abilitato': value.abilitato,
    'giorniResidui': value.giorniResidui,
    'tipoRapporto': value.tipoRapporto,
    'esigenzePersonali': value.esigenzePersonali,
    'esigenzeAziendali': value.esigenzeAziendali,
    'naturaRapporto': value.naturaRapporto,
    'scopoPrevalenteRapporto': value.scopoPrevalenteRapporto,
    'descrizioneScopoPrevalenteRapporto': value.descrizioneScopoPrevalenteRapporto,
    'origineDestinazioneFondi': value.origineDestinazioneFondi,
    'descOrigineDestinazioneFondi': value.descOrigineDestinazioneFondi,
    'codiceRapportoFormattatoBanca': value.codiceRapportoFormattatoBanca
  };
}
exports.RapportoADVDtoToJSON = RapportoADVDtoToJSON;