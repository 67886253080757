"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuotazioneDettaglioIndiceDtoToJSON = exports.QuotazioneDettaglioIndiceDtoFromJSONTyped = exports.QuotazioneDettaglioIndiceDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuotazioneDettaglioIndiceDtoFromJSON(json) {
  return QuotazioneDettaglioIndiceDtoFromJSONTyped(json, false);
}
exports.QuotazioneDettaglioIndiceDtoFromJSON = QuotazioneDettaglioIndiceDtoFromJSON;
function QuotazioneDettaglioIndiceDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'valoreMax52Sett': !runtime_1.exists(json, 'valoreMax52Sett') ? undefined : json['valoreMax52Sett'],
    'valoreMin52Sett': !runtime_1.exists(json, 'valoreMin52Sett') ? undefined : json['valoreMin52Sett'],
    'quotazione': !runtime_1.exists(json, 'quotazione') ? undefined : _1.QuotazioneIndiceDtoFromJSON(json['quotazione']),
    'tipologiaIndice': !runtime_1.exists(json, 'tipologiaIndice') ? undefined : json['tipologiaIndice'],
    'varAssoluta': !runtime_1.exists(json, 'varAssoluta') ? undefined : json['varAssoluta']
  };
}
exports.QuotazioneDettaglioIndiceDtoFromJSONTyped = QuotazioneDettaglioIndiceDtoFromJSONTyped;
function QuotazioneDettaglioIndiceDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'valoreMax52Sett': value.valoreMax52Sett,
    'valoreMin52Sett': value.valoreMin52Sett,
    'quotazione': _1.QuotazioneIndiceDtoToJSON(value.quotazione),
    'tipologiaIndice': value.tipologiaIndice,
    'varAssoluta': value.varAssoluta
  };
}
exports.QuotazioneDettaglioIndiceDtoToJSON = QuotazioneDettaglioIndiceDtoToJSON;