"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MavsDtoToJSON = exports.MavsDtoFromJSONTyped = exports.MavsDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MavsDtoFromJSON(json) {
  return MavsDtoFromJSONTyped(json, false);
}
exports.MavsDtoFromJSON = MavsDtoFromJSON;
function MavsDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mavs': !runtime_1.exists(json, 'mavs') ? undefined : json['mavs'].map(_1.MavDtoFromJSON)
  };
}
exports.MavsDtoFromJSONTyped = MavsDtoFromJSONTyped;
function MavsDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mavs': value.mavs === undefined ? undefined : value.mavs.map(_1.MavDtoToJSON)
  };
}
exports.MavsDtoToJSON = MavsDtoToJSON;