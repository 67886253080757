"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RapportoCompletoDtoToJSON = exports.RapportoCompletoDtoFromJSONTyped = exports.RapportoCompletoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RapportoCompletoDtoFromJSON(json) {
  return RapportoCompletoDtoFromJSONTyped(json, false);
}
exports.RapportoCompletoDtoFromJSON = RapportoCompletoDtoFromJSON;
function RapportoCompletoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporto': !runtime_1.exists(json, 'rapporto') ? undefined : _1.RapportoDtoFromJSON(json['rapporto']),
    'saldo': !runtime_1.exists(json, 'saldo') ? undefined : _1.SaldoDtoFromJSON(json['saldo'])
  };
}
exports.RapportoCompletoDtoFromJSONTyped = RapportoCompletoDtoFromJSONTyped;
function RapportoCompletoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporto': _1.RapportoDtoToJSON(value.rapporto),
    'saldo': _1.SaldoDtoToJSON(value.saldo)
  };
}
exports.RapportoCompletoDtoToJSON = RapportoCompletoDtoToJSON;