"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pagamentiRapportiEstinti = exports.formElencoPagamentiRapportiEstinti = exports.rapportiEstinti = exports.formRapportiEstintiAsFiltro = exports.formElencoPagamentiRapportiEstintiAsFiltro = exports.formElencoPagamentiAsFiltro = exports.filtriPagamentiApplicati = exports.formPagamentiValid = exports.formPagamenti = exports.pagamentoSelezionato = exports.dettaglioPagamento = exports.pagamenti = exports.parametriPagamenti = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const IMPORTO_MOLTIPLICATORI_INTERVALLO = {
  MIN: 0.8,
  MAX: 1.2
};
const pagamentiReducer = state => state;
exports.parametriPagamenti = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.parametri;
});
exports.pagamenti = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.pagamenti;
});
exports.dettaglioPagamento = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.dettaglioPagamento;
});
exports.pagamentoSelezionato = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.pagamentoSelezionato;
});
exports.formPagamenti = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.form;
});
exports.formPagamentiValid = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  let formValid = false;
  if (!state.form.descrizioneError && !state.form.dataDaError && !state.form.dataAError && !state.form.importoError) {
    formValid = true;
  }
  return formValid;
});
exports.filtriPagamentiApplicati = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  var _a;
  let r = false;
  const periodoDefault = (((_a = state.parametri.parametri) === null || _a === void 0 ? void 0 : _a['PAGAMENTI.PERIODI.RICERCA']) || []).find(periodo => periodo.defaultValue);
  if (!!state.form.descrizione || String(state.form.periodo) != String((periodoDefault === null || periodoDefault === void 0 ? void 0 : periodoDefault.giorni) || '30') || !!state.form.importo || !!state.form.codiceFiscale || !!state.form.tipologie && state.form.tipologie.length > 0 || !!state.form.stati && state.form.stati.length > 0) {
    r = true;
  }
  return r;
});
exports.formElencoPagamentiAsFiltro = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  var _a, _b, _c, _d, _e, _f;
  return {
    offset: state.form.offset || 0,
    codiceRapporto: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
    dataDa: new Date(state.form.dataDa),
    dataA: new Date(state.form.dataA),
    paroleChiave: state.form.descrizione || undefined,
    importoDa: (0, helpers_1.formattedNumberToNumber)(state.form.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MIN || undefined,
    importoA: (0, helpers_1.formattedNumberToNumber)(state.form.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MAX || undefined,
    codiceFiscale: state.form.codiceFiscale || undefined,
    tipologie: ((_c = state.form.tipologie) === null || _c === void 0 ? void 0 : _c.length) ? state.form.tipologie : state.parametri.tipologie,
    stato: ((_d = state.form.stati) === null || _d === void 0 ? void 0 : _d.length) ? state.form.stati : undefined,
    rapportoEstinto: ((_f = (_e = state.form.rapportoSelezionato) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.isEstinto) || undefined
  };
});
exports.formElencoPagamentiRapportiEstintiAsFiltro = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  var _a;
  return {
    offset: state.form.offset || 0,
    codiceRapporto: ((_a = state.formEstinti.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.id) || 0,
    dataDa: new Date(state.formEstinti.dataDa),
    dataA: new Date(state.formEstinti.dataA),
    tipologie: [state.formEstinti.tipologie],
    rapportoEstinto: true,
    ibanRapportoEstinto: state.formEstinti.rapportoSelezionato.iban
  };
});
exports.formRapportiEstintiAsFiltro = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return {
    dataDa: new Date(state.formEstinti.dataDa),
    dataA: new Date(state.formEstinti.dataA),
    tipologiaMovimento: state.formEstinti.tipologie,
    offset: state.formEstinti.offset
  };
});
exports.rapportiEstinti = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.rapportiEstinti;
});
exports.formElencoPagamentiRapportiEstinti = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.formEstinti;
});
exports.pagamentiRapportiEstinti = (0, reselect_1.createSelector)(pagamentiReducer, state => {
  return state.pagamentiRapportiEstinti;
});