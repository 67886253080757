"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.indiciReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const indici_types_1 = require("./indici.types");
const initialState = {
  indici: (0, api_rest_1.QuotazioniIndiciDtoFromJSON)({}),
  mercati: (0, api_rest_1.MercatiDtoFromJSON)({}),
  indiceDettaglio: {
    dettaglioIndice: (0, api_rest_1.QuotazioneDettaglioTitoloDtoFromJSON)({}),
    datiIntradayIndice: (0, api_rest_1.DatiIntradayDtoFromJSON)({}),
    schedaIndice: (0, api_rest_1.SchedaTitoloTrading2DtoFromJSON)({}),
    datiStoriciIndice: (0, api_rest_1.DatiStoriciDtoFromJSON)({}),
    tipoGrafico: indici_types_1.TipoGraficoIndici.Settimana1
  },
  form: {
    mercato: '',
    denominazione: '',
    indiceSelezionato: (0, api_rest_1.QuotazioneTitoloDtoFromJSON)({}),
    offset: 0
  }
};
const indiciReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case indici_types_1.EReduxIndiciActionTypes.INDICI_GET_MERCATI_SUCCESS:
      return {
        ...initialState,
        mercati: action.payload
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_GET_TITOLI_SUCCESS:
      return {
        ...state,
        indici: !action.offset ? action.payload : {
          ...state.indici,
          quotazioni: (state.indici.quotazioni || []).concat(action.payload.quotazioni || [])
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DETTAGLIO_INDICE_SUCCESS:
      return {
        ...state,
        indiceDettaglio: {
          ...state.indiceDettaglio,
          dettaglioIndice: action.payload
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DATI_INTRADAY_INDICE_SUCCESS:
      return {
        ...state,
        indiceDettaglio: {
          ...state.indiceDettaglio,
          datiIntradayIndice: action.payload,
          aggiornamento: action.aggiornamento
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DATI_STORICI_INDICE_SUCCESS:
      return {
        ...state,
        indiceDettaglio: {
          ...state.indiceDettaglio,
          aggiornamento: action.aggiornamento,
          datiStoriciIndice: action.payload,
          datiSettimana: action.tipo === action.tipologiaGrafico.Settimana1 ? action.payload : state.indiceDettaglio.datiSettimana,
          datiMese: action.tipo === action.tipologiaGrafico.Mese1 ? action.payload : state.indiceDettaglio.datiMese,
          datiAnno: action.tipo === action.tipologiaGrafico.Anno1 ? action.payload : state.indiceDettaglio.datiAnno
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_GET_SCHEDA_INDICE_SUCCESS:
      return {
        ...state,
        indiceDettaglio: {
          ...state.indiceDettaglio,
          schedaIndice: action.payload
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.SET_INDICI_MERCATO:
      return {
        ...state,
        form: {
          ...state.form,
          mercato: action.payload
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.SET_INDICI_DENOMINAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          denominazione: action.payload
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_RESET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          indiceSelezionato: initialState.form.indiceSelezionato,
          denominazione: initialState.form.denominazione,
          offset: initialState.form.offset
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_RESET:
      return {
        ...initialState
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_RESET_DETTAGLIO:
      return {
        ...state,
        indiceDettaglio: initialState.indiceDettaglio
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_SET_INDICE_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          indiceSelezionato: action.payload
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_SET_OFFSET:
      return {
        ...state,
        form: {
          ...state.form,
          offset: action.payload
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_SET_TIPO_GRAFICO:
      return {
        ...state,
        indiceDettaglio: {
          ...state.indiceDettaglio,
          tipoGrafico: action.payload
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_AGGIORNA_GRAFICO:
      return {
        ...state,
        indiceDettaglio: {
          ...state.indiceDettaglio,
          aggiornamento: action.aggiornamento,
          datiIntradayIndice: action.tipo === indici_types_1.TipoGraficoIndici.Ore1 || action.tipo === indici_types_1.TipoGraficoIndici.Giorni1 || action.tipo === indici_types_1.TipoGraficoIndici.Ore4 ? action.payload : initialState.indiceDettaglio.datiIntradayIndice,
          datiStoriciIndice: action.tipo === indici_types_1.TipoGraficoIndici.Ore1 || action.tipo === indici_types_1.TipoGraficoIndici.Giorni1 || action.tipo === indici_types_1.TipoGraficoIndici.Ore4 ? action.payload : initialState.indiceDettaglio.datiStoriciIndice,
          datiSettimana: action.tipo === indici_types_1.TipoGraficoIndici.Settimana1 ? action.payload : initialState.indiceDettaglio.datiSettimana,
          datiMese: action.tipo === indici_types_1.TipoGraficoIndici.Mese1 ? action.payload : initialState.indiceDettaglio.datiMese,
          datiAnno: action.tipo === indici_types_1.TipoGraficoIndici.Anno1 ? action.payload : initialState.indiceDettaglio.datiAnno
        }
      };
    case indici_types_1.EReduxIndiciActionTypes.INDICI_ESTERI_AGGIORNA_GRAFICO:
      return {
        ...state,
        indiceDettaglio: {
          ...state.indiceDettaglio,
          aggiornamento: action.aggiornamento,
          datiIntradayIndice: initialState.indiceDettaglio.datiIntradayIndice,
          datiStoriciIndice: action.tipo === indici_types_1.TipoGraficoIndiciEsteri.Settimana1 || action.tipo === indici_types_1.TipoGraficoIndiciEsteri.Mese1 || action.tipo === indici_types_1.TipoGraficoIndiciEsteri.Anno1 ? action.payload : initialState.indiceDettaglio.datiStoriciIndice,
          datiSettimana: action.tipo === indici_types_1.TipoGraficoIndiciEsteri.Settimana1 ? action.payload : initialState.indiceDettaglio.datiSettimana,
          datiMese: action.tipo === indici_types_1.TipoGraficoIndiciEsteri.Mese1 ? action.payload : initialState.indiceDettaglio.datiMese,
          datiAnno: action.tipo === indici_types_1.TipoGraficoIndiciEsteri.Anno1 ? action.payload : initialState.indiceDettaglio.datiAnno
        }
      };
    default:
      return state;
  }
};
exports.indiciReducer = indiciReducer;
exports.default = exports.indiciReducer;