"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetProdottoDaId = exports.getProdottoCatalogoDaId = exports.getProdottoDaIdSuccessAction = exports.getProdottiTop = exports.resetProdottoDaCodiceFunzione = exports.getProdottoCatalogoDaCodiceFunzione = exports.getProdottoDaCodiceFunzioneSuccessAction = exports.selectProdotto = exports.getCatalogoProdotti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const catalogoProdotti_types_1 = require("./catalogoProdotti.types");
/* get prodotti - START */
function getCatalogoProdottiStart() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_START
  };
}
function getCatalogoProdottiSuccess(payload) {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getCatalogoProdotti = lingua => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getCatalogoProdottiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('./mock_catalogo_prodotti.json')).then(catalogo => {
      dispatch(getCatalogoProdottiSuccess(catalogo));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem("language", true).then(language => {
      const requestParameters = {
        lingua: lingua ? lingua : language
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.CatalogoProdottiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getProdotti(requestParameters).then(response => {
          try {
            const catalogo = JSON.parse(response);
            dispatch(getCatalogoProdottiSuccess(catalogo));
          } catch (error) {
            Promise.resolve().then(() => require('../../mock/catalogo_prodotti.json')).then(catalogo => {
              dispatch(getCatalogoProdottiSuccess(catalogo));
            });
          }
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    });
  }
};
exports.getCatalogoProdotti = getCatalogoProdotti;
/* get prodotti - FINE */
/* select prodotto - START */
function selectProdotto(item) {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_SELECT_PRODOTTO,
    payload: item
  };
}
exports.selectProdotto = selectProdotto;
/* get prodotto da codice funzione - START */
function getProdottoDaCodiceFunzioneStart() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_START
  };
}
function getProdottoDaCodiceFunzioneSuccess(prodotto) {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_SUCCESS,
    payload: prodotto
  };
}
const getProdottoDaCodiceFunzioneSuccessAction = codiceFunzione => async (dispatch, getState, extraArguments) => {
  const catalogo = getState().catalogoProdotti.catalogo;
  let prodotto;
  for (let i = 0; i < catalogo.length; i++) {
    if (prodotto) {
      break;
    }
    prodotto = catalogo[i].term.products.find(currentProduct => {
      var _a, _b;
      return ((_a = currentProduct.multiplatform) === null || _a === void 0 ? void 0 : _a.find) ? (_b = currentProduct.multiplatform) === null || _b === void 0 ? void 0 : _b.find(multiplatform => {
        return (multiplatform === null || multiplatform === void 0 ? void 0 : multiplatform.key) == 'codiceFunzione' && multiplatform.value == codiceFunzione;
      }) : undefined;
    });
  }
  if (prodotto) {
    dispatch(getProdottoDaCodiceFunzioneSuccess(prodotto));
  } else {
    dispatch(generalError({
      timestamp: moment().toISOString(),
      message: 'nessunProdottoTrovato'
    }));
    dispatch(getProdottoDaCodiceFunzioneErrorAction());
  }
};
exports.getProdottoDaCodiceFunzioneSuccessAction = getProdottoDaCodiceFunzioneSuccessAction;
function getProdottoDaCodiceFunzioneErrorAction() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_ERROR
  };
}
const getProdottoCatalogoDaCodiceFunzione = codiceFunzione => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getProdottoDaCodiceFunzioneStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/prodotto_catalogo.json')).then(prodotto => {
      dispatch(getProdottoDaCodiceFunzioneSuccess(prodotto));
    });
  } else {
    if (getState().catalogoProdotti.catalogo) {
      dispatch((0, exports.getProdottoDaCodiceFunzioneSuccessAction)(codiceFunzione));
      dispatch((0, general_actions_1.setLoader)(false));
    } else {
      const requestParameters = {
        lingua: getState().i18n.currentLanguage || 'it'
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.CatalogoProdottiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getProdotti(requestParameters).then(response => {
          try {
            const catalogo = JSON.parse(response);
            dispatch(getCatalogoProdottiSuccess(catalogo));
            dispatch((0, exports.getProdottoDaCodiceFunzioneSuccessAction)(codiceFunzione));
          } catch (error) {
            Promise.resolve().then(() => require('../../mock/catalogo_prodotti.json')).then(catalogo => {
              dispatch(getCatalogoProdottiSuccess(catalogo));
              dispatch((0, exports.getProdottoDaCodiceFunzioneSuccessAction)(codiceFunzione));
            });
          }
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
          dispatch(getProdottoDaCodiceFunzioneErrorAction());
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.getProdottoCatalogoDaCodiceFunzione = getProdottoCatalogoDaCodiceFunzione;
function resetProdottoDaCodiceFunzione() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_CODICE_FUNZIONE
  };
}
exports.resetProdottoDaCodiceFunzione = resetProdottoDaCodiceFunzione;
/* get prodotto da codice funzione - FINE */
/* get prodotti top - START */
function getProdottiTopStart() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_START
  };
}
function getProdottiTopSuccess(payload) {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_SUCCESS,
    payload
  };
}
const getProdottiTop = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getProdottiTopStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/prodotti_top.json')).then(response => {
      dispatch(getProdottiTopSuccess(response));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ProdottiTopControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getProdottiTop().then(response => {
        dispatch(getProdottiTopSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getProdottiTop = getProdottiTop;
/* get prodotti top - FINE */
/* get prodotto da id - START */
function getProdottoDaIdStart() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_START
  };
}
function getProdottoDaIdSuccess(prodotto) {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_SUCCESS,
    payload: prodotto
  };
}
const getProdottoDaIdSuccessAction = id => async (dispatch, getState, extraArguments) => {
  const catalogo = getState().catalogoProdotti.catalogo;
  let prodotto;
  for (let i = 0; i < catalogo.length; i++) {
    if (prodotto) {
      break;
    }
    prodotto = catalogo[i].term.products.find(currentProduct => {
      return currentProduct.ID === id;
    });
  }
  if (prodotto) {
    dispatch(getProdottoDaIdSuccess(prodotto));
  } else {
    dispatch(generalError({
      timestamp: moment().toISOString(),
      message: 'nessunProdottoTrovato'
    }));
    dispatch(getProdottoDaIdErrorAction());
  }
};
exports.getProdottoDaIdSuccessAction = getProdottoDaIdSuccessAction;
function getProdottoDaIdErrorAction() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_ERROR
  };
}
const getProdottoCatalogoDaId = id => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getProdottoDaIdStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/prodotto_catalogo.json')).then(prodotto => {
      dispatch(getProdottoDaIdSuccess(prodotto));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    if (getState().catalogoProdotti.catalogo) {
      dispatch((0, exports.getProdottoDaIdSuccessAction)(id));
      dispatch((0, general_actions_1.setLoader)(false));
    } else {
      const lingua = await extraArguments.getItem('language');
      const requestParameters = {
        lingua: lingua || 'it'
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.CatalogoProdottiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getProdotti(requestParameters).then(response => {
          try {
            const catalogo = JSON.parse(response);
            dispatch(getCatalogoProdottiSuccess(catalogo));
            dispatch((0, exports.getProdottoDaIdSuccessAction)(id));
          } catch (error) {
            Promise.resolve().then(() => require('../../mock/catalogo_prodotti.json')).then(catalogo => {
              dispatch(getCatalogoProdottiSuccess(catalogo));
              dispatch((0, exports.getProdottoDaIdSuccessAction)(id));
            });
          }
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
          dispatch(getProdottoDaIdErrorAction());
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.getProdottoCatalogoDaId = getProdottoCatalogoDaId;
function resetProdottoDaId() {
  return {
    type: catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_ID
  };
}
exports.resetProdottoDaId = resetProdottoDaId;
/* get prodotto da id - FINE */