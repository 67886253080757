"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DisposizioneDtoStatoDisposizioneEnum = exports.DisposizioneDtoTipoDisposizioneEnum = exports.DisposizioneDtoTipoDataEnum = exports.DisposizioneDtoPeriodoEnum = exports.DisposizioneDtoTipoPeriodoEnum = exports.DisposizioneDtoTipoImportoEnum = exports.BonificoRipetitivoImpostazioni = exports.BonificoRipetitivoTipoDisposizione = exports.FEBBRAIO_29_ABILITATO = exports.bonificoRipetitivoReducer = void 0;
var bonificoripetitivo_reducer_1 = require("./bonificoripetitivo.reducer");
Object.defineProperty(exports, "bonificoRipetitivoReducer", {
  enumerable: true,
  get: function () {
    return bonificoripetitivo_reducer_1.bonificoRipetitivoReducer;
  }
});
__exportStar(require("./bonificoripetitivo.selectors"), exports);
__exportStar(require("./bonificoripetitivo.actions"), exports);
var bonificoripetitivo_reducer_2 = require("./bonificoripetitivo.reducer");
Object.defineProperty(exports, "FEBBRAIO_29_ABILITATO", {
  enumerable: true,
  get: function () {
    return bonificoripetitivo_reducer_2.FEBBRAIO_29_ABILITATO;
  }
});
var bonificoripetitivo_types_1 = require("./bonificoripetitivo.types");
Object.defineProperty(exports, "BonificoRipetitivoTipoDisposizione", {
  enumerable: true,
  get: function () {
    return bonificoripetitivo_types_1.BonificoRipetitivoTipoDisposizione;
  }
});
Object.defineProperty(exports, "BonificoRipetitivoImpostazioni", {
  enumerable: true,
  get: function () {
    return bonificoripetitivo_types_1.BonificoRipetitivoImpostazioni;
  }
});
var api_rest_1 = require("@sparkasse/api-rest");
Object.defineProperty(exports, "DisposizioneDtoTipoImportoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.DisposizioneDtoTipoImportoEnum;
  }
});
Object.defineProperty(exports, "DisposizioneDtoTipoPeriodoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.DisposizioneDtoTipoPeriodoEnum;
  }
});
Object.defineProperty(exports, "DisposizioneDtoPeriodoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.DisposizioneDtoPeriodoEnum;
  }
});
Object.defineProperty(exports, "DisposizioneDtoTipoDataEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.DisposizioneDtoTipoDataEnum;
  }
});
Object.defineProperty(exports, "DisposizioneDtoTipoDisposizioneEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.DisposizioneDtoTipoDisposizioneEnum;
  }
});
Object.defineProperty(exports, "DisposizioneDtoStatoDisposizioneEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.DisposizioneDtoStatoDisposizioneEnum;
  }
});