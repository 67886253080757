import BankIcon from "component/Icon/icon.component";
import React from "react";
import { useTranslation } from "react-i18next";
import separator from "../../assets/icons/breadcrumb_slash.svg";

interface Props {
  index: number
  selectedIndex: number
  txt?: string
  menuId?: string
  onClick?: any
  isActive?: boolean
  keepVisible?: boolean
}

const ItemBreadcrumbs: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    index,
    selectedIndex,
    txt,
    menuId,
    onClick,
    isActive,
    keepVisible
  } = props;

  return (
    <div className="breadcrumb-block">

      <img className="separator" src={separator} />

      {menuId && index === 0
        ? (
          <BankIcon
            title={menuId}
            tooltip={txt || (menuId ? t(menuId) : "")}
            onClick={isActive ? onClick : undefined}
          />
        ) : (
          <span
            className={`
            text-item-breadcrumbs
            text-item-breadcrumbs-${selectedIndex === index ? "selected" : "unselected"}
            ${isActive ? "text-item-breadcrumbs-active" : ""}
            ${keepVisible ? "text-item-breadcrumbs-keep-visible" : ""}
          `}
            onClick={isActive ? onClick : undefined}
          >
            {txt || (menuId ? t(menuId) : "")}
          </span>
        )
      }

    </div>
  );
};

export default ItemBreadcrumbs;
