"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldUpdateBozzaBolloACI = exports.setBolloAciBozzaSalvata = exports.setBolloAciSelectedContatto = exports.setBolloAciSalvaBozzaValidation = exports.setBolloAciTarga = exports.setBolloAciTipoVeicolo = exports.resetBolloAci = exports.setBolloAciStep = exports.setBolloAciStepAction = exports.setBolloIdContattoBeneficiario = exports.setBolloAciAffidabile = exports.setBolloAciAgiungiRubrica = exports.setBolloAciNome = exports.setBolloAciFormSubmitted = exports.resetCurrentBozzaAndFormBolloAci = exports.setBolloAciSalva1Click = exports.setBolloAciAlias1Click = exports.setBolloAciRapportoSelezionato = exports.getBolloAciFromBozza = exports.delBozzaBolloAci = exports.getBozzaBolloAci = exports.getBozzeBolloAci = exports.getBozzeBolloAciStart = exports.getInfoAutenticazioneBolloAci = exports.controlloBolloAci = exports.autorizzaBolloAci = exports.getParametriBolloAci = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const bolloAci_selectors_1 = require("./bolloAci.selectors");
const moment = require("moment");
/* get parametri - START */
function getParametriBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_PARAMETRI_START
  };
}
function getParametriBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    !!sendErrorToMatomo && sendErrorToMatomo('BOLLO_ACI', "code_error BOLLO_ACI ".concat(value.status, " / ").concat(value === null || value === void 0 ? void 0 : value.message, " / ").concat(value === null || value === void 0 ? void 0 : value.timestamp), 'ESITO_DISPOSITIVA');
  };
}
function getParametriPerRipetiBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_PARAMETRI_PER_RIPETI_SUCCESS,
    payload
  };
}
const getParametriBolloAci = ripeti => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_mav.json')).then(parametri => {
      dispatch(ripeti ? getParametriPerRipetiBolloAciSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)) : getParametriBolloAciSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersBolloAci().then(response => {
        var _a;
        dispatch(ripeti ? getParametriPerRipetiBolloAciSuccess(response) : getParametriBolloAciSuccess(response));
        ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.BOLLO_AUTO
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriBolloAci = getParametriBolloAci;
/* get parametri - FINE */
/* autorizza - START */
function autorizzaBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_AUTORIZZA_START
  };
}
function autorizzaBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_AUTORIZZA_SUCCESS,
    payload
  };
}
const autorizzaBolloAci = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/autorizza_mav.json')).then(parametri => {
      dispatch(autorizzaBolloAciSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const request = {
      idDispositiva: idDispositiva,
      credenzialeDispositivaDto: credenziale
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeBolloAci(request).then(response => {
        dispatch(autorizzaBolloAciSuccess(response));
        extraArguments.sendEventToMatomo('BOLLO_ACI', "esito dispositiva BOLLO_ACI ".concat(response.stato), 'ESITO_DISPOSITIVA');
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaBolloAci = autorizzaBolloAci;
/* autorizza - FINE */
/* controllo - START */
function controlloBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_CONTROLLO_START
  };
}
function controlloBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_CONTROLLO_SUCCESS,
    payload
  };
}
const controlloBolloAci = () => async (dispatch, getState, extraArguments) => {
  const bolloAci = getState().bolloAci;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(controlloBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/controllo_mav.json')).then(parametri => {
      dispatch(controlloBolloAciSuccess((0, api_rest_1.BolloAciDtoFromJSON)(parametri)));
    });
  } else {
    const controlloBolloAciRequest = {
      bolloAciDto: {
        ...(0, bolloAci_selectors_1.buildBolloAci)(bolloAci),
        bozza: {
          dataUltimaModifica: moment().toDate()
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloBolloAci(controlloBolloAciRequest).then(response => {
        dispatch(controlloBolloAciSuccess(response));
        dispatch((0, general_actions_1.setLoader)(false));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.controlloBolloAci = controlloBolloAci;
/* controllo - FINE */
/* get info autenticazione - START */
function getInfoAutenticazioneBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_INFO_AUTENTICAZIONE_START
  };
}
function getInfoAutenticazioneBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_INFO_AUTENTICAZIONE_SUCCESS,
    payload
  };
}
const getInfoAutenticazioneBolloAci = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoAutenticazioneBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
      dispatch(getInfoAutenticazioneBolloAciSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestParameters = {
      idDispositiva: idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationBolloAci(requestParameters).then(response => {
        dispatch(getInfoAutenticazioneBolloAciSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoAutenticazioneBolloAci = getInfoAutenticazioneBolloAci;
/* get info autenticazione - FINE */
/*  get bozze Bollo ACI - START */
function getBozzeBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_BOZZE_START
  };
}
exports.getBozzeBolloAciStart = getBozzeBolloAciStart;
function getBozzeBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_BOZZE_SUCCESS,
    payload
  };
}
const getBozzeBolloAci = request => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getBozzeBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bozze_mav.json')).then(bozze => {
      dispatch(getBozzeBolloAciSuccess([]));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getBozzeBolloAciRequest = {
      bozzeInDto: request
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftListBolloAci(getBozzeBolloAciRequest).then(response => {
        dispatch(getBozzeBolloAciSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getBozzeBolloAci = getBozzeBolloAci;
/** get bozze Bollo ACI-FINE- */
/* get bozza Bollo ACI - START */
function getBozzaBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_BOZZA_START
  };
}
function getBozzaBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_BOZZA_SUCCESS,
    payload
  };
}
const getBozzaBolloAci = id => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getBozzaBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
      dispatch(getBozzaBolloAciSuccess((0, api_rest_1.BolloAciDtoFromJSON)(bozza)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getBozzaBolloAciRequest = {
      id
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftByIdBolloAci(getBozzaBolloAciRequest).then(response => {
        dispatch(getBozzaBolloAciSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getBozzaBolloAci = getBozzaBolloAci;
/* get bozza Bollo ACI -  FINE */
/* add bozza Bollo ACI - START */
function addBozzaBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_ADD_BOZZA_START
  };
}
function addBozzaBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_ADD_BOZZA_SUCCESS,
    payload
  };
}
const addBozzaBolloAci = () => async (dispatch, getState, extraArguments) => {
  const bolloAci = getState().bolloAci;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(addBozzaBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
      dispatch(addBozzaBolloAciSuccess((0, api_rest_1.BolloAciDtoFromJSON)(bozza)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const addBozzaBolloAcieRequest = {
      bolloAciDto: {
        ...(0, bolloAci_selectors_1.buildBolloAci)(bolloAci),
        bozza: {
          dataUltimaModifica: moment().toDate()
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).createDraftBolloAci(addBozzaBolloAcieRequest).then(response => {
        dispatch(addBozzaBolloAciSuccess(response));
        dispatch((0, general_actions_1.addSnackbar)('bolloACI.nuovaBozzaBolloACISalvata'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
/* add bozza Bollo ACI- FINE */
/* del bozza Bollo ACI - START */
function delBozzaBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_DEL_BOZZA_START
  };
}
function delBozzaBolloAciSuccess(id) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_DEL_BOZZA_SUCCESS,
    payload: id
  };
}
const delBozzaBolloAci = id => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(delBozzaBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
      dispatch(delBozzaBolloAciSuccess(id));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const delBozzaBolloAciRequest = {
      id
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).deleteDraftBolloAci(delBozzaBolloAciRequest).then(() => {
        dispatch(delBozzaBolloAciSuccess(id));
        dispatch((0, general_actions_1.addSnackbar)('bolloACI.bozzaEliminataConSuccesso'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.delBozzaBolloAci = delBozzaBolloAci;
/* del bozza Bollo ACI - FINE */
/* update bozza Bollo ACI - START */
function updateBozzaBolloAciStart() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_UPDATE_BOZZA_START
  };
}
function updateBozzaBolloAciSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_UPDATE_BOZZA_SUCCESS,
    payload
  };
}
const updateBozzaBolloAci = () => async (dispatch, getState, extraArguments) => {
  const bolloAci = getState().bolloAci;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(updateBozzaBolloAciStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
      dispatch(updateBozzaBolloAciSuccess((0, api_rest_1.BolloAciDtoFromJSON)(bozza)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const idCurrentBozza = bolloAci.currentBozza.id || 0;
    const updateBozzaBolloAciRequest = {
      id: idCurrentBozza,
      bolloAciDto: {
        id: idCurrentBozza,
        ...(0, bolloAci_selectors_1.buildBolloAci)(bolloAci),
        bozza: {
          dataUltimaModifica: moment().toDate()
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.BolloAciControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateDraftBolloAci(updateBozzaBolloAciRequest).then(response => {
        dispatch(updateBozzaBolloAciSuccess(response));
        dispatch((0, general_actions_1.addSnackbar)('bolloACI.bozzaBolloACIAggiornata'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
/* update bozza Bollo ACI - FINE */
/** get Bollo ACI from  bozza -START */
function getBolloAciFromBozza(payload) {
  let noBozza = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_GET_BOLLO_ACI_FROM_BOZZA,
    payload,
    noBozza
  };
}
exports.getBolloAciFromBozza = getBolloAciFromBozza;
/* get Bollo ACI from bozza - FINE */
/* set form values - START */
function setBolloAciRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setBolloAciRapportoSelezionato = setBolloAciRapportoSelezionato;
function setBolloAciAlias1Click(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_ALIAS_1CLICK,
    payload: value
  };
}
exports.setBolloAciAlias1Click = setBolloAciAlias1Click;
function setBolloAciSalva1Click(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_SALVA_1CLICK,
    payload: value
  };
}
exports.setBolloAciSalva1Click = setBolloAciSalva1Click;
function resetCurrentBozzaAndFormBolloAci() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_RESET_CURRENT_BOZZA_AND_FORM
  };
}
exports.resetCurrentBozzaAndFormBolloAci = resetCurrentBozzaAndFormBolloAci;
function setBolloAciFormSubmitted(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_FROM_SUBMITTED,
    payload: value
  };
}
exports.setBolloAciFormSubmitted = setBolloAciFormSubmitted;
function setBolloAciNome(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_NOME,
    payload: value
  };
}
exports.setBolloAciNome = setBolloAciNome;
function setBolloAciAgiungiRubrica(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_AGIUNGI_RUBRICA,
    payload: value
  };
}
exports.setBolloAciAgiungiRubrica = setBolloAciAgiungiRubrica;
function setBolloAciAffidabile(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_AFFIDABILE,
    payload: value
  };
}
exports.setBolloAciAffidabile = setBolloAciAffidabile;
function setBolloIdContattoBeneficiario(id) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_ID_CONTATTO_BENEFICIARIO,
    payload: id
  };
}
exports.setBolloIdContattoBeneficiario = setBolloIdContattoBeneficiario;
function setBolloAciStepAction(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_STEP,
    payload: value
  };
}
exports.setBolloAciStepAction = setBolloAciStepAction;
const setBolloAciStep = i => async (dispatch, _, extraArguments) => {
  dispatch(setBolloAciStepAction(i));
  if (extraArguments.platform == 'mobile') {
    dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
  }
};
exports.setBolloAciStep = setBolloAciStep;
function resetBolloAci() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_RESET
  };
}
exports.resetBolloAci = resetBolloAci;
function setBolloAciTipoVeicolo(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_TIPO_VEICOLO,
    payload: value
  };
}
exports.setBolloAciTipoVeicolo = setBolloAciTipoVeicolo;
function setBolloAciTarga(value) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_TARGA,
    payload: value
  };
}
exports.setBolloAciTarga = setBolloAciTarga;
function setBolloAciSalvaBozzaValidation() {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_SALVA_BOZZA_VALIDATION
  };
}
exports.setBolloAciSalvaBozzaValidation = setBolloAciSalvaBozzaValidation;
function setBolloAciSelectedContatto(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_CONTATTO_SELEZIONATO,
    payload
  };
}
exports.setBolloAciSelectedContatto = setBolloAciSelectedContatto;
function setBolloAciBozzaSalvata(b) {
  return {
    type: __1.EReduxActionTypes.BOLLO_ACI_SET_BOZZA_SALVATA,
    payload: b
  };
}
exports.setBolloAciBozzaSalvata = setBolloAciBozzaSalvata;
/* set form values - FINE */
/* set update bozza - INIZIO */
const shouldUpdateBozzaBolloACI = () => async (dispatch, getState, extraArguments) => {
  const state = getState().bolloAci;
  if ((0, bolloAci_selectors_1.checkUpdateBozzaBolloAci)(state)) {
    dispatch(updateBozzaBolloAci());
  } else {
    dispatch(addBozzaBolloAci());
  }
  dispatch(setBolloAciBozzaSalvata(true));
};
exports.shouldUpdateBozzaBolloACI = shouldUpdateBozzaBolloACI;
/* set update bozza - FINE */