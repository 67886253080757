"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BilancioSinteticoDtoToJSON = exports.BilancioSinteticoDtoFromJSONTyped = exports.BilancioSinteticoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BilancioSinteticoDtoFromJSON(json) {
  return BilancioSinteticoDtoFromJSONTyped(json, false);
}
exports.BilancioSinteticoDtoFromJSON = BilancioSinteticoDtoFromJSON;
function BilancioSinteticoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'differenza': !runtime_1.exists(json, 'differenza') ? undefined : json['differenza'],
    'entrate': !runtime_1.exists(json, 'entrate') ? undefined : json['entrate'],
    'uscite': !runtime_1.exists(json, 'uscite') ? undefined : json['uscite']
  };
}
exports.BilancioSinteticoDtoFromJSONTyped = BilancioSinteticoDtoFromJSONTyped;
function BilancioSinteticoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'differenza': value.differenza,
    'entrate': value.entrate,
    'uscite': value.uscite
  };
}
exports.BilancioSinteticoDtoToJSON = BilancioSinteticoDtoToJSON;