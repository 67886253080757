"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.consensiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
  consensi: {},
  formConsensi: {
    step: 0
  },
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSON)({}),
  infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.AutorizzaConsensoPsd2DtoFromJSON)({})
};
const consensiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.ELENCO_CONSENSI_GET_CONSENSI_SUCCESS:
      return {
        ...state,
        consensi: action.payload
      };
    case __1.EReduxActionTypes.AUTORIZZA_CONSENSI_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.GET_INFO_AUTENTICAZIONE_CONSENSI_SUCCESS:
      return {
        ...state,
        infoDispositiva: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.CONTROLLO_DISABILITA_CONSENSI_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.CONSENSI_SET_STEP:
      return {
        ...state,
        formConsensi: {
          ...state.formConsensi,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.CONSENSI_RESET_FORM:
      return {
        ...state,
        formConsensi: initialState.formConsensi,
        esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSON)({}),
        infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
        riepilogo: (0, api_rest_1.AutorizzaConsensoPsd2DtoFromJSON)({})
      };
    case __1.EReduxActionTypes.CONSENSI_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.consensiReducer = consensiReducer;
exports.default = exports.consensiReducer;