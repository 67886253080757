"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PfmTagsAndCategoriesDtoToJSON = exports.PfmTagsAndCategoriesDtoFromJSONTyped = exports.PfmTagsAndCategoriesDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PfmTagsAndCategoriesDtoFromJSON(json) {
  return PfmTagsAndCategoriesDtoFromJSONTyped(json, false);
}
exports.PfmTagsAndCategoriesDtoFromJSON = PfmTagsAndCategoriesDtoFromJSON;
function PfmTagsAndCategoriesDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'categories': !runtime_1.exists(json, 'categories') ? undefined : json['categories'].map(_1.CategoryDtoFromJSON),
    'tags': !runtime_1.exists(json, 'tags') ? undefined : json['tags']
  };
}
exports.PfmTagsAndCategoriesDtoFromJSONTyped = PfmTagsAndCategoriesDtoFromJSONTyped;
function PfmTagsAndCategoriesDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'categories': value.categories === undefined ? undefined : value.categories.map(_1.CategoryDtoToJSON),
    'tags': value.tags
  };
}
exports.PfmTagsAndCategoriesDtoToJSON = PfmTagsAndCategoriesDtoToJSON;