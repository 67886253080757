"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransactionStartInputDtoToJSON = exports.TransactionStartInputDtoFromJSONTyped = exports.TransactionStartInputDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TransactionStartInputDtoFromJSON(json) {
  return TransactionStartInputDtoFromJSONTyped(json, false);
}
exports.TransactionStartInputDtoFromJSON = TransactionStartInputDtoFromJSON;
function TransactionStartInputDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tokenInputValue': !runtime_1.exists(json, 'tokenInputValue') ? undefined : json['tokenInputValue'],
    'lang': !runtime_1.exists(json, 'lang') ? undefined : json['lang'],
    'description': !runtime_1.exists(json, 'description') ? undefined : _1.DescrizioneDtoFromJSON(json['description']),
    'shortDescription': !runtime_1.exists(json, 'shortDescription') ? undefined : _1.DescrizioneDtoFromJSON(json['shortDescription'])
  };
}
exports.TransactionStartInputDtoFromJSONTyped = TransactionStartInputDtoFromJSONTyped;
function TransactionStartInputDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tokenInputValue': value.tokenInputValue,
    'lang': value.lang,
    'description': _1.DescrizioneDtoToJSON(value.description),
    'shortDescription': _1.DescrizioneDtoToJSON(value.shortDescription)
  };
}
exports.TransactionStartInputDtoToJSON = TransactionStartInputDtoToJSON;