"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrecciaRevocaRequestInToJSON = exports.FrecciaRevocaRequestInFromJSONTyped = exports.FrecciaRevocaRequestInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FrecciaRevocaRequestInFromJSON(json) {
  return FrecciaRevocaRequestInFromJSONTyped(json, false);
}
exports.FrecciaRevocaRequestInFromJSON = FrecciaRevocaRequestInFromJSON;
function FrecciaRevocaRequestInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziale']),
    'freccia': !runtime_1.exists(json, 'freccia') ? undefined : _1.FrecciaDtoFromJSON(json['freccia'])
  };
}
exports.FrecciaRevocaRequestInFromJSONTyped = FrecciaRevocaRequestInFromJSONTyped;
function FrecciaRevocaRequestInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'credenziale': _1.CredenzialeDispositivaDtoToJSON(value.credenziale),
    'freccia': _1.FrecciaDtoToJSON(value.freccia)
  };
}
exports.FrecciaRevocaRequestInToJSON = FrecciaRevocaRequestInToJSON;