"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SoglieInDtoToJSON = exports.SoglieInDtoFromJSONTyped = exports.SoglieInDtoFromJSON = void 0;
function SoglieInDtoFromJSON(json) {
  return SoglieInDtoFromJSONTyped(json, false);
}
exports.SoglieInDtoFromJSON = SoglieInDtoFromJSON;
function SoglieInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idServizio': json['idServizio'],
    'idSessioneChiamante': json['idSessioneChiamante'],
    'idStrumento': json['idStrumento'],
    'codiceRapporto': json['codiceRapporto']
  };
}
exports.SoglieInDtoFromJSONTyped = SoglieInDtoFromJSONTyped;
function SoglieInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idServizio': value.idServizio,
    'idSessioneChiamante': value.idSessioneChiamante,
    'idStrumento': value.idStrumento,
    'codiceRapporto': value.codiceRapporto
  };
}
exports.SoglieInDtoToJSON = SoglieInDtoToJSON;