"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticationStartRequestOptionsDtoToJSON = exports.AuthenticationStartRequestOptionsDtoFromJSONTyped = exports.AuthenticationStartRequestOptionsDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AuthenticationStartRequestOptionsDtoFromJSON(json) {
  return AuthenticationStartRequestOptionsDtoFromJSONTyped(json, false);
}
exports.AuthenticationStartRequestOptionsDtoFromJSON = AuthenticationStartRequestOptionsDtoFromJSON;
function AuthenticationStartRequestOptionsDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'app2app': !runtime_1.exists(json, 'app2app') ? undefined : json['app2app'],
    'channel': !runtime_1.exists(json, 'channel') ? undefined : _1.ChannelEnumFromJSON(json['channel']),
    'offline': !runtime_1.exists(json, 'offline') ? undefined : json['offline'],
    'phoneAbroad': !runtime_1.exists(json, 'phoneAbroad') ? undefined : json['phoneAbroad'],
    'rsaMinifiedQrcode': !runtime_1.exists(json, 'rsaMinifiedQrcode') ? undefined : json['rsaMinifiedQrcode']
  };
}
exports.AuthenticationStartRequestOptionsDtoFromJSONTyped = AuthenticationStartRequestOptionsDtoFromJSONTyped;
function AuthenticationStartRequestOptionsDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'app2app': value.app2app,
    'channel': _1.ChannelEnumToJSON(value.channel),
    'offline': value.offline,
    'phoneAbroad': value.phoneAbroad,
    'rsaMinifiedQrcode': value.rsaMinifiedQrcode
  };
}
exports.AuthenticationStartRequestOptionsDtoToJSON = AuthenticationStartRequestOptionsDtoToJSON;