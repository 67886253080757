"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.elencoRapportiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
  rapportiPredefiniti: [],
  rapportiUtente: undefined,
  form: {
    rapportoContoCorrente: '',
    rapportoContoDeposito: '',
    rapportoContoEstero: '',
    rapportoDepositiRisparmio: '',
    rapportoBonifici: '',
    rapportoPagamenti: '',
    rapportoFinanziamentiInfo: '',
    rapportoPolizzeBancaAss: '',
    rapportoTrading: '',
    rapportoTradingInfo: '',
    rapportoSelezionato: (0, api_rest_1.RapportoDtoFromJSON)({})
  },
  adeguataVerificaRapporti: undefined
};
const elencoRapportiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_GET_PREDEFINITI_SUCCESS:
      let rapportoContoCorrente = '';
      let rapportoDepositiRisparmio = '';
      let rapportoBonifici = '';
      let rapportoPagamenti = '';
      let rapportoFinanziamentiInfo = '';
      let rapportoTrading = '';
      let rapportoTradingInfo = '';
      let rapportoPolizzeBancaAss = '';
      action.payload.forEach(el => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        switch (el.codiceCategoria) {
          case "ESTRATTOCONTOITALIA":
            rapportoContoCorrente = ((_a = el.codiceRapportoPredefinito) === null || _a === void 0 ? void 0 : _a.toString()) || '';
            break;
          case "DEPOSITIRISPARMIO":
            rapportoDepositiRisparmio = ((_b = el.codiceRapportoPredefinito) === null || _b === void 0 ? void 0 : _b.toString()) || '';
            break;
          case "BONIFICI":
            rapportoBonifici = ((_c = el.codiceRapportoPredefinito) === null || _c === void 0 ? void 0 : _c.toString()) || '';
            break;
          case "PAGAMENTI":
            rapportoPagamenti = ((_d = el.codiceRapportoPredefinito) === null || _d === void 0 ? void 0 : _d.toString()) || '';
            break;
          case "FINANZIAMENTIINFO":
            rapportoFinanziamentiInfo = ((_e = el.codiceRapportoPredefinito) === null || _e === void 0 ? void 0 : _e.toString()) || '';
            break;
          case "TRADING":
            rapportoTrading = ((_f = el.codiceRapportoPredefinito) === null || _f === void 0 ? void 0 : _f.toString()) || '';
            break;
          case "TRADINGINFO":
            rapportoTradingInfo = ((_g = el.codiceRapportoPredefinito) === null || _g === void 0 ? void 0 : _g.toString()) || '';
            break;
          case "POLIZZEBANCAASS":
            rapportoPolizzeBancaAss = ((_h = el.codiceRapportoPredefinito) === null || _h === void 0 ? void 0 : _h.toString()) || '';
            break;
          default:
            break;
        }
      });
      return {
        ...state,
        rapportiPredefiniti: action.payload,
        form: {
          ...state.form,
          rapportoContoCorrente,
          rapportoDepositiRisparmio,
          rapportoBonifici,
          rapportoPagamenti,
          rapportoFinanziamentiInfo,
          rapportoTrading,
          rapportoTradingInfo,
          rapportoPolizzeBancaAss
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_GET_RAPPORTI_UTENTE_SUCCESS:
      return {
        ...state,
        rapportiUtente: action.payload
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ITALIA:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoContoCorrente: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoContoDeposito: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ESTERO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoContoEstero: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_DEPOSITI_RISPARMIO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoDepositiRisparmio: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_BONIFICI:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoBonifici: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_PAGAMENTI:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoPagamenti: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoTrading: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING_INFO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoTradingInfo: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_FINANZIAMENTI_INFO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoFinanziamentiInfo: action.payload
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_POLIZZE_BANCA_ASS:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoPolizzeBancaAss: action.payload
        }
      };
    case __1.EReduxActionTypes.SELEZIONA_RAPPORTI_RESET_FORM:
      return {
        ...state,
        form: initialState.form
      };
    case __1.EReduxActionTypes.SET_ALIAS_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: {
            ...state.form.rapportoSelezionato,
            alias: action.payload
          }
        }
      };
    case __1.EReduxActionTypes.ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.RAPPORTI_GET_RAPPORTI_ADEGUATA_VERIFICA_SUCCESS:
      return {
        ...state,
        adeguataVerificaRapporti: action.payload
      };
    case __1.EReduxActionTypes.GENERAL_RESET_POST_CAMBIO_LINGUA:
      // action invocata dal cambio lingua
      return {
        ...state,
        rapportiUtente: initialState.rapportiUtente
      };
    case __1.EReduxActionTypes.ENROLLMENT_PSD2_ATTIVA_SUCCESS:
      return {
        ...state,
        rapportiUtente: initialState.rapportiUtente
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.elencoRapportiReducer = elencoRapportiReducer;
exports.default = exports.elencoRapportiReducer;