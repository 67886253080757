"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrdineParametriAvanzatiToJSON = exports.OrdineParametriAvanzatiFromJSONTyped = exports.OrdineParametriAvanzatiFromJSON = void 0;
const runtime_1 = require("../runtime");
function OrdineParametriAvanzatiFromJSON(json) {
  return OrdineParametriAvanzatiFromJSONTyped(json, false);
}
exports.OrdineParametriAvanzatiFromJSON = OrdineParametriAvanzatiFromJSON;
function OrdineParametriAvanzatiFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'esecuzioneDefault': !runtime_1.exists(json, 'esecuzioneDefault') ? undefined : json['esecuzioneDefault'],
    'esecuzione': !runtime_1.exists(json, 'esecuzione') ? undefined : json['esecuzione'],
    'prezzoDefault': !runtime_1.exists(json, 'prezzoDefault') ? undefined : json['prezzoDefault'],
    'prezzo': !runtime_1.exists(json, 'prezzo') ? undefined : json['prezzo'],
    'prezzoStopCondition': !runtime_1.exists(json, 'prezzoStopCondition') ? undefined : json['prezzoStopCondition'],
    'quantitaDefault': !runtime_1.exists(json, 'quantitaDefault') ? undefined : json['quantitaDefault'],
    'quantita': !runtime_1.exists(json, 'quantita') ? undefined : json['quantita'],
    'validitaDefault': !runtime_1.exists(json, 'validitaDefault') ? undefined : json['validitaDefault'],
    'validita': !runtime_1.exists(json, 'validita') ? undefined : json['validita'],
    'validitaDataScadenza': !runtime_1.exists(json, 'validitaDataScadenza') ? undefined : json['validitaDataScadenza'],
    'validitaDataScadenzaDefault': !runtime_1.exists(json, 'validitaDataScadenzaDefault') ? undefined : new Date(json['validitaDataScadenzaDefault']),
    'stopLoss': !runtime_1.exists(json, 'stopLoss') ? undefined : json['stopLoss'],
    'stopConditionDefault': !runtime_1.exists(json, 'stopConditionDefault') ? undefined : json['stopConditionDefault'],
    'stopCondition': !runtime_1.exists(json, 'stopCondition') ? undefined : json['stopCondition'],
    'stopOrderForzaPrezzo': !runtime_1.exists(json, 'stopOrderForzaPrezzo') ? undefined : json['stopOrderForzaPrezzo'],
    'stopOrderPrezzoDefault': !runtime_1.exists(json, 'stopOrderPrezzoDefault') ? undefined : json['stopOrderPrezzoDefault']
  };
}
exports.OrdineParametriAvanzatiFromJSONTyped = OrdineParametriAvanzatiFromJSONTyped;
function OrdineParametriAvanzatiToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'esecuzioneDefault': value.esecuzioneDefault,
    'esecuzione': value.esecuzione,
    'prezzoDefault': value.prezzoDefault,
    'prezzo': value.prezzo,
    'prezzoStopCondition': value.prezzoStopCondition,
    'quantitaDefault': value.quantitaDefault,
    'quantita': value.quantita,
    'validitaDefault': value.validitaDefault,
    'validita': value.validita,
    'validitaDataScadenza': value.validitaDataScadenza,
    'validitaDataScadenzaDefault': value.validitaDataScadenzaDefault === undefined ? undefined : value.validitaDataScadenzaDefault.toISOString(),
    'stopLoss': value.stopLoss,
    'stopConditionDefault': value.stopConditionDefault,
    'stopCondition': value.stopCondition,
    'stopOrderForzaPrezzo': value.stopOrderForzaPrezzo,
    'stopOrderPrezzoDefault': value.stopOrderPrezzoDefault
  };
}
exports.OrdineParametriAvanzatiToJSON = OrdineParametriAvanzatiToJSON;