"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCategorie = exports.getWidgetBilancioSintetico = exports.getWidgetPatrimonio = void 0;
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const api_rest_1 = require("@sparkasse/api-rest");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/* get widget patrimonio - START */
function getWidgetPatrimonioStart() {
  return {
    type: __1.EReduxActionTypes.PFM_GET_WIDGET_PATRIMONIO_START
  };
}
function getWidgetPatrimonioSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PFM_GET_WIDGET_PATRIMONIO_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getWidgetPatrimonio = () => async (dispatch, _, extraArguments) => {
  dispatch(getWidgetPatrimonioStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/widget_patrimonio.json')).then(parametri => {
      dispatch(getWidgetPatrimonioSuccess((0, api_rest_1.WidgetBeniDebitiDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PfmControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaWidgetPatrimonio().then(response => {
        dispatch(getWidgetPatrimonioSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      });
    });
  }
};
exports.getWidgetPatrimonio = getWidgetPatrimonio;
/* get widget patrimonio - FINE */
/* get widget bilancio sintetico - START */
function getWidgetBilancioSinteticoStart() {
  return {
    type: __1.EReduxActionTypes.PFM_GET_WIDGET_BILANCIO_SINTETICO_START
  };
}
function getWidgetBilancioSinteticoSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PFM_GET_WIDGET_BILANCIO_SINTETICO_SUCCESS,
    payload
  };
}
const getWidgetBilancioSintetico = () => async (dispatch, _, extraArguments) => {
  dispatch(getWidgetBilancioSinteticoStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/bilancio_sintetico.json')).then(parametri => {
      dispatch(getWidgetBilancioSinteticoSuccess((0, api_rest_1.BilancioSinteticoDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PfmControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaWidgetBilancioSintetico().then(response => {
        dispatch(getWidgetBilancioSinteticoSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      });
    });
  }
};
exports.getWidgetBilancioSintetico = getWidgetBilancioSintetico;
/* get widget bilancio sintetico - FINE */
/* get categorie - START */
function getCategorieStart() {
  return {
    type: __1.EReduxActionTypes.PFM_GET_CATEGORIE_START
  };
}
function getCategorieSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PFM_GET_CATEGORIE_SUCCESS,
    payload
  };
}
const getCategorie = () => async (dispatch, _, extraArguments) => {
  dispatch(getCategorieStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/categorie_pfm.json')).then(parametri => {
      dispatch(getCategorieSuccess(parametri.map(p => (0, api_rest_1.CategoryDtoFromJSON)(p))));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PfmControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).elencoCategoriePfm().then(response => {
        dispatch(getCategorieSuccess(response.map(c => (0, api_rest_1.CategoryDtoFromJSON)(c))));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      });
    });
  }
};
exports.getCategorie = getCategorie;
/* get categorie - FINE */