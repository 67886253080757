"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RipetitivoRiattivaRequestInToJSON = exports.RipetitivoRiattivaRequestInFromJSONTyped = exports.RipetitivoRiattivaRequestInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RipetitivoRiattivaRequestInFromJSON(json) {
  return RipetitivoRiattivaRequestInFromJSONTyped(json, false);
}
exports.RipetitivoRiattivaRequestInFromJSON = RipetitivoRiattivaRequestInFromJSON;
function RipetitivoRiattivaRequestInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziale']),
    'codiceDisposizione': !runtime_1.exists(json, 'codiceDisposizione') ? undefined : json['codiceDisposizione'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    'dataRiattivazione': !runtime_1.exists(json, 'dataRiattivazione') ? undefined : new Date(json['dataRiattivazione']),
    'dataFine': !runtime_1.exists(json, 'dataFine') ? undefined : new Date(json['dataFine']),
    'dataFineDisposizioneCli': !runtime_1.exists(json, 'dataFineDisposizioneCli') ? undefined : new Date(json['dataFineDisposizioneCli'])
  };
}
exports.RipetitivoRiattivaRequestInFromJSONTyped = RipetitivoRiattivaRequestInFromJSONTyped;
function RipetitivoRiattivaRequestInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'credenziale': _1.CredenzialeDispositivaDtoToJSON(value.credenziale),
    'codiceDisposizione': value.codiceDisposizione,
    'codiceRapporto': value.codiceRapporto,
    'statoDisposizione': value.statoDisposizione,
    'dataRiattivazione': value.dataRiattivazione === undefined ? undefined : value.dataRiattivazione.toISOString(),
    'dataFine': value.dataFine === undefined ? undefined : value.dataFine.toISOString(),
    'dataFineDisposizioneCli': value.dataFineDisposizioneCli === undefined ? undefined : value.dataFineDisposizioneCli.toISOString()
  };
}
exports.RipetitivoRiattivaRequestInToJSON = RipetitivoRiattivaRequestInToJSON;