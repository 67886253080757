"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.widgetsReducer = void 0;
const __1 = require("..");
const WIDGET_SALDO = 'saldo';
const initialState = {
  parametriWidget: undefined,
  widgetsUtente: undefined,
  widgetSaldo: undefined
};
const sortWidgets = widgets => {
  return widgets.filter(w => w.widgetId !== WIDGET_SALDO).sort((a, b) => (a.order || 0) - (b.order || 0));
};
const widgetsReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c, _d;
  switch (action.type) {
    case __1.EReduxActionTypes.GET_PARAMETRI_WIDGET_SUCCESS:
      return {
        ...state,
        parametriWidget: {
          ...action.payload,
          userWidget: sortWidgets(action.payload.userWidget || []),
          appWidget: sortWidgets(action.payload.appWidget || [])
        },
        widgetSaldo: ((_a = action.payload.userWidget) === null || _a === void 0 ? void 0 : _a.find(w => w.widgetId === WIDGET_SALDO)) || ((_b = action.payload.appWidget) === null || _b === void 0 ? void 0 : _b.find(w => w.widgetId === WIDGET_SALDO))
      };
    case __1.EReduxActionTypes.GET_WIDGETS_UTENTE_SUCCESS:
      return {
        ...state,
        widgetsUtente: {
          ...state.widgetsUtente,
          widgets: sortWidgets(action.payload.widgets || [])
        },
        widgetSaldo: (_c = action.payload.widgets) === null || _c === void 0 ? void 0 : _c.find(w => w.widgetId === WIDGET_SALDO)
      };
    case __1.EReduxActionTypes.SALVA_WIDGETS_SUCCESS:
      return {
        ...state,
        widgetsUtente: {
          ...state.widgetsUtente,
          widgets: sortWidgets(action.payload.widgets || [])
        },
        parametriWidget: {
          ...state.parametriWidget,
          userWidget: sortWidgets(action.payload.widgets || [])
        },
        widgetSaldo: (_d = action.payload.widgets) === null || _d === void 0 ? void 0 : _d.find(w => w.widgetId === WIDGET_SALDO)
      };
    case __1.EReduxActionTypes.WIDGETS_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.widgetsReducer = widgetsReducer;
exports.default = exports.widgetsReducer;