"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProdottiTopDtoToJSON = exports.ProdottiTopDtoFromJSONTyped = exports.ProdottiTopDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProdottiTopDtoFromJSON(json) {
  return ProdottiTopDtoFromJSONTyped(json, false);
}
exports.ProdottiTopDtoFromJSON = ProdottiTopDtoFromJSON;
function ProdottiTopDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'productIds': !runtime_1.exists(json, 'productIds') ? undefined : json['productIds'],
    'isNdgPresent': !runtime_1.exists(json, 'isNdgPresent') ? undefined : json['isNdgPresent']
  };
}
exports.ProdottiTopDtoFromJSONTyped = ProdottiTopDtoFromJSONTyped;
function ProdottiTopDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'productIds': value.productIds,
    'isNdgPresent': value.isNdgPresent
  };
}
exports.ProdottiTopDtoToJSON = ProdottiTopDtoToJSON;