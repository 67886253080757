"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticationControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AuthenticationControllerApi extends runtime.BaseAPI {
  /**
   * Recupera le informazioni necessarie a SSL Pinning
   */
  getPinningInfoRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/oauth/pinning",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.CertificateInfoOutDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupera le informazioni necessarie a SSL Pinning
   */
  getPinningInfo() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getPinningInfoRaw();
      return yield response.value();
    });
  }
  /**
   * Autenticazione OAuth2, authorize call per ottenere authentication_context. OAuth state opzionale
   */
  oauthAuthorizeMobileRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.oauthAuthorizeIn === null || requestParameters.oauthAuthorizeIn === undefined) {
        throw new runtime.RequiredError('oauthAuthorizeIn', 'Required parameter requestParameters.oauthAuthorizeIn was null or undefined when calling oauthAuthorizeMobile.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/oauth/authenticationcontext",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OauthAuthorizeInToJSON(requestParameters.oauthAuthorizeIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.AutenticationContextDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autenticazione OAuth2, authorize call per ottenere authentication_context. OAuth state opzionale
   */
  oauthAuthorizeMobile(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.oauthAuthorizeMobileRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autenticazione OAuth2 per WEB per avere access token e refresh token a partire dal codegrant, che saranno inclusi nel JWT di risposta
   */
  oauthTokenRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codeGrantReqIn === null || requestParameters.codeGrantReqIn === undefined) {
        throw new runtime.RequiredError('codeGrantReqIn', 'Required parameter requestParameters.codeGrantReqIn was null or undefined when calling oauthToken.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/oauth/token",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CodeGrantReqInToJSON(requestParameters.codeGrantReqIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.JwtReqOutFromJSON(jsonValue));
    });
  }
  /**
   * Autenticazione OAuth2 per WEB per avere access token e refresh token a partire dal codegrant, che saranno inclusi nel JWT di risposta
   */
  oauthToken(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.oauthTokenRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autenticazione OAuth2 per MOBILE per avere access token e refresh token a partire dal sessionId, che saranno inclusi nel JWT di risposta
   */
  oauthTokenMobileRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.sessionReqIn === null || requestParameters.sessionReqIn === undefined) {
        throw new runtime.RequiredError('sessionReqIn', 'Required parameter requestParameters.sessionReqIn was null or undefined when calling oauthTokenMobile.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/oauth/token/mobile",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SessionReqInToJSON(requestParameters.sessionReqIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.JwtReqOutFromJSON(jsonValue));
    });
  }
  /**
   * Autenticazione OAuth2 per MOBILE per avere access token e refresh token a partire dal sessionId, che saranno inclusi nel JWT di risposta
   */
  oauthTokenMobile(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.oauthTokenMobileRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Refresh dell\'access token
   */
  oauthTokenRefreshRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.refreshTokenIn === null || requestParameters.refreshTokenIn === undefined) {
        throw new runtime.RequiredError('refreshTokenIn', 'Required parameter requestParameters.refreshTokenIn was null or undefined when calling oauthTokenRefresh.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/public/oauth/token/refresh",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.RefreshTokenInToJSON(requestParameters.refreshTokenIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.JwtReqOutFromJSON(jsonValue));
    });
  }
  /**
   * Refresh dell\'access token
   */
  oauthTokenRefresh(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.oauthTokenRefreshRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.AuthenticationControllerApi = AuthenticationControllerApi;