"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaldoRapportoDtoToJSON = exports.SaldoRapportoDtoFromJSONTyped = exports.SaldoRapportoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SaldoRapportoDtoFromJSON(json) {
  return SaldoRapportoDtoFromJSONTyped(json, false);
}
exports.SaldoRapportoDtoFromJSON = SaldoRapportoDtoFromJSON;
function SaldoRapportoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporto': _1.ProfiloRapportoDtoFromJSON(json['rapporto']),
    'saldo': !runtime_1.exists(json, 'saldo') ? undefined : _1.SaldoDtoFromJSON(json['saldo'])
  };
}
exports.SaldoRapportoDtoFromJSONTyped = SaldoRapportoDtoFromJSONTyped;
function SaldoRapportoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporto': _1.ProfiloRapportoDtoToJSON(value.rapporto),
    'saldo': _1.SaldoDtoToJSON(value.saldo)
  };
}
exports.SaldoRapportoDtoToJSON = SaldoRapportoDtoToJSON;