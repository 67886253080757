"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElementiDtoToJSON = exports.ElementiDtoFromJSONTyped = exports.ElementiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ElementiDtoFromJSON(json) {
  return ElementiDtoFromJSONTyped(json, false);
}
exports.ElementiDtoFromJSON = ElementiDtoFromJSON;
function ElementiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'contenuto': !runtime_1.exists(json, 'contenuto') ? undefined : json['contenuto'],
    'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
    'mediaType': !runtime_1.exists(json, 'mediaType') ? undefined : json['mediaType'],
    'elementiOrder': !runtime_1.exists(json, 'elementiOrder') ? undefined : json['elementiOrder']
  };
}
exports.ElementiDtoFromJSONTyped = ElementiDtoFromJSONTyped;
function ElementiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'titolo': value.titolo,
    'contenuto': value.contenuto,
    'url': value.url,
    'mediaType': value.mediaType,
    'elementiOrder': value.elementiOrder
  };
}
exports.ElementiDtoToJSON = ElementiDtoToJSON;