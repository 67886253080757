"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MavDtoStatoEnum = exports.MavDtoToJSON = exports.MavDtoFromJSONTyped = exports.MavDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MavDtoFromJSON(json) {
  return MavDtoFromJSONTyped(json, false);
}
exports.MavDtoFromJSON = MavDtoFromJSON;
function MavDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'contoAddebito': json['contoAddebito'],
    'numeroBollettino': json['numeroBollettino'],
    'importo': json['importo'],
    'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : json['condivisione'].map(_1.CondivisioneDtoFromJSON),
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
    'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
    'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
    'datiPagamento': !runtime_1.exists(json, 'datiPagamento') ? undefined : _1.DatiPagamentoCommonDtoFromJSON(json['datiPagamento']),
    'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
    'nativeId': !runtime_1.exists(json, 'nativeId') ? undefined : json['nativeId'],
    'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
    'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
    'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
    'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
    'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
    'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport']
  };
}
exports.MavDtoFromJSONTyped = MavDtoFromJSONTyped;
function MavDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'contoAddebito': value.contoAddebito,
    'numeroBollettino': value.numeroBollettino,
    'importo': value.importo,
    'condivisione': value.condivisione === undefined ? undefined : value.condivisione.map(_1.CondivisioneDtoToJSON),
    'stato': value.stato,
    'revocabile': value.revocabile,
    'bozza': _1.BozzaDtoToJSON(value.bozza),
    'inviato': _1.InviatoDtoToJSON(value.inviato),
    'datiPagamento': _1.DatiPagamentoCommonDtoToJSON(value.datiPagamento),
    'idBozzaCollegata': value.idBozzaCollegata,
    'nativeId': value.nativeId,
    'canale': value.canale,
    'alias': value.alias,
    'contoAddebitoIban': value.contoAddebitoIban,
    'contoAddebitoAlias': value.contoAddebitoAlias,
    'ordinanteIntestazione': value.ordinanteIntestazione,
    'statoReport': value.statoReport
  };
}
exports.MavDtoToJSON = MavDtoToJSON;
/**
* @export
* @enum {string}
*/
var MavDtoStatoEnum;
(function (MavDtoStatoEnum) {
  MavDtoStatoEnum["AUTORIZZATO"] = "AUTORIZZATO";
  MavDtoStatoEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
  MavDtoStatoEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
  MavDtoStatoEnum["IN_CORSO"] = "IN_CORSO";
  MavDtoStatoEnum["INVIATO"] = "INVIATO";
  MavDtoStatoEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
  MavDtoStatoEnum["REVOCATO"] = "REVOCATO";
  MavDtoStatoEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
  MavDtoStatoEnum["BOZZA"] = "BOZZA";
  MavDtoStatoEnum["SOSPESO"] = "SOSPESO";
  MavDtoStatoEnum["STORNATO"] = "STORNATO";
  MavDtoStatoEnum["PAGATO"] = "PAGATO";
  MavDtoStatoEnum["ANNULLATO"] = "ANNULLATO";
})(MavDtoStatoEnum = exports.MavDtoStatoEnum || (exports.MavDtoStatoEnum = {}));