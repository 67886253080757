"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DossierRequestInDtoToJSON = exports.DossierRequestInDtoFromJSONTyped = exports.DossierRequestInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DossierRequestInDtoFromJSON(json) {
  return DossierRequestInDtoFromJSONTyped(json, false);
}
exports.DossierRequestInDtoFromJSON = DossierRequestInDtoFromJSON;
function DossierRequestInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'customerCode': json['customerCode'],
    'accounts': !runtime_1.exists(json, 'accounts') ? undefined : json['accounts'],
    'includeCoi': !runtime_1.exists(json, 'includeCoi') ? undefined : json['includeCoi']
  };
}
exports.DossierRequestInDtoFromJSONTyped = DossierRequestInDtoFromJSONTyped;
function DossierRequestInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'customerCode': value.customerCode,
    'accounts': value.accounts,
    'includeCoi': value.includeCoi
  };
}
exports.DossierRequestInDtoToJSON = DossierRequestInDtoToJSON;