"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tributiLocaliReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const tributiLocali_types_1 = require("./tributiLocali.types");
const initialState = {
  dettaglioTributo: (0, api_rest_1.PagamentoTributiDtoFromJSON)({}),
  elencoTributiInScadenza: (0, api_rest_1.TributiDtoFromJSON)({}),
  elencoTributiContoTerzi: (0, api_rest_1.TributiDtoFromJSON)({}),
  filter: (0, tributiLocali_types_1.FilterTributiLocaliFromJSONTyped)({}),
  form: (0, tributiLocali_types_1.FormTributiLocaliFromJSONTyped)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSON)({}),
  parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
  infoAutenticazione: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  notificaTributi: (0, api_rest_1.NotificaTributiDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.DatiPagamentoTributoDtoFromJSON)({})
};
const tributiLocaliReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_PARAMETRI_SUCCESS:
      return {
        ...initialState,
        dettaglioTributo: state.dettaglioTributo,
        elencoTributiContoTerzi: state.elencoTributiContoTerzi,
        elencoTributiInScadenza: state.elencoTributiInScadenza,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_SCADENZA_SUCCESS:
      return {
        ...state,
        elencoTributiInScadenza: action.payload
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_CONTO_TERZI_SUCCESS:
      return {
        ...state,
        elencoTributiContoTerzi: action.payload
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_NOTIFICA_TRIBUTI_SUCCESS:
      return {
        ...state,
        notificaTributi: action.payload
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_DETTAGLIO:
      return {
        ...state,
        dettaglioTributo: action.payload
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_DETTAGLIO:
      return {
        ...state,
        dettaglioTributo: initialState.dettaglioTributo
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_CONTROLLO_PAGAMENTO_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_RIEPILOGO:
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_AUTORIZZA_PAGAMENTO_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoAutenticazione: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazione, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_ESITO:
      return {
        ...state,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_INFO_AUTENTICAZIONE_PAGAMENTO_SUCCESS:
      return {
        ...state,
        infoAutenticazione: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_FORM_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_PRESA_VISIONE:
      return {
        ...state,
        form: {
          ...state.form,
          presaVisione: action.payload
        }
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_FORM:
      return {
        ...state,
        form: initialState.form
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_CODICE_FISCALE_CONTRIBUENTE:
      return {
        ...state,
        filter: {
          ...state.filter,
          filtriDirty: !!state.filter.codicePagamento || !!action.payload,
          codiceFiscaleContribuente: action.payload,
          codiceFiscaleContribuenteDirty: true,
          codiceFiscaleContribuenteError: (0, helpers_1.validationCodiceFiscale)(action.payload, false)
        }
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_CODICE_PAGAMENTO:
      return {
        ...state,
        filter: {
          ...state.filter,
          filtriDirty: !!state.filter.codiceFiscaleContribuente || !!action.payload,
          codicePagamento: action.payload,
          codicePagamentoDirty: true,
          codicePagamentoError: (0, helpers_1.validationField)(action.payload, action.payload)
        }
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_FILTRI_SUBMITTED:
      return {
        ...state,
        filter: {
          ...state.filter,
          filtriSubmitted: true
        }
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_SCADENZA:
      return {
        ...state,
        elencoTributiInScadenza: initialState.elencoTributiInScadenza
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_CONTO_TERZI:
      return {
        ...state,
        elencoTributiContoTerzi: initialState.elencoTributiContoTerzi
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_FILTRI:
      return {
        ...state,
        filter: initialState.filter
      };
    case __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_NOTIFICA_TRIBUTI:
      return {
        ...state,
        notificaTributi: initialState.notificaTributi
      };
    case __1.EReduxActionTypes.GENERAL_RESET_CACHE_POST_DISPO:
      // action invocata dopo una dispositiva di tipo resetWidgetsDispoFunctions
      return {
        ...state,
        notificaTributi: initialState.notificaTributi,
        parametri: initialState.parametri
      };
    case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS:
      // action invocata tramite pull to refresh
      return {
        ...state,
        notificaTributi: initialState.notificaTributi
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.tributiLocaliReducer = tributiLocaliReducer;