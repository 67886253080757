import BankIcon from "component/Icon/icon.component";
import React from "react";
import { useTranslation } from "react-i18next";
import ItemBreadcrumbs from "./item.breadcrumbs.component";

const Breadcrumbs: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    onAction,
    onGoBack,
    breadcrumbs,
    imageSrc,
    onOpenSpallaDestra
  } = props;

  const breadcrumbIsActive = (index: number, breadcrumbs: Array<iBreadcrumb>) => {
    const { inactive } = breadcrumbs[index];
    return !inactive && index < breadcrumbs.length - 1;
  };

  const handleClick = (index: number, breadcrumbs: Array<iBreadcrumb>) => {
    const { action } = breadcrumbs[index];
    if (action) {
      action();
    } else {
      const { menuId } = breadcrumbs[0];
      if (menuId) {
        document.getElementById(menuId)?.click();
        if (index === 1) {
          const { menuId: menuId1 } = breadcrumbs[1];
          if (menuId1) {
            setTimeout(() => {
              document.getElementById(menuId1)?.click();
            }, 50);
          }
        }
      }
    }
  };

  return (
    <div className="breadcrumbs">

      <div className="breadcrumbs-inner">

        {onGoBack && (
          <div className="breadcrumb-back">
            <BankIcon
              title={"navigazione.indietro.circular"}
              tooltip={t("breadcrumbs.back")}
              onClick={onGoBack}
            />
          </div>
        )}

        {breadcrumbs && breadcrumbs?.length > 0 &&
          <div className="breadcrumb-home">
            <BankIcon
              title={"home.home"}
              tooltip={t("breadcrumbs.home")}
              onClick={onAction ? onAction : undefined}
            />
          </div>
        }

        {breadcrumbs?.map((item: iBreadcrumb, index: number) => (
          <ItemBreadcrumbs
            key={`item-${index}`}
            index={index}
            selectedIndex={breadcrumbs.length - 1}
            txt={item.description}
            menuId={item.menuId}
            keepVisible={!!item.action}
            isActive={breadcrumbIsActive(index, breadcrumbs)}
            onClick={() => { handleClick(index, breadcrumbs) }}
          />
        ))}

      </div>

      <div className="breadcrumb-side">

        {imageSrc &&
          <img src={imageSrc} />
        }

        {onOpenSpallaDestra &&
          <div className="trigger-spalla-destra">
            <BankIcon
              title={"spalla-destra"}
              tooltip={t("common.apriSpallaDestra")}
              onClick={onOpenSpallaDestra}
            />
          </div>
        }

      </div>

    </div>
  );
};

export interface iBreadcrumb {
  description?: string
  menuId?: string
  inactive?: boolean
  action?: () => void
}

interface iProps {
  breadcrumbs?: iBreadcrumb[]
  onAction?: () => void
  onGoBack?: () => void
  imageSrc?: string
  onOpenSpallaDestra?: () => void
}

export type Props = iProps;
export type BreadcrumbsProps = iBreadcrumb;

export default Breadcrumbs;
