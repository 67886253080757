"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RiepilogoPatrimonioDtoToJSON = exports.RiepilogoPatrimonioDtoFromJSONTyped = exports.RiepilogoPatrimonioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RiepilogoPatrimonioDtoFromJSON(json) {
  return RiepilogoPatrimonioDtoFromJSONTyped(json, false);
}
exports.RiepilogoPatrimonioDtoFromJSON = RiepilogoPatrimonioDtoFromJSON;
function RiepilogoPatrimonioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'posizioneDisponibilita': !runtime_1.exists(json, 'posizioneDisponibilita') ? undefined : _1.RiepilogoPatrimonioDisponibilitaDtoFromJSON(json['posizioneDisponibilita']),
    'posizioneLiquiditaList': !runtime_1.exists(json, 'posizioneLiquiditaList') ? undefined : json['posizioneLiquiditaList'].map(_1.PosizioneLiquiditaDtoFromJSON),
    'posizioneInvestimentiList': !runtime_1.exists(json, 'posizioneInvestimentiList') ? undefined : json['posizioneInvestimentiList'].map(_1.PosizioneInvestimentiDtoFromJSON),
    'posizioneAssicurazioniList': !runtime_1.exists(json, 'posizioneAssicurazioniList') ? undefined : json['posizioneAssicurazioniList'].map(_1.PosizioneAssicurazioniDtoFromJSON),
    'posizioneFinanziamentiList': !runtime_1.exists(json, 'posizioneFinanziamentiList') ? undefined : json['posizioneFinanziamentiList'].map(_1.PosizioneFinanziamentiDtoFromJSON),
    'posizioneGestionePatrimonialiList': !runtime_1.exists(json, 'posizioneGestionePatrimonialiList') ? undefined : json['posizioneGestionePatrimonialiList'].map(_1.PosizioneGestionePatrimonialiFromJSON),
    'posizioneDisponibilitaErrore': !runtime_1.exists(json, 'posizioneDisponibilitaErrore') ? undefined : json['posizioneDisponibilitaErrore'],
    'posizioneGestionePatrimonialiErrore': !runtime_1.exists(json, 'posizioneGestionePatrimonialiErrore') ? undefined : json['posizioneGestionePatrimonialiErrore'],
    'posizioneLiquiditaErrore': !runtime_1.exists(json, 'posizioneLiquiditaErrore') ? undefined : json['posizioneLiquiditaErrore'],
    'posizioneInvestimentiErrore': !runtime_1.exists(json, 'posizioneInvestimentiErrore') ? undefined : json['posizioneInvestimentiErrore'],
    'posizioneAssicurazioniErrore': !runtime_1.exists(json, 'posizioneAssicurazioniErrore') ? undefined : json['posizioneAssicurazioniErrore'],
    'posizioneFinanziamentiErrore': !runtime_1.exists(json, 'posizioneFinanziamentiErrore') ? undefined : json['posizioneFinanziamentiErrore'],
    'posizioneDisponibilitaVisibile': !runtime_1.exists(json, 'posizioneDisponibilitaVisibile') ? undefined : json['posizioneDisponibilitaVisibile'],
    'posizioneGestionePatrimonialiVisibile': !runtime_1.exists(json, 'posizioneGestionePatrimonialiVisibile') ? undefined : json['posizioneGestionePatrimonialiVisibile'],
    'posizioneLiquiditaVisibile': !runtime_1.exists(json, 'posizioneLiquiditaVisibile') ? undefined : json['posizioneLiquiditaVisibile'],
    'posizioneInvestimentiVisibile': !runtime_1.exists(json, 'posizioneInvestimentiVisibile') ? undefined : json['posizioneInvestimentiVisibile'],
    'posizioneAssicurazioniVisibile': !runtime_1.exists(json, 'posizioneAssicurazioniVisibile') ? undefined : json['posizioneAssicurazioniVisibile'],
    'posizioneFinanziamentiVisibile': !runtime_1.exists(json, 'posizioneFinanziamentiVisibile') ? undefined : json['posizioneFinanziamentiVisibile'],
    'totaleLiquidita': !runtime_1.exists(json, 'totaleLiquidita') ? undefined : json['totaleLiquidita'],
    'totaleInvestimenti': !runtime_1.exists(json, 'totaleInvestimenti') ? undefined : json['totaleInvestimenti'],
    'totaleFinanziamenti': !runtime_1.exists(json, 'totaleFinanziamenti') ? undefined : json['totaleFinanziamenti'],
    'totaleAssicurazioni': !runtime_1.exists(json, 'totaleAssicurazioni') ? undefined : json['totaleAssicurazioni'],
    'totaleGestionePatrimoniale': !runtime_1.exists(json, 'totaleGestionePatrimoniale') ? undefined : json['totaleGestionePatrimoniale']
  };
}
exports.RiepilogoPatrimonioDtoFromJSONTyped = RiepilogoPatrimonioDtoFromJSONTyped;
function RiepilogoPatrimonioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'posizioneDisponibilita': _1.RiepilogoPatrimonioDisponibilitaDtoToJSON(value.posizioneDisponibilita),
    'posizioneLiquiditaList': value.posizioneLiquiditaList === undefined ? undefined : value.posizioneLiquiditaList.map(_1.PosizioneLiquiditaDtoToJSON),
    'posizioneInvestimentiList': value.posizioneInvestimentiList === undefined ? undefined : value.posizioneInvestimentiList.map(_1.PosizioneInvestimentiDtoToJSON),
    'posizioneAssicurazioniList': value.posizioneAssicurazioniList === undefined ? undefined : value.posizioneAssicurazioniList.map(_1.PosizioneAssicurazioniDtoToJSON),
    'posizioneFinanziamentiList': value.posizioneFinanziamentiList === undefined ? undefined : value.posizioneFinanziamentiList.map(_1.PosizioneFinanziamentiDtoToJSON),
    'posizioneGestionePatrimonialiList': value.posizioneGestionePatrimonialiList === undefined ? undefined : value.posizioneGestionePatrimonialiList.map(_1.PosizioneGestionePatrimonialiToJSON),
    'posizioneDisponibilitaErrore': value.posizioneDisponibilitaErrore,
    'posizioneGestionePatrimonialiErrore': value.posizioneGestionePatrimonialiErrore,
    'posizioneLiquiditaErrore': value.posizioneLiquiditaErrore,
    'posizioneInvestimentiErrore': value.posizioneInvestimentiErrore,
    'posizioneAssicurazioniErrore': value.posizioneAssicurazioniErrore,
    'posizioneFinanziamentiErrore': value.posizioneFinanziamentiErrore,
    'posizioneDisponibilitaVisibile': value.posizioneDisponibilitaVisibile,
    'posizioneGestionePatrimonialiVisibile': value.posizioneGestionePatrimonialiVisibile,
    'posizioneLiquiditaVisibile': value.posizioneLiquiditaVisibile,
    'posizioneInvestimentiVisibile': value.posizioneInvestimentiVisibile,
    'posizioneAssicurazioniVisibile': value.posizioneAssicurazioniVisibile,
    'posizioneFinanziamentiVisibile': value.posizioneFinanziamentiVisibile,
    'totaleLiquidita': value.totaleLiquidita,
    'totaleInvestimenti': value.totaleInvestimenti,
    'totaleFinanziamenti': value.totaleFinanziamenti,
    'totaleAssicurazioni': value.totaleAssicurazioni,
    'totaleGestionePatrimoniale': value.totaleGestionePatrimoniale
  };
}
exports.RiepilogoPatrimonioDtoToJSON = RiepilogoPatrimonioDtoToJSON;