"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResultToJSON = exports.ResultFromJSONTyped = exports.ResultFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const Geometry_1 = require("./Geometry");
const OpeningHours_1 = require("./OpeningHours");
const Photo_1 = require("./Photo");
function ResultFromJSON(json) {
  return ResultFromJSONTyped(json, false);
}
exports.ResultFromJSON = ResultFromJSON;
function ResultFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'geometry': !(0, api_rest_1.exists)(json, 'geometry') ? undefined : (0, Geometry_1.GeometryFromJSON)(json['geometry']),
    'icon': !(0, api_rest_1.exists)(json, 'icon') ? undefined : json['icon'],
    'id': !(0, api_rest_1.exists)(json, 'id') ? undefined : json['id'],
    'name': !(0, api_rest_1.exists)(json, 'name') ? undefined : json['name'],
    'opening_hours': !(0, api_rest_1.exists)(json, 'opening_hours') ? undefined : (0, OpeningHours_1.OpeningHoursFromJSON)(json['opening_hours']),
    'photos': !(0, api_rest_1.exists)(json, 'photos') ? undefined : json['photos'].map(Photo_1.PhotoFromJSON),
    'place_id': !(0, api_rest_1.exists)(json, 'place_id') ? undefined : json['place_id'],
    'reference': !(0, api_rest_1.exists)(json, 'reference') ? undefined : json['reference'],
    'types': !(0, api_rest_1.exists)(json, 'types') ? undefined : json['types'],
    'vicinity': !(0, api_rest_1.exists)(json, 'vicinity') ? undefined : json['vicinity'],
    'formatted_address': !(0, api_rest_1.exists)(json, 'formatted_address') ? undefined : json['formatted_address'],
    'business_status': !(0, api_rest_1.exists)(json, 'business_status') ? undefined : json['business_status'],
    'rating': !(0, api_rest_1.exists)(json, 'rating') ? undefined : json['rating'],
    'user_ratings_total': !(0, api_rest_1.exists)(json, 'user_ratings_total') ? undefined : json['user_ratings_total']
  };
}
exports.ResultFromJSONTyped = ResultFromJSONTyped;
function ResultToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'geometry': value.geometry,
    'icon': value.icon,
    'id': value.id,
    'name': value.name,
    'opening_hours': value.opening_hours,
    'photos': value.photos,
    'place_id': value.place_id,
    'reference': value.reference,
    'types': value.types,
    'vicinity': value.vicinity,
    'formatted_address': value.formatted_address,
    'business_status': value.business_status,
    'rating': value.rating,
    'user_ratings_total': value.user_ratings_total
  };
}
exports.ResultToJSON = ResultToJSON;