"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.polizzaSelezionata = exports.listaPolizzeInvestimento = exports.listaPolizzeAssicurazione = exports.dettaglioPolizza = exports.parametriPolizze = exports.formPolizze = void 0;
const reselect_1 = require("reselect");
const polizzeReducer = state => state;
exports.formPolizze = (0, reselect_1.createSelector)(polizzeReducer, state => {
  return state.form;
});
exports.parametriPolizze = (0, reselect_1.createSelector)(polizzeReducer, state => {
  return state.parametri;
});
exports.dettaglioPolizza = (0, reselect_1.createSelector)(polizzeReducer, state => {
  return state.dettaglio;
});
exports.listaPolizzeAssicurazione = (0, reselect_1.createSelector)(polizzeReducer, state => {
  return state.listaPolizzeAssicurazione;
});
exports.listaPolizzeInvestimento = (0, reselect_1.createSelector)(polizzeReducer, state => {
  return state.listaPolizzeInvestimento;
});
exports.polizzaSelezionata = (0, reselect_1.createSelector)(polizzeReducer, state => {
  return state.form.polizzaSelezionata;
});