"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperazioniOneClickControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class OperazioniOneClickControllerApi extends runtime.BaseAPI {
  /**
   * Autorizzazione di una disposizione di un Operazione OneClick
   */
  autorizzaModificaOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaModificaOneClick.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaModificaOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/oneclick/autorizza/{idDispositiva}/modifica".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di un Operazione OneClick
   */
  autorizzaModificaOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaModificaOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di un bonifico
   */
  autorizzaOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaOneClick.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/oneclick/autorizza/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di un bonifico
   */
  autorizzaOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Eliminazione delle oneclick associate all\'idcontatto
   */
  eliminaContattoOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
        throw new runtime.RequiredError('idContatto', 'Required parameter requestParameters.idContatto was null or undefined when calling eliminaContattoOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/oneclick/eliminadacontatto/{idContatto}".replace("{".concat("idContatto", "}"), encodeURIComponent(String(requestParameters.idContatto))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Eliminazione delle oneclick associate all\'idcontatto
   */
  eliminaContattoOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.eliminaContattoOneClickRaw(requestParameters);
    });
  }
  /**
   * Eliminazione di una oneClick dato l\'id
   */
  eliminaOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling eliminaOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/oneclick/elimina/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Eliminazione di una oneClick dato l\'id
   */
  eliminaOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.eliminaOneClickRaw(requestParameters);
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneModificaOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneModificaOneClick.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/oneclick/infoautenticazione/{idDispositiva}/modifica".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneModificaOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneModificaOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneOneClick.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/oneclick/infoautenticazione/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Modifica Alias OneClick
   */
  modificaAliasOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling modificaAliasOneClick.');
      }
      if (requestParameters.modificaAliasReqDto === null || requestParameters.modificaAliasReqDto === undefined) {
        throw new runtime.RequiredError('modificaAliasReqDto', 'Required parameter requestParameters.modificaAliasReqDto was null or undefined when calling modificaAliasOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/oneclick/aliasmodifica/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ModificaAliasReqDtoToJSON(requestParameters.modificaAliasReqDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OperazioneOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Modifica Alias OneClick
   */
  modificaAliasOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.modificaAliasOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Modifica Operazione OneClick
   */
  modificaOperazioneOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling modificaOperazioneOneClick.');
      }
      if (requestParameters.operazioneOneClickDto === null || requestParameters.operazioneOneClickDto === undefined) {
        throw new runtime.RequiredError('operazioneOneClickDto', 'Required parameter requestParameters.operazioneOneClickDto was null or undefined when calling modificaOperazioneOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/oneclick/modifica/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OperazioneOneClickDtoToJSON(requestParameters.operazioneOneClickDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OperazioneOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Modifica Operazione OneClick
   */
  modificaOperazioneOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.modificaOperazioneOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le operazioni per homepage
   */
  operazioniOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
        throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/oneclick",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le operazioni per homepage
   */
  operazioniOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.operazioniOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri per le OneClick
   */
  parametriOneClickRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/oneclick/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri per le OneClick
   */
  parametriOneClick() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriOneClickRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce le operazioni per homepage
   */
  salvaOneClickRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.operazioneOneClickDto === null || requestParameters.operazioneOneClickDto === undefined) {
        throw new runtime.RequiredError('operazioneOneClickDto', 'Required parameter requestParameters.operazioneOneClickDto was null or undefined when calling salvaOneClick.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/oneclick/salva",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OperazioneOneClickDtoToJSON(requestParameters.operazioneOneClickDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OperazioneOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le operazioni per homepage
   */
  salvaOneClick(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.salvaOneClickRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.OperazioniOneClickControllerApi = OperazioniOneClickControllerApi;