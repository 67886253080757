"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangePasswordRequestDtoToJSON = exports.ChangePasswordRequestDtoFromJSONTyped = exports.ChangePasswordRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ChangePasswordRequestDtoFromJSON(json) {
  return ChangePasswordRequestDtoFromJSONTyped(json, false);
}
exports.ChangePasswordRequestDtoFromJSON = ChangePasswordRequestDtoFromJSON;
function ChangePasswordRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'passwordNew': !runtime_1.exists(json, 'passwordNew') ? undefined : json['passwordNew'],
    'passwordOld': !runtime_1.exists(json, 'passwordOld') ? undefined : json['passwordOld']
  };
}
exports.ChangePasswordRequestDtoFromJSONTyped = ChangePasswordRequestDtoFromJSONTyped;
function ChangePasswordRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'passwordNew': value.passwordNew,
    'passwordOld': value.passwordOld
  };
}
exports.ChangePasswordRequestDtoToJSON = ChangePasswordRequestDtoToJSON;