"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModelloF24DtoToJSON = exports.ModelloF24DtoFromJSONTyped = exports.ModelloF24DtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ModelloF24DtoFromJSON(json) {
  return ModelloF24DtoFromJSONTyped(json, false);
}
exports.ModelloF24DtoFromJSON = ModelloF24DtoFromJSON;
function ModelloF24DtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
    'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
    'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
    'codiceRapporto': json['codiceRapporto'],
    'codiceAzienda': json['codiceAzienda'],
    'codiceCAB': json['codiceCAB'],
    'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : new Date(json['dataInserimento']),
    'dataOperazione': !runtime_1.exists(json, 'dataOperazione') ? undefined : new Date(json['dataOperazione']),
    'dataPagamento': !runtime_1.exists(json, 'dataPagamento') ? undefined : new Date(json['dataPagamento']),
    'stato': json['stato'],
    'revocabile': json['revocabile'],
    'tipologia': json['tipologia'],
    'saldoFinale': json['saldoFinale'],
    'idContattoBeneficiario': !runtime_1.exists(json, 'idContattoBeneficiario') ? undefined : json['idContattoBeneficiario'],
    'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
    'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
    'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
    'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
    'alias1Click': !runtime_1.exists(json, 'alias1Click') ? undefined : json['alias1Click'],
    'contribuente': _1.ContribuenteDtoFromJSON(json['contribuente']),
    'erario': !runtime_1.exists(json, 'erario') ? undefined : _1.SezioneErarioDtoFromJSON(json['erario']),
    'inps': !runtime_1.exists(json, 'inps') ? undefined : _1.SezioneInpsDtoFromJSON(json['inps']),
    'regioni': !runtime_1.exists(json, 'regioni') ? undefined : _1.SezioneRegioniDtoFromJSON(json['regioni']),
    'tributiLocali': !runtime_1.exists(json, 'tributiLocali') ? undefined : _1.SezioneTributiLocaliDtoFromJSON(json['tributiLocali']),
    'entiPrevidenziali': !runtime_1.exists(json, 'entiPrevidenziali') ? undefined : _1.SezioneEntiPrevidenzialiDtoFromJSON(json['entiPrevidenziali']),
    'inail': !runtime_1.exists(json, 'inail') ? undefined : _1.SezioneInailDtoFromJSON(json['inail']),
    'semplificato': !runtime_1.exists(json, 'semplificato') ? undefined : _1.SezioneSemplificatoDtoFromJSON(json['semplificato']),
    'identificativi': !runtime_1.exists(json, 'identificativi') ? undefined : _1.SezioneIdentificativiDtoFromJSON(json['identificativi']),
    'accise': !runtime_1.exists(json, 'accise') ? undefined : _1.SezioneAcciseDtoFromJSON(json['accise']),
    'iud': !runtime_1.exists(json, 'iud') ? undefined : json['iud'],
    'campiF24DelegheRB': !runtime_1.exists(json, 'campiF24DelegheRB') ? undefined : _1.CampiF24DelegheRBDtoFromJSON(json['campiF24DelegheRB']),
    'campiF24SP': !runtime_1.exists(json, 'campiF24SP') ? undefined : _1.CampiF24SPDtoFromJSON(json['campiF24SP']),
    'titolarePagamentoDiverso': !runtime_1.exists(json, 'titolarePagamentoDiverso') ? undefined : json['titolarePagamentoDiverso'],
    'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
    'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
    'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias']
  };
}
exports.ModelloF24DtoFromJSONTyped = ModelloF24DtoFromJSONTyped;
function ModelloF24DtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'idBozzaCollegata': value.idBozzaCollegata,
    'bozza': _1.BozzaDtoToJSON(value.bozza),
    'inviato': _1.InviatoDtoToJSON(value.inviato),
    'codiceRapporto': value.codiceRapporto,
    'codiceAzienda': value.codiceAzienda,
    'codiceCAB': value.codiceCAB,
    'dataInserimento': value.dataInserimento === undefined ? undefined : value.dataInserimento.toISOString(),
    'dataOperazione': value.dataOperazione === undefined ? undefined : value.dataOperazione.toISOString(),
    'dataPagamento': value.dataPagamento === undefined ? undefined : value.dataPagamento.toISOString(),
    'stato': value.stato,
    'revocabile': value.revocabile,
    'tipologia': value.tipologia,
    'saldoFinale': value.saldoFinale,
    'idContattoBeneficiario': value.idContattoBeneficiario,
    'idOneClickCollegata': value.idOneClickCollegata,
    'aggiungiRubrica': value.aggiungiRubrica,
    'aggiungi1click': value.aggiungi1click,
    'aggiungiWhitelist': value.aggiungiWhitelist,
    'alias1Click': value.alias1Click,
    'contribuente': _1.ContribuenteDtoToJSON(value.contribuente),
    'erario': _1.SezioneErarioDtoToJSON(value.erario),
    'inps': _1.SezioneInpsDtoToJSON(value.inps),
    'regioni': _1.SezioneRegioniDtoToJSON(value.regioni),
    'tributiLocali': _1.SezioneTributiLocaliDtoToJSON(value.tributiLocali),
    'entiPrevidenziali': _1.SezioneEntiPrevidenzialiDtoToJSON(value.entiPrevidenziali),
    'inail': _1.SezioneInailDtoToJSON(value.inail),
    'semplificato': _1.SezioneSemplificatoDtoToJSON(value.semplificato),
    'identificativi': _1.SezioneIdentificativiDtoToJSON(value.identificativi),
    'accise': _1.SezioneAcciseDtoToJSON(value.accise),
    'iud': value.iud,
    'campiF24DelegheRB': _1.CampiF24DelegheRBDtoToJSON(value.campiF24DelegheRB),
    'campiF24SP': _1.CampiF24SPDtoToJSON(value.campiF24SP),
    'titolarePagamentoDiverso': value.titolarePagamentoDiverso,
    'ordinanteIntestazione': value.ordinanteIntestazione,
    'contoAddebitoIban': value.contoAddebitoIban,
    'contoAddebitoAlias': value.contoAddebitoAlias
  };
}
exports.ModelloF24DtoToJSON = ModelloF24DtoToJSON;