"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RispostaAutorizzazioneAnatocismoDtoToJSON = exports.RispostaAutorizzazioneAnatocismoDtoFromJSONTyped = exports.RispostaAutorizzazioneAnatocismoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RispostaAutorizzazioneAnatocismoDtoFromJSON(json) {
  return RispostaAutorizzazioneAnatocismoDtoFromJSONTyped(json, false);
}
exports.RispostaAutorizzazioneAnatocismoDtoFromJSON = RispostaAutorizzazioneAnatocismoDtoFromJSON;
function RispostaAutorizzazioneAnatocismoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.AnatocismoDtoFromJSON),
    'stampaPersonalizzata': !runtime_1.exists(json, 'stampaPersonalizzata') ? undefined : json['stampaPersonalizzata'],
    'descrizioneIstituto': !runtime_1.exists(json, 'descrizioneIstituto') ? undefined : json['descrizioneIstituto'],
    'descrizioneFiliale': !runtime_1.exists(json, 'descrizioneFiliale') ? undefined : json['descrizioneFiliale'],
    'accettato': !runtime_1.exists(json, 'accettato') ? undefined : json['accettato']
  };
}
exports.RispostaAutorizzazioneAnatocismoDtoFromJSONTyped = RispostaAutorizzazioneAnatocismoDtoFromJSONTyped;
function RispostaAutorizzazioneAnatocismoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.AnatocismoDtoToJSON),
    'stampaPersonalizzata': value.stampaPersonalizzata,
    'descrizioneIstituto': value.descrizioneIstituto,
    'descrizioneFiliale': value.descrizioneFiliale,
    'accettato': value.accettato
  };
}
exports.RispostaAutorizzazioneAnatocismoDtoToJSON = RispostaAutorizzazioneAnatocismoDtoToJSON;