"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfiloAbilitazioneDtoToJSON = exports.ProfiloAbilitazioneDtoFromJSONTyped = exports.ProfiloAbilitazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProfiloAbilitazioneDtoFromJSON(json) {
  return ProfiloAbilitazioneDtoFromJSONTyped(json, false);
}
exports.ProfiloAbilitazioneDtoFromJSON = ProfiloAbilitazioneDtoFromJSON;
function ProfiloAbilitazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'servizio': !runtime_1.exists(json, 'servizio') ? undefined : json['servizio'],
    'codiciRapporto': !runtime_1.exists(json, 'codiciRapporto') ? undefined : json['codiciRapporto']
  };
}
exports.ProfiloAbilitazioneDtoFromJSONTyped = ProfiloAbilitazioneDtoFromJSONTyped;
function ProfiloAbilitazioneDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'servizio': value.servizio,
    'codiciRapporto': value.codiciRapporto
  };
}
exports.ProfiloAbilitazioneDtoToJSON = ProfiloAbilitazioneDtoToJSON;