"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RigaSezioneSemplificatoDtoSaldoEnum = exports.RigaSezioneSemplificatoDtoAccontoEnum = exports.RigaSezioneSemplificatoDtoImmobiliVariatiEnum = exports.RigaSezioneSemplificatoDtoRavvedimentoEnum = exports.RigaSezioneSemplificatoDtoToJSON = exports.RigaSezioneSemplificatoDtoFromJSONTyped = exports.RigaSezioneSemplificatoDtoFromJSON = void 0;
function RigaSezioneSemplificatoDtoFromJSON(json) {
  return RigaSezioneSemplificatoDtoFromJSONTyped(json, false);
}
exports.RigaSezioneSemplificatoDtoFromJSON = RigaSezioneSemplificatoDtoFromJSON;
function RigaSezioneSemplificatoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'sezione': json['sezione'],
    'codiceTributo': json['codiceTributo'],
    'codiceEnte': json['codiceEnte'],
    'ravvedimento': json['ravvedimento'],
    'immobiliVariati': json['immobiliVariati'],
    'acconto': json['acconto'],
    'saldo': json['saldo'],
    'numeroImmobili': json['numeroImmobili'],
    'rateazione': json['rateazione'],
    'annoRiferimento': json['annoRiferimento'],
    'detrazione': json['detrazione'],
    'importoDebito': json['importoDebito'],
    'importoCredito': json['importoCredito']
  };
}
exports.RigaSezioneSemplificatoDtoFromJSONTyped = RigaSezioneSemplificatoDtoFromJSONTyped;
function RigaSezioneSemplificatoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'sezione': value.sezione,
    'codiceTributo': value.codiceTributo,
    'codiceEnte': value.codiceEnte,
    'ravvedimento': value.ravvedimento,
    'immobiliVariati': value.immobiliVariati,
    'acconto': value.acconto,
    'saldo': value.saldo,
    'numeroImmobili': value.numeroImmobili,
    'rateazione': value.rateazione,
    'annoRiferimento': value.annoRiferimento,
    'detrazione': value.detrazione,
    'importoDebito': value.importoDebito,
    'importoCredito': value.importoCredito
  };
}
exports.RigaSezioneSemplificatoDtoToJSON = RigaSezioneSemplificatoDtoToJSON;
/**
* @export
* @enum {string}
*/
var RigaSezioneSemplificatoDtoRavvedimentoEnum;
(function (RigaSezioneSemplificatoDtoRavvedimentoEnum) {
  RigaSezioneSemplificatoDtoRavvedimentoEnum["N"] = "N";
  RigaSezioneSemplificatoDtoRavvedimentoEnum["S"] = "S";
})(RigaSezioneSemplificatoDtoRavvedimentoEnum = exports.RigaSezioneSemplificatoDtoRavvedimentoEnum || (exports.RigaSezioneSemplificatoDtoRavvedimentoEnum = {}));
/**
* @export
* @enum {string}
*/
var RigaSezioneSemplificatoDtoImmobiliVariatiEnum;
(function (RigaSezioneSemplificatoDtoImmobiliVariatiEnum) {
  RigaSezioneSemplificatoDtoImmobiliVariatiEnum["N"] = "N";
  RigaSezioneSemplificatoDtoImmobiliVariatiEnum["S"] = "S";
})(RigaSezioneSemplificatoDtoImmobiliVariatiEnum = exports.RigaSezioneSemplificatoDtoImmobiliVariatiEnum || (exports.RigaSezioneSemplificatoDtoImmobiliVariatiEnum = {}));
/**
* @export
* @enum {string}
*/
var RigaSezioneSemplificatoDtoAccontoEnum;
(function (RigaSezioneSemplificatoDtoAccontoEnum) {
  RigaSezioneSemplificatoDtoAccontoEnum["N"] = "N";
  RigaSezioneSemplificatoDtoAccontoEnum["S"] = "S";
})(RigaSezioneSemplificatoDtoAccontoEnum = exports.RigaSezioneSemplificatoDtoAccontoEnum || (exports.RigaSezioneSemplificatoDtoAccontoEnum = {}));
/**
* @export
* @enum {string}
*/
var RigaSezioneSemplificatoDtoSaldoEnum;
(function (RigaSezioneSemplificatoDtoSaldoEnum) {
  RigaSezioneSemplificatoDtoSaldoEnum["N"] = "N";
  RigaSezioneSemplificatoDtoSaldoEnum["S"] = "S";
})(RigaSezioneSemplificatoDtoSaldoEnum = exports.RigaSezioneSemplificatoDtoSaldoEnum || (exports.RigaSezioneSemplificatoDtoSaldoEnum = {}));