"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiFilialeDtoToJSON = exports.DatiFilialeDtoFromJSONTyped = exports.DatiFilialeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiFilialeDtoFromJSON(json) {
  return DatiFilialeDtoFromJSONTyped(json, false);
}
exports.DatiFilialeDtoFromJSON = DatiFilialeDtoFromJSON;
function DatiFilialeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'consulente': !runtime_1.exists(json, 'consulente') ? undefined : _1.ConsulenteDtoFromJSON(json['consulente']),
    'filiale': !runtime_1.exists(json, 'filiale') ? undefined : _1.FilialeDtoFromJSON(json['filiale'])
  };
}
exports.DatiFilialeDtoFromJSONTyped = DatiFilialeDtoFromJSONTyped;
function DatiFilialeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'consulente': _1.ConsulenteDtoToJSON(value.consulente),
    'filiale': _1.FilialeDtoToJSON(value.filiale)
  };
}
exports.DatiFilialeDtoToJSON = DatiFilialeDtoToJSON;