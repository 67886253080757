"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SezioneSemplificatoDtoToJSON = exports.SezioneSemplificatoDtoFromJSONTyped = exports.SezioneSemplificatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SezioneSemplificatoDtoFromJSON(json) {
  return SezioneSemplificatoDtoFromJSONTyped(json, false);
}
exports.SezioneSemplificatoDtoFromJSON = SezioneSemplificatoDtoFromJSON;
function SezioneSemplificatoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceAtto': !runtime_1.exists(json, 'codiceAtto') ? undefined : json['codiceAtto'],
    'codiceUfficio': !runtime_1.exists(json, 'codiceUfficio') ? undefined : json['codiceUfficio'],
    'identificativoOperazione': !runtime_1.exists(json, 'identificativoOperazione') ? undefined : json['identificativoOperazione'],
    'righe': json['righe'].map(_1.RigaSezioneSemplificatoDtoFromJSON),
    'totalSemplificato': !runtime_1.exists(json, 'totalSemplificato') ? undefined : json['totalSemplificato']
  };
}
exports.SezioneSemplificatoDtoFromJSONTyped = SezioneSemplificatoDtoFromJSONTyped;
function SezioneSemplificatoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceAtto': value.codiceAtto,
    'codiceUfficio': value.codiceUfficio,
    'identificativoOperazione': value.identificativoOperazione,
    'righe': value.righe.map(_1.RigaSezioneSemplificatoDtoToJSON),
    'totalSemplificato': value.totalSemplificato
  };
}
exports.SezioneSemplificatoDtoToJSON = SezioneSemplificatoDtoToJSON;