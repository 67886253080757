"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PreloginControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PreloginControllerApi extends runtime.BaseAPI {
  /**
   * Restituisce contatti
   */
  contattiPreloginRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      if (requestParameters.lingua !== undefined) {
        queryParameters['lingua'] = requestParameters.lingua;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/prelogin/contatti",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.NumeroContattiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce contatti
   */
  contattiPrelogin(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.contattiPreloginRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le informazioni di prelogin
   */
  preloginRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      if (requestParameters.appVersion !== undefined) {
        queryParameters['appVersion'] = requestParameters.appVersion;
      }
      if (requestParameters.appPlatform !== undefined) {
        queryParameters['appPlatform'] = requestParameters.appPlatform;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/prelogin",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response);
    });
  }
  /**
   * Restituisce le informazioni di prelogin
   */
  prelogin(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.preloginRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Riferimenti utili per blocco carte
   */
  riferimentiBloccoCartePreloginRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      if (requestParameters.lingua !== undefined) {
        queryParameters['lingua'] = requestParameters.lingua;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/public/prelogin/bloccocarte",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.BloccoCarteDtoFromJSON));
    });
  }
  /**
   * Riferimenti utili per blocco carte
   */
  riferimentiBloccoCartePrelogin(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.riferimentiBloccoCartePreloginRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.PreloginControllerApi = PreloginControllerApi;