"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetVariazioneCanali = exports.downloadCondizioniEconomiche = exports.getCondizioniEconomicheVariazioneCanali = exports.setSelectedItemsVariazioneCanali = exports.esportaElencoCanaliDiComunicazionePDF = exports.esportaElencoCanaliDiComunicazionePDFperTipoNDG = exports.setSelectedAllVariazioneCanali = exports.setAccettatoVariazioneCanali = exports.setStepVariazioneCanali = exports.autorizzaVariazioneCanali = exports.getCorrispondenzeVariazioneCanali = exports.getParametriCorrispondenzaVariazioneCanaliPerModifica = exports.getParametriCorrispondenzaVariazioneCanali = exports.getInfoAutenticazioneVariazioneCanali = exports.getRapportiPredefinitiVariazioneCanali = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const variazioneCanali_selectors_1 = require("./variazioneCanali.selectors");
/* Get Rapporti Predefiniti - START */
function getRapportiPredefinitiVariazioneCanaliStart() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_START
  };
}
function getRapportiPredefinitiVariazioneCanaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
  };
}
const getRapportiPredefinitiVariazioneCanali = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRapportiPredefinitiVariazioneCanaliStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).rapportiPredefiniti().then(response => {
        dispatch(getRapportiPredefinitiVariazioneCanaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRapportiPredefinitiVariazioneCanali = getRapportiPredefinitiVariazioneCanali;
/* Get Rapporti Predefiniti - FINE */
/* Get info autenticazione - START */
function getInfoAutenticazioneVariazioneCanaliStart() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_START
  };
}
function getInfoAutenticazioneVariazioneCanaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_SUCCESS,
    payload
  };
}
const getInfoAutenticazioneVariazioneCanali = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoAutenticazioneVariazioneCanaliStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).infoAutenticazioneCambioCanali().then(response => {
        dispatch(getInfoAutenticazioneVariazioneCanaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoAutenticazioneVariazioneCanali = getInfoAutenticazioneVariazioneCanali;
/* Get info autenticazione - FINE */
/* Get Parametri Corrispondenza - START */
function getParametriCorrispondenzaVariazioneCanaliStart() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_START
  };
}
function getParametriCorrispondenzaVariazioneCanaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_SUCCESS,
    payload
  };
}
function getParametriCorrispondenzaVariazioneCanaliPerModificaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_PER_MODIFICA_SUCCESS,
    payload
  };
}
const getParametriCorrispondenzaVariazioneCanali = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriCorrispondenzaVariazioneCanaliStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriCorrispondenza().then(response => {
        dispatch(getParametriCorrispondenzaVariazioneCanaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriCorrispondenzaVariazioneCanali = getParametriCorrispondenzaVariazioneCanali;
const getParametriCorrispondenzaVariazioneCanaliPerModifica = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriCorrispondenzaVariazioneCanaliStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriCorrispondenza().then(response => {
        dispatch(getParametriCorrispondenzaVariazioneCanaliPerModificaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriCorrispondenzaVariazioneCanaliPerModifica = getParametriCorrispondenzaVariazioneCanaliPerModifica;
/* Get Parametri Corrispondenza - FINE */
/* Get Corrispondenze - START */
function getCorrispondenzeVariazioneCanaliStart() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_CORRISPONDEZE_START
  };
}
function getCorrispondenzeVariazioneCanaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_CORRISPONDEZE_SUCCESS,
    payload
  };
}
const getCorrispondenzeVariazioneCanali = tipoEstrazione => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getCorrispondenzeVariazioneCanaliStart());
  if (extraArguments.mock) {} else {
    const request = {
      tipoEstrazione
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).corrispondenze(request).then(response => {
        dispatch(getCorrispondenzeVariazioneCanaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getCorrispondenzeVariazioneCanali = getCorrispondenzeVariazioneCanali;
/* Get Corrispondenze - FINE */
/* Autorizza - START */
function autorizzaVariazioneCanaliStart() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_AUTORIZZA_START
  };
}
function autorizzaVariazioneCanaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_AUTORIZZA_SUCCESS,
    payload
  };
}
const autorizzaVariazioneCanali = (corrispondenze, credenziale) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaVariazioneCanaliStart());
  if (extraArguments.mock) {} else {
    const items = corrispondenze || (0, variazioneCanali_selectors_1.formVariazioneCanaliAsFiltroCorrispondenze)(getState().variazioneCanali);
    const request = {
      corrispondenzaRequestIn: {
        credenziale,
        corrispondenze: items
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioneAutenticataCambioCanali(request).then(response => {
        dispatch(autorizzaVariazioneCanaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaVariazioneCanali = autorizzaVariazioneCanali;
/* Autorizza - FINE */
/* Set form values - START */
function setStepVariazioneCanali(step) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_STEP,
    payload: step
  };
}
exports.setStepVariazioneCanali = setStepVariazioneCanali;
function setAccettatoVariazioneCanali(accettato) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_ACCETTATO,
    payload: accettato
  };
}
exports.setAccettatoVariazioneCanali = setAccettatoVariazioneCanali;
function setSelectedAllVariazioneCanali(selectedAll) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_SELECTED_ALL,
    payload: selectedAll
  };
}
exports.setSelectedAllVariazioneCanali = setSelectedAllVariazioneCanali;
/* Set form values - FINE */
/* esporta  PDF - START */
function esportaElencoCanaliDiComunicazionePDFStart() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_START
  };
}
function esportaElencoCanaliDiComunicazionePDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_SUCCESS,
    payload
  };
}
const esportaElencoCanaliDiComunicazionePDFperTipoNDG = (share, title, message) => async (dispatch, getState, extraArguments) => {
  (0, exports.esportaElencoCanaliDiComunicazionePDF)(share, title, message, 'NDG')(dispatch, getState, extraArguments);
};
exports.esportaElencoCanaliDiComunicazionePDFperTipoNDG = esportaElencoCanaliDiComunicazionePDFperTipoNDG;
const esportaElencoCanaliDiComunicazionePDF = (share, title, message, tipoEstrazione) => async (dispatch, getState, extraArguments) => {
  dispatch(esportaElencoCanaliDiComunicazionePDFStart());
  dispatch((0, general_actions_1.setLoader)(true));
  if (extraArguments.mock) {} else {
    const request = {
      filtroCorrispondenzaDto: {
        tipoEstrazione: tipoEstrazione
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportElencoCanaliDiComunicazione(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'elenco.pdf', response, share, title, message);
        }
        dispatch(esportaElencoCanaliDiComunicazionePDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaElencoCanaliDiComunicazionePDF = esportaElencoCanaliDiComunicazionePDF;
/* esporta  PDF - FINE */
/* Set Selected items */
function setSelectedItemsVariazioneCanali(item) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_SET_SELECTED_ITEMS,
    payload: item
  };
}
exports.setSelectedItemsVariazioneCanali = setSelectedItemsVariazioneCanali;
/* Get Condizioni Economiche - START */
function getCondizioniEconomicheVariazioneCanaliStart() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_START
  };
}
function getCondizioniEconomicheVariazioneCanaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_SUCCESS,
    payload
  };
}
const getCondizioniEconomicheVariazioneCanali = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getCondizioniEconomicheVariazioneCanaliStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).condizioniEconomiche().then(response => {
        dispatch(getCondizioniEconomicheVariazioneCanaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getCondizioniEconomicheVariazioneCanali = getCondizioniEconomicheVariazioneCanali;
/* Get Condizioni Economiche - FINE */
const downloadCondizioniEconomiche = () => async (dispatch, getState, extraArguments) => {
  var _a;
  const state = getState().variazioneCanali;
  const documento = (0, variazioneCanali_selectors_1.condizioniEconomicheVariazioneCanali)(state);
  if (extraArguments.platform === 'mobile') {
    (0, helpers_1.downloadFile)(extraArguments, documento.nomeAllegato || '', ((_a = documento.allegato) === null || _a === void 0 ? void 0 : _a.data) || '');
  }
};
exports.downloadCondizioniEconomiche = downloadCondizioniEconomiche;
/* Reset all */
function resetVariazioneCanali() {
  return {
    type: __1.EReduxActionTypes.VARIAZIONE_CANALI_RESET
  };
}
exports.resetVariazioneCanali = resetVariazioneCanali;