"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CriteriBilancioInToJSON = exports.CriteriBilancioInFromJSONTyped = exports.CriteriBilancioInFromJSON = void 0;
const runtime_1 = require("../runtime");
function CriteriBilancioInFromJSON(json) {
  return CriteriBilancioInFromJSONTyped(json, false);
}
exports.CriteriBilancioInFromJSON = CriteriBilancioInFromJSON;
function CriteriBilancioInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'dataIniziale': new Date(json['dataIniziale']),
    'dataFinale': new Date(json['dataFinale'])
  };
}
exports.CriteriBilancioInFromJSONTyped = CriteriBilancioInFromJSONTyped;
function CriteriBilancioInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'dataIniziale': value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale.toISOString()
  };
}
exports.CriteriBilancioInToJSON = CriteriBilancioInToJSON;