"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperazioniPreferiteDtoToJSON = exports.OperazioniPreferiteDtoFromJSONTyped = exports.OperazioniPreferiteDtoFromJSON = void 0;
function OperazioniPreferiteDtoFromJSON(json) {
  return OperazioniPreferiteDtoFromJSONTyped(json, false);
}
exports.OperazioniPreferiteDtoFromJSON = OperazioniPreferiteDtoFromJSON;
function OperazioniPreferiteDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'operazioniPreferite': json['operazioniPreferite']
  };
}
exports.OperazioniPreferiteDtoFromJSONTyped = OperazioniPreferiteDtoFromJSONTyped;
function OperazioniPreferiteDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'operazioniPreferite': value.operazioniPreferite
  };
}
exports.OperazioniPreferiteDtoToJSON = OperazioniPreferiteDtoToJSON;