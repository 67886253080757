"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FatturaDtoStatoEnum = exports.FatturaDtoToJSON = exports.FatturaDtoFromJSONTyped = exports.FatturaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FatturaDtoFromJSON(json) {
  return FatturaDtoFromJSONTyped(json, false);
}
exports.FatturaDtoFromJSON = FatturaDtoFromJSON;
function FatturaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'codiceBiller': !runtime_1.exists(json, 'codiceBiller') ? undefined : json['codiceBiller'],
    'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
    'numeroFattura': !runtime_1.exists(json, 'numeroFattura') ? undefined : json['numeroFattura'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'statoDesc': !runtime_1.exists(json, 'statoDesc') ? undefined : json['statoDesc'],
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'importoTotale': !runtime_1.exists(json, 'importoTotale') ? undefined : json['importoTotale'],
    'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
    'idTransazione': !runtime_1.exists(json, 'idTransazione') ? undefined : json['idTransazione'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'riferimentoBolleta': !runtime_1.exists(json, 'riferimentoBolleta') ? undefined : json['riferimentoBolleta'],
    'commissioniBiller': !runtime_1.exists(json, 'commissioniBiller') ? undefined : json['commissioniBiller'],
    'commissioniBanca': !runtime_1.exists(json, 'commissioniBanca') ? undefined : json['commissioniBanca'],
    'ibanBeneficiario': !runtime_1.exists(json, 'ibanBeneficiario') ? undefined : json['ibanBeneficiario'],
    'info': !runtime_1.exists(json, 'info') ? undefined : json['info'],
    'descrizioneBiller': !runtime_1.exists(json, 'descrizioneBiller') ? undefined : json['descrizioneBiller'],
    'link': !runtime_1.exists(json, 'link') ? undefined : json['link'],
    'dataPagamento': !runtime_1.exists(json, 'dataPagamento') ? undefined : new Date(json['dataPagamento']),
    'idPagamento': !runtime_1.exists(json, 'idPagamento') ? undefined : json['idPagamento'],
    'infoAggiuntive': !runtime_1.exists(json, 'infoAggiuntive') ? undefined : json['infoAggiuntive'],
    'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
    'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
    'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
    'riferimento': !runtime_1.exists(json, 'riferimento') ? undefined : json['riferimento'],
    'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : json['condivisione'].map(_1.CondivisioneDtoFromJSON),
    'annoInserimento': !runtime_1.exists(json, 'annoInserimento') ? undefined : json['annoInserimento'],
    'nominativoCliente': !runtime_1.exists(json, 'nominativoCliente') ? undefined : json['nominativoCliente'],
    'indirizzoCliente': !runtime_1.exists(json, 'indirizzoCliente') ? undefined : json['indirizzoCliente'],
    'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
    'citta': !runtime_1.exists(json, 'citta') ? undefined : json['citta'],
    'intestazioneBiller': !runtime_1.exists(json, 'intestazioneBiller') ? undefined : json['intestazioneBiller'],
    'identificativoBolletta': !runtime_1.exists(json, 'identificativoBolletta') ? undefined : json['identificativoBolletta'],
    'numeroBolletta': !runtime_1.exists(json, 'numeroBolletta') ? undefined : json['numeroBolletta'],
    'indicazioniAggiuntive': !runtime_1.exists(json, 'indicazioniAggiuntive') ? undefined : json['indicazioniAggiuntive'],
    'informazioniAggiuntive': !runtime_1.exists(json, 'informazioniAggiuntive') ? undefined : json['informazioniAggiuntive'],
    'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : new Date(json['dataValuta']),
    'listaMoreInfo': !runtime_1.exists(json, 'listaMoreInfo') ? undefined : json['listaMoreInfo'].map(_1.InfoDtoFromJSON),
    'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : new Date(json['dataInserimento']),
    'facilitatore': !runtime_1.exists(json, 'facilitatore') ? undefined : json['facilitatore'],
    'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
    'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
    'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
    'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
    'idContattoBeneficiario': !runtime_1.exists(json, 'idContattoBeneficiario') ? undefined : json['idContattoBeneficiario'],
    'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
    'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
    'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
    'nomeBeneficiario': !runtime_1.exists(json, 'nomeBeneficiario') ? undefined : json['nomeBeneficiario'],
    'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport']
  };
}
exports.FatturaDtoFromJSONTyped = FatturaDtoFromJSONTyped;
function FatturaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'codiceBiller': value.codiceBiller,
    'contoAddebito': value.contoAddebito,
    'numeroFattura': value.numeroFattura,
    'codiceFiscale': value.codiceFiscale,
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'stato': value.stato,
    'statoDesc': value.statoDesc,
    'importo': value.importo,
    'importoTotale': value.importoTotale,
    'canale': value.canale,
    'idTransazione': value.idTransazione,
    'divisa': value.divisa,
    'riferimentoBolleta': value.riferimentoBolleta,
    'commissioniBiller': value.commissioniBiller,
    'commissioniBanca': value.commissioniBanca,
    'ibanBeneficiario': value.ibanBeneficiario,
    'info': value.info,
    'descrizioneBiller': value.descrizioneBiller,
    'link': value.link,
    'dataPagamento': value.dataPagamento === undefined ? undefined : value.dataPagamento.toISOString(),
    'idPagamento': value.idPagamento,
    'infoAggiuntive': value.infoAggiuntive,
    'bozza': _1.BozzaDtoToJSON(value.bozza),
    'inviato': _1.InviatoDtoToJSON(value.inviato),
    'idBozzaCollegata': value.idBozzaCollegata,
    'riferimento': value.riferimento,
    'condivisione': value.condivisione === undefined ? undefined : value.condivisione.map(_1.CondivisioneDtoToJSON),
    'annoInserimento': value.annoInserimento,
    'nominativoCliente': value.nominativoCliente,
    'indirizzoCliente': value.indirizzoCliente,
    'cap': value.cap,
    'citta': value.citta,
    'intestazioneBiller': value.intestazioneBiller,
    'identificativoBolletta': value.identificativoBolletta,
    'numeroBolletta': value.numeroBolletta,
    'indicazioniAggiuntive': value.indicazioniAggiuntive,
    'informazioniAggiuntive': value.informazioniAggiuntive,
    'dataValuta': value.dataValuta === undefined ? undefined : value.dataValuta.toISOString(),
    'listaMoreInfo': value.listaMoreInfo === undefined ? undefined : value.listaMoreInfo.map(_1.InfoDtoToJSON),
    'dataInserimento': value.dataInserimento === undefined ? undefined : value.dataInserimento.toISOString(),
    'facilitatore': value.facilitatore,
    'idOneClickCollegata': value.idOneClickCollegata,
    'aggiungiRubrica': value.aggiungiRubrica,
    'aggiungi1click': value.aggiungi1click,
    'aggiungiWhitelist': value.aggiungiWhitelist,
    'idContattoBeneficiario': value.idContattoBeneficiario,
    'contoAddebitoIban': value.contoAddebitoIban,
    'contoAddebitoAlias': value.contoAddebitoAlias,
    'ordinanteIntestazione': value.ordinanteIntestazione,
    'nomeBeneficiario': value.nomeBeneficiario,
    'statoReport': value.statoReport
  };
}
exports.FatturaDtoToJSON = FatturaDtoToJSON;
/**
* @export
* @enum {string}
*/
var FatturaDtoStatoEnum;
(function (FatturaDtoStatoEnum) {
  FatturaDtoStatoEnum["DA_PAGARE"] = "DA_PAGARE";
  FatturaDtoStatoEnum["PAGATA"] = "PAGATA";
  FatturaDtoStatoEnum["NON_PAGABILE"] = "NON_PAGABILE";
  FatturaDtoStatoEnum["IN_PAGAMENTO"] = "IN_PAGAMENTO";
})(FatturaDtoStatoEnum = exports.FatturaDtoStatoEnum || (exports.FatturaDtoStatoEnum = {}));