"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FvDatiUtenteDtoToJSON = exports.FvDatiUtenteDtoFromJSONTyped = exports.FvDatiUtenteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FvDatiUtenteDtoFromJSON(json) {
  return FvDatiUtenteDtoFromJSONTyped(json, false);
}
exports.FvDatiUtenteDtoFromJSON = FvDatiUtenteDtoFromJSON;
function FvDatiUtenteDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
    'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
    'nomeUtente': !runtime_1.exists(json, 'nomeUtente') ? undefined : json['nomeUtente'],
    'cognomeUtente': !runtime_1.exists(json, 'cognomeUtente') ? undefined : json['cognomeUtente'],
    'dataNascita': !runtime_1.exists(json, 'dataNascita') ? undefined : new Date(json['dataNascita']),
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'ruoloUtente': !runtime_1.exists(json, 'ruoloUtente') ? undefined : json['ruoloUtente'],
    'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    'telefono': !runtime_1.exists(json, 'telefono') ? undefined : json['telefono'],
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'schemaAut': !runtime_1.exists(json, 'schemaAut') ? undefined : json['schemaAut'],
    'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
    'contratto': !runtime_1.exists(json, 'contratto') ? undefined : json['contratto']
  };
}
exports.FvDatiUtenteDtoFromJSONTyped = FvDatiUtenteDtoFromJSONTyped;
function FvDatiUtenteDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceUtente': value.codiceUtente,
    'ndg': value.ndg,
    'nomeUtente': value.nomeUtente,
    'cognomeUtente': value.cognomeUtente,
    'dataNascita': value.dataNascita === undefined ? undefined : value.dataNascita.toISOString(),
    'codiceFiscale': value.codiceFiscale,
    'ruoloUtente': value.ruoloUtente,
    'email': value.email,
    'telefono': value.telefono,
    'intestazione': value.intestazione,
    'schemaAut': value.schemaAut,
    'filiale': value.filiale,
    'contratto': value.contratto
  };
}
exports.FvDatiUtenteDtoToJSON = FvDatiUtenteDtoToJSON;