"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SezioneErarioDtoToJSON = exports.SezioneErarioDtoFromJSONTyped = exports.SezioneErarioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SezioneErarioDtoFromJSON(json) {
  return SezioneErarioDtoFromJSONTyped(json, false);
}
exports.SezioneErarioDtoFromJSON = SezioneErarioDtoFromJSON;
function SezioneErarioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceUfficio': json['codiceUfficio'],
    'codiceAtto': json['codiceAtto'],
    'righe': !runtime_1.exists(json, 'righe') ? undefined : json['righe'].map(_1.RigaSezioneErarioDtoFromJSON),
    'totalErario': !runtime_1.exists(json, 'totalErario') ? undefined : json['totalErario']
  };
}
exports.SezioneErarioDtoFromJSONTyped = SezioneErarioDtoFromJSONTyped;
function SezioneErarioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceUfficio': value.codiceUfficio,
    'codiceAtto': value.codiceAtto,
    'righe': value.righe === undefined ? undefined : value.righe.map(_1.RigaSezioneErarioDtoToJSON),
    'totalErario': value.totalErario
  };
}
exports.SezioneErarioDtoToJSON = SezioneErarioDtoToJSON;