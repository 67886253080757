"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoToJSON = exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSON = EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSON;
function EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.DatiPagamentoTributoDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.DatiPagamentoTributoDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoToJSON = EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoStatoEnum = {}));