"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SogliaNotificheDtoToJSON = exports.SogliaNotificheDtoFromJSONTyped = exports.SogliaNotificheDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SogliaNotificheDtoFromJSON(json) {
  return SogliaNotificheDtoFromJSONTyped(json, false);
}
exports.SogliaNotificheDtoFromJSON = SogliaNotificheDtoFromJSON;
function SogliaNotificheDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'importoSogliaCarte': !runtime_1.exists(json, 'importoSogliaCarte') ? undefined : json['importoSogliaCarte'],
    'importoSogliaConto': !runtime_1.exists(json, 'importoSogliaConto') ? undefined : json['importoSogliaConto'],
    'gestioneAlertSaldoConto': !runtime_1.exists(json, 'gestioneAlertSaldoConto') ? undefined : json['gestioneAlertSaldoConto'],
    'condizioneAlertSaldoConto': !runtime_1.exists(json, 'condizioneAlertSaldoConto') ? undefined : json['condizioneAlertSaldoConto']
  };
}
exports.SogliaNotificheDtoFromJSONTyped = SogliaNotificheDtoFromJSONTyped;
function SogliaNotificheDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'importoSogliaCarte': value.importoSogliaCarte,
    'importoSogliaConto': value.importoSogliaConto,
    'gestioneAlertSaldoConto': value.gestioneAlertSaldoConto,
    'condizioneAlertSaldoConto': value.condizioneAlertSaldoConto
  };
}
exports.SogliaNotificheDtoToJSON = SogliaNotificheDtoToJSON;