"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilialeDtoToJSON = exports.FilialeDtoFromJSONTyped = exports.FilialeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FilialeDtoFromJSON(json) {
  return FilialeDtoFromJSONTyped(json, false);
}
exports.FilialeDtoFromJSON = FilialeDtoFromJSON;
function FilialeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceLingua': !runtime_1.exists(json, 'codiceLingua') ? undefined : json['codiceLingua'],
    'valoreCodice2000': !runtime_1.exists(json, 'valoreCodice2000') ? undefined : json['valoreCodice2000'],
    'nomeFiliale': !runtime_1.exists(json, 'nomeFiliale') ? undefined : json['nomeFiliale'],
    'emailFiliale': !runtime_1.exists(json, 'emailFiliale') ? undefined : json['emailFiliale'],
    'telefonoF1': !runtime_1.exists(json, 'telefonoF1') ? undefined : json['telefonoF1'],
    'indirizzoFiliale': !runtime_1.exists(json, 'indirizzoFiliale') ? undefined : json['indirizzoFiliale'],
    'orario1': !runtime_1.exists(json, 'orario1') ? undefined : json['orario1'],
    'orario2': !runtime_1.exists(json, 'orario2') ? undefined : json['orario2'],
    'orario3': !runtime_1.exists(json, 'orario3') ? undefined : json['orario3']
  };
}
exports.FilialeDtoFromJSONTyped = FilialeDtoFromJSONTyped;
function FilialeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceLingua': value.codiceLingua,
    'valoreCodice2000': value.valoreCodice2000,
    'nomeFiliale': value.nomeFiliale,
    'emailFiliale': value.emailFiliale,
    'telefonoF1': value.telefonoF1,
    'indirizzoFiliale': value.indirizzoFiliale,
    'orario1': value.orario1,
    'orario2': value.orario2,
    'orario3': value.orario3
  };
}
exports.FilialeDtoToJSON = FilialeDtoToJSON;