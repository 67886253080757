"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RapportoDtoToJSON = exports.RapportoDtoFromJSONTyped = exports.RapportoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RapportoDtoFromJSON(json) {
  return RapportoDtoFromJSONTyped(json, false);
}
exports.RapportoDtoFromJSON = RapportoDtoFromJSON;
function RapportoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'predefinito': !runtime_1.exists(json, 'predefinito') ? undefined : json['predefinito'],
    'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'servizio': !runtime_1.exists(json, 'servizio') ? undefined : json['servizio'],
    'descrizioneServizio': !runtime_1.exists(json, 'descrizioneServizio') ? undefined : json['descrizioneServizio'],
    'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
    'numeroConto': !runtime_1.exists(json, 'numeroConto') ? undefined : json['numeroConto'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'ibanFormattato': !runtime_1.exists(json, 'ibanFormattato') ? undefined : json['ibanFormattato'],
    'cab': !runtime_1.exists(json, 'cab') ? undefined : json['cab'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'bic': !runtime_1.exists(json, 'bic') ? undefined : json['bic'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'divise': !runtime_1.exists(json, 'divise') ? undefined : json['divise'],
    'categoria': !runtime_1.exists(json, 'categoria') ? undefined : json['categoria'],
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'cointestatari': !runtime_1.exists(json, 'cointestatari') ? undefined : json['cointestatari'],
    'codiceTitoli': !runtime_1.exists(json, 'codiceTitoli') ? undefined : _1.CodiceTitoliDtoFromJSON(json['codiceTitoli']),
    'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
    'circuitoCarta': !runtime_1.exists(json, 'circuitoCarta') ? undefined : json['circuitoCarta'],
    'idCarta': !runtime_1.exists(json, 'idCarta') ? undefined : _1.CartaNexiIdDtoFromJSON(json['idCarta']),
    'codiceRapportoFormattatoPerTipo': !runtime_1.exists(json, 'codiceRapportoFormattatoPerTipo') ? undefined : json['codiceRapportoFormattatoPerTipo'],
    'tipoLegame': !runtime_1.exists(json, 'tipoLegame') ? undefined : json['tipoLegame'],
    'tipoCollegamento': !runtime_1.exists(json, 'tipoCollegamento') ? undefined : json['tipoCollegamento'],
    'isEstinto': !runtime_1.exists(json, 'isEstinto') ? undefined : json['isEstinto'],
    'codiceRapportoFormatoBanca': !runtime_1.exists(json, 'codiceRapportoFormatoBanca') ? undefined : json['codiceRapportoFormatoBanca'],
    'nomeBanca': !runtime_1.exists(json, 'nomeBanca') ? undefined : json['nomeBanca'],
    'cin': !runtime_1.exists(json, 'cin') ? undefined : json['cin'],
    'abi': !runtime_1.exists(json, 'abi') ? undefined : json['abi'],
    'psd2': !runtime_1.exists(json, 'psd2') ? undefined : _1.AttributiRapportoPsd2FromJSON(json['psd2']),
    'alertPsd2': !runtime_1.exists(json, 'alertPsd2') ? undefined : _1.AlertPsd2DtoFromJSON(json['alertPsd2']),
    'rapportoDelega': !runtime_1.exists(json, 'rapportoDelega') ? undefined : json['rapportoDelega'],
    'rapportoCointestato': !runtime_1.exists(json, 'rapportoCointestato') ? undefined : json['rapportoCointestato'],
    'estero': !runtime_1.exists(json, 'estero') ? undefined : json['estero']
  };
}
exports.RapportoDtoFromJSONTyped = RapportoDtoFromJSONTyped;
function RapportoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'predefinito': value.predefinito,
    'alias': value.alias,
    'intestazione': value.intestazione,
    'servizio': value.servizio,
    'descrizioneServizio': value.descrizioneServizio,
    'filiale': value.filiale,
    'numeroConto': value.numeroConto,
    'iban': value.iban,
    'ibanFormattato': value.ibanFormattato,
    'cab': value.cab,
    'codiceFiscale': value.codiceFiscale,
    'bic': value.bic,
    'divisa': value.divisa,
    'divise': value.divise,
    'categoria': value.categoria,
    'stato': value.stato,
    'cointestatari': value.cointestatari,
    'codiceTitoli': _1.CodiceTitoliDtoToJSON(value.codiceTitoli),
    'pan': value.pan,
    'circuitoCarta': value.circuitoCarta,
    'idCarta': _1.CartaNexiIdDtoToJSON(value.idCarta),
    'tipoLegame': value.tipoLegame,
    'tipoCollegamento': value.tipoCollegamento,
    'isEstinto': value.isEstinto,
    'codiceRapportoFormatoBanca': value.codiceRapportoFormatoBanca,
    'nomeBanca': value.nomeBanca,
    'cin': value.cin,
    'abi': value.abi,
    'psd2': _1.AttributiRapportoPsd2ToJSON(value.psd2),
    'alertPsd2': _1.AlertPsd2DtoToJSON(value.alertPsd2),
    'rapportoDelega': value.rapportoDelega,
    'rapportoCointestato': value.rapportoCointestato,
    'estero': value.estero
  };
}
exports.RapportoDtoToJSON = RapportoDtoToJSON;