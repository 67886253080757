"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SogliaDaRecuperareDtoToJSON = exports.SogliaDaRecuperareDtoFromJSONTyped = exports.SogliaDaRecuperareDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SogliaDaRecuperareDtoFromJSON(json) {
  return SogliaDaRecuperareDtoFromJSONTyped(json, false);
}
exports.SogliaDaRecuperareDtoFromJSON = SogliaDaRecuperareDtoFromJSON;
function SogliaDaRecuperareDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoSoglia': !runtime_1.exists(json, 'tipoSoglia') ? undefined : json['tipoSoglia'],
    'sogliaMinima': !runtime_1.exists(json, 'sogliaMinima') ? undefined : json['sogliaMinima'],
    'sogliaMassima': !runtime_1.exists(json, 'sogliaMassima') ? undefined : json['sogliaMassima'],
    'sogliaImpostata': !runtime_1.exists(json, 'sogliaImpostata') ? undefined : json['sogliaImpostata']
  };
}
exports.SogliaDaRecuperareDtoFromJSONTyped = SogliaDaRecuperareDtoFromJSONTyped;
function SogliaDaRecuperareDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoSoglia': value.tipoSoglia,
    'sogliaMinima': value.sogliaMinima,
    'sogliaMassima': value.sogliaMassima,
    'sogliaImpostata': value.sogliaImpostata
  };
}
exports.SogliaDaRecuperareDtoToJSON = SogliaDaRecuperareDtoToJSON;