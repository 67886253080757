"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DettagliAppuntamentoPeriodicoDtoTipoDataEnum = exports.DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum = exports.DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum = exports.DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum = exports.DettagliAppuntamentoPeriodicoDtoToJSON = exports.DettagliAppuntamentoPeriodicoDtoFromJSONTyped = exports.DettagliAppuntamentoPeriodicoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DettagliAppuntamentoPeriodicoDtoFromJSON(json) {
  return DettagliAppuntamentoPeriodicoDtoFromJSONTyped(json, false);
}
exports.DettagliAppuntamentoPeriodicoDtoFromJSON = DettagliAppuntamentoPeriodicoDtoFromJSON;
function DettagliAppuntamentoPeriodicoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'periodicita': !runtime_1.exists(json, 'periodicita') ? undefined : json['periodicita'],
    'giornoInizioSettimana': !runtime_1.exists(json, 'giornoInizioSettimana') ? undefined : json['giornoInizioSettimana'],
    'giornoInizioMese': !runtime_1.exists(json, 'giornoInizioMese') ? undefined : json['giornoInizioMese'],
    'dataPrimaOccorrenza': !runtime_1.exists(json, 'dataPrimaOccorrenza') ? undefined : new Date(json['dataPrimaOccorrenza']),
    'dataUltimaOccorrenza': !runtime_1.exists(json, 'dataUltimaOccorrenza') ? undefined : new Date(json['dataUltimaOccorrenza']),
    'intervalloRicorrenza': !runtime_1.exists(json, 'intervalloRicorrenza') ? undefined : json['intervalloRicorrenza'],
    'numeroRicorrenze': !runtime_1.exists(json, 'numeroRicorrenze') ? undefined : json['numeroRicorrenze'],
    'tipoData': !runtime_1.exists(json, 'tipoData') ? undefined : json['tipoData'],
    'giornoSel': !runtime_1.exists(json, 'giornoSel') ? undefined : json['giornoSel'],
    'giorniStacco': !runtime_1.exists(json, 'giorniStacco') ? undefined : json['giorniStacco']
  };
}
exports.DettagliAppuntamentoPeriodicoDtoFromJSONTyped = DettagliAppuntamentoPeriodicoDtoFromJSONTyped;
function DettagliAppuntamentoPeriodicoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'periodicita': value.periodicita,
    'giornoInizioSettimana': value.giornoInizioSettimana,
    'giornoInizioMese': value.giornoInizioMese,
    'dataPrimaOccorrenza': value.dataPrimaOccorrenza === undefined ? undefined : value.dataPrimaOccorrenza.toISOString(),
    'dataUltimaOccorrenza': value.dataUltimaOccorrenza === undefined ? undefined : value.dataUltimaOccorrenza.toISOString(),
    'intervalloRicorrenza': value.intervalloRicorrenza,
    'numeroRicorrenze': value.numeroRicorrenze,
    'tipoData': value.tipoData,
    'giornoSel': value.giornoSel,
    'giorniStacco': value.giorniStacco
  };
}
exports.DettagliAppuntamentoPeriodicoDtoToJSON = DettagliAppuntamentoPeriodicoDtoToJSON;
/**
* @export
* @enum {string}
*/
var DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum;
(function (DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum) {
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["SETTIMANALE"] = "SETTIMANALE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["DECADALE"] = "DECADALE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["BISETTIMANALE"] = "BISETTIMANALE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["MENSILE"] = "MENSILE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["BIMESTRALE"] = "BIMESTRALE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["TRIMESTRALE"] = "TRIMESTRALE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["QUADRIMESTRALE"] = "QUADRIMESTRALE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["SEMESTRALE"] = "SEMESTRALE";
  DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum["ANNUALE"] = "ANNUALE";
})(DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum = exports.DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum || (exports.DettagliAppuntamentoPeriodicoDtoPeriodicitaEnum = {}));
/**
* @export
* @enum {string}
*/
var DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum;
(function (DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum) {
  DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum["LUNEDI"] = "LUNEDI";
  DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum["MARTEDI"] = "MARTEDI";
  DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum["MERCOLEDI"] = "MERCOLEDI";
  DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum["GIOVEDI"] = "GIOVEDI";
  DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum["VENERDI"] = "VENERDI";
  DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum["SABATO"] = "SABATO";
  DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum["DOMENICA"] = "DOMENICA";
})(DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum = exports.DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum || (exports.DettagliAppuntamentoPeriodicoDtoGiornoInizioSettimanaEnum = {}));
/**
* @export
* @enum {string}
*/
var DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum;
(function (DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum) {
  DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum["D"] = "D";
  DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum["N"] = "N";
})(DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum = exports.DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum || (exports.DettagliAppuntamentoPeriodicoDtoIntervalloRicorrenzaEnum = {}));
/**
* @export
* @enum {string}
*/
var DettagliAppuntamentoPeriodicoDtoTipoDataEnum;
(function (DettagliAppuntamentoPeriodicoDtoTipoDataEnum) {
  DettagliAppuntamentoPeriodicoDtoTipoDataEnum["AD"] = "AD";
  DettagliAppuntamentoPeriodicoDtoTipoDataEnum["AC"] = "AC";
})(DettagliAppuntamentoPeriodicoDtoTipoDataEnum = exports.DettagliAppuntamentoPeriodicoDtoTipoDataEnum || (exports.DettagliAppuntamentoPeriodicoDtoTipoDataEnum = {}));