"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * PNS Client API
 * PNS - Push Notification Service: Api per l\'invio di notifiche
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PNSErrorHandlerToJSON = exports.PNSErrorHandlerFromJSONTyped = exports.PNSErrorHandlerFromJSON = void 0;
const runtime_1 = require("../runtime");
function PNSErrorHandlerFromJSON(json) {
  return PNSErrorHandlerFromJSONTyped(json, false);
}
exports.PNSErrorHandlerFromJSON = PNSErrorHandlerFromJSON;
function PNSErrorHandlerFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'statusCode': !runtime_1.exists(json, 'statusCode') ? undefined : json['statusCode']
  };
}
exports.PNSErrorHandlerFromJSONTyped = PNSErrorHandlerFromJSONTyped;
function PNSErrorHandlerToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'message': value.message,
    'statusCode': value.statusCode
  };
}
exports.PNSErrorHandlerToJSON = PNSErrorHandlerToJSON;