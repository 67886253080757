import { ProfiloMenu } from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IDToPath from "../../component/IDToPath";
import SidebarMenuNavigatorLvl3 from "./SidebarMenu-navigator-lvl3";

interface Props {
  items: ProfiloMenu[]
  navigateTo: (path: string) => void
  setMenuExpanded: (bSelect: boolean) => any
  selectedLvl1: number
  setSelectedLvl1: (v: number | null) => void
  selectedLvl2: number | null
  setSelectedLvl2: (v: number | null) => void
};

const SidebarMenuNavigatorLvl2 = (props: Props) => {

  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { t } = useTranslation();

  const {
    items,
    navigateTo,
    setMenuExpanded,
    selectedLvl1,
    setSelectedLvl1,
    selectedLvl2,
    setSelectedLvl2
  } = props;

  const [isLvl3Active, activateLVL3] = useState<boolean>(false);


  const LVL2 =
    items &&
    items[selectedLvl1] &&
    items[selectedLvl1].submenu;

  const clickOnElement = (el: ProfiloMenu, i: number) => {
    if (el.submenu?.length && el.tipoSubmenu !== "tabMenu") {

      activateLVL3(true);
      setSelectedLvl2(i);
      setIsMenuOpened(true)

      if (isMenuOpened) {
        if (i !== selectedLvl2) {
          activateLVL3(true);
          setSelectedLvl2(i);
        } else {
          activateLVL3(false);
          setSelectedLvl2(null);
          setIsMenuOpened(false)
        }

      }
    } else {

      IDToPath(
        el.label,
        navigateTo,
        setMenuExpanded,
        el
      );
      activateLVL3(false);
      setSelectedLvl1(null);
    }
  };

  const clickOnLeftArrow = () => {
    setSelectedLvl1(null);
    setMenuExpanded(true);
  };

  return (
    <>
      <div className={`menu-window ${selectedLvl2 != null ? "menu-window-minimized" : ""}`}>

        <div className="menu-window-header">
          <BankIcon
            title="freccia-sinistra"
            className="frecciaSinistra"
            width={44}
            height={44}
            onClick={clickOnLeftArrow}
          />
          <span
            onClick={clickOnLeftArrow}
          >
            {t(items[selectedLvl1]?.label || "notFound")}
          </span>
        </div>

        <div className="menu-window-body">
          {items?.[selectedLvl1]?.submenu?.map(
            (el: ProfiloMenu, i: number) => {
              return (
                <div
                  key={i}
                  id={el.id || ""}
                  className={`menu-window-element icone-azioni ${i === selectedLvl2 ? "selectedElement-LVL2" : ""}`}
                  onClick={() => clickOnElement(el, i)}
                >

                  <BankIcon
                    title={el.id || "notFound"}
                    white={i === selectedLvl2}
                    active={i !== selectedLvl2}
                  />

                  <div className="menu-element-label">
                    {el.label === "menu.oneclick"
                      ? t("menu.elenco_oneclick")
                      : t(el.label || "notFound")
                    }
                  </div>

                  {!!el.submenu?.length && el.tipoSubmenu !== "tabMenu" && (
                    <span className={`menu-item-corner ${i === selectedLvl2 ? "corner-reverse" : ""}`} />
                  )}

                </div>
              );
            }
          )}
        </div>

      </div>

      {isLvl3Active && LVL2 && items && (
        <SidebarMenuNavigatorLvl3
          items={selectedLvl2 != null && LVL2[selectedLvl2] ? LVL2[selectedLvl2].submenu : undefined}
          submenu={items[selectedLvl1]?.submenu}
          setSelectedLvl1={setSelectedLvl1}
          clickOnElement={clickOnElement}
          selectedLvl2={selectedLvl2}
          setSelectedLvl2={setSelectedLvl2}
        />
      )}

    </>
  );
};

export default SidebarMenuNavigatorLvl2;
