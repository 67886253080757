"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsensoPsd2DtoToJSON = exports.ConsensoPsd2DtoFromJSONTyped = exports.ConsensoPsd2DtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ConsensoPsd2DtoFromJSON(json) {
  return ConsensoPsd2DtoFromJSONTyped(json, false);
}
exports.ConsensoPsd2DtoFromJSON = ConsensoPsd2DtoFromJSON;
function ConsensoPsd2DtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : new Date(json['dataInserimento']),
    'tipoUtente': !runtime_1.exists(json, 'tipoUtente') ? undefined : json['tipoUtente'],
    'abi': !runtime_1.exists(json, 'abi') ? undefined : json['abi'],
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'expiresIn': !runtime_1.exists(json, 'expiresIn') ? undefined : json['expiresIn'],
    'expiresInGg': !runtime_1.exists(json, 'expiresInGg') ? undefined : json['expiresInGg'],
    'user': !runtime_1.exists(json, 'user') ? undefined : json['user'],
    'clientId': !runtime_1.exists(json, 'clientId') ? undefined : json['clientId'],
    'token': !runtime_1.exists(json, 'token') ? undefined : json['token'],
    'organizationName': !runtime_1.exists(json, 'organizationName') ? undefined : json['organizationName']
  };
}
exports.ConsensoPsd2DtoFromJSONTyped = ConsensoPsd2DtoFromJSONTyped;
function ConsensoPsd2DtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'dataInserimento': value.dataInserimento === undefined ? undefined : value.dataInserimento.toISOString(),
    'tipoUtente': value.tipoUtente,
    'abi': value.abi,
    'id': value.id,
    'expiresIn': value.expiresIn,
    'expiresInGg': value.expiresInGg,
    'user': value.user,
    'clientId': value.clientId,
    'token': value.token,
    'organizationName': value.organizationName
  };
}
exports.ConsensoPsd2DtoToJSON = ConsensoPsd2DtoToJSON;