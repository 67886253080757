"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetFormCercaTitoli = exports.setRicercaOffset = exports.setTitoloSelezionato = exports.setShowPreferitoModalError = exports.setTitoliOnlyPreferred = exports.setTitoliRapportoSelezionato = exports.setTitoliAccordionFiltriOpened = exports.setCercaTitoliTipo = exports.setCercaTitoliDesc = exports.setCercaTitoliMercato = exports.setCercaTitoliISIN = exports.checkIsPreferred = exports.rimuoviPreferito = exports.aggiungiPreferito = exports.getCercaTitoli = exports.getParametriCercaTitoli = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const cercaTitoli_selectors_1 = require("./cercaTitoli.selectors");
// Get Parameteri Cerca Titoli Start
function getParametriCercaTitoliStart() {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_GET_FORM_PARAMETERS_START
  };
}
function getParametriCercaTitoliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_GET_FORM_PARAMETERS_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getParametriCercaTitoli = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriCercaTitoliStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriTrading().then(response => {
        dispatch(getParametriCercaTitoliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriCercaTitoli = getParametriCercaTitoli;
// Get Parameteri Cerca Titoli Fine
// Get Titoli Start
function getCercaTitoliStart() {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_GET_TITOLI_START
  };
}
function getCercaTitoliSuccess(payload, offset) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_GET_TITOLI_SUCCESS,
    payload,
    offset
  };
}
const getCercaTitoli = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getCercaTitoliStart());
  if (extraArguments.mock) {} else {
    const request = {
      ricercaTitoliInDto: (0, cercaTitoli_selectors_1.buildFilterCercaTitoli)(getState().titoli)
    };
    if (extraArguments.platform === 'mobile') {
      request.ricercaTitoliInDto.offset = getState().titoli.form.offset;
    }
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).ricercaTitoli(request).then(response => {
        dispatch(getCercaTitoliSuccess(response, request.ricercaTitoliInDto.offset));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getCercaTitoli = getCercaTitoli;
/* aggiungi preferito - START */
function aggiungiPreferitoStart() {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_AGGIUNGI_PREFERITO_START
  };
}
function aggiungiPreferitoSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_AGGIUNGI_PREFERITO_SUCCESS,
    payload
  };
}
const aggiungiPreferito = titolo => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(aggiungiPreferitoStart());
  if (extraArguments.mock) {} else {
    const requestAggiungiPreferito = {
      addToPreferredDto: {
        ricercaIn: (0, cercaTitoli_selectors_1.buildFilterCercaTitoli)(getState().titoli),
        titolo: titolo || ((_a = getState().titoli.form) === null || _a === void 0 ? void 0 : _a.titoloSelezionato)
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).aggiungiTitoloPreferito(requestAggiungiPreferito).then(response => {
        dispatch(aggiungiPreferitoSuccess(response));
        dispatch((0, general_actions_1.addSnackbar)('cercaTitoli.cercaTitoliActions.aggiungiPreferitoSucces'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.aggiungiPreferito = aggiungiPreferito;
/* aggiungi preferito - FINE */
/* rimuovo preferito - START */
function rimuoviPreferitoStart() {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_RIMUOVI_PREFERITO_START
  };
}
function rimuoviPreferitoSuccess() {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_RIMUOVI_PREFERITO_SUCCESS
  };
}
const rimuoviPreferito = titolo => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(rimuoviPreferitoStart());
  if (extraArguments.mock) {} else {
    const requestRimuoviPreferito = {
      codiceRapporto: (_b = (_a = getState().titoli.form.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
      codiceIsin: (_c = titolo.codiceIsin) !== null && _c !== void 0 ? _c : ''
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).rimuoviPreferito(requestRimuoviPreferito).then(() => {
        dispatch(rimuoviPreferitoSuccess());
        dispatch((0, general_actions_1.addSnackbar)('cercaTitoli.cercaTitoliActions.rimuoviPreferitoSuccess'));
        dispatch((0, exports.getCercaTitoli)());
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.rimuoviPreferito = rimuoviPreferito;
/* rimuovi preferito - FINE */
/* controllo se isin è gia esistente nei preferiti - START */
function checkIsPreferredStart() {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_IS_PREFERRED_START
  };
}
function checkIsPreferredSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_IS_PREFERRED_SUCCESS,
    payload
  };
}
const checkIsPreferred = titolo => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(checkIsPreferredStart());
  if (extraArguments.mock) {} else {
    const requestIsPreferred = {
      codiceRapporto: (_b = (_a = getState().titoli.form.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
      codiceIsin: (_c = titolo.codiceIsin) !== null && _c !== void 0 ? _c : ''
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).isPreferredTitle(requestIsPreferred).then(response => {
        dispatch(checkIsPreferredSuccess(response));
        if (response.toString() === 'true') {
          dispatch(setShowPreferitoModalError(true));
          dispatch(setTitoloSelezionato(titolo));
        } else {
          dispatch((0, exports.aggiungiPreferito)(titolo));
        }
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.checkIsPreferred = checkIsPreferred;
/* controllo se isin è gia esistente nei preferiti - FINE */
/* set form values - START */
function setCercaTitoliISIN(isinValue) {
  return {
    type: __1.EReduxActionTypes.SET_CERCA_TITOLI_ISIN,
    payload: isinValue
  };
}
exports.setCercaTitoliISIN = setCercaTitoliISIN;
function setCercaTitoliMercato(mercatoValue) {
  return {
    type: __1.EReduxActionTypes.SET_CERCA_TITOLI_MERCATO,
    payload: mercatoValue
  };
}
exports.setCercaTitoliMercato = setCercaTitoliMercato;
function setCercaTitoliDesc(descValue) {
  return {
    type: __1.EReduxActionTypes.SET_CERCA_TITOLI_DESCRIZIONE,
    payload: descValue
  };
}
exports.setCercaTitoliDesc = setCercaTitoliDesc;
function setCercaTitoliTipo(tipoValue) {
  return {
    type: __1.EReduxActionTypes.SET_CERCA_TITOLI_TIPO,
    payload: tipoValue
  };
}
exports.setCercaTitoliTipo = setCercaTitoliTipo;
function setTitoliAccordionFiltriOpened(opened) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_SET_ACCORDION_FILTRI_OPENED,
    payload: opened
  };
}
exports.setTitoliAccordionFiltriOpened = setTitoliAccordionFiltriOpened;
function setTitoliRapportoSelezionato(rapporto) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_SET_RAPPORTO_SELEZIONATO,
    payload: rapporto
  };
}
exports.setTitoliRapportoSelezionato = setTitoliRapportoSelezionato;
function setTitoliOnlyPreferred(preferred) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_SET_ONLY_PREFERRED,
    payload: preferred
  };
}
exports.setTitoliOnlyPreferred = setTitoliOnlyPreferred;
function setShowPreferitoModalError(show) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_SET_SHOW_PREFERITO_MODAL,
    payload: show
  };
}
exports.setShowPreferitoModalError = setShowPreferitoModalError;
function setTitoloSelezionato(titolo) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_SET_TITOLO_SELEZIONATO,
    payload: titolo
  };
}
exports.setTitoloSelezionato = setTitoloSelezionato;
function setRicercaOffset(offset) {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_SET_OFFSET,
    payload: offset
  };
}
exports.setRicercaOffset = setRicercaOffset;
/* set form values - Fine */
// Reset Form Cerca Titoli
function resetFormCercaTitoli() {
  return {
    type: __1.EReduxActionTypes.CERCA_TITOLI_RESET_FORM
  };
}
exports.resetFormCercaTitoli = resetFormCercaTitoli;
// end Reset Form