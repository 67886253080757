"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CittadinanzaResidenzaDtoToJSON = exports.CittadinanzaResidenzaDtoFromJSONTyped = exports.CittadinanzaResidenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CittadinanzaResidenzaDtoFromJSON(json) {
  return CittadinanzaResidenzaDtoFromJSONTyped(json, false);
}
exports.CittadinanzaResidenzaDtoFromJSON = CittadinanzaResidenzaDtoFromJSON;
function CittadinanzaResidenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoCittadinanza': !runtime_1.exists(json, 'tipoCittadinanza') ? undefined : json['tipoCittadinanza'],
    'codiceCittadinanza': !runtime_1.exists(json, 'codiceCittadinanza') ? undefined : json['codiceCittadinanza'],
    'codiceResidenza': !runtime_1.exists(json, 'codiceResidenza') ? undefined : json['codiceResidenza'],
    'descrizioneCittadinanza': !runtime_1.exists(json, 'descrizioneCittadinanza') ? undefined : json['descrizioneCittadinanza']
  };
}
exports.CittadinanzaResidenzaDtoFromJSONTyped = CittadinanzaResidenzaDtoFromJSONTyped;
function CittadinanzaResidenzaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoCittadinanza': value.tipoCittadinanza,
    'codiceCittadinanza': value.codiceCittadinanza,
    'codiceResidenza': value.codiceResidenza,
    'descrizioneCittadinanza': value.descrizioneCittadinanza
  };
}
exports.CittadinanzaResidenzaDtoToJSON = CittadinanzaResidenzaDtoToJSON;