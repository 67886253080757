"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriBonificoRipetitivoDtoToJSON = exports.ParametriBonificoRipetitivoDtoFromJSONTyped = exports.ParametriBonificoRipetitivoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriBonificoRipetitivoDtoFromJSON(json) {
  return ParametriBonificoRipetitivoDtoFromJSONTyped(json, false);
}
exports.ParametriBonificoRipetitivoDtoFromJSON = ParametriBonificoRipetitivoDtoFromJSON;
function ParametriBonificoRipetitivoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'tipoImporto': !runtime_1.exists(json, 'tipoImporto') ? undefined : json['tipoImporto'],
    'tipoPeriodo': !runtime_1.exists(json, 'tipoPeriodo') ? undefined : json['tipoPeriodo'],
    'periodo': !runtime_1.exists(json, 'periodo') ? undefined : json['periodo'],
    'stati': !runtime_1.exists(json, 'stati') ? undefined : json['stati'],
    'tipoDisposizione': !runtime_1.exists(json, 'tipoDisposizione') ? undefined : json['tipoDisposizione']
  };
}
exports.ParametriBonificoRipetitivoDtoFromJSONTyped = ParametriBonificoRipetitivoDtoFromJSONTyped;
function ParametriBonificoRipetitivoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri,
    'tipoImporto': value.tipoImporto,
    'tipoPeriodo': value.tipoPeriodo,
    'periodo': value.periodo,
    'stati': value.stati,
    'tipoDisposizione': value.tipoDisposizione
  };
}
exports.ParametriBonificoRipetitivoDtoToJSON = ParametriBonificoRipetitivoDtoToJSON;