"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElencoTemplateFilialeDtoToJSON = exports.ElencoTemplateFilialeDtoFromJSONTyped = exports.ElencoTemplateFilialeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ElencoTemplateFilialeDtoFromJSON(json) {
  return ElencoTemplateFilialeDtoFromJSONTyped(json, false);
}
exports.ElencoTemplateFilialeDtoFromJSON = ElencoTemplateFilialeDtoFromJSON;
function ElencoTemplateFilialeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'templates': !runtime_1.exists(json, 'templates') ? undefined : json['templates'].map(_1.TemplateDtoFromJSON),
    'motiviRichiesta': !runtime_1.exists(json, 'motiviRichiesta') ? undefined : json['motiviRichiesta']
  };
}
exports.ElencoTemplateFilialeDtoFromJSONTyped = ElencoTemplateFilialeDtoFromJSONTyped;
function ElencoTemplateFilialeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'templates': value.templates === undefined ? undefined : value.templates.map(_1.TemplateDtoToJSON),
    'motiviRichiesta': value.motiviRichiesta
  };
}
exports.ElencoTemplateFilialeDtoToJSON = ElencoTemplateFilialeDtoToJSON;