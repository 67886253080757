"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriAdeguataVerificaDtoToJSON = exports.ParametriAdeguataVerificaDtoFromJSONTyped = exports.ParametriAdeguataVerificaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriAdeguataVerificaDtoFromJSON(json) {
  return ParametriAdeguataVerificaDtoFromJSONTyped(json, false);
}
exports.ParametriAdeguataVerificaDtoFromJSON = ParametriAdeguataVerificaDtoFromJSON;
function ParametriAdeguataVerificaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri']
  };
}
exports.ParametriAdeguataVerificaDtoFromJSONTyped = ParametriAdeguataVerificaDtoFromJSONTyped;
function ParametriAdeguataVerificaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri
  };
}
exports.ParametriAdeguataVerificaDtoToJSON = ParametriAdeguataVerificaDtoToJSON;