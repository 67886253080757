"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriElencoPagamentiDtoToJSON = exports.ParametriElencoPagamentiDtoFromJSONTyped = exports.ParametriElencoPagamentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriElencoPagamentiDtoFromJSON(json) {
  return ParametriElencoPagamentiDtoFromJSONTyped(json, false);
}
exports.ParametriElencoPagamentiDtoFromJSON = ParametriElencoPagamentiDtoFromJSON;
function ParametriElencoPagamentiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'tipologie': !runtime_1.exists(json, 'tipologie') ? undefined : json['tipologie'],
    'tipologieRappEstinti': !runtime_1.exists(json, 'tipologieRappEstinti') ? undefined : json['tipologieRappEstinti'],
    'stati': !runtime_1.exists(json, 'stati') ? undefined : json['stati']
  };
}
exports.ParametriElencoPagamentiDtoFromJSONTyped = ParametriElencoPagamentiDtoFromJSONTyped;
function ParametriElencoPagamentiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri,
    'tipologie': value.tipologie,
    'tipologieRappEstinti': value.tipologieRappEstinti,
    'stati': value.stati
  };
}
exports.ParametriElencoPagamentiDtoToJSON = ParametriElencoPagamentiDtoToJSON;