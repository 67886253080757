"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrecciaDtoStatoDisposizioneEnum = exports.FrecciaDtoStatoEnum = exports.FrecciaDtoToJSON = exports.FrecciaDtoFromJSONTyped = exports.FrecciaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FrecciaDtoFromJSON(json) {
  return FrecciaDtoFromJSONTyped(json, false);
}
exports.FrecciaDtoFromJSON = FrecciaDtoFromJSON;
function FrecciaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'contoAddebito': json['contoAddebito'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    'datiBollettino': !runtime_1.exists(json, 'datiBollettino') ? undefined : _1.DatiBollettinoDtoFromJSON(json['datiBollettino']),
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
    'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : json['condivisione'].map(_1.CondivisioneDtoFromJSON),
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
    'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
    'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
    'datiPagamento': !runtime_1.exists(json, 'datiPagamento') ? undefined : _1.DatiPagamentoCommonDtoFromJSON(json['datiPagamento']),
    'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
    'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
    'nativeId': !runtime_1.exists(json, 'nativeId') ? undefined : json['nativeId'],
    'dataPrenotazione': !runtime_1.exists(json, 'dataPrenotazione') ? undefined : new Date(json['dataPrenotazione']),
    'statoEP': !runtime_1.exists(json, 'statoEP') ? undefined : json['statoEP'],
    'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
    'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
    'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
    'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
    'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport']
  };
}
exports.FrecciaDtoFromJSONTyped = FrecciaDtoFromJSONTyped;
function FrecciaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'contoAddebito': value.contoAddebito,
    'userId': value.userId,
    'datiBollettino': _1.DatiBollettinoDtoToJSON(value.datiBollettino),
    'importo': value.importo,
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'note': value.note,
    'condivisione': value.condivisione === undefined ? undefined : value.condivisione.map(_1.CondivisioneDtoToJSON),
    'stato': value.stato,
    'revocabile': value.revocabile,
    'bozza': _1.BozzaDtoToJSON(value.bozza),
    'inviato': _1.InviatoDtoToJSON(value.inviato),
    'datiPagamento': _1.DatiPagamentoCommonDtoToJSON(value.datiPagamento),
    'statoDisposizione': value.statoDisposizione,
    'idBozzaCollegata': value.idBozzaCollegata,
    'idOneClickCollegata': value.idOneClickCollegata,
    'nativeId': value.nativeId,
    'dataPrenotazione': value.dataPrenotazione === undefined ? undefined : value.dataPrenotazione.toISOString(),
    'statoEP': value.statoEP,
    'contoAddebitoIban': value.contoAddebitoIban,
    'contoAddebitoAlias': value.contoAddebitoAlias,
    'ordinanteIntestazione': value.ordinanteIntestazione,
    'alias': value.alias,
    'statoReport': value.statoReport
  };
}
exports.FrecciaDtoToJSON = FrecciaDtoToJSON;
/**
* @export
* @enum {string}
*/
var FrecciaDtoStatoEnum;
(function (FrecciaDtoStatoEnum) {
  FrecciaDtoStatoEnum["AUTORIZZATO"] = "AUTORIZZATO";
  FrecciaDtoStatoEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
  FrecciaDtoStatoEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
  FrecciaDtoStatoEnum["IN_CORSO"] = "IN_CORSO";
  FrecciaDtoStatoEnum["INVIATO"] = "INVIATO";
  FrecciaDtoStatoEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
  FrecciaDtoStatoEnum["REVOCATO"] = "REVOCATO";
  FrecciaDtoStatoEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
  FrecciaDtoStatoEnum["BOZZA"] = "BOZZA";
  FrecciaDtoStatoEnum["SOSPESO"] = "SOSPESO";
  FrecciaDtoStatoEnum["STORNATO"] = "STORNATO";
  FrecciaDtoStatoEnum["PAGATO"] = "PAGATO";
  FrecciaDtoStatoEnum["ANNULLATO"] = "ANNULLATO";
})(FrecciaDtoStatoEnum = exports.FrecciaDtoStatoEnum || (exports.FrecciaDtoStatoEnum = {}));
/**
* @export
* @enum {string}
*/
var FrecciaDtoStatoDisposizioneEnum;
(function (FrecciaDtoStatoDisposizioneEnum) {
  FrecciaDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
  FrecciaDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
  FrecciaDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
  FrecciaDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
  FrecciaDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
  FrecciaDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
  FrecciaDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
  FrecciaDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
  FrecciaDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
  FrecciaDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
  FrecciaDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
  FrecciaDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
  FrecciaDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(FrecciaDtoStatoDisposizioneEnum = exports.FrecciaDtoStatoDisposizioneEnum || (exports.FrecciaDtoStatoDisposizioneEnum = {}));