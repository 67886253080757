"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filtriApplicatiCartaRicaricabile = exports.filtriRicaricaCarta = exports.parametriRicaricaMovimenti = exports.movimentiRicaricaCarta = exports.infoDispositivaRicaricaCarta = exports.esitoRicaricaCarta = exports.oneclickRicaricaCarta = exports.disableRicaricaCartaSalvaBozza = exports.currentBozzaRicaricaCarta = exports.bozzeRicaricaCarta = exports.checkUpdateBozzaRicaricaCarta = exports.riepilogoRicaricaCarta = exports.buildRicaricaCarta = exports.formRicaricaCartaValid = exports.formRicaricaCarta = exports.parametriRicaricaCarta = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const moment = require("moment");
const ricaricaCartaReducer = state => state;
exports.parametriRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.parametri;
});
exports.formRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.formRicaricaCarta;
});
exports.formRicaricaCartaValid = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  let formValid = false;
  if (!state.formRicaricaCarta.importoError && !!state.formRicaricaCarta.pan) {
    formValid = true;
  }
  return formValid;
});
exports.buildRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  var _a, _b;
  return {
    contoAddebito: ((_b = (_a = state.formRicaricaCarta.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
    dataOraCompilazione: moment().toDate(),
    importo: (0, helpers_1.formattedNumberToNumber)(state.formRicaricaCarta.importo),
    aggiungi1click: state.formRicaricaCarta.salva1Click,
    pan: state.formRicaricaCarta.pan,
    tipoCarta: state.formRicaricaCarta.tipoCarta,
    idBozzaCollegata: state.currentBozza.id,
    idOneClickCollegata: state.formRicaricaCarta.idOneClickCollegata
    //alias1Click: state.form.alias1Click,
  };
});
exports.riepilogoRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.riepilogoRicaricaCarta;
});
exports.checkUpdateBozzaRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  var _a, _b, _c, _d, _e;
  let check = false;
  if (((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id) && (((_b = state.formRicaricaCarta) === null || _b === void 0 ? void 0 : _b.pan) !== ((_c = state.currentBozza) === null || _c === void 0 ? void 0 : _c.pan) || ((_d = state.formRicaricaCarta) === null || _d === void 0 ? void 0 : _d.importo) !== ((_e = state.currentBozza) === null || _e === void 0 ? void 0 : _e.importo))) {
    check = true;
  }
  return check;
});
exports.bozzeRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.bozzeRicaricaCarta && state.bozzeRicaricaCarta.ricariche ? state.bozzeRicaricaCarta.ricariche.sort((a, b) => b.id - a.id) : [];
});
exports.currentBozzaRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.currentBozza;
});
exports.disableRicaricaCartaSalvaBozza = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  var _a;
  let isBozzaAccepted = !((_a = state.formRicaricaCarta) === null || _a === void 0 ? void 0 : _a.bozzaSalvata) && (!!state.formRicaricaCarta.pan || !!state.formRicaricaCarta.importo);
  isBozzaAccepted = isBozzaAccepted && !!state.formRicaricaCarta.pan ? !state.formRicaricaCarta.panError : isBozzaAccepted;
  isBozzaAccepted = isBozzaAccepted && !!state.formRicaricaCarta.importo ? !state.formRicaricaCarta.importoError : isBozzaAccepted;
  return !isBozzaAccepted;
});
exports.oneclickRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.oneclick;
});
exports.esitoRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.esito;
});
exports.infoDispositivaRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.infoDispositiva;
});
exports.movimentiRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.movimentiRicaricaCarta;
});
exports.parametriRicaricaMovimenti = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.parametriRicaricaMovimenti;
});
exports.filtriRicaricaCarta = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  return state.filtri;
});
exports.filtriApplicatiCartaRicaricabile = (0, reselect_1.createSelector)(ricaricaCartaReducer, state => {
  let r = false;
  if (state.filtri.periodo != '30') {
    r = true;
  }
  return r;
});