"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datiFiliale = void 0;
const reselect_1 = require("reselect");
const datiFilialeReducer = state => state;
exports.datiFiliale = (0, reselect_1.createSelector)(datiFilialeReducer, state => {
  return state.datiFiliali;
});