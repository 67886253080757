"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpesaBOEDtoToJSON = exports.SpesaBOEDtoFromJSONTyped = exports.SpesaBOEDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SpesaBOEDtoFromJSON(json) {
  return SpesaBOEDtoFromJSONTyped(json, false);
}
exports.SpesaBOEDtoFromJSON = SpesaBOEDtoFromJSON;
function SpesaBOEDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore']
  };
}
exports.SpesaBOEDtoFromJSONTyped = SpesaBOEDtoFromJSONTyped;
function SpesaBOEDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'descrizione': value.descrizione,
    'divisa': value.divisa,
    'importo': value.importo,
    'controvalore': value.controvalore
  };
}
exports.SpesaBOEDtoToJSON = SpesaBOEDtoToJSON;