"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.richiediFidoIsiDispoReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const richiediFidoIsiDispo_types_1 = require("./richiediFidoIsiDispo.types");
const initialState = {
  parametri: (0, api_rest_1.ParametriPrestitiIsiDispoDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.PrestitoDtoFromJSON)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizionePrestitoDtoFromJSON)({}),
  infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  form: (0, richiediFidoIsiDispo_types_1.FormFidoIsiDispoDtoFromJSONTyped)({})
};
const richiediFidoIsiDispoReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_TRANSAZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          massimaleTransazione: action.payload,
          massimaleTransazioneDirty: true,
          massimaleTransazioneError: (0, helpers_1.validationTransazione)(action.payload, state.form.massimaleMax, state.form.massimaleMin, state.form.stepMassimale)
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_STATO_CIVILE:
      return {
        ...state,
        form: {
          ...state.form,
          statoCivile: action.payload,
          statoCivileDirty: true,
          statoCivileError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_NUMERO_ADULTI:
      return {
        ...state,
        form: {
          ...state.form,
          numeroAdulti: action.payload,
          numeroAdultiDirty: true,
          numeroAdultiError: (0, helpers_1.validationField)(action.payload, 0)
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_NUMERO_FIGLI:
      return {
        ...state,
        form: {
          ...state.form,
          numeroFigli: action.payload,
          numeroFigliDirty: true,
          numeroFigliError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_SITUAZIONE_ABITATIVA:
      return {
        ...state,
        form: {
          ...state.form,
          situazioneAbitativa: action.payload,
          situazioneAbitativaDirty: true,
          situazioneAbitativaError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_PROFESSIONALE:
      return {
        ...state,
        form: {
          ...state.form,
          categoriaProfessionale: action.payload,
          categoriaProfessionaleDirty: true,
          categoriaProfessionaleError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_DETTAGLIO_PROFESSIONE:
      return {
        ...state,
        form: {
          ...state.form,
          dettaglioProfessione: action.payload,
          dettaglioProfessioneDirty: true,
          dettaglioProfessioneError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_DATORE_DI_LAVORO:
      return {
        ...state,
        form: {
          ...state.form,
          datoreDiLavoro: action.payload,
          datoreDiLavoroDirty: true,
          datoreDiLavoroError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_RAPPORTO_DI_LAVORO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoDiLavoro: action.payload,
          rapportoDiLavoroDirty: true,
          rapportoDiLavoroError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ANNO_DI_INIZIO:
      return {
        ...state,
        form: {
          ...state.form,
          annoDiInizio: action.payload,
          annoDiInizioDirty: true,
          annoDiInizioError: (0, helpers_1.validationRangeAnno)(true, action.payload)
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ACCREDITO_DOMICILIATO_PRESSO_BANCA:
      return {
        ...state,
        form: {
          ...state.form,
          accreditoDomiciliatoPressoBanca: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_REDDITO_NETTO_MENSILE:
      return {
        ...state,
        form: {
          ...state.form,
          redditoNettoMensile: action.payload,
          redditoNettoMensileDirty: true,
          redditoNettoMensileError: (0, helpers_1.validationField)(action.payload, 0)
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ALTRI_REDDITI_MENSILI:
      return {
        ...state,
        form: {
          ...state.form,
          altriRedditiMensili: action.payload,
          altriRedditiMensiliDirty: true,
          altriRedditiMensiliError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ONERI_MENSILI_CREDITI:
      return {
        ...state,
        form: {
          ...state.form,
          oneriMensiliCrediti: action.payload,
          oneriMensiliCreditiDirty: true,
          oneriMensiliCreditiError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_IMPEGNI_MENSILI_AFFITTI:
      return {
        ...state,
        form: {
          ...state.form,
          impegniMensiliAffitti: action.payload,
          impegniMensiliAffittiDirty: true,
          impegniMensiliAffittiError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ALTRI_IMPEGNI:
      return {
        ...state,
        form: {
          ...state.form,
          altriImpegni: action.payload,
          altriImpegniDirty: true,
          altriImpegniError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CARTE_DI_CREDITO_POSSEDUTE:
      return {
        ...state,
        form: {
          ...state.form,
          carteDiCreditoPossedute: action.payload,
          carteDiCreditoPosseduteDirty: true,
          carteDiCreditoPosseduteError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_FORM_SUBMITTED:
      return {
        ...state,
        form: {
          ...state.form,
          formSubmitted: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload,
        form: {
          ...state.form,
          massimaleTransazione: Number(action.payload.parametri["VENDITAPRESTITIDISPO.IMPORTOMIN"])
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_CONTROLLO_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_GET_INFO_DISPOSITIVA_SUCCESS:
      return {
        ...state,
        infoDispositiva: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_AUTORIZZA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_RESET_RIEPILOGO:
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_RESET_ESITO:
      return {
        ...state,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_STATO_CIVILE:
      return {
        ...state,
        form: {
          ...state.form,
          arrayStatoCivile: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_SITUAZIONE_ABITATIVA:
      return {
        ...state,
        form: {
          ...state.form,
          arraySituazioneAbitativa: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_PROFESSIONE:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCategoriaProfessionale: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_INATTIVI:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCategoriaInattivi: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_DIPENDENTI:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCategoriaDipendenti: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_MILITARI:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCategoriaMilitari: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_DOCENTI:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCategoriaDocenti: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_LIBERI_PROFESSIONISTI:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCategoriaLiberiProfessionisti: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_AUTONOMI:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCategoriaAutonomi: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_DETTAGLIO_PROFESSIONE:
      return {
        ...state,
        form: {
          ...state.form,
          arrayDettaglioProfessione: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_RAPPORTO_DI_LAVORO:
      return {
        ...state,
        form: {
          ...state.form,
          arrayRapportoDiLavoro: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CARTE_POSSEDUTE:
      return {
        ...state,
        form: {
          ...state.form,
          arrayCartePossedute: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_INATTIVI:
      return {
        ...state,
        form: {
          ...state.form,
          categoriaInattivi: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_DIPENDENTI:
      return {
        ...state,
        form: {
          ...state.form,
          categoriaDipendenti: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_MILITARI:
      return {
        ...state,
        form: {
          ...state.form,
          categoriaMilitari: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_DOCENTI:
      return {
        ...state,
        form: {
          ...state.form,
          categoriaDocenti: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_LIBERI_PROFESSIONISTI:
      return {
        ...state,
        form: {
          ...state.form,
          categoriaLiberiProfessionisti: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_AUTONOMI:
      return {
        ...state,
        form: {
          ...state.form,
          categoriaAutonomi: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_PRESA_VISIONE_INFORMATIVA_ENABLED:
      return {
        ...state,
        form: {
          ...state.form,
          presaVisioneInformativaEnabled: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_PRESA_VISIONE_INFORMATIVA:
      return {
        ...state,
        form: {
          ...state.form,
          presaVisioneInformativa: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_DICHIARAZIONE_SCOPO:
      return {
        ...state,
        form: {
          ...state.form,
          dichiarazioneScopoChecked: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_MAX:
      return {
        ...state,
        form: {
          ...state.form,
          massimaleMax: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_MIN:
      return {
        ...state,
        form: {
          ...state.form,
          massimaleMin: action.payload
        }
      };
    case __1.EReduxActionTypes.RICHIEDI_FIDO_ISI_DISPO_SET_STEP_MASSIMALE:
      return {
        ...state,
        form: {
          ...state.form,
          stepMassimale: action.payload
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.richiediFidoIsiDispoReducer = richiediFidoIsiDispoReducer;
exports.default = exports.richiediFidoIsiDispoReducer;