"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InformazioniDtoToJSON = exports.InformazioniDtoFromJSONTyped = exports.InformazioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function InformazioniDtoFromJSON(json) {
  return InformazioniDtoFromJSONTyped(json, false);
}
exports.InformazioniDtoFromJSON = InformazioniDtoFromJSON;
function InformazioniDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'beneficiario': !runtime_1.exists(json, 'beneficiario') ? undefined : json['beneficiario'],
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'dataPagamento': !runtime_1.exists(json, 'dataPagamento') ? undefined : new Date(json['dataPagamento']),
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'modelloF24': !runtime_1.exists(json, 'modelloF24') ? undefined : json['modelloF24'],
    'contribuente': !runtime_1.exists(json, 'contribuente') ? undefined : json['contribuente'],
    'numeroCellulare': !runtime_1.exists(json, 'numeroCellulare') ? undefined : json['numeroCellulare'],
    'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    'descrizioneCodiceSia': !runtime_1.exists(json, 'descrizioneCodiceSia') ? undefined : json['descrizioneCodiceSia'],
    'importoBollettino': !runtime_1.exists(json, 'importoBollettino') ? undefined : json['importoBollettino'],
    'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan']
  };
}
exports.InformazioniDtoFromJSONTyped = InformazioniDtoFromJSONTyped;
function InformazioniDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'causale': value.causale,
    'beneficiario': value.beneficiario,
    'importo': value.importo,
    'dataPagamento': value.dataPagamento === undefined ? undefined : value.dataPagamento.toISOString(),
    'divisa': value.divisa,
    'modelloF24': value.modelloF24,
    'contribuente': value.contribuente,
    'numeroCellulare': value.numeroCellulare,
    'email': value.email,
    'descrizioneCodiceSia': value.descrizioneCodiceSia,
    'importoBollettino': value.importoBollettino,
    'pan': value.pan
  };
}
exports.InformazioniDtoToJSON = InformazioniDtoToJSON;