"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriBonificoEsteroDtoToJSON = exports.ParametriBonificoEsteroDtoFromJSONTyped = exports.ParametriBonificoEsteroDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriBonificoEsteroDtoFromJSON(json) {
  return ParametriBonificoEsteroDtoFromJSONTyped(json, false);
}
exports.ParametriBonificoEsteroDtoFromJSON = ParametriBonificoEsteroDtoFromJSON;
function ParametriBonificoEsteroDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'divise': !runtime_1.exists(json, 'divise') ? undefined : json['divise'].map(_1.DivisaFromJSON),
    'paesiDestinazione': !runtime_1.exists(json, 'paesiDestinazione') ? undefined : json['paesiDestinazione'].map(_1.PaeseDtoFromJSON),
    'paesiDestinazioneSepa': !runtime_1.exists(json, 'paesiDestinazioneSepa') ? undefined : json['paesiDestinazioneSepa'].map(_1.PaeseSepaDtoFromJSON)
  };
}
exports.ParametriBonificoEsteroDtoFromJSONTyped = ParametriBonificoEsteroDtoFromJSONTyped;
function ParametriBonificoEsteroDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri,
    'divise': value.divise === undefined ? undefined : value.divise.map(_1.DivisaToJSON),
    'paesiDestinazione': value.paesiDestinazione === undefined ? undefined : value.paesiDestinazione.map(_1.PaeseDtoToJSON),
    'paesiDestinazioneSepa': value.paesiDestinazioneSepa === undefined ? undefined : value.paesiDestinazioneSepa.map(_1.PaeseSepaDtoToJSON)
  };
}
exports.ParametriBonificoEsteroDtoToJSON = ParametriBonificoEsteroDtoToJSON;