"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BillerDtoToJSON = exports.BillerDtoFromJSONTyped = exports.BillerDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BillerDtoFromJSON(json) {
  return BillerDtoFromJSONTyped(json, false);
}
exports.BillerDtoFromJSON = BillerDtoFromJSON;
function BillerDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'preferito': !runtime_1.exists(json, 'preferito') ? undefined : json['preferito'],
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'codiceBiller': !runtime_1.exists(json, 'codiceBiller') ? undefined : json['codiceBiller'],
    'informazioniAggiuntive': !runtime_1.exists(json, 'informazioniAggiuntive') ? undefined : json['informazioniAggiuntive'],
    'servizi': !runtime_1.exists(json, 'servizi') ? undefined : json['servizi']
  };
}
exports.BillerDtoFromJSONTyped = BillerDtoFromJSONTyped;
function BillerDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'preferito': value.preferito,
    'intestazione': value.intestazione,
    'codiceBiller': value.codiceBiller,
    'informazioniAggiuntive': value.informazioniAggiuntive,
    'servizi': value.servizi
  };
}
exports.BillerDtoToJSON = BillerDtoToJSON;