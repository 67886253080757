"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PinErroreToJSON = exports.PinErroreFromJSONTyped = exports.PinErroreFromJSON = void 0;
const runtime_1 = require("../runtime");
function PinErroreFromJSON(json) {
  return PinErroreFromJSONTyped(json, false);
}
exports.PinErroreFromJSON = PinErroreFromJSON;
function PinErroreFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'httpStatus': !runtime_1.exists(json, 'httpStatus') ? undefined : json['httpStatus'],
    'descrizioneErrore': !runtime_1.exists(json, 'descrizioneErrore') ? undefined : json['descrizioneErrore'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'tipoCodiceErroreSpecifico': !runtime_1.exists(json, 'tipoCodiceErroreSpecifico') ? undefined : json['tipoCodiceErroreSpecifico'],
    'codiceErroreSpecifico': !runtime_1.exists(json, 'codiceErroreSpecifico') ? undefined : json['codiceErroreSpecifico'],
    'descrizioneErroreSpecifico': !runtime_1.exists(json, 'descrizioneErroreSpecifico') ? undefined : json['descrizioneErroreSpecifico']
  };
}
exports.PinErroreFromJSONTyped = PinErroreFromJSONTyped;
function PinErroreToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'httpStatus': value.httpStatus,
    'descrizioneErrore': value.descrizioneErrore,
    'codiceErrore': value.codiceErrore,
    'tipoCodiceErroreSpecifico': value.tipoCodiceErroreSpecifico,
    'codiceErroreSpecifico': value.codiceErroreSpecifico,
    'descrizioneErroreSpecifico': value.descrizioneErroreSpecifico
  };
}
exports.PinErroreToJSON = PinErroreToJSON;