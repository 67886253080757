"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.condizioniEconomicheVariazioneCanali = exports.formVariazioneCanaliAsFiltroCorrispondenze = exports.corrispondenzeVariazioneCanali = exports.esitoVariazioneCanali = exports.parametriCorrispondenzaVariazioneCanali = exports.infoAutenticazioneVariazioneCanali = exports.rapportiPredefinitiVariazioneCanali = exports.formVariazioneCanali = void 0;
const reselect_1 = require("reselect");
const variazioneCanaliReducer = state => state;
exports.formVariazioneCanali = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  return state.form;
});
exports.rapportiPredefinitiVariazioneCanali = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  return state.rapportiPredefiniti;
});
exports.infoAutenticazioneVariazioneCanali = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  return state.infoAutenticazione;
});
exports.parametriCorrispondenzaVariazioneCanali = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  return state.parametriCorrispondenza;
});
exports.esitoVariazioneCanali = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  return state.esito;
});
exports.corrispondenzeVariazioneCanali = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  return state.corrispondenze;
});
exports.formVariazioneCanaliAsFiltroCorrispondenze = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  var _a;
  return (_a = state.form) === null || _a === void 0 ? void 0 : _a.selectedItems;
});
exports.condizioniEconomicheVariazioneCanali = (0, reselect_1.createSelector)(variazioneCanaliReducer, state => {
  return state.condizioniEconomiche;
});