"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyValeDtoToJSON = exports.KeyValeDtoFromJSONTyped = exports.KeyValeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function KeyValeDtoFromJSON(json) {
  return KeyValeDtoFromJSONTyped(json, false);
}
exports.KeyValeDtoFromJSON = KeyValeDtoFromJSON;
function KeyValeDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'key': !runtime_1.exists(json, 'key') ? undefined : json['key'],
    'value': !runtime_1.exists(json, 'value') ? undefined : json['value']
  };
}
exports.KeyValeDtoFromJSONTyped = KeyValeDtoFromJSONTyped;
function KeyValeDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'key': value.key,
    'value': value.value
  };
}
exports.KeyValeDtoToJSON = KeyValeDtoToJSON;