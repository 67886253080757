"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxOnboardingActionTypes = void 0;
var EReduxOnboardingActionTypes;
(function (EReduxOnboardingActionTypes) {
  EReduxOnboardingActionTypes["ONBOARDING_INVIA_NOTIFICA_PUSH_START"] = "ONBOARDING_INVIA_NOTIFICA_PUSH_START";
  EReduxOnboardingActionTypes["ONBOARDING_INVIA_NOTIFICA_PUSH_SUCCESS"] = "ONBOARDING_INVIA_NOTIFICA_PUSH_SUCCESS";
  EReduxOnboardingActionTypes["ONBOARDING_CHECK_START"] = "ONBOARDING_CHECK_START";
  EReduxOnboardingActionTypes["ONBOARDING_CHECK_SUCCESS"] = "ONBOARDING_CHECK_SUCCESS";
  EReduxOnboardingActionTypes["ONBOARDING_UPDATE_START"] = "ONBOARDING_UPDATE_START";
  EReduxOnboardingActionTypes["ONBOARDING_UPDATE_SUCCESS"] = "ONBOARDING_UPDATE_SUCCESS";
  EReduxOnboardingActionTypes["ONBOARDING_SET_UPDATE_NECESSARIO"] = "ONBOARDING_SET_UPDATE_NECESSARIO";
  EReduxOnboardingActionTypes["ONBOARDING_RESET_UPDATE_NECESSARIO"] = "ONBOARDING_RESET_UPDATE_NECESSARIO";
})(EReduxOnboardingActionTypes = exports.EReduxOnboardingActionTypes || (exports.EReduxOnboardingActionTypes = {}));