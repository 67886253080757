"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * PNS Client API
 * PNS - Push Notification Service: Api per l\'invio di notifiche
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextApiResponse = exports.BlobApiResponse = exports.VoidApiResponse = exports.JSONApiResponse = exports.canConsumeForm = exports.mapValues = exports.querystring = exports.exists = exports.Configuration = exports.COLLECTION_FORMATS = exports.RequiredError = exports.BaseAPI = exports.BASE_PATH = void 0;
exports.BASE_PATH = "http://20.73.150.170/notifications".replace(/\/+$/, "");
const isBlob = value => typeof Blob !== 'undefined' && value instanceof Blob;
/**
 * This is the base class for all generated API classes.
 */
class BaseAPI {
  constructor() {
    let configuration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Configuration();
    this.configuration = configuration;
    this.fetchApi = (url, init) => __awaiter(this, void 0, void 0, function* () {
      let fetchParams = {
        url,
        init
      };
      for (const middleware of this.middleware) {
        if (middleware.pre) {
          fetchParams = (yield middleware.pre(Object.assign({
            fetch: this.fetchApi
          }, fetchParams))) || fetchParams;
        }
      }
      let response = yield this.configuration.fetchApi(fetchParams.url, fetchParams.init);
      for (const middleware of this.middleware) {
        if (middleware.post) {
          response = (yield middleware.post({
            fetch: this.fetchApi,
            url,
            init,
            response: response.clone()
          })) || response;
        }
      }
      return response;
    });
    this.middleware = configuration.middleware;
  }
  withMiddleware() {
    const next = this.clone();
    next.middleware = next.middleware.concat(...arguments);
    return next;
  }
  withPreMiddleware() {
    for (var _len = arguments.length, preMiddlewares = new Array(_len), _key = 0; _key < _len; _key++) {
      preMiddlewares[_key] = arguments[_key];
    }
    const middlewares = preMiddlewares.map(pre => ({
      pre
    }));
    return this.withMiddleware(...middlewares);
  }
  withPostMiddleware() {
    for (var _len2 = arguments.length, postMiddlewares = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      postMiddlewares[_key2] = arguments[_key2];
    }
    const middlewares = postMiddlewares.map(post => ({
      post
    }));
    return this.withMiddleware(...middlewares);
  }
  request(context) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        url,
        init
      } = this.createFetchParams(context);
      const response = yield this.fetchApi(url, init);
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      throw response;
    });
  }
  createFetchParams(context) {
    let url = this.configuration.basePath + context.path;
    if (context.query !== undefined && Object.keys(context.query).length !== 0) {
      // only add the querystring to the URL if there are query parameters.
      // this is done to avoid urls ending with a "?" character which buggy webservers
      // do not handle correctly sometimes.
      url += '?' + this.configuration.queryParamsStringify(context.query);
    }
    const body = typeof FormData !== "undefined" && context.body instanceof FormData || context.body instanceof URLSearchParams || isBlob(context.body) ? context.body : JSON.stringify(context.body);
    const headers = Object.assign({}, this.configuration.headers, context.headers);
    const init = {
      method: context.method,
      headers: headers,
      body,
      credentials: this.configuration.credentials
    };
    return {
      url,
      init
    };
  }
  /**
   * Create a shallow clone of `this` by constructing a new instance
   * and then shallow cloning data members.
   */
  clone() {
    const constructor = this.constructor;
    const next = new constructor(this.configuration);
    next.middleware = this.middleware.slice();
    return next;
  }
}
exports.BaseAPI = BaseAPI;
;
class RequiredError extends Error {
  constructor(field, msg) {
    super(msg);
    this.field = field;
    this.name = "RequiredError";
  }
}
exports.RequiredError = RequiredError;
exports.COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};
class Configuration {
  constructor() {
    let configuration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.configuration = configuration;
  }
  get basePath() {
    return this.configuration.basePath != null ? this.configuration.basePath : exports.BASE_PATH;
  }
  get fetchApi() {
    return this.configuration.fetchApi || window.fetch.bind(window);
  }
  get middleware() {
    return this.configuration.middleware || [];
  }
  get queryParamsStringify() {
    return this.configuration.queryParamsStringify || querystring;
  }
  get username() {
    return this.configuration.username;
  }
  get password() {
    return this.configuration.password;
  }
  get apiKey() {
    const apiKey = this.configuration.apiKey;
    if (apiKey) {
      return typeof apiKey === 'function' ? apiKey : () => apiKey;
    }
    return undefined;
  }
  get accessToken() {
    const accessToken = this.configuration.accessToken;
    if (accessToken) {
      return typeof accessToken === 'function' ? accessToken : () => accessToken;
    }
    return undefined;
  }
  get headers() {
    return this.configuration.headers;
  }
  get credentials() {
    return this.configuration.credentials;
  }
}
exports.Configuration = Configuration;
function exists(json, key) {
  const value = json[key];
  return value !== null && value !== undefined;
}
exports.exists = exists;
function querystring(params) {
  let prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.keys(params).map(key => {
    const fullKey = prefix + (prefix.length ? "[".concat(key, "]") : key);
    const value = params[key];
    if (value instanceof Array) {
      const multiValue = value.map(singleValue => encodeURIComponent(String(singleValue))).join("&".concat(encodeURIComponent(fullKey), "="));
      return "".concat(encodeURIComponent(fullKey), "=").concat(multiValue);
    }
    if (value instanceof Object) {
      return querystring(value, fullKey);
    }
    return "".concat(encodeURIComponent(fullKey), "=").concat(encodeURIComponent(String(value)));
  }).filter(part => part.length > 0).join('&');
}
exports.querystring = querystring;
function mapValues(data, fn) {
  return Object.keys(data).reduce((acc, key) => Object.assign(Object.assign({}, acc), {
    [key]: fn(data[key])
  }), {});
}
exports.mapValues = mapValues;
function canConsumeForm(consumes) {
  for (const consume of consumes) {
    if ('multipart/form-data' === consume.contentType) {
      return true;
    }
  }
  return false;
}
exports.canConsumeForm = canConsumeForm;
class JSONApiResponse {
  constructor(raw) {
    let transformer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : jsonValue => jsonValue;
    this.raw = raw;
    this.transformer = transformer;
  }
  value() {
    return __awaiter(this, void 0, void 0, function* () {
      return this.transformer(yield this.raw.json());
    });
  }
}
exports.JSONApiResponse = JSONApiResponse;
class VoidApiResponse {
  constructor(raw) {
    this.raw = raw;
  }
  value() {
    return __awaiter(this, void 0, void 0, function* () {
      return undefined;
    });
  }
}
exports.VoidApiResponse = VoidApiResponse;
class BlobApiResponse {
  constructor(raw) {
    this.raw = raw;
  }
  value() {
    return __awaiter(this, void 0, void 0, function* () {
      return yield this.raw.blob();
    });
  }
}
exports.BlobApiResponse = BlobApiResponse;
class TextApiResponse {
  constructor(raw) {
    this.raw = raw;
  }
  value() {
    return __awaiter(this, void 0, void 0, function* () {
      return yield this.raw.text();
    });
  }
}
exports.TextApiResponse = TextApiResponse;