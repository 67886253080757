"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MavControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MavControllerApi extends runtime.BaseAPI {
  /**
   * Informazioni sull\'autenticazione dispositiva per un mav
   */
  authenticationInformationMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationMav.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mav/infoautenticazione/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva per un mav
   */
  authenticationInformationMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva per la revoca
   */
  authenticationRevocationInformationMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mav/infoautenticazione/revoca",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva per la revoca
   */
  authenticationRevocationInformationMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationRevocationInformationMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di revoca mav
   */
  authenticationRevocationMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.mavRevocaRequestIn === null || requestParameters.mavRevocaRequestIn === undefined) {
        throw new runtime.RequiredError('mavRevocaRequestIn', 'Required parameter requestParameters.mavRevocaRequestIn was null or undefined when calling authenticationRevocationMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mav/autorizza/revoca",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.MavRevocaRequestInToJSON(requestParameters.mavRevocaRequestIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneMavDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di revoca mav
   */
  authenticationRevocationMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationRevocationMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di mav
   */
  authorizeMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeMav.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mav/autorizza/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneMavDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di mav
   */
  authorizeMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Esegue il controllo di validità di un mav in base ai valori specificati nell\'oggetto di richiesta
   */
  checkMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.mavDto === null || requestParameters.mavDto === undefined) {
        throw new runtime.RequiredError('mavDto', 'Required parameter requestParameters.mavDto was null or undefined when calling checkMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mav/controllo",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.MavDtoToJSON(requestParameters.mavDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.MavDtoFromJSON(jsonValue));
    });
  }
  /**
   * Esegue il controllo di validità di un mav in base ai valori specificati nell\'oggetto di richiesta
   */
  checkMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.checkMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Eliminazione di una bozza dato l\'id
   */
  deleteDraftMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteDraftMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mav/bozze/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Eliminazione di una bozza dato l\'id
   */
  deleteDraftMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.deleteDraftMavRaw(requestParameters);
    });
  }
  /**
   * Restituisce una bozza dato l\'id per mav
   */
  getDraftByIdMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDraftByIdMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mav/bozze/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.MavDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce una bozza dato l\'id per mav
   */
  getDraftByIdMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getDraftByIdMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le bozze per mav
   */
  getDraftListMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
        throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getDraftListMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mav/bozze",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.MavsDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le bozze per mav
   */
  getDraftListMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getDraftListMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri MAV
   */
  parametersMavRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mav/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriMavDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri MAV
   */
  parametersMav() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametersMavRaw();
      return yield response.value();
    });
  }
  /**
   * Salva una bozza per mav
   */
  saveDraftMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.mavDto === null || requestParameters.mavDto === undefined) {
        throw new runtime.RequiredError('mavDto', 'Required parameter requestParameters.mavDto was null or undefined when calling saveDraftMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mav/salvabozza",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.MavDtoToJSON(requestParameters.mavDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.MavDtoFromJSON(jsonValue));
    });
  }
  /**
   * Salva una bozza per mav
   */
  saveDraftMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.saveDraftMavRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Modifica di una bozza dato l\'id per mav
   */
  updateDraftMavRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateDraftMav.');
      }
      if (requestParameters.mavDto === null || requestParameters.mavDto === undefined) {
        throw new runtime.RequiredError('mavDto', 'Required parameter requestParameters.mavDto was null or undefined when calling updateDraftMav.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mav/bozze/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.MavDtoToJSON(requestParameters.mavDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.MavDtoFromJSON(jsonValue));
    });
  }
  /**
   * Modifica di una bozza dato l\'id per mav
   */
  updateDraftMav(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.updateDraftMavRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.MavControllerApi = MavControllerApi;