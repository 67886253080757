"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApriPraticaInDtoToJSON = exports.ApriPraticaInDtoFromJSONTyped = exports.ApriPraticaInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ApriPraticaInDtoFromJSON(json) {
  return ApriPraticaInDtoFromJSONTyped(json, false);
}
exports.ApriPraticaInDtoFromJSON = ApriPraticaInDtoFromJSON;
function ApriPraticaInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tokenIdentifier': !runtime_1.exists(json, 'tokenIdentifier') ? undefined : json['tokenIdentifier'],
    'datiDocumento': !runtime_1.exists(json, 'datiDocumento') ? undefined : _1.DatiDocumentoFromJSON(json['datiDocumento'])
  };
}
exports.ApriPraticaInDtoFromJSONTyped = ApriPraticaInDtoFromJSONTyped;
function ApriPraticaInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tokenIdentifier': value.tokenIdentifier,
    'datiDocumento': _1.DatiDocumentoToJSON(value.datiDocumento)
  };
}
exports.ApriPraticaInDtoToJSON = ApriPraticaInDtoToJSON;