"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WatchlistDtoTipoEnum = exports.WatchlistDtoToJSON = exports.WatchlistDtoFromJSONTyped = exports.WatchlistDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function WatchlistDtoFromJSON(json) {
  return WatchlistDtoFromJSONTyped(json, false);
}
exports.WatchlistDtoFromJSON = WatchlistDtoFromJSON;
function WatchlistDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo']
  };
}
exports.WatchlistDtoFromJSONTyped = WatchlistDtoFromJSONTyped;
function WatchlistDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codice': value.codice,
    'nome': value.nome,
    'tipo': value.tipo
  };
}
exports.WatchlistDtoToJSON = WatchlistDtoToJSON;
/**
* @export
* @enum {string}
*/
var WatchlistDtoTipoEnum;
(function (WatchlistDtoTipoEnum) {
  WatchlistDtoTipoEnum["Statica"] = "Statica";
  WatchlistDtoTipoEnum["Dinamica"] = "Dinamica";
})(WatchlistDtoTipoEnum = exports.WatchlistDtoTipoEnum || (exports.WatchlistDtoTipoEnum = {}));