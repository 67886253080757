"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum = exports.DatiCaricamentoDocumentoIdentitaOutDtoToJSON = exports.DatiCaricamentoDocumentoIdentitaOutDtoFromJSONTyped = exports.DatiCaricamentoDocumentoIdentitaOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiCaricamentoDocumentoIdentitaOutDtoFromJSON(json) {
  return DatiCaricamentoDocumentoIdentitaOutDtoFromJSONTyped(json, false);
}
exports.DatiCaricamentoDocumentoIdentitaOutDtoFromJSON = DatiCaricamentoDocumentoIdentitaOutDtoFromJSON;
function DatiCaricamentoDocumentoIdentitaOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'success': !runtime_1.exists(json, 'success') ? undefined : json['success'],
    'datiRilevati': !runtime_1.exists(json, 'datiRilevati') ? undefined : _1.DatiRilevatiFromJSON(json['datiRilevati']),
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato']
  };
}
exports.DatiCaricamentoDocumentoIdentitaOutDtoFromJSONTyped = DatiCaricamentoDocumentoIdentitaOutDtoFromJSONTyped;
function DatiCaricamentoDocumentoIdentitaOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'success': value.success,
    'datiRilevati': _1.DatiRilevatiToJSON(value.datiRilevati),
    'stato': value.stato
  };
}
exports.DatiCaricamentoDocumentoIdentitaOutDtoToJSON = DatiCaricamentoDocumentoIdentitaOutDtoToJSON;
/**
* @export
* @enum {string}
*/
var DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum;
(function (DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum) {
  DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum["OK"] = "OK";
  DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
})(DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum = exports.DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum || (exports.DatiCaricamentoDocumentoIdentitaOutDtoStatoEnum = {}));