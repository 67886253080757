"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AttivitaEconomicaDtoToJSON = exports.AttivitaEconomicaDtoFromJSONTyped = exports.AttivitaEconomicaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AttivitaEconomicaDtoFromJSON(json) {
  return AttivitaEconomicaDtoFromJSONTyped(json, false);
}
exports.AttivitaEconomicaDtoFromJSON = AttivitaEconomicaDtoFromJSON;
function AttivitaEconomicaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'cae': !runtime_1.exists(json, 'cae') ? undefined : json['cae'],
    'descrizioneCAE': !runtime_1.exists(json, 'descrizioneCAE') ? undefined : json['descrizioneCAE'],
    'sae': !runtime_1.exists(json, 'sae') ? undefined : json['sae'],
    'descrizioneSAE': !runtime_1.exists(json, 'descrizioneSAE') ? undefined : json['descrizioneSAE'],
    'rae': !runtime_1.exists(json, 'rae') ? undefined : json['rae'],
    'descrizioneRAE': !runtime_1.exists(json, 'descrizioneRAE') ? undefined : json['descrizioneRAE']
  };
}
exports.AttivitaEconomicaDtoFromJSONTyped = AttivitaEconomicaDtoFromJSONTyped;
function AttivitaEconomicaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'cae': value.cae,
    'descrizioneCAE': value.descrizioneCAE,
    'sae': value.sae,
    'descrizioneSAE': value.descrizioneSAE,
    'rae': value.rae,
    'descrizioneRAE': value.descrizioneRAE
  };
}
exports.AttivitaEconomicaDtoToJSON = AttivitaEconomicaDtoToJSON;