"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkUpdateBozzaBolloAci = exports.disableSalvaBozzaBolloACI = exports.formValidBolloAci = exports.buildBolloAci = exports.bozzeBolloAci = exports.currentBozzaBolloAci = exports.esitoBolloAci = exports.infoAutenticazioneBolloAci = exports.riepilogoBolloAci = exports.formBolloAci = exports.parametriBolloAci = void 0;
const reselect_1 = require("reselect");
const bolloAciReducer = state => state;
exports.parametriBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  return state.parametri;
});
exports.formBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  return state.form;
});
exports.riepilogoBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  return state.riepilogo;
});
exports.infoAutenticazioneBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  return state.infoAutenticazioneBolloAci;
});
exports.esitoBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  return state.esito;
});
exports.currentBozzaBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  return state.currentBozza;
});
exports.bozzeBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  return state.bozzeBolloAci ? state.bozzeBolloAci.sort((a, b) => b.id - a.id) : [];
});
exports.buildBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  var _a, _b;
  return {
    contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
    tipoDiVeicolo: state.form.tipoVeicolo,
    targa: state.form.targa || '',
    idBozzaCollegata: state.currentBozza.id,
    idContattoBeneficiario: state.form.idContattoBeneficiario,
    aggiungiRubrica: state.form.aggiungiRubrica,
    aggiungiWhitelist: state.form.affidabile,
    nomeBeneficiario: state.form.nome
  };
});
exports.formValidBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  let formValid = false;
  if (!state.form.tipoVeicoloError && !state.form.targaError && !state.form.alias1ClickError && !(state.form.aggiungiRubrica && state.form.nomeError)) {
    formValid = true;
  }
  return formValid;
});
exports.disableSalvaBozzaBolloACI = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  let isBozzaAccepted = !state.form.bozzaSalvata && state.form.tipoVeicolo && !state.form.tipoVeicoloError || state.form.targa && !state.form.targaError;
  return !isBozzaAccepted;
});
exports.checkUpdateBozzaBolloAci = (0, reselect_1.createSelector)(bolloAciReducer, state => {
  var _a;
  return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});