import logo from "logoSparkasse.svg";
import logoResp from "Pictogram.svg";
import React from "react";

interface Props {
  onClick?: () => void
};

const Logo = (props: Props) => {

  const {
    onClick
  } = props;

  return (
    <div>

      <img
        src={logo}
        alt="logo"
        className={`bank-logo big ${onClick ? '' : 'disabled'}`}
        onClick={onClick}
      />

      <img
        src={logoResp}
        alt="logo"
        className={`bank-logo small ${onClick ? '' : 'disabled'}`}
        onClick={onClick}
      />

    </div>
  );
};

export default Logo;
