"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SendNotificationOutDtoToJSON = exports.SendNotificationOutDtoFromJSONTyped = exports.SendNotificationOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SendNotificationOutDtoFromJSON(json) {
  return SendNotificationOutDtoFromJSONTyped(json, false);
}
exports.SendNotificationOutDtoFromJSON = SendNotificationOutDtoFromJSON;
function SendNotificationOutDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'token': !runtime_1.exists(json, 'token') ? undefined : json['token']
  };
}
exports.SendNotificationOutDtoFromJSONTyped = SendNotificationOutDtoFromJSONTyped;
function SendNotificationOutDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'token': value.token
  };
}
exports.SendNotificationOutDtoToJSON = SendNotificationOutDtoToJSON;