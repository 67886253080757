"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiAnagraficiDtoModoAutenticazioneEnum = exports.DatiAnagraficiDtoToJSON = exports.DatiAnagraficiDtoFromJSONTyped = exports.DatiAnagraficiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiAnagraficiDtoFromJSON(json) {
  return DatiAnagraficiDtoFromJSONTyped(json, false);
}
exports.DatiAnagraficiDtoFromJSON = DatiAnagraficiDtoFromJSON;
function DatiAnagraficiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idVirtuale': !runtime_1.exists(json, 'idVirtuale') ? undefined : json['idVirtuale'],
    'codiceAlias': !runtime_1.exists(json, 'codiceAlias') ? undefined : json['codiceAlias'],
    'modoAutenticazione': !runtime_1.exists(json, 'modoAutenticazione') ? undefined : json['modoAutenticazione'],
    'cellulare': !runtime_1.exists(json, 'cellulare') ? undefined : json['cellulare'],
    'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    'telefonoFisso': !runtime_1.exists(json, 'telefonoFisso') ? undefined : _1.TelefonoFissoDtoFromJSON(json['telefonoFisso']),
    'indirizzoResidenza': !runtime_1.exists(json, 'indirizzoResidenza') ? undefined : _1.IndirizzoAnagrafeDtoFromJSON(json['indirizzoResidenza']),
    'indirizzoDomicilio': !runtime_1.exists(json, 'indirizzoDomicilio') ? undefined : _1.IndirizzoAnagrafeDtoFromJSON(json['indirizzoDomicilio']),
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.AnagraficaRapportoDtoFromJSON)
  };
}
exports.DatiAnagraficiDtoFromJSONTyped = DatiAnagraficiDtoFromJSONTyped;
function DatiAnagraficiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idVirtuale': value.idVirtuale,
    'codiceAlias': value.codiceAlias,
    'modoAutenticazione': value.modoAutenticazione,
    'cellulare': value.cellulare,
    'email': value.email,
    'telefonoFisso': _1.TelefonoFissoDtoToJSON(value.telefonoFisso),
    'indirizzoResidenza': _1.IndirizzoAnagrafeDtoToJSON(value.indirizzoResidenza),
    'indirizzoDomicilio': _1.IndirizzoAnagrafeDtoToJSON(value.indirizzoDomicilio),
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.AnagraficaRapportoDtoToJSON)
  };
}
exports.DatiAnagraficiDtoToJSON = DatiAnagraficiDtoToJSON;
/**
* @export
* @enum {string}
*/
var DatiAnagraficiDtoModoAutenticazioneEnum;
(function (DatiAnagraficiDtoModoAutenticazioneEnum) {
  DatiAnagraficiDtoModoAutenticazioneEnum["FULL"] = "FULL";
  DatiAnagraficiDtoModoAutenticazioneEnum["LIGTH"] = "LIGTH";
})(DatiAnagraficiDtoModoAutenticazioneEnum = exports.DatiAnagraficiDtoModoAutenticazioneEnum || (exports.DatiAnagraficiDtoModoAutenticazioneEnum = {}));