"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRevocaRevocaSDDDtoFromJSON = exports.FiltroMandatoSDDFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
function FiltroMandatoSDDFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'statoMandato': !(0, helpers_1.exists)(json, 'statoMandato') ? [] : json.statoMandato,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? undefined : json.rapportoSelezionato
  };
}
exports.FiltroMandatoSDDFromJSONTyped = FiltroMandatoSDDFromJSONTyped;
function FormRevocaRevocaSDDDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step
  };
}
exports.FormRevocaRevocaSDDDtoFromJSON = FormRevocaRevocaSDDDtoFromJSON;