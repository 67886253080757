import { RiepilogoPatrimonioDisponibilitaDto } from "@sparkasse/commons";
import { currencyFormat, defaultCurrency } from "helpers/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import { ChartData } from "./patrimonio.view";
import PatrimonioSection from "./patrimonioComponent/patrimonioSection.component";

interface Props {
  chartDataPatrimonioLordo?: Array<ChartData>
  chartDataFinanzamenti?: Array<ChartData>
  chartDataDettaglio?: Array<ChartData>
  onSelectPatrimonio?: (tipologia: string) => void
  onBack?: () => void
  tipologiaSelezionata: string | undefined
  patrimonioTotaleDisponibilita: RiepilogoPatrimonioDisponibilitaDto | undefined
  patrimonioTotaleFinanziamenti: number | undefined
  chartSize?: string
  totaleLiquidita: number | undefined
  totaleAssicurazioni: number | undefined
  totaleInvestimenti: number | undefined
  totaleGestionePatrimoniali: number | undefined
}

const PatrimonioChartView: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    chartDataPatrimonioLordo,
    chartDataFinanzamenti,
    chartDataDettaglio,
    onSelectPatrimonio,
    onBack,
    tipologiaSelezionata,
    patrimonioTotaleDisponibilita,
    patrimonioTotaleFinanziamenti,
    chartSize,
    totaleLiquidita,
    totaleAssicurazioni,
    totaleInvestimenti,
    totaleGestionePatrimoniali
  } = props;

  const datiPatrimonioLordoPresenti = chartDataPatrimonioLordo?.reduce((a, b) => a || !!b?.importo, false);
  const datiFinanziamentiPresenti = chartDataFinanzamenti?.reduce((a, b) => a || !!b?.importo, false);
  const datiDettaglioPresenti = (chartDataDettaglio?.length || 0) > 0;

  const getTotalTipologia = (tipologia: string | undefined): number => {
    if (tipologia === "bankingLiquidita") {
      return totaleLiquidita || 0;
    } else if (tipologia === "investimenti") {
      return totaleInvestimenti || 0;
    } else if (tipologia === "assicurazioni") {
      return totaleAssicurazioni || 0;
    } else if (tipologia === 'GP') {
      return totaleGestionePatrimoniali || 0;
    }
    return 0;
  };

  return (
    <>

      {chartDataPatrimonioLordo && datiPatrimonioLordoPresenti &&
        <>

          {!datiDettaglioPresenti && patrimonioTotaleDisponibilita?.disponibilitaLordo != null &&
            <PatrimonioSection
              data={chartDataPatrimonioLordo}
              total={patrimonioTotaleDisponibilita.disponibilitaLordo}
              onSelectPatrimonio={onSelectPatrimonio}
              onBack={onBack}
              patrimonioTitle={t("patrimonio.lordo.title")}
              patrimonioTotale={t(`patrimonio.lordo.totale`)}
              className={datiPatrimonioLordoPresenti ? datiFinanziamentiPresenti ? "widget-patrimonio-twin" : "" : "widget-patrimonio-no-data"}
              chartSize={chartSize}
            />
          }

          {chartDataDettaglio && datiDettaglioPresenti &&
            <PatrimonioSection
              isDettaglio={true}
              data={chartDataDettaglio}
              total={getTotalTipologia(tipologiaSelezionata)}
              onSelectPatrimonio={onSelectPatrimonio}
              onBack={onBack}
              patrimonioTitle={t("patrimonio.lordo.title")}
              patrimonioTotale={t(`patrimonio.totale.${tipologiaSelezionata}`)}
              className={datiPatrimonioLordoPresenti ? datiFinanziamentiPresenti ? "widget-patrimonio-twin" : "" : "widget-patrimonio-no-data"}
              chartSize={chartSize}
            />
          }

        </>
      }

      {chartDataFinanzamenti && datiFinanziamentiPresenti && patrimonioTotaleFinanziamenti != null &&
        <PatrimonioSection
          data={chartDataFinanzamenti}
          total={patrimonioTotaleFinanziamenti}
          patrimonioTitle={t("patrimonio.finanziamenti.title")}
          patrimonioTotale={t("patrimonio.totale.finanziamenti")}
          className={datiPatrimonioLordoPresenti ? datiFinanziamentiPresenti ? "widget-patrimonio-twin" : "widget-patrimonio-no-data" : ""}
          chartSize={chartSize}
        />
      }

      <div className="patrimoniText">
        <p>
          {t("patrimonio.paragraph")}
        </p>
      </div>

      <div className="patrimonio-totaleInfo">
        <p className="patrimonio-totaleInfo_netto">
          <span className="patrimonio-totaleInfo_netto_label">
            {`${t("patrimonio.lordo.totaleNetto")}: `}
          </span>
          <span
            className="patrimonio-totaleInfo_netto_value"
            style={{ color: Math.sign(patrimonioTotaleDisponibilita?.disponibilitaNetto || 0) === 1 ? '#006731' : '#b2001c' }}
          >
            {currencyFormat(patrimonioTotaleDisponibilita?.disponibilitaNetto)}
            <span className="patrimonio-totaleInfo_netto_currency">{` ${defaultCurrency}`}</span>
          </span>
        </p>
        <div className="patrimonio-totaleInfo_values">

          <p className="patrimonio-totaleInfo_values_lordo">
            <span className="patrimonio-totaleInfo_values_lordo_label">
              {`${t("patrimonio.lordo.title")}: `}
            </span>
            <span className="patrimonio-totaleInfo_values_lordo_value">
              {currencyFormat(patrimonioTotaleDisponibilita?.disponibilitaLordo)}
              <span className="patrimonio-totaleInfo_netto_currency">{` ${defaultCurrency}`}</span>
            </span>
          </p>

          <p className="patrimonio-totaleInfo_values_lordo">
            <span className="patrimonio-totaleInfo_values_lordo_label">
              {`${t("patrimonio.totale.finanziamenti")}: `}
            </span>
            <span className="patrimonio-totaleInfo_values_lordo_value">
              {currencyFormat(Math.abs(patrimonioTotaleFinanziamenti || 0))}
              <span className="patrimonio-totaleInfo_netto_currency">{` ${defaultCurrency}`}</span>
            </span>
          </p>

        </div>
      </div>

    </>
  )
}
export default PatrimonioChartView
