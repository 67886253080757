"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxMIFIDActionTypes = exports.FormMIFIDDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function FormMIFIDDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, api_rest_1.exists)(json, 'step') ? 1 : json.step,
    'domandaSelezionata': !(0, api_rest_1.exists)(json, 'domandaSelezionata') ? (0, api_rest_1.QuestionDtoFromJSON)({}) : json.domandaSelezionata,
    'sezioneSelezionata': !(0, api_rest_1.exists)(json, 'sezioneSelezionata') ? (0, api_rest_1.SectionDtoFromJSON)({}) : json.sezioneSelezionata
  };
}
exports.FormMIFIDDtoFromJSONTyped = FormMIFIDDtoFromJSONTyped;
var EReduxMIFIDActionTypes;
(function (EReduxMIFIDActionTypes) {
  EReduxMIFIDActionTypes["MIFID_GET_RISPOSTE_START"] = "MIFID_GET_RISPOSTE_START";
  EReduxMIFIDActionTypes["MIFID_GET_RISPOSTE_SUCCESS"] = "MIFID_GET_RISPOSTE_SUCCESS";
  EReduxMIFIDActionTypes["MIFID_EXPORT_REPORT_START"] = "MIFID_EXPORT_REPORT_START";
  EReduxMIFIDActionTypes["MIFID_EXPORT_REPORT_SUCCESS"] = "MIFID_EXPORT_REPORT_SUCCESS";
  EReduxMIFIDActionTypes["MIFID_SALVA_RISPOSTE_START"] = "MIFID_SALVA_RISPOSTE_START";
  EReduxMIFIDActionTypes["MIFID_SALVA_RISPOSTE_SUCCESS"] = "MIFID_SALVA_RISPOSTE_SUCCESS";
  EReduxMIFIDActionTypes["MIFID_SALVA_INCOERENZA_DA_IGNORARE"] = "MIFID_SALVA_INCOERENZA_DA_IGNORARE";
  EReduxMIFIDActionTypes["MIFID_RIMUOVI_INCOERENZE_DA_IGNORARE"] = "MIFID_RIMUOVI_INCOERENZE_DA_IGNORARE";
  EReduxMIFIDActionTypes["MIFID_GET_RAPPORTI_START"] = "MIFID_GET_RAPPORTI_START";
  EReduxMIFIDActionTypes["MIFID_GET_RAPPORTI_SUCCESS"] = "MIFID_GET_RAPPORTI_SUCCESS";
  EReduxMIFIDActionTypes["MIFID_RESET_RISPOSTE_QUESTIONARIO"] = "MIFID_RESET_RISPOSTE_QUESTIONARIO";
  EReduxMIFIDActionTypes["MIFID_SALVA_SEZIONE_QUESTIONARIO"] = "MIFID_SALVA_SEZIONE_QUESTIONARIO";
  EReduxMIFIDActionTypes["MIFID_SET_DOMANDA_SELEZIONATA"] = "MIFID_SET_DOMANDA_SELEZIONATA";
  EReduxMIFIDActionTypes["MIFID_SET_STEP"] = "MIFID_SET_STEP";
  EReduxMIFIDActionTypes["MIFID_SET_SEZIONE_SELEZIONATA"] = "MIFID_SET_SEZIONE_SELEZIONATA";
  EReduxMIFIDActionTypes["MIFID_SET_RISPOSTA_SINGOLA"] = "MIFID_SET_RISPOSTA_SINGOLA";
  EReduxMIFIDActionTypes["MIFID_SALVA_RISPOSTA_A_DOMANDA"] = "MIFID_SALVA_RISPOSTA_A_DOMANDA";
  EReduxMIFIDActionTypes["MIFID_SALVA_TABELLA"] = "MIFID_SALVA_TABELLA";
  EReduxMIFIDActionTypes["MIFID_SALVA_RISPOSTA_CHECKBOX"] = "MIFID_SALVA_RISPOSTA_CHECKBOX";
  EReduxMIFIDActionTypes["MIFID_AGGIUNGI_RISPOSTA_A_FORM_RISPOSTE"] = "MIFID_AGGIUNGI_RISPOSTA_A_FORM_RISPOSTE";
  EReduxMIFIDActionTypes["MIFID_FORM_SET_ANSWERS"] = "MIFID_FORM_SET_ANSWERS";
  EReduxMIFIDActionTypes["MIFID_FORM_SET_ANSWER_VALUE"] = "MIFID_FORM_SET_ANSWER_VALUE";
  EReduxMIFIDActionTypes["MIFID_HISTORY_SET_ANSWERS"] = "MIFID_HISTORY_SET_ANSWERS";
  EReduxMIFIDActionTypes["MIFID_FORM_RESET_ANSWERS"] = "MIFID_FORM_RESET_ANSWERS";
  EReduxMIFIDActionTypes["MIFID_FORM_RESET"] = "MIFID_FORM_RESET";
  EReduxMIFIDActionTypes["MIFID_RESET"] = "MIFID_RESET";
  EReduxMIFIDActionTypes["MIFID_RESET_NO_RAPPORTI"] = "MIFID_RESET_NO_RAPPORTI";
})(EReduxMIFIDActionTypes = exports.EReduxMIFIDActionTypes || (exports.EReduxMIFIDActionTypes = {}));