"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CalendarRequestToJSON = exports.CalendarRequestFromJSONTyped = exports.CalendarRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function CalendarRequestFromJSON(json) {
  return CalendarRequestFromJSONTyped(json, false);
}
exports.CalendarRequestFromJSON = CalendarRequestFromJSON;
function CalendarRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'bookingDelay': !runtime_1.exists(json, 'bookingDelay') ? undefined : json['bookingDelay'],
    'branch': !runtime_1.exists(json, 'branch') ? undefined : json['branch'],
    'counselorGraphId': !runtime_1.exists(json, 'counselorGraphId') ? undefined : json['counselorGraphId'],
    'customerType': !runtime_1.exists(json, 'customerType') ? undefined : json['customerType'],
    'day': !runtime_1.exists(json, 'day') ? undefined : json['day'],
    'eventType': json['eventType'],
    'getVirtualBranchUsers': !runtime_1.exists(json, 'getVirtualBranchUsers') ? undefined : json['getVirtualBranchUsers'],
    'serviceProductId': !runtime_1.exists(json, 'serviceProductId') ? undefined : json['serviceProductId']
  };
}
exports.CalendarRequestFromJSONTyped = CalendarRequestFromJSONTyped;
function CalendarRequestToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'bookingDelay': value.bookingDelay,
    'branch': value.branch,
    'counselorGraphId': value.counselorGraphId,
    'customerType': value.customerType,
    'day': value.day,
    'eventType': value.eventType,
    'getVirtualBranchUsers': value.getVirtualBranchUsers,
    'serviceProductId': value.serviceProductId
  };
}
exports.CalendarRequestToJSON = CalendarRequestToJSON;