"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificaTributiDtoToJSON = exports.NotificaTributiDtoFromJSONTyped = exports.NotificaTributiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function NotificaTributiDtoFromJSON(json) {
  return NotificaTributiDtoFromJSONTyped(json, false);
}
exports.NotificaTributiDtoFromJSON = NotificaTributiDtoFromJSON;
function NotificaTributiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoNotifica': !runtime_1.exists(json, 'tipoNotifica') ? undefined : json['tipoNotifica'],
    'listaTributi': !runtime_1.exists(json, 'listaTributi') ? undefined : json['listaTributi'].map(_1.PagamentoTributiDtoFromJSON)
  };
}
exports.NotificaTributiDtoFromJSONTyped = NotificaTributiDtoFromJSONTyped;
function NotificaTributiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoNotifica': value.tipoNotifica,
    'listaTributi': value.listaTributi === undefined ? undefined : value.listaTributi.map(_1.PagamentoTributiDtoToJSON)
  };
}
exports.NotificaTributiDtoToJSON = NotificaTributiDtoToJSON;