"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiTitoloConMercatoDtoToJSON = exports.DatiTitoloConMercatoDtoFromJSONTyped = exports.DatiTitoloConMercatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiTitoloConMercatoDtoFromJSON(json) {
  return DatiTitoloConMercatoDtoFromJSONTyped(json, false);
}
exports.DatiTitoloConMercatoDtoFromJSON = DatiTitoloConMercatoDtoFromJSON;
function DatiTitoloConMercatoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'descrizioneTitolo': !runtime_1.exists(json, 'descrizioneTitolo') ? undefined : json['descrizioneTitolo'],
    'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
    'mercatoOrdine': !runtime_1.exists(json, 'mercatoOrdine') ? undefined : json['mercatoOrdine'],
    'mercatoEsterno': !runtime_1.exists(json, 'mercatoEsterno') ? undefined : json['mercatoEsterno'],
    'prezzoCorrente': !runtime_1.exists(json, 'prezzoCorrente') ? undefined : json['prezzoCorrente'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'titoloTrattabile': !runtime_1.exists(json, 'titoloTrattabile') ? undefined : json['titoloTrattabile'],
    'indicativo': !runtime_1.exists(json, 'indicativo') ? undefined : json['indicativo'],
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    'isPreferred': !runtime_1.exists(json, 'isPreferred') ? undefined : json['isPreferred']
  };
}
exports.DatiTitoloConMercatoDtoFromJSONTyped = DatiTitoloConMercatoDtoFromJSONTyped;
function DatiTitoloConMercatoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'descrizioneTitolo': value.descrizioneTitolo,
    'codiceIsin': value.codiceIsin,
    'mercatoOrdine': value.mercatoOrdine,
    'mercatoEsterno': value.mercatoEsterno,
    'prezzoCorrente': value.prezzoCorrente,
    'titolo': value.titolo,
    'titoloTrattabile': value.titoloTrattabile,
    'indicativo': value.indicativo,
    'descrizione': value.descrizione,
    'isPreferred': value.isPreferred
  };
}
exports.DatiTitoloConMercatoDtoToJSON = DatiTitoloConMercatoDtoToJSON;