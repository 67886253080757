"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bozzeReducer = void 0;
const __1 = require("..");
const bozze_types_1 = require("./bozze.types");
const api_rest_1 = require("@sparkasse/api-rest");
const initialState = {
  parametri: (0, api_rest_1.ParametriBozzeDtoFromJSON)({}),
  form: (0, bozze_types_1.FormBozzeDtoFromJSONTyped)({}),
  bozze: (0, api_rest_1.BozzeWrapperDtoFromJSON)({})
};
const bozzeReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.BOZZE_GET_PARAMETRI_SUCCESS:
      return {
        ...initialState,
        parametri: action.payload
      };
    case __1.EReduxActionTypes.BOZZE_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.BOZZE_SET_DESCRIZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          descrizione: action.payload
        }
      };
    case __1.EReduxActionTypes.BOZZE_SET_TIPOLOGIA:
      return {
        ...state,
        form: {
          ...state.form,
          tipologia: action.payload
        }
      };
    case __1.EReduxActionTypes.BOZZE_GET_BOZZE_START:
      return {
        ...state,
        bozze: (0, api_rest_1.BozzeWrapperDtoFromJSON)({})
      };
    case __1.EReduxActionTypes.BOZZE_GET_BOZZE_SUCCESS:
      return {
        ...state,
        bozze: action.payload
      };
    case __1.EReduxActionTypes.BOZZE_SET_ACCORDION_FILTRI_OPENED:
      return {
        ...state,
        form: {
          ...state.form,
          accordionFiltriOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.BOZZE_SET_TUTTI_RAPPORTI:
      return {
        ...state,
        form: {
          ...state.form,
          tuttiRapporti: action.payload
        }
      };
    case __1.EReduxActionTypes.BOZZE_ELIMINA_BOZZA_ARCHIVIO_SUCCESS:
      const newBozze = state.bozze && state.bozze.bozze ? state.bozze.bozze.filter(val => val.id !== action.payload) : [];
      return {
        ...state,
        bozze: {
          ...state.bozze,
          bozze: newBozze
        }
      };
    case __1.EReduxActionTypes.BOZZE_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.bozzeReducer = bozzeReducer;
exports.default = exports.bozzeReducer;