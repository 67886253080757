"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElencoMandatiSDDPdfTypeEnum = exports.MandatiSddControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MandatiSddControllerApi extends runtime.BaseAPI {
  /**
   * Archivio Mandati SDD
   */
  archivioMandatiSddRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filterMandatiDto === null || requestParameters.filterMandatiDto === undefined) {
        throw new runtime.RequiredError('filterMandatiDto', 'Required parameter requestParameters.filterMandatiDto was null or undefined when calling archivioMandatiSdd.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mandatisdd/archivio",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FilterMandatiDtoToJSON(requestParameters.filterMandatiDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.MandatoSDDDtoFromJSON));
    });
  }
  /**
   * Archivio Mandati SDD
   */
  archivioMandatiSdd(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.archivioMandatiSddRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva per la revoca
   */
  authenticationRevocationInformationMandatiSddRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationRevocationInformationMandatiSdd.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mandatisdd/infoautenticazione/{idDispositiva}/revoca".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva per la revoca
   */
  authenticationRevocationInformationMandatiSdd(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationRevocationInformationMandatiSddRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Export in pdf del dettaglio del mandati in base ai criteri inviati per il rapporto indicato
   */
  dettaglioMandatiSDDPdfRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filterMandatiDto === null || requestParameters.filterMandatiDto === undefined) {
        throw new runtime.RequiredError('filterMandatiDto', 'Required parameter requestParameters.filterMandatiDto was null or undefined when calling dettaglioMandatiSDDPdf.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mandatisdd/export/pdf",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FilterMandatiDtoToJSON(requestParameters.filterMandatiDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in pdf del dettaglio del mandati in base ai criteri inviati per il rapporto indicato
   */
  dettaglioMandatiSDDPdf(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioMandatiSDDPdfRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Dettaglio di un Mandato SDD
   */
  dettaglioMandatoSddRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.contoAddebito === null || requestParameters.contoAddebito === undefined) {
        throw new runtime.RequiredError('contoAddebito', 'Required parameter requestParameters.contoAddebito was null or undefined when calling dettaglioMandatoSdd.');
      }
      if (requestParameters.idMandato === null || requestParameters.idMandato === undefined) {
        throw new runtime.RequiredError('idMandato', 'Required parameter requestParameters.idMandato was null or undefined when calling dettaglioMandatoSdd.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mandatisdd/{contoAddebito}/{idMandato}".replace("{".concat("contoAddebito", "}"), encodeURIComponent(String(requestParameters.contoAddebito))).replace("{".concat("idMandato", "}"), encodeURIComponent(String(requestParameters.idMandato))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.MandatoSDDDtoFromJSON(jsonValue));
    });
  }
  /**
   * Dettaglio di un Mandato SDD
   */
  dettaglioMandatoSdd(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioMandatoSddRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Export in pdf o excel dei mandati in base ai criteri inviati per il rapporto indicato
   */
  elencoMandatiSDDPdfRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling elencoMandatiSDDPdf.');
      }
      if (requestParameters.filterMandatiDto === null || requestParameters.filterMandatiDto === undefined) {
        throw new runtime.RequiredError('filterMandatiDto', 'Required parameter requestParameters.filterMandatiDto was null or undefined when calling elencoMandatiSDDPdf.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mandatisdd/export/elenco/{type}".replace("{".concat("type", "}"), encodeURIComponent(String(requestParameters.type))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FilterMandatiDtoToJSON(requestParameters.filterMandatiDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in pdf o excel dei mandati in base ai criteri inviati per il rapporto indicato
   */
  elencoMandatiSDDPdf(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.elencoMandatiSDDPdfRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di revoca mandato SDD
   */
  operazioneAutenticataMandatiSddRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.mandatiSddRevocaRequestIn === null || requestParameters.mandatiSddRevocaRequestIn === undefined) {
        throw new runtime.RequiredError('mandatiSddRevocaRequestIn', 'Required parameter requestParameters.mandatiSddRevocaRequestIn was null or undefined when calling operazioneAutenticataMandatiSdd.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/mandatisdd/autorizza/revoca",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.MandatiSddRevocaRequestInToJSON(requestParameters.mandatiSddRevocaRequestIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di revoca mandato SDD
   */
  operazioneAutenticataMandatiSdd(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.operazioneAutenticataMandatiSddRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri Mandati SDD
   */
  parametersMandatiSddRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/mandatisdd/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriMandatiSDDDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri Mandati SDD
   */
  parametersMandatiSdd() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametersMandatiSddRaw();
      return yield response.value();
    });
  }
}
exports.MandatiSddControllerApi = MandatiSddControllerApi;
/**
    * @export
    * @enum {string}
    */
var ElencoMandatiSDDPdfTypeEnum;
(function (ElencoMandatiSDDPdfTypeEnum) {
  ElencoMandatiSDDPdfTypeEnum["pdf"] = "pdf";
  ElencoMandatiSDDPdfTypeEnum["excel"] = "excel";
})(ElencoMandatiSDDPdfTypeEnum = exports.ElencoMandatiSDDPdfTypeEnum || (exports.ElencoMandatiSDDPdfTypeEnum = {}));