"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NaturaGiuridicaDtoToJSON = exports.NaturaGiuridicaDtoFromJSONTyped = exports.NaturaGiuridicaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function NaturaGiuridicaDtoFromJSON(json) {
  return NaturaGiuridicaDtoFromJSONTyped(json, false);
}
exports.NaturaGiuridicaDtoFromJSON = NaturaGiuridicaDtoFromJSON;
function NaturaGiuridicaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione']
  };
}
exports.NaturaGiuridicaDtoFromJSONTyped = NaturaGiuridicaDtoFromJSONTyped;
function NaturaGiuridicaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'nome': value.nome,
    'codice': value.codice,
    'descrizione': value.descrizione
  };
}
exports.NaturaGiuridicaDtoToJSON = NaturaGiuridicaDtoToJSON;