"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiPagamentoCommonDtoToJSON = exports.DatiPagamentoCommonDtoFromJSONTyped = exports.DatiPagamentoCommonDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiPagamentoCommonDtoFromJSON(json) {
  return DatiPagamentoCommonDtoFromJSONTyped(json, false);
}
exports.DatiPagamentoCommonDtoFromJSON = DatiPagamentoCommonDtoFromJSON;
function DatiPagamentoCommonDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'ente': !runtime_1.exists(json, 'ente') ? undefined : json['ente'],
    'codiceServizio': !runtime_1.exists(json, 'codiceServizio') ? undefined : json['codiceServizio'],
    'numeroAvviso': !runtime_1.exists(json, 'numeroAvviso') ? undefined : json['numeroAvviso'],
    'importoQuietanza': !runtime_1.exists(json, 'importoQuietanza') ? undefined : json['importoQuietanza'],
    'importoBollettino': !runtime_1.exists(json, 'importoBollettino') ? undefined : json['importoBollettino'],
    'commissioni': !runtime_1.exists(json, 'commissioni') ? undefined : json['commissioni'],
    'filialeOperazione': !runtime_1.exists(json, 'filialeOperazione') ? undefined : json['filialeOperazione'],
    'dataQuietanza': !runtime_1.exists(json, 'dataQuietanza') ? undefined : new Date(json['dataQuietanza']),
    'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : new Date(json['dataContabile']),
    'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : new Date(json['dataValuta']),
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'dataPrenotazione': !runtime_1.exists(json, 'dataPrenotazione') ? undefined : new Date(json['dataPrenotazione']),
    'descrizioneCodiceSia': !runtime_1.exists(json, 'descrizioneCodiceSia') ? undefined : json['descrizioneCodiceSia'],
    'numeroQuietanza': !runtime_1.exists(json, 'numeroQuietanza') ? undefined : json['numeroQuietanza'],
    'chiaveQuietanza': !runtime_1.exists(json, 'chiaveQuietanza') ? undefined : _1.ChiaveQuietanzaDtoFromJSON(json['chiaveQuietanza'])
  };
}
exports.DatiPagamentoCommonDtoFromJSONTyped = DatiPagamentoCommonDtoFromJSONTyped;
function DatiPagamentoCommonDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'divisa': value.divisa,
    'causale': value.causale,
    'ente': value.ente,
    'codiceServizio': value.codiceServizio,
    'numeroAvviso': value.numeroAvviso,
    'importoQuietanza': value.importoQuietanza,
    'importoBollettino': value.importoBollettino,
    'commissioni': value.commissioni,
    'filialeOperazione': value.filialeOperazione,
    'dataQuietanza': value.dataQuietanza === undefined ? undefined : value.dataQuietanza.toISOString(),
    'dataContabile': value.dataContabile === undefined ? undefined : value.dataContabile.toISOString(),
    'dataValuta': value.dataValuta === undefined ? undefined : value.dataValuta.toISOString(),
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'dataPrenotazione': value.dataPrenotazione === undefined ? undefined : value.dataPrenotazione.toISOString(),
    'descrizioneCodiceSia': value.descrizioneCodiceSia,
    'numeroQuietanza': value.numeroQuietanza,
    'chiaveQuietanza': _1.ChiaveQuietanzaDtoToJSON(value.chiaveQuietanza)
  };
}
exports.DatiPagamentoCommonDtoToJSON = DatiPagamentoCommonDtoToJSON;