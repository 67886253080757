"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formQuotazioniWatchlist = exports.elencoQuotazioniWatchlist = exports.elencoWatchlist = void 0;
const reselect_1 = require("reselect");
const watchlistReducer = state => state;
exports.elencoWatchlist = (0, reselect_1.createSelector)(watchlistReducer, state => {
  return state.watchlist;
});
exports.elencoQuotazioniWatchlist = (0, reselect_1.createSelector)(watchlistReducer, state => {
  return state.elenco;
});
exports.formQuotazioniWatchlist = (0, reselect_1.createSelector)(watchlistReducer, state => {
  return state.form;
});