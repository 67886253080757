"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelfResetStatusRequestDtoToJSON = exports.SelfResetStatusRequestDtoFromJSONTyped = exports.SelfResetStatusRequestDtoFromJSON = void 0;
function SelfResetStatusRequestDtoFromJSON(json) {
  return SelfResetStatusRequestDtoFromJSONTyped(json, false);
}
exports.SelfResetStatusRequestDtoFromJSON = SelfResetStatusRequestDtoFromJSON;
function SelfResetStatusRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceUtente': json['codiceUtente'],
    'vendorId': json['vendorId']
  };
}
exports.SelfResetStatusRequestDtoFromJSONTyped = SelfResetStatusRequestDtoFromJSONTyped;
function SelfResetStatusRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceUtente': value.codiceUtente,
    'vendorId': value.vendorId
  };
}
exports.SelfResetStatusRequestDtoToJSON = SelfResetStatusRequestDtoToJSON;