"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormAggiungiContattoDtoFromJSON = exports.FormRecapitoFromJSON = exports.FormContattoEmailFromJSON = void 0;
const helpers_1 = require("../helpers");
function FormContattoEmailFromJSON() {
  return {
    email: '',
    emailDirty: false,
    emailError: ''
  };
}
exports.FormContattoEmailFromJSON = FormContattoEmailFromJSON;
function FormRecapitoFromJSON() {
  return {
    recapito: '',
    recapitoDirty: false,
    recapitoError: '',
    etichetta: '',
    etichettaDirty: false,
    etichettaError: '',
    dispensaCredenziale: false
  };
}
exports.FormRecapitoFromJSON = FormRecapitoFromJSON;
function FormAggiungiContattoDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !(0, helpers_1.exists)(json, 'id') ? undefined : json.id,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'alias': !(0, helpers_1.exists)(json, 'alias') ? undefined : json.alias,
    'aliasDirty': !(0, helpers_1.exists)(json, 'aliasDirty') ? false : json.aliasDirty,
    'aliasError': !(0, helpers_1.exists)(json, 'aliasError') ? 'invalid.required' : json.aliasError,
    'dispensaCredenziale': !(0, helpers_1.exists)(json, 'dispensaCredenziale') ? false : json.dispensaCredenziale,
    'email': !(0, helpers_1.exists)(json, 'email') ? [FormRecapitoFromJSON()] : json.email,
    'iban': !(0, helpers_1.exists)(json, 'iban') ? [FormRecapitoFromJSON()] : json.iban,
    'telefono': !(0, helpers_1.exists)(json, 'telefono') ? [FormRecapitoFromJSON()] : json.telefono,
    'targa': !(0, helpers_1.exists)(json, 'targa') ? [FormRecapitoFromJSON()] : json.targa,
    'altoAdigePass': !(0, helpers_1.exists)(json, 'altoAdigePass') ? [FormRecapitoFromJSON()] : json.altoAdigePass,
    'nome': !(0, helpers_1.exists)(json, 'nome') ? undefined : json.nome,
    'nomeDirty': !(0, helpers_1.exists)(json, 'nomeDirty') ? false : json.nomeDirty,
    'nomeError': !(0, helpers_1.exists)(json, 'nomeError') ? '' : json.nomeError,
    'cognome': !(0, helpers_1.exists)(json, 'cognome') ? undefined : json.cognome,
    'cognomeDirty': !(0, helpers_1.exists)(json, 'cognomeDirty') ? false : json.cognomeDirty,
    'cognomeError': !(0, helpers_1.exists)(json, 'cognomeError') ? '' : json.cognomeError,
    'dataDiNascita': !(0, helpers_1.exists)(json, 'dataDiNascita') ? undefined : json.dataDiNascita,
    'dataDiNascitaDirty': !(0, helpers_1.exists)(json, 'dataDiNascitaDirty') ? false : json.dataDiNascitaDirty,
    'dataDiNascitaError': !(0, helpers_1.exists)(json, 'dataDiNascitaError') ? '' : json.dataDiNascitaError,
    'sesso': !(0, helpers_1.exists)(json, 'sesso') ? undefined : json.sesso,
    'sessoDirty': !(0, helpers_1.exists)(json, 'sessoDirty') ? false : json.sessoDirty,
    'sessoError': !(0, helpers_1.exists)(json, 'sessoError') ? '' : json.sessoError,
    'luogoNascita': !(0, helpers_1.exists)(json, 'luogoNascita') ? undefined : json.luogoNascita,
    'luogoNascitaDirty': !(0, helpers_1.exists)(json, 'luogoNascitaDirty') ? false : json.luogoNascitaDirty,
    'luogoNascitaError': !(0, helpers_1.exists)(json, 'luogoNascitaError') ? '' : json.luogoNascitaError,
    'provinciaNascita': !(0, helpers_1.exists)(json, 'provinciaNascita') ? undefined : json.provinciaNascita,
    'provinciaNascitaDirty': !(0, helpers_1.exists)(json, 'provinciaNascitaDirty') ? false : json.provinciaNascitaDirty,
    'provinciaNascitaError': !(0, helpers_1.exists)(json, 'provinciaNascitaError') ? '' : json.provinciaNascitaError,
    'indirizzo': !(0, helpers_1.exists)(json, 'indirizzo') ? undefined : json.indirizzo,
    'indirizzoDirty': !(0, helpers_1.exists)(json, 'indirizzoDirty') ? false : json.indirizzoDirty,
    'indirizzoError': !(0, helpers_1.exists)(json, 'indirizzoError') ? '' : json.indirizzoError,
    'comune': !(0, helpers_1.exists)(json, 'comune') ? undefined : json.comune,
    'comuneDirty': !(0, helpers_1.exists)(json, 'comuneDirty') ? false : json.comuneDirty,
    'comuneError': !(0, helpers_1.exists)(json, 'comuneError') ? '' : json.comuneError,
    'cap': !(0, helpers_1.exists)(json, 'cap') ? undefined : json.cap,
    'capDirty': !(0, helpers_1.exists)(json, 'capDirty') ? false : json.capDirty,
    'capError': !(0, helpers_1.exists)(json, 'capError') ? '' : json.capError,
    'provincia': !(0, helpers_1.exists)(json, 'provincia') ? undefined : json.provincia,
    'provinciaDirty': !(0, helpers_1.exists)(json, 'provinciaDirty') ? false : json.provinciaDirty,
    'provinciaError': !(0, helpers_1.exists)(json, 'provinciaError') ? '' : json.provinciaError,
    'cf': !(0, helpers_1.exists)(json, 'cf') ? undefined : json.cf,
    'cfDirty': !(0, helpers_1.exists)(json, 'cfDirty') ? false : json.cfDirty,
    'cfError': !(0, helpers_1.exists)(json, 'cfError') ? '' : json.cfError,
    'dispensaF24': !(0, helpers_1.exists)(json, 'dispensaF24') ? false : json.dispensaF24,
    'piva': !(0, helpers_1.exists)(json, 'piva') ? undefined : json.piva,
    'pivaDirty': !(0, helpers_1.exists)(json, 'pivaDirty') ? false : json.pivaDirty,
    'pivaError': !(0, helpers_1.exists)(json, 'pivaError') ? '' : json.pivaError,
    'accordionDatiPersonaliOpened': !(0, helpers_1.exists)(json, 'accordionDatiPersonaliOpened') ? true : json.accordionDatiPersonaliOpened,
    'accordionDatiBonificoOpened': !(0, helpers_1.exists)(json, 'accordionDatiBonificoOpened') ? false : json.accordionDatiBonificoOpened,
    'accordionDatiRicaricaOpened': !(0, helpers_1.exists)(json, 'accordionDatiRicaricaOpened') ? false : json.accordionDatiRicaricaOpened,
    'accordionDatiBolloOpened': !(0, helpers_1.exists)(json, 'accordionDatiBolloOpened') ? false : json.accordionDatiBolloOpened,
    'accordionDatiAltoAdigePassOpened': !(0, helpers_1.exists)(json, 'accordionDatiAltoAdigePassOpened') ? false : json.accordionDatiAltoAdigePassOpened,
    'accordionDatiF24Opened': !(0, helpers_1.exists)(json, 'accordionDatiF24Opened') ? false : json.accordionDatiF24Opened
  };
}
exports.FormAggiungiContattoDtoFromJSON = FormAggiungiContattoDtoFromJSON;