"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MandatoSDDDtoStatoDisposizioneEnum = exports.MandatoSDDDtoTipoRelazioneEnum = exports.MandatoSDDDtoCodiceStatoEnum = exports.MandatoSDDDtoToJSON = exports.MandatoSDDDtoFromJSONTyped = exports.MandatoSDDDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MandatoSDDDtoFromJSON(json) {
  return MandatoSDDDtoFromJSONTyped(json, false);
}
exports.MandatoSDDDtoFromJSON = MandatoSDDDtoFromJSON;
function MandatoSDDDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'codiceIstituto': !runtime_1.exists(json, 'codiceIstituto') ? undefined : json['codiceIstituto'],
    'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
    'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
    'schema': !runtime_1.exists(json, 'schema') ? undefined : json['schema'],
    'codiceIdentificativoCreditore': !runtime_1.exists(json, 'codiceIdentificativoCreditore') ? undefined : json['codiceIdentificativoCreditore'],
    'codiceMandato': !runtime_1.exists(json, 'codiceMandato') ? undefined : json['codiceMandato'],
    'codiceMandatoVis': !runtime_1.exists(json, 'codiceMandatoVis') ? undefined : json['codiceMandatoVis'],
    'codiceStato': !runtime_1.exists(json, 'codiceStato') ? undefined : json['codiceStato'],
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'tipoCodiceMandato': !runtime_1.exists(json, 'tipoCodiceMandato') ? undefined : json['tipoCodiceMandato'],
    'dataFirma': !runtime_1.exists(json, 'dataFirma') ? undefined : new Date(json['dataFirma']),
    'dataCancelazione': !runtime_1.exists(json, 'dataCancelazione') ? undefined : new Date(json['dataCancelazione']),
    'dataUltimaVariazione': !runtime_1.exists(json, 'dataUltimaVariazione') ? undefined : new Date(json['dataUltimaVariazione']),
    'dataFinal': !runtime_1.exists(json, 'dataFinal') ? undefined : new Date(json['dataFinal']),
    'ibanDebitore': !runtime_1.exists(json, 'ibanDebitore') ? undefined : json['ibanDebitore'],
    'nominativoIban': !runtime_1.exists(json, 'nominativoIban') ? undefined : json['nominativoIban'],
    'bicDebitore': !runtime_1.exists(json, 'bicDebitore') ? undefined : json['bicDebitore'],
    'nominativoDebitore': !runtime_1.exists(json, 'nominativoDebitore') ? undefined : json['nominativoDebitore'],
    'indirizzoDebitore': !runtime_1.exists(json, 'indirizzoDebitore') ? undefined : json['indirizzoDebitore'],
    'codiceIdentificativoDebitore': !runtime_1.exists(json, 'codiceIdentificativoDebitore') ? undefined : json['codiceIdentificativoDebitore'],
    'ibanDebitoreContoAddebito': !runtime_1.exists(json, 'ibanDebitoreContoAddebito') ? undefined : json['ibanDebitoreContoAddebito'],
    'nomeDebitoreFinale': !runtime_1.exists(json, 'nomeDebitoreFinale') ? undefined : json['nomeDebitoreFinale'],
    'codiceIdentificativoDebitoreFinale': !runtime_1.exists(json, 'codiceIdentificativoDebitoreFinale') ? undefined : json['codiceIdentificativoDebitoreFinale'],
    'nominativoCreditore': !runtime_1.exists(json, 'nominativoCreditore') ? undefined : json['nominativoCreditore'],
    'indirizzoCreditore': !runtime_1.exists(json, 'indirizzoCreditore') ? undefined : json['indirizzoCreditore'],
    'codicePaeseCreditore': !runtime_1.exists(json, 'codicePaeseCreditore') ? undefined : json['codicePaeseCreditore'],
    'descrizionePaese': !runtime_1.exists(json, 'descrizionePaese') ? undefined : json['descrizionePaese'],
    'nomeCreditoreFinale': !runtime_1.exists(json, 'nomeCreditoreFinale') ? undefined : json['nomeCreditoreFinale'],
    'codiceIdentificativoCreditoreFinale': !runtime_1.exists(json, 'codiceIdentificativoCreditoreFinale') ? undefined : json['codiceIdentificativoCreditoreFinale'],
    'chiaveRiferimento': !runtime_1.exists(json, 'chiaveRiferimento') ? undefined : json['chiaveRiferimento'],
    'richiestaMandato': !runtime_1.exists(json, 'richiestaMandato') ? undefined : json['richiestaMandato'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'codiceContratto': !runtime_1.exists(json, 'codiceContratto') ? undefined : json['codiceContratto'],
    'tipoMandato': !runtime_1.exists(json, 'tipoMandato') ? undefined : json['tipoMandato'],
    'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : new Date(json['dataInserimento']),
    'dataVariazione': !runtime_1.exists(json, 'dataVariazione') ? undefined : new Date(json['dataVariazione']),
    'filialeInserimento': !runtime_1.exists(json, 'filialeInserimento') ? undefined : json['filialeInserimento'],
    'descrizioneInserimento': !runtime_1.exists(json, 'descrizioneInserimento') ? undefined : json['descrizioneInserimento'],
    'codiceIdentificativoCreditoreOriginale': !runtime_1.exists(json, 'codiceIdentificativoCreditoreOriginale') ? undefined : json['codiceIdentificativoCreditoreOriginale'],
    'codiceMandatoOriginale': !runtime_1.exists(json, 'codiceMandatoOriginale') ? undefined : json['codiceMandatoOriginale'],
    'codiceMandatoOriginaleVis': !runtime_1.exists(json, 'codiceMandatoOriginaleVis') ? undefined : json['codiceMandatoOriginaleVis'],
    'tipoRelazione': !runtime_1.exists(json, 'tipoRelazione') ? undefined : json['tipoRelazione'],
    'dataDecRev': !runtime_1.exists(json, 'dataDecRev') ? undefined : new Date(json['dataDecRev']),
    'descrizioneTipoRelazione': !runtime_1.exists(json, 'descrizioneTipoRelazione') ? undefined : json['descrizioneTipoRelazione'],
    'formaPagamento': !runtime_1.exists(json, 'formaPagamento') ? undefined : json['formaPagamento'],
    'modDataRev': !runtime_1.exists(json, 'modDataRev') ? undefined : json['modDataRev'],
    'dataFirmaCanc': !runtime_1.exists(json, 'dataFirmaCanc') ? undefined : new Date(json['dataFirmaCanc']),
    'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : json['condivisione'].map(_1.CondivisioneDtoFromJSON),
    'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'nominativoIBANDebitoreCC': !runtime_1.exists(json, 'nominativoIBANDebitoreCC') ? undefined : json['nominativoIBANDebitoreCC'],
    'rapporto': !runtime_1.exists(json, 'rapporto') ? undefined : json['rapporto'],
    'mostraDataFirma': !runtime_1.exists(json, 'mostraDataFirma') ? undefined : json['mostraDataFirma'],
    'mostraCodContrattoDebitore': !runtime_1.exists(json, 'mostraCodContrattoDebitore') ? undefined : json['mostraCodContrattoDebitore'],
    'mostraCodiceDebitore': !runtime_1.exists(json, 'mostraCodiceDebitore') ? undefined : json['mostraCodiceDebitore'],
    'mostraBic': !runtime_1.exists(json, 'mostraBic') ? undefined : json['mostraBic'],
    'mostraNonCreditoreEffettivo': !runtime_1.exists(json, 'mostraNonCreditoreEffettivo') ? undefined : json['mostraNonCreditoreEffettivo'],
    'mostraIndirizzoDebitore': !runtime_1.exists(json, 'mostraIndirizzoDebitore') ? undefined : json['mostraIndirizzoDebitore'],
    'mostraIndirizzoCreditore': !runtime_1.exists(json, 'mostraIndirizzoCreditore') ? undefined : json['mostraIndirizzoCreditore'],
    'mostraIdDebitoreEffettivo': !runtime_1.exists(json, 'mostraIdDebitoreEffettivo') ? undefined : json['mostraIdDebitoreEffettivo'],
    'mostraIdCreditoreEffettivo': !runtime_1.exists(json, 'mostraIdCreditoreEffettivo') ? undefined : json['mostraIdCreditoreEffettivo'],
    'mostraNomDebitoreEffettivo': !runtime_1.exists(json, 'mostraNomDebitoreEffettivo') ? undefined : json['mostraNomDebitoreEffettivo'],
    'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
    'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
    'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
    'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione']
  };
}
exports.MandatoSDDDtoFromJSONTyped = MandatoSDDDtoFromJSONTyped;
function MandatoSDDDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'codiceIstituto': value.codiceIstituto,
    'codiceUtente': value.codiceUtente,
    'contoAddebito': value.contoAddebito,
    'schema': value.schema,
    'codiceIdentificativoCreditore': value.codiceIdentificativoCreditore,
    'codiceMandato': value.codiceMandato,
    'codiceMandatoVis': value.codiceMandatoVis,
    'codiceStato': value.codiceStato,
    'stato': value.stato,
    'tipoCodiceMandato': value.tipoCodiceMandato,
    'dataFirma': value.dataFirma === undefined ? undefined : value.dataFirma.toISOString(),
    'dataCancelazione': value.dataCancelazione === undefined ? undefined : value.dataCancelazione.toISOString(),
    'dataUltimaVariazione': value.dataUltimaVariazione === undefined ? undefined : value.dataUltimaVariazione.toISOString(),
    'dataFinal': value.dataFinal === undefined ? undefined : value.dataFinal.toISOString(),
    'ibanDebitore': value.ibanDebitore,
    'nominativoIban': value.nominativoIban,
    'bicDebitore': value.bicDebitore,
    'nominativoDebitore': value.nominativoDebitore,
    'indirizzoDebitore': value.indirizzoDebitore,
    'codiceIdentificativoDebitore': value.codiceIdentificativoDebitore,
    'ibanDebitoreContoAddebito': value.ibanDebitoreContoAddebito,
    'nomeDebitoreFinale': value.nomeDebitoreFinale,
    'codiceIdentificativoDebitoreFinale': value.codiceIdentificativoDebitoreFinale,
    'nominativoCreditore': value.nominativoCreditore,
    'indirizzoCreditore': value.indirizzoCreditore,
    'codicePaeseCreditore': value.codicePaeseCreditore,
    'descrizionePaese': value.descrizionePaese,
    'nomeCreditoreFinale': value.nomeCreditoreFinale,
    'codiceIdentificativoCreditoreFinale': value.codiceIdentificativoCreditoreFinale,
    'chiaveRiferimento': value.chiaveRiferimento,
    'richiestaMandato': value.richiestaMandato,
    'codiceFiscale': value.codiceFiscale,
    'causale': value.causale,
    'codiceContratto': value.codiceContratto,
    'tipoMandato': value.tipoMandato,
    'dataInserimento': value.dataInserimento === undefined ? undefined : value.dataInserimento.toISOString(),
    'dataVariazione': value.dataVariazione === undefined ? undefined : value.dataVariazione.toISOString(),
    'filialeInserimento': value.filialeInserimento,
    'descrizioneInserimento': value.descrizioneInserimento,
    'codiceIdentificativoCreditoreOriginale': value.codiceIdentificativoCreditoreOriginale,
    'codiceMandatoOriginale': value.codiceMandatoOriginale,
    'codiceMandatoOriginaleVis': value.codiceMandatoOriginaleVis,
    'tipoRelazione': value.tipoRelazione,
    'dataDecRev': value.dataDecRev === undefined ? undefined : value.dataDecRev.toISOString(),
    'descrizioneTipoRelazione': value.descrizioneTipoRelazione,
    'formaPagamento': value.formaPagamento,
    'modDataRev': value.modDataRev,
    'dataFirmaCanc': value.dataFirmaCanc === undefined ? undefined : value.dataFirmaCanc.toISOString(),
    'statoDisposizione': value.statoDisposizione,
    'condivisione': value.condivisione === undefined ? undefined : value.condivisione.map(_1.CondivisioneDtoToJSON),
    'bozza': _1.BozzaDtoToJSON(value.bozza),
    'intestazione': value.intestazione,
    'nominativoIBANDebitoreCC': value.nominativoIBANDebitoreCC,
    'rapporto': value.rapporto,
    'mostraDataFirma': value.mostraDataFirma,
    'mostraCodContrattoDebitore': value.mostraCodContrattoDebitore,
    'mostraCodiceDebitore': value.mostraCodiceDebitore,
    'mostraBic': value.mostraBic,
    'mostraNonCreditoreEffettivo': value.mostraNonCreditoreEffettivo,
    'mostraIndirizzoDebitore': value.mostraIndirizzoDebitore,
    'mostraIndirizzoCreditore': value.mostraIndirizzoCreditore,
    'mostraIdDebitoreEffettivo': value.mostraIdDebitoreEffettivo,
    'mostraIdCreditoreEffettivo': value.mostraIdCreditoreEffettivo,
    'mostraNomDebitoreEffettivo': value.mostraNomDebitoreEffettivo,
    'inviato': _1.InviatoDtoToJSON(value.inviato),
    'contoAddebitoIban': value.contoAddebitoIban,
    'contoAddebitoAlias': value.contoAddebitoAlias,
    'ordinanteIntestazione': value.ordinanteIntestazione
  };
}
exports.MandatoSDDDtoToJSON = MandatoSDDDtoToJSON;
/**
* @export
* @enum {string}
*/
var MandatoSDDDtoCodiceStatoEnum;
(function (MandatoSDDDtoCodiceStatoEnum) {
  MandatoSDDDtoCodiceStatoEnum["IN_ESSERE"] = "IN_ESSERE";
  MandatoSDDDtoCodiceStatoEnum["NON_CONFERMATO"] = "NON_CONFERMATO";
  MandatoSDDDtoCodiceStatoEnum["SOSPESO"] = "SOSPESO";
  MandatoSDDDtoCodiceStatoEnum["SCADUTO"] = "SCADUTO";
  MandatoSDDDtoCodiceStatoEnum["ESAURITO"] = "ESAURITO";
  MandatoSDDDtoCodiceStatoEnum["CANCELLATO"] = "CANCELLATO";
})(MandatoSDDDtoCodiceStatoEnum = exports.MandatoSDDDtoCodiceStatoEnum || (exports.MandatoSDDDtoCodiceStatoEnum = {}));
/**
* @export
* @enum {string}
*/
var MandatoSDDDtoTipoRelazioneEnum;
(function (MandatoSDDDtoTipoRelazioneEnum) {
  MandatoSDDDtoTipoRelazioneEnum["S"] = "S";
  MandatoSDDDtoTipoRelazioneEnum["R"] = "R";
})(MandatoSDDDtoTipoRelazioneEnum = exports.MandatoSDDDtoTipoRelazioneEnum || (exports.MandatoSDDDtoTipoRelazioneEnum = {}));
/**
* @export
* @enum {string}
*/
var MandatoSDDDtoStatoDisposizioneEnum;
(function (MandatoSDDDtoStatoDisposizioneEnum) {
  MandatoSDDDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
  MandatoSDDDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
  MandatoSDDDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
  MandatoSDDDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
  MandatoSDDDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
  MandatoSDDDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
  MandatoSDDDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
  MandatoSDDDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
  MandatoSDDDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
  MandatoSDDDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
  MandatoSDDDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
  MandatoSDDDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
  MandatoSDDDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(MandatoSDDDtoStatoDisposizioneEnum = exports.MandatoSDDDtoStatoDisposizioneEnum || (exports.MandatoSDDDtoStatoDisposizioneEnum = {}));