"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetAssetDebtDtoToJSON = exports.WidgetAssetDebtDtoFromJSONTyped = exports.WidgetAssetDebtDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WidgetAssetDebtDtoFromJSON(json) {
  return WidgetAssetDebtDtoFromJSONTyped(json, false);
}
exports.WidgetAssetDebtDtoFromJSON = WidgetAssetDebtDtoFromJSON;
function WidgetAssetDebtDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'data': !runtime_1.exists(json, 'data') ? undefined : json['data'].map(_1.WidgetAssetDebtDataDtoFromJSON),
    'tot': !runtime_1.exists(json, 'tot') ? undefined : json['tot'],
    'get_tot': !runtime_1.exists(json, 'get_tot') ? undefined : json['get_tot']
  };
}
exports.WidgetAssetDebtDtoFromJSONTyped = WidgetAssetDebtDtoFromJSONTyped;
function WidgetAssetDebtDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'data': value.data === undefined ? undefined : value.data.map(_1.WidgetAssetDebtDataDtoToJSON),
    'tot': value.tot,
    'get_tot': value.get_tot
  };
}
exports.WidgetAssetDebtDtoToJSON = WidgetAssetDebtDtoToJSON;