"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.limitiOperativi = exports.parametriLimitiOperativi = void 0;
const reselect_1 = require("reselect");
const limitiOperativiReducer = state => state;
exports.parametriLimitiOperativi = (0, reselect_1.createSelector)(limitiOperativiReducer, state => {
  return state.parametri;
});
exports.limitiOperativi = (0, reselect_1.createSelector)(limitiOperativiReducer, state => {
  return state.limitiOperativi;
});