import { adeguataVerificaRapporti, bloccanti, bloccantiMessaggi, currentLanguage, error, estendiSessione, getBloccantiMessaggi, getMenu, getNotificheMenu, getParametriPostlogin, getParametriProfilo, getRapportiAdeguataVerifica, initI18nAction, isMenuAssistenzaVisible, isMenuComunicazioniVisible, isMenuProfiloVisible, isNotificaMenuAssistenzaVisible, isNotificaMenuComunicazioniVisible, isNotificaMenuProfiloVisible, jwtToken, loaderActive, menuList, MessaggioBodyDto, myBankTokenSelector, noRapporti, notificheMenu, ProfiloMenu, pushPopUp, resetBonificoSepa, resetNoRapporti, setI18nSetCurrentLanguage, setPreferenzeUtente, setStepHomeBonifico, setWebMenu, updateMessaggiBloccanti, waitingSections } from "@sparkasse/commons";
import chiudiBianco from "assets/icons/navigazione/chiudi_white.svg";
import burgerBianco from "assets/icons/navigazione/hamburger_white.svg";
import FullPageNotification from "component/fullPageNotification/fullPageNotification.component";
import Loader from "component/loader/loader.component";
import Logo from "component/logo/logo.component";
import MessaggiBloccantiComponent from "component/messaggiBloccanti/messaggiBloccanti.component";
import NavbarButtons from "component/navbarButtons/navbarButtons.component";
import NoRapportiModal from "component/noRapportiModal/noRapportiModal.component";
import Search from "component/search/search.component";
import SessionHandler from "component/sessionHandler/sessionHandler.component";
import SideBarMenu from "component/SideBarMenu/SidebarMenu";
import Snackbar from "component/snackBar/snackBar.component";
import { getItem, MOCK, setItem } from "index";
import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, RouteComponentProps, useLocation } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { navigateToLogin, navigateToLogout, navigateToPage, WEBPAGE } from "../component/history/history.component";
import { AppState } from "../reducers";
import Home from "./home/home.view";
import PostLoginMessages from "./postLogin/postLogin";
import Rubrica from "./rubrica/rubrica.view";

const Bonifico = lazy(() => import("./bonifico/bonifico.view"));
const BonificoDF = lazy(() => import("./bonificoDF/bonificodf.view"));
const BonificoDeposito = lazy(() => import("./bonificoDeposito/bonificodeposito.view"));
const BonificoVersamentoDeposito = lazy(() => import("./bonificoVersamentoDeposito/bonificoVersamentoDeposito.view"))
const BonificoEstero = lazy(() => import("./bonificoEstero/bonificoEstero.view"));
const BonificoRipetitivo = lazy(() => import("./bonificoRipetitivo/bonificoripetitivo.view"));
const SaldoEMovimenti = lazy(() => import("./saldoemovimenti/saldoemovimenti.view"));
const SaldoEMovimentiDeposito = lazy(() => import("./saldoemovimentideposito/saldoemovimentideposito.view"));
const RevocaBonificoSepa = lazy(() => import("./revocaBonificoSepa/revoca.bonificoSepa.view"));
const RevocaBonificoDF = lazy(() => import("./bonificoDF/revoca.bonificodf.view"));
const RevocaBonificoRipetitivo = lazy(() => import("./bonificoRipetitivo/revoca.bonificoripetitivo.view"));
const ArchivioBozze = lazy(() => import("./archivioBozze/archivioBozze.view"));
const PagamentiEffettuati = lazy(() => import("./pagamentieffettuati/pagamentieffettuati.view"));
const DettaglioPagamento = lazy(() => import("./pagamentieffettuati/dettagliopagamento.view"));
const PagamentiEffettuatiRapportiEstinti = lazy(() => import("./pagamentieffettuati/pagamentieffettuati.rapportiEstinti.view"));
const DettaglioPagamentoRapportiEstinti = lazy(() => import("./pagamentieffettuati/dettaglioPagamento.estinti.view"));
const BonificiRipetitivi = lazy(() => import("./bonificiRipetitivi/bonificiRipetitivi.view"));
const Mav = lazy(() => import("./mav/mav.view"));
const Rav = lazy(() => import("./rav/rav.view"));
const RevocaMav = lazy(() => import("./revocaMav/revocaMav.view"));
const RevocaRav = lazy(() => import("./revocaRav/revocaRav.view"));
const RevocaBolletinoPostale = lazy(() => import("./revocaBolletinoPostale/revoca.BolletinoPostale.view"));
const RevocaBolletinoPremarcato = lazy(() => import("./revocaBollettinoPremarcato/revoca.BollettinoPremarcato.view"));
const RicaricaTelefonica = lazy(() => import("./ricaricaTelefonica/ricaricaTelefonica.view"));
const OperazioniOneClick = lazy(() => import("./operazionioneclick/operazionioneclick.view"));
const BloccoUtente = lazy(() => import("./bloccoUtente/bloccoUtente.view"));
const MassimaliUtente = lazy(() => import("./massimaliUtente/massimaliUtente.view"));
const BancomatPay = lazy(() => import("./bancomatPay/bancomatpay.view"));
const Nexi = lazy(() => import("./nexi/nexi.view"));
const RevocaBonificoDeposito = lazy(() => import("./revocaBonificoDeposito/revoca.bonificoDeposito.view"));
const RevocaBonificoVersamentoDeposito = lazy(() => import("./revocaBonificoVersamentoDeposito/revoca.bonificoVersamentoDeposito.view"));
const ScadenzaTitoli = lazy(() => import("./scadenzaTitoli/scadenzaTitoli.view"));
const BancomatPayMassimali = lazy(() => import("./bancomatPayMassimali/bancomatPayMassimali.view"));
const BloccoCarte = lazy(() => import("./bloccoCarte/bloccoCarte.view"));
const ModificaResidenza = lazy(() => import("./datiPersonali/modificaResidenza/modificaResidenza.view"));
const BollettinoFreccia = lazy(() => import("./BollettinoFreccia"));
const BollettinoFrecciaRevoca = lazy(() => import("./BollettinoFreccia/BollettinoFrecciaRevoca"));
const BollettinoBianco = lazy(() => import("./bollettino-postale/bollettino-bianco/bollettino-bianco.view"));
const BollettinoPremarcato = lazy(() => import("./bollettino-postale/bollettino-premarcato/bollettino-premarcato.view"));
const BolloACI = lazy(() => import("./bolloACI/bolloACI.view"));
const Assegni = lazy(() => import("./assegni/assegni.view"));
const F24Ordinario = lazy(() => import("./f24-ordinario/f24.ordinario.view"));
const F24Semplificato = lazy(() => import("./f24-semplificato/f24.semplificato.view"));
const F24Accise = lazy(() => import("./f24-accise/f24.accise.view"));
const F24Elide = lazy(() => import("./f24-elide/f24.elide.view"));
const CBill = lazy(() => import("./cbill/cbill.view"));
const AddebitoInteressi = lazy(() => import("./addebitointeressi/addebitointeressi.view"));
const RevocaF24Ordinario = lazy(() => import("./revocaF24Ordinario/revoca.f24-ordinario.view"));
const RevocaF24Accise = lazy(() => import("./revocaF24Accise/revoca.f24-accise.view"));
const RevocaF24Elide = lazy(() => import("./revocaF24Elide/revoca.f24-elide.view"));
const RevocaF24Semplificato = lazy(() => import("./revocaF24Semplificato/revoca.f24-semplificato.view"));
const MandatiSDD = lazy(() => import("./mandatiSDD/mandatiSDD.view"));
const RicaricaSmartCard = lazy(() => import("./ricaricaSmartCard/ricaricaSmartCard.view"));
const ModificaBonificoRipetitivo = lazy(() => import("./bonificoRipetitivoModifica/bonificoripetitivo.view"));
const ElencoSmartCard = lazy(() => import("./elencoSmardCard/elencoSmardCard.view"));
const SospendiBonificoRipetitivo = lazy(() => import("./bonificoRipetitivoSospendi/sospendi.bonificoRipetitivo.view"));
const BonificoRiattiva = lazy(() => import("./bonificoRipetitivoRiattiva/riattiva.bonificoRpetitivo.view"));
const LibrettiRisparmio = lazy(() => import("./librettiRisparmio/librettiRisparmio.view"));
const Riba = lazy(() => import("./riba/riba.view"));
const Polizze = lazy(() => import("./polizze/polizze.view"));
const PianoMutui = lazy(() => import("./pianoMutui/pianoMutui.view"));
const PianoPrestiti = lazy(() => import("./pianoPrestiti/pianoPrestiti.view"));
const TimeDeposit = lazy(() => import("./timeDeposit/timeDeposit.view"));
const CreaTimeDeposit = lazy(() => import("./timeDeposit/nuovaSottoscrizione/creaTimeDeposit.view"));
const BancomatPayAttivazione = lazy(() => import("./bancomatPayAttivazione/bancomatPayAttivazione.view"));
const RevocaTimeDepositView = lazy(() => import("./timeDeposit/revocaTimeDeposit/revocaTimeDeposit.view"));
const BancomatPayDissattiva = lazy(() => import("./bancomatPayDisattiva/bancomatPayDisattiva.view"));
const RicaricaAltoAdigePass = lazy(() => import("./ricaricaAltoAdigePass/ricaricaAltoAdigePass.view"));
const BancomatPayGestioneRapporti = lazy(() => import("./bancomatPayGestioneRapporti/bancomatPayGestioneRapporti.view"));
const VariazioneCanali = lazy(() => import("./variazioneCanali/variazioneCanali.view"));
const ElencoRapporti = lazy(() => import("./elencoRapporti/elencoRapporti.view"));
const DettaglioRapportoUtente = lazy(() => import("./elencoRapporti/rapportiUtente.dettaglio"));
const DocumentiView = lazy(() => import("./documenti/documenti.view"));
const RapportiPredefinitiView = lazy(() => import("./elencoRapporti/rapportiPredefiniti.view"));
const ContrattiView = lazy(() => import("./documenti/contratti.view"));
const ConsensiTerzeParteView = lazy(() => import("./consensiTerzeParti/consensiTerzeParti.view"));
const GestioneCredenziali = lazy(() => import("./gestioneCredenziali/gestioneCredenziali.view"));
const ImpostaAlias = lazy(() => import("./gestioneCredenziali/impostaAlias/impostaAlias.view"));
const ModificaPassword = lazy(() => import("./gestioneCredenziali/modificaPassword/modificaPassword.view"));
const ModificaPin = lazy(() => import("./gestioneCredenziali/modificaPin/modificaPin.view"));
const CercaTitolo = lazy(() => import("./cercaTitolo/cercaTitolo.view"));
const DatiPersonali = lazy(() => import("./datiPersonali/datiPersonali.view"));
const QuestionarioAdeguataVerifica = lazy(() => import("./questionario-adeguata-verifica/questionario-adeguata-verifica.view"));
const AggiornaQuestionarioAdeguataVerifica = lazy(() => import("./questionario-adeguata-verifica/aggiorna-questionario-adeguata-verifica.view"));
const AggiornaDocumento = lazy(() => import("./aggiornaDocumentoIdentita/aggiornaDocumentoIdentita.view"));
{/*
  HOTFIX 11/03/22 (MARRA)
  Nascondere i pulsanti di MODIFICA CONTATTI per prevenire fishing Sparkasse
*/}
// const ModificaContatti = lazy(() => import("pages/datiPersonali/modificaContatti/modificaContatti.view"));
const CercaTitoliPreferiti = lazy(() => import("./cercaTitolo/titolo-preferiti/cercaTitoloPreferiti.view"));
const DatiFiliale = lazy(() => import("./datiFiliale/datiFiliale.view"));
const ConsensiRevocaView = lazy(() => import("./consensiTerzeParti/consensiRevoca.view"));
const BonificoMyBankView = lazy(() => import("./bonificoMyBank/bonificoMyBank.view"));
const Bilancio = lazy(() => import("./bilancio/bilancio.view"));
const NotificePush = lazy(() => import("./notificaPush/notificaPush.view"));
const PatrimonioView = lazy(() => import("./patrimonio/patrimonio.view"));
const FAQ = lazy(() => import("./faq/faq.view"));
const TimeDepositBox = lazy(() => import('./timeDeposit/timeDepositBox/timeDepositBox.view'));
const ContattiFilialeVirtuale = lazy(() => import('./filialeVirtuale/filialeVirtuale.view'));
const StoricoOrdini = lazy(() => import('./storicoOrdini/storicoOrdini.view'));
const DettaglioOrdine = lazy(() => import('./storicoOrdini/dettaglioOrdine.view'));
const RevocaOrdineView = lazy(() => import('./storicoOrdini/storicoOrdiniRevoca/storicoOrdiniRevoca.view'));
const NuovaRichiestaFilialeVirtualeView = lazy(() => import('./richiesteFilialeVirtuale/nuovaRichiesta/nuovaRichiesta.view'));
const ElencoRichiesteFilialeVirtualeView = lazy(() => import('./richiesteFilialeVirtuale/richiesteFilialeVirtuale.view'));
const GestionePolizzeView = lazy(() => import('./gestionePolizze/gestionePolizze.view'));
const Mercato = lazy(() => import('./mercato/mercato.view'));
const GestionePolizzeAquisto = lazy(() => import('./gestionePolizze/gestionePolizzeAquisto/gestionePolizzeAquisto.view'));
const RichiediFidoIsiDispoView = lazy(() => import('./richiediFidoIsiDispo/richiediFidoIsiDispo.view'));
const PrestitoCofidisView = lazy(() => import('./prestitoCofidis/prestitoCofidis.view'));
const PrestitoIdeaView = lazy(() => import('./prestitoCofidis/prestitoIdea/prestitoIdea.view'));
const ElencoTributiLocali = lazy(() => import('./tributiLocali/elencoTributiLocali.view'));
const ElencoTributiContoTerzi = lazy(() => import('./tributiLocali/tributiContoTerzi.view'));
const DettaglioTributiLocali = lazy(() => import('./tributiLocali/tributiLocali.dettaglio'));
const PctSottoscrizioneView = lazy(() => import('./PctSottoscrizione/PctSottoscrizione.view'));
const AcquistaTitoloView = lazy(() => import('./cercaTitolo/acquistaTitoli/acquistaTitoli.view'));
const VenditaTitoliView = lazy(() => import('./cercaTitolo/venditaTitoli/venditaTitoli.view'));
const PolizzeInvestimento = lazy(() => import('./polizzeInvestimento/polizzeInvestimento.view'));
const CatalogoProdotti = lazy(() => import('./catalogoProdotti/catalogoProdotti.view'));
const DettaglioProdottoCatalogo = lazy(() => import('./catalogoProdotti/dettaglioProdotto.view'));
const DossierTitoli = lazy(() => import('./dossierTitoli/dossierTitoli.view'));
const AnalisiRendimenti = lazy(() => import('./rendimenti/rendimenti.view'));
const FirmaProposte = lazy(() => import('./firmaProposte/firmaProposte.view'));
const NuovoContatto = lazy(() => import('./rubrica/creaContatto/nuovoContatto.view'));
const DettaglioContatto = lazy(() => import('./rubrica/rubrica.dettaglio'));
const ModificaContatto = lazy(() => import('./rubrica/modificaContatto/modificaContatto.view'));
const Mifid = lazy(() => import('./mifid/mifid.view'));
const MifidAggiornamento = lazy(() => import('./mifid/mifid.aggiornamento.view'));
const FondiPaperless = lazy(() => import('./fondi-paperless/fondiPaperless.view'));
// const OnboardingAdmin = lazy(() => import('./onboarding-admin'));
const GestionePolizzeAquistoPet = lazy(() => import('./gestionePolizze/gestionePolizzeAquisto/gestionePolizzeAquistoPet.view'));
const GestionePolizzeAquistoTravel = lazy(() => import('./gestionePolizze/gestionePolizzeAquisto/gestionePolizzeAcquistoTravel.view'));
const ElencoAppuntamenti = lazy(() => import('./consulenzaVirtuale/elencoAppuntamenti.view'));
const NuovoAppuntamento = lazy(() => import('./consulenzaVirtuale/nuovoAppuntamento.view'));
const GestioneAppuntamento = lazy(() => import('./consulenzaVirtuale/gestioneAppuntamento.view'));
const ElencoUltimiAccessi = lazy(() => import('./elencoUltimiAccessi/elencoUltimiAccessi.view'));
const ZainettoFiscale = lazy(() => import('./zainettoFiscale/zainettoFiscale.view'));
const CartaRicaricabile = lazy(() => import('./cartaRicaricabile/cartaRicaricabile.view'));
const CartaRicaricabileMovimenti = lazy(() => import('./cartaRicaricabileMovimenti/cartaRicaricabileMovimenti.view'));
const CartaNexiDebit = lazy(() => import('./cartaNexiDebit/cartaNexiDebit.view'));
const AcquistoNexiDebit = lazy(() => import("./acquistoNexiDebit/acquistoNexiDebit.view"));

const SwitchPage: React.FC<Props> = (propsIn: Props) => {

  const { t } = useTranslation();

  const {
    history,
    menu,
    notificheMenu,
    setStepHomeBonifico,
    resetBonificoSepa,
    setI18nSetCurrentLanguage,
    waitingSections,
    loaderActive,
    currentLanguage,
    setPreferenzeUtente,
    estendiSessione,
    noRapporti,
    resetNoRapporti,
    error,
    bloccantiMessaggi,
    updateMessaggiBloccanti,
    getBloccantiMessaggi,
    getMenu,
    getNotificheMenu,
    getParametriPostlogin,
    getParametriProfilo,
    getRapportiAdeguataVerifica,
    initI18nAction,
    jwtToken,
    isMenuExpanded,
    setMenuExpanded,
    myBankToken,
    rapportiAdeguataVerifica,
    isMenuComunicazioniVisible,
    isMenuProfiloVisible,
    isMenuAssistenzaVisible,
    isNotificaMenuComunicazioniVisible,
    isNotificaMenuProfiloVisible,
    isNotificaMenuAssistenzaVisible,
    pushPopUp,
    bloccanti
  } = propsIn;

  const [selectedLvl1, setSelectedLvl1] = useState<number | null>(null);
  const [pendingSavePreferences, setPendingSavePreferences] = useState(false);
  const [areaItemsLvl1, setAreaItemsLvl1] = useState<ProfiloMenu[]>([]);
  const [areaItemsLvl2, setAreaItemsLvl2] = useState<ProfiloMenu[]>([]);
  const [areaLabelLvl1, setAreaLabelLvl1] = useState<string>("");
  const [areaLabelLvl2, setAreaLabelLvl2] = useState<string>("");

  useEffect(() => {
    getParametriPostlogin();
  }, [])

  const onMenu = (e?: any) => {
    console.log(e);
    if (
      selectedLvl1 != null &&
      areaItemsLvl1 != null &&
      e &&
      (e.target.nodeName === "IMG" || e.target.className === "ham-menu")
    ) {
      setSelectedLvl1(null);
      setAreaItemsLvl1([]);
      setAreaLabelLvl1("");
      setAreaItemsLvl2([]);
      setAreaLabelLvl2("");
    } else {
      setAreaItemsLvl1([]);
      setAreaLabelLvl1("");
      setAreaItemsLvl2([]);
      setAreaLabelLvl2("");
      setMenuExpanded(!isMenuExpanded);
    }
  };

  useEffect(() => {
    error && console.log("debug - errore nella pagina: ", error);
  }, [error]);

  useEffect(() => {
    if (!jwtToken) {
      getItem("jwt").then((jwt) => {
        if (!jwt && !MOCK) {
          navigateToLogin();
        } else {
          estendiSessione();
        }
      });
    }
  }, [jwtToken]);

  useEffect(() => {

    const documentiDaFirmare = notificheMenu?.some(n => n.id == 'menu.firma_proposte' && !!n.notifica);
    const showModalDocumentiDaFirmare = history.location.pathname == WEBPAGE.Home.url;

    if (!!documentiDaFirmare && showModalDocumentiDaFirmare) {
      pushPopUp({
        type: 'confirm',
        title: t('firmaProposte.documentiDaFirmare.title'),
        message: t('firmaProposte.documentiDaFirmare.description'),
        buttonKo: {
          text: t('firmaProposte.documentiDaFirmare.buttons.close'),
          action: onCloseFirmaProposteMessage
        },
        buttonOk: {
          text: t('firmaProposte.documentiDaFirmare.buttons.sign'),
          action: onSignFirmaProposteMessage
        }
      });
    }

  }, [notificheMenu]);

  const setHome = () => {
    navigateToPage(WEBPAGE.Home.url);
    setStepHomeBonifico(0);
    resetBonificoSepa();
  };

  const onLogout = () => {
    navigateToLogout()
  };

  const navigateTo = (path: string) => {
    ReactGA.pageview(path);
    path && navigateToPage(path);
  };

  const { pathname } = useLocation();

  const onChangeLanguage = (lingua: string) => {
    initI18nAction();
    setI18nSetCurrentLanguage(lingua.toLowerCase());
    setItem('language', lingua.toLowerCase(), true);
    setPendingSavePreferences(true);
  };

  useEffect(() => {
    if (pendingSavePreferences) {
      setPendingSavePreferences(false);
      setPreferenzeUtente();
    }
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  window.onload = () => {
    // Get menu items after page reloading
    if (!bloccantiMessaggi) {
      getBloccantiMessaggi();
    }
  };

  useEffect(() => {

    if (!!bloccanti) {

      if (!!bloccantiMessaggi && bloccantiMessaggi.length > 0) {

        pathname !== WEBPAGE.PostLoginMessages.url && navigateToPage(WEBPAGE.PostLoginMessages.url);
        if (bloccantiMessaggi.some((e) => e.funzione === 'ADEGUATAVERIFICA')) {
          getRapportiAdeguataVerifica();
        }

      } else if (!!bloccantiMessaggi && bloccantiMessaggi.length === 0) {

        if (!myBankToken) {
          getMenu();
          getNotificheMenu();
          getParametriProfilo();
          if (pathname !== WEBPAGE.PostLoginMessages.url) {
            navigateToPage(pathname || WEBPAGE.Home.url);
          } else {
            navigateToPage(WEBPAGE.Home.url);
          }
        }
      }
    }

  }, [bloccanti]);

  const onClose = (idMessaggio: number | undefined) => {
    if (idMessaggio == null) {
      return;
    }
    updateMessaggiBloccanti(idMessaggio);
  };

  const onCloseFirmaProposteMessage = () => {
    console.log('onCloseFirmaProposteMessage');
  };

  const onSignFirmaProposteMessage = () => {
    console.log('onSignFirmaProposteMessage');
    navigateToPage(WEBPAGE.FirmaProposte.url);
  };

  const openSubMenuArea = (items: ProfiloMenu[] | undefined, label: string) => {
    if (areaLabelLvl1 === label) {
      setAreaItemsLvl1([]);
      setAreaLabelLvl1("");
    } else {
      setAreaItemsLvl1(items || []);
      setAreaLabelLvl1(label);
    }

    setAreaItemsLvl2([]);
    setAreaLabelLvl2("");
    setSelectedLvl1(null);
    setMenuExpanded(false);
  };

  return <>

    {loaderActive &&
      <Loader />
    }

    <div className={`root-switch ${waitingSections.map((s) => `waiting-${s}`).join(" ")}`}>

      <Snackbar />

      <FullPageNotification />

      {bloccantiMessaggi?.length !== 0 &&
        bloccantiMessaggi?.map((messaggio: MessaggioBodyDto, index: number) => (
          <React.Fragment key={index}>
            <MessaggiBloccantiComponent
              messaggio={messaggio}
              show={index === 0}
              onClose={onClose}
              isAdeguataVerifica={messaggio.funzione === 'ADEGUATAVERIFICA'}
              rapportiAdeguataVerifica={rapportiAdeguataVerifica}
            />
          </React.Fragment>
        ))
      }

      <NoRapportiModal resetNoRapporti={resetNoRapporti} noRapporti={noRapporti} />

      <div className="central-switch">

        <div className="main-switch" id="main-switch">

          <Suspense fallback={<Loader />}>

            <Route path={WEBPAGE.Anatocismo.url} exact component={AddebitoInteressi} />
            <Route path={WEBPAGE.ArchivioBozze.url} exact component={ArchivioBozze} />
            <Route path={WEBPAGE.Assegni.url} exact component={Assegni} />
            <Route path={WEBPAGE.BancomatPay.url} exact component={BancomatPay} />
            <Route path={WEBPAGE.BancomatPayAttivazione.url} exact component={BancomatPayAttivazione} />
            <Route path={WEBPAGE.BancomatPayDisattiva.url} exact component={BancomatPayDissattiva} />
            <Route path={WEBPAGE.BancomatPayGestioneRapporti.url} exact component={BancomatPayGestioneRapporti} />
            <Route path={WEBPAGE.BancomatPayMassimali.url} exact component={BancomatPayMassimali} />
            <Route path={WEBPAGE.Bilancio.url} exact component={Bilancio} />
            <Route path={WEBPAGE.BloccoCarte.url} exact component={BloccoCarte} />
            <Route path={WEBPAGE.BloccoUtente.url} exact component={BloccoUtente} />
            <Route path={WEBPAGE.BollettinoBancarioFreccia.url} exact component={BollettinoFreccia} />
            <Route path={WEBPAGE.BollettinoBianco.url} exact component={BollettinoBianco} />
            <Route path={WEBPAGE.BollettinoPremarcato.url} exact component={BollettinoPremarcato} />
            <Route path={WEBPAGE.BolloAci.url} exact component={BolloACI} />
            <Route path={WEBPAGE.BonificiRipetitivi.url} exact component={BonificiRipetitivi} />
            <Route path={WEBPAGE.BonificoDeposito.url} exact component={BonificoDeposito} />
            <Route path={WEBPAGE.BonificoVersamentoDeposito.url} exact component={BonificoVersamentoDeposito} />
            <Route path={WEBPAGE.BonificoDetrazioneFiscale.url} exact component={BonificoDF} />
            <Route path={WEBPAGE.BonificoEstero.url} exact component={BonificoEstero} />
            <Route path={WEBPAGE.BonificoMyBank.url} exact component={BonificoMyBankView} />
            <Route path={WEBPAGE.BonificoRipetitivo.url} exact component={BonificoRipetitivo} />
            <Route path={WEBPAGE.BonificoSepa.url} exact component={Bonifico} />
            <Route path={WEBPAGE.CartaCredito.url} exact component={Nexi} />
            <Route path={WEBPAGE.Cbill.url} exact component={CBill} />
            <Route path={WEBPAGE.CercaTitoliPreferiti.url} exact component={CercaTitoliPreferiti} />
            <Route path={WEBPAGE.CercaTitolo.url} exact component={CercaTitolo} />
            <Route path={WEBPAGE.ConsensiRevoca.url} exact component={ConsensiRevocaView} />
            <Route path={WEBPAGE.ConsensiTerzeParti.url} exact component={ConsensiTerzeParteView} />
            <Route path={WEBPAGE.Contratti.url} exact component={ContrattiView} />
            <Route path={WEBPAGE.DatiFiliale.url} exact component={DatiFiliale} />
            <Route path={WEBPAGE.DatiPersonali.url} exact component={DatiPersonali} />
            <Route path={WEBPAGE.Dettaglio.url} exact component={Assegni} />
            <Route path={WEBPAGE.DettaglioRapporto.url} exact component={DettaglioRapportoUtente} />
            <Route path={WEBPAGE.Documenti.url} exact component={DocumentiView} />
            <Route path={WEBPAGE.ElencoRapporti.url} exact component={ElencoRapporti} />
            <Route path={WEBPAGE.ContattiFilialeVirtuale.url} exact component={ContattiFilialeVirtuale} />
            <Route path={WEBPAGE.ElencoSmartcard.url} exact component={ElencoSmartCard} />
            <Route path={WEBPAGE.EstinguiStoricoOrdine.url} exact component={RevocaOrdineView} />
            <Route path={WEBPAGE.EstinguiTimeDeposit.url} exact component={RevocaTimeDepositView} />
            <Route path={WEBPAGE.F24Accise.url} exact component={F24Accise} />
            <Route path={WEBPAGE.F24Elide.url} exact component={F24Elide} />
            <Route path={WEBPAGE.F24Ordinario.url} exact component={F24Ordinario} />
            <Route path={WEBPAGE.F24Semplificato.url} exact component={F24Semplificato} />
            <Route path={WEBPAGE.Faq.url} exact component={FAQ} />
            <Route path={WEBPAGE.GestioneCredenziali.url} exact component={GestioneCredenziali} />
            <Route path={WEBPAGE.Home.url} exact component={Home} />
            <Route path={WEBPAGE.ImpostaAlias.url} exact component={ImpostaAlias} />
            <Route path={WEBPAGE.LibrettiRisparmio.url} exact component={LibrettiRisparmio} />
            <Route path={WEBPAGE.MandatiSdd.url} exact component={MandatiSDD} />
            <Route path={WEBPAGE.MassimaliUtente.url} exact component={MassimaliUtente} />
            <Route path={WEBPAGE.Mav.url} exact component={Mav} />
            <Route path={WEBPAGE.Mercato.url} exact component={Mercato} />
            <Route path={WEBPAGE.ModificaBonificoRipetitivo.url} exact component={ModificaBonificoRipetitivo} />
            <Route path={WEBPAGE.QuestionarioAdeguataVerifica.url} exact component={QuestionarioAdeguataVerifica} />
            <Route path={WEBPAGE.AggiornaQuestionarioAdeguataVerifica.url} exact component={AggiornaQuestionarioAdeguataVerifica} />
            <Route path={WEBPAGE.AggiornaDocumento.url} exact component={AggiornaDocumento} />


            {/*
              HOTFIX 11/03/22 (MARRA)
              Nascondere i pulsanti di MODIFICA CONTATTI per prevenire fishing Sparkasse
            */}
            {/* <Route path={WEBPAGE.ModificaContatti.url} exact component={ModificaContatti} /> */}
            <Route path={WEBPAGE.ModificaPassword.url} exact component={ModificaPassword} />
            <Route path={WEBPAGE.ModificaPin.url} exact component={ModificaPin} />
            <Route path={WEBPAGE.ModificaResidenza.url} exact component={ModificaResidenza} />
            <Route path={WEBPAGE.NotificePush.url} exact component={NotificePush} />
            <Route path={WEBPAGE.NuovoTimeDeposit.url} exact component={CreaTimeDeposit} />
            <Route path={WEBPAGE.OperazioniOneClick.url} exact component={OperazioniOneClick} />
            <Route path={WEBPAGE.PagamentiEffettuati.url} exact component={PagamentiEffettuati} />
            <Route path={WEBPAGE.DettaglioPagamento.url} exact component={DettaglioPagamento} />
            <Route path={WEBPAGE.PagamentiEffettuatiRapportiEstinti.url} exact component={PagamentiEffettuatiRapportiEstinti} />
            <Route path={WEBPAGE.DettaglioPagamentoRapportiEstinti.url} exact component={DettaglioPagamentoRapportiEstinti} />
            <Route path={WEBPAGE.Patrimonio.url} exact component={PatrimonioView} />
            <Route path={WEBPAGE.PianoMutui.url} exact component={PianoMutui} />
            <Route path={WEBPAGE.PianoPrestiti.url} exact component={PianoPrestiti} />
            <Route path={WEBPAGE.Polizze.url} exact component={Polizze} />
            <Route path={WEBPAGE.PostLoginMessages.url} exact component={PostLoginMessages} />
            <Route path={WEBPAGE.RapportiPredefiniti.url} exact component={RapportiPredefinitiView} />
            <Route path={WEBPAGE.Rav.url} exact component={Rav} />
            <Route path={WEBPAGE.RevocaBollettinoBianco.url} exact component={RevocaBolletinoPostale} />
            <Route path={WEBPAGE.RevocaBollettinoFreccia.url} exact component={BollettinoFrecciaRevoca} />
            <Route path={WEBPAGE.RevocaBollettinoPremarcato.url} exact component={RevocaBolletinoPremarcato} />
            <Route path={WEBPAGE.RevocaBonificoDeposito.url} exact component={RevocaBonificoDeposito} />
            <Route path={WEBPAGE.RevocaBonificoVersamentoDeposito.url} exact component={RevocaBonificoVersamentoDeposito} />
            <Route path={WEBPAGE.RevocaBonificoDetrazioneFiscale.url} exact component={RevocaBonificoDF} />
            <Route path={WEBPAGE.RevocaBonificoRipetitivo.url} exact component={RevocaBonificoRipetitivo} />
            <Route path={WEBPAGE.RevocaBonificoSepa.url} exact component={RevocaBonificoSepa} />
            <Route path={WEBPAGE.RevocaF24Accise.url} exact component={RevocaF24Accise} />
            <Route path={WEBPAGE.RevocaF24Elide.url} exact component={RevocaF24Elide} />
            <Route path={WEBPAGE.RevocaF24Ordinario.url} exact component={RevocaF24Ordinario} />
            <Route path={WEBPAGE.RevocaF24Semplificato.url} exact component={RevocaF24Semplificato} />
            <Route path={WEBPAGE.RevocaMav.url} exact component={RevocaMav} />
            <Route path={WEBPAGE.RevocaRav.url} component={RevocaRav} />
            <Route path={WEBPAGE.RiattivaBonificoRipetitivo.url} exact component={BonificoRiattiva} />
            <Route path={WEBPAGE.RicaricaSmartcard.url} exact component={RicaricaSmartCard} />
            <Route path={WEBPAGE.RicaricaTelefonica.url} component={RicaricaTelefonica} />
            <Route path={WEBPAGE.RicaricaAltoAdigePass.url} exact component={RicaricaAltoAdigePass} />
            <Route path={WEBPAGE.RicevuteBancarie.url} exact component={Riba} />
            <Route path={WEBPAGE.RubricaBeneficiari.url} exact component={Rubrica} />
            <Route path={WEBPAGE.SaldoEMovimenti.url} exact component={SaldoEMovimenti} />
            <Route path={WEBPAGE.SaldoEMovimentiDeposito.url} exact component={SaldoEMovimentiDeposito} />
            <Route path={WEBPAGE.ScadenzaTitoli.url} exact component={ScadenzaTitoli} />
            <Route path={WEBPAGE.SospendiBonificoRipetitivo.url} exact component={SospendiBonificoRipetitivo} />
            <Route path={WEBPAGE.StoricoOrdine.url} exact component={StoricoOrdini} />
            <Route path={WEBPAGE.DettaglioOrdine.url} exact component={DettaglioOrdine} />
            <Route path={WEBPAGE.TimeDeposit.url} exact component={TimeDeposit} />
            <Route path={WEBPAGE.TimeDepositBox.url} exact component={TimeDepositBox} />
            <Route path={WEBPAGE.VariazioneCanali.url} exact component={VariazioneCanali} />
            <Route path={WEBPAGE.NuovaRichiestaFilialeVirtuale.url} exact component={NuovaRichiestaFilialeVirtualeView} />
            <Route path={WEBPAGE.GestionePolizze.url} exact component={GestionePolizzeView} />
            <Route path={WEBPAGE.GestionePolizzeAquisto.url} exact component={GestionePolizzeAquisto} />

            <Route path={WEBPAGE.PrestitoCofidis.url} exact component={PrestitoCofidisView} />
            <Route path={WEBPAGE.PrestitoNuovo.url} exact component={PrestitoIdeaView} />
            <Route path={WEBPAGE.RichiediFidoIsiDispo.url} exact component={RichiediFidoIsiDispoView} />
            <Route path={WEBPAGE.AcquistaTitolo.url} exact component={AcquistaTitoloView} />
            <Route path={WEBPAGE.VenditaTitolo.url} exact component={VenditaTitoliView} />
            <Route path={WEBPAGE.ElencoTributiLocali.url} exact component={ElencoTributiLocali} />
            <Route path={WEBPAGE.ElencoPagamentiContoTerzi.url} exact component={ElencoTributiContoTerzi} />
            <Route path={WEBPAGE.DettaglioTributiLocali.url} exact component={DettaglioTributiLocali} />
            <Route path={WEBPAGE.PctSottoscrizione.url} exact component={PctSottoscrizioneView} />
            <Route path={WEBPAGE.PolizzeInvestimento.url} exact component={PolizzeInvestimento} />
            <Route path={WEBPAGE.CatalogoProdotti.url} exact component={CatalogoProdotti} />
            <Route path={WEBPAGE.DettaglioCatalogoProdotti.url} exact component={DettaglioProdottoCatalogo} />
            <Route path={WEBPAGE.DossierTitoli.url} exact component={DossierTitoli} />
            <Route path={WEBPAGE.Rendimenti.url} exact component={AnalisiRendimenti} />
            <Route path={WEBPAGE.FirmaProposte.url} exact component={FirmaProposte} />
            <Route path={WEBPAGE.NuovoContatto.url} exact component={NuovoContatto} />
            <Route path={WEBPAGE.DettaglioContatto.url} exact component={DettaglioContatto} />
            <Route path={WEBPAGE.ModificaContatto.url} exact component={ModificaContatto} />
            <Route path={WEBPAGE.GestionePolizzeAquistoPet.url} exact component={GestionePolizzeAquistoPet} />
            <Route path={WEBPAGE.GestionePolizzeAquistoTravel.url} exact component={GestionePolizzeAquistoTravel} />
            <Route path={WEBPAGE.Mifid.url} exact component={Mifid} />
            <Route path={WEBPAGE.MifidAggiornamento.url} exact component={MifidAggiornamento} />
            <Route path={WEBPAGE.ElencoAppuntamenti.url} exact component={ElencoAppuntamenti} />
            <Route path={WEBPAGE.NuovoAppuntamento.url} exact component={NuovoAppuntamento} />
            <Route path={WEBPAGE.GestioneAppuntamento.url} exact component={GestioneAppuntamento} />
            <Route path={WEBPAGE.ElencoRichiesteFilialeVirtualeView.url} exact component={ElencoRichiesteFilialeVirtualeView} />

            <Route path={WEBPAGE.FondiPaperless.url} exact component={FondiPaperless} />
            <Route path={WEBPAGE.ElencoUltimiAccessi.url} exact component={ElencoUltimiAccessi} />
            <Route path={WEBPAGE.ZainettoFiscale.url} exact component={ZainettoFiscale} />
            <Route path={WEBPAGE.CartaRicaricabile.url} exact component={CartaRicaricabile} />
            <Route path={WEBPAGE.CartaRicaricabileMovimenti.url} exact component={CartaRicaricabileMovimenti} />
            <Route path={WEBPAGE.CartaNexiDebit.url} exact component={CartaNexiDebit} />
            <Route path={WEBPAGE.AcquistoNexiDebit.url} exact component={AcquistoNexiDebit} />

            {/* <Route path={WEBPAGE.OnboardingAdmin.url} exact component={OnboardingAdmin} /> */}

          </Suspense>

        </div>

        {!myBankToken && pathname !== WEBPAGE.BonificoMyBank.url && (
          <SideBarMenu
            menu={menu?.profiloMenu}
            items={menu?.profiloMenu?.items}
            isMenuExpanded={isMenuExpanded}
            setMenuExpanded={setMenuExpanded}
            navigateTo={navigateTo}
            onMenu={onMenu}
            selectedLvl1={selectedLvl1}
            setSelectedLvl1={setSelectedLvl1}
            onChangeLanguage={onChangeLanguage}
            currentLanguage={currentLanguage}
            onLogout={onLogout}
            notificheMenu={notificheMenu?.filter(n => !!n.notifica && !!n.id)}
            areaItemsLvl1={areaItemsLvl1}
            setAreaItemsLvl1={setAreaItemsLvl1}
            areaItemsLvl2={areaItemsLvl2}
            setAreaItemsLvl2={setAreaItemsLvl2}
            areaLabelLvl1={areaLabelLvl1}
            setAreaLabelLvl1={setAreaLabelLvl1}
            areaLabelLvl2={areaLabelLvl2}
            setAreaLabelLvl2={setAreaLabelLvl2}
            isMenuComunicazioniVisible={isMenuComunicazioniVisible}
            isMenuProfiloVisible={isMenuProfiloVisible}
            isMenuAssistenzaVisible={isMenuAssistenzaVisible}
            isNotificaMenuComunicazioniVisible={isNotificaMenuComunicazioniVisible}
            isNotificaMenuProfiloVisible={isNotificaMenuProfiloVisible}
            isNotificaMenuAssistenzaVisible={isNotificaMenuAssistenzaVisible}
          />
        )}

      </div>

      <div className="main-header">

        {!myBankToken && pathname !== WEBPAGE.BonificoMyBank.url &&
          <div
            className="ham-menu"
            onClick={(e) => {
              onMenu(e);
            }}
          >
            <img
              src={
                isMenuExpanded || selectedLvl1 !== null
                  ? chiudiBianco
                  : burgerBianco
              }
              style={{ height: 40 }}
            />
          </div>
        }

        <SessionHandler />

        <div className="header-logo-search-container">

          <Logo onClick={(myBankToken || pathname === WEBPAGE.BonificoMyBank.url) ? undefined : setHome} />

          {!myBankToken && pathname !== WEBPAGE.BonificoMyBank.url &&
            <div className="header-search-bar">
              <Search />
            </div>
          }

        </div>

        {!myBankToken && pathname !== WEBPAGE.BonificoMyBank.url &&
          <div className="header-buttons-container">
            <NavbarButtons
              onChangeLanguage={onChangeLanguage}
              navigateTo={navigateTo}
              currentLanguage={currentLanguage}
              onLogout={onLogout}
              menu={menu.profiloMenu!}
              openSubMenuArea={openSubMenuArea}
              areaLabelLvl1={areaLabelLvl1}
              isMenuComunicazioniVisible={isMenuComunicazioniVisible}
              isMenuProfiloVisible={isMenuProfiloVisible}
              isMenuAssistenzaVisible={isMenuAssistenzaVisible}
              isNotificaMenuComunicazioniVisible={isNotificaMenuComunicazioniVisible}
              isNotificaMenuProfiloVisible={isNotificaMenuProfiloVisible}
              isNotificaMenuAssistenzaVisible={isNotificaMenuAssistenzaVisible}
            />
          </div>
        }

      </div>

    </div>

  </>;
};

const mapStateToProps = (state: AppState) => ({
  menu: menuList(state.menu),
  notificheMenu: notificheMenu(state.menu),
  isMenuExpanded: state.general.webMenuExpanded,
  error: error(state.general),
  waitingSections: waitingSections(state.general),
  loaderActive: loaderActive(state.general),
  currentLanguage: currentLanguage(state.i18n),
  bloccantiMessaggi: bloccantiMessaggi(state.messaggi),
  rapportiAdeguataVerifica: adeguataVerificaRapporti(state.elencoRapporti),
  myBankToken: myBankTokenSelector(state.myBank),
  jwtToken: jwtToken(state.login),
  noRapporti: noRapporti(state.general),
  isMenuComunicazioniVisible: isMenuComunicazioniVisible(state.menu),
  isMenuProfiloVisible: isMenuProfiloVisible(state.menu),
  isMenuAssistenzaVisible: isMenuAssistenzaVisible(state.menu),
  isNotificaMenuComunicazioniVisible: isNotificaMenuComunicazioniVisible(state.menu),
  isNotificaMenuProfiloVisible: isNotificaMenuProfiloVisible(state.menu),
  isNotificaMenuAssistenzaVisible: isNotificaMenuAssistenzaVisible(state.menu),
  bloccanti: bloccanti(state.messaggi)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getMenu,
      getNotificheMenu,
      getParametriPostlogin,
      getParametriProfilo,
      setMenuExpanded: setWebMenu,
      setStepHomeBonifico,
      resetBonificoSepa,
      setI18nSetCurrentLanguage,
      initI18nAction,
      setPreferenzeUtente,
      getBloccantiMessaggi,
      updateMessaggiBloccanti,
      getRapportiAdeguataVerifica,
      estendiSessione,
      resetNoRapporti,
      pushPopUp
    },
    dispatch
  );

interface RouteParams {
  id: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>;

export default connect(mapStateToProps, mapDispatchToProps)(SwitchPage);
