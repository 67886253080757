"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setFormSubmittedModificaContatti = exports.datiPersonaliResetEsitoAutorizzaContatti = exports.datiPersonaliResetInfoDispositivaContatti = exports.datiPersonaliResetRiepilogoContatti = exports.setCellulareDatiPersonali = exports.setEmailDatiPersonali = exports.datiPersonaliResetFormAction = exports.setStepDatiPersonali = exports.autorizzaImpostaContatti = exports.getInfoDispositivaImpostaContatti = exports.controlloAggiornaProfiloImpostaContatti = exports.getDatiAnagrafici = exports.modificaDatiAggiuntivi = exports.getInfoAutenticazioneDatiPersonali = exports.getDatiAggiuntivi = exports.datiPersonaliAutorizzaDatiAggiuntivi = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/* autorizza Dati Aggiuntivi - START */
function datiPersonaliAutorizzaDatiAggiuntiviStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_AUTORIZZA_DATI_AGGIUNTIVI_START
  };
}
function datiPersonaliAutorizzaDatiAggiuntiviSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_AUTORIZZA_DATI_AGGIUNTIVI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
  };
}
const datiPersonaliAutorizzaDatiAggiuntivi = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(datiPersonaliAutorizzaDatiAggiuntiviStart());
  if (extraArguments.mock) {} else {
    const autorizzaDatiPersonaliRequest = {
      credenzialeDispositivaDto: credenziale,
      idDispositiva
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AnagrafeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaDatiAggiuntivi(autorizzaDatiPersonaliRequest).then(response => {
        dispatch(datiPersonaliAutorizzaDatiAggiuntiviSuccess(response));
        dispatch((0, general_actions_1.setLoader)(false));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false));
        dispatch((0, general_actions_1.setLoader)(false));
      }).finally(() => {
        if (extraArguments.platform == 'mobile') {
          dispatch((0, general_actions_1.setLoader)(false));
        }
      });
    });
  }
};
exports.datiPersonaliAutorizzaDatiAggiuntivi = datiPersonaliAutorizzaDatiAggiuntivi;
/*  autorizza Dati Aggiuntivi - FINE */
/* get datiAggiuntivi -START */
function getDatiAggiuntiviStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_GET_DATI_AGGIUNTIVI_START
  };
}
function getDatiAggiuntiviSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_GET_DATI_AGGIUNTIVI_SUCCESS,
    payload
  };
}
const getDatiAggiuntivi = letturaDatiAggiuntivi => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDatiAggiuntiviStart());
  if (extraArguments.mock) {} else {
    const datiAggiuntiviRequest = {
      letturaDatiAggiuntiviRichiestaDto: letturaDatiAggiuntivi
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AnagrafeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).datiAggiuntivi(datiAggiuntiviRequest).then(response => {
        dispatch(getDatiAggiuntiviSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDatiAggiuntivi = getDatiAggiuntivi;
/* get datiAggiuntivi -FINE */
/* Get info autenticazione - START */
function getInfoAutenticazioneDatiPersonaliStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_GET_INFORMAZIONI_DATI_AGGIUNTIVI_START
  };
}
function getInfoAutenticazioneDatiPersonaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_GET_INFORMAZIONI_DATI_AGGIUNTIVI_SUCCESS,
    payload
  };
}
const getInfoAutenticazioneDatiPersonali = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoAutenticazioneDatiPersonaliStart());
  if (extraArguments.mock) {} else {
    const getInfoAutenticazioneDatiPersonaliRequest = {
      idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AnagrafeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneDatiAggiuntivi(getInfoAutenticazioneDatiPersonaliRequest).then(response => {
        dispatch(getInfoAutenticazioneDatiPersonaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoAutenticazioneDatiPersonali = getInfoAutenticazioneDatiPersonali;
/* Get info autenticazione - FINE */
/* modifica dati aggiunntivi -START */
function modificaDatiAggiuntiviStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_MODIFICA_DATI_AGGIUNTIVI_START
  };
}
function modificaDatiAggiuntiviSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_MODIFICA_DATI_AGGIUNTIVI_SUCCESS,
    payload
  };
}
const modificaDatiAggiuntivi = aggiornaDatiAggiuntivi => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(modificaDatiAggiuntiviStart());
  if (extraArguments.mock) {} else {
    const modificaDatiAggiuntiviRequest = {
      aggiornaDatiAggiuntiviRichiestaDto: aggiornaDatiAggiuntivi
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AnagrafeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).modificaDatiAggiuntivi(modificaDatiAggiuntiviRequest).then(response => {
        dispatch(modificaDatiAggiuntiviSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.modificaDatiAggiuntivi = modificaDatiAggiuntivi;
/* modifica dati aggiunntivi -FINE */
/* dati anagrafici -START */
function getDatiAnagraficiStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_GET_DATI_ANAGRAFICI_START
  };
}
function getDatiAnagraficiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_GET_DATI_ANAGRAFICI_SUCCESS,
    payload
  };
}
const getDatiAnagrafici = mask => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDatiAnagraficiStart());
  let request = {
    mask: mask
  };
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AnagrafeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).datiAnagrafici(request).then(response => {
        dispatch(getDatiAnagraficiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDatiAnagrafici = getDatiAnagrafici;
/* dati anagrafici -FINE */
//* imposta contatti controllo - START */
function controlloAggiornaProfiloImpostaContattiStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_CONTROLLO_START
  };
}
function controlloAggiornaProfiloImpostaContattiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_CONTROLLO_SUCCESS,
    payload
  };
}
const controlloAggiornaProfiloImpostaContatti = datiAnagrafici => async (dispatch, getState, extraArguments) => {
  dispatch(setFormSubmittedModificaContatti(true));
  const datiPersonali = getState().datiPersonali;
  if ((0, __1.formModificaContattoValid)(datiPersonali)) {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloAggiornaProfiloImpostaContattiStart());
    if (extraArguments.mock) {} else {
      const datiIn = datiAnagrafici || (0, __1.formDatiPersonaliAsFilter)(getState().datiPersonali);
      const request = {
        datiAnagraficiDto: {
          email: datiIn.email,
          cellulare: datiIn.cellulare
        }
      };
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloAggiornaProfilo(request).then(response => {
          dispatch(controlloAggiornaProfiloImpostaContattiSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          dispatch((0, general_actions_1.setLoader)(false));
        });
      });
    }
  }
};
exports.controlloAggiornaProfiloImpostaContatti = controlloAggiornaProfiloImpostaContatti;
/* imposta contatti controllo - FINE */
/* imposta contatti info dispositiva - START */
function getInfoDispositivaImpostaContattiStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_GET_INFO_DISPOSITIVA_START
  };
}
function getInfoDispositivaImpostaContattiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_GET_INFO_DISPOSITIVA_SUCCESS,
    payload
  };
}
const getInfoDispositivaImpostaContatti = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoDispositivaImpostaContattiStart());
  if (extraArguments.mock) {} else {
    const request = {
      idDispositiva: idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneModificaProfilo(request).then(response => {
        dispatch(getInfoDispositivaImpostaContattiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoDispositivaImpostaContatti = getInfoDispositivaImpostaContatti;
/* imposta contatti info dispositiva - FINE */
/* imposta contatti autorizza - FINE */
function autorizzaImpostaContattiStart() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_AUTORIZZA_START
  };
}
function autorizzaImpostaContattiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_IMPOSTA_CONTATTI_AUTORIZZA_SUCCESS,
    payload
  };
}
const autorizzaImpostaContatti = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaImpostaContattiStart());
  if (extraArguments.mock) {} else {
    const request = {
      idDispositiva: idDispositiva,
      credenzialeDispositivaDto: credenziale
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaProfilo(request).then(response => {
        dispatch(autorizzaImpostaContattiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaImpostaContatti = autorizzaImpostaContatti;
/* imposta contatti autorizza - FINE */
/* Set form values - START */
function setStepDatiPersonali(step) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_SET_STEP,
    payload: step
  };
}
exports.setStepDatiPersonali = setStepDatiPersonali;
function datiPersonaliResetFormAction() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_RESET
  };
}
exports.datiPersonaliResetFormAction = datiPersonaliResetFormAction;
function setEmailDatiPersonali(email) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_SET_EMAIL,
    payload: email
  };
}
exports.setEmailDatiPersonali = setEmailDatiPersonali;
function setCellulareDatiPersonali(cellulare) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_SET_CELLULARE,
    payload: cellulare
  };
}
exports.setCellulareDatiPersonali = setCellulareDatiPersonali;
function datiPersonaliResetRiepilogoContatti() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_RESET_CONTATTI_CONTROLLO
  };
}
exports.datiPersonaliResetRiepilogoContatti = datiPersonaliResetRiepilogoContatti;
function datiPersonaliResetInfoDispositivaContatti() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_RESET_CONTATTI_GET_INFO_DISPOSITIVA
  };
}
exports.datiPersonaliResetInfoDispositivaContatti = datiPersonaliResetInfoDispositivaContatti;
function datiPersonaliResetEsitoAutorizzaContatti() {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_RESET_CONTATTI_AUTORIZZA
  };
}
exports.datiPersonaliResetEsitoAutorizzaContatti = datiPersonaliResetEsitoAutorizzaContatti;
function setFormSubmittedModificaContatti(b) {
  return {
    type: __1.EReduxActionTypes.DATI_PERSONALI_SET_FORM_SUBMITTED_MODIFICA_CONTATTI,
    payload: b
  };
}
exports.setFormSubmittedModificaContatti = setFormSubmittedModificaContatti;
// export function setDatiPersonaliSetResidenza(value: string): IDatiPersonaliSetResidenza {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_RESIDENZA,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetProvincia(value: string): IDatiPersonaliSetProvincia {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_PROVINCIA,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetComune(value: string): IDatiPersonaliSetComune {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_COMUNE,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetLocalita(value: string): IDatiPersonaliSetLocalita {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_LOCALITA,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetCap(value: string): IDatiPersonaliSetCap {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_CAP,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetViaPlaza(value: string): IDatiPersonaliSetViaPlaza {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_VIA_PLAZA,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetIndirizio(value: string): IDatiPersonaliSetIndirizio {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_INDIRIZIO,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetNumeroCivicio(value: string): IDatiPersonaliSetNumeroCivicio {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_NUMERO_CIVICIO,
//         payload: value
//     }
// }
// export function setDatiPersonaliSetFormSubmitted(value: boolean): IDatiPersonaliSetFormSubmitted {
//     return {
//         type: EReduxActionTypes.DATI_PERSONALI_SET_SET_FORM_SUBMITTED,
//         payload: value
//     }
// }