"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfoAutenticazionePagamentroTributiLocali = exports.resetEsitoTributiLocali = exports.autorizzaPagamentoTributiLocali = exports.resetRiepilogoTributiLocali = exports.controlloPagamentoTributiLocali = exports.resetDettaglioTributiLocali = exports.setTributiLocaliSetDettaglio = exports.resetFormTributiLocali = exports.setTributiLocaliSetPresaVisione = exports.setTributiLocaliSetRapportoSelezionato = exports.setTributiLocaliSetStepForm = exports.resetFiltriTributiLocali = exports.setTributiLocaliSetFiltriSubmitted = exports.setTributiLocaliSetCodicePagamento = exports.setTributiLocaliSetCodiceFiscaleContribuente = exports.resetNotificaTributi = exports.getNotificaTributiLocali = exports.resetElencoTributiContoTerzi = exports.getElencoTributiContoTerzi = exports.resetElencoTributiInScadenza = exports.getElencoTributiInScadenza = exports.getParametriTributiLocali = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const tributiLocali_selectors_1 = require("./tributiLocali.selectors");
/* get parametri tributi locali - START */
function getParametriTributiLocaliStart() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_PARAMETRI_START
  };
}
function getParametriTributiLocaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError) {
  return async dispatch => {
    dispatch((0, general_1.setError)(value, showGenericModalError));
  };
}
const getParametriTributiLocali = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getParametriTributiLocaliStart());
  if (extraArguments.mock) {
    dispatch((0, general_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PagamentoTributiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getParametriPagamentoTributi().then(response => {
        var _a;
        dispatch(getParametriTributiLocaliSuccess(response));
        ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.TRIBUTI_LOCALI
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriTributiLocali = getParametriTributiLocali;
/* get parametri tributi locali - FINE */
/* get elenco tributi in scadenza - INIZIO */
function getElencoTributiInScadenzaStart() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_SCADENZA_START
  };
}
function getElencoTributiInScadenzaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_SCADENZA_SUCCESS,
    payload
  };
}
const getElencoTributiInScadenza = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getElencoTributiInScadenzaStart());
  if (extraArguments.mock) {
    dispatch((0, general_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PagamentoTributiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaTributi().then(response => {
        dispatch(getElencoTributiInScadenzaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getElencoTributiInScadenza = getElencoTributiInScadenza;
function resetElencoTributiInScadenza() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_SCADENZA
  };
}
exports.resetElencoTributiInScadenza = resetElencoTributiInScadenza;
/* get elenco tributi in scadenza - FINE */
/* get elenco tributi conto terzi - INIZIO */
function getElencoTributiContoTerziStart() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_CONTO_TERZI_START
  };
}
function getElencoTributiContoTerziSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_CONTO_TERZI_SUCCESS,
    payload
  };
}
const getElencoTributiContoTerzi = () => async (dispatch, getState, extraArguments) => {
  const state = getState().tributiLocali;
  dispatch((0, general_1.setLoader)(true));
  dispatch(getElencoTributiContoTerziStart());
  if (extraArguments.mock) {
    dispatch((0, general_1.setLoader)(false));
  } else {
    const requestParameters = {
      pagamentoTributiRichiestaDto: (0, tributiLocali_selectors_1.buildFiltriPagamentiContoTerzi)(state)
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PagamentoTributiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaTributiContoTerzi(requestParameters).then(response => {
        dispatch(getElencoTributiContoTerziSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getElencoTributiContoTerzi = getElencoTributiContoTerzi;
function resetElencoTributiContoTerzi() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_CONTO_TERZI
  };
}
exports.resetElencoTributiContoTerzi = resetElencoTributiContoTerzi;
/* get elenco tributi conto terzi - FINE */
/* get notifica tributi locali - INIZIO */
function getNotificaTributiLocaliStart() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_NOTIFICA_TRIBUTI_START
  };
}
function getNotificaTributiLocaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_GET_NOTIFICA_TRIBUTI_SUCCESS,
    payload
  };
}
const getNotificaTributiLocali = function () {
  let loaderEnabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_1.setLoader)(true));
    dispatch(getNotificaTributiLocaliStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/notifica_tributi.json')).then(response => {
        dispatch(getNotificaTributiLocaliSuccess((0, api_rest_1.NotificaTributiDtoFromJSON)(response)));
        loaderEnabled && dispatch((0, general_1.setLoader)(false));
      });
      loaderEnabled && dispatch((0, general_1.setLoader)(false));
    } else {
      extraArguments.getItem('jwt').then(jwt => {
        new api_rest_1.PagamentoTributiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).notificaTributiHome().then(response => {
          dispatch(getNotificaTributiLocaliSuccess(response));
        }).catch(async error => {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
          loaderEnabled && dispatch((0, general_1.setLoader)(false));
        });
      });
    }
  };
};
exports.getNotificaTributiLocali = getNotificaTributiLocali;
function resetNotificaTributi() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_NOTIFICA_TRIBUTI
  };
}
exports.resetNotificaTributi = resetNotificaTributi;
/* get notifica tributi locali - FINE */
/* FILTRI - START */
function setTributiLocaliSetCodiceFiscaleContribuente(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_CODICE_FISCALE_CONTRIBUENTE,
    payload
  };
}
exports.setTributiLocaliSetCodiceFiscaleContribuente = setTributiLocaliSetCodiceFiscaleContribuente;
function setTributiLocaliSetCodicePagamento(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_CODICE_PAGAMENTO,
    payload
  };
}
exports.setTributiLocaliSetCodicePagamento = setTributiLocaliSetCodicePagamento;
function setTributiLocaliSetFiltriSubmitted(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_FILTRI_SUBMITTED,
    payload
  };
}
exports.setTributiLocaliSetFiltriSubmitted = setTributiLocaliSetFiltriSubmitted;
function resetFiltriTributiLocali() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_FILTRI
  };
}
exports.resetFiltriTributiLocali = resetFiltriTributiLocali;
/* FILTRI - END */
/* FORM - START */
function setTributiLocaliSetStepForm(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_FORM_STEP,
    payload
  };
}
exports.setTributiLocaliSetStepForm = setTributiLocaliSetStepForm;
function setTributiLocaliSetRapportoSelezionato(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RAPPORTO_SELEZIONATO,
    payload
  };
}
exports.setTributiLocaliSetRapportoSelezionato = setTributiLocaliSetRapportoSelezionato;
function setTributiLocaliSetPresaVisione(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_PRESA_VISIONE,
    payload
  };
}
exports.setTributiLocaliSetPresaVisione = setTributiLocaliSetPresaVisione;
function resetFormTributiLocali() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_FORM
  };
}
exports.resetFormTributiLocali = resetFormTributiLocali;
/* FORM - END */
/* DETTAGLIO - START */
function setTributiLocaliSetDettaglio(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_SET_DETTAGLIO,
    payload
  };
}
exports.setTributiLocaliSetDettaglio = setTributiLocaliSetDettaglio;
function resetDettaglioTributiLocali() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_DETTAGLIO
  };
}
exports.resetDettaglioTributiLocali = resetDettaglioTributiLocali;
/* DETTAGLIO - END */
/* CONTROLLO PAGAMENTO - START */
function controlloPagamentoTributiLocaliStart() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_CONTROLLO_PAGAMENTO_START
  };
}
function controlloPagamentoTributiLocaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_CONTROLLO_PAGAMENTO_SUCCESS,
    payload
  };
}
const controlloPagamentoTributiLocali = () => async (dispatch, getState, extraArguments) => {
  const state = getState().tributiLocali;
  dispatch((0, general_1.setLoader)(true));
  dispatch(controlloPagamentoTributiLocaliStart());
  if (extraArguments.mock) {
    dispatch((0, general_1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const requestParameters = {
        pagamentoTributiRichiestaDto: (0, tributiLocali_selectors_1.buildPagamentoTributiRichiesta)(state)
      };
      new api_rest_1.PagamentoTributiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloPagamentoTributi(requestParameters).then(response => {
        dispatch(controlloPagamentoTributiLocaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.controlloPagamentoTributiLocali = controlloPagamentoTributiLocali;
function resetRiepilogoTributiLocali() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_RIEPILOGO
  };
}
exports.resetRiepilogoTributiLocali = resetRiepilogoTributiLocali;
/* CONTROLLO PAGAMENTO - END */
/* AUTORIZZA PAGAMENTO - START */
function autorizzaPagamentoTributiLocaliStart() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_AUTORIZZA_PAGAMENTO_START
  };
}
function autorizzaPagamentoTributiLocaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_AUTORIZZA_PAGAMENTO_SUCCESS,
    payload
  };
}
const autorizzaPagamentoTributiLocali = (idDispositiva, credenzialeDispositivaDto) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(autorizzaPagamentoTributiLocaliStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/tributi_autorizza.json')).then(parametri => {
      dispatch(autorizzaPagamentoTributiLocaliSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSON)(parametri)));
      dispatch((0, general_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const requestParameters = {
        credenzialeDispositivaDto,
        idDispositiva
      };
      new api_rest_1.PagamentoTributiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaPagamentoTributi(requestParameters).then(response => {
        dispatch(autorizzaPagamentoTributiLocaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaPagamentoTributiLocali = autorizzaPagamentoTributiLocali;
function resetEsitoTributiLocali() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_RESET_ESITO
  };
}
exports.resetEsitoTributiLocali = resetEsitoTributiLocali;
/* AUTORIZZA PAGAMENTO - END */
/* Info autenticazione pagamento tributi locali - START */
function getInfoAutenticazionePagamentoTributiLocaliStart() {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_INFO_AUTENTICAZIONE_PAGAMENTO_START
  };
}
function getInfoAutenticazionePagamentoTributiLocaliSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.TRIBUTI_LOCALI_INFO_AUTENTICAZIONE_PAGAMENTO_SUCCESS,
    payload
  };
}
const getInfoAutenticazionePagamentroTributiLocali = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getInfoAutenticazionePagamentoTributiLocaliStart());
  if (extraArguments.mock) {
    dispatch((0, general_1.setLoader)(false));
  } else {
    const requestParameters = {
      idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.PagamentoTributiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).infoAutenticazionePagamentoTributi(requestParameters).then(response => {
        dispatch(getInfoAutenticazionePagamentoTributiLocaliSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoAutenticazionePagamentroTributiLocali = getInfoAutenticazionePagamentroTributiLocali;
/* Info autenticazione revoca time deposit - END */