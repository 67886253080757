"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrdiniScadenzaDtoToJSON = exports.OrdiniScadenzaDtoFromJSONTyped = exports.OrdiniScadenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OrdiniScadenzaDtoFromJSON(json) {
  return OrdiniScadenzaDtoFromJSONTyped(json, false);
}
exports.OrdiniScadenzaDtoFromJSON = OrdiniScadenzaDtoFromJSON;
function OrdiniScadenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'ordini': !runtime_1.exists(json, 'ordini') ? undefined : json['ordini'].map(_1.OrdineDtoFromJSON),
    'totaleOrdini': !runtime_1.exists(json, 'totaleOrdini') ? undefined : json['totaleOrdini']
  };
}
exports.OrdiniScadenzaDtoFromJSONTyped = OrdiniScadenzaDtoFromJSONTyped;
function OrdiniScadenzaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'ordini': value.ordini === undefined ? undefined : value.ordini.map(_1.OrdineDtoToJSON),
    'totaleOrdini': value.totaleOrdini
  };
}
exports.OrdiniScadenzaDtoToJSON = OrdiniScadenzaDtoToJSON;