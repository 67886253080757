"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneFinanzaDtoToJSON = exports.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON(json) {
  return EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON = EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON;
function EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
    'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.OrdineTradingDtoFromJSON(json['disposizione'])
  };
}
exports.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneFinanzaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'codiceErrore': value.codiceErrore,
    'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    'disposizione': _1.OrdineTradingDtoToJSON(value.disposizione)
  };
}
exports.EsitoAutorizzazioneDisposizioneFinanzaDtoToJSON = EsitoAutorizzazioneDisposizioneFinanzaDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum) {
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["OK"] = "OK";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ACCP"] = "ACCP";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ACSC"] = "ACSC";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ACSP"] = "ACSP";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ACTC"] = "ACTC";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ACWC"] = "ACWC";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["ACWP"] = "ACWP";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["RCVD"] = "RCVD";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["PDNG"] = "PDNG";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["RJCT"] = "RJCT";
  EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneFinanzaDtoStatoEnum = {}));