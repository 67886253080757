"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticationStartRequestDtoToJSON = exports.AuthenticationStartRequestDtoFromJSONTyped = exports.AuthenticationStartRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AuthenticationStartRequestDtoFromJSON(json) {
  return AuthenticationStartRequestDtoFromJSONTyped(json, false);
}
exports.AuthenticationStartRequestDtoFromJSON = AuthenticationStartRequestDtoFromJSON;
function AuthenticationStartRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'authenticationContext': !runtime_1.exists(json, 'authenticationContext') ? undefined : json['authenticationContext'],
    'captchaContext': !runtime_1.exists(json, 'captchaContext') ? undefined : json['captchaContext'],
    'identityStoreId': !runtime_1.exists(json, 'identityStoreId') ? undefined : _1.IdentityStoreIdEnumFromJSON(json['identityStoreId']),
    'options': !runtime_1.exists(json, 'options') ? undefined : _1.AuthenticationStartRequestOptionsDtoFromJSON(json['options']),
    'password': !runtime_1.exists(json, 'password') ? undefined : json['password'],
    'solvedCaptcha': !runtime_1.exists(json, 'solvedCaptcha') ? undefined : json['solvedCaptcha'],
    'useralias': !runtime_1.exists(json, 'useralias') ? undefined : json['useralias'],
    'username': !runtime_1.exists(json, 'username') ? undefined : json['username']
  };
}
exports.AuthenticationStartRequestDtoFromJSONTyped = AuthenticationStartRequestDtoFromJSONTyped;
function AuthenticationStartRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'authenticationContext': value.authenticationContext,
    'captchaContext': value.captchaContext,
    'identityStoreId': _1.IdentityStoreIdEnumToJSON(value.identityStoreId),
    'options': _1.AuthenticationStartRequestOptionsDtoToJSON(value.options),
    'password': value.password,
    'solvedCaptcha': value.solvedCaptcha,
    'useralias': value.useralias,
    'username': value.username
  };
}
exports.AuthenticationStartRequestDtoToJSON = AuthenticationStartRequestDtoToJSON;