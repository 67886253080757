"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PreCustomersAggregationLevelBODtoToJSON = exports.PreCustomersAggregationLevelBODtoFromJSONTyped = exports.PreCustomersAggregationLevelBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PreCustomersAggregationLevelBODtoFromJSON(json) {
  return PreCustomersAggregationLevelBODtoFromJSONTyped(json, false);
}
exports.PreCustomersAggregationLevelBODtoFromJSON = PreCustomersAggregationLevelBODtoFromJSON;
function PreCustomersAggregationLevelBODtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'averageIdleCapital': !runtime_1.exists(json, 'averageIdleCapital') ? undefined : json['averageIdleCapital'],
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'children': !runtime_1.exists(json, 'children') ? undefined : json['children'].map(_1.PreCustomerLevelBODtoFromJSON),
    'codiciClienti': !runtime_1.exists(json, 'codiciClienti') ? undefined : json['codiciClienti'],
    'contributionToPerformance': !runtime_1.exists(json, 'contributionToPerformance') ? undefined : json['contributionToPerformance'],
    'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
    'finalCapital': !runtime_1.exists(json, 'finalCapital') ? undefined : json['finalCapital'],
    'flowCouponsDividends': !runtime_1.exists(json, 'flowCouponsDividends') ? undefined : json['flowCouponsDividends'],
    'flowDeposit': !runtime_1.exists(json, 'flowDeposit') ? undefined : json['flowDeposit'],
    'flowWithdrawal': !runtime_1.exists(json, 'flowWithdrawal') ? undefined : json['flowWithdrawal'],
    'initialCapital': !runtime_1.exists(json, 'initialCapital') ? undefined : json['initialCapital'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'netReturn': !runtime_1.exists(json, 'netReturn') ? undefined : json['netReturn'],
    'performance': !runtime_1.exists(json, 'performance') ? undefined : json['performance'],
    'startDate': !runtime_1.exists(json, 'startDate') ? undefined : json['startDate'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId']
  };
}
exports.PreCustomersAggregationLevelBODtoFromJSONTyped = PreCustomersAggregationLevelBODtoFromJSONTyped;
function PreCustomersAggregationLevelBODtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'averageIdleCapital': value.averageIdleCapital,
    'bankCode': value.bankCode,
    'children': value.children === undefined ? undefined : value.children.map(_1.PreCustomerLevelBODtoToJSON),
    'codiciClienti': value.codiciClienti,
    'contributionToPerformance': value.contributionToPerformance,
    'endDate': value.endDate,
    'finalCapital': value.finalCapital,
    'flowCouponsDividends': value.flowCouponsDividends,
    'flowDeposit': value.flowDeposit,
    'flowWithdrawal': value.flowWithdrawal,
    'initialCapital': value.initialCapital,
    'message': value.message,
    'netReturn': value.netReturn,
    'performance': value.performance,
    'startDate': value.startDate,
    'userId': value.userId
  };
}
exports.PreCustomersAggregationLevelBODtoToJSON = PreCustomersAggregationLevelBODtoToJSON;