"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomerWebCollaborationControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CustomerWebCollaborationControllerApi extends runtime.BaseAPI {
  /**
   * Create Calendar Event
   */
  createEventFromCustomerRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling createEventFromCustomer.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling createEventFromCustomer.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling createEventFromCustomer.');
      }
      if (requestParameters.iduser === null || requestParameters.iduser === undefined) {
        throw new runtime.RequiredError('iduser', 'Required parameter requestParameters.iduser was null or undefined when calling createEventFromCustomer.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling createEventFromCustomer.');
      }
      if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
        throw new runtime.RequiredError('requestBody', 'Required parameter requestParameters.requestBody was null or undefined when calling createEventFromCustomer.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/user/{iduser}/calendar/event".replace("{".concat("iduser", "}"), encodeURIComponent(String(requestParameters.iduser))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.EventRequestToJSON(requestParameters.requestBody)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EventResponseFromJSON(jsonValue));
    });
  }
  /**
   * Create Calendar Event
   */
  createEventFromCustomer(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.createEventFromCustomerRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Delete Event
   */
  deleteEventFromCustomerRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling deleteEventFromCustomer.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling deleteEventFromCustomer.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling deleteEventFromCustomer.');
      }
      if (requestParameters.idEvent === null || requestParameters.idEvent === undefined) {
        throw new runtime.RequiredError('idEvent', 'Required parameter requestParameters.idEvent was null or undefined when calling deleteEventFromCustomer.');
      }
      if (requestParameters.iduser === null || requestParameters.iduser === undefined) {
        throw new runtime.RequiredError('iduser', 'Required parameter requestParameters.iduser was null or undefined when calling deleteEventFromCustomer.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling deleteEventFromCustomer.');
      }
      const queryParameters = {};
      const headerParameters = {};
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/user/{iduser}/calendar/event/{idEvent}".replace("{".concat("idEvent", "}"), encodeURIComponent(String(requestParameters.idEvent))).replace("{".concat("iduser", "}"), encodeURIComponent(String(requestParameters.iduser))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EventResponseFromJSON(jsonValue));
    });
  }
  /**
   * Delete Event
   */
  deleteEventFromCustomer(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.deleteEventFromCustomerRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Find Counselor using customer NDG
   */
  findCounselorRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling findCounselor.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling findCounselor.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling findCounselor.');
      }
      if (requestParameters.ndg === null || requestParameters.ndg === undefined) {
        throw new runtime.RequiredError('ndg', 'Required parameter requestParameters.ndg was null or undefined when calling findCounselor.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling findCounselor.');
      }
      const queryParameters = {};
      const headerParameters = {};
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/user/{ndg}/find".replace("{".concat("ndg", "}"), encodeURIComponent(String(requestParameters.ndg))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.CounselorResponseFromJSON(jsonValue));
    });
  }
  /**
   * Find Counselor using customer NDG
   */
  findCounselor(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.findCounselorRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Get List of Service Product.
   */
  getAllServiceProductCustomerRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling getAllServiceProductCustomer.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling getAllServiceProductCustomer.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling getAllServiceProductCustomer.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling getAllServiceProductCustomer.');
      }
      const queryParameters = {};
      const headerParameters = {};
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/serviceproduct/all",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.ServiceProductResponseFromJSON));
    });
  }
  /**
   * Get List of Service Product.
   */
  getAllServiceProductCustomer(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getAllServiceProductCustomerRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Get Calendar availables slot.This API return slots considering events where the User is REQUIRED.
   */
  getCalendarFromCustomerRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling getCalendarFromCustomer.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling getCalendarFromCustomer.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling getCalendarFromCustomer.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling getCalendarFromCustomer.');
      }
      if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
        throw new runtime.RequiredError('requestBody', 'Required parameter requestParameters.requestBody was null or undefined when calling getCalendarFromCustomer.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/user/calendar",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CalendarRequestToJSON(requestParameters.requestBody)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.DayFromJSON));
    });
  }
  /**
   * Get Calendar availables slot.This API return slots considering events where the User is REQUIRED.
   */
  getCalendarFromCustomer(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getCalendarFromCustomerRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Get configuration details
   */
  getConfigFromCustomerRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling getConfigFromCustomer.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling getConfigFromCustomer.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling getConfigFromCustomer.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling getConfigFromCustomer.');
      }
      const queryParameters = {};
      const headerParameters = {};
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/config",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ConfigResponseFromJSON(jsonValue));
    });
  }
  /**
   * Get configuration details
   */
  getConfigFromCustomer(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getConfigFromCustomerRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Get Customer Events by identifier
   */
  getCustomerEventsRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling getCustomerEvents.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling getCustomerEvents.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling getCustomerEvents.');
      }
      if (requestParameters.ndg === null || requestParameters.ndg === undefined) {
        throw new runtime.RequiredError('ndg', 'Required parameter requestParameters.ndg was null or undefined when calling getCustomerEvents.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling getCustomerEvents.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/event/customer/{ndg}".replace("{".concat("ndg", "}"), encodeURIComponent(String(requestParameters.ndg))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CustomerEventFilterRequestToJSON(requestParameters.requestBody)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.EventResponseFromJSON));
    });
  }
  /**
   * Get Customer Events by identifier
   */
  getCustomerEvents(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getCustomerEventsRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Get valid Token meeting
   */
  getEventTokenRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling getEventToken.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling getEventToken.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling getEventToken.');
      }
      if (requestParameters.idEvent === null || requestParameters.idEvent === undefined) {
        throw new runtime.RequiredError('idEvent', 'Required parameter requestParameters.idEvent was null or undefined when calling getEventToken.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling getEventToken.');
      }
      const queryParameters = {};
      const headerParameters = {};
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/event/{idEvent}/token".replace("{".concat("idEvent", "}"), encodeURIComponent(String(requestParameters.idEvent))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OnlineMeetingResponseFromJSON(jsonValue));
    });
  }
  /**
   * Get valid Token meeting
   */
  getEventToken(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getEventTokenRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Get Multilang.
   */
  getMultilangRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling getMultilang.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling getMultilang.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling getMultilang.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling getMultilang.');
      }
      const queryParameters = {};
      const headerParameters = {};
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/multilang",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response);
    });
  }
  /**
   * Get Multilang.
   */
  getMultilang(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getMultilangRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Get Counselor presence by identifier (graph ID)
   */
  getPresenceRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling getPresence.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling getPresence.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling getPresence.');
      }
      if (requestParameters.iduser === null || requestParameters.iduser === undefined) {
        throw new runtime.RequiredError('iduser', 'Required parameter requestParameters.iduser was null or undefined when calling getPresence.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling getPresence.');
      }
      const queryParameters = {};
      const headerParameters = {};
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/user/{iduser}/presence".replace("{".concat("iduser", "}"), encodeURIComponent(String(requestParameters.iduser))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.PresenceResponseFromJSON(jsonValue));
    });
  }
  /**
   * Get Counselor presence by identifier (graph ID)
   */
  getPresence(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getPresenceRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Update Event
   */
  updateEventFromCustomerRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
        throw new runtime.RequiredError('authorization', 'Required parameter requestParameters.authorization was null or undefined when calling updateEventFromCustomer.');
      }
      if (requestParameters.channel === null || requestParameters.channel === undefined) {
        throw new runtime.RequiredError('channel', 'Required parameter requestParameters.channel was null or undefined when calling updateEventFromCustomer.');
      }
      if (requestParameters.correlationId === null || requestParameters.correlationId === undefined) {
        throw new runtime.RequiredError('correlationId', 'Required parameter requestParameters.correlationId was null or undefined when calling updateEventFromCustomer.');
      }
      if (requestParameters.idEvent === null || requestParameters.idEvent === undefined) {
        throw new runtime.RequiredError('idEvent', 'Required parameter requestParameters.idEvent was null or undefined when calling updateEventFromCustomer.');
      }
      if (requestParameters.iduser === null || requestParameters.iduser === undefined) {
        throw new runtime.RequiredError('iduser', 'Required parameter requestParameters.iduser was null or undefined when calling updateEventFromCustomer.');
      }
      if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
        throw new runtime.RequiredError('tenant', 'Required parameter requestParameters.tenant was null or undefined when calling updateEventFromCustomer.');
      }
      if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
        throw new runtime.RequiredError('requestBody', 'Required parameter requestParameters.requestBody was null or undefined when calling updateEventFromCustomer.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
      }
      if (requestParameters.channel !== undefined && requestParameters.channel !== null) {
        headerParameters['channel'] = String(requestParameters.channel);
      }
      if (requestParameters.correlationId !== undefined && requestParameters.correlationId !== null) {
        headerParameters['correlation_id'] = String(requestParameters.correlationId);
      }
      if (requestParameters.tenant !== undefined && requestParameters.tenant !== null) {
        headerParameters['tenant'] = String(requestParameters.tenant);
      }
      const response = yield this.request({
        path: "/webcollaboration/api/v1/customer/user/{iduser}/calendar/event/{idEvent}".replace("{".concat("idEvent", "}"), encodeURIComponent(String(requestParameters.idEvent))).replace("{".concat("iduser", "}"), encodeURIComponent(String(requestParameters.iduser))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.EventUpdateFilterRequestToJSON(requestParameters.requestBody)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EventResponseFromJSON(jsonValue));
    });
  }
  /**
   * Update Event
   */
  updateEventFromCustomer(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.updateEventFromCustomerRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.CustomerWebCollaborationControllerApi = CustomerWebCollaborationControllerApi;