"use strict";

/* get oneclick - START */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esportaAssegniEXCEL = exports.esportaDettaglioAssegnoPDF = exports.esportaAssegniPDF = exports.resetAssegni = exports.setAssegniSetRapportoSelezionato = exports.resetAssegniFiltri = exports.resetAssegniCarnetSelezionato = exports.setAssegniSetCarnetSelezionato = exports.setMostraCarnetEsauriti = exports.getParametriAssegni = exports.getAssegni = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
/* get assegni - INIZIO */
function getAssegniStart() {
  return {
    type: __1.EReduxActionTypes.GET_ASSEGNI_START
  };
}
function getAssegniSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.GET_ASSEGNI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_1.setError)(value));
  };
}
/**
 *
 * @param filtriAssegniItalia codice rapportp: rapporto selezionato; soloAManoCliente (default true) -> mostra carnet non esauriti, false -> mostra anche carnet esauriti
 */
const getAssegni = filtriAssegniItalia => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getAssegniStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(parametri => {
      dispatch(getAssegniSuccess((0, api_rest_1.AssegniItaliaDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const request = {
        filtroAssegniItaliaDto: filtriAssegniItalia
      };
      new api_rest_1.AssegniControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getAssegniItalia(request).then(response => {
        dispatch(getAssegniSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getAssegni = getAssegni;
/* get assegni - FINE */
/* get parametri One Click - START */
function getParametriAssegniStart() {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_GET_PARAMETRI_START
  };
}
function getParametriAssegniSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
const getParametriAssegni = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(getParametriAssegniStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/assegni_parametri.json')).then(parametri => {
      dispatch(getParametriAssegniSuccess((0, api_rest_1.ParametriAssegniDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssegniControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersAssegno().then(response => {
        var _a;
        dispatch(getParametriAssegniSuccess(response));
        ((_a = response.rapportiItalia) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.ASSEGNI
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriAssegni = getParametriAssegni;
/* get parametri Assegni - FINE */
// set flag mostraCarnetEsauriti
function setMostraCarnetEsauriti(payload) {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_MOSTRA_CARNET_ESAURITI,
    payload
  };
}
exports.setMostraCarnetEsauriti = setMostraCarnetEsauriti;
function setAssegniSetCarnetSelezionato(carnetSelezionato) {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_CARNET_SELEZIONATO,
    payload: carnetSelezionato
  };
}
exports.setAssegniSetCarnetSelezionato = setAssegniSetCarnetSelezionato;
function resetAssegniCarnetSelezionato() {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_RESET_CARNET_SELEZIONATO
  };
}
exports.resetAssegniCarnetSelezionato = resetAssegniCarnetSelezionato;
function resetAssegniFiltri() {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_RESET_FILTRI
  };
}
exports.resetAssegniFiltri = resetAssegniFiltri;
function setAssegniSetRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setAssegniSetRapportoSelezionato = setAssegniSetRapportoSelezionato;
function resetAssegni() {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_RESET
  };
}
exports.resetAssegni = resetAssegni;
/* esporta PDF Assegni - START */
function esportaAssegniPDFStart() {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_ESPORTA_PDF_START
  };
}
function esportaAssegniPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_ESPORTA_PDF_SUCCESS,
    payload
  };
}
const esportaAssegniPDF = (datiForm, share, title, message) => async (dispatch, _, extraArguments) => {
  dispatch(esportaAssegniPDFStart());
  dispatch((0, general_1.setLoader)(true));
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
      import('../../mock/assegni.json').then(parametri => {
        dispatch(esportaAssegniPDFSuccess(ListaAssegniDtoFromJSON(parametri)));
      });
      */
  } else {
    const esportaAssegniPDFRequest = {
      type: api_rest_1.ElencoAssegniItaliaPdfTypeEnum.pdf,
      filtroAssegniItaliaDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssegniControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).elencoAssegniItaliaPdf(esportaAssegniPDFRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'assegni.pdf', response, share, title, message);
        }
        dispatch(esportaAssegniPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.esportaAssegniPDF = esportaAssegniPDF;
/* esporta PDF Assegni - FINE */
/* esporta PDF dettaglio Assegno - START */
function esportaDettaglioAssegnoPDFStart() {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_ESPORTA_DETTAGLIO_PDF_START
  };
}
function esportaDettaglioAssegnoPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_ESPORTA_DETTAGLIO_PDF_SUCCESS,
    payload
  };
}
const esportaDettaglioAssegnoPDF = (datiForm, share, title, message) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(esportaDettaglioAssegnoPDFStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/dettaglio_assegno.json').then(parametri => {
      dispatch(esportaDettaglioAssegnoPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const esportaDettaglioAssegnoPDFRequest = {
      filtroAssegniItaliaDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssegniControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioAssegnoItaliaPdf(esportaDettaglioAssegnoPDFRequest).then(async response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'dettaglio_assegno.pdf', response, share, title, message);
        }
        dispatch(esportaDettaglioAssegnoPDFSuccess(response));
      }).catch(async error => {
        try {
          dispatch(generalError(await (0, helpers_1.formatError)(error)));
        } catch (e) {
          console.log(e);
        }
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.esportaDettaglioAssegnoPDF = esportaDettaglioAssegnoPDF;
/* esporta PDF dettaglio Assegno - FINE */
/* esporta EXCEL assegni - START */
function esportaAssegniEXCELStart() {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_ESPORTA_EXCEL_START
  };
}
function esportaAssegniEXCELSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.ASSEGNI_ESPORTA_EXCEL_SUCCESS,
    payload
  };
}
const esportaAssegniEXCEL = (datiForm, share) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_1.setLoader)(true));
  dispatch(esportaAssegniEXCELStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
      import('../../mock/lista_assegni.json').then(parametri => {
        dispatch(esportaAssegniEXCELSuccess(ListaMovimentiDtoFromJSON(parametri)));
      });
      */
  } else {
    const esportaAssegniEXCELRequest = {
      type: api_rest_1.ElencoAssegniItaliaPdfTypeEnum.excel,
      filtroAssegniItaliaDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.AssegniControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).elencoAssegniItaliaPdf(esportaAssegniEXCELRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadleExcelResponse)(response, 'assegni.xlsx');
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_shareXLS)(extraArguments, 'assegni.xls', response, share);
        }
        dispatch(esportaAssegniEXCELSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_1.setLoader)(false));
      });
    });
  }
};
exports.esportaAssegniEXCEL = esportaAssegniEXCEL;
/* esporta EXCEL assegni - FINE */