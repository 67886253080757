"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterBilancioDirty = exports.filterBilancioValid = exports.buildFilterBilancio = exports.filtriBilancio = exports.bilancioGrafico = exports.bilancio = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const bilancioReducer = state => state;
exports.bilancio = (0, reselect_1.createSelector)(bilancioReducer, state => {
  return state.bilancio;
});
exports.bilancioGrafico = (0, reselect_1.createSelector)(bilancioReducer, state => {
  var _a;
  return [...(((_a = state.bilancio) === null || _a === void 0 ? void 0 : _a.mesiBilancio) || [])].reverse();
});
exports.filtriBilancio = (0, reselect_1.createSelector)(bilancioReducer, state => {
  return state.filtri;
});
exports.buildFilterBilancio = (0, reselect_1.createSelector)(bilancioReducer, state => {
  var _a, _b;
  let filter = {
    dataIniziale: (0, helpers_1.getFirstDayOfMonth)(state.filtri.dataIniziale),
    dataFinale: (0, helpers_1.getLastDayOfMonth)(state.filtri.dataFinale)
  };
  if (!state.filtri.tuttiRapportiSelezionati) {
    filter = {
      ...filter,
      codiceRapporto: (_b = (_a = state.filtri.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id
    };
  }
  return filter;
});
exports.filterBilancioValid = (0, reselect_1.createSelector)(bilancioReducer, state => {
  let formValid = false;
  if (!!state.filtri.dataIniziale && !!state.filtri.dataFinale) {
    formValid = true;
  }
  return formValid;
});
exports.filterBilancioDirty = (0, reselect_1.createSelector)(bilancioReducer, state => {
  return state.filtri.filtriDirty;
});