"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificaDtoTipoEnum = exports.NotificaDtoAvvisoEnum = exports.NotificaDtoToJSON = exports.NotificaDtoFromJSONTyped = exports.NotificaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function NotificaDtoFromJSON(json) {
  return NotificaDtoFromJSONTyped(json, false);
}
exports.NotificaDtoFromJSON = NotificaDtoFromJSON;
function NotificaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'idAppuntamento': !runtime_1.exists(json, 'idAppuntamento') ? undefined : json['idAppuntamento'],
    'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
    'avviso': !runtime_1.exists(json, 'avviso') ? undefined : json['avviso'],
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    'dataNotifica': !runtime_1.exists(json, 'dataNotifica') ? undefined : new Date(json['dataNotifica']),
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'idRipetitivo': !runtime_1.exists(json, 'idRipetitivo') ? undefined : json['idRipetitivo'],
    'dataAppuntamento': !runtime_1.exists(json, 'dataAppuntamento') ? undefined : new Date(json['dataAppuntamento']),
    'modificabile': !runtime_1.exists(json, 'modificabile') ? undefined : json['modificabile']
  };
}
exports.NotificaDtoFromJSONTyped = NotificaDtoFromJSONTyped;
function NotificaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'idAppuntamento': value.idAppuntamento,
    'canale': value.canale,
    'avviso': value.avviso,
    'tipo': value.tipo,
    'dataNotifica': value.dataNotifica === undefined ? undefined : value.dataNotifica.toISOString(),
    'titolo': value.titolo,
    'idRipetitivo': value.idRipetitivo,
    'dataAppuntamento': value.dataAppuntamento === undefined ? undefined : value.dataAppuntamento.toISOString(),
    'modificabile': value.modificabile
  };
}
exports.NotificaDtoToJSON = NotificaDtoToJSON;
/**
* @export
* @enum {string}
*/
var NotificaDtoAvvisoEnum;
(function (NotificaDtoAvvisoEnum) {
  NotificaDtoAvvisoEnum["GIORNO_STESSO"] = "GIORNO_STESSO";
  NotificaDtoAvvisoEnum["GIORNO_PRIMA"] = "GIORNO_PRIMA";
  NotificaDtoAvvisoEnum["DUE_GIORNI_PRIMA"] = "DUE_GIORNI_PRIMA";
  NotificaDtoAvvisoEnum["SETTIMANA_PRIMA"] = "SETTIMANA_PRIMA";
})(NotificaDtoAvvisoEnum = exports.NotificaDtoAvvisoEnum || (exports.NotificaDtoAvvisoEnum = {}));
/**
* @export
* @enum {string}
*/
var NotificaDtoTipoEnum;
(function (NotificaDtoTipoEnum) {
  NotificaDtoTipoEnum["E"] = "E";
  NotificaDtoTipoEnum["C"] = "C";
})(NotificaDtoTipoEnum = exports.NotificaDtoTipoEnum || (exports.NotificaDtoTipoEnum = {}));