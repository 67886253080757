"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnrollmentStatusDtoToJSON = exports.EnrollmentStatusDtoFromJSONTyped = exports.EnrollmentStatusDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EnrollmentStatusDtoFromJSON(json) {
  return EnrollmentStatusDtoFromJSONTyped(json, false);
}
exports.EnrollmentStatusDtoFromJSON = EnrollmentStatusDtoFromJSON;
function EnrollmentStatusDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'consentId': !runtime_1.exists(json, 'consentId') ? undefined : json['consentId'],
    'consentType': !runtime_1.exists(json, 'consentType') ? undefined : _1.ConsentTypeFromJSON(json['consentType']),
    'oneShot': !runtime_1.exists(json, 'oneShot') ? undefined : json['oneShot'],
    'activatedConsent': !runtime_1.exists(json, 'activatedConsent') ? undefined : json['activatedConsent'],
    'accountList': !runtime_1.exists(json, 'accountList') ? undefined : _1.InlineResponse2004DtoFromJSON(json['accountList'])
  };
}
exports.EnrollmentStatusDtoFromJSONTyped = EnrollmentStatusDtoFromJSONTyped;
function EnrollmentStatusDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'consentId': value.consentId,
    'consentType': _1.ConsentTypeToJSON(value.consentType),
    'oneShot': value.oneShot,
    'activatedConsent': value.activatedConsent,
    'accountList': _1.InlineResponse2004DtoToJSON(value.accountList)
  };
}
exports.EnrollmentStatusDtoToJSON = EnrollmentStatusDtoToJSON;