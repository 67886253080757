"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitoCompilaContrattoDtoStatoEnum = exports.EsitoCompilaContrattoDtoToJSON = exports.EsitoCompilaContrattoDtoFromJSONTyped = exports.EsitoCompilaContrattoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function EsitoCompilaContrattoDtoFromJSON(json) {
  return EsitoCompilaContrattoDtoFromJSONTyped(json, false);
}
exports.EsitoCompilaContrattoDtoFromJSON = EsitoCompilaContrattoDtoFromJSON;
function EsitoCompilaContrattoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'jobUrl': !runtime_1.exists(json, 'jobUrl') ? undefined : json['jobUrl'],
    'shortJobUrl': !runtime_1.exists(json, 'shortJobUrl') ? undefined : json['shortJobUrl']
  };
}
exports.EsitoCompilaContrattoDtoFromJSONTyped = EsitoCompilaContrattoDtoFromJSONTyped;
function EsitoCompilaContrattoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'stato': value.stato,
    'jobUrl': value.jobUrl,
    'shortJobUrl': value.shortJobUrl
  };
}
exports.EsitoCompilaContrattoDtoToJSON = EsitoCompilaContrattoDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoCompilaContrattoDtoStatoEnum;
(function (EsitoCompilaContrattoDtoStatoEnum) {
  EsitoCompilaContrattoDtoStatoEnum["CERTIFICATO_NON_PRESENTE"] = "CERTIFICATO_NON_PRESENTE";
  EsitoCompilaContrattoDtoStatoEnum["CERTIFICATO_INVIATO"] = "CERTIFICATO_INVIATO";
  EsitoCompilaContrattoDtoStatoEnum["CERTIFICATO_POLLING_SCADUTO"] = "CERTIFICATO_POLLING_SCADUTO";
  EsitoCompilaContrattoDtoStatoEnum["CONTRATTO_COMPILATO"] = "CONTRATTO_COMPILATO";
  EsitoCompilaContrattoDtoStatoEnum["CONTRATTO_POLLING_SCADUTO"] = "CONTRATTO_POLLING_SCADUTO";
  EsitoCompilaContrattoDtoStatoEnum["CONTRATTO_FIRMATO"] = "CONTRATTO_FIRMATO";
})(EsitoCompilaContrattoDtoStatoEnum = exports.EsitoCompilaContrattoDtoStatoEnum || (exports.EsitoCompilaContrattoDtoStatoEnum = {}));