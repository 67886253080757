"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Psd2AttivaControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class Psd2AttivaControllerApi extends runtime.BaseAPI {
  /**
   * Inizia il processo di enrollment con il recupero dell\'elenco aspsp
   */
  aspspListRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/psd2attiva/startenrollment",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.AspspOutDtoFromJSON(jsonValue));
    });
  }
  /**
   * Inizia il processo di enrollment con il recupero dell\'elenco aspsp
   */
  aspspList() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.aspspListRaw();
      return yield response.value();
    });
  }
  /**
   * Secondo step processo di enrollment con il recupero dei consensi
   */
  chechEnrollmentActivityRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.aspspFilterIn === null || requestParameters.aspspFilterIn === undefined) {
        throw new runtime.RequiredError('aspspFilterIn', 'Required parameter requestParameters.aspspFilterIn was null or undefined when calling chechEnrollmentActivity.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/psd2attiva/enrollmentactivities",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.AspspFilterInToJSON(requestParameters.aspspFilterIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EnrollmentActivityDtoFromJSON(jsonValue));
    });
  }
  /**
   * Secondo step processo di enrollment con il recupero dei consensi
   */
  chechEnrollmentActivity(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.chechEnrollmentActivityRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Terzo step processo di enrollment con il recupero dell\'elenco aspsp
   */
  chechEnrollmentStatusRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.enrollmentStatusInDto === null || requestParameters.enrollmentStatusInDto === undefined) {
        throw new runtime.RequiredError('enrollmentStatusInDto', 'Required parameter requestParameters.enrollmentStatusInDto was null or undefined when calling chechEnrollmentStatus.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/psd2attiva/enrollmentstatus",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.EnrollmentStatusInDtoToJSON(requestParameters.enrollmentStatusInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EnrollmentStatusDtoFromJSON(jsonValue));
    });
  }
  /**
   * Terzo step processo di enrollment con il recupero dell\'elenco aspsp
   */
  chechEnrollmentStatus(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.chechEnrollmentStatusRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Revoca consenso per una banca
   */
  revokeRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.revokeConsentRequest === null || requestParameters.revokeConsentRequest === undefined) {
        throw new runtime.RequiredError('revokeConsentRequest', 'Required parameter requestParameters.revokeConsentRequest was null or undefined when calling revoke.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/psd2attiva/revoke",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.RevokeConsentRequestToJSON(requestParameters.revokeConsentRequest)
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Revoca consenso per una banca
   */
  revoke(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.revokeRaw(requestParameters);
    });
  }
  /**
   * pdf per visione consenso
   */
  stampaPdfconsensoRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/psd2attiva/pdfconsenso",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * pdf per visione consenso
   */
  stampaPdfconsenso() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.stampaPdfconsensoRaw();
      return yield response.value();
    });
  }
}
exports.Psd2AttivaControllerApi = Psd2AttivaControllerApi;