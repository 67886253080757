"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GestionePreferitiDtoTipoSelezioneUtenteEnum = exports.GestionePreferitiDtoToJSON = exports.GestionePreferitiDtoFromJSONTyped = exports.GestionePreferitiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function GestionePreferitiDtoFromJSON(json) {
  return GestionePreferitiDtoFromJSONTyped(json, false);
}
exports.GestionePreferitiDtoFromJSON = GestionePreferitiDtoFromJSON;
function GestionePreferitiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'elencoFunzionalita': !runtime_1.exists(json, 'elencoFunzionalita') ? undefined : json['elencoFunzionalita'],
    'tipoSelezioneUtente': !runtime_1.exists(json, 'tipoSelezioneUtente') ? undefined : json['tipoSelezioneUtente'],
    'elencoFunzionalitaCustom': !runtime_1.exists(json, 'elencoFunzionalitaCustom') ? undefined : json['elencoFunzionalitaCustom'],
    'elencoFunzionalitaDefault': !runtime_1.exists(json, 'elencoFunzionalitaDefault') ? undefined : json['elencoFunzionalitaDefault'],
    'elencoFunzionalitaFrequenti': !runtime_1.exists(json, 'elencoFunzionalitaFrequenti') ? undefined : json['elencoFunzionalitaFrequenti']
  };
}
exports.GestionePreferitiDtoFromJSONTyped = GestionePreferitiDtoFromJSONTyped;
function GestionePreferitiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'elencoFunzionalita': value.elencoFunzionalita,
    'tipoSelezioneUtente': value.tipoSelezioneUtente,
    'elencoFunzionalitaCustom': value.elencoFunzionalitaCustom,
    'elencoFunzionalitaDefault': value.elencoFunzionalitaDefault,
    'elencoFunzionalitaFrequenti': value.elencoFunzionalitaFrequenti
  };
}
exports.GestionePreferitiDtoToJSON = GestionePreferitiDtoToJSON;
/**
* @export
* @enum {string}
*/
var GestionePreferitiDtoTipoSelezioneUtenteEnum;
(function (GestionePreferitiDtoTipoSelezioneUtenteEnum) {
  GestionePreferitiDtoTipoSelezioneUtenteEnum["DEFAULT"] = "DEFAULT";
  GestionePreferitiDtoTipoSelezioneUtenteEnum["CUSTOM"] = "CUSTOM";
  GestionePreferitiDtoTipoSelezioneUtenteEnum["FREQUENTI"] = "FREQUENTI";
})(GestionePreferitiDtoTipoSelezioneUtenteEnum = exports.GestionePreferitiDtoTipoSelezioneUtenteEnum || (exports.GestionePreferitiDtoTipoSelezioneUtenteEnum = {}));