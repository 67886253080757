"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.neosuranceReducer = void 0;
const __1 = require("..");
const api_rest_1 = require("@sparkasse/api-rest");
const initialState = {
  polizzeNeosurance: [],
  form: {
    rapportoSelezionato: (0, api_rest_1.NeosurancePoliciesOutDtoFromJSON)({})
  }
};
const neosuranceReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.NEOSURANCE_GET_POLIZZE_SUCCESS:
      return {
        ...state,
        polizzeNeosurance: action.payload
      };
    case __1.EReduxActionTypes.NEOSURANCE_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.neosuranceReducer = neosuranceReducer;