"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CambioCredenzialeRequestDtoToJSON = exports.CambioCredenzialeRequestDtoFromJSONTyped = exports.CambioCredenzialeRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CambioCredenzialeRequestDtoFromJSON(json) {
  return CambioCredenzialeRequestDtoFromJSONTyped(json, false);
}
exports.CambioCredenzialeRequestDtoFromJSON = CambioCredenzialeRequestDtoFromJSON;
function CambioCredenzialeRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'vecchiaCredenziale': !runtime_1.exists(json, 'vecchiaCredenziale') ? undefined : json['vecchiaCredenziale'],
    'nuovaCredenziale': !runtime_1.exists(json, 'nuovaCredenziale') ? undefined : json['nuovaCredenziale'],
    'confermaNuovaCredenziale': !runtime_1.exists(json, 'confermaNuovaCredenziale') ? undefined : json['confermaNuovaCredenziale'],
    'otp': !runtime_1.exists(json, 'otp') ? undefined : json['otp'],
    'otpNextTokenCode': !runtime_1.exists(json, 'otpNextTokenCode') ? undefined : json['otpNextTokenCode'],
    'domandeSegrete': !runtime_1.exists(json, 'domandeSegrete') ? undefined : json['domandeSegrete'],
    'credenziali': !runtime_1.exists(json, 'credenziali') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziali'])
  };
}
exports.CambioCredenzialeRequestDtoFromJSONTyped = CambioCredenzialeRequestDtoFromJSONTyped;
function CambioCredenzialeRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'vecchiaCredenziale': value.vecchiaCredenziale,
    'nuovaCredenziale': value.nuovaCredenziale,
    'confermaNuovaCredenziale': value.confermaNuovaCredenziale,
    'otp': value.otp,
    'otpNextTokenCode': value.otpNextTokenCode,
    'domandeSegrete': value.domandeSegrete,
    'credenziali': _1.CredenzialeDispositivaDtoToJSON(value.credenziali)
  };
}
exports.CambioCredenzialeRequestDtoToJSON = CambioCredenzialeRequestDtoToJSON;