"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetFondiPaperless = exports.exportDocumentoCondizioniFondi = exports.exportCorrispondenzaFondiPdf = exports.salvaCorrispondenzeFondi = exports.getCorrispondenzeFondi = exports.getCondizioniFondi = exports.setFondiPaperlessFormCorrispondenze = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function generalError(value, showGenericModalError) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
  };
}
function setFondiPaperlessFormCorrispondenze(corrispondenze) {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_SET_FORM_CONDIZIONI,
    payload: corrispondenze
  };
}
exports.setFondiPaperlessFormCorrispondenze = setFondiPaperlessFormCorrispondenze;
function getCondizioniFondiStart() {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_GET_CONDIZIONI_FONDI_START
  };
}
function getCondizioniFondiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_GET_CONDIZIONI_FONDI_SUCCESS,
    payload
  };
}
const getCondizioniFondi = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getCondizioniFondiStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).condizioniFondi().then(response => {
        dispatch(getCondizioniFondiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getCondizioniFondi = getCondizioniFondi;
function getCorrispondenzeFondiStart() {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_GET_CORRISPONDENZE_FONDI_START
  };
}
function getCorrispondenzeFondiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_GET_CORRISPONDENZE_FONDI_SUCCESS,
    payload
  };
}
const getCorrispondenzeFondi = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getCorrispondenzeFondiStart());
  if (extraArguments.mock) {} else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).corrispondenzeFondi().then(response => {
        dispatch(getCorrispondenzeFondiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getCorrispondenzeFondi = getCorrispondenzeFondi;
function salvaCorrispondenzeFondiStart() {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_SALVA_CORRISPONDENZE_FONDI_START
  };
}
function salvaCorrispondenzeFondiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_SALVA_CORRISPONDENZE_FONDI_SUCCESS,
    payload
  };
}
const salvaCorrispondenzeFondi = corrispondenzaFondiDto => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(salvaCorrispondenzeFondiStart());
  if (extraArguments.mock) {} else {
    const request = {
      corrispondenzaFondiDto: corrispondenzaFondiDto
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).salvaCorrispondenzeFondi(request).then(response => {
        dispatch(salvaCorrispondenzeFondiSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.salvaCorrispondenzeFondi = salvaCorrispondenzeFondi;
function exportCorrispondenzaFondiPdfStart() {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_EXPORT_CORRISPONDENZE_FONDI_PDF_START
  };
}
function exportCorrispondenzaFondiPdfSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_EXPORT_CORRISPONDENZE_FONDI_PDF_SUCCESS,
    payload
  };
}
const exportCorrispondenzaFondiPdf = (corrispondenzaFondiDto, share, title, message) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(exportCorrispondenzaFondiPdfStart());
  if (extraArguments.mock) {} else {
    const fondiPaperless = getState().fondiPaperless;
    if (!fondiPaperless.esito) {
      dispatch(generalError(await (0, helpers_1.formatError)('')));
      dispatch((0, general_actions_1.setLoader)(false));
      return;
    }
    const request = {
      corrispondenzaFondiDto: fondiPaperless.esito
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).corrispondenzaFondiPdf(request).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'movimenti.pdf', response, share, title, message);
        }
        dispatch(exportCorrispondenzaFondiPdfSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.exportCorrispondenzaFondiPdf = exportCorrispondenzaFondiPdf;
const exportDocumentoCondizioniFondi = () => async (dispatch, getState, extraArguments) => {
  var _a, _b;
  const state = getState().fondiPaperless;
  const messaggio = state.condizioni;
  if (extraArguments.platform === 'mobile') {
    messaggio && (0, helpers_1.downloadFile)(extraArguments, messaggio.nomeAllegato || ((_a = messaggio.allegato) === null || _a === void 0 ? void 0 : _a.name) || '', ((_b = messaggio.allegato) === null || _b === void 0 ? void 0 : _b.data) || '');
  }
};
exports.exportDocumentoCondizioniFondi = exportDocumentoCondizioniFondi;
function resetFondiPaperless() {
  return {
    type: __1.EReduxActionTypes.FONDI_PAPERLESS_RESET
  };
}
exports.resetFondiPaperless = resetFondiPaperless;