"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiCartaDtoToJSON = exports.DatiCartaDtoFromJSONTyped = exports.DatiCartaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiCartaDtoFromJSON(json) {
  return DatiCartaDtoFromJSONTyped(json, false);
}
exports.DatiCartaDtoFromJSON = DatiCartaDtoFromJSON;
function DatiCartaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporto': !runtime_1.exists(json, 'rapporto') ? undefined : _1.RapportoCarteDtoFromJSON(json['rapporto']),
    'numeroCarta': !runtime_1.exists(json, 'numeroCarta') ? undefined : json['numeroCarta'],
    'tipoDisp': !runtime_1.exists(json, 'tipoDisp') ? undefined : json['tipoDisp'],
    'idDispReale': !runtime_1.exists(json, 'idDispReale') ? undefined : json['idDispReale'],
    'codiceProdotto': !runtime_1.exists(json, 'codiceProdotto') ? undefined : json['codiceProdotto'],
    'releaseProdotto': !runtime_1.exists(json, 'releaseProdotto') ? undefined : json['releaseProdotto'],
    'intestazioneCarta': !runtime_1.exists(json, 'intestazioneCarta') ? undefined : json['intestazioneCarta'],
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'dataRichiesta': !runtime_1.exists(json, 'dataRichiesta') ? undefined : new Date(json['dataRichiesta']),
    'descrizioneProdotto': !runtime_1.exists(json, 'descrizioneProdotto') ? undefined : json['descrizioneProdotto'],
    'statoCarta': !runtime_1.exists(json, 'statoCarta') ? undefined : json['statoCarta'],
    'descrizioneStato': !runtime_1.exists(json, 'descrizioneStato') ? undefined : json['descrizioneStato'],
    'circuitoCarta': !runtime_1.exists(json, 'circuitoCarta') ? undefined : json['circuitoCarta']
  };
}
exports.DatiCartaDtoFromJSONTyped = DatiCartaDtoFromJSONTyped;
function DatiCartaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporto': _1.RapportoCarteDtoToJSON(value.rapporto),
    'numeroCarta': value.numeroCarta,
    'tipoDisp': value.tipoDisp,
    'idDispReale': value.idDispReale,
    'codiceProdotto': value.codiceProdotto,
    'releaseProdotto': value.releaseProdotto,
    'intestazioneCarta': value.intestazioneCarta,
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'dataRichiesta': value.dataRichiesta === undefined ? undefined : value.dataRichiesta.toISOString(),
    'descrizioneProdotto': value.descrizioneProdotto,
    'statoCarta': value.statoCarta,
    'descrizioneStato': value.descrizioneStato,
    'circuitoCarta': value.circuitoCarta
  };
}
exports.DatiCartaDtoToJSON = DatiCartaDtoToJSON;