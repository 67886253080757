"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxCatalogoProdottiActionTypes = exports.Type = exports.Subtype = exports.MIMEType = void 0;
var MIMEType;
(function (MIMEType) {
  MIMEType["ApplicationPDF"] = "application/pdf";
  MIMEType["ImageJPEG"] = "image/jpeg";
  MIMEType["ImagePNG"] = "image/png";
})(MIMEType = exports.MIMEType || (exports.MIMEType = {}));
var Subtype;
(function (Subtype) {
  Subtype["JPEG"] = "jpeg";
  Subtype["PDF"] = "pdf";
  Subtype["PNG"] = "png";
})(Subtype = exports.Subtype || (exports.Subtype = {}));
var Type;
(function (Type) {
  Type["Application"] = "application";
  Type["Image"] = "image";
})(Type = exports.Type || (exports.Type = {}));
var EReduxCatalogoProdottiActionTypes;
(function (EReduxCatalogoProdottiActionTypes) {
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_START"] = "CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_START";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_SUCCESS"] = "CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_SUCCESS";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_ERROR"] = "CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_ERROR";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_SELECT_PRODOTTO"] = "CATALOGO_PRODOTTI_SELECT_PRODOTTO";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_START"] = "CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_START";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_SUCCESS"] = "CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_SUCCESS";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_ERROR"] = "CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_ERROR";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_CODICE_FUNZIONE"] = "CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_CODICE_FUNZIONE";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_START"] = "CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_START";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_SUCCESS"] = "CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_SUCCESS";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_ERROR"] = "CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_ERROR";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_ID"] = "CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_ID";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_START"] = "CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_START";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_SUCCESS"] = "CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_SUCCESS";
  EReduxCatalogoProdottiActionTypes["CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_ERROR"] = "CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_ERROR";
})(EReduxCatalogoProdottiActionTypes = exports.EReduxCatalogoProdottiActionTypes || (exports.EReduxCatalogoProdottiActionTypes = {}));