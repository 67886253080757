"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRevocaBollettinoDtoFromJSON = exports.FormBollettinoPostaleDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormBollettinoPostaleDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'idUtenza': !(0, helpers_1.exists)(json, 'idUtenza') ? undefined : json.idUtenza,
    'idUtenzaDirty': !(0, helpers_1.exists)(json, 'idUtenzaDirty') ? false : json.idUtenzaDirty,
    'idUtenzaError': !(0, helpers_1.exists)(json, 'idUtenzaError') ? 'invalid.required' : json.idUtenzaError,
    'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
    'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
    'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
    'esecutore': !(0, helpers_1.exists)(json, 'esecutore') ? undefined : json.esecutore,
    'esecutoreDirty': !(0, helpers_1.exists)(json, 'esecutoreDirty') ? false : json.esecutoreDirty,
    'esecutoreError': !(0, helpers_1.exists)(json, 'esecutoreError') ? 'invalid.required' : json.esecutoreError,
    'indirizzo': !(0, helpers_1.exists)(json, 'indirizzo') ? undefined : json.indirizzo,
    'indirizzoDirty': !(0, helpers_1.exists)(json, 'indirizzoDirty') ? false : json.indirizzoDirty,
    'indirizzoError': !(0, helpers_1.exists)(json, 'indirizzoError') ? 'invalid.required' : json.indirizzoError,
    'provincia': !(0, helpers_1.exists)(json, 'provincia') ? undefined : json.provincia,
    'provinciaDirty': !(0, helpers_1.exists)(json, 'provinciaDirty') ? false : json.provinciaDirty,
    'provinciaError': !(0, helpers_1.exists)(json, 'provinciaError') ? 'invalid.required' : json.provinciaError,
    'citta': !(0, helpers_1.exists)(json, 'citta') ? undefined : json.citta,
    'cittaDirty': !(0, helpers_1.exists)(json, 'cittaDirty') ? false : json.cittaDirty,
    'cittaError': !(0, helpers_1.exists)(json, 'cittaError') ? 'invalid.required' : json.cittaError,
    'cap': !(0, helpers_1.exists)(json, 'cap') ? undefined : json.cap,
    'capDirty': !(0, helpers_1.exists)(json, 'capDirty') ? false : json.capDirty,
    'capError': !(0, helpers_1.exists)(json, 'capError') ? 'invalid.required' : json.capError,
    'numeroBollettino': !(0, helpers_1.exists)(json, 'numeroBollettino') ? undefined : json.numeroBollettino,
    'numeroBollettinoDirty': !(0, helpers_1.exists)(json, 'numeroBollettinoDirty') ? false : json.numeroBollettinoDirty,
    'numeroBollettinoError': !(0, helpers_1.exists)(json, 'numeroBollettinoError') ? 'invalid.required' : json.numeroBollettinoError,
    'tipoDocumento': !(0, helpers_1.exists)(json, 'tipoDocumento') ? undefined : json.tipoDocumento,
    'tipoDocumentoDirty': !(0, helpers_1.exists)(json, 'tipoDocumentoDirty') ? false : json.tipoDocumentoDirty,
    'tipoDocumentoError': !(0, helpers_1.exists)(json, 'tipoDocumentoError') ? 'invalid.required' : json.tipoDocumentoError,
    'dataPagamento': !(0, helpers_1.exists)(json, 'dataPagamento') ? new Date() : json.dataPagamento,
    'dataPagamentoDirty': !(0, helpers_1.exists)(json, 'dataPagamentoDirty') ? false : json.dataPagamentoDirty,
    'dataPagamentoError': !(0, helpers_1.exists)(json, 'dataPagamentoError') ? '' : json.dataPagamentoError,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti,
    'causale': !(0, helpers_1.exists)(json, 'causale') ? undefined : json.causale,
    'causaleDirty': !(0, helpers_1.exists)(json, 'causaleDirty') ? false : json.causaleDirty,
    'causaleError': !(0, helpers_1.exists)(json, 'causaleError') ? 'invalid.required' : json.causaleError,
    'intestatario': !(0, helpers_1.exists)(json, 'intestatario') ? undefined : json.intestatario,
    'intestatarioDirty': !(0, helpers_1.exists)(json, 'intestatarioDirty') ? false : json.intestatarioDirty,
    'intestatarioError': !(0, helpers_1.exists)(json, 'intestatarioError') ? 'invalid.required' : json.intestatarioError,
    'salva1Click': !(0, helpers_1.exists)(json, 'salva1Click') ? undefined : json.salva1Click,
    'alias1Click': !(0, helpers_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
    'aliasDirty': !(0, helpers_1.exists)(json, 'aliasDirty') ? false : json.aliasDirty,
    'aliasError': !(0, helpers_1.exists)(json, 'aliasError') ? '' : json.aliasError,
    'idOneClickCollegata': !(0, helpers_1.exists)(json, 'idOneClickCollegata') ? undefined : json.idOneClickCollegata
  };
}
exports.FormBollettinoPostaleDtoFromJSONTyped = FormBollettinoPostaleDtoFromJSONTyped;
function FormRevocaBollettinoDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaBollettinoDtoFromJSON = FormRevocaBollettinoDtoFromJSON;