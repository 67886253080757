"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EsitiOperazioneDtoToJSON = exports.EsitiOperazioneDtoFromJSONTyped = exports.EsitiOperazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function EsitiOperazioneDtoFromJSON(json) {
  return EsitiOperazioneDtoFromJSONTyped(json, false);
}
exports.EsitiOperazioneDtoFromJSON = EsitiOperazioneDtoFromJSON;
function EsitiOperazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero'],
    'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
    'quantita': !runtime_1.exists(json, 'quantita') ? undefined : json['quantita'],
    'prezzo': !runtime_1.exists(json, 'prezzo') ? undefined : json['prezzo'],
    'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
    'spese': !runtime_1.exists(json, 'spese') ? undefined : json['spese'],
    'commissioni': !runtime_1.exists(json, 'commissioni') ? undefined : json['commissioni'],
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'dataOraOrdine': !runtime_1.exists(json, 'dataOraOrdine') ? undefined : new Date(json['dataOraOrdine']),
    'cambio': !runtime_1.exists(json, 'cambio') ? undefined : json['cambio'],
    'controvaloreDivisa': !runtime_1.exists(json, 'controvaloreDivisa') ? undefined : json['controvaloreDivisa'],
    'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : new Date(json['dataValuta']),
    'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : new Date(json['dataContabile']),
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
    'esecuzione': !runtime_1.exists(json, 'esecuzione') ? undefined : json['esecuzione'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'dossier': !runtime_1.exists(json, 'dossier') ? undefined : json['dossier'],
    'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
    'descrizioneTitolo': !runtime_1.exists(json, 'descrizioneTitolo') ? undefined : json['descrizioneTitolo'],
    'rapporto': !runtime_1.exists(json, 'rapporto') ? undefined : json['rapporto'],
    'dataOraDate': !runtime_1.exists(json, 'dataOraDate') ? undefined : new Date(json['dataOraDate'])
  };
}
exports.EsitiOperazioneDtoFromJSONTyped = EsitiOperazioneDtoFromJSONTyped;
function EsitiOperazioneDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'numero': value.numero,
    'mercato': value.mercato,
    'quantita': value.quantita,
    'prezzo': value.prezzo,
    'controvalore': value.controvalore,
    'spese': value.spese,
    'commissioni': value.commissioni,
    'causale': value.causale,
    'divisa': value.divisa,
    'dataOraOrdine': value.dataOraOrdine === undefined ? undefined : value.dataOraOrdine.toISOString(),
    'cambio': value.cambio,
    'controvaloreDivisa': value.controvaloreDivisa,
    'dataValuta': value.dataValuta === undefined ? undefined : value.dataValuta.toISOString(),
    'dataContabile': value.dataContabile === undefined ? undefined : value.dataContabile.toISOString(),
    'stato': value.stato,
    'codiceIsin': value.codiceIsin,
    'esecuzione': value.esecuzione,
    'titolo': value.titolo,
    'dossier': value.dossier,
    'tipologia': value.tipologia,
    'descrizioneTitolo': value.descrizioneTitolo,
    'rapporto': value.rapporto,
    'dataOraDate': value.dataOraDate === undefined ? undefined : value.dataOraDate.toISOString()
  };
}
exports.EsitiOperazioneDtoToJSON = EsitiOperazioneDtoToJSON;