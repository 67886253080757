"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrdiniInScadenzaFiltriInDtoToJSON = exports.OrdiniInScadenzaFiltriInDtoFromJSONTyped = exports.OrdiniInScadenzaFiltriInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OrdiniInScadenzaFiltriInDtoFromJSON(json) {
  return OrdiniInScadenzaFiltriInDtoFromJSONTyped(json, false);
}
exports.OrdiniInScadenzaFiltriInDtoFromJSON = OrdiniInScadenzaFiltriInDtoFromJSON;
function OrdiniInScadenzaFiltriInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': json['codiceRapporto'],
    'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : new Date(json['dataIniziale']),
    'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : new Date(json['dataFinale']),
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset']
  };
}
exports.OrdiniInScadenzaFiltriInDtoFromJSONTyped = OrdiniInScadenzaFiltriInDtoFromJSONTyped;
function OrdiniInScadenzaFiltriInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'dataIniziale': value.dataIniziale === undefined ? undefined : value.dataIniziale.toISOString(),
    'dataFinale': value.dataFinale === undefined ? undefined : value.dataFinale.toISOString(),
    'stato': value.stato,
    'offset': value.offset
  };
}
exports.OrdiniInScadenzaFiltriInDtoToJSON = OrdiniInScadenzaFiltriInDtoToJSON;