"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BannerWcmDtoToJSON = exports.BannerWcmDtoFromJSONTyped = exports.BannerWcmDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BannerWcmDtoFromJSON(json) {
  return BannerWcmDtoFromJSONTyped(json, false);
}
exports.BannerWcmDtoFromJSON = BannerWcmDtoFromJSON;
function BannerWcmDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'urlLink': !runtime_1.exists(json, 'urlLink') ? undefined : json['urlLink'],
    'immagine': !runtime_1.exists(json, 'immagine') ? undefined : json['immagine'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'testo': !runtime_1.exists(json, 'testo') ? undefined : json['testo'],
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    'nonTrovato': !runtime_1.exists(json, 'nonTrovato') ? undefined : json['nonTrovato'],
    'codiceServizio': !runtime_1.exists(json, 'codiceServizio') ? undefined : json['codiceServizio'],
    'allegatoId': !runtime_1.exists(json, 'allegatoId') ? undefined : json['allegatoId'],
    'messaggioId': !runtime_1.exists(json, 'messaggioId') ? undefined : json['messaggioId'],
    'visualizzaTitolo': !runtime_1.exists(json, 'visualizzaTitolo') ? undefined : json['visualizzaTitolo'],
    'idTemplate': !runtime_1.exists(json, 'idTemplate') ? undefined : json['idTemplate'],
    'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua']
  };
}
exports.BannerWcmDtoFromJSONTyped = BannerWcmDtoFromJSONTyped;
function BannerWcmDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'urlLink': value.urlLink,
    'immagine': value.immagine,
    'titolo': value.titolo,
    'testo': value.testo,
    'tipo': value.tipo,
    'nonTrovato': value.nonTrovato,
    'codiceServizio': value.codiceServizio,
    'allegatoId': value.allegatoId,
    'messaggioId': value.messaggioId,
    'visualizzaTitolo': value.visualizzaTitolo,
    'idTemplate': value.idTemplate,
    'lingua': value.lingua
  };
}
exports.BannerWcmDtoToJSON = BannerWcmDtoToJSON;