"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabellaCostiDtoToJSON = exports.TabellaCostiDtoFromJSONTyped = exports.TabellaCostiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TabellaCostiDtoFromJSON(json) {
  return TabellaCostiDtoFromJSONTyped(json, false);
}
exports.TabellaCostiDtoFromJSON = TabellaCostiDtoFromJSON;
function TabellaCostiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'costi': !runtime_1.exists(json, 'costi') ? undefined : json['costi'],
    'testataCosti': !runtime_1.exists(json, 'testataCosti') ? undefined : json['testataCosti']
  };
}
exports.TabellaCostiDtoFromJSONTyped = TabellaCostiDtoFromJSONTyped;
function TabellaCostiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'costi': value.costi,
    'testataCosti': value.testataCosti
  };
}
exports.TabellaCostiDtoToJSON = TabellaCostiDtoToJSON;