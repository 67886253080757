"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IndirizzoDatiAnagraficiDtoToJSON = exports.IndirizzoDatiAnagraficiDtoFromJSONTyped = exports.IndirizzoDatiAnagraficiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function IndirizzoDatiAnagraficiDtoFromJSON(json) {
  return IndirizzoDatiAnagraficiDtoFromJSONTyped(json, false);
}
exports.IndirizzoDatiAnagraficiDtoFromJSON = IndirizzoDatiAnagraficiDtoFromJSON;
function IndirizzoDatiAnagraficiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'via': !runtime_1.exists(json, 'via') ? undefined : _1.ViaDatiAnagraficiDtoFromJSON(json['via']),
    'localita': !runtime_1.exists(json, 'localita') ? undefined : json['localita'],
    'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
    'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
    'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
    'residenza': !runtime_1.exists(json, 'residenza') ? undefined : json['residenza'],
    'codiceResidenza': !runtime_1.exists(json, 'codiceResidenza') ? undefined : json['codiceResidenza']
  };
}
exports.IndirizzoDatiAnagraficiDtoFromJSONTyped = IndirizzoDatiAnagraficiDtoFromJSONTyped;
function IndirizzoDatiAnagraficiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'via': _1.ViaDatiAnagraficiDtoToJSON(value.via),
    'localita': value.localita,
    'cap': value.cap,
    'comune': value.comune,
    'provincia': value.provincia,
    'residenza': value.residenza,
    'codiceResidenza': value.codiceResidenza
  };
}
exports.IndirizzoDatiAnagraficiDtoToJSON = IndirizzoDatiAnagraficiDtoToJSON;