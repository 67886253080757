"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bollettinoFrecciaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const bollettino_freccia_types_1 = require("./bollettino.freccia.types");
const initialState = {
  parametri: (0, api_rest_1.ParametriFrecciaDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.FrecciaDtoFromJSON)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON)({}),
  infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  form: (0, bollettino_freccia_types_1.FormBollettinoFrecciaFromJSONTyped)({}),
  riepilogoRevoca: (0, api_rest_1.FrecciaDtoFromJSON)({}),
  formRevoca: (0, bollettino_freccia_types_1.FormRevocaBollettinoFrecciaFromJSON)({}),
  infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON)({}),
  bozzeBollettinoFreccia: (0, api_rest_1.FrecciasDtoFromJSON)({}),
  currentBozza: (0, api_rest_1.FrecciaDtoFromJSON)({})
};
const bollettinoFrecciaReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
  switch (action.type) {
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_PARAMETRI_SUCCESS:
      return {
        ...initialState,
        parametri: action.payload,
        form: {
          ...initialState.form,
          ripeti: false
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_PARAMETRI_PER_RIPETI_SUCCESS:
      return {
        ...initialState,
        parametri: action.payload,
        form: {
          ...state.form,
          ripeti: true
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CODICE_SIA:
      return {
        ...state,
        form: {
          ...state.form,
          codiceSia: action.payload,
          codiceSiaDirty: true,
          codiceSiaError: (0, helpers_1.validationField)(action.payload, 0),
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_RIFERIMENTO_CREDITORE:
      return {
        ...state,
        form: {
          ...state.form,
          riferimentoCreditore: action.payload,
          riferimentoCreditoreDirty: true,
          riferimentoCreditoreError: (0, helpers_1.validationField)(action.payload, 0),
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_IBAN_CREDITORE:
      {
        return {
          ...state,
          form: {
            ...state.form,
            ibanCreditore: action.payload,
            ibanCreditoreDirty: true,
            ibanCreditoreError: (0, helpers_1.validationIbanItaliani)(action.payload, true),
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_IMPORTO:
      {
        return {
          ...state,
          form: {
            ...state.form,
            importo: action.payload,
            importoDirty: true,
            importoError: (0, helpers_1.validationField)(action.payload, 0),
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_DATA_SCADENZA:
      {
        return {
          ...state,
          form: {
            ...state.form,
            dataScadenza: action.payload,
            dataScadenzaDirty: true,
            dataScadenzaError: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICOSEPA.FESTIVI'] || [] : [], false, moment().format('YYYY-MM-DD')),
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_NOTE:
      {
        return {
          ...state,
          form: {
            ...state.form,
            note: action.payload,
            noteDirty: true,
            noteError: '',
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CIN_IMPORTO:
      {
        return {
          ...state,
          form: {
            ...state.form,
            cinImporto: (_a = action.payload) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
            cinImportoDirty: true,
            cinImportoError: (0, helpers_1.validationField)(action.payload, ''),
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CIN_INTERMEDIO:
      {
        return {
          ...state,
          form: {
            ...state.form,
            cinIntermedio: (_b = action.payload) === null || _b === void 0 ? void 0 : _b.toUpperCase(),
            cinIntermedioDirty: true,
            cinIntermedioError: (0, helpers_1.validationField)(action.payload, ''),
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CIN_COMPLESSO:
      {
        return {
          ...state,
          form: {
            ...state.form,
            cinComplesso: (_c = action.payload) === null || _c === void 0 ? void 0 : _c.toUpperCase(),
            cinComplessoDirty: true,
            cinComplessoError: (0, helpers_1.validationField)(action.payload, ''),
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CARATTERE_ESENZIONE:
      {
        return {
          ...state,
          form: {
            ...state.form,
            carattereEsenzione: action.payload,
            carattereEsenzioneDirty: true,
            carattereEsenzioneError: (0, helpers_1.validationField)(action.payload, 0),
            bozzaSalvata: false
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_FORM_SUBMITTED:
      {
        return {
          ...state,
          form: {
            ...state.form,
            formSubmitted: action.payload
          }
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_CHECK_FRECCIA_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_SUCCESS:
      return {
        ...state,
        infoDispositiva: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_AUTORIZZA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: action.payload
        };
      } else {
        return {
          ...state,
          infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esito: action.payload
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_RIEPILOGO_REVOCA:
      return {
        ...state,
        formRevoca: initialState.formRevoca,
        riepilogoRevoca: action.payload
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_PROVENIENZA:
      return {
        ...state,
        formRevoca: {
          ...state.formRevoca,
          provenienza: action.payload
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_STEP_REVOCA:
      return {
        ...state,
        formRevoca: {
          ...state.formRevoca,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_INFO_REVOCA_SUCCESS:
      return {
        ...state,
        infoRevoca: action.payload,
        esitoRevoca: initialState.esitoRevoca
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_BOZZE_SUCCESS:
      return {
        ...state,
        bozzeBollettinoFreccia: action.payload
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esitoRevoca: action.payload
        };
      } else {
        return {
          ...state,
          parametri: initialState.parametri,
          riepilogo: initialState.riepilogo,
          infoDispositiva: initialState.infoDispositiva,
          esito: initialState.esito,
          form: initialState.form,
          riepilogoRevoca: initialState.riepilogoRevoca,
          infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esitoRevoca: action.payload
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_FROM_BOZZA:
      {
        const rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
        const data = action.payload.dataScadenza && moment(action.payload.dataScadenza) >= moment() ? moment(action.payload.dataScadenza).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        return {
          ...state,
          form: {
            ...state.form,
            codiceSia: ((_e = (_d = action.payload.datiBollettino) === null || _d === void 0 ? void 0 : _d.codiceIdentificativo) === null || _e === void 0 ? void 0 : _e.codiceSia) || '',
            codiceSiaDirty: false,
            codiceSiaError: (0, helpers_1.validationField)((_g = (_f = action.payload.datiBollettino) === null || _f === void 0 ? void 0 : _f.codiceIdentificativo) === null || _g === void 0 ? void 0 : _g.codiceSia, 0),
            riferimentoCreditore: ((_j = (_h = action.payload.datiBollettino) === null || _h === void 0 ? void 0 : _h.codiceIdentificativo) === null || _j === void 0 ? void 0 : _j.riferimentoCreditore) || '',
            riferimentoCreditoreDirty: false,
            riferimentoCreditoreError: (0, helpers_1.validationField)((_l = (_k = action.payload.datiBollettino) === null || _k === void 0 ? void 0 : _k.codiceIdentificativo) === null || _l === void 0 ? void 0 : _l.riferimentoCreditore, 0),
            ibanCreditore: ((_m = action.payload.datiBollettino) === null || _m === void 0 ? void 0 : _m.iban) || '',
            ibanCreditoreDirty: false,
            ibanCreditoreError: (0, helpers_1.validationField)((_o = action.payload.datiBollettino) === null || _o === void 0 ? void 0 : _o.iban, '', true),
            importo: action.payload.importo,
            importoDirty: false,
            importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
            note: action.payload.note || '',
            noteDirty: false,
            noteError: (0, helpers_1.validationCompleteCharSet)((_p = action.payload.note) !== null && _p !== void 0 ? _p : '', false),
            cinImporto: ((_q = action.payload.datiBollettino) === null || _q === void 0 ? void 0 : _q.cinImporto) || '',
            cinImportoDirty: false,
            cinImportoError: (0, helpers_1.validationField)((_r = action.payload.datiBollettino) === null || _r === void 0 ? void 0 : _r.cinImporto, 0),
            cinIntermedio: ((_s = action.payload.datiBollettino) === null || _s === void 0 ? void 0 : _s.cinIntermedio) || '',
            cinIntermedioDirty: false,
            cinIntermedioError: (0, helpers_1.validationField)((_t = action.payload.datiBollettino) === null || _t === void 0 ? void 0 : _t.cinIntermedio, 0),
            cinComplesso: ((_u = action.payload.datiBollettino) === null || _u === void 0 ? void 0 : _u.cinComplessivo) || '',
            cinComplessoDirty: false,
            cinComplessoError: (0, helpers_1.validationField)((_v = action.payload.datiBollettino) === null || _v === void 0 ? void 0 : _v.cinComplessivo, 0),
            carattereEsenzione: ((_w = action.payload.datiBollettino) === null || _w === void 0 ? void 0 : _w.esenzione) || '',
            carattereEsenzioneDirty: false,
            carattereEsenzioneError: (0, helpers_1.validationField)((_x = action.payload.datiBollettino) === null || _x === void 0 ? void 0 : _x.esenzione, 0),
            rapportoSelezionato: rapportoSelezionato,
            idOneClickCollegata: action.payload.idOneClickCollegata,
            dataScadenza: data
          },
          currentBozza: action.noBozza ? initialState.currentBozza : action.payload
        };
      }
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_ADD_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeBollettinoFreccia: {
          ...state.bozzeBollettinoFreccia,
          freccias: state.bozzeBollettinoFreccia && state.bozzeBollettinoFreccia.freccias ? [...state.bozzeBollettinoFreccia.freccias, action.payload] : []
        },
        form: {
          ...state.form,
          bozzaSalvata: true
        },
        currentBozza: action.payload
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_DEL_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeBollettinoFreccia: {
          ...state.bozzeBollettinoFreccia,
          freccias: state.bozzeBollettinoFreccia && state.bozzeBollettinoFreccia.freccias ? state.bozzeBollettinoFreccia.freccias.filter(bollettinoFreccia => bollettinoFreccia.id !== action.payload) : []
        },
        form: {
          ...state.form,
          bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
        },
        currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_UPDATE_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeBollettinoFreccia: {
          ...state.bozzeBollettinoFreccia,
          freccias: state.bozzeBollettinoFreccia && state.bozzeBollettinoFreccia.freccias ? state.bozzeBollettinoFreccia.freccias.map(bollettinoFreccia => bollettinoFreccia.id === state.currentBozza.id ? action.payload : bollettinoFreccia) : []
        },
        form: {
          ...state.form,
          bozzaSalvata: true
        }
      };
    case __1.EReduxActionTypes.BOLLETTINO_FRECCIA_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.bollettinoFrecciaReducer = bollettinoFrecciaReducer;