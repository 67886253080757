"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StrongAuthVerifyRequestDtoToJSON = exports.StrongAuthVerifyRequestDtoFromJSONTyped = exports.StrongAuthVerifyRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StrongAuthVerifyRequestDtoFromJSON(json) {
  return StrongAuthVerifyRequestDtoFromJSONTyped(json, false);
}
exports.StrongAuthVerifyRequestDtoFromJSON = StrongAuthVerifyRequestDtoFromJSON;
function StrongAuthVerifyRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'nextTokencode': !runtime_1.exists(json, 'nextTokencode') ? undefined : json['nextTokencode'],
    'password': !runtime_1.exists(json, 'password') ? undefined : json['password'],
    'strongAuthType': !runtime_1.exists(json, 'strongAuthType') ? undefined : _1.StrongAuthTypeEnumFromJSON(json['strongAuthType']),
    'tokenNewPin': !runtime_1.exists(json, 'tokenNewPin') ? undefined : json['tokenNewPin'],
    'tokenPin': !runtime_1.exists(json, 'tokenPin') ? undefined : json['tokenPin'],
    'tokenSignature': !runtime_1.exists(json, 'tokenSignature') ? undefined : json['tokenSignature'],
    'tokencode': !runtime_1.exists(json, 'tokencode') ? undefined : json['tokencode']
  };
}
exports.StrongAuthVerifyRequestDtoFromJSONTyped = StrongAuthVerifyRequestDtoFromJSONTyped;
function StrongAuthVerifyRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'nextTokencode': value.nextTokencode,
    'password': value.password,
    'strongAuthType': _1.StrongAuthTypeEnumToJSON(value.strongAuthType),
    'tokenNewPin': value.tokenNewPin,
    'tokenPin': value.tokenPin,
    'tokenSignature': value.tokenSignature,
    'tokencode': value.tokencode
  };
}
exports.StrongAuthVerifyRequestDtoToJSON = StrongAuthVerifyRequestDtoToJSON;