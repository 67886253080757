"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaldoDtoToJSON = exports.SaldoDtoFromJSONTyped = exports.SaldoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SaldoDtoFromJSON(json) {
  return SaldoDtoFromJSONTyped(json, false);
}
exports.SaldoDtoFromJSON = SaldoDtoFromJSON;
function SaldoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'divisa': json['divisa'],
    'saldoContabile': json['saldoContabile'],
    'saldoDisponibile': json['saldoDisponibile'],
    'operazioniNonContabilizzate': !runtime_1.exists(json, 'operazioniNonContabilizzate') ? undefined : json['operazioniNonContabilizzate'],
    'fido': !runtime_1.exists(json, 'fido') ? undefined : json['fido'],
    'mostraAvviso': !runtime_1.exists(json, 'mostraAvviso') ? undefined : json['mostraAvviso'],
    'cambio': !runtime_1.exists(json, 'cambio') ? undefined : json['cambio'],
    'alertPsd2': !runtime_1.exists(json, 'alertPsd2') ? undefined : json['alertPsd2'].map(_1.AlertPsd2DtoFromJSON)
  };
}
exports.SaldoDtoFromJSONTyped = SaldoDtoFromJSONTyped;
function SaldoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'divisa': value.divisa,
    'saldoContabile': value.saldoContabile,
    'saldoDisponibile': value.saldoDisponibile,
    'operazioniNonContabilizzate': value.operazioniNonContabilizzate,
    'fido': value.fido,
    'mostraAvviso': value.mostraAvviso,
    'cambio': value.cambio,
    'alertPsd2': value.alertPsd2 === undefined ? undefined : value.alertPsd2.map(_1.AlertPsd2DtoToJSON)
  };
}
exports.SaldoDtoToJSON = SaldoDtoToJSON;