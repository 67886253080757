"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServiceProductResponseToJSON = exports.ServiceProductResponseFromJSONTyped = exports.ServiceProductResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ServiceProductResponseFromJSON(json) {
  return ServiceProductResponseFromJSONTyped(json, false);
}
exports.ServiceProductResponseFromJSON = ServiceProductResponseFromJSON;
function ServiceProductResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
    'hideCustomer': !runtime_1.exists(json, 'hideCustomer') ? undefined : json['hideCustomer'],
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'keyLabel': !runtime_1.exists(json, 'keyLabel') ? undefined : json['keyLabel'],
    'label': !runtime_1.exists(json, 'label') ? undefined : json['label']
  };
}
exports.ServiceProductResponseFromJSONTyped = ServiceProductResponseFromJSONTyped;
function ServiceProductResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
    'hideCustomer': value.hideCustomer,
    'id': value.id,
    'keyLabel': value.keyLabel,
    'label': value.label
  };
}
exports.ServiceProductResponseToJSON = ServiceProductResponseToJSON;