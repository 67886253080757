"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductGovernanceDtoToJSON = exports.ProductGovernanceDtoFromJSONTyped = exports.ProductGovernanceDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProductGovernanceDtoFromJSON(json) {
  return ProductGovernanceDtoFromJSONTyped(json, false);
}
exports.ProductGovernanceDtoFromJSON = ProductGovernanceDtoFromJSON;
function ProductGovernanceDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'obbligatori': !runtime_1.exists(json, 'obbligatori') ? undefined : json['obbligatori'],
    'obbligatoriFacoltativi': !runtime_1.exists(json, 'obbligatoriFacoltativi') ? undefined : json['obbligatoriFacoltativi'],
    'tutti': !runtime_1.exists(json, 'tutti') ? undefined : json['tutti'],
    'esito': !runtime_1.exists(json, 'esito') ? undefined : json['esito'],
    'controlli': !runtime_1.exists(json, 'controlli') ? undefined : json['controlli'],
    'derogata': !runtime_1.exists(json, 'derogata') ? undefined : json['derogata']
  };
}
exports.ProductGovernanceDtoFromJSONTyped = ProductGovernanceDtoFromJSONTyped;
function ProductGovernanceDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'obbligatori': value.obbligatori,
    'obbligatoriFacoltativi': value.obbligatoriFacoltativi,
    'tutti': value.tutti,
    'esito': value.esito,
    'controlli': value.controlli,
    'derogata': value.derogata
  };
}
exports.ProductGovernanceDtoToJSON = ProductGovernanceDtoToJSON;