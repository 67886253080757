"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContattoIndirizzoDtoToJSON = exports.ContattoIndirizzoDtoFromJSONTyped = exports.ContattoIndirizzoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContattoIndirizzoDtoFromJSON(json) {
  return ContattoIndirizzoDtoFromJSONTyped(json, false);
}
exports.ContattoIndirizzoDtoFromJSON = ContattoIndirizzoDtoFromJSON;
function ContattoIndirizzoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
    'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
    'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
    'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
    'paese': !runtime_1.exists(json, 'paese') ? undefined : json['paese']
  };
}
exports.ContattoIndirizzoDtoFromJSONTyped = ContattoIndirizzoDtoFromJSONTyped;
function ContattoIndirizzoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'indirizzo': value.indirizzo,
    'comune': value.comune,
    'cap': value.cap,
    'provincia': value.provincia,
    'paese': value.paese
  };
}
exports.ContattoIndirizzoDtoToJSON = ContattoIndirizzoDtoToJSON;