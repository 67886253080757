"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventResponseToJSON = exports.EventResponseFromJSONTyped = exports.EventResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EventResponseFromJSON(json) {
  return EventResponseFromJSONTyped(json, false);
}
exports.EventResponseFromJSON = EventResponseFromJSON;
function EventResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'counselorDisplayName': !runtime_1.exists(json, 'counselorDisplayName') ? undefined : json['counselorDisplayName'],
    'counselorEmail': !runtime_1.exists(json, 'counselorEmail') ? undefined : json['counselorEmail'],
    'counselorId': !runtime_1.exists(json, 'counselorId') ? undefined : json['counselorId'],
    'counselorNoteToCustomer': !runtime_1.exists(json, 'counselorNoteToCustomer') ? undefined : json['counselorNoteToCustomer'],
    'counselorType': !runtime_1.exists(json, 'counselorType') ? undefined : _1.CounselorTypeFromJSON(json['counselorType']),
    'customerEmail': !runtime_1.exists(json, 'customerEmail') ? undefined : json['customerEmail'],
    'customerName': !runtime_1.exists(json, 'customerName') ? undefined : json['customerName'],
    'customerNote': !runtime_1.exists(json, 'customerNote') ? undefined : json['customerNote'],
    'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
    'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
    'eventCreatorType': !runtime_1.exists(json, 'eventCreatorType') ? undefined : json['eventCreatorType'],
    'extraServiceProduct': !runtime_1.exists(json, 'extraServiceProduct') ? undefined : json['extraServiceProduct'].map(_1.ExtraServiceProductFromJSON),
    'idEvent': !runtime_1.exists(json, 'idEvent') ? undefined : json['idEvent'],
    'idServiceProduct': !runtime_1.exists(json, 'idServiceProduct') ? undefined : json['idServiceProduct'],
    'internalEventId': !runtime_1.exists(json, 'internalEventId') ? undefined : json['internalEventId'],
    'kind': !runtime_1.exists(json, 'kind') ? undefined : json['kind'],
    'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
    'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
    'organizerId': !runtime_1.exists(json, 'organizerId') ? undefined : json['organizerId'],
    'organizerName': !runtime_1.exists(json, 'organizerName') ? undefined : json['organizerName'],
    'serviceProduct': !runtime_1.exists(json, 'serviceProduct') ? undefined : json['serviceProduct'],
    'startDate': !runtime_1.exists(json, 'startDate') ? undefined : json['startDate'],
    'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
    'webLink': !runtime_1.exists(json, 'webLink') ? undefined : json['webLink']
  };
}
exports.EventResponseFromJSONTyped = EventResponseFromJSONTyped;
function EventResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'counselorDisplayName': value.counselorDisplayName,
    'counselorEmail': value.counselorEmail,
    'counselorId': value.counselorId,
    'counselorNoteToCustomer': value.counselorNoteToCustomer,
    'counselorType': _1.CounselorTypeToJSON(value.counselorType),
    'customerEmail': value.customerEmail,
    'customerName': value.customerName,
    'customerNote': value.customerNote,
    'description': value.description,
    'endDate': value.endDate,
    'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
    'eventCreatorType': value.eventCreatorType,
    'extraServiceProduct': value.extraServiceProduct === undefined ? undefined : value.extraServiceProduct.map(_1.ExtraServiceProductToJSON),
    'idEvent': value.idEvent,
    'idServiceProduct': value.idServiceProduct,
    'internalEventId': value.internalEventId,
    'kind': value.kind,
    'ndg': value.ndg,
    'note': value.note,
    'organizerId': value.organizerId,
    'organizerName': value.organizerName,
    'serviceProduct': value.serviceProduct,
    'startDate': value.startDate,
    'status': value.status,
    'webLink': value.webLink
  };
}
exports.EventResponseToJSON = EventResponseToJSON;