"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CBillControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CBillControllerApi extends runtime.BaseAPI {
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationCBillRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationCBill.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/cbill/infoautenticazione/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  authenticationInformationCBill(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authenticationInformationCBillRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di cbill
   */
  authorizeCBillRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeCBill.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeCBill.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/cbill/autorizza/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneCBillDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di cbill
   */
  authorizeCBill(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.authorizeCBillRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Esegue il controllo di validità di un cbill in base ai valori specificati nell\'oggetto di richiesta
   */
  controlloCBillRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.fatturaDto === null || requestParameters.fatturaDto === undefined) {
        throw new runtime.RequiredError('fatturaDto', 'Required parameter requestParameters.fatturaDto was null or undefined when calling controlloCBill.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/cbill/controllo",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FatturaDtoToJSON(requestParameters.fatturaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.FatturaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Esegue il controllo di validità di un cbill in base ai valori specificati nell\'oggetto di richiesta
   */
  controlloCBill(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloCBillRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Salva una bozza di cBill
   */
  createDraftRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.fatturaDto === null || requestParameters.fatturaDto === undefined) {
        throw new runtime.RequiredError('fatturaDto', 'Required parameter requestParameters.fatturaDto was null or undefined when calling createDraft.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/cbill/salvabozza",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FatturaDtoToJSON(requestParameters.fatturaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.FatturaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Salva una bozza di cBill
   */
  createDraft(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.createDraftRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Eliminazione di una bozza di cBill dato l\'id
   */
  deleteDraftRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteDraft.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/cbill/bozze/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Eliminazione di una bozza di cBill dato l\'id
   */
  deleteDraft(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.deleteDraftRaw(requestParameters);
    });
  }
  /**
   * Dato un conto addebito restituisce i codici fiscali associati
   */
  getCodiciFiscaliByContoAddebitoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
        throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling getCodiciFiscaliByContoAddebito.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/cbill/codiceFiscale/{codiceRapporto}".replace("{".concat("codiceRapporto", "}"), encodeURIComponent(String(requestParameters.codiceRapporto))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response);
    });
  }
  /**
   * Dato un conto addebito restituisce i codici fiscali associati
   */
  getCodiciFiscaliByContoAddebito(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getCodiciFiscaliByContoAddebitoRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce una bozza dato l\'id per cBill
   */
  getDraftByIdRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDraftById.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/cbill/bozze/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.FatturaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce una bozza dato l\'id per cBill
   */
  getDraftById(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getDraftByIdRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le bozze per cBill
   */
  getDraftListRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
        throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getDraftList.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/cbill/bozze",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.FatturaDtoFromJSON));
    });
  }
  /**
   * Restituisce le bozze per cBill
   */
  getDraftList(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getDraftListRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri cbill
   */
  parametersCbillRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/cbill/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri cbill
   */
  parametersCbill() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametersCbillRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce i biller sulla base dei filtri di ricerca
   */
  searchBillersRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filterBillerDto === null || requestParameters.filterBillerDto === undefined) {
        throw new runtime.RequiredError('filterBillerDto', 'Required parameter requestParameters.filterBillerDto was null or undefined when calling searchBillers.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/cbill/billers",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FilterBillerDtoToJSON(requestParameters.filterBillerDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.BillerDtoFromJSON));
    });
  }
  /**
   * Restituisce i biller sulla base dei filtri di ricerca
   */
  searchBillers(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.searchBillersRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Modifica di una bozza di cBill dato l\'id
   */
  updateDraftRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateDraft.');
      }
      if (requestParameters.fatturaDto === null || requestParameters.fatturaDto === undefined) {
        throw new runtime.RequiredError('fatturaDto', 'Required parameter requestParameters.fatturaDto was null or undefined when calling updateDraft.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/cbill/bozze/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FatturaDtoToJSON(requestParameters.fatturaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.FatturaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Modifica di una bozza di cBill dato l\'id
   */
  updateDraft(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.updateDraftRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.CBillControllerApi = CBillControllerApi;