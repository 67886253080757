"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrecciasDtoToJSON = exports.FrecciasDtoFromJSONTyped = exports.FrecciasDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FrecciasDtoFromJSON(json) {
  return FrecciasDtoFromJSONTyped(json, false);
}
exports.FrecciasDtoFromJSON = FrecciasDtoFromJSON;
function FrecciasDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'freccias': !runtime_1.exists(json, 'freccias') ? undefined : json['freccias'].map(_1.FrecciaDtoFromJSON)
  };
}
exports.FrecciasDtoFromJSONTyped = FrecciasDtoFromJSONTyped;
function FrecciasDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'freccias': value.freccias === undefined ? undefined : value.freccias.map(_1.FrecciaDtoToJSON)
  };
}
exports.FrecciasDtoToJSON = FrecciasDtoToJSON;