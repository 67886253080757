"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InviatoDtoToJSON = exports.InviatoDtoFromJSONTyped = exports.InviatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function InviatoDtoFromJSON(json) {
  return InviatoDtoFromJSONTyped(json, false);
}
exports.InviatoDtoFromJSON = InviatoDtoFromJSON;
function InviatoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'dataDisposizione': new Date(json['dataDisposizione'])
  };
}
exports.InviatoDtoFromJSONTyped = InviatoDtoFromJSONTyped;
function InviatoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'importo': value.importo,
    'intestazione': value.intestazione,
    'dataDisposizione': value.dataDisposizione.toISOString()
  };
}
exports.InviatoDtoToJSON = InviatoDtoToJSON;