import { getParametriWidget, parametriWidget, salvaWidgets, WidgetDto, widgetSaldo, widgetsUtente } from "@sparkasse/commons";
import BankIcon, { ICON_ALIAS } from "component/Icon/icon.component";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import GridLayout from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import responsiveImage from "../../assets/image/responsive_illustration_2.svg";
import { AppState } from "../../reducers";

const MAX_WIDGETS = 7;

export interface Widget {
  id: string
  titolo: string
  descrizione: string
  icon: string
  order: number
  x?: number
  y?: number
  w?: number
  h?: number
  static?: boolean
  updates?: number
}

const StaticPlaceholders = {
  sp1: "static-placeholder-1",
  sp2: "static-placeholder-2",
  sp3: "static-placeholder-3",
  default: "default-placeholder"
};

const WidgetBox = (item: Widget, add?: Function, remove?: Function) => {

  if (item.id === StaticPlaceholders.sp1) {
    return (
      <div
        key={item.id}
        id={item.id}
        data-grid={item}
      >
        <div className="modal-personalizza-bar-slim modal-personalizza-bar-short" />
        <div className="modal-personalizza-bar-slim modal-personalizza-bar-shortest" />
      </div>
    );
  } else if (item.id === StaticPlaceholders.sp2) {
    return (
      <div
        key={item.id}
        id={item.id}
        data-grid={item}
      >
        <div className="modal-personalizza-bar-slim modal-personalizza-bar-shortest" />
        <div className="modal-personalizza-bar-slim modal-personalizza-bar-short" />
        <div className="modal-personalizza-bar-medium" />
        <div className="modal-personalizza-bar-medium" />
        <div className="modal-personalizza-bar-medium" />
      </div>
    );
  } else if (item.id === StaticPlaceholders.sp3) {
    return (
      <div
        key={item.id}
        id={item.id}
        data-grid={item}
      >
        <div className="modal-personalizza-bar-fat modal-personalizza-bar-shorter" />
        <div className="modal-personalizza-bar-fat modal-personalizza-bar-auto">
          <div className="modal-personalizza-bar-slim modal-personalizza-bar-long" />
          <div className="modal-personalizza-bar-slim modal-personalizza-bar-longest" />
        </div>
      </div>
    );
  } else {

    const onClick = () => {
      remove?.();
      add?.();
    };

    return (
      <div
        key={item.id}
        className={`home-widget widget-order-${item.order} ${!add && !remove ? "home-widget-disabled" : ""}`}
      >

        <div className="icon-item-home-widget">
          <BankIcon title={item.icon} />
        </div>

        <div className="desc-item-home-widget">
          {item.titolo !== "" && <p className="title-item-home-widget">{item.titolo}</p>}
          <p className="text-item-home-widget">{item.descrizione}</p>
        </div>

        <BankIcon title="azioni-draggable" className="icon-draggable" height={24} width={24} />

        <div
          className={`cta-item-home-widget ${!item.static && (!!remove || !!add) ? 'cta-item-home-widget-clickable' : ''}`}
          onClick={!item.static ? onClick : undefined}
        >
          {!!remove && !item.static &&
            <BankIcon title="azioni_elimina" height={24} width={24} />
          }
          {!!add && !item.static &&
            <BankIcon title="azioni_aggiungi" height={24} width={24} />
          }
        </div>

      </div>
    );
  };
};

const placeholderPreferiti = (
  <div className="modal-personalizza-preferiti">
    <div className="modal-personalizza-preferiti-item">&nbsp;</div>
    <div className="modal-personalizza-preferiti-item">&nbsp;</div>
    <div className="modal-personalizza-preferiti-item">&nbsp;</div>
    <div className="modal-personalizza-preferiti-item">&nbsp;</div>
    <div className="modal-personalizza-preferiti-item">&nbsp;</div>
    <div className="modal-personalizza-preferiti-item">&nbsp;</div>
  </div>
);

const ModalPersonalizzaHomepageComponent: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    show,
    onClose,
    widgets,
    parametri,
    widgetSaldo,
    salvaWidgets,
    getParametriWidget
  } = props;

  const [widgetsUI, setWidgetsUI] = useState<Widget[]>([]);
  const [elencoWidgets, setElencoWidgets] = useState<Widget[]>([]);
  const [elencoWidgetsFuturi, setElencoWidgetsFuturi] = useState<Widget[]>([]);

  const buildWidget = (widgetList: WidgetDto[]) => {
    const icons = Object.keys(ICON_ALIAS);
    return widgetList.map((w, i) => ({
      id: w.widgetId || "",
      titolo: t(`personalizzaHomepage.${w.widgetId}.titolo`),
      descrizione: t(`personalizzaHomepage.${w.widgetId}.descrizione`),
      order: w.order || i,
      icon: icons.find(_icon => _icon === w.widgetId) || "varie"
    }));
  };

  const staticWidgets: Array<Widget> = [
    {
      static: true,
      id: StaticPlaceholders.sp1,
      x: 0,
      y: 0,
      titolo: "",
      descrizione: "",
      icon: "",
      order: 0,
      h: 1,
      w: 1
    }, {
      static: true,
      id: StaticPlaceholders.sp2,
      x: 1,
      y: 0,
      titolo: "",
      descrizione: "",
      icon: "",
      order: 0,
      h: 2,
      w: 1
    },
    {
      static: true,
      id: StaticPlaceholders.sp3,
      x: 0,
      y: 2,
      titolo: "",
      descrizione: "",
      icon: "",
      order: 0,
      h: 1,
      w: 2
    }
  ];

  const staticDefaultWidget: Array<Widget> = [
    {
      static: true,
      id: StaticPlaceholders.default,
      x: 0,
      y: 1,
      titolo: "",
      descrizione: t("personalizzaHomepage.almenoUnWidget"),
      icon: "",
      order: 1,
      h: 1,
      w: 1
    }
  ];

  useEffect(() => {
    getParametriWidget();
  }, []);

  useEffect(() => {
    if (widgets?.widgets?.length) {
      const _widgets = buildWidget(widgets?.widgets?.filter(w => !!w.widgetId));
      setWidgetsUI(_widgets);
    }
  }, [widgets]);

  useEffect(() => {
    if (parametri?.appWidget?.length) {
      const _elencoWidgets = buildWidget(parametri?.appWidget?.filter(w => !!w.widgetId));
      setElencoWidgets(_elencoWidgets);
      const _elencoWidgetsFuturi = buildWidget(parametri?.futureAppWidget?.filter(w => !!w.widgetId) || []);
      setElencoWidgetsFuturi(_elencoWidgetsFuturi);
    }
  }, [parametri]);

  const ListaWidgets = (
    <div className={`lista-widgets-container ${widgetsUI.length >= MAX_WIDGETS ? "lista-widgets-container-disabilita-cta" : ""}`}>
      <div className="lista-widgets-container-section-title">{t("personalizzaHomepage.disponibili")}</div>
      <div className="lista-widgets-container-section-description">{t("personalizzaHomepage.disponibiliDescrizione")}</div>
      <div className="lista-widgets">
        {elencoWidgets
          ?.filter(item => !widgetsUI.some(wui => wui.id === item.id))
          .map((item) => WidgetBox(item, () => {
            setWidgetsUI(widgetsUI.concat({ ...item, order: widgetsUI.length ? (Math.max.apply(Math, widgetsUI.map(w => w.order)) || 0) + 1 : 1 }));
          }))
        }
      </div>
      {elencoWidgetsFuturi.length > 0 && (
        <>
          <div className="lista-widgets-container-section-title margin-top-16">{t("personalizzaHomepage.prestoDisponibili")}</div>
          <div className="lista-widgets">
            {elencoWidgetsFuturi?.map((item) => WidgetBox(item))}
          </div>
        </>
      )}
    </div>
  );

  const gridWidgets = useMemo(() => {
    return staticWidgets
      .concat(widgetsUI.length > 0 ? widgetsUI : staticDefaultWidget)
      .map((item) =>
        WidgetBox(item, undefined, () => {
          setWidgetsUI(widgetsUI.filter(w => w.id !== item.id).map((w, i) => ({ ...w, order: i + 1 })));
        })
      );
  }, [staticWidgets, staticDefaultWidget, widgetsUI]);

  const calculatedLayout = staticWidgets
    .concat(widgetsUI.length > 0 ? widgetsUI : staticDefaultWidget)
    .map((item, i) => ({
      x: item.x !== undefined ? item.x : item.order === 1 ? 0 : item.order % 2,
      y: item.y !== undefined ? item.y : item.order === 1 ? 1 : (2 + Math.trunc(item.order / 2)),
      w: item.w || 1,
      h: item.h || 1,
      i: item.id,
      static: !!item.static,
      order: item.order,
      updates: item.updates
    }));

  return (
    <>
      <Modal show={show} className="modal-personalizza-home" onHide={onClose}>
        <Modal.Body>
          <div className="responsive-modal-image-blocker">
            <div className="modal-personalizza-home-superwrapper">
              <div className="modal-personalizza-home-wrapper ">
                <div className="modal-personalizza-home-wrapper-row">
                  <div className="modal-personalizza-home-wrapper-row-logo" />
                  <div className="modal-title">{t("personalizzaHomepage.personalizzaHomepage")}</div>
                </div>
                <div className="modal-personalizza-home-wrapper-row">
                  <div className="modal-personalizza-home-wrapper-row-menu" />
                  <div className="modal-personalizza-home-wrapper-row-widgets-area">
                    {placeholderPreferiti}
                    <GridLayout
                      className={`home-widgets-grid ${widgetsUI.length <= 1 ? "no-drag-n-drop" : ""}`}
                      layout={calculatedLayout}
                      cols={2}
                      isBounded
                      useCSSTransforms={true}
                      isDroppable={true}
                      isResizable={false}
                      isDraggable={widgetsUI.length >= 2}
                      onDragStop={(newLayout) => {
                        setWidgetsUI(widgetsUI
                          .map(p => ({ ...p, order: (newLayout.find(n => n.i === p.id)?.y || 0) * 2 + (newLayout.find(n => n.i === p.id)?.x || 0) }))
                          .sort((a, b) => (a.order - b.order))
                          .map((p, i) => ({
                            ...p,
                            order: i + 1,
                            updates: (p.updates || 0) + 1
                          })));
                      }}
                      compactType={'horizontal'}
                      verticalCompact={true}
                      width={750}
                      rowHeight={124}
                    >
                      {gridWidgets}
                    </GridLayout>
                  </div>
                </div>
              </div>
              {ListaWidgets}
            </div>
          </div>
          {/* blocco funzionalità in caso di risoluzione 1024 */}
          <div className="change-modalCustom-responsive">
            <img className="responsive-image" src={responsiveImage} />
            <span className="responsive-text-header">
              <p>{t('modalPreferiti.risoluzioneNonSufficiente')}</p>
            </span>
            <span className="responsive-text-subtitle">
              <p>{t('modalPreferiti.modificaRisoluzione')}</p>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className={"justify"}>
          <div className="btn-group responsive-modal-image-blocker">
            <button
              type="button"
              className="modal-preferiti-modal__close-button left"
              onClick={onClose}
            >
              {t("modalPreferiti.button.annulla").toUpperCase()}
            </button>
            <button
              type="button"
              className={`modal-preferiti-modal__close-button right ${widgetsUI.length < 1 ? "button-disable" : ""}`}
              onClick={() => {
                const widgetFissi = widgetSaldo ? [widgetSaldo] : [];
                salvaWidgets(widgetFissi.concat(widgetsUI.map((w, i) => ({
                  widgetId: w.id,
                  order: i + 1,
                }))));
                onClose();
              }}
              disabled={widgetsUI.length < 1}
            >
              {t("modalPreferiti.button.conferma").toUpperCase()}
            </button>
          </div>


          {/* bottone unico visualizzato per mobile view */}
          <button
            type="button"
            className="modal-preferiti-modal__close-button right change-modalCustom-responsive"
            onClick={onClose}
          >
            {t("modalPreferiti.button.conferma").toUpperCase()}
          </button>

        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  widgets: widgetsUtente(state.widgets),
  parametri: parametriWidget(state.widgets),
  widgetSaldo: widgetSaldo(state.widgets),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getParametriWidget,
      salvaWidgets,
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    show: boolean,
    onClose: () => void,
  };

export default connect(mapStateToProps, mapDispatchToProps)(ModalPersonalizzaHomepageComponent);
