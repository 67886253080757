"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SortItemDtoToJSON = exports.SortItemDtoFromJSONTyped = exports.SortItemDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SortItemDtoFromJSON(json) {
  return SortItemDtoFromJSONTyped(json, false);
}
exports.SortItemDtoFromJSON = SortItemDtoFromJSON;
function SortItemDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
    'campo': !runtime_1.exists(json, 'campo') ? undefined : json['campo']
  };
}
exports.SortItemDtoFromJSONTyped = SortItemDtoFromJSONTyped;
function SortItemDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codice': value.codice,
    'campo': value.campo
  };
}
exports.SortItemDtoToJSON = SortItemDtoToJSON;