"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FinanziamentoDtoTipoPrestitoEnum = exports.FinanziamentoDtoToJSON = exports.FinanziamentoDtoFromJSONTyped = exports.FinanziamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FinanziamentoDtoFromJSON(json) {
  return FinanziamentoDtoFromJSONTyped(json, false);
}
exports.FinanziamentoDtoFromJSON = FinanziamentoDtoFromJSON;
function FinanziamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'numeroFinanziamento': !runtime_1.exists(json, 'numeroFinanziamento') ? undefined : json['numeroFinanziamento'],
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'progressivoRapporto': !runtime_1.exists(json, 'progressivoRapporto') ? undefined : json['progressivoRapporto'],
    'capitaleResiduo': !runtime_1.exists(json, 'capitaleResiduo') ? undefined : json['capitaleResiduo'],
    'importoStipula': !runtime_1.exists(json, 'importoStipula') ? undefined : json['importoStipula'],
    'divisaStipula': !runtime_1.exists(json, 'divisaStipula') ? undefined : json['divisaStipula'],
    'dataStipula': !runtime_1.exists(json, 'dataStipula') ? undefined : new Date(json['dataStipula']),
    'finePianoPrevista': !runtime_1.exists(json, 'finePianoPrevista') ? undefined : new Date(json['finePianoPrevista']),
    'formaTecnica': !runtime_1.exists(json, 'formaTecnica') ? undefined : json['formaTecnica'],
    'tassoCorrente': !runtime_1.exists(json, 'tassoCorrente') ? undefined : json['tassoCorrente'],
    'periodicita': !runtime_1.exists(json, 'periodicita') ? undefined : json['periodicita'],
    'taeg': !runtime_1.exists(json, 'taeg') ? undefined : json['taeg'],
    'dataProssimaScadenza': !runtime_1.exists(json, 'dataProssimaScadenza') ? undefined : new Date(json['dataProssimaScadenza']),
    'importoProssimaScadenza': !runtime_1.exists(json, 'importoProssimaScadenza') ? undefined : json['importoProssimaScadenza'],
    'numeroRateResidue': !runtime_1.exists(json, 'numeroRateResidue') ? undefined : json['numeroRateResidue'],
    'importoRateResidue': !runtime_1.exists(json, 'importoRateResidue') ? undefined : json['importoRateResidue'],
    'numeroRateInMora': !runtime_1.exists(json, 'numeroRateInMora') ? undefined : json['numeroRateInMora'],
    'importoRateInMora': !runtime_1.exists(json, 'importoRateInMora') ? undefined : json['importoRateInMora'],
    'tipoPrestito': !runtime_1.exists(json, 'tipoPrestito') ? undefined : json['tipoPrestito']
  };
}
exports.FinanziamentoDtoFromJSONTyped = FinanziamentoDtoFromJSONTyped;
function FinanziamentoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceRapporto': value.codiceRapporto,
    'numeroFinanziamento': value.numeroFinanziamento,
    'intestazione': value.intestazione,
    'progressivoRapporto': value.progressivoRapporto,
    'capitaleResiduo': value.capitaleResiduo,
    'importoStipula': value.importoStipula,
    'divisaStipula': value.divisaStipula,
    'dataStipula': value.dataStipula === undefined ? undefined : value.dataStipula.toISOString(),
    'finePianoPrevista': value.finePianoPrevista === undefined ? undefined : value.finePianoPrevista.toISOString(),
    'formaTecnica': value.formaTecnica,
    'tassoCorrente': value.tassoCorrente,
    'periodicita': value.periodicita,
    'taeg': value.taeg,
    'dataProssimaScadenza': value.dataProssimaScadenza === undefined ? undefined : value.dataProssimaScadenza.toISOString(),
    'importoProssimaScadenza': value.importoProssimaScadenza,
    'numeroRateResidue': value.numeroRateResidue,
    'importoRateResidue': value.importoRateResidue,
    'numeroRateInMora': value.numeroRateInMora,
    'importoRateInMora': value.importoRateInMora,
    'tipoPrestito': value.tipoPrestito
  };
}
exports.FinanziamentoDtoToJSON = FinanziamentoDtoToJSON;
/**
* @export
* @enum {string}
*/
var FinanziamentoDtoTipoPrestitoEnum;
(function (FinanziamentoDtoTipoPrestitoEnum) {
  FinanziamentoDtoTipoPrestitoEnum["COFIDIS"] = "COFIDIS";
  FinanziamentoDtoTipoPrestitoEnum["ALTRIPRESTITI"] = "ALTRIPRESTITI";
})(FinanziamentoDtoTipoPrestitoEnum = exports.FinanziamentoDtoTipoPrestitoEnum || (exports.FinanziamentoDtoTipoPrestitoEnum = {}));