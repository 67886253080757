"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRicaricaAltoAdigePassDtoFromJSONTyped = exports.TipoOperazioneRicaricaAltoAdigePass = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
var TipoOperazioneRicaricaAltoAdigePass;
(function (TipoOperazioneRicaricaAltoAdigePass) {
  TipoOperazioneRicaricaAltoAdigePass["RIC"] = "RIC";
  TipoOperazioneRicaricaAltoAdigePass["RIN"] = "RIN";
  TipoOperazioneRicaricaAltoAdigePass["ATT"] = "ATT";
})(TipoOperazioneRicaricaAltoAdigePass = exports.TipoOperazioneRicaricaAltoAdigePass || (exports.TipoOperazioneRicaricaAltoAdigePass = {}));
function FormRicaricaAltoAdigePassDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'numeroAltoAdigePass': !(0, helpers_1.exists)(json, 'numeroAltoAdigePass') ? undefined : json.numeroAltoAdigePass,
    'numeroAltoAdigePassDirty': !(0, helpers_1.exists)(json, 'numeroAltoAdigePassDirty') ? false : json.numeroAltoAdigePassDirty,
    'numeroAltoAdigePassError': !(0, helpers_1.exists)(json, 'numeroAltoAdigePassError') ? 'invalid.required' : json.numeroAltoAdigePassError,
    'nomeBeneficiario': !(0, helpers_1.exists)(json, 'nomeBeneficiario') ? undefined : json.nomeBeneficiario,
    'nomeBeneficiarioDirty': !(0, helpers_1.exists)(json, 'nomeBeneficiarioDirty') ? false : json.nomeBeneficiarioDirty,
    'nomeBeneficiarioError': !(0, helpers_1.exists)(json, 'nomeBeneficiarioError') ? 'invalid.required' : json.nomeBeneficiarioError,
    'taglioRicarica': !(0, helpers_1.exists)(json, 'taglioRicarica') ? undefined : json.taglioRicarica,
    'taglioRicaricaDirty': !(0, helpers_1.exists)(json, 'taglioRicaricaDirty') ? false : json.taglioRicaricaDirty,
    'taglioRicaricaError': !(0, helpers_1.exists)(json, 'taglioRicaricaError') ? 'invalid.required' : json.taglioRicaricaError,
    'tipoOperazione': !(0, helpers_1.exists)(json, 'tipoOperazione') ? undefined : json.tipoOperazione,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'beneficiarioAffidabile': !(0, helpers_1.exists)(json, 'beneficiarioAffidabile') ? false : json.beneficiarioAffidabile,
    'aggiungiBeneficiarioRubrica': !(0, helpers_1.exists)(json, 'aggiungiBeneficiarioRubrica') ? false : json.aggiungiBeneficiarioRubrica,
    'idContattoBeneficiario': !(0, helpers_1.exists)(json, 'idContattoBeneficiario') ? undefined : json.idContattoBeneficiario,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti,
    'idOneClickCollegata': !(0, helpers_1.exists)(json, 'idOneClickCollegata') ? undefined : json.idOneClickCollegata,
    'aggiungi1click': !(0, helpers_1.exists)(json, 'aggiungi1click') ? false : json.aggiungi1click,
    'alias1Click': !(0, helpers_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
    'aliasDirty': !(0, helpers_1.exists)(json, 'aliasDirty') ? false : json.aliasDirty,
    'aliasError': !(0, helpers_1.exists)(json, 'aliasError') ? '' : json.aliasError
  };
}
exports.FormRicaricaAltoAdigePassDtoFromJSONTyped = FormRicaricaAltoAdigePassDtoFromJSONTyped;