"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiIntradayQuotazioneDtoToJSON = exports.DatiIntradayQuotazioneDtoFromJSONTyped = exports.DatiIntradayQuotazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiIntradayQuotazioneDtoFromJSON(json) {
  return DatiIntradayQuotazioneDtoFromJSONTyped(json, false);
}
exports.DatiIntradayQuotazioneDtoFromJSON = DatiIntradayQuotazioneDtoFromJSON;
function DatiIntradayQuotazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'valore': !runtime_1.exists(json, 'valore') ? undefined : json['valore'],
    'data': !runtime_1.exists(json, 'data') ? undefined : new Date(json['data'])
  };
}
exports.DatiIntradayQuotazioneDtoFromJSONTyped = DatiIntradayQuotazioneDtoFromJSONTyped;
function DatiIntradayQuotazioneDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'valore': value.valore,
    'data': value.data === undefined ? undefined : value.data.toISOString()
  };
}
exports.DatiIntradayQuotazioneDtoToJSON = DatiIntradayQuotazioneDtoToJSON;