"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BollettinoPostaleRevocaInToJSON = exports.BollettinoPostaleRevocaInFromJSONTyped = exports.BollettinoPostaleRevocaInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BollettinoPostaleRevocaInFromJSON(json) {
  return BollettinoPostaleRevocaInFromJSONTyped(json, false);
}
exports.BollettinoPostaleRevocaInFromJSON = BollettinoPostaleRevocaInFromJSON;
function BollettinoPostaleRevocaInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziale']),
    'annoInserimento': !runtime_1.exists(json, 'annoInserimento') ? undefined : json['annoInserimento'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'progressivoInserimento': !runtime_1.exists(json, 'progressivoInserimento') ? undefined : json['progressivoInserimento']
  };
}
exports.BollettinoPostaleRevocaInFromJSONTyped = BollettinoPostaleRevocaInFromJSONTyped;
function BollettinoPostaleRevocaInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'credenziale': _1.CredenzialeDispositivaDtoToJSON(value.credenziale),
    'annoInserimento': value.annoInserimento,
    'codiceRapporto': value.codiceRapporto,
    'progressivoInserimento': value.progressivoInserimento
  };
}
exports.BollettinoPostaleRevocaInToJSON = BollettinoPostaleRevocaInToJSON;