"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InvestimentiContoSaldiDtoToJSON = exports.InvestimentiContoSaldiDtoFromJSONTyped = exports.InvestimentiContoSaldiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function InvestimentiContoSaldiDtoFromJSON(json) {
  return InvestimentiContoSaldiDtoFromJSONTyped(json, false);
}
exports.InvestimentiContoSaldiDtoFromJSON = InvestimentiContoSaldiDtoFromJSON;
function InvestimentiContoSaldiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'descrizioneRapporto': !runtime_1.exists(json, 'descrizioneRapporto') ? undefined : json['descrizioneRapporto'],
    'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    'indicativo': !runtime_1.exists(json, 'indicativo') ? undefined : json['indicativo'],
    'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
    'descrizioneTitolo': !runtime_1.exists(json, 'descrizioneTitolo') ? undefined : json['descrizioneTitolo'],
    'divisoEmissione': !runtime_1.exists(json, 'divisoEmissione') ? undefined : json['divisoEmissione'],
    'divisoTrattazione': !runtime_1.exists(json, 'divisoTrattazione') ? undefined : json['divisoTrattazione'],
    'tipoTitolo': !runtime_1.exists(json, 'tipoTitolo') ? undefined : json['tipoTitolo'],
    'raggrTitolo': !runtime_1.exists(json, 'raggrTitolo') ? undefined : json['raggrTitolo'],
    'quantita': !runtime_1.exists(json, 'quantita') ? undefined : json['quantita'],
    'prezzo': !runtime_1.exists(json, 'prezzo') ? undefined : json['prezzo'],
    'cambio': !runtime_1.exists(json, 'cambio') ? undefined : json['cambio'],
    'controvaloreDivisa': !runtime_1.exists(json, 'controvaloreDivisa') ? undefined : json['controvaloreDivisa'],
    'controvaloreEuro': !runtime_1.exists(json, 'controvaloreEuro') ? undefined : json['controvaloreEuro'],
    'dataPrezzo': !runtime_1.exists(json, 'dataPrezzo') ? undefined : new Date(json['dataPrezzo']),
    'dietimoPercentuale': !runtime_1.exists(json, 'dietimoPercentuale') ? undefined : json['dietimoPercentuale'],
    'prezzoFiscale': !runtime_1.exists(json, 'prezzoFiscale') ? undefined : json['prezzoFiscale'],
    'cambioFiscale': !runtime_1.exists(json, 'cambioFiscale') ? undefined : json['cambioFiscale'],
    'dataFiscale': !runtime_1.exists(json, 'dataFiscale') ? undefined : new Date(json['dataFiscale']),
    'rateo': !runtime_1.exists(json, 'rateo') ? undefined : json['rateo'],
    'ritenutaFiscale': !runtime_1.exists(json, 'ritenutaFiscale') ? undefined : json['ritenutaFiscale'],
    'ritenutaFiscaleMaturata': !runtime_1.exists(json, 'ritenutaFiscaleMaturata') ? undefined : json['ritenutaFiscaleMaturata'],
    'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
    'mercatoEsterno': !runtime_1.exists(json, 'mercatoEsterno') ? undefined : json['mercatoEsterno'],
    'controvaloreGaranzia': !runtime_1.exists(json, 'controvaloreGaranzia') ? undefined : json['controvaloreGaranzia'],
    'tassoLordo': !runtime_1.exists(json, 'tassoLordo') ? undefined : json['tassoLordo'],
    'controvaloreFiscaleEuro': !runtime_1.exists(json, 'controvaloreFiscaleEuro') ? undefined : json['controvaloreFiscaleEuro'],
    'controvaloreFiscaleDivisa': !runtime_1.exists(json, 'controvaloreFiscaleDivisa') ? undefined : json['controvaloreFiscaleDivisa'],
    'minusPlus': !runtime_1.exists(json, 'minusPlus') ? undefined : json['minusPlus'],
    'minusPlusControvaloreEuro': !runtime_1.exists(json, 'minusPlusControvaloreEuro') ? undefined : json['minusPlusControvaloreEuro']
  };
}
exports.InvestimentiContoSaldiDtoFromJSONTyped = InvestimentiContoSaldiDtoFromJSONTyped;
function InvestimentiContoSaldiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'descrizioneRapporto': value.descrizioneRapporto,
    'titolo': value.titolo,
    'indicativo': value.indicativo,
    'codiceIsin': value.codiceIsin,
    'descrizioneTitolo': value.descrizioneTitolo,
    'divisoEmissione': value.divisoEmissione,
    'divisoTrattazione': value.divisoTrattazione,
    'tipoTitolo': value.tipoTitolo,
    'raggrTitolo': value.raggrTitolo,
    'quantita': value.quantita,
    'prezzo': value.prezzo,
    'cambio': value.cambio,
    'controvaloreDivisa': value.controvaloreDivisa,
    'controvaloreEuro': value.controvaloreEuro,
    'dataPrezzo': value.dataPrezzo === undefined ? undefined : value.dataPrezzo.toISOString(),
    'dietimoPercentuale': value.dietimoPercentuale,
    'prezzoFiscale': value.prezzoFiscale,
    'cambioFiscale': value.cambioFiscale,
    'dataFiscale': value.dataFiscale === undefined ? undefined : value.dataFiscale.toISOString(),
    'rateo': value.rateo,
    'ritenutaFiscale': value.ritenutaFiscale,
    'ritenutaFiscaleMaturata': value.ritenutaFiscaleMaturata,
    'mercato': value.mercato,
    'mercatoEsterno': value.mercatoEsterno,
    'controvaloreGaranzia': value.controvaloreGaranzia,
    'tassoLordo': value.tassoLordo,
    'controvaloreFiscaleEuro': value.controvaloreFiscaleEuro,
    'controvaloreFiscaleDivisa': value.controvaloreFiscaleDivisa,
    'minusPlus': value.minusPlus,
    'minusPlusControvaloreEuro': value.minusPlusControvaloreEuro
  };
}
exports.InvestimentiContoSaldiDtoToJSON = InvestimentiContoSaldiDtoToJSON;