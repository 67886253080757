"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RapportoPerServizioDtoToJSON = exports.RapportoPerServizioDtoFromJSONTyped = exports.RapportoPerServizioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RapportoPerServizioDtoFromJSON(json) {
  return RapportoPerServizioDtoFromJSONTyped(json, false);
}
exports.RapportoPerServizioDtoFromJSON = RapportoPerServizioDtoFromJSON;
function RapportoPerServizioDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codiceCategoria': !runtime_1.exists(json, 'codiceCategoria') ? undefined : json['codiceCategoria'],
    'codiceRapportoPredefinito': !runtime_1.exists(json, 'codiceRapportoPredefinito') ? undefined : json['codiceRapportoPredefinito'],
    'rapportiAbilitati': !runtime_1.exists(json, 'rapportiAbilitati') ? undefined : json['rapportiAbilitati'],
    'rapportiIntestazioni': !runtime_1.exists(json, 'rapportiIntestazioni') ? undefined : runtime_1.mapValues(json['rapportiIntestazioni'], _1.DatiRapportoDtoFromJSON)
  };
}
exports.RapportoPerServizioDtoFromJSONTyped = RapportoPerServizioDtoFromJSONTyped;
function RapportoPerServizioDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codiceCategoria': value.codiceCategoria,
    'codiceRapportoPredefinito': value.codiceRapportoPredefinito,
    'rapportiAbilitati': value.rapportiAbilitati,
    'rapportiIntestazioni': value.rapportiIntestazioni === undefined ? undefined : runtime_1.mapValues(value.rapportiIntestazioni, _1.DatiRapportoDtoToJSON)
  };
}
exports.RapportoPerServizioDtoToJSON = RapportoPerServizioDtoToJSON;