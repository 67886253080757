"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAccordionFiltriRapportiEstintiOpened = exports.setPagamentiSetDettaglioPagamentoSelezionato = exports.setPagamentiRapportiEstintiSetOffset = exports.setPagamentiRapportiEstintiResetRapportoSelezionato = exports.resetFiltriPagamentiRapportiEstinti = exports.setPagamentiEstintiSetTipologia = exports.setPagamentiRapportiEstintiSetDataA = exports.setPagamentiRapportiEstintiSetDataDa = exports.setPagamentiRapportiEstintiRapportoSelezionato = exports.getRapportiEstintiPagamenti = exports.setPagamentiSetOffset = exports.esportaPagamentiDetaglioPDF = exports.setPagamentiSetAccordionFiltriOpened = exports.setPagamentiSetCodiceFiscale = exports.setPagamentiSetPeriodo = exports.setPagamentiSetImporto = exports.setPagamentiSetStati = exports.setPagamentiSetTipologie = exports.setPagamentiSetDataA = exports.setPagamentiSetDataDa = exports.setPagamentiSetDescrizione = exports.setPagamentiSetRapportoSelezionato = exports.setPagamentiResetDettaglioPagamento = exports.setPagamentoSelezionato = exports.resetFiltriPagamenti = exports.esportaPagamentiExcel = exports.esportaPagamentiPDF = exports.getDettaglioPagamento = exports.getPagamenti = exports.getPagamentiRapportiEstintiFiltrati = exports.getPagamentiFiltrati = exports.getParametriPagamenti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const pagamenti_selectors_1 = require("./pagamenti.selectors");
/* get parametri pagamenti - START */
function getParametriPagamentiStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_PARAMETRI_START
  };
}
function getParametriPagamentiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getParametriPagamenti = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriPagamentiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch((0, general_actions_1.setLoader)(false));
      dispatch(getParametriPagamentiSuccess((0, api_rest_1.ParametriElencoPagamentiDtoFromJSON)(parametri)));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ElencoPagamentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriPagamenti().then(response => {
        var _a;
        dispatch(getParametriPagamentiSuccess(response));
        ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({
          type: general_types_1.TypeNoRapporti.ELENCO_PAG
        }));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriPagamenti = getParametriPagamenti;
/* get parametri pagamenti - FINE */
/* get pagamenti - START */
function getPagamentiStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_PAGAMENTI_START
  };
}
function getPagamentiRapportiEstintiStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_PAGAMENTI_RAPPORTI_ESTINTI_START
  };
}
function getPagamentiSuccess(payload, offset) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_PAGAMENTI_SUCCESS,
    payload,
    offset
  };
}
function getPagamentiRapportiEstintiSuccess(payload, offset) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_PAGAMENTI_RAPPORTI_ESTINTI_SUCCESS,
    payload,
    offset
  };
}
const getPagamentiFiltrati = () => async (dispatch, getState, extraArguments) => {
  const datiForm = (0, pagamenti_selectors_1.formElencoPagamentiAsFiltro)(getState().pagamenti);
  datiForm && (0, exports.getPagamenti)(datiForm)(dispatch, getState, extraArguments);
};
exports.getPagamentiFiltrati = getPagamentiFiltrati;
const getPagamentiRapportiEstintiFiltrati = () => async (dispatch, getState, extraArguments) => {
  const datiForm = (0, pagamenti_selectors_1.formElencoPagamentiRapportiEstintiAsFiltro)(getState().pagamenti);
  datiForm && (0, exports.getPagamenti)(datiForm, true)(dispatch, getState, extraArguments);
};
exports.getPagamentiRapportiEstintiFiltrati = getPagamentiRapportiEstintiFiltrati;
const getPagamenti = (datiForm, estinti) => async (dispatch, _, extraArguments) => {
  if (!datiForm.offset || datiForm.offset === 0) {
    dispatch((0, general_actions_1.setLoader)(true));
  }
  if (datiForm.offset && datiForm.offset > 0) {
    estinti ? dispatch(setPagamentiRapportiEstintiListLoader(true)) : dispatch(setPagamentiListLoader(true));
  }
  estinti ? dispatch(getPagamentiRapportiEstintiStart()) : dispatch(getPagamentiStart());
  if (!datiForm.offset || datiForm.offset === 0) {
    dispatch(resetPagamenti());
  }
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/lista_movimenti.json')).then(parametri => {
      dispatch(getPagamentiSuccess((0, api_rest_1.DatiPagamentiDtoFromJSON)(parametri), datiForm.offset));
    });
    if (!datiForm.offset || datiForm.offset === 0) {
      dispatch((0, general_actions_1.setLoader)(false));
    }
    if (datiForm.offset && datiForm.offset > 0) {
      estinti ? dispatch(setPagamentiRapportiEstintiListLoader(true)) : dispatch(setPagamentiListLoader(false));
    }
  } else {
    const getPagamentiRequest = {
      filtroListaPagamentiDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ElencoPagamentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).elencoPagamenti(getPagamentiRequest).then(response => {
        dispatch(estinti ? getPagamentiRapportiEstintiSuccess(response, datiForm.offset) : getPagamentiSuccess(response, datiForm.offset));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        if (!datiForm.offset || datiForm.offset === 0) {
          dispatch((0, general_actions_1.setLoader)(false));
        }
        if (datiForm.offset && datiForm.offset > 0) {
          estinti ? dispatch(setPagamentiRapportiEstintiListLoader(true)) : dispatch(setPagamentiListLoader(true));
        }
      });
    });
  }
};
exports.getPagamenti = getPagamenti;
/* get pagamenti - FINE */
/* get pagamenti - START */
function getDettaglioPagamentoStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_START
  };
}
function getDettaglioPagamentoSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_SUCCESS,
    payload
  };
}
const getDettaglioPagamento = datiForm => async (dispatch, _, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDettaglioPagamentoStart());
  if ((_a = datiForm.dettaglio) === null || _a === void 0 ? void 0 : _a.disposizione) {
    // alcuni pagsamenti hanno il dettaglio integrato
    dispatch(getDettaglioPagamentoSuccess(datiForm.dettaglio));
    dispatch((0, general_actions_1.setLoader)(false));
  } else if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/f24quietanza.json')).then(parametri => {
      dispatch(getDettaglioPagamentoSuccess((0, api_rest_1.DettaglioPagamentoDtoFromJSON)(parametri[0])));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getDettaglioPagamentoRequest = {
      datiPagamentoDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ElencoPagamentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).dettaglioPagamento(getDettaglioPagamentoRequest).then(response => {
        dispatch(getDettaglioPagamentoSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getDettaglioPagamento = getDettaglioPagamento;
/* get pagamenti - FINE */
function esportaPagamentiPDFStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_ESPORTA_PDF_START
  };
}
function esportaPagamentiPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_ESPORTA_PDF_SUCCESS,
    payload
  };
}
const esportaPagamentiPDF = (datiForm, share, title, message) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaPagamentiPDFStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaPagaimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const esportaPagamentiDetaglioPDFRequest = {
      type: api_rest_1.ElencoPagamentiPdfTypeEnum.pdf,
      filtroListaPagamentiDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ElencoPagamentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).elencoPagamentiPdf(esportaPagamentiDetaglioPDFRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'ElencoPagamenti.pdf', response, share, title, message);
        }
        dispatch(esportaPagamentiPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaPagamentiPDF = esportaPagamentiPDF;
function esportaPagamentiExcelStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_ESPORTA_EXCEL_START
  };
}
function esportaPagamentiExcelSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_ESPORTA_EXCEL_SUCCESS,
    payload
  };
}
const esportaPagamentiExcel = (datiForm, share) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaPagamentiExcelStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaPagamentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const esportaPagamentiDetaglioPDFRequest = {
      type: api_rest_1.ElencoPagamentiPdfTypeEnum.excel,
      filtroListaPagamentiDto: datiForm
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ElencoPagamentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).elencoPagamentiPdf(esportaPagamentiDetaglioPDFRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadleExcelResponse)(response, 'pagamenti.xlsx');
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_shareXLS)(extraArguments, 'ElencoPagamenti.xls', response, share);
        }
        dispatch(esportaPagamentiExcelSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaPagamentiExcel = esportaPagamentiExcel;
function resetFiltriPagamenti() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RESET_FILTRO
  };
}
exports.resetFiltriPagamenti = resetFiltriPagamenti;
function setPagamentoSelezionato(pagamento) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_SET_PAGAMENTO_SELEZIONATO,
    payload: pagamento
  };
}
exports.setPagamentoSelezionato = setPagamentoSelezionato;
function setPagamentiResetDettaglioPagamento() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RESET_DETTAGLIO_PAGAMENTO
  };
}
exports.setPagamentiResetDettaglioPagamento = setPagamentiResetDettaglioPagamento;
function setPagamentiSetRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setPagamentiSetRapportoSelezionato = setPagamentiSetRapportoSelezionato;
function setPagamentiSetDescrizione(txt) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_DESCRIZIONE,
    payload: txt
  };
}
exports.setPagamentiSetDescrizione = setPagamentiSetDescrizione;
function setPagamentiSetDataDa(txt) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_DATA_DA,
    payload: txt
  };
}
exports.setPagamentiSetDataDa = setPagamentiSetDataDa;
function setPagamentiSetDataA(txt) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_DATA_A,
    payload: txt
  };
}
exports.setPagamentiSetDataA = setPagamentiSetDataA;
function setPagamentiSetTipologie(tipologie) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_TIPOLOGIE,
    payload: tipologie
  };
}
exports.setPagamentiSetTipologie = setPagamentiSetTipologie;
function setPagamentiSetStati(stati) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_STATI,
    payload: stati
  };
}
exports.setPagamentiSetStati = setPagamentiSetStati;
function setPagamentiSetImporto(num) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_IMPORTO,
    payload: num
  };
}
exports.setPagamentiSetImporto = setPagamentiSetImporto;
function setPagamentiSetPeriodo(txt) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_PERIODO,
    payload: txt
  };
}
exports.setPagamentiSetPeriodo = setPagamentiSetPeriodo;
function setPagamentiSetCodiceFiscale(val) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_CODICE_FISCALE,
    payload: val
  };
}
exports.setPagamentiSetCodiceFiscale = setPagamentiSetCodiceFiscale;
function setPagamentiSetAccordionFiltriOpened(opened) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_SET_ACCORDION_FILTRI_OPENED,
    payload: opened
  };
}
exports.setPagamentiSetAccordionFiltriOpened = setPagamentiSetAccordionFiltriOpened;
/* export detaglio pdf - START */
function esportaPagamentiDetaglioPDFStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_ESPORTA_PDF_DETAGLIO_START
  };
}
function esportaPagamentiDetaglioPDFSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_ESPORTA_PDF_DETAGLIO_SUCCESS,
    payload
  };
}
const esportaPagamentiDetaglioPDF = (datiForm, share, title, message) => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(esportaPagamentiDetaglioPDFStart());
  if (extraArguments.mock) {
    /* TODO: vedere nella parte mockata cosa restituire
    import('../../mock/lista_movimenti.json').then(parametri => {
      dispatch(esportaPagaimentiPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
    });
    */
  } else {
    const pagamento = getState().pagamenti.pagamentoSelezionato;
    const esportaPagamentiDetaglioPDFRequest = {
      datiPagamentoDto: {
        ...datiForm,
        dataPrenotazione: !!pagamento ? pagamento.dataPrenotazione : undefined,
        ibanRapportoEstinto: !!pagamento ? pagamento.ibanRapportoEstinto : undefined,
        rapportoEstinto: !!pagamento ? pagamento.rapportoEstinto : false
      } // creare builder DatiPagamentoCommonDto --> DatiPagamentoDto
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.ElencoPagamentiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioPagamentiPdf(esportaPagamentiDetaglioPDFRequest).then(response => {
        if (extraArguments.platform === 'desktop') {
          (0, helpers_1.hadlePdfResponse)(response, url => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
        }
        if (extraArguments.platform === 'mobile') {
          (0, helpers_1.download_sharePDF)(extraArguments, 'ElencoPagamento.pdf', response, share, title, message);
        }
        dispatch(esportaPagamentiDetaglioPDFSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.esportaPagamentiDetaglioPDF = esportaPagamentiDetaglioPDF;
/* export detaglio pdf - FINE */
function resetPagamenti() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RESET_ELENCO
  };
}
function setPagamentiSetOffset(offset) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_SET_OFFSET,
    payload: offset
  };
}
exports.setPagamentiSetOffset = setPagamentiSetOffset;
function setPagamentiListLoader(show) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_LIST_LOADER,
    payload: show
  };
}
/* get rapporti estinti pagamenti - START */
function getRapportiEstintiPagamentiStart() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_RAPPORTI_ESTINTI_START
  };
}
function getRapportiEstintiPagamentiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_GET_RAPPORTI_ESTINTI_SUCCESS,
    payload
  };
}
const getRapportiEstintiPagamenti = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getRapportiEstintiPagamentiStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const request = {
      filtriEstintiDto: (0, pagamenti_selectors_1.formRapportiEstintiAsFiltro)(getState().pagamenti)
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).rapportiEstinti(request).then(response => {
        dispatch(getRapportiEstintiPagamentiSuccess(response));
        if (response.length == 0) {
          dispatch(resetPagamentiRapportiEstinti());
        }
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getRapportiEstintiPagamenti = getRapportiEstintiPagamenti;
/* get rapporti estinti pagamenti - FINE */
function setPagamentiRapportiEstintiRapportoSelezionato(rapporto) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_RAPPORTO_SELEZIONATO,
    payload: rapporto
  };
}
exports.setPagamentiRapportiEstintiRapportoSelezionato = setPagamentiRapportiEstintiRapportoSelezionato;
function setPagamentiRapportiEstintiSetDataDa(txt) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_DATA_DA,
    payload: txt
  };
}
exports.setPagamentiRapportiEstintiSetDataDa = setPagamentiRapportiEstintiSetDataDa;
function setPagamentiRapportiEstintiSetDataA(txt) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_DATA_A,
    payload: txt
  };
}
exports.setPagamentiRapportiEstintiSetDataA = setPagamentiRapportiEstintiSetDataA;
function setPagamentiEstintiSetTipologia(tipologia) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_TIPOLOGIA,
    payload: tipologia
  };
}
exports.setPagamentiEstintiSetTipologia = setPagamentiEstintiSetTipologia;
function resetFiltriPagamentiRapportiEstinti() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RESET_FILTRI_RAPPORTI_ESTINTI
  };
}
exports.resetFiltriPagamentiRapportiEstinti = resetFiltriPagamentiRapportiEstinti;
function setPagamentiRapportiEstintiResetRapportoSelezionato() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_RESET_RAPPORTO_SELEZIONATO
  };
}
exports.setPagamentiRapportiEstintiResetRapportoSelezionato = setPagamentiRapportiEstintiResetRapportoSelezionato;
function setPagamentiRapportiEstintiSetOffset(offset) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_OFFSET,
    payload: offset
  };
}
exports.setPagamentiRapportiEstintiSetOffset = setPagamentiRapportiEstintiSetOffset;
function setPagamentiRapportiEstintiListLoader(show) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RAPPORTI_ESTINTI_SET_LIST_LOADER,
    payload: show
  };
}
function setPagamentiSetDettaglioPagamentoSelezionato(pagamento) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_SET_DETTAGLIO_PAGAMENTO_SELEZIONATO,
    payload: pagamento
  };
}
exports.setPagamentiSetDettaglioPagamentoSelezionato = setPagamentiSetDettaglioPagamentoSelezionato;
function resetPagamentiRapportiEstinti() {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_RESET_ELENCO_RAPPORTI_ESTINTI
  };
}
function setAccordionFiltriRapportiEstintiOpened(opened) {
  return {
    type: __1.EReduxActionTypes.PAGAMENTI_SET_ACCORDION_FILTRI_RAPPORTI_ESTINTI_OPENED,
    payload: opened
  };
}
exports.setAccordionFiltriRapportiEstintiOpened = setAccordionFiltriRapportiEstintiOpened;