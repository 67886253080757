"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CodeGrantReqInToJSON = exports.CodeGrantReqInFromJSONTyped = exports.CodeGrantReqInFromJSON = void 0;
const runtime_1 = require("../runtime");
function CodeGrantReqInFromJSON(json) {
  return CodeGrantReqInFromJSONTyped(json, false);
}
exports.CodeGrantReqInFromJSON = CodeGrantReqInFromJSON;
function CodeGrantReqInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'codegrant': json['codegrant'],
    'redirectUri': !runtime_1.exists(json, 'redirectUri') ? undefined : json['redirectUri']
  };
}
exports.CodeGrantReqInFromJSONTyped = CodeGrantReqInFromJSONTyped;
function CodeGrantReqInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'codegrant': value.codegrant,
    'redirectUri': value.redirectUri
  };
}
exports.CodeGrantReqInToJSON = CodeGrantReqInToJSON;