"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefreshTokenInToJSON = exports.RefreshTokenInFromJSONTyped = exports.RefreshTokenInFromJSON = void 0;
function RefreshTokenInFromJSON(json) {
  return RefreshTokenInFromJSONTyped(json, false);
}
exports.RefreshTokenInFromJSON = RefreshTokenInFromJSON;
function RefreshTokenInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'refreshToken': json['refreshToken']
  };
}
exports.RefreshTokenInFromJSONTyped = RefreshTokenInFromJSONTyped;
function RefreshTokenInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'refreshToken': value.refreshToken
  };
}
exports.RefreshTokenInToJSON = RefreshTokenInToJSON;