"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetBreakdownDetailInDtoToJSON = exports.GetBreakdownDetailInDtoFromJSONTyped = exports.GetBreakdownDetailInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function GetBreakdownDetailInDtoFromJSON(json) {
  return GetBreakdownDetailInDtoFromJSONTyped(json, false);
}
exports.GetBreakdownDetailInDtoFromJSON = GetBreakdownDetailInDtoFromJSON;
function GetBreakdownDetailInDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'customerId': json['customerId'],
    'breakdownCode': json['breakdownCode'],
    'breakdownKey': json['breakdownKey'],
    'accounts': !runtime_1.exists(json, 'accounts') ? undefined : json['accounts'],
    'includeCoi': !runtime_1.exists(json, 'includeCoi') ? undefined : json['includeCoi']
  };
}
exports.GetBreakdownDetailInDtoFromJSONTyped = GetBreakdownDetailInDtoFromJSONTyped;
function GetBreakdownDetailInDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'customerId': value.customerId,
    'breakdownCode': value.breakdownCode,
    'breakdownKey': value.breakdownKey,
    'accounts': value.accounts,
    'includeCoi': value.includeCoi
  };
}
exports.GetBreakdownDetailInDtoToJSON = GetBreakdownDetailInDtoToJSON;