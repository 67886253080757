"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RevokeConsentRequestToJSON = exports.RevokeConsentRequestFromJSONTyped = exports.RevokeConsentRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function RevokeConsentRequestFromJSON(json) {
  return RevokeConsentRequestFromJSONTyped(json, false);
}
exports.RevokeConsentRequestFromJSON = RevokeConsentRequestFromJSON;
function RevokeConsentRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
    'aspspName': !runtime_1.exists(json, 'aspspName') ? undefined : json['aspspName']
  };
}
exports.RevokeConsentRequestFromJSONTyped = RevokeConsentRequestFromJSONTyped;
function RevokeConsentRequestToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'aspspId': value.aspspId,
    'aspspName': value.aspspName
  };
}
exports.RevokeConsentRequestToJSON = RevokeConsentRequestToJSON;