"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldUpdateBozzaBollettinoFreccia = exports.getBollettinoFrecciaFromBozza = exports.delBozzaBollettinoFreccia = exports.getBozzeBollettinoFreccia = exports.autorizzaRevocaBollettinoFreccia = exports.getInfoRevocaBollettinoFreccia = exports.setBollettinoFrecciaRevocaSetStep = exports.setProvenienzaRevocaBollettinoFreccia = exports.setRiepilogoRevocaBollettinoFreccia = exports.resetRiepilogoBollettinoFreccia = exports.resetBollettinoFreccia = exports.setBollettinoFrecciaSetFormSubmitted = exports.setBollettinoFrecciaSetCarattereEsenzione = exports.setBollettinoFrecciaSetCinComplesso = exports.setBollettinoFrecciaSetCinIntermedio = exports.setBollettinoFrecciaSetCinImporto = exports.setBollettinoFrecciaSetNote = exports.setBollettinoFrecciaSetDataScadenza = exports.setBollettinoFrecciaSetImporto = exports.setBollettinoFrecciaSetIbanCreditore = exports.setBollettinoFrecciaSetRiferimentoCreditore = exports.setBollettinoFrecciaSetCodiceSia = exports.setBollettinoFrecciaSetRapportoSelezionato = exports.setBollettinoFrecciaSetStep = exports.autorizzaBollettinoFreccia = exports.getInfoDispositivaBollettinoFreccia = exports.controlloBollettinoFreccia = exports.getParametriBollettinoFreccia = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const bollettino_freccia_selectors_1 = require("./bollettino.freccia.selectors");
const moment = require("moment");
/* get parametri bollettino freccia - START */
function getParametriStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_PARAMETRI_START
  };
}
function getParametriSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_PARAMETRI_SUCCESS,
    payload
  };
}
function getParametriPerRipetiSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_PARAMETRI_PER_RIPETI_SUCCESS,
    payload
  };
}
function generalError(value, showGenericModalError, sendErrorToMatomo, dispositiva) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    sendErrorToMatomo && sendErrorToMatomo(dispositiva, "code_error ".concat(dispositiva, " ").concat(value.status, " / ").concat(value === null || value === void 0 ? void 0 : value.message, " / ").concat(value === null || value === void 0 ? void 0 : value.timestamp), 'ESITO_DISPOSITIVA');
  };
}
const getParametriBollettinoFreccia = ripeti => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getParametriStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/operazionidefault.json')).then(parametri => {
      dispatch(ripeti ? getParametriPerRipetiSuccess((0, api_rest_1.ParametriFrecciaDtoFromJSON)(parametri)) : getParametriSuccess((0, api_rest_1.ParametriFrecciaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersFreccia().then(response => {
        dispatch(ripeti ? getParametriPerRipetiSuccess(response) : getParametriSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getParametriBollettinoFreccia = getParametriBollettinoFreccia;
/* get parametri - FINE */
/* controllo bollettino freccia - INIT */
function controlloBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_CHECK_FRECCIA_START
  };
}
function controlloBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_CHECK_FRECCIA_SUCCESS,
    payload
  };
}
const controlloBollettinoFreccia = () => async (dispatch, getState, extraArguments) => {
  const bollettinoFreccia = getState().bollettinoFreccia;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(controlloBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/operazionidefault.json')).then(parametri => {
      dispatch(controlloBollettinoFrecciaSuccess((0, api_rest_1.FrecciaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const requestParameters = {
        frecciaDto: (0, bollettino_freccia_selectors_1.buildBollettinoFreccia)(bollettinoFreccia)
      };
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).checkFreccia(requestParameters).then(response => {
        dispatch(controlloBollettinoFrecciaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.controlloBollettinoFreccia = controlloBollettinoFreccia;
/* controllo bollettino freccia - FINE */
/* get informazioni autenticazione dispositiva - START*/
function getInfoDispositivaBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_START
  };
}
function getInfoDispositivaBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_SUCCESS,
    payload
  };
}
const getInfoDispositivaBollettinoFreccia = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoDispositivaBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/operazionidefault.json')).then(parametri => {
      dispatch(getInfoDispositivaBollettinoFrecciaSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const requestParameters = {
        idDispositiva,
        chiamataUscente,
        eseguiInit,
        offline: !!offline
      };
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationFreccia(requestParameters).then(response => {
        dispatch(getInfoDispositivaBollettinoFrecciaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoDispositivaBollettinoFreccia = getInfoDispositivaBollettinoFreccia;
/* get informazioni dispositiva bollettino freccia - FINE */
/* autorizza bollettino freccia - START */
function autorizzaBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_AUTORIZZA_START
  };
}
function autorizzaBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_AUTORIZZA_SUCCESS,
    payload
  };
}
const autorizzaBollettinoFreccia = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/operazionidefault.json')).then(parametri => {
      dispatch(autorizzaBollettinoFrecciaSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      const requestParameters = {
        idDispositiva,
        credenzialeDispositivaDto: credenziale
      };
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeFreccia(requestParameters).then(response => {
        dispatch(autorizzaBollettinoFrecciaSuccess(response));
        extraArguments.sendEventToMatomo('BOLLETTINO_FRECCIA', "esito dispositiva BOLLETTINO_FRECCIA ".concat(response.stato), 'ESITO_DISPOSITIVA');
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'BOLLETTINO_FRECCIA'));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaBollettinoFreccia = autorizzaBollettinoFreccia;
/* autorizza bollettino freccia - FINE */
function setBollettinoFrecciaSetStep(step) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_STEP,
    payload: step
  };
}
exports.setBollettinoFrecciaSetStep = setBollettinoFrecciaSetStep;
function setBollettinoFrecciaSetRapportoSelezionato(rapportoSelezionato) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_RAPPORTO_SELEZIONATO,
    payload: rapportoSelezionato
  };
}
exports.setBollettinoFrecciaSetRapportoSelezionato = setBollettinoFrecciaSetRapportoSelezionato;
function setBollettinoFrecciaSetCodiceSia(codiceSia) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CODICE_SIA,
    payload: codiceSia
  };
}
exports.setBollettinoFrecciaSetCodiceSia = setBollettinoFrecciaSetCodiceSia;
function setBollettinoFrecciaSetRiferimentoCreditore(riferimentoCreditore) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_RIFERIMENTO_CREDITORE,
    payload: riferimentoCreditore
  };
}
exports.setBollettinoFrecciaSetRiferimentoCreditore = setBollettinoFrecciaSetRiferimentoCreditore;
function setBollettinoFrecciaSetIbanCreditore(ibanCreditore) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_IBAN_CREDITORE,
    payload: ibanCreditore
  };
}
exports.setBollettinoFrecciaSetIbanCreditore = setBollettinoFrecciaSetIbanCreditore;
function setBollettinoFrecciaSetImporto(importo) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_IMPORTO,
    payload: importo
  };
}
exports.setBollettinoFrecciaSetImporto = setBollettinoFrecciaSetImporto;
function setBollettinoFrecciaSetDataScadenza(dataScadenza) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_DATA_SCADENZA,
    payload: dataScadenza
  };
}
exports.setBollettinoFrecciaSetDataScadenza = setBollettinoFrecciaSetDataScadenza;
function setBollettinoFrecciaSetNote(note) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_NOTE,
    payload: note
  };
}
exports.setBollettinoFrecciaSetNote = setBollettinoFrecciaSetNote;
function setBollettinoFrecciaSetCinImporto(cinImporto) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CIN_IMPORTO,
    payload: cinImporto
  };
}
exports.setBollettinoFrecciaSetCinImporto = setBollettinoFrecciaSetCinImporto;
function setBollettinoFrecciaSetCinIntermedio(cinIntermedio) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CIN_INTERMEDIO,
    payload: cinIntermedio
  };
}
exports.setBollettinoFrecciaSetCinIntermedio = setBollettinoFrecciaSetCinIntermedio;
function setBollettinoFrecciaSetCinComplesso(cinComplesso) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CIN_COMPLESSO,
    payload: cinComplesso
  };
}
exports.setBollettinoFrecciaSetCinComplesso = setBollettinoFrecciaSetCinComplesso;
function setBollettinoFrecciaSetCarattereEsenzione(carattereEsenzione) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_CARATTERE_ESENZIONE,
    payload: carattereEsenzione
  };
}
exports.setBollettinoFrecciaSetCarattereEsenzione = setBollettinoFrecciaSetCarattereEsenzione;
function setBollettinoFrecciaSetFormSubmitted(formSubmitted) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_FORM_SUBMITTED,
    payload: formSubmitted
  };
}
exports.setBollettinoFrecciaSetFormSubmitted = setBollettinoFrecciaSetFormSubmitted;
function resetBollettinoFreccia() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_RESET
  };
}
exports.resetBollettinoFreccia = resetBollettinoFreccia;
function resetRiepilogoBollettinoFreccia() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_RESET_RIEPILOGO
  };
}
exports.resetRiepilogoBollettinoFreccia = resetRiepilogoBollettinoFreccia;
function setRiepilogoRevocaBollettinoFreccia(riepilogo) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_RIEPILOGO_REVOCA,
    payload: riepilogo
  };
}
exports.setRiepilogoRevocaBollettinoFreccia = setRiepilogoRevocaBollettinoFreccia;
function setProvenienzaRevocaBollettinoFreccia(provenienza) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_SET_PROVENIENZA,
    payload: provenienza
  };
}
exports.setProvenienzaRevocaBollettinoFreccia = setProvenienzaRevocaBollettinoFreccia;
function setBollettinoFrecciaRevocaSetStepAction(i) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_STEP_REVOCA,
    payload: i
  };
}
const setBollettinoFrecciaRevocaSetStep = i => async (dispatch, _, extraArguments) => {
  dispatch(setBollettinoFrecciaRevocaSetStepAction(i));
  if (extraArguments.platform == 'mobile') {
    dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 1));
  }
};
exports.setBollettinoFrecciaRevocaSetStep = setBollettinoFrecciaRevocaSetStep;
/* get info revoca - START */
function getInfoRevocaBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_INFO_REVOCA_START
  };
}
function getInfoRevocaBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_INFO_REVOCA_SUCCESS,
    payload
  };
}
const getInfoRevocaBollettinoFreccia = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getInfoRevocaBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
      dispatch(getInfoRevocaBollettinoFrecciaSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestParameters = {
      idDispositiva,
      chiamataUscente,
      eseguiInit,
      offline: !!offline
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationRevocationInformationFreccia(requestParameters).then(response => {
        dispatch(getInfoRevocaBollettinoFrecciaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getInfoRevocaBollettinoFreccia = getInfoRevocaBollettinoFreccia;
/* get info revoca - FINE */
/* autorizza revoca - START */
function autorizzaRevocaBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_START
  };
}
function autorizzaRevocaBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_SUCCESS,
    payload
  };
}
const autorizzaRevocaBollettinoFreccia = (idDispositiva, credenziale, freccia) => async (dispatch, _, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(autorizzaRevocaBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
      dispatch(autorizzaRevocaBollettinoFrecciaSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON)(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const requestParameters = {
      frecciaRevocaRequestIn: {
        credenziale,
        freccia: {
          contoAddebito: freccia.contoAddebito,
          datiPagamento: {
            chiaveQuietanza: (_a = freccia.datiPagamento) === null || _a === void 0 ? void 0 : _a.chiaveQuietanza
          }
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationRevocationFreccia(requestParameters).then(response => {
        dispatch(autorizzaRevocaBollettinoFrecciaSuccess(response));
        extraArguments.sendEventToMatomo('BOLLETTINO_FRECCIA_REVOCA', "esito revoca dispositiva BOLLETTINO_FRECCIA_REVOCA ".concat(response.stato), 'REVOCA_DISPOSITIVA');
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'BOLLETTINO_FRECCIA_REVOCA'));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.autorizzaRevocaBollettinoFreccia = autorizzaRevocaBollettinoFreccia;
/* get bozze bollettino freccia - START */
function getBozzeBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_BOZZE_START
  };
}
function getBozzeBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_BOZZE_SUCCESS,
    payload
  };
}
const getBozzeBollettinoFreccia = request => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getBozzeBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      // dispatch(getBozzeBollettinoFrecciaSuccess(BollettinoFrecciaDtoFromJSON(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const getBozzeBollettinoFrecciaRequest = {
      bozzeInDto: request
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftListFreccia(getBozzeBollettinoFrecciaRequest).then(response => {
        dispatch(getBozzeBollettinoFrecciaSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.getBozzeBollettinoFreccia = getBozzeBollettinoFreccia;
/* get bozze bollettino freccia - FINE */
/* add bozza bollettino freccia - START */
function addBozzaBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_ADD_BOZZA_START
  };
}
function addBozzaBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_ADD_BOZZA_SUCCESS,
    payload
  };
}
const addBozzaBollettinoFreccia = () => async (dispatch, getState, extraArguments) => {
  const bollettinoFreccia = getState().bollettinoFreccia;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(addBozzaBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      // dispatch(getBozzeBollettinoFrecciaSuccess(BollettinoFrecciaDtoFromJSON(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const addBozzaBollettinoFrecciaRequest = {
      frecciaDto: {
        ...(0, bollettino_freccia_selectors_1.buildBollettinoFreccia)(bollettinoFreccia),
        bozza: {
          dataUltimaModifica: moment().toDate()
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).saveDraftFreccia(addBozzaBollettinoFrecciaRequest).then(response => {
        dispatch(addBozzaBollettinoFrecciaSuccess(response));
        dispatch((0, general_actions_1.addSnackbar)('bollettinoFreccia.nuovaBozzaBollettinoFrecciaSalvata'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
/* add bozza bollettino freccia - FINE */
/* del bozza bollettino freccia - START */
function delBozzaBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_DEL_BOZZA_START
  };
}
function delBozzaBollettinoFrecciaSuccess(id) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_DEL_BOZZA_SUCCESS,
    payload: id
  };
}
const delBozzaBollettinoFreccia = id => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(delBozzaBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      // dispatch(getBozzeBollettinoFrecciaSuccess(BollettinoFrecciaDtoFromJSON(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const delBozzaBollettinoFrecciaRequest = {
      id
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).deleteDraftFreccia(delBozzaBollettinoFrecciaRequest).then(() => {
        dispatch(delBozzaBollettinoFrecciaSuccess(id));
        dispatch((0, general_actions_1.addSnackbar)('bollettinoFreccia.bozzaEliminataConSuccesso'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
exports.delBozzaBollettinoFreccia = delBozzaBollettinoFreccia;
/* del bozza bollettino freccia - FINE */
/* update bozza bollettino freccia - START */
function updateBozzaBollettinoFrecciaStart() {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_UPDATE_BOZZA_START
  };
}
function updateBozzaBollettinoFrecciaSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_UPDATE_BOZZA_SUCCESS,
    payload
  };
}
const updateBozzaBollettinoFreccia = () => async (dispatch, getState, extraArguments) => {
  const bollettinoFreccia = getState().bollettinoFreccia;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(updateBozzaBollettinoFrecciaStart());
  if (extraArguments.mock) {
    Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
      // dispatch(getBozzeBollettinoFrecciaSuccess(BollettinoFrecciaDtoFromJSON(parametri)));
      dispatch((0, general_actions_1.setLoader)(false));
    });
  } else {
    const idCurrentBozza = bollettinoFreccia.currentBozza.id || 0;
    const updateBozzaBollettinoFrecciaRequest = {
      id: idCurrentBozza,
      frecciaDto: {
        id: idCurrentBozza,
        ...(0, bollettino_freccia_selectors_1.buildBollettinoFreccia)(bollettinoFreccia),
        bozza: {
          dataUltimaModifica: moment().toDate()
        }
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FrecciaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateDraftFreccia(updateBozzaBollettinoFrecciaRequest).then(response => {
        dispatch(updateBozzaBollettinoFrecciaSuccess(response));
        dispatch((0, general_actions_1.addSnackbar)('bollettinoFreccia.bozzaBollettinoFrecciaAggiornata'));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
};
/* update bozza bollettino freccia - FINE */
function getBollettinoFrecciaFromBozza(payload) {
  let noBozza = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return {
    type: __1.EReduxActionTypes.BOLLETTINO_FRECCIA_GET_FROM_BOZZA,
    payload,
    noBozza
  };
}
exports.getBollettinoFrecciaFromBozza = getBollettinoFrecciaFromBozza;
const shouldUpdateBozzaBollettinoFreccia = () => async (dispatch, getState, extraArguments) => {
  const state = getState().bollettinoFreccia;
  if ((0, bollettino_freccia_selectors_1.checkUpdateBozzaBollettinoFreccia)(state)) {
    dispatch(updateBozzaBollettinoFreccia());
  } else {
    dispatch(addBozzaBollettinoFreccia());
  }
};
exports.shouldUpdateBozzaBollettinoFreccia = shouldUpdateBozzaBollettinoFreccia;