"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.i18nReducer = void 0;
const __1 = require("..");
const helpers_1 = require("../helpers");
const i18nMobileResources = require("./i18n_mobile.json");
const i18nWebResources = require("./i18n_web.json");
const initialState = {
  currentLanguage: 'it',
  resources: {},
  multilinguaGenesys: ''
};
const i18nReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.I18N_INIT:
      const i18nResources = action.payload === 'mobile' ? i18nMobileResources : i18nWebResources;
      const keys = Object.keys(i18nResources);
      const i18nFlatResources = {};
      keys.forEach(key => {
        const i18nResource = i18nResources[key];
        i18nFlatResources[key + '_offline'] = (0, helpers_1.flattenObject)(i18nResource);
      });
      return {
        ...state,
        resources: i18nFlatResources
      };
    case __1.EReduxActionTypes.I18N_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.I18N_SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload
      };
    case __1.EReduxActionTypes.I18N_SET_RESOURCES:
      return {
        ...state,
        resources: action.payload
      };
    case __1.EReduxActionTypes.I18N_GET_MULTILINGUA_GENESYS_SUCCESS:
      return {
        ...state,
        multilinguaGenesys: action.payload
      };
    case __1.EReduxActionTypes.I18N_GET_MULTILINGUA_SUCCESS:
      let _resources = state.resources;
      _resources[state.currentLanguage] = action.payload.messaggi;
      return {
        ...state,
        resources: {
          ...state.resources,
          ..._resources
        }
      };
    default:
      return state;
  }
};
exports.i18nReducer = i18nReducer;
exports.default = exports.i18nReducer;