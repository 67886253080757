"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EseguitiResponseDtoToJSON = exports.EseguitiResponseDtoFromJSONTyped = exports.EseguitiResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EseguitiResponseDtoFromJSON(json) {
  return EseguitiResponseDtoFromJSONTyped(json, false);
}
exports.EseguitiResponseDtoFromJSON = EseguitiResponseDtoFromJSON;
function EseguitiResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'dossier': !runtime_1.exists(json, 'dossier') ? undefined : json['dossier'],
    'ordine': !runtime_1.exists(json, 'ordine') ? undefined : _1.OrdineDtoFromJSON(json['ordine']),
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'esitiOperaziones': !runtime_1.exists(json, 'esitiOperaziones') ? undefined : json['esitiOperaziones'].map(_1.EsitiOperazioneDtoFromJSON)
  };
}
exports.EseguitiResponseDtoFromJSONTyped = EseguitiResponseDtoFromJSONTyped;
function EseguitiResponseDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'intestazione': value.intestazione,
    'dossier': value.dossier,
    'ordine': _1.OrdineDtoToJSON(value.ordine),
    'codiceRapporto': value.codiceRapporto,
    'esitiOperaziones': value.esitiOperaziones === undefined ? undefined : value.esitiOperaziones.map(_1.EsitiOperazioneDtoToJSON)
  };
}
exports.EseguitiResponseDtoToJSON = EseguitiResponseDtoToJSON;