"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EBonificoVersamentoDepositoTypes = exports.FormRevocaBonificoVersamentoDepositoDtoFromJSON = exports.FormBonificoVersamentoDepositoDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormBonificoVersamentoDepositoDtoFromJSONTyped(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
    'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
    'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
    'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
    'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
    'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
    'causale': !(0, helpers_1.exists)(json, 'causale') ? undefined : json.causale,
    'causaleDirty': !(0, helpers_1.exists)(json, 'causaleDirty') ? false : json.causaleDirty,
    'causaleError': !(0, helpers_1.exists)(json, 'causaleError') ? 'invalid.required' : json.causaleError,
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'intestatario': !(0, helpers_1.exists)(json, 'intestatario') ? undefined : json.intestatario,
    'intestatarioDirty': !(0, helpers_1.exists)(json, 'intestatarioDirty') ? false : json.intestatarioDirty,
    'intestatarioError': !(0, helpers_1.exists)(json, 'intestatarioError') ? 'invalid.required' : json.intestatarioError,
    'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
    'contoAccredito': !(0, helpers_1.exists)(json, 'contoAccredito') ? (0, api_rest_1.RapportoGirofondoDtoFromJSON)({}) : json.contoAccredito,
    'contoAccreditoDirty': !(0, helpers_1.exists)(json, 'contoAccreditoDirty') ? false : json.contoAccreditoDirty,
    'contoAccreditoError': !(0, helpers_1.exists)(json, 'contoAccredito') ? 'invalid.required' : json.contoAccredito,
    'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti
  };
}
exports.FormBonificoVersamentoDepositoDtoFromJSONTyped = FormBonificoVersamentoDepositoDtoFromJSONTyped;
function FormRevocaBonificoVersamentoDepositoDtoFromJSON(json) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
    'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
  };
}
exports.FormRevocaBonificoVersamentoDepositoDtoFromJSON = FormRevocaBonificoVersamentoDepositoDtoFromJSON;
var EBonificoVersamentoDepositoTypes;
(function (EBonificoVersamentoDepositoTypes) {
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_START"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_START";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_SUCCESS"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_SUCCESS";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_ERROR"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_ERROR";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_START"] = "BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_START";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_SUCCESS"] = "BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_SUCCESS";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_ERROR"] = "BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_ERROR";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_START"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_START";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_SUCCESS"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_SUCCESS";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_ERROR"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_ERROR";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_START"] = "BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_START";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_SUCCESS"] = "BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_SUCCESS";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_ERROR"] = "BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_ERROR";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_RESET"] = "BONIFICO_VERSAMENTO_DEPOSITO_RESET";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_RESET_RIEPILOGO"] = "BONIFICO_VERSAMENTO_DEPOSITO_RESET_RIEPILOGO";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_CONTO_ACCREDITO"] = "BONIFICO_VERSAMENTO_DEPOSITO_CONTO_ACCREDITO";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_IMPORTO"] = "BONIFICO_VERSAMENTO_DEPOSITO_IMPORTO";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_DATA_ESECUZIONE"] = "BONIFICO_VERSAMENTO_DEPOSITO_DATA_ESECUZIONE";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_CAUSALE"] = "BONIFICO_VERSAMENTO_DEPOSITO_CAUSALE";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_STEP"] = "BONIFICO_VERSAMENTO_DEPOSITO_STEP";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_INTESTATARIO"] = "BONIFICO_VERSAMENTO_DEPOSITO_INTESTATARIO";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_SET_RAPPORTO_SELEZIONATO"] = "BONIFICO_VERSAMENTO_DEPOSITO_SET_RAPPORTO_SELEZIONATO";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_FORM_SUBMITTED"] = "BONIFICO_VERSAMENTO_DEPOSITO_FORM_SUBMITTED";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_SET_RIEPILOGO_REVOCA"] = "BONIFICO_VERSAMENTO_DEPOSITO_SET_RIEPILOGO_REVOCA";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_SET_PROVENIENZA"] = "BONIFICO_VERSAMENTO_DEPOSITO_SET_PROVENIENZA";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_STEP_REVOCA"] = "BONIFICO_VERSAMENTO_DEPOSITO_STEP_REVOCA";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_START"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_START";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_SUCCESS"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_SUCCESS";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_ERROR"] = "BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_ERROR";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_START"] = "BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_START";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_SUCCESS"] = "BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_SUCCESS";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_ERROR"] = "BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_ERROR";
  EBonificoVersamentoDepositoTypes["BONIFICO_VERSAMENTO_DEPOSITO_RESET_ESITO"] = "BONIFICO_VERSAMENTO_DEPOSITO_RESET_ESITO";
})(EBonificoVersamentoDepositoTypes = exports.EBonificoVersamentoDepositoTypes || (exports.EBonificoVersamentoDepositoTypes = {}));