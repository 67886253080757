"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatiPagamentoDtoToJSON = exports.DatiPagamentoDtoFromJSONTyped = exports.DatiPagamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiPagamentoDtoFromJSON(json) {
  return DatiPagamentoDtoFromJSONTyped(json, false);
}
exports.DatiPagamentoDtoFromJSON = DatiPagamentoDtoFromJSON;
function DatiPagamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idProgressivo': !runtime_1.exists(json, 'idProgressivo') ? undefined : json['idProgressivo'],
    'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
    'identificativoPagamento': !runtime_1.exists(json, 'identificativoPagamento') ? undefined : json['identificativoPagamento'],
    'progressivoInserimento': !runtime_1.exists(json, 'progressivoInserimento') ? undefined : json['progressivoInserimento'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : new Date(json['dataContabile']),
    'dataPrenotazione': !runtime_1.exists(json, 'dataPrenotazione') ? undefined : new Date(json['dataPrenotazione']),
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : new Date(json['dataScadenza']),
    'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : new Date(json['dataValuta']),
    'dataRicerca': !runtime_1.exists(json, 'dataRicerca') ? undefined : new Date(json['dataRicerca']),
    'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    'uscite': !runtime_1.exists(json, 'uscite') ? undefined : json['uscite'],
    'usciteEuro': !runtime_1.exists(json, 'usciteEuro') ? undefined : json['usciteEuro'],
    'annoInserimento': !runtime_1.exists(json, 'annoInserimento') ? undefined : json['annoInserimento'],
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    'sottoTipo': !runtime_1.exists(json, 'sottoTipo') ? undefined : json['sottoTipo'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'dettaglio': !runtime_1.exists(json, 'dettaglio') ? undefined : _1.DettaglioPagamentoDtoFromJSON(json['dettaglio']),
    'provenienzaMovimento': !runtime_1.exists(json, 'provenienzaMovimento') ? undefined : json['provenienzaMovimento'],
    'codiceTransazione': !runtime_1.exists(json, 'codiceTransazione') ? undefined : json['codiceTransazione'],
    'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    'dataInvio': !runtime_1.exists(json, 'dataInvio') ? undefined : new Date(json['dataInvio']),
    'progressivoDelegaInvio': !runtime_1.exists(json, 'progressivoDelegaInvio') ? undefined : json['progressivoDelegaInvio'],
    'progressivoInvio': !runtime_1.exists(json, 'progressivoInvio') ? undefined : json['progressivoInvio'],
    'beneficiario': !runtime_1.exists(json, 'beneficiario') ? undefined : json['beneficiario'],
    'rapportoEstinto': !runtime_1.exists(json, 'rapportoEstinto') ? undefined : json['rapportoEstinto'],
    'ibanRapportoEstinto': !runtime_1.exists(json, 'ibanRapportoEstinto') ? undefined : json['ibanRapportoEstinto']
  };
}
exports.DatiPagamentoDtoFromJSONTyped = DatiPagamentoDtoFromJSONTyped;
function DatiPagamentoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idProgressivo': value.idProgressivo,
    'canale': value.canale,
    'identificativoPagamento': value.identificativoPagamento,
    'progressivoInserimento': value.progressivoInserimento,
    'codiceRapporto': value.codiceRapporto,
    'dataContabile': value.dataContabile === undefined ? undefined : value.dataContabile.toISOString(),
    'dataPrenotazione': value.dataPrenotazione === undefined ? undefined : value.dataPrenotazione.toISOString(),
    'dataScadenza': value.dataScadenza === undefined ? undefined : value.dataScadenza.toISOString(),
    'dataValuta': value.dataValuta === undefined ? undefined : value.dataValuta.toISOString(),
    'dataRicerca': value.dataRicerca === undefined ? undefined : value.dataRicerca.toISOString(),
    'stato': value.stato,
    'uscite': value.uscite,
    'usciteEuro': value.usciteEuro,
    'annoInserimento': value.annoInserimento,
    'tipo': value.tipo,
    'sottoTipo': value.sottoTipo,
    'divisa': value.divisa,
    'dettaglio': _1.DettaglioPagamentoDtoToJSON(value.dettaglio),
    'provenienzaMovimento': value.provenienzaMovimento,
    'codiceTransazione': value.codiceTransazione,
    'codiceFiscale': value.codiceFiscale,
    'dataInvio': value.dataInvio === undefined ? undefined : value.dataInvio.toISOString(),
    'progressivoDelegaInvio': value.progressivoDelegaInvio,
    'progressivoInvio': value.progressivoInvio,
    'beneficiario': value.beneficiario,
    'rapportoEstinto': value.rapportoEstinto,
    'ibanRapportoEstinto': value.ibanRapportoEstinto
  };
}
exports.DatiPagamentoDtoToJSON = DatiPagamentoDtoToJSON;