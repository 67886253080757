"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildFiltriPagamentiContoTerzi = exports.buildPagamentoTributiRichiesta = exports.filtriTributiLocaliValid = exports.filtriTributiLocali = exports.esitoTributiLocali = exports.infoAutenticazioneTributiLocali = exports.riepilogoTributiLocali = exports.formTributiLocali = exports.parametriTributiLocali = exports.dettaglioTributoLocale = exports.notificheTributiLocali = exports.elencoTributiContoTerzi = exports.elencoTributiInScadenza = void 0;
const reselect_1 = require("reselect");
const tributiLocaliReducer = state => state;
exports.elencoTributiInScadenza = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.elencoTributiInScadenza;
});
exports.elencoTributiContoTerzi = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.elencoTributiContoTerzi;
});
exports.notificheTributiLocali = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.notificaTributi;
});
exports.dettaglioTributoLocale = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.dettaglioTributo;
});
exports.parametriTributiLocali = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.parametri;
});
exports.formTributiLocali = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.form;
});
exports.riepilogoTributiLocali = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.riepilogo;
});
exports.infoAutenticazioneTributiLocali = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.infoAutenticazione;
});
exports.esitoTributiLocali = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.esito;
});
exports.filtriTributiLocali = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.filter;
});
exports.filtriTributiLocaliValid = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  return state.filter.codiceFiscaleContribuenteError === '' && state.filter.codicePagamentoError === '';
});
exports.buildPagamentoTributiRichiesta = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  var _a, _b, _c;
  const richiestaPagamentoTributi = {
    codiceFiscale: state.dettaglioTributo.tipoDebitore === 'F' ? state.dettaglioTributo.codFiscaleF : state.dettaglioTributo.codFiscaleG,
    codicePagamento: state.dettaglioTributo.codPagamento,
    codiceRapporto: (_c = (_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0
  };
  return richiestaPagamentoTributi;
});
exports.buildFiltriPagamentiContoTerzi = (0, reselect_1.createSelector)(tributiLocaliReducer, state => {
  let richiestaPagamentoTributi = {
    codicePagamento: state.filter.codicePagamento,
    codiceFiscale: state.filter.codiceFiscaleContribuente
  };
  return richiestaPagamentoTributi;
});