"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showModalNetInsurance = exports.netInsurance = void 0;
const reselect_1 = require("reselect");
const notificePushReducer = state => state;
exports.netInsurance = (0, reselect_1.createSelector)(notificePushReducer, state => {
  return state.netInsurance;
});
exports.showModalNetInsurance = (0, reselect_1.createSelector)(notificePushReducer, state => {
  return state.showModal;
});