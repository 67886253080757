"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DettaglioInformativaCofidisDtoToJSON = exports.DettaglioInformativaCofidisDtoFromJSONTyped = exports.DettaglioInformativaCofidisDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DettaglioInformativaCofidisDtoFromJSON(json) {
  return DettaglioInformativaCofidisDtoFromJSONTyped(json, false);
}
exports.DettaglioInformativaCofidisDtoFromJSON = DettaglioInformativaCofidisDtoFromJSON;
function DettaglioInformativaCofidisDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'importoErogato': !runtime_1.exists(json, 'importoErogato') ? undefined : json['importoErogato'],
    'debitoResiduo': !runtime_1.exists(json, 'debitoResiduo') ? undefined : json['debitoResiduo'],
    'numeroTotaleRate': !runtime_1.exists(json, 'numeroTotaleRate') ? undefined : json['numeroTotaleRate'],
    'importoCut': !runtime_1.exists(json, 'importoCut') ? undefined : json['importoCut'],
    'importoRata': !runtime_1.exists(json, 'importoRata') ? undefined : json['importoRata'],
    'speseIstruttoria': !runtime_1.exists(json, 'speseIstruttoria') ? undefined : json['speseIstruttoria'],
    'tassoTAEN': !runtime_1.exists(json, 'tassoTAEN') ? undefined : json['tassoTAEN'],
    'tassoTAEG': !runtime_1.exists(json, 'tassoTAEG') ? undefined : json['tassoTAEG'],
    'dataApertura': !runtime_1.exists(json, 'dataApertura') ? undefined : json['dataApertura'],
    'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : json['dataScadenza'],
    'canaleVendita': !runtime_1.exists(json, 'canaleVendita') ? undefined : json['canaleVendita'],
    'tipologiaPrestito': !runtime_1.exists(json, 'tipologiaPrestito') ? undefined : json['tipologiaPrestito'],
    'statoPratica': !runtime_1.exists(json, 'statoPratica') ? undefined : json['statoPratica']
  };
}
exports.DettaglioInformativaCofidisDtoFromJSONTyped = DettaglioInformativaCofidisDtoFromJSONTyped;
function DettaglioInformativaCofidisDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'importoErogato': value.importoErogato,
    'debitoResiduo': value.debitoResiduo,
    'numeroTotaleRate': value.numeroTotaleRate,
    'importoCut': value.importoCut,
    'importoRata': value.importoRata,
    'speseIstruttoria': value.speseIstruttoria,
    'tassoTAEN': value.tassoTAEN,
    'tassoTAEG': value.tassoTAEG,
    'dataApertura': value.dataApertura,
    'dataScadenza': value.dataScadenza,
    'canaleVendita': value.canaleVendita,
    'tipologiaPrestito': value.tipologiaPrestito,
    'statoPratica': value.statoPratica
  };
}
exports.DettaglioInformativaCofidisDtoToJSON = DettaglioInformativaCofidisDtoToJSON;