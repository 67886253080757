"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RicaricaCellulareDtoToJSON = exports.RicaricaCellulareDtoFromJSONTyped = exports.RicaricaCellulareDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RicaricaCellulareDtoFromJSON(json) {
  return RicaricaCellulareDtoFromJSONTyped(json, false);
}
exports.RicaricaCellulareDtoFromJSON = RicaricaCellulareDtoFromJSON;
function RicaricaCellulareDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
    'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
    'rrn': !runtime_1.exists(json, 'rrn') ? undefined : json['rrn'],
    'codiceIstituto': !runtime_1.exists(json, 'codiceIstituto') ? undefined : json['codiceIstituto'],
    'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'taglioRicarica': !runtime_1.exists(json, 'taglioRicarica') ? undefined : json['taglioRicarica'],
    'totaleAddebito': !runtime_1.exists(json, 'totaleAddebito') ? undefined : json['totaleAddebito'],
    'commissioniServizio': !runtime_1.exists(json, 'commissioniServizio') ? undefined : json['commissioniServizio'],
    'commissioniOperazione': !runtime_1.exists(json, 'commissioniOperazione') ? undefined : json['commissioniOperazione'],
    'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : json['condivisione'].map(_1.CondivisioneDtoFromJSON),
    'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : new Date(json['dataContabile']),
    'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : new Date(json['dataValuta']),
    'numeroCellulare': !runtime_1.exists(json, 'numeroCellulare') ? undefined : json['numeroCellulare'],
    'idContattoBeneficiario': !runtime_1.exists(json, 'idContattoBeneficiario') ? undefined : json['idContattoBeneficiario'],
    'nomeBeneficiario': !runtime_1.exists(json, 'nomeBeneficiario') ? undefined : json['nomeBeneficiario'],
    'statoRicarica': !runtime_1.exists(json, 'statoRicarica') ? undefined : json['statoRicarica'],
    'nomeGestore': !runtime_1.exists(json, 'nomeGestore') ? undefined : json['nomeGestore'],
    'codiceSia': !runtime_1.exists(json, 'codiceSia') ? undefined : json['codiceSia'],
    'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
    'annoRrn': !runtime_1.exists(json, 'annoRrn') ? undefined : json['annoRrn'],
    'dataOraRisposta': !runtime_1.exists(json, 'dataOraRisposta') ? undefined : new Date(json['dataOraRisposta']),
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'idOperazione': !runtime_1.exists(json, 'idOperazione') ? undefined : json['idOperazione'],
    'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
    'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
    'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
    'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
    'alias1Click': !runtime_1.exists(json, 'alias1Click') ? undefined : json['alias1Click'],
    'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
    'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
    'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
    'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
    'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport']
  };
}
exports.RicaricaCellulareDtoFromJSONTyped = RicaricaCellulareDtoFromJSONTyped;
function RicaricaCellulareDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'id': value.id,
    'bozza': _1.BozzaDtoToJSON(value.bozza),
    'inviato': _1.InviatoDtoToJSON(value.inviato),
    'rrn': value.rrn,
    'codiceIstituto': value.codiceIstituto,
    'codiceUtente': value.codiceUtente,
    'codiceRapporto': value.codiceRapporto,
    'taglioRicarica': value.taglioRicarica,
    'totaleAddebito': value.totaleAddebito,
    'commissioniServizio': value.commissioniServizio,
    'commissioniOperazione': value.commissioniOperazione,
    'condivisione': value.condivisione === undefined ? undefined : value.condivisione.map(_1.CondivisioneDtoToJSON),
    'dataContabile': value.dataContabile === undefined ? undefined : value.dataContabile.toISOString(),
    'dataValuta': value.dataValuta === undefined ? undefined : value.dataValuta.toISOString(),
    'numeroCellulare': value.numeroCellulare,
    'idContattoBeneficiario': value.idContattoBeneficiario,
    'nomeBeneficiario': value.nomeBeneficiario,
    'statoRicarica': value.statoRicarica,
    'nomeGestore': value.nomeGestore,
    'codiceSia': value.codiceSia,
    'idBozzaCollegata': value.idBozzaCollegata,
    'annoRrn': value.annoRrn,
    'dataOraRisposta': value.dataOraRisposta === undefined ? undefined : value.dataOraRisposta.toISOString(),
    'divisa': value.divisa,
    'idOperazione': value.idOperazione,
    'idOneClickCollegata': value.idOneClickCollegata,
    'aggiungiRubrica': value.aggiungiRubrica,
    'aggiungi1click': value.aggiungi1click,
    'aggiungiWhitelist': value.aggiungiWhitelist,
    'alias1Click': value.alias1Click,
    'contoAddebitoIban': value.contoAddebitoIban,
    'contoAddebitoAlias': value.contoAddebitoAlias,
    'ordinanteIntestazione': value.ordinanteIntestazione,
    'alias': value.alias,
    'statoReport': value.statoReport
  };
}
exports.RicaricaCellulareDtoToJSON = RicaricaCellulareDtoToJSON;