"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MovimentoDtoFlagMovimentoGiornoEnum = exports.MovimentoDtoToJSON = exports.MovimentoDtoFromJSONTyped = exports.MovimentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MovimentoDtoFromJSON(json) {
  return MovimentoDtoFromJSONTyped(json, false);
}
exports.MovimentoDtoFromJSON = MovimentoDtoFromJSON;
function MovimentoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : new Date(json['dataContabile']),
    'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : new Date(json['dataValuta']),
    'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    'numeroOperazione': !runtime_1.exists(json, 'numeroOperazione') ? undefined : json['numeroOperazione'],
    'descrizioneAggiuntiva': !runtime_1.exists(json, 'descrizioneAggiuntiva') ? undefined : json['descrizioneAggiuntiva'],
    'descrizioneOriginale': !runtime_1.exists(json, 'descrizioneOriginale') ? undefined : json['descrizioneOriginale'],
    'totale': !runtime_1.exists(json, 'totale') ? undefined : json['totale'],
    'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno'],
    'descBanca': !runtime_1.exists(json, 'descBanca') ? undefined : json['descBanca'],
    'categoriaPfm': !runtime_1.exists(json, 'categoriaPfm') ? undefined : _1.CategoryDtoFromJSON(json['categoriaPfm']),
    'rapportoLight': !runtime_1.exists(json, 'rapportoLight') ? undefined : _1.RapportoLightDtoFromJSON(json['rapportoLight']),
    'tags': !runtime_1.exists(json, 'tags') ? undefined : json['tags'],
    'strumento': !runtime_1.exists(json, 'strumento') ? undefined : json['strumento'],
    'comparto': !runtime_1.exists(json, 'comparto') ? undefined : json['comparto'],
    'tipoCategoria': !runtime_1.exists(json, 'tipoCategoria') ? undefined : json['tipoCategoria'],
    'escluso': !runtime_1.exists(json, 'escluso') ? undefined : json['escluso'],
    'ruleId': !runtime_1.exists(json, 'ruleId') ? undefined : json['ruleId'],
    'debito': !runtime_1.exists(json, 'debito') ? undefined : json['debito'],
    'tipologiaMovimento': !runtime_1.exists(json, 'tipologiaMovimento') ? undefined : json['tipologiaMovimento'],
    'nativeId': !runtime_1.exists(json, 'nativeId') ? undefined : json['nativeId'],
    'flagMovimentoGiorno': !runtime_1.exists(json, 'flagMovimentoGiorno') ? undefined : json['flagMovimentoGiorno'],
    'servizioProvenienza': !runtime_1.exists(json, 'servizioProvenienza') ? undefined : json['servizioProvenienza'],
    'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
    'movNonContabilizzato': !runtime_1.exists(json, 'movNonContabilizzato') ? undefined : json['movNonContabilizzato'],
    'alertPsd2': !runtime_1.exists(json, 'alertPsd2') ? undefined : _1.AlertPsd2DtoFromJSON(json['alertPsd2']),
    'psd2': !runtime_1.exists(json, 'psd2') ? undefined : _1.AttributiMovimentiPsd2FromJSON(json['psd2']),
    'attributiPsd2': !runtime_1.exists(json, 'attributiPsd2') ? undefined : _1.AttributiMovimentiPsd2FromJSON(json['attributiPsd2'])
  };
}
exports.MovimentoDtoFromJSONTyped = MovimentoDtoFromJSONTyped;
function MovimentoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'dataContabile': value.dataContabile === undefined ? undefined : value.dataContabile.toISOString(),
    'dataValuta': value.dataValuta === undefined ? undefined : value.dataValuta.toISOString(),
    'tipo': value.tipo,
    'descrizione': value.descrizione,
    'numeroOperazione': value.numeroOperazione,
    'descrizioneAggiuntiva': value.descrizioneAggiuntiva,
    'descrizioneOriginale': value.descrizioneOriginale,
    'totale': value.totale,
    'segno': value.segno,
    'descBanca': value.descBanca,
    'categoriaPfm': _1.CategoryDtoToJSON(value.categoriaPfm),
    'rapportoLight': _1.RapportoLightDtoToJSON(value.rapportoLight),
    'tags': value.tags,
    'strumento': value.strumento,
    'comparto': value.comparto,
    'tipoCategoria': value.tipoCategoria,
    'escluso': value.escluso,
    'ruleId': value.ruleId,
    'debito': value.debito,
    'tipologiaMovimento': value.tipologiaMovimento,
    'nativeId': value.nativeId,
    'flagMovimentoGiorno': value.flagMovimentoGiorno,
    'servizioProvenienza': value.servizioProvenienza,
    'causale': value.causale,
    'movNonContabilizzato': value.movNonContabilizzato,
    'alertPsd2': _1.AlertPsd2DtoToJSON(value.alertPsd2),
    'psd2': _1.AttributiMovimentiPsd2ToJSON(value.psd2),
    'attributiPsd2': _1.AttributiMovimentiPsd2ToJSON(value.attributiPsd2)
  };
}
exports.MovimentoDtoToJSON = MovimentoDtoToJSON;
/**
* @export
* @enum {string}
*/
var MovimentoDtoFlagMovimentoGiornoEnum;
(function (MovimentoDtoFlagMovimentoGiornoEnum) {
  MovimentoDtoFlagMovimentoGiornoEnum["S"] = "S";
  MovimentoDtoFlagMovimentoGiornoEnum["N"] = "N";
})(MovimentoDtoFlagMovimentoGiornoEnum = exports.MovimentoDtoFlagMovimentoGiornoEnum || (exports.MovimentoDtoFlagMovimentoGiornoEnum = {}));