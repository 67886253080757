"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adeguataVerificaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const adeguataVerifica_types_1 = require("./adeguataVerifica.types");
const moment = require("moment");
const initialState = {
  parametri: (0, api_rest_1.ParametriAdeguataVerificaDtoFromJSON)({}),
  dati: (0, api_rest_1.DatiAdeguataVerificaDtoFromJSON)({}),
  form: (0, adeguataVerifica_types_1.FormADVDtoFromJSONTyped)({}),
  formDatiResidenza: (0, adeguataVerifica_types_1.FormDatiResidenzaADVDtoFromJSONTyped)({}),
  formDocumentoIdentita: (0, adeguataVerifica_types_1.FormDatiDocumentoIdentitaADVDTOFromJSONTyped)({}),
  formScopiRapporti: (0, adeguataVerifica_types_1.FormADVScopiRapportiFromJsonTyped)({}),
  formDatiReddituali: (0, adeguataVerifica_types_1.FormDatiRedditoPatrimonialiADVDtoFromJSONTyped)({}),
  esitoModificaDocumento: (0, api_rest_1.ApriPraticaOutDtoFromJSON)({}),
  riepilogoDatiDocumento: (0, api_rest_1.CaricaDocumentoIdentitaOutDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.DatiAdeguataVerificaDtoFromJSON)({}),
  documentoModificato: false,
  esitoCaricamentoDocumento: (0, api_rest_1.CaricaDocumentoIdentitaOutDtoFromJSON)({})
};
const adeguataVerificaReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48;
  switch (action.type) {
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_GET_PARAMETRI_SUCCESS:
      return {
        ...state,
        parametri: action.payload
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_GET_DATI_SUCCESS:
      return {
        ...state,
        dati: action.payload
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_FORM_RESIDENZA_SUBMITTED:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          formSubmitted: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA_PROVINCIA:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          provinciaResidenza: action.payload,
          provinciaResidenzaDirty: true,
          provinciaResidenzaError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA_COMUNE:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          comuneResidenza: action.payload,
          comuneResidenzaDirty: true,
          comuneResidenzaError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA_LOCALITA:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          localitaResidenza: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA_CAP:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          capResidenza: action.payload,
          capResidenzaDirty: true,
          capResidenzaError: (0, helpers_1.validationField)(action.payload, '', false, 5, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA_TIPO_VIA:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          tipoViaResidenza: action.payload,
          tipoViaResidenzaDirty: true,
          tipoViaResidenzaError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA_INDIRIZZO:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          indirizzoResidenza: action.payload,
          indirizzoResidenzaDirty: true,
          indirizzoResidenzaError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA_NCIVICO:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          numeroCivicoResidenza: action.payload,
          numeroCivicoResidenzaDirty: true,
          numeroCivicoResidenzaError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOMICILIO_PROVINCIA:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          provinciaDomicilio: action.payload,
          provinciaDomicilioDirty: true,
          provinciaDomicilioError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOMICILIO_COMUNE:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          comuneDomicilio: action.payload,
          comuneDomicilioDirty: true,
          comuneDomicilioError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOMICILIO_LOCALITA:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          localitaDomicilio: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOMICILIO_CAP:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          capDomicilio: action.payload,
          capDomicilioDirty: true,
          capDomicilioError: (0, helpers_1.validationField)(action.payload, '', false, 5, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOMICILIO_TIPO_VIA:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          tipoViaDomicilio: action.payload,
          tipoViaDomicilioDirty: true,
          tipoViaDomicilioError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOMICILIO_INDIRIZZO:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          indirizzoDomicilio: action.payload,
          indirizzoDomicilioDirty: true,
          indirizzoDomicilioError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOMICILIO_NCIVICO:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          numeroCivicoDomicilio: action.payload,
          numeroCivicoDomicilioDirty: true,
          numeroCivicoDomicilioError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_RESIDENZA:
      const datiResidenza = (_a = state.dati) === null || _a === void 0 ? void 0 : _a.indirizzoResidenza;
      const datiDomicilio = (_b = state.dati) === null || _b === void 0 ? void 0 : _b.indirizzoDomicilio;
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          provinciaResidenza: (datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.provincia) || '',
          provinciaResidenzaDirty: !!(datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.provincia),
          provinciaResidenzaError: (0, helpers_1.validationField)(datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.provincia, '', false, undefined, undefined, true),
          comuneResidenza: (datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.comune) || '',
          comuneResidenzaDirty: !!(datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.comune),
          comuneResidenzaError: (0, helpers_1.validationField)(datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.comune, '', false, undefined, undefined, true),
          localitaResidenza: (datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.localita) || '',
          localitaResidenzaDirty: !!(datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.localita),
          localitaResidenzaError: (0, helpers_1.validationField)(datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.localita, '', false, undefined, undefined, false),
          tipoViaResidenza: ((_c = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _c === void 0 ? void 0 : _c.tipo) || '',
          tipoViaResidenzaDirty: !!((_d = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _d === void 0 ? void 0 : _d.tipo),
          tipoViaResidenzaError: (0, helpers_1.validationField)((_e = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _e === void 0 ? void 0 : _e.tipo, '', false, undefined, undefined, true),
          indirizzoResidenza: ((_f = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _f === void 0 ? void 0 : _f.nome) || '',
          indirizzoResidenzaDirty: !!((_g = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _g === void 0 ? void 0 : _g.nome),
          indirizzoResidenzaError: (0, helpers_1.validationField)((_h = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _h === void 0 ? void 0 : _h.nome, '', false, undefined, undefined, true),
          numeroCivicoResidenza: ((_j = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _j === void 0 ? void 0 : _j.numeroDomicilio) || '',
          numeroCivicoResidenzaDirty: !!((_k = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _k === void 0 ? void 0 : _k.numeroDomicilio),
          numeroCivicoResidenzaError: (0, helpers_1.validationField)((_l = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.via) === null || _l === void 0 ? void 0 : _l.numeroDomicilio, '', false, undefined, undefined, true),
          capResidenza: ((_m = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.cap) === null || _m === void 0 ? void 0 : _m.toString()) || '',
          capResidenzaDirty: !!(datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.cap),
          capResidenzaError: (0, helpers_1.validationField)((_o = datiResidenza === null || datiResidenza === void 0 ? void 0 : datiResidenza.cap) === null || _o === void 0 ? void 0 : _o.toString(), '', false, 5, undefined, true),
          provinciaDomicilio: ((_p = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _p === void 0 ? void 0 : _p.provincia) || '',
          provinciaDomicilioDirty: !!((_q = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _q === void 0 ? void 0 : _q.provincia),
          provinciaDomicilioError: (0, helpers_1.validationField)((_r = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _r === void 0 ? void 0 : _r.provincia, '', false, undefined, undefined, true),
          comuneDomicilio: ((_s = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _s === void 0 ? void 0 : _s.comune) || '',
          comuneDomicilioDirty: !!((_t = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _t === void 0 ? void 0 : _t.comune),
          comuneDomicilioError: (0, helpers_1.validationField)((_u = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _u === void 0 ? void 0 : _u.comune, '', false, undefined, undefined, true),
          localitaDomicilio: ((_v = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _v === void 0 ? void 0 : _v.localita) || '',
          localitaDomicilioDirty: !!((_w = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _w === void 0 ? void 0 : _w.localita),
          localitaDomicilioError: (0, helpers_1.validationField)((_x = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _x === void 0 ? void 0 : _x.localita, ''),
          tipoViaDomicilio: ((_z = (_y = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _y === void 0 ? void 0 : _y.via) === null || _z === void 0 ? void 0 : _z.tipo) || '',
          tipoViaDomicilioDirty: !!((_1 = (_0 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _0 === void 0 ? void 0 : _0.via) === null || _1 === void 0 ? void 0 : _1.tipo),
          tipoViaDomicilioError: (0, helpers_1.validationField)((_3 = (_2 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _2 === void 0 ? void 0 : _2.via) === null || _3 === void 0 ? void 0 : _3.tipo, '', false, undefined, undefined, true),
          indirizzoDomicilio: ((_5 = (_4 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _4 === void 0 ? void 0 : _4.via) === null || _5 === void 0 ? void 0 : _5.nome) || '',
          indirizzoDomicilioDirty: !!((_7 = (_6 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _6 === void 0 ? void 0 : _6.via) === null || _7 === void 0 ? void 0 : _7.nome),
          indirizzoDomicilioError: (0, helpers_1.validationField)((_9 = (_8 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _8 === void 0 ? void 0 : _8.via) === null || _9 === void 0 ? void 0 : _9.nome, '', false, undefined, undefined, true),
          numeroCivicoDomicilio: ((_11 = (_10 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _10 === void 0 ? void 0 : _10.via) === null || _11 === void 0 ? void 0 : _11.numeroDomicilio) || '',
          numeroCivicoDomicilioDirty: !!((_13 = (_12 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _12 === void 0 ? void 0 : _12.via) === null || _13 === void 0 ? void 0 : _13.numeroDomicilio),
          numeroCivicoDomicilioError: (0, helpers_1.validationField)((_15 = (_14 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _14 === void 0 ? void 0 : _14.via) === null || _15 === void 0 ? void 0 : _15.numeroDomicilio, '', false, undefined, undefined, true),
          capDomicilio: ((_17 = (_16 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _16 === void 0 ? void 0 : _16.cap) === null || _17 === void 0 ? void 0 : _17.toString()) || '',
          capDomicilioDirty: !!((_18 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _18 === void 0 ? void 0 : _18.cap),
          capDomicilioError: (0, helpers_1.validationField)((_20 = (_19 = datiDomicilio === null || datiDomicilio === void 0 ? void 0 : datiDomicilio.indirizzo) === null || _19 === void 0 ? void 0 : _19.cap) === null || _20 === void 0 ? void 0 : _20.toString(), '', false, 5, undefined, true),
          modificaDatiDomicilio: !!((_22 = (_21 = state.dati.indirizzoDomicilio) === null || _21 === void 0 ? void 0 : _21.indirizzo) === null || _22 === void 0 ? void 0 : _22.cap)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_FORM_DOCUMENTO_SUBMITTED:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          formSubmitted: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOCUMENTO_IDENTITA_TIPO_DOCUMENTO:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          tipoDocumento: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOCUMENTO_IDENTITA_NUMERO_DOCUMENTO:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          numDocumento: action.payload,
          numDocumentoDirty: true,
          numDocumentoError: (0, helpers_1.validationField)(action.payload, '', false, 1, 16, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOCUMENTO_IDENTITA_LUOGO_RILASCIO:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          luogoRilascio: action.payload,
          luogoRilascioDirty: true,
          luogoRilascioError: (0, helpers_1.validationField)(action.payload, '', false, 1, 40, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOCUMENTO_IDENTITA_DATA_RILASCIO:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          dataRilascio: action.payload,
          dataRilascioDirty: true,
          dataRilascioError: (0, __1.validationFieldDate)(action.payload, [], false, moment().subtract(20, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOCUMENTO_IDENTITA_DATA_SCADENZA:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          dataScadenza: action.payload,
          dataScadenzaDirty: true,
          dataScadenzaError: (0, __1.validationFieldDate)(action.payload, [], false, moment().add(1, 'days').format('YYYY-MM-DD'), moment().add(11, 'years').format('YYYY-MM-DD'), true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_FILES_DOCUMENTO_IDENTITA:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          files: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_IS_UPLOAD_LOADING:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          isUploadLoading: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DATI_DOCUMENTO_IDENTITA:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          tipoDocumento: (_23 = state.riepilogoDatiDocumento.datiRilevati) === null || _23 === void 0 ? void 0 : _23.tipologiaDocumento,
          luogoRilascio: (_24 = state.riepilogoDatiDocumento.datiRilevati) === null || _24 === void 0 ? void 0 : _24.luogoRilascio,
          luogoRilascioDirty: true,
          luogoRilascioError: (0, helpers_1.validationField)((_25 = state.riepilogoDatiDocumento.datiRilevati) === null || _25 === void 0 ? void 0 : _25.luogoRilascio, '', false, undefined, undefined, true),
          numDocumento: (_26 = state.riepilogoDatiDocumento.datiRilevati) === null || _26 === void 0 ? void 0 : _26.numero,
          numDocumentoDirty: true,
          numDocumentoError: (0, helpers_1.validationField)((_27 = state.riepilogoDatiDocumento.datiRilevati) === null || _27 === void 0 ? void 0 : _27.numero, '', false, undefined, undefined, true),
          dataScadenzaDirty: true,
          dataScadenza: (0, helpers_1.formatStringDate)((_29 = (_28 = state.riepilogoDatiDocumento) === null || _28 === void 0 ? void 0 : _28.datiRilevati) === null || _29 === void 0 ? void 0 : _29.dataScadenza),
          dataScadenzaError: (0, __1.validationFieldDate)((_31 = (_30 = state.riepilogoDatiDocumento) === null || _30 === void 0 ? void 0 : _30.datiRilevati) === null || _31 === void 0 ? void 0 : _31.dataScadenza, [], false, moment().add(1, 'days').format('YYYY-MM-DD'), moment().add(11, 'years').format('YYYY-MM-DD'), true),
          dataRilascioDirty: true,
          dataRilascio: (0, helpers_1.formatStringDate)((_33 = (_32 = state.riepilogoDatiDocumento) === null || _32 === void 0 ? void 0 : _32.datiRilevati) === null || _33 === void 0 ? void 0 : _33.dataEmissione),
          dataRilascioError: (0, __1.validationFieldDate)((_35 = (_34 = state.riepilogoDatiDocumento) === null || _34 === void 0 ? void 0 : _34.datiRilevati) === null || _35 === void 0 ? void 0 : _35.dataEmissione, [], false, moment().subtract(20, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_ARRAY_RAPPORTI_SCOPI:
      return {
        ...state,
        formScopiRapporti: {
          ...state.formScopiRapporti,
          rapporti: ((_36 = state.dati) === null || _36 === void 0 ? void 0 : _36.rapporti) || []
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_SCOPO_RAPPORTO:
      const arrayRapporti = state.formScopiRapporti.rapporti;
      arrayRapporti.map((r, index) => {
        if (index === action.index) {
          r.scopoPrevalenteRapporto = action.payload;
        }
      });
      return {
        ...state,
        formScopiRapporti: {
          ...state.formScopiRapporti,
          rapporti: arrayRapporti
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_ORIGINE_DESTINAZIONE_FONDI:
      const rapporti = state.formScopiRapporti.rapporti;
      rapporti.map((r, index) => {
        if (index === action.index) {
          r.origineDestinazioneFondi = action.payload;
        }
      });
      return {
        ...state,
        formScopiRapporti: {
          ...state.formScopiRapporti,
          rapporti: rapporti
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_FORM_DATI_REDDITUALI:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          professione: state.dati.professione,
          professioneError: '',
          tae: state.dati.tae,
          taeDirty: !!state.dati.tae,
          taeError: (0, helpers_1.validationField)(state.dati.tae, '', false, undefined, undefined, true),
          cae: state.dati.cae,
          caeDirty: true,
          caeError: '',
          redditoAnnuo: (_37 = state.dati.datiRedditoPatrimonio) === null || _37 === void 0 ? void 0 : _37.redditoAnnuo,
          redditoAnnuoDirty: !!((_38 = state.dati.datiRedditoPatrimonio) === null || _38 === void 0 ? void 0 : _38.redditoAnnuo),
          redditoAnnuoError: (0, helpers_1.validationField)((_39 = state.dati.datiRedditoPatrimonio) === null || _39 === void 0 ? void 0 : _39.redditoAnnuo, '', false, undefined, undefined, true),
          patrimonioTotaleCliente: (_40 = state.dati.datiRedditoPatrimonio) === null || _40 === void 0 ? void 0 : _40.patrimonioTotale,
          patrimonioTotaleClienteDirty: !!state.dati.datiRedditoPatrimonio,
          patrimonioTotaleClienteError: (0, helpers_1.validationField)((_41 = state.dati.datiRedditoPatrimonio) === null || _41 === void 0 ? void 0 : _41.patrimonioTotale, '', false, undefined, undefined, true),
          statoattecprev: state.dati.statoAttivitaPrevalente,
          statoattecprevDirty: !!state.dati.statoAttivitaPrevalente,
          statoattecprevError: (0, helpers_1.validationField)(state.dati.statoAttivitaPrevalente, ''),
          provattecprev: state.dati.provinciaAttivitaPrevalente,
          provattecprevDirty: !!state.dati.provinciaAttivitaPrevalente,
          provattecprevError: (0, helpers_1.validationField)(state.dati.provinciaAttivitaPrevalente, ''),
          qualificaLavorativa: state.dati.qualificaLavorativa,
          qualificaLavorativaDirty: true,
          qualificaLavorativaError: '',
          notaTipologia: state.dati.noteAttivita,
          notaTipologiaDirty: true,
          notaTipologiaError: (0, helpers_1.validationField)(state.dati.noteAttivita, ''),
          primoPaese: state.dati.primoPaeseRelazioniCliente,
          primoPaeseDirty: !!state.dati.primoPaeseRelazioniCliente,
          primoPaeseError: (0, helpers_1.validationField)(state.dati.primoPaeseRelazioniCliente, ''),
          professioneDirty: true,
          secondoPaese: state.dati.secondoPaeseRelazioniCliente,
          terzoPaese: state.dati.terzoPaeseRelazioniCliente,
          esercizioDati: (_42 = state.dati.datiRedditoPatrimonio) === null || _42 === void 0 ? void 0 : _42.esercizioDati,
          esercizioDatiDirty: true,
          esercizioDatiError: '',
          ultimaProfessione: state.dati.ultimaProfessione,
          ultimaProfessioneDirty: true,
          ultimaProfessioneError: '',
          appaltiFinanziamentiPubblici: state.dati.appaltiFinanziamentiPubblici || false,
          origineReddito: (_43 = state.dati.datiRedditoPatrimonio) === null || _43 === void 0 ? void 0 : _43.origineReddito,
          origineRedditoDirty: !!((_44 = state.dati.datiRedditoPatrimonio) === null || _44 === void 0 ? void 0 : _44.origineReddito),
          origineRedditoError: (0, helpers_1.validationField)((_45 = state.dati.datiRedditoPatrimonio) === null || _45 === void 0 ? void 0 : _45.origineReddito, ''),
          originePatrimonio: (_46 = state.dati.datiRedditoPatrimonio) === null || _46 === void 0 ? void 0 : _46.originePatrimonio,
          originePatrimonioDirty: !!((_47 = state.dati.datiRedditoPatrimonio) === null || _47 === void 0 ? void 0 : _47.originePatrimonio),
          originePatrimonioError: (0, helpers_1.validationField)((_48 = state.dati.datiRedditoPatrimonio) === null || _48 === void 0 ? void 0 : _48.originePatrimonio, '')
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_PROFESSIONE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          professione: action.payload,
          professioneDirty: true,
          professioneError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_TAE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          tae: action.payload,
          taeDirty: true,
          taeError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_CAE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          cae: action.payload,
          caeDirty: true,
          caeError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_REDDITO_ANNUO:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          redditoAnnuo: action.payload,
          redditoAnnuoDirty: true,
          redditoAnnuoError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_PATRIMONIO_TOTALE_CLIENTE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          patrimonioTotaleCliente: action.payload,
          patrimonioTotaleClienteDirty: true,
          patrimonioTotaleClienteError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_STATO_ATT_EC_PREV:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          statoattecprev: action.payload,
          statoattecprevDirty: true,
          statoattecprevError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_PROV_ATT_EC_PREV:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          provattecprev: action.payload,
          provattecprevDirty: true,
          provattecprevError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_QUALIFICA_LAVORATIVA:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          qualificaLavorativa: action.payload,
          qualificaLavorativaDirty: true,
          qualificaLavorativaError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_NOTA_TIPOLOGIA:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          notaTipologia: action.payload,
          notaTipologiaDirty: true,
          notaTipologiaError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_PRIMO_PAESE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          primoPaese: action.payload,
          primoPaeseDirty: true,
          primoPaeseError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_SECONDO_PAESE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          secondoPaese: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_TERZO_PAESE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          terzoPaese: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_ESERCIZIO_DATI:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          esercizioDati: action.payload,
          esercizioDatiDirty: true,
          esercizioDatiError: (0, helpers_1.validationField)(action.payload, '', false, undefined, undefined, true)
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_ULTIMA_PROFESSIONE:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          ultimaProfessione: action.payload,
          ultimaProfessioneDirty: true,
          ultimaProfessioneError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_APPALTI_PUBBLICI:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          appaltiFinanziamentiPubblici: action.payload || false
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_ORIGINE_REDDITO:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          origineReddito: action.payload,
          origineRedditoDirty: true,
          origineRedditoError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_ORIGINE_PATRIMONIO:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          originePatrimonio: action.payload,
          originePatrimonioDirty: true,
          originePatrimonioError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_FORM_DATI_REDDITTUALI_SUBMITTED:
      return {
        ...state,
        formDatiReddituali: {
          ...state.formDatiReddituali,
          formDatiRedSubmitted: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_MODIFICA_QUESTIONARIO_SUCCESS:
      return {
        ...state,
        riepilogo: action.payload
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_RESET_RIEPILOGO:
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DISABLED_FIRMA:
      return {
        ...state,
        form: {
          ...state.form,
          disabeldFirma: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_AGGIORNA_DOCUMENTO_SUCCESS:
      return {
        ...state,
        esitoCaricamentoDocumento: action.payload
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_GET_QUALIFICHE_TAE_CAE_BY_PROFESSIONE_SUCCESS:
      return {
        ...state,
        elencoQualifiche: action.payload.qualificaLavorativa,
        elencoCAE: action.payload.cae,
        elencoTAE: action.payload.tae
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_MODIFICA_DOCUMENTO:
      return {
        ...state,
        formDocumentoIdentita: {
          ...state.formDocumentoIdentita,
          modificaDocumento: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_MODIFICA_DOMICILIO:
      return {
        ...state,
        formDatiResidenza: {
          ...state.formDatiResidenza,
          modificaDatiDomicilio: action.payload
        }
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SALVA_DOCUMENTO_MODIFICATO_SUCCESS:
      return {
        ...state,
        esitoModificaDocumento: action.payload
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_RESET_FORM_DOCUMENTI_ACTION:
      return {
        ...state,
        formDocumentoIdentita: {
          ...initialState.formDocumentoIdentita,
          modificaDocumento: state.formDocumentoIdentita.modificaDocumento
        },
        riepilogoDatiDocumento: initialState.riepilogoDatiDocumento
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_RESET_TOTALE:
      return {
        ...state,
        form: initialState.form,
        formDatiResidenza: initialState.formDatiResidenza,
        formDocumentoIdentita: initialState.formDocumentoIdentita,
        formScopiRapporti: initialState.formScopiRapporti,
        formDatiReddituali: initialState.formDatiReddituali,
        esitoModificaDocumento: initialState.esitoModificaDocumento,
        riepilogoDatiDocumento: initialState.riepilogoDatiDocumento,
        riepilogo: initialState.riepilogo,
        documentoModificato: initialState.documentoModificato
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_RESET_TOTALE_DOCUMENTO:
      return {
        ...state,
        formDocumentoIdentita: initialState.formDocumentoIdentita,
        esitoModificaDocumento: initialState.esitoModificaDocumento,
        riepilogoDatiDocumento: initialState.riepilogoDatiDocumento,
        riepilogo: initialState.riepilogo,
        documentoModificato: initialState.documentoModificato,
        esitoCaricamentoDocumento: initialState.esitoCaricamentoDocumento
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_SET_DOCUMENTO_MODIFICATO:
      return {
        ...state,
        documentoModificato: action.payload
      };
    case adeguataVerifica_types_1.EReduxAdeguataVerificaActionTypes.ADV_VERIFICA_CARICAMENTO_DOCUMENTO_SUCCESS:
      return {
        ...state,
        riepilogoDatiDocumento: action.payload
      };
    default:
      return state;
  }
  ;
};
exports.adeguataVerificaReducer = adeguataVerificaReducer;