"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeDepositElencoDtoToJSON = exports.TimeDepositElencoDtoFromJSONTyped = exports.TimeDepositElencoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TimeDepositElencoDtoFromJSON(json) {
  return TimeDepositElencoDtoFromJSONTyped(json, false);
}
exports.TimeDepositElencoDtoFromJSON = TimeDepositElencoDtoFromJSON;
function TimeDepositElencoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'attivi': !runtime_1.exists(json, 'attivi') ? undefined : json['attivi'].map(_1.TimeDepositDtoFromJSON),
    'inCorso': !runtime_1.exists(json, 'inCorso') ? undefined : json['inCorso'].map(_1.LogTimeDepositDtoFromJSON),
    'inErrore': !runtime_1.exists(json, 'inErrore') ? undefined : json['inErrore'].map(_1.LogTimeDepositDtoFromJSON)
  };
}
exports.TimeDepositElencoDtoFromJSONTyped = TimeDepositElencoDtoFromJSONTyped;
function TimeDepositElencoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'attivi': value.attivi === undefined ? undefined : value.attivi.map(_1.TimeDepositDtoToJSON),
    'inCorso': value.inCorso === undefined ? undefined : value.inCorso.map(_1.LogTimeDepositDtoToJSON),
    'inErrore': value.inErrore === undefined ? undefined : value.inErrore.map(_1.LogTimeDepositDtoToJSON)
  };
}
exports.TimeDepositElencoDtoToJSON = TimeDepositElencoDtoToJSON;