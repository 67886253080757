"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TassoInteressiDtoToJSON = exports.TassoInteressiDtoFromJSONTyped = exports.TassoInteressiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TassoInteressiDtoFromJSON(json) {
  return TassoInteressiDtoFromJSONTyped(json, false);
}
exports.TassoInteressiDtoFromJSON = TassoInteressiDtoFromJSON;
function TassoInteressiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tassoAvere': !runtime_1.exists(json, 'tassoAvere') ? undefined : json['tassoAvere'],
    'interessiLordi': !runtime_1.exists(json, 'interessiLordi') ? undefined : json['interessiLordi'],
    'interessiNetti': !runtime_1.exists(json, 'interessiNetti') ? undefined : json['interessiNetti'],
    'dataRiferimento': !runtime_1.exists(json, 'dataRiferimento') ? undefined : new Date(json['dataRiferimento'])
  };
}
exports.TassoInteressiDtoFromJSONTyped = TassoInteressiDtoFromJSONTyped;
function TassoInteressiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tassoAvere': value.tassoAvere,
    'interessiLordi': value.interessiLordi,
    'interessiNetti': value.interessiNetti,
    'dataRiferimento': value.dataRiferimento === undefined ? undefined : value.dataRiferimento.toISOString()
  };
}
exports.TassoInteressiDtoToJSON = TassoInteressiDtoToJSON;