"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContattiDtoToJSON = exports.ContattiDtoFromJSONTyped = exports.ContattiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ContattiDtoFromJSON(json) {
  return ContattiDtoFromJSONTyped(json, false);
}
exports.ContattiDtoFromJSON = ContattiDtoFromJSON;
function ContattiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'contatti': !runtime_1.exists(json, 'contatti') ? undefined : json['contatti'].map(_1.ContattoDtoFromJSON)
  };
}
exports.ContattiDtoFromJSONTyped = ContattiDtoFromJSONTyped;
function ContattiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'contatti': value.contatti === undefined ? undefined : value.contatti.map(_1.ContattoDtoToJSON)
  };
}
exports.ContattiDtoToJSON = ContattiDtoToJSON;