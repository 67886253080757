"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModificaIbanInputDtoToJSON = exports.ModificaIbanInputDtoFromJSONTyped = exports.ModificaIbanInputDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ModificaIbanInputDtoFromJSON(json) {
  return ModificaIbanInputDtoFromJSONTyped(json, false);
}
exports.ModificaIbanInputDtoFromJSON = ModificaIbanInputDtoFromJSON;
function ModificaIbanInputDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'idSessioneChiamante': !runtime_1.exists(json, 'idSessioneChiamante') ? undefined : json['idSessioneChiamante'],
    'servizi': !runtime_1.exists(json, 'servizi') ? undefined : json['servizi'].map(_1.ServizioOperativoOutputDtoFromJSON)
  };
}
exports.ModificaIbanInputDtoFromJSONTyped = ModificaIbanInputDtoFromJSONTyped;
function ModificaIbanInputDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'idSessioneChiamante': value.idSessioneChiamante,
    'servizi': value.servizi === undefined ? undefined : value.servizi.map(_1.ServizioOperativoOutputDtoToJSON)
  };
}
exports.ModificaIbanInputDtoToJSON = ModificaIbanInputDtoToJSON;