"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GestionePatrimonialiDtoToJSON = exports.GestionePatrimonialiDtoFromJSONTyped = exports.GestionePatrimonialiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function GestionePatrimonialiDtoFromJSON(json) {
  return GestionePatrimonialiDtoFromJSONTyped(json, false);
}
exports.GestionePatrimonialiDtoFromJSON = GestionePatrimonialiDtoFromJSON;
function GestionePatrimonialiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'saldo': !runtime_1.exists(json, 'saldo') ? undefined : json['saldo'],
    'saldoContabile': !runtime_1.exists(json, 'saldoContabile') ? undefined : json['saldoContabile'],
    'saldoDisponibile': !runtime_1.exists(json, 'saldoDisponibile') ? undefined : json['saldoDisponibile']
  };
}
exports.GestionePatrimonialiDtoFromJSONTyped = GestionePatrimonialiDtoFromJSONTyped;
function GestionePatrimonialiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'intestatario': value.intestatario,
    'iban': value.iban,
    'divisa': value.divisa,
    'saldo': value.saldo,
    'saldoContabile': value.saldoContabile,
    'saldoDisponibile': value.saldoDisponibile
  };
}
exports.GestionePatrimonialiDtoToJSON = GestionePatrimonialiDtoToJSON;