"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTextSearch = exports.getListaFilialiAtmByRadius = exports.getListaFilialiAtm = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const env_1 = require("../general/env");
const environments_1 = require("../general/environments");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const GooglePlacesApi_1 = require("./api/GooglePlacesApi");
const GoogleApiKeys = require("./google-api-keys.json");
function getListaFilialiAtmStart() {
  return {
    type: __1.EReduxActionTypes.RICERCA_FILIALE_ATM_GET_LISTA_START
  };
}
function getListaFilialiAtmSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICERCA_FILIALE_ATM_GET_LISTA_SUCCESS,
    payload
  };
}
function getListaFilialiAtmError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getListaFilialiAtm = function () {
  let loaderEnabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return async (dispatch, getState, extraArguments) => {
    const lingua = getState().i18n.currentLanguage;
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getListaFilialiAtmStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/ricerca_filiali_atm.json')).then(response => {
        dispatch(getListaFilialiAtmSuccess(response.map(r => (0, api_rest_1.AtmDtoFromJSON)(r))));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      const request = {
        lingua
      };
      new api_rest_1.RicercaAtmControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).listaAtm(request).then(response => {
        dispatch(getListaFilialiAtmSuccess(response));
      }).catch(async error => {
        dispatch(getListaFilialiAtmError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    }
  };
};
exports.getListaFilialiAtm = getListaFilialiAtm;
function getListaFilialiAtmByRadiusStart() {
  return {
    type: __1.EReduxActionTypes.RICERCA_FILIALE_ATM_GET_LISTA_BY_RADIUS_START
  };
}
function getListaFilialiAtmByRadiusSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICERCA_FILIALE_ATM_GET_LISTA_BY_RADIUS_SUCCESS,
    payload
  };
}
function getListaFilialiAtmByRadiusError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getListaFilialiAtmByRadius = function (distance, latitude, longitude) {
  let loaderEnabled = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return async (dispatch, getState, extraArguments) => {
    const lingua = getState().i18n.currentLanguage;
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getListaFilialiAtmByRadiusStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/ricerca_filiali_atm.json')).then(response => {
        dispatch(getListaFilialiAtmSuccess(response.map(r => (0, api_rest_1.AtmDtoFromJSON)(r))));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      const listaAtmByRadiusRequest = {
        ricercaAtmFiltriInDto: {
          distance,
          latitude,
          longitude,
          lingua
        }
      };
      new api_rest_1.RicercaAtmControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).listaAtmByRadius(listaAtmByRadiusRequest).then(response => {
        dispatch(getListaFilialiAtmByRadiusSuccess(response));
      }).catch(async error => {
        dispatch(getListaFilialiAtmByRadiusError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    }
  };
};
exports.getListaFilialiAtmByRadius = getListaFilialiAtmByRadius;
function getTextSearchStart() {
  return {
    type: __1.EReduxActionTypes.RICERCA_FILIALE_GET_TEXT_SEARCH_START
  };
}
function getTextSearchSuccess(payload) {
  return {
    type: __1.EReduxActionTypes.RICERCA_FILIALE_GET_TEXT_SEARCH_SUCCESS,
    payload
  };
}
function getTextSearchError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
const getTextSearch = function (query, location) {
  let loaderEnabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return async (dispatch, getState, extraArguments) => {
    const lingua = getState().i18n.currentLanguage;
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getTextSearchStart());
    if (extraArguments.mock) {
      Promise.resolve().then(() => require('../../mock/text_search.json')).then(response => {
        dispatch(getTextSearchSuccess(response));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    } else {
      const env = (0, environments_1.getCustomEnv)() || env_1.selectedEnv || 'DEV';
      const apiKey = GoogleApiKeys[env === 'PROD' ? 'PROD' : 'DEV'].googlePlacesApiKey;
      const request = {
        key: apiKey,
        query,
        language: lingua,
        region: 'it',
        type: 'address',
        location: (location === null || location === void 0 ? void 0 : location.latitude) != undefined && (location === null || location === void 0 ? void 0 : location.longitude) != undefined ? "".concat(location.latitude, ",").concat(location.longitude) : undefined,
        radius: 1000
      };
      const c = new api_rest_1.Configuration();
      const configuration = {
        basePath: 'https://maps.googleapis.com/maps/api',
        middleware: c.middleware,
        fetchApi: c.fetchApi,
        queryParamsStringify: c.queryParamsStringify,
        headers: {}
      };
      new GooglePlacesApi_1.GooglePlacesApi(configuration).textSearch(request).then(response => {
        dispatch(getTextSearchSuccess(response));
      }).catch(async error => {
        dispatch(getTextSearchError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
      });
    }
  };
};
exports.getTextSearch = getTextSearch;