import { itemSearch, menuList, resetTextSearchMenu, setTextSearchMenu, setWebMenu, TagDto } from "@sparkasse/commons";
import history, { navigateToPage } from "component/history/history.component";
import BankIcon from "component/Icon/icon.component";
import SearchInputText from "component/inputSearchBar/inputSearchBar";
import { sendEventToMatomo } from "helpers/utilities";
import React, { useEffect, useRef, useState } from "react";
import { InputGroup, Overlay } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

const Search: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    menu,
    setTextSearchMenu,
    resetTextSearchMenu,
    itemSearch
  } = props;

  const [focusSearch, setFocusSearch] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [focusedElement, setFocusedElement] = useState<(number | null)>(null);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const searchInput = useRef<HTMLInputElement>(null);

  const hidePopover = () => {
    setShowOverlay(false);
    setFocusedElement(null)
  };

  useEffect(() => {
    history.listen((e) => {
      resetSearch();
    });
  }, []);

  const clickOnElement = (el: TagDto) => {
    setFocusedElement(null);
    if (el && el.idMenu) {
      resetTextSearchMenu();
      setShowOverlay(false);
      setTarget(null);
      sendEventToMatomo('NAVIGAZIONE_DA_RICERCA', `navigazione verso pagina ${el.menuLabel} da barra di ricerca`, el.idMenu)
      return navigateToPage(el.idMenu);
    }
  };

  const handleSearch = (value: string, event: any) => {
    setTextSearchMenu(value);
    if (value.length >= 2) {
      setShowOverlay(true);
      setTarget(event.target);
    }
  };

  const resetSearch = () => {
    resetTextSearchMenu();
  };

  const handleKeyChange = (e: any, item: TagDto) => {
    if (e.keyCode === 40) {
      // Down arrow
      e.preventDefault();
      setFocusedElement(focusedElement !== itemSearch.length - 1 && focusedElement !== null ? focusedElement + 1 : 0);
    } else if (e.keyCode === 38) {
      // Up arrow
      e.preventDefault();
      setFocusedElement(focusedElement !== 0 && focusedElement !== null ? focusedElement - 1 : itemSearch.length - 1);
    }
    else if (e.keyCode === 13) {
      clickOnElement(item);
    }
  };

  const handleOnKeyDown = (e: any) => {
    if (e.keyCode === 40) {
      e.target.blur();
      setFocusedElement(0);
    }
  };

  const onFocusInput = (v: boolean) => {
    setTimeout(() => {
      setFocusSearch(v);
    }, v ? 0 : 300);
  };

  return (
    <div className={`search-view ${focusSearch ? "input-control-text-focused" : ""}`}>
      <InputGroup className={`search-view--input-group`}>
        <div className="w-100" ref={ref}>
          <SearchInputText
            onkeydown={handleOnKeyDown}
            placeholder={t("home.cosaStaiCercando")}
            img={
              menu.textSearch === "" ? "navigazione-search" : "azioni_elimina"
            }
            value={menu.textSearch}
            onChange={(value: string, event: any) => {
              handleSearch(value, event);
            }}
            onFocusInput={onFocusInput}
            imgOnClick={() => {
              if (menu.textSearch === "") {
                searchInput &&
                  searchInput.current &&
                  searchInput.current.focus();
              } else {
                resetSearch();
              }
            }}
            searchInputRef={searchInput}
            focusSearch={focusSearch}
          />
          <Overlay
            show={showOverlay}
            target={target}
            container={ref?.current || undefined}
            placement="bottom"
            rootClose={true}
            onHide={hidePopover}
          >
            <div className="search-result">
              <ListItems
                selectionElement={clickOnElement}
                menuItems={itemSearch || []}
                focus={focusedElement}
                handleKeyChange={handleKeyChange}
              />
            </div>
          </Overlay>
        </div>
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  menu: menuList(state.menu),
  itemSearch: itemSearch(state.menu)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setTextSearchMenu,
      resetTextSearchMenu,
      setWebMenu,
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Search);

interface IProps {
  menuItems: TagDto[]
  selectionElement: Function
  handleKeyChange: Function
  focus: number | null
}

const ListItems: React.FC<IProps> = (propsIn: IProps) => {

  const { t } = useTranslation();

  const {
    menuItems,
    selectionElement,
    handleKeyChange,
    focus
  } = propsIn;

  return (
    <>
      <ul className="listItems">
        {menuItems.length > 0 ? (
          menuItems.map((item, index) => {
            return (
              <ListSingleItem
                key={index}
                singleItem={item}
                selectionElement={selectionElement}
                index={index}
                handleKeyChange={handleKeyChange}
                focus={focus === index}
              />
            );
          })
        ) : (
            <li className="noItems">
              <p> {t("searchBar.nessunRisultato")} </p>
            </li>
          )}
      </ul>
    </>
  );
};

interface ItemProps {
  singleItem: TagDto
  selectionElement: Function
  handleKeyChange: Function
  index: number
  focus: boolean
}

const ListSingleItem: React.FC<ItemProps> = (propsIn: ItemProps) => {

  const { t } = useTranslation();

  const {
    singleItem,
    selectionElement,
    index,
    focus,
    handleKeyChange
  } = propsIn;

  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focus) {
      // Move element into view when it is focused
      if (ref && ref.current && ref?.current?.focus) {
        ref?.current?.focus();
      }
    }
  }, [focus]);

  return (
    <li
      tabIndex={focus ? 0 : -1}
      role="button"
      ref={ref}
      key={index}
      className={`d-flex  justify-content-between single-searchItem`}
      onKeyDown={(e: any) => {
        handleKeyChange(e, singleItem)
      }}
      onClick={() => selectionElement(singleItem)}
    >
      <div className={`d-flex align-items-center `}>
        <div>
          <BankIcon title={singleItem.idMenu || "notFound"} />
        </div>
        <div>
          <p> {t(singleItem.idMenu || "")}</p>
        </div>
      </div>
      <div>
        <BankIcon title="dettaglio-mini" />
      </div>
    </li>
  );
};
