"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListaMovimentiNexiDebitToJSON = exports.ListaMovimentiNexiDebitFromJSONTyped = exports.ListaMovimentiNexiDebitFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ListaMovimentiNexiDebitFromJSON(json) {
  return ListaMovimentiNexiDebitFromJSONTyped(json, false);
}
exports.ListaMovimentiNexiDebitFromJSON = ListaMovimentiNexiDebitFromJSON;
function ListaMovimentiNexiDebitFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'totaleMovimenti': !runtime_1.exists(json, 'totaleMovimenti') ? undefined : json['totaleMovimenti'],
    'movimenti': !runtime_1.exists(json, 'movimenti') ? undefined : json['movimenti'].map(_1.MovimentoNexiDebitDtoFromJSON),
    'totaleFinale': !runtime_1.exists(json, 'totaleFinale') ? undefined : json['totaleFinale']
  };
}
exports.ListaMovimentiNexiDebitFromJSONTyped = ListaMovimentiNexiDebitFromJSONTyped;
function ListaMovimentiNexiDebitToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'totaleMovimenti': value.totaleMovimenti,
    'movimenti': value.movimenti === undefined ? undefined : value.movimenti.map(_1.MovimentoNexiDebitDtoToJSON),
    'totaleFinale': value.totaleFinale
  };
}
exports.ListaMovimentiNexiDebitToJSON = ListaMovimentiNexiDebitToJSON;