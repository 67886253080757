"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElencoAppuntamentiInToJSON = exports.ElencoAppuntamentiInFromJSONTyped = exports.ElencoAppuntamentiInFromJSON = void 0;
function ElencoAppuntamentiInFromJSON(json) {
  return ElencoAppuntamentiInFromJSONTyped(json, false);
}
exports.ElencoAppuntamentiInFromJSON = ElencoAppuntamentiInFromJSON;
function ElencoAppuntamentiInFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'mese': json['mese'],
    'anno': json['anno']
  };
}
exports.ElencoAppuntamentiInFromJSONTyped = ElencoAppuntamentiInFromJSONTyped;
function ElencoAppuntamentiInToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'mese': value.mese,
    'anno': value.anno
  };
}
exports.ElencoAppuntamentiInToJSON = ElencoAppuntamentiInToJSON;