"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartaDtoToJSON = exports.CartaDtoFromJSONTyped = exports.CartaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CartaDtoFromJSON(json) {
  return CartaDtoFromJSONTyped(json, false);
}
exports.CartaDtoFromJSON = CartaDtoFromJSON;
function CartaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'contractType': !runtime_1.exists(json, 'contractType') ? undefined : json['contractType'],
    'cnumber': !runtime_1.exists(json, 'cnumber') ? undefined : json['cnumber'],
    'cardBalanceStatus': !runtime_1.exists(json, 'cardBalanceStatus') ? undefined : json['cardBalanceStatus'],
    'balance': !runtime_1.exists(json, 'balance') ? undefined : json['balance']
  };
}
exports.CartaDtoFromJSONTyped = CartaDtoFromJSONTyped;
function CartaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'contractType': value.contractType,
    'cnumber': value.cnumber,
    'cardBalanceStatus': value.cardBalanceStatus,
    'balance': value.balance
  };
}
exports.CartaDtoToJSON = CartaDtoToJSON;