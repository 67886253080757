"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RicaricheCarteDtoToJSON = exports.RicaricheCarteDtoFromJSONTyped = exports.RicaricheCarteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RicaricheCarteDtoFromJSON(json) {
  return RicaricheCarteDtoFromJSONTyped(json, false);
}
exports.RicaricheCarteDtoFromJSON = RicaricheCarteDtoFromJSON;
function RicaricheCarteDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'ricariche': !runtime_1.exists(json, 'ricariche') ? undefined : json['ricariche'].map(_1.RicaricaCarteDtoFromJSON)
  };
}
exports.RicaricheCarteDtoFromJSONTyped = RicaricheCarteDtoFromJSONTyped;
function RicaricheCarteDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'ricariche': value.ricariche === undefined ? undefined : value.ricariche.map(_1.RicaricaCarteDtoToJSON)
  };
}
exports.RicaricheCarteDtoToJSON = RicaricheCarteDtoToJSON;