"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestSPToJSON = exports.RequestSPFromJSONTyped = exports.RequestSPFromJSON = void 0;
const runtime_1 = require("../runtime");
function RequestSPFromJSON(json) {
  return RequestSPFromJSONTyped(json, false);
}
exports.RequestSPFromJSON = RequestSPFromJSON;
function RequestSPFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'annoInserimento': !runtime_1.exists(json, 'annoInserimento') ? undefined : json['annoInserimento'],
    'progressivoInserimento': !runtime_1.exists(json, 'progressivoInserimento') ? undefined : json['progressivoInserimento'],
    'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto']
  };
}
exports.RequestSPFromJSONTyped = RequestSPFromJSONTyped;
function RequestSPToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'annoInserimento': value.annoInserimento,
    'progressivoInserimento': value.progressivoInserimento,
    'tipologia': value.tipologia,
    'codiceRapporto': value.codiceRapporto
  };
}
exports.RequestSPToJSON = RequestSPToJSON;