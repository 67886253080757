"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContribuenteDatiAnagraficiDtoToJSON = exports.ContribuenteDatiAnagraficiDtoFromJSONTyped = exports.ContribuenteDatiAnagraficiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContribuenteDatiAnagraficiDtoFromJSON(json) {
  return ContribuenteDatiAnagraficiDtoFromJSONTyped(json, false);
}
exports.ContribuenteDatiAnagraficiDtoFromJSON = ContribuenteDatiAnagraficiDtoFromJSON;
function ContribuenteDatiAnagraficiDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'cognome': json['cognome'],
    'nome': json['nome'],
    'dataNascita': !runtime_1.exists(json, 'dataNascita') ? undefined : new Date(json['dataNascita']),
    'sesso': json['sesso'],
    'comune': json['comune'],
    'provincia': json['provincia']
  };
}
exports.ContribuenteDatiAnagraficiDtoFromJSONTyped = ContribuenteDatiAnagraficiDtoFromJSONTyped;
function ContribuenteDatiAnagraficiDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'cognome': value.cognome,
    'nome': value.nome,
    'dataNascita': value.dataNascita === undefined ? undefined : value.dataNascita.toISOString(),
    'sesso': value.sesso,
    'comune': value.comune,
    'provincia': value.provincia
  };
}
exports.ContribuenteDatiAnagraficiDtoToJSON = ContribuenteDatiAnagraficiDtoToJSON;