"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerSelezionatoFunzionalitaSenzaImmagine = exports.bannerSelezionatoFunzionalitaConImmagine = exports.bannerSenzaImmagineSenzaHTML = exports.bannerSenzaImmagine = exports.bannerConImmagineSenzaHTML = exports.bannerConImmagine = exports.visibilitaBanner = exports.bannerDaNascondere = exports.bannerSelezionato = exports.bannerPerFunzionalitaSenzaHTML = exports.bannerPerFunzionalita = void 0;
const reselect_1 = require("reselect");
const bannerReducer = state => state;
exports.bannerPerFunzionalita = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerPerFunzionalita;
});
exports.bannerPerFunzionalitaSenzaHTML = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerPerFunzionalitaSenzaHTML;
});
exports.bannerSelezionato = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerSelezionato;
});
exports.bannerDaNascondere = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerDaNascondere;
});
exports.visibilitaBanner = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.visibilitaBanner;
});
exports.bannerConImmagine = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerConImmagine;
});
exports.bannerConImmagineSenzaHTML = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerConImmagineSenzaHTML;
});
exports.bannerSenzaImmagine = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerSenzaImmagine;
});
exports.bannerSenzaImmagineSenzaHTML = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerSenzaImmagineSenzaHTML;
});
exports.bannerSelezionatoFunzionalitaConImmagine = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerFunzionalitaConImmagineSelezionato;
});
exports.bannerSelezionatoFunzionalitaSenzaImmagine = (0, reselect_1.createSelector)(bannerReducer, state => {
  return state.bannerFunzionalitaSenzaImmagineSelezionato;
});