"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HomebankingQuestionBODtoToJSON = exports.HomebankingQuestionBODtoFromJSONTyped = exports.HomebankingQuestionBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function HomebankingQuestionBODtoFromJSON(json) {
  return HomebankingQuestionBODtoFromJSONTyped(json, false);
}
exports.HomebankingQuestionBODtoFromJSON = HomebankingQuestionBODtoFromJSON;
function HomebankingQuestionBODtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'category': !runtime_1.exists(json, 'category') ? undefined : json['category'],
    'defaultValue': !runtime_1.exists(json, 'defaultValue') ? undefined : json['defaultValue'],
    'help': !runtime_1.exists(json, 'help') ? undefined : json['help'],
    'hidden': !runtime_1.exists(json, 'hidden') ? undefined : json['hidden'],
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'options': !runtime_1.exists(json, 'options') ? undefined : json['options'].map(_1.HomebankingQuestionOptionBODtoFromJSON),
    'order': !runtime_1.exists(json, 'order') ? undefined : json['order'],
    'readOnly': !runtime_1.exists(json, 'readOnly') ? undefined : json['readOnly'],
    'required': !runtime_1.exists(json, 'required') ? undefined : json['required'],
    'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
    'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    'value': !runtime_1.exists(json, 'value') ? undefined : json['value']
  };
}
exports.HomebankingQuestionBODtoFromJSONTyped = HomebankingQuestionBODtoFromJSONTyped;
function HomebankingQuestionBODtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'bankCode': value.bankCode,
    'category': value.category,
    'defaultValue': value.defaultValue,
    'help': value.help,
    'hidden': value.hidden,
    'id': value.id,
    'message': value.message,
    'options': value.options === undefined ? undefined : value.options.map(_1.HomebankingQuestionOptionBODtoToJSON),
    'order': value.order,
    'readOnly': value.readOnly,
    'required': value.required,
    'text': value.text,
    'type': value.type,
    'userId': value.userId,
    'value': value.value
  };
}
exports.HomebankingQuestionBODtoToJSON = HomebankingQuestionBODtoToJSON;