"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DettaglioBloccoCarteDtoToJSON = exports.DettaglioBloccoCarteDtoFromJSONTyped = exports.DettaglioBloccoCarteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DettaglioBloccoCarteDtoFromJSON(json) {
  return DettaglioBloccoCarteDtoFromJSONTyped(json, false);
}
exports.DettaglioBloccoCarteDtoFromJSON = DettaglioBloccoCarteDtoFromJSON;
function DettaglioBloccoCarteDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'tipoCarta': !runtime_1.exists(json, 'tipoCarta') ? undefined : json['tipoCarta'],
    'nomeContatto': !runtime_1.exists(json, 'nomeContatto') ? undefined : json['nomeContatto'],
    'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero']
  };
}
exports.DettaglioBloccoCarteDtoFromJSONTyped = DettaglioBloccoCarteDtoFromJSONTyped;
function DettaglioBloccoCarteDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'tipoCarta': value.tipoCarta,
    'nomeContatto': value.nomeContatto,
    'numero': value.numero
  };
}
exports.DettaglioBloccoCarteDtoToJSON = DettaglioBloccoCarteDtoToJSON;