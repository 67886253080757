"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.f24Reducer = exports.RigaSezioneTributiLocaliDtoSaldoEnum = exports.RigaSezioneTributiLocaliDtoRavvedimentoEnum = exports.RigaSezioneTributiLocaliDtoImmVariatiEnum = exports.RigaSezioneTributiLocaliDtoAccontoEnum = exports.RigaSezioneSemplificatoDtoSaldoEnum = exports.RigaSezioneSemplificatoDtoRavvedimentoEnum = exports.RigaSezioneSemplificatoDtoImmobiliVariatiEnum = exports.RigaSezioneSemplificatoDtoAccontoEnum = exports.BozzeInDtoTipoOrdinamentoEnum = void 0;
// export { F24BoxCoordinate, F24BoundingBox } from '@sparkasse/api-f24';
var api_rest_1 = require("@sparkasse/api-rest");
Object.defineProperty(exports, "BozzeInDtoTipoOrdinamentoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.BozzeInDtoTipoOrdinamentoEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneSemplificatoDtoAccontoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneSemplificatoDtoAccontoEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneSemplificatoDtoImmobiliVariatiEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneSemplificatoDtoImmobiliVariatiEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneSemplificatoDtoRavvedimentoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneSemplificatoDtoRavvedimentoEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneSemplificatoDtoSaldoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneSemplificatoDtoSaldoEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneTributiLocaliDtoAccontoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneTributiLocaliDtoAccontoEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneTributiLocaliDtoImmVariatiEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneTributiLocaliDtoImmVariatiEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneTributiLocaliDtoRavvedimentoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneTributiLocaliDtoRavvedimentoEnum;
  }
});
Object.defineProperty(exports, "RigaSezioneTributiLocaliDtoSaldoEnum", {
  enumerable: true,
  get: function () {
    return api_rest_1.RigaSezioneTributiLocaliDtoSaldoEnum;
  }
});
__exportStar(require("./f24.actions"), exports);
var f24_reducer_1 = require("./f24.reducer");
Object.defineProperty(exports, "f24Reducer", {
  enumerable: true,
  get: function () {
    return f24_reducer_1.f24Reducer;
  }
});
__exportStar(require("./f24.selectors"), exports);
__exportStar(require("./f24.types"), exports);