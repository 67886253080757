"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esitoRevocaBonificoDeposito = exports.infoRevocaBonificoDeposito = exports.formRevocaBonificoDeposito = exports.riepilogoRevocaBonificoDeposito = exports.esitoBonificoDeposito = exports.infoDispositivaBonificoDeposito = exports.riepilogoBonificoDeposito = exports.formBonificoDepositoValid = exports.buildBonificoDeposito = exports.formBonificoDeposito = exports.parametriBonificoDeposito = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const moment = require("moment");
const bonificoDepositoReducer = state => state;
exports.parametriBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.parametri;
});
exports.formBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.form;
});
exports.buildBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  var _a, _b, _c, _d, _e, _f;
  return {
    causale: state.form.causale,
    contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
    contoAddebitoIban: ((_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.rapporto) === null || _d === void 0 ? void 0 : _d.iban) || '',
    dataEsecuzione: moment(state.form.dataEsecuzione).toDate(),
    ibanBeneficiario: ((_e = state.form.contoAccredito) === null || _e === void 0 ? void 0 : _e.iban) || '',
    importo: (0, helpers_1.formattedNumberToNumber)(state.form.importo),
    motivazionePagamento: 'GIRO',
    naturaMovimento: 'SCT',
    nomeBeneficiario: ((_f = state.form.contoAccredito) === null || _f === void 0 ? void 0 : _f.intestazione) || '',
    datiEsecuzioneBonifico: {
      importoCommissioni: 0,
      importoTotale: 0,
      dataAddebito: new Date(),
      dataEsecuzioneAddebito: new Date(),
      dataValuta: new Date(),
      dataRegolamento: new Date(),
      denominazioneBanca: '',
      denominazioneSportello: '',
      revocabile: false,
      riferimentoOperazione: '',
      urgente: false
    }
  };
});
exports.formBonificoDepositoValid = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  let formValid = false;
  if (!state.form.contoAccreditoError && !state.form.importoError && !state.form.dataEsecuzioneError && !state.form.causaleError) {
    formValid = true;
  }
  return formValid;
});
exports.riepilogoBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.riepilogo;
});
exports.infoDispositivaBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.infoDispositiva;
});
exports.esitoBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.esito;
});
exports.riepilogoRevocaBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.riepilogoRevoca;
});
exports.formRevocaBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.formRevoca;
});
exports.infoRevocaBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.infoRevoca;
});
exports.esitoRevocaBonificoDeposito = (0, reselect_1.createSelector)(bonificoDepositoReducer, state => {
  return state.esitoRevoca;
});