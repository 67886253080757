"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BreakdownDtoToJSON = exports.BreakdownDtoFromJSONTyped = exports.BreakdownDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BreakdownDtoFromJSON(json) {
  return BreakdownDtoFromJSONTyped(json, false);
}
exports.BreakdownDtoFromJSON = BreakdownDtoFromJSON;
function BreakdownDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'breakdownItems': !runtime_1.exists(json, 'breakdownItems') ? undefined : json['breakdownItems'].map(_1.BreakdownItemDtoFromJSON),
    'ctv': !runtime_1.exists(json, 'ctv') ? undefined : json['ctv'],
    'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId']
  };
}
exports.BreakdownDtoFromJSONTyped = BreakdownDtoFromJSONTyped;
function BreakdownDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'bankCode': value.bankCode,
    'breakdownItems': value.breakdownItems === undefined ? undefined : value.breakdownItems.map(_1.BreakdownItemDtoToJSON),
    'ctv': value.ctv,
    'description': value.description,
    'id': value.id,
    'message': value.message,
    'userId': value.userId
  };
}
exports.BreakdownDtoToJSON = BreakdownDtoToJSON;