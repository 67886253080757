"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountDetailDtoStatusEnum = exports.AccountDetailDtoToJSON = exports.AccountDetailDtoFromJSONTyped = exports.AccountDetailDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AccountDetailDtoFromJSON(json) {
  return AccountDetailDtoFromJSONTyped(json, false);
}
exports.AccountDetailDtoFromJSON = AccountDetailDtoFromJSON;
function AccountDetailDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'accountId': !runtime_1.exists(json, 'accountId') ? undefined : json['accountId'],
    'resourceId': !runtime_1.exists(json, 'resourceId') ? undefined : json['resourceId'],
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'bban': !runtime_1.exists(json, 'bban') ? undefined : json['bban'],
    'currency': !runtime_1.exists(json, 'currency') ? undefined : json['currency'],
    'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
    'product': !runtime_1.exists(json, 'product') ? undefined : json['product'],
    'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
    'maskedPan': !runtime_1.exists(json, 'maskedPan') ? undefined : json['maskedPan'],
    'displayName': !runtime_1.exists(json, 'displayName') ? undefined : json['displayName'],
    'cashAccountType': !runtime_1.exists(json, 'cashAccountType') ? undefined : json['cashAccountType'],
    'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
    'bic': !runtime_1.exists(json, 'bic') ? undefined : json['bic'],
    'alertPsd2': !runtime_1.exists(json, 'alertPsd2') ? undefined : _1.AlertPsd2DtoFromJSON(json['alertPsd2'])
  };
}
exports.AccountDetailDtoFromJSONTyped = AccountDetailDtoFromJSONTyped;
function AccountDetailDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'accountId': value.accountId,
    'resourceId': value.resourceId,
    'iban': value.iban,
    'bban': value.bban,
    'currency': value.currency,
    'name': value.name,
    'product': value.product,
    'pan': value.pan,
    'maskedPan': value.maskedPan,
    'displayName': value.displayName,
    'cashAccountType': value.cashAccountType,
    'status': value.status,
    'bic': value.bic,
    'alertPsd2': _1.AlertPsd2DtoToJSON(value.alertPsd2)
  };
}
exports.AccountDetailDtoToJSON = AccountDetailDtoToJSON;
/**
* @export
* @enum {string}
*/
var AccountDetailDtoStatusEnum;
(function (AccountDetailDtoStatusEnum) {
  AccountDetailDtoStatusEnum["enabled"] = "enabled";
  AccountDetailDtoStatusEnum["deleted"] = "deleted";
  AccountDetailDtoStatusEnum["blocked"] = "blocked";
})(AccountDetailDtoStatusEnum = exports.AccountDetailDtoStatusEnum || (exports.AccountDetailDtoStatusEnum = {}));