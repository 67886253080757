"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BreakdownDetailDtoToJSON = exports.BreakdownDetailDtoFromJSONTyped = exports.BreakdownDetailDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BreakdownDetailDtoFromJSON(json) {
  return BreakdownDetailDtoFromJSONTyped(json, false);
}
exports.BreakdownDetailDtoFromJSON = BreakdownDetailDtoFromJSON;
function BreakdownDetailDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'balance': !runtime_1.exists(json, 'balance') ? undefined : json['balance'],
    'balancePerc': !runtime_1.exists(json, 'balancePerc') ? undefined : json['balancePerc'],
    'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
    'code': !runtime_1.exists(json, 'code') ? undefined : json['code'],
    'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    'isin': !runtime_1.exists(json, 'isin') ? undefined : json['isin'],
    'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    'productDescription': !runtime_1.exists(json, 'productDescription') ? undefined : json['productDescription'],
    'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
    'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId']
  };
}
exports.BreakdownDetailDtoFromJSONTyped = BreakdownDetailDtoFromJSONTyped;
function BreakdownDetailDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'balance': value.balance,
    'balancePerc': value.balancePerc,
    'bankCode': value.bankCode,
    'code': value.code,
    'description': value.description,
    'isin': value.isin,
    'message': value.message,
    'productDescription': value.productDescription,
    'type': value.type,
    'userId': value.userId
  };
}
exports.BreakdownDetailDtoToJSON = BreakdownDetailDtoToJSON;