"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScopeListDtoToJSON = exports.ScopeListDtoFromJSONTyped = exports.ScopeListDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ScopeListDtoFromJSON(json) {
  return ScopeListDtoFromJSONTyped(json, false);
}
exports.ScopeListDtoFromJSON = ScopeListDtoFromJSON;
function ScopeListDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'scopeIds': !runtime_1.exists(json, 'scopeIds') ? undefined : json['scopeIds']
  };
}
exports.ScopeListDtoFromJSONTyped = ScopeListDtoFromJSONTyped;
function ScopeListDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'scopeIds': value.scopeIds
  };
}
exports.ScopeListDtoToJSON = ScopeListDtoToJSON;