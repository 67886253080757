"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.psd2Reducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const psd2_types_1 = require("./psd2.types");
const initialState = {
  bancheDisponibili: undefined,
  bancheEnrollate: undefined,
  formEnrollment: (0, psd2_types_1.FormEnrollmentPSD2DtoFromJSON)({}),
  dettaglioBanca: undefined,
  enrollmentWebViewLink: undefined,
  renewWebViewLink: undefined,
  enrollmentPollingModalShown: false,
  enrollmentPollingActive: false,
  enrollmentCompleted: false,
  verificaFirmaDigitale: (0, api_rest_1.EsitoVerificaContrattoDtoFromJSON)({}),
  certificatoFirmaDigitale: undefined,
  paymentPollingActive: false,
  paymentPollingModalShown: false,
  psd2ResponseStatus: (0, psd2_types_1.PSD2StatusResponseDtoFromJSON)({})
};
const psd2Reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_START_ENROLLMENT_START:
      return {
        ...state,
        bancheDisponibili: initialState.bancheDisponibili,
        bancheEnrollate: initialState.bancheEnrollate
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_START_ENROLLMENT_SUCCESS:
      return {
        ...state,
        bancheDisponibili: action.payload.availables,
        bancheEnrollate: action.payload.enrolled
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_STEP_ENROLLMENT:
      return {
        ...state,
        formEnrollment: {
          ...state.formEnrollment,
          step: action.payload
        }
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_BANCA_SELEZIONATA_ENROLLMENT:
      return {
        ...state,
        formEnrollment: {
          ...state.formEnrollment,
          banca: action.payload
        }
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_USERNAME_ENROLLMENT:
      return {
        ...state,
        formEnrollment: {
          ...state.formEnrollment,
          username: action.payload,
          usernameDirty: true,
          usernameError: (0, helpers_1.validationField)(action.payload, '')
        }
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_PRODOTTO_ENROLLMENT:
      return {
        ...state,
        formEnrollment: {
          ...state.formEnrollment,
          prodotto: action.payload
        }
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_CONSENSO_ENROLLMENT:
      return {
        ...state,
        formEnrollment: {
          ...state.formEnrollment,
          consenso: action.payload
        }
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_ADD_IBAN_ENROLLMENT:
      {
        return {
          ...state,
          formEnrollment: {
            ...state.formEnrollment,
            ibanList: [...state.formEnrollment.ibanList, {
              iban: "",
              ibanDirty: false,
              ibanError: "invalid.required"
            }]
          }
        };
      }
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_REMOVE_IBAN_ENROLLMENT:
      {
        return {
          ...state,
          formEnrollment: {
            ...state.formEnrollment,
            ibanList: state.formEnrollment.ibanList.filter((_, i) => i != action.payload)
          }
        };
      }
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_UPDATE_IBAN_ENROLLMENT:
      {
        if (action.payload.index >= state.formEnrollment.ibanList.length) {
          return {
            ...state,
            formEnrollment: {
              ...state.formEnrollment,
              ibanList: [...state.formEnrollment.ibanList, {
                iban: action.payload.newIban,
                ibanDirty: true,
                ibanError: (0, helpers_1.validationField)(action.payload.newIban, '')
              }]
            }
          };
        }
        return {
          ...state,
          formEnrollment: {
            ...state.formEnrollment,
            ibanList: state.formEnrollment.ibanList.map((iban, i) => {
              if (i == action.payload.index) {
                return {
                  iban: action.payload.newIban,
                  ibanDirty: true,
                  ibanError: (0, helpers_1.validationField)(action.payload.newIban, '')
                };
              }
              return iban;
            })
          }
        };
      }
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_FORM_ENROLLMENT_SUBMITTED:
      {
        return {
          ...state,
          formEnrollment: {
            ...state.formEnrollment,
            formSubmitted: action.payload
          }
        };
      }
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_DOWNLOAD_PDF_ENROLLMENT_SUCCESS:
      return {
        ...state,
        formEnrollment: {
          ...state.formEnrollment,
          presaVisione: true
        }
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        dettaglioBanca: action.payload
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_WEBVIEW_LINK:
      return {
        ...state,
        enrollmentWebViewLink: action.payload
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_START:
      return {
        ...state,
        enrollmentPollingActive: false
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_SUCCESS:
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_CONSENT_RENEW_SUCCESS:
      return {
        ...state,
        enrollmentCompleted: true
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_ERROR:
      return {
        ...state,
        enrollmentPollingModalShown: false,
        enrollmentPollingActive: false
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_POLLING_SET_ACTIVE:
      return {
        ...state,
        enrollmentPollingModalShown: true,
        enrollmentPollingActive: true
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_RESET_LINKS:
      return {
        ...state,
        enrollmentWebViewLink: initialState.enrollmentWebViewLink,
        renewWebViewLink: initialState.renewWebViewLink
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_RESET_FORM_ENROLLMENT:
      return {
        ...state,
        formEnrollment: initialState.formEnrollment,
        dettaglioBanca: initialState.dettaglioBanca,
        enrollmentWebViewLink: initialState.enrollmentWebViewLink,
        renewWebViewLink: initialState.renewWebViewLink,
        enrollmentCompleted: initialState.enrollmentCompleted,
        enrollmentPollingActive: initialState.enrollmentPollingActive
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_CONSENT_RENEW_WEBVIEW_LINK:
      return {
        ...state,
        renewWebViewLink: action.payload
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_RESET_ENROLLMENT:
      return {
        ...initialState
      };
    case psd2_types_1.EReduxPSD2ActionTypes.VERIFICA_FIRMA_DIGITALE_SUCCESS:
      return {
        ...state,
        verificaFirmaDigitale: action.payload
      };
    case psd2_types_1.EReduxPSD2ActionTypes.CREA_CERTIFICATO_FIRMA_DIGITALE_START:
      return {
        ...state,
        certificatoFirmaDigitale: initialState.certificatoFirmaDigitale
      };
    case psd2_types_1.EReduxPSD2ActionTypes.CREA_CERTIFICATO_FIRMA_DIGITALE_SUCCESS:
      return {
        ...state,
        certificatoFirmaDigitale: true
      };
    case psd2_types_1.EReduxPSD2ActionTypes.RESET_PROCESSO_FIRMA_DIGITALE:
      return {
        ...state,
        verificaFirmaDigitale: initialState.verificaFirmaDigitale,
        certificatoFirmaDigitale: initialState.certificatoFirmaDigitale
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_PAYMENT_POLLING_SET_ACTIVE:
      return {
        ...state,
        paymentPollingActive: action.payload,
        paymentPollingModalShown: action.payload
      };
    case psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_RESPONSE_STATUS:
      return {
        ...state,
        psd2ResponseStatus: {
          ...state.psd2ResponseStatus,
          psd2Response: action.payload
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.psd2Reducer = psd2Reducer;
exports.default = exports.psd2Reducer;