"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LiquiditaElementoDtoToJSON = exports.LiquiditaElementoDtoFromJSONTyped = exports.LiquiditaElementoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function LiquiditaElementoDtoFromJSON(json) {
  return LiquiditaElementoDtoFromJSONTyped(json, false);
}
exports.LiquiditaElementoDtoFromJSON = LiquiditaElementoDtoFromJSON;
function LiquiditaElementoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'saldo': !runtime_1.exists(json, 'saldo') ? undefined : json['saldo'],
    'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'codiceRapportoFormattatoPerTipo': !runtime_1.exists(json, 'codiceRapportoFormattatoPerTipo') ? undefined : json['codiceRapportoFormattatoPerTipo']
  };
}
exports.LiquiditaElementoDtoFromJSONTyped = LiquiditaElementoDtoFromJSONTyped;
function LiquiditaElementoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'saldo': value.saldo,
    'divisa': value.divisa,
    'codiceRapporto': value.codiceRapporto,
    'codiceRapportoFormattatoPerTipo': value.codiceRapportoFormattatoPerTipo
  };
}
exports.LiquiditaElementoDtoToJSON = LiquiditaElementoDtoToJSON;