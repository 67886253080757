import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import React, { ReactFragment, useEffect, useState } from "react";
import left from "../../assets/icons/random/caret_left_grey.svg";
import right from "../../assets/icons/random/caret_right_grey.svg";
import { default as CarouselStepper } from "./item.carouselCustom.stepper";

interface Props {
  className?: string
  arrows?: boolean
  custom?: {
    slides?: JSX.Element[]
    slideIndexTrigger?: number
    onSelectSlideIndex?: (slideIndex: number) => void
    autoplayMilliseconds?: number
    allowHtmlMainText?: boolean
    info0?: string
    info1?: string
    cta?: ReactFragment
  }
}

const CarouselCustom: React.FC<Props> = (props: Props) => {

  const {
    className,
    arrows,
    custom
  } = props;

  const [selectedItemIndex, setSelectedtItemIndex] = useState<number>(0);

  const onChange = (value: number) => {
    const index = value >= 0 ? value % (custom?.slides?.length || 1) : (custom?.slides?.length || 1) - 1;

    if (value !== selectedItemIndex) {
      setSelectedtItemIndex(index);
      custom?.onSelectSlideIndex?.(index);
    }
  };

  useEffect(() => {
    if (custom?.slideIndexTrigger != undefined && selectedItemIndex != custom?.slideIndexTrigger) {
      onChange(custom?.slideIndexTrigger);
    }
  }, [custom?.slideIndexTrigger]);

  const LeftArrow = () => {
    if (!custom?.slides || custom?.slides.length <= 1 || !arrows) {
      return null;
    }
    return (
      <div className="carousel-button-arrow">
        <img
          src={left}
          style={{ height: 42, width: 42 }}
        />
      </div>
    );
  };

  const RightArrow = () => {
    if (!custom?.slides || custom?.slides?.length <= 1 || !arrows) {
      return null;
    }
    return (
      <div className="carousel-button-arrow">
        <img
          src={right}
          style={{ height: 42, width: 42 }}
        />
      </div>
    );
  };

  return (
    <div className={"carousel-wrapper " + (className || "")}>

      <div className="carousel-view">
        {custom?.slides && custom?.slides?.length > 0
          ? <Carousel
            value={selectedItemIndex}
            slidesPerPage={1}
            arrows
            slides={custom?.slides}
            infinite={!custom?.autoplayMilliseconds ? undefined : true}
            autoPlay={custom?.autoplayMilliseconds || undefined}
            onChange={onChange}
            arrowLeft={<LeftArrow />}
            arrowRight={<RightArrow />}
            addArrowClickHandler={arrows && !!custom?.slides && custom?.slides?.length > 1}
            draggable={!!custom?.slides && custom?.slides?.length > 1}
          />
          : null
        }
      </div>

      {custom?.slides && custom?.slides?.length > 1 &&
        <CarouselStepper
          items={custom?.slides}
          selectedItemIndex={selectedItemIndex % (custom?.slides?.length || 1)}
          onSelectItemIndex={onChange}
        />
      }

    </div>
  );
};

export default CarouselCustom;
