"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RapportoGirofondoDtoToJSON = exports.RapportoGirofondoDtoFromJSONTyped = exports.RapportoGirofondoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RapportoGirofondoDtoFromJSON(json) {
  return RapportoGirofondoDtoFromJSONTyped(json, false);
}
exports.RapportoGirofondoDtoFromJSON = RapportoGirofondoDtoFromJSON;
function RapportoGirofondoDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias']
  };
}
exports.RapportoGirofondoDtoFromJSONTyped = RapportoGirofondoDtoFromJSONTyped;
function RapportoGirofondoDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'iban': value.iban,
    'intestazione': value.intestazione,
    'alias': value.alias
  };
}
exports.RapportoGirofondoDtoToJSON = RapportoGirofondoDtoToJSON;