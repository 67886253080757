"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FirmaDigitaleSessionUrlDtoToJSON = exports.FirmaDigitaleSessionUrlDtoFromJSONTyped = exports.FirmaDigitaleSessionUrlDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FirmaDigitaleSessionUrlDtoFromJSON(json) {
  return FirmaDigitaleSessionUrlDtoFromJSONTyped(json, false);
}
exports.FirmaDigitaleSessionUrlDtoFromJSON = FirmaDigitaleSessionUrlDtoFromJSON;
function FirmaDigitaleSessionUrlDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'jobUrl': !runtime_1.exists(json, 'jobUrl') ? undefined : json['jobUrl'],
    'shortJobUrl': !runtime_1.exists(json, 'shortJobUrl') ? undefined : json['shortJobUrl']
  };
}
exports.FirmaDigitaleSessionUrlDtoFromJSONTyped = FirmaDigitaleSessionUrlDtoFromJSONTyped;
function FirmaDigitaleSessionUrlDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'jobUrl': value.jobUrl,
    'shortJobUrl': value.shortJobUrl
  };
}
exports.FirmaDigitaleSessionUrlDtoToJSON = FirmaDigitaleSessionUrlDtoToJSON;