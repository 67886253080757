"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setWatchlistTipoPagina = exports.resetWatchlist = exports.setWatchlistSelezionata = exports.setNomeWatchlist = exports.getQuotazioniWatchlist = exports.rimuoviWatchlist = exports.rimuoviElementoWatchlist = exports.aggiungiElementoWatchlist = exports.creaNuovaWatchlist = exports.getElencoWatchlist = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const watchlist_types_1 = require("./watchlist.types");
// Get elenco watchlist - Start
function getElencoWatchlistStart() {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_GET_ELENCO_WATCHLIST_START
  };
}
function getElencoWatchlistSuccess(payload) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_GET_ELENCO_WATCHLIST_SUCCESS,
    payload
  };
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, __1.setError)(value));
  };
}
const getElencoWatchlist = () => async (dispatch, _, extraArguments) => {
  dispatch((0, __1.setLoader)(true));
  dispatch(getElencoWatchlistStart());
  if (extraArguments.mock) {
    dispatch((0, __1.setLoader)(false));
  } else {
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).watchlist().then(response => {
        dispatch(getElencoWatchlistSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, __1.setLoader)(false));
      });
    });
  }
};
exports.getElencoWatchlist = getElencoWatchlist;
// Get elenco watchlist - Fine
// crea nuova watchlist - Start
function creaNuovaWatchlistStart() {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_CREA_WATCHLIST_START
  };
}
function creaNuovaWatchlistSuccess(payload) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_CREA_WATCHLIST_SUCCESS,
    payload
  };
}
const creaNuovaWatchlist = titolo => async (dispatch, getState, extraArguments) => {
  dispatch((0, __1.setLoader)(true));
  dispatch(creaNuovaWatchlistStart());
  if (extraArguments.mock) {
    dispatch((0, __1.setLoader)(false));
  } else {
    const watchlistState = getState().watchlist;
    const request = {
      watchlistStaticaDto: {
        nome: watchlistState.form.nomeWatchlist,
        items: [{
          mercato: titolo.mercato,
          codice: titolo.codice
        }]
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).creaWatchlistStatica(request).then(response => {
        dispatch(creaNuovaWatchlistSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, __1.setLoader)(false));
      });
    });
  }
};
exports.creaNuovaWatchlist = creaNuovaWatchlist;
// crea nuova watchlist - fine
// aggiunge elemento a watchlist - Start
function aggiungiElementoWatchlistStart() {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_AGGIUNGI_ELEMENTO_WATCHLIST_START
  };
}
function aggiungiElementoWatchlistSuccess(payload) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_AGGIUNGI_ELEMENTO_WATCHLIST_SUCCESS,
    payload
  };
}
const aggiungiElementoWatchlist = titolo => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  dispatch((0, __1.setLoader)(true));
  dispatch(aggiungiElementoWatchlistStart());
  if (extraArguments.mock) {
    dispatch((0, __1.setLoader)(false));
  } else {
    const watchlistState = getState().watchlist;
    const request = {
      watchlistItemInDto: {
        codiceWatchlist: (_a = watchlistState.form.watchlistSelezionata.codice) !== null && _a !== void 0 ? _a : '',
        mercato: (_b = titolo.mercato) !== null && _b !== void 0 ? _b : '',
        codice: (_c = titolo.codice) !== null && _c !== void 0 ? _c : ''
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).aggiungiItemWatchlistStatica(request).then(response => {
        dispatch(aggiungiElementoWatchlistSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, __1.setLoader)(false));
      });
    });
  }
};
exports.aggiungiElementoWatchlist = aggiungiElementoWatchlist;
// aggiunge elemento a watchlist - Fine
// rimuove elemento da watchlist - Start
function rimuoviElementoWatchlistStart() {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RIMUOVI_ELEMENTO_WATCHLIST_START
  };
}
function rimuoviElementoWatchlistSuccess(payload) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RIMUOVI_ELEMENTO_WATCHLIST_SUCCESS,
    payload
  };
}
const rimuoviElementoWatchlist = titolo => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d;
  dispatch((0, __1.setLoader)(true));
  dispatch(rimuoviElementoWatchlistStart());
  if (extraArguments.mock) {
    dispatch((0, __1.setLoader)(false));
  } else {
    const watchlistState = getState().watchlist;
    const request = {
      watchlistItemInDto: {
        codiceWatchlist: (_a = watchlistState.elenco.codiceWatchlist) !== null && _a !== void 0 ? _a : '',
        mercato: (_c = (_b = titolo.codiceMercato) === null || _b === void 0 ? void 0 : _b.substring(1, titolo.codiceMercato.length)) !== null && _c !== void 0 ? _c : '',
        codice: (_d = titolo.codice) !== null && _d !== void 0 ? _d : ''
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).rimuoviItemWatchlistStatica(request).then(response => {
        dispatch(rimuoviElementoWatchlistSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, __1.setLoader)(false));
      });
    });
  }
};
exports.rimuoviElementoWatchlist = rimuoviElementoWatchlist;
// rimuove elemento da watchlist - Fine
// rimuove watchlist - Start
function rimuoviWatchlistStart() {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RIMUOVI_WATCHLIST_START
  };
}
function rimuoviWatchlistSuccess(payload) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RIMUOVI_WATCHLIST_SUCCESS,
    payload
  };
}
const rimuoviWatchlist = () => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, __1.setLoader)(true));
  dispatch(rimuoviWatchlistStart());
  if (extraArguments.mock) {
    dispatch((0, __1.setLoader)(false));
  } else {
    const watchlistState = getState().watchlist;
    const request = {
      codiceWatchlist: (_a = watchlistState.elenco.codiceWatchlist) !== null && _a !== void 0 ? _a : ''
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).eliminaWatchlist(request).then(response => {
        dispatch(rimuoviWatchlistSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, __1.setLoader)(false));
      });
    });
  }
};
exports.rimuoviWatchlist = rimuoviWatchlist;
// rimuove watchlist - Fine
// get quotazioni watchlist - Start
function getQuotazioniWatchlistStart() {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_GET_QUOTAZIONI_WATCHLIST_START
  };
}
function getQuotazioniWatchlistSuccess(payload) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_GET_QUOTAZIONI_WATCHLIST_SUCCESS,
    payload
  };
}
const getQuotazioniWatchlist = () => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, __1.setLoader)(true));
  dispatch(getQuotazioniWatchlistStart());
  if (extraArguments.mock) {
    dispatch((0, __1.setLoader)(false));
  } else {
    const watchlistState = getState().watchlist;
    const request = {
      codiceWatchlist: (_a = watchlistState.form.watchlistSelezionata.codice) !== null && _a !== void 0 ? _a : ''
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getWatchlistQuotazioni(request).then(response => {
        dispatch(getQuotazioniWatchlistSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, __1.setLoader)(false));
      });
    });
  }
};
exports.getQuotazioniWatchlist = getQuotazioniWatchlist;
// rimuove watchlist - Fine
function setNomeWatchlist(txt) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_SET_NOME_WATCHLIST,
    payload: txt
  };
}
exports.setNomeWatchlist = setNomeWatchlist;
;
function setWatchlistSelezionata(watchlist) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_SET_WATCHLIST_SELEZIONATA,
    payload: watchlist
  };
}
exports.setWatchlistSelezionata = setWatchlistSelezionata;
;
function resetWatchlist() {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RESET
  };
}
exports.resetWatchlist = resetWatchlist;
;
function setWatchlistTipoPagina(txt) {
  return {
    type: watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_SET_TIPO_PAGINA,
    payload: txt
  };
}
exports.setWatchlistTipoPagina = setWatchlistTipoPagina;