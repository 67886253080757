"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParametriFrecciaDtoToJSON = exports.ParametriFrecciaDtoFromJSONTyped = exports.ParametriFrecciaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriFrecciaDtoFromJSON(json) {
  return ParametriFrecciaDtoFromJSONTyped(json, false);
}
exports.ParametriFrecciaDtoFromJSON = ParametriFrecciaDtoFromJSON;
function ParametriFrecciaDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'].map(_1.RapportoCompletoDtoFromJSON),
    'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    'ultimeBozzeFreccia': !runtime_1.exists(json, 'ultimeBozzeFreccia') ? undefined : json['ultimeBozzeFreccia'].map(_1.FrecciaDtoFromJSON),
    'ultimeFreccia': !runtime_1.exists(json, 'ultimeFreccia') ? undefined : json['ultimeFreccia'].map(_1.FrecciaDtoFromJSON)
  };
}
exports.ParametriFrecciaDtoFromJSONTyped = ParametriFrecciaDtoFromJSONTyped;
function ParametriFrecciaDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'rapporti': value.rapporti === undefined ? undefined : value.rapporti.map(_1.RapportoCompletoDtoToJSON),
    'parametri': value.parametri,
    'ultimeBozzeFreccia': value.ultimeBozzeFreccia === undefined ? undefined : value.ultimeBozzeFreccia.map(_1.FrecciaDtoToJSON),
    'ultimeFreccia': value.ultimeFreccia === undefined ? undefined : value.ultimeFreccia.map(_1.FrecciaDtoToJSON)
  };
}
exports.ParametriFrecciaDtoToJSON = ParametriFrecciaDtoToJSON;