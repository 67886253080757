"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dossierTitoliReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
  form: {
    tuttiRapporti: false,
    rapportoSelezionato: (0, api_rest_1.DatiIntestatarioDtoFromJSON)({}),
    depositiSelezionati: []
  },
  rapporti: undefined,
  customerDossier: undefined,
  customerGraphics: undefined,
  excelDossierTitoli: undefined,
  pdfDossierTitoli: undefined,
  titoloDaAcquistare: undefined
};
const dossierTitoliReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case __1.EReduxActionTypes.DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS:
      return {
        ...state,
        rapporti: action.payload
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_START:
      return {
        ...state,
        customerDossier: initialState.customerDossier
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_SUCCESS:
      return {
        ...state,
        customerDossier: action.payload
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_GET_GRAPHICS_START:
      return {
        ...state,
        customerGraphics: initialState.customerGraphics
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_GET_GRAPHICS_SUCCESS:
      return {
        ...state,
        customerGraphics: action.payload
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_SET_TUTTI_RAPPORTI:
      return {
        ...state,
        form: {
          ...state.form,
          tuttiRapporti: action.payload
        }
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_SET_DEPOSITI_SELEZIONATI:
      return {
        ...state,
        form: {
          ...state.form,
          depositiSelezionati: action.payload
        }
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_RESET:
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_RESET_DOSSIER:
      return {
        ...state,
        customerDossier: initialState.customerDossier
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_RESET_GRAPHICS:
      return {
        ...state,
        customerGraphics: initialState.customerGraphics
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_ESPORTA_PDF_SUCCESS:
      return {
        ...state,
        pdfDossierTitoli: action.payload
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_ESPORTA_EXCEL_SUCCESS:
      return {
        ...state,
        excelDossierTitoli: action.payload
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_SET_TITOLO_DA_ACQUISTARE:
      return {
        ...state,
        titoloDaAcquistare: action.payload
      };
    case __1.EReduxActionTypes.DOSSIER_TITOLI_RESET_TITOLO_DA_ACQUISTARE:
      return {
        ...state,
        titoloDaAcquistare: initialState.titoloDaAcquistare
      };
    case __1.EReduxActionTypes.GENERAL_RESET_CACHE_POST_DISPO:
      // action invocata dopo una dispositiva di tipo resetWidgetsDispoFunctions
      return {
        ...state,
        rapporti: initialState.rapporti,
        customerDossier: initialState.customerDossier,
        customerGraphics: initialState.customerGraphics
      };
    case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS:
      // action invocata tramite pull to refresh
      return {
        ...state,
        rapporti: initialState.rapporti,
        customerDossier: initialState.customerDossier,
        customerGraphics: initialState.customerGraphics
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.dossierTitoliReducer = dossierTitoliReducer;