"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PagamentoTributiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PagamentoTributiControllerApi extends runtime.BaseAPI {
  /**
   * Autorizzazione di una disposizione di pagamento tributi
   */
  autorizzaPagamentoTributiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaPagamentoTributi.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaPagamentoTributi.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/tributi/autorizza/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di pagamento tributi
   */
  autorizzaPagamentoTributi(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaPagamentoTributiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Controlla pagamento tributo
   */
  controlloPagamentoTributiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.pagamentoTributiRichiestaDto === null || requestParameters.pagamentoTributiRichiestaDto === undefined) {
        throw new runtime.RequiredError('pagamentoTributiRichiestaDto', 'Required parameter requestParameters.pagamentoTributiRichiestaDto was null or undefined when calling controlloPagamentoTributi.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/tributi/controllo",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.PagamentoTributiRichiestaDtoToJSON(requestParameters.pagamentoTributiRichiestaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DatiPagamentoTributoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controlla pagamento tributo
   */
  controlloPagamentoTributi(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloPagamentoTributiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Leggi parametri pagamento tributi
   */
  getParametriPagamentoTributiRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/tributi/parametri",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriDtoFromJSON(jsonValue));
    });
  }
  /**
   * Leggi parametri pagamento tributi
   */
  getParametriPagamentoTributi() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getParametriPagamentoTributiRaw();
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  infoAutenticazionePagamentoTributiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling infoAutenticazionePagamentoTributi.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/tributi/infoautenticazione/{idDispositiva}".replace("{".concat("idDispositiva", "}"), encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  infoAutenticazionePagamentoTributi(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.infoAutenticazionePagamentoTributiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce la notifica dei tributi
   */
  notificaTributiHomeRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/tributi/notifica",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.NotificaTributiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce la notifica dei tributi
   */
  notificaTributiHome() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.notificaTributiHomeRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce la lista dei tributi da pagare per l\'utente
   */
  recuperaTributiRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: "/private/tributi",
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TributiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce la lista dei tributi da pagare per l\'utente
   */
  recuperaTributi() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaTributiRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce il tributo in base al codice pagamento e al codice fiscale del debitore
   */
  recuperaTributiContoTerziRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.pagamentoTributiRichiestaDto === null || requestParameters.pagamentoTributiRichiestaDto === undefined) {
        throw new runtime.RequiredError('pagamentoTributiRichiestaDto', 'Required parameter requestParameters.pagamentoTributiRichiestaDto was null or undefined when calling recuperaTributiContoTerzi.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: "/private/tributi/contoterzi",
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.PagamentoTributiRichiestaDtoToJSON(requestParameters.pagamentoTributiRichiestaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.TributiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce il tributo in base al codice pagamento e al codice fiscale del debitore
   */
  recuperaTributiContoTerzi(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaTributiContoTerziRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.PagamentoTributiControllerApi = PagamentoTributiControllerApi;