"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerificaRicaricaRequestDtoToJSON = exports.VerificaRicaricaRequestDtoFromJSONTyped = exports.VerificaRicaricaRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function VerificaRicaricaRequestDtoFromJSON(json) {
  return VerificaRicaricaRequestDtoFromJSONTyped(json, false);
}
exports.VerificaRicaricaRequestDtoFromJSON = VerificaRicaricaRequestDtoFromJSON;
function VerificaRicaricaRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    'pan': json['pan'],
    'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    'tipoOperazione': !runtime_1.exists(json, 'tipoOperazione') ? undefined : json['tipoOperazione']
  };
}
exports.VerificaRicaricaRequestDtoFromJSONTyped = VerificaRicaricaRequestDtoFromJSONTyped;
function VerificaRicaricaRequestDtoToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'pan': value.pan,
    'codiceRapporto': value.codiceRapporto,
    'tipoOperazione': value.tipoOperazione
  };
}
exports.VerificaRicaricaRequestDtoToJSON = VerificaRicaricaRequestDtoToJSON;